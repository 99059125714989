<template>
  <fragment>
    <td class="ht-ticker-td wide-col" style="padding: 0px 0.5rem 0px 0.5rem !important">
      <span class="ui grey tiny inverted header">{{ ticker.time }}</span>
    </td>
    <td
      v-if="!hideSymbol"
      class="left aligned ht-ticker-td"
      style="cursor: pointer; padding: 0px 0.5rem 0px 0.5rem !important"
      @click="onSymbolClicked"
    >
      <template v-if="ticker.symbol.length <= 8">
        <span v-if="ticker.changeFlag === 'U' || ticker.changeFlag === 'C'" class="ui green tiny header">{{ ticker.symbol }}</span>
        <span v-else-if="ticker.changeFlag === 'D' || ticker.changeFlag === 'F'" class="ui red tiny header">{{ ticker.symbol }}</span>
        <span v-else class="ui yellow tiny header">{{ ticker.symbol }}</span>
      </template>
      <template v-else>
        <span v-if="ticker.changeFlag === 'U' || ticker.changeFlag === 'C'" style="font-size: 0.75rem" class="ui green tiny header">{{
          ticker.symbol
        }}</span>
        <span v-else-if="ticker.changeFlag === 'D' || ticker.changeFlag === 'F'" style="font-size: 0.75rem" class="ui red tiny header">{{
          ticker.symbol
        }}</span>
        <span v-else style="font-size: 0.75rem" class="ui yellow tiny header">{{ ticker.symbol }}</span>
      </template>
    </td>
    <td class="right aligned ht-ticker-td" style="padding: 0px 0.5rem 0px 0px !important">
      <span v-if="ticker.side === 'B' || ticker.side === 'L'" class="ui blue tiny header"
        >{{ $numeral(ticker.volume).format("0,0") }}&nbsp;<i class="plus square outline blue icon"></i
      ></span>
      <span v-else-if="ticker.side === 'S'" class="ui pink tiny header"
        >{{ $numeral(ticker.volume).format("0,0") }}&nbsp;<i class="minus square outline pink icon"></i
      ></span>
      <span v-else class="ui yellow tiny header">{{ $numeral(ticker.volume).format("0,0") }}&nbsp;<i class="square outline yellow icon"></i></span>
    </td>
    <td class="right aligned ht-ticker-td" style="padding: 0px 0.5rem 0px 0px !important">
      <span v-if="ticker.changeFlag === 'U' || ticker.changeFlag === 'C'" class="ui green tiny header">
        {{ price(ticker.price) }}
      </span>
      <span v-else-if="ticker.changeFlag === 'D' || ticker.changeFlag === 'F'" class="ui red tiny header">
        {{ price(ticker.price) }}
      </span>
      <span v-else class="ui yellow tiny header">
        {{ price(ticker.price) }}
      </span>
    </td>
    <td class="right aligned ht-ticker-td wide-col-mini" style="padding: 0px 0.5rem 0px 0px !important">
      <span v-if="ticker.changeFlag === 'U' || ticker.changeFlag === 'C'" class="ui green tiny header"
        >{{ "+" + price(ticker.changePrice) }}&nbsp;<i class="caret up green icon" style="padding-left: 5px !important"></i
      ></span>
      <span v-else-if="ticker.changeFlag === 'D' || ticker.changeFlag === 'F'" class="ui red tiny header"
        >{{ price(ticker.changePrice) }}&nbsp;<i class="caret down red icon" style="padding-left: 5px !important"></i
      ></span>
      <span v-else class="ui yellow tiny header">&nbsp;&nbsp;{{ $numeral(0).format("0,0.00") }}&nbsp;<i class="sort rotated yellow icon"></i></span>
    </td>
  </fragment>
</template>

<script>
import { price } from "@/utils/NumberFormatter";

export default {
  props: {
    ticker: {
      type: [Array, Object],
      default: () => [],
    },
    hideSymbol: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    price,
    onSymbolClicked() {
      const symbol = {
        symbol: this.ticker.symbol,
        value: {
          exchangeId: this.ticker.code === "PB01" || this.ticker.code === "PS01" ? "1" : "2",
          securityId: this.ticker.securityId,
          symbol: this.ticker.symbol,
        },
        fromUID: "outside",
      };
      this.$store.set("global/currentQuoteSelectedSymbol", symbol);
    },
  },
};
</script>

<style scoped>
.ht-ticker-td {
  border: 0px !important;
  font-size: 1rem !important;
  line-height: 21px;
  height: 21px !important;
}

.icon {
  line-height: 0.75em !important;
  margin: -2.5px 0 !important;
  font-size: 1.15em !important;
}

@media screen and (max-width: 1599px) {
  .wide-col {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .wide-col-mini {
    display: none !important;
  }
}
</style>
