/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class BS03 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._date = new StringMessageBase();
		this._totalMktVol = new NumberMessageBase();
		this._totalMktVal = new NumberMessageBase();
		this._records = new ArrayMessageBase(Record);
		this._sortBy = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get date() {
		return this._date;
	}
	
	get totalMktVol() {
		return this._totalMktVol;
	}
	
	get totalMktVal() {
		return this._totalMktVal;
	}
	
	get records() {
		return this._records;
	}
	
	get sortBy() {
		return this._sortBy;
	}
}

export class Record {
	constructor() {
		this._recordId = new NumberMessageBase();
		this._rank = new NumberMessageBase();
		this._brokerName = new StringMessageBase();
		this._brokerNo = new StringMessageBase();
		this._buyVal = new NumberMessageBase();
		this._buyValMktPct = new NumberMessageBase();
		this._sellVal = new NumberMessageBase();
		this._sellValMktPct = new NumberMessageBase();
		this._buyShare = new NumberMessageBase();
		this._buyShareMktPct = new NumberMessageBase();
		this._sellShare = new NumberMessageBase();
		this._sellShareMktPct = new NumberMessageBase();
		this._totalVal = new NumberMessageBase();
		this._totalValMktPct = new NumberMessageBase();
		this._totalShare = new NumberMessageBase();
		this._totalShareMktPct = new NumberMessageBase();
		this._netVal = new NumberMessageBase();
		this._chg = new NumberMessageBase();
		this._chgPct = new NumberMessageBase();
		this._rankFlag = new NumberMessageBase();
	}
	
	get recordId() {
		return this._recordId;
	}
	
	get rank() {
		return this._rank;
	}
	
	get brokerName() {
		return this._brokerName;
	}
	
	get brokerNo() {
		return this._brokerNo;
	}
	
	get buyVal() {
		return this._buyVal;
	}
	
	get buyValMktPct() {
		return this._buyValMktPct;
	}
	
	get sellVal() {
		return this._sellVal;
	}
	
	get sellValMktPct() {
		return this._sellValMktPct;
	}
	
	get buyShare() {
		return this._buyShare;
	}
	
	get buyShareMktPct() {
		return this._buyShareMktPct;
	}
	
	get sellShare() {
		return this._sellShare;
	}
	
	get sellShareMktPct() {
		return this._sellShareMktPct;
	}
	
	get totalVal() {
		return this._totalVal;
	}
	
	get totalValMktPct() {
		return this._totalValMktPct;
	}
	
	get totalShare() {
		return this._totalShare;
	}
	
	get totalShareMktPct() {
		return this._totalShareMktPct;
	}
	
	get netVal() {
		return this._netVal;
	}
	
	get chg() {
		return this._chg;
	}
	
	get chgPct() {
		return this._chgPct;
	}
	
	get rankFlag() {
		return this._rankFlag;
	}
}

export default BS03;
