import _ from "lodash";

const portFavorite = {
  text: "Port",
  value: "Port",
  label: { empty: true, circular: true }
};

const newFavorite = {
  text: "Create New Favorite",
  value: "New Favorite",
  label: { empty: true, circular: true }
};

const rankingMenu = () => [
  {
    securityIds: "Most Active by Value",
    listType: "RK",
    listId: "1",
    securityTypes: "",
    listGroup: ""
  },
  {
    securityIds: "Most Active by Volume",
    listType: "RK",
    listId: "2",
    securityTypes: "",
    listGroup: ""
  },
  // {
  //   securityIds: "Most Active in Broker",
  //   listType: "RK",
  //   listId: "10",
  //   securityTypes: "",
  //   listGroup: ""
  // },
  {
    securityIds: "Top Gainer",
    listType: "RK",
    listId: "3",
    securityTypes: "",
    listGroup: ""
  },
  {
    securityIds: "Top Loser",
    listType: "RK",
    listId: "4",
    securityTypes: "",
    listGroup: ""
  },
  {
    securityIds: "Projected Open/Close Gainers",
    listType: "RK",
    listId: "6",
    securityTypes: "",
    listGroup: ""
  },
  {
    securityIds: "Projected Open/Close Losers",
    listType: "RK",
    listId: "7",
    securityTypes: "",
    listGroup: ""
  },
  {
    securityIds: "Open Gainers",
    listType: "RK",
    listId: "8",
    securityTypes: "",
    listGroup: ""
  },
  {
    securityIds: "Open Losers",
    listType: "RK",
    listId: "9",
    securityTypes: "",
    listGroup: ""
  },
  {
    securityIds: "Most Swing",
    listType: "RK",
    listId: "5",
    securityTypes: "",
    listGroup: ""
  }
];

const generateRankingMenu = () => {
  let allRanking = [];

  rankingMenu().forEach(rank => {
    allRanking.push({
      id: rank.securityIds,
      name: rank.securityIds
    });
  });

  return {
    title: "Ranking",
    list: [...allRanking]
  };
};

const generateIndexesMenu = indexes => {
  let allIndexes = [];

  indexes.forEach(index => {
    if (index.name === ".SET" || index.name === ".MAI") {
      allIndexes.push({
        id: index.name,
        name: index.name
      });
    }
  });

  return {
    title: "Index",
    list: [...allIndexes]
  };
};

const generateSectorIndustryMenu = (sector, industry) => {
  let subSectorItem = [];
  let subIndustryItem = [];

  sector.forEach(s => {
    subSectorItem.push({
      id: s.name,
      name: s.name
    });
  });

  industry.forEach(i => {
    subIndustryItem.push({
      id: i.name,
      name: i.name
    });
  });

  return [
    {
      title: "Sector",
      list: [...subSectorItem]
    },
    {
      title: "Industry",
      list: [...subIndustryItem]
    }
  ];
};

const securitiesTypeMenu = () => [
  {
    securityIds: "Common Stock",
    listType: "ST",
    listId: "S",
    securityTypes: ""
  },
  {
    securityIds: "Foreign Common Stock",
    listType: "ST",
    listId: "F",
    securityTypes: ""
  },
  {
    securityIds: "Derivatives Warrant",
    listType: "ST",
    listId: "V",
    securityTypes: ""
  },
  {
    securityIds: "Warrant",
    listType: "ST",
    listId: "W",
    securityTypes: ""
  },
  {
    securityIds: "ETF",
    listType: "ST",
    listId: "L",
    securityTypes: ""
  },
  {
    securityIds: "Unit Trust",
    listType: "ST",
    listId: "U",
    securityTypes: ""
  },
  {
    securityIds: "Preferred",
    listType: "ST",
    listId: "P",
    securityTypes: ""
  },
  {
    securityIds: "Preferred Foreign",
    listType: "ST",
    listId: "Q",
    securityTypes: ""
  }
];

const generateSecuritiesTypeMenu = () => {
  let allSecuritiesType = [];

  securitiesTypeMenu().forEach(type => {
    allSecuritiesType.push({
      id: type.securityIds,
      name: type.securityIds
    });
  });

  return {
    title: "Type",
    list: [...allSecuritiesType]
  };
};

const securitiesStatusMenu = () => [
  {
    securityIds: "Turnover List",
    listType: "SS",
    listId: "T",
    securityTypes: ""
  }
];

const generateSecuritiesStatusMenu = () => {
  let allSecuritiesStatus = [];

  securitiesStatusMenu().forEach(status => {
    allSecuritiesStatus.push({
      id: status.securityIds,
      name: status.securityIds
    });
  });

  return {
    title: "Status",
    list: [...allSecuritiesStatus]
  };
};

/* TFEX Menu */

const equityMenu = () => [
  {
    securityIds: "SET50 Index Futures",
    listType: "UL",
    listId: "EQ",
    listGroup: "S50FU"
  },
  {
    securityIds: "SET50 Index Options",
    listType: "UL",
    listId: "EQ",
    listGroup: "S50OP"
  },
  {
    securityIds: "Sector Index Futures",
    listType: "UL",
    listId: "EQ",
    listGroup: "SECINDXFU"
  },
  {
    securityIds: "Single Stock Futures",
    listType: "UL",
    listId: "EQ",
    listGroup: "SSFU"
  }
];

const preciousMetalMenu = () => [
  {
    securityIds: "Gold Futures",
    listType: "UL",
    listId: "PM",
    listGroup: "GFU"
  }
];

const deferredPreciousMetalMenu = () => [
  {
    securityIds: "Gold-D",
    listType: "UL",
    listId: "DP",
    listGroup: "GD"
  }
];

const interestRateMenu = () => [
  {
    securityIds: "3M BIBOR",
    listType: "UL",
    listId: "IR",
    listGroup: "3MBIFU"
  },
  {
    securityIds: "5Y Gov Bond",
    listType: "UL",
    listId: "IR",
    listGroup: "5YBOFU"
  }
];

const agriCultureMenu = () => [
  {
    securityIds: "RSS3 Futures",
    listType: "UL",
    listId: "AG",
    listGroup: "RSS3FU"
  },
  {
    securityIds: "RSS3D Futures",
    listType: "UL",
    listId: "AG",
    listGroup: "RSS3DFU"
  }
];

const currencyMenu = () => [
  {
    securityIds: "USD Futures",
    listType: "UL",
    listId: "CR",
    listGroup: "USDFU"
  }
];

const generateUnderlyingMenu = () => {
  let allEquity = [];
  let allPreciousMetal = [];
  let allDeferredPreciousMetal = [];
  let allInterestRate = [];
  let allAgriCulture = [];
  let allCurrency = [];

  equityMenu().forEach(status => {
    allEquity.push({
      id: status.securityIds,
      name: status.securityIds
    });
  });

  preciousMetalMenu().forEach(status => {
    allPreciousMetal.push({
      id: status.securityIds,
      name: status.securityIds
    });
  });

  deferredPreciousMetalMenu().forEach(status => {
    allDeferredPreciousMetal.push({
      id: status.securityIds,
      name: status.securityIds
    });
  });

  interestRateMenu().forEach(status => {
    allInterestRate.push({
      id: status.securityIds,
      name: status.securityIds
    });
  });

  agriCultureMenu().forEach(status => {
    allAgriCulture.push({
      id: status.securityIds,
      name: status.securityIds
    });
  });

  currencyMenu().forEach(status => {
    allCurrency.push({
      id: status.securityIds,
      name: status.securityIds
    });
  });

  return [
    {
      title: "Equity",
      list: [...allEquity]
    },
    {
      title: "Metal",
      list: [...allPreciousMetal]
    },
    {
      title: "Currency",
      list: [...allCurrency]
    },
    {
      title: "Interest Rate",
      list: [...allInterestRate]
    },
    {
      title: "Agricultural",
      list: [...allAgriCulture]
    },
    {
      title: "Deferred",
      list: [...allDeferredPreciousMetal]
    }
  ];
};

export {
  portFavorite,
  newFavorite,
  rankingMenu,
  securitiesTypeMenu,
  securitiesStatusMenu,
  equityMenu,
  preciousMetalMenu,
  deferredPreciousMetalMenu,
  interestRateMenu,
  agriCultureMenu,
  currencyMenu,
  generateRankingMenu,
  generateIndexesMenu,
  generateSectorIndustryMenu,
  generateSecuritiesTypeMenu,
  generateSecuritiesStatusMenu,
  generateUnderlyingMenu
};
