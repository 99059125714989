<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    value: {
      type: Number,
    },
    market: {
      type: String,
      default: "SET",
    },
    placeholder: {
      type: String,
      default: "Condition",
    },
    filterable: {
      type: String,
      default: "startsWith",
    },
  },
  data() {
    return {
      style: "width: 100%; min-width: 20px;",
      defaultValue: 1,
      displayValue: 1,
      options: [
        {
          text: "Bid >=",
          value: 1,
        },
        {
          text: "Bid <=",
          value: 2,
        },
        {
          text: "Offer >=",
          value: 3,
        },
        {
          text: "Offer <=",
          value: 4,
        },
        {
          text: "Last >=",
          value: 5,
        },
        {
          text: "Last <=",
          value: 6,
        },
      ],
    };
  },
  created() {
    if (this.market === "SET") {
      const SETOptions = [
        {
          text: "High >=",
          value: 7,
        },
        {
          text: "High <=",
          value: 8,
        },
        {
          text: "Low >=",
          value: 9,
        },
        {
          text: "Low <=",
          value: 10,
        },
        {
          text: "Avg >=",
          value: 11,
        },
        {
          text: "Avg <=",
          value: 12,
        },
      ];
      this.options = this.options.concat(SETOptions);
    }
  },
};
</script>
