<template>
  <div class="group-value">
    <div class="options-container">
      <div class="row">
        <sui-checkbox v-model="selectedMarket" radio value="SET" :disabled="!isEnableETS()" class="custom-checkbox">
          <h5 class="ui grey inverted tiny header">{{ "SET" }}</h5>
        </sui-checkbox>
        <sui-checkbox v-model="selectedMarket" radio value="TFEX" :disabled="!isEnableDTS()" class="custom-checkbox">
          <h5 class="ui grey inverted tiny header">{{ "TFEX" }}</h5>
        </sui-checkbox>
        <div class="input-field">
          <h5 class="ui grey header inverted" style="padding: 1rem 0 1rem 0; width: auto">{{ "Trader ID" }}</h5>
          <InputText
            v-model="traderID"
            class="ht-trader-id"
            style="height: 36px"
            name="Trader ID"
            placeholder="Trader ID"
            :uppercase="true"
            @enter="onEnter"
          />
        </div>
        <div class="input-field">
          <h5 class="ui grey header inverted ht-text-header">{{ "Trader Hierarchy" }}</h5>
          <sui-breadcrumb class="custom-breadcrumb" icon="right angle" :sections="sections" @click="onBreadcrumbClicked" />
        </div>
      </div>
      <div class="row">
        <div class="group-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Trader Name" }}
          </h5>
          <span class="ht-text-value">{{ traderName }}</span>
        </div>
        <div class="group-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Title" }}
          </h5>
          <span class="ht-text-value">{{ traderTitle }}</span>
        </div>
        <div class="group-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Approval Over" }}
          </h5>
          <span class="ht-text-value">{{ approvalOver }}</span>
        </div>
        <div class="group-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Level" }}
          </h5>
          <span class="ht-text-value">{{ level }}</span>
        </div>
        <div class="group-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Supervisor" }}
          </h5>
          <span class="ht-text-value">{{ supervisor }}</span>
        </div>
        <div class="group-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Value" }}
          </h5>
          <span class="ht-text-value">{{ value }}</span>
        </div>
        <div class="group-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Commission" }}
          </h5>
          <span class="ht-text-value">{{ commission }}</span>
        </div>
      </div>
    </div>
    <div class="ui grid content-grid">
      <div class="two column row content-container">
        <div class="sixteen wide computer twelve wide large screen column twelve wide widescreen monitor column left-side-container">
          <div class="group-value-by-side-container">
            <ag-grid-vue
              class="group-value-by-side-table"
              :class="{
                'ag-theme-balham-dark': theme === 'Dark',
                'ag-theme-balham': theme === 'Light',
              }"
              row-selection="single"
              :grid-options="gridGroupValueBySideOptions"
              :column-defs="selectedMarket === 'SET' ? columnDefsETSGroupValueBySide : columnDefsDTSGroupValueBySide"
              :row-data="rowGroupValueBySide"
              :row-height="21"
              :header-height="32"
              :default-col-def="{ sortable: false, resizable: true }"
              :animate-rows="true"
              :suppress-drag-leave-hides-columns="true"
              :enable-cell-change-flash="true"
              :locale-text="{ noRowsToShow: `Don't have any Group Value by Side.` }"
              :row-drag-managed="false"
              :pinned-bottom-row-data="pinnedBottomRowGroupValueBySide"
              overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
              @grid-ready="onGroupValueBySideGridReady"
              @cell-focused="onCellGroupValueBySideFocused"
            />
          </div>

          <div class="group-value-by-trader-container">
            <ag-grid-vue
              class="group-value-by-trader-table"
              style="width: 100%; height: 100%; padding: 0px"
              :class="{
                'ag-theme-balham-dark': theme === 'Dark',
                'ag-theme-balham': theme === 'Light',
              }"
              row-selection="single"
              :grid-options="gridGroupValueByTraderOptions"
              :column-defs="selectedMarket === 'SET' ? columnDefsETSGroupValueByTrader : columnDefsDTSGroupValueByTrader"
              :row-data="rowGroupValueByTrader"
              :row-height="21"
              :header-height="32"
              :default-col-def="{ sortable: true, resizable: true }"
              :animate-rows="true"
              :suppress-drag-leave-hides-columns="true"
              :enable-cell-change-flash="true"
              :locale-text="{ noRowsToShow: `Don't have any Group Value by Trader.` }"
              :row-drag-managed="false"
              :pinned-bottom-row-data="pinnedBottomRowGroupValueByTrader"
              overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
              @grid-ready="onGroupValueByTraderGridReady"
              @cell-focused="onCellGroupValueByTraderFocused"
              @row-double-clicked="onRowByTraderDoubleClicked"
            />
          </div>
        </div>

        <div class="four wide column large screen only right-side-container">
          <div class="row pie-chart-value-container">
            <PieChartValue mode="Group" :data="chartData" />
          </div>
          <div class="row progress-bar-value-container">
            <ProgressBarValue mode="Group" :data="chartData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsETSGroupValueBySide, columnDefsDTSGroupValueBySide } from "@/components/grid/value/GroupValueBySideColumnDefs.js";
import { columnDefsETSGroupValueByTrader, columnDefsDTSGroupValueByTrader } from "@/components/grid/value/GroupValueByTraderColumnDefs.js";
import InputText from "@/components/InputText";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

Object.freeze(columnDefsETSGroupValueBySide);
Object.freeze(columnDefsDTSGroupValueBySide);
Object.freeze(columnDefsETSGroupValueByTrader);
Object.freeze(columnDefsDTSGroupValueByTrader);

import PieChartValue from "@/components/value/PieChartValue.vue";
import ProgressBarValue from "@/components/value/ProgressBarValue.vue";

export default {
  name: "GroupValue",
  components: {
    AgGridVue,
    PieChartValue,
    ProgressBarValue,
    InputText,
  },
  data: () => ({
    columnDefsETSGroupValueBySide,
    columnDefsDTSGroupValueBySide,
    columnDefsETSGroupValueByTrader,
    columnDefsDTSGroupValueByTrader,

    gridGroupValueBySideApi: null,
    gridGroupValueByTraderApi: null,
    columnGroupValueBySideApi: null,
    columnGroupValueByTraderApi: null,
    rowGroupValueBySide: [],
    rowGroupValueByTrader: [],
    pinnedBottomRowGroupValueBySide: [
      {
        side: "Total",
        vol: 0,
        volBrkPct: 0,
        val: 0,
        valBrkPct: 0,
        commission: 0,
        commissionBrkPct: 0,
      },
    ],
    pinnedBottomRowGroupValueByTrader: [
      {
        traderId: "Total",
        title: "0 Group(s)",
        level: "",
        val: "",
        valPct: "",
        vol: "",
        volPct: "",
        commission: "",
        commissionPct: "",
      },
    ],
    selectedMarket: "SET",
    isEnableETS,
    isEnableDTS,

    traderID: "",
    traderName: "-",
    traderTitle: "-",
    approvalOver: "0",
    level: "-",
    supervisor: "-",
    value: "0",
    commission: "0.00",

    BRRefId: null,
    chartData: {},
    sections: [],
  }),
  watch: {
    selectedMarket() {
      this.filterChanged();
    },
  },
  methods: {
    onValueStartRefresh() {
      this.rowGroupValueBySide = [];
      this.rowGroupValueByTrader = [];
      this.pinnedBottomRowGroupValueBySide = [
        {
          side: "Total",
          vol: 0,
          volBrkPct: 0,
          val: 0,
          valBrkPct: 0,
          commission: 0,
          commissionBrkPct: 0,
        },
      ];
      this.pinnedBottomRowGroupValueByTrader = [
        {
          traderId: "Total",
          title: "0 Group(s)",
          level: "",
          val: "",
          valPct: "",
          vol: "",
          volPct: "",
          commission: "",
          commissionPct: "",
        },
      ];

      this.chartData = {};
      this.traderName = "-";
      this.traderTitle = "-";
      this.approvalOver = "0";
      this.level = "-";
      this.supervisor = "-";
      this.value = "0";
      this.commission = "0.00";

      if (this.selectedMarket === "SET") {
        this.BR06();
      } else if (this.selectedMarket === "TFEX") {
        this.BR56();
      }
    },
    onEnter() {
      if (this.traderID.length > 4) {
        this.traderID = this.traderID.slice(0, 4);
      }
      this.sections = [{ key: this.traderID, content: this.traderID, active: true }];
      this.onValueStartRefresh();
    },
    filterChanged() {
      this.onValueStartRefresh();
    },
    BR06() {
      const msg = this.$messageFactory.createMessage("BR06");
      this.BRRefId = this.$shortid.generate();

      const trader = this.$_.find(this.traders, ["exchangeId", 1]);
      if (!trader?.traderCode) {
        this.$EventBus.$emit("onValueStopRefresh");
        this.$Swal.fire("Error", "User does not have SET account.", "error");
        return;
      }
      msg.ownTid.set(trader.traderCode);

      if (!this.traderID) {
        this.traderID = trader.traderCode;
        this.sections = [{ key: this.traderID, content: this.traderID, active: true }];
      }
      msg.reqTid.set(this.traderID);
      msg.recordCount.set(0);
      msg.lastRecordId.set(0);

      this.$ws.send(msg, this.BRRefId);

      if (this.gridGroupValueBySideApi) {
        this.gridGroupValueBySideApi.showLoadingOverlay();
      }
      if (this.gridGroupValueByTraderApi) {
        this.gridGroupValueByTraderApi.showLoadingOverlay();
      }
    },
    BR56() {
      const msg = this.$messageFactory.createMessage("BR56");
      this.BRRefId = this.$shortid.generate();

      const trader = this.$_.find(this.traders, ["exchangeId", 2]);
      if (!trader?.traderCode) {
        this.$EventBus.$emit("onValueStopRefresh");
        this.$Swal.fire("Error", "User does not have TFEX account.", "error");
        return;
      }
      msg.ownTid.set(trader.traderCode);

      if (!this.traderID) {
        this.traderID = trader.traderCode;
        this.sections = [{ key: this.traderID, content: this.traderID, active: true }];
      }
      msg.reqTid.set(this.traderID);
      msg.recordCount.set(0);
      msg.lastRecordId.set(0);

      this.$ws.send(msg, this.BRRefId);

      if (this.gridGroupValueBySideApi) {
        this.gridGroupValueBySideApi.showLoadingOverlay();
      }
      if (this.gridGroupValueByTraderApi) {
        this.gridGroupValueByTraderApi.showLoadingOverlay();
      }
    },
    updateRowGroupValueBySide(group) {
      if (this.BRRefId === group.refId) {
        if (group.resultCode === 1001) {
          this.chartData = group;
          this.traderName = group.name;
          this.traderTitle = group.title;
          this.approvalOver = this.$numeral(group.approvalOver).format("0,0");
          this.level = group.level;
          this.supervisor = group.supervisor || "-";
          this.value = this.$numeral(group.value).format("0,0");
          this.commission = this.$numeral(group.commission).format("0,0.00");

          if (group.sides?.length) {
            this.rowGroupValueBySide = group.sides.slice(0, 2);
            const lastIndexLength = group.sides.length - 1;
            this.pinnedBottomRowGroupValueBySide = [
              {
                side: "Total",
                vol: group.sides[lastIndexLength].vol,
                volBrkPct: group.sides[lastIndexLength].volBrkPct,
                val: group.sides[lastIndexLength].val,
                valBrkPct: group.sides[lastIndexLength].valBrkPct,
                commission: group.sides[lastIndexLength].commission,
                commissionBrkPct: group.sides[lastIndexLength].commissionBrkPct,
              },
            ];
          } else {
            this.chartData.sides = [];
            this.rowGroupValueBySide = [];
            this.pinnedBottomRowGroupValueBySide = [
              {
                side: "Total",
                vol: 0,
                volBrkPct: 0,
                val: 0,
                valBrkPct: 0,
                commission: 0,
                commissionBrkPct: 0,
              },
            ];
            this.$Swal.fire("Warning!", "This Trader ID have no group.", "warning");
          }

          if (group.traders?.length) {
            this.rowGroupValueByTrader = group.traders;
            this.pinnedBottomRowGroupValueByTrader = [
              {
                traderId: "Total",
                title: group.traders.length + " Group(s)",
                level: "",
                val: "",
                valPct: "",
                vol: "",
                volPct: "",
                commission: "",
                commissionPct: "",
              },
            ];
          } else {
            this.chartData.traders = [];
            this.rowGroupValueByTrader = [];
            this.pinnedBottomRowGroupValueByTrader = [
              {
                traderId: "Total",
                title: "0 Group(s)",
                level: "",
                val: "",
                valPct: "",
                vol: "",
                volPct: "",
                commission: "",
                commissionPct: "",
              },
            ];
          }
        } else {
          this.traderName = "-";
          this.traderTitle = "-";
          this.approvalOver = "0";
          this.level = "-";
          this.supervisor = "-";
          this.value = "0";
          this.commission = "0.00";

          this.chartData = {};
          this.chartData.sides = [];
          this.rowGroupValueBySide = [];
          this.chartData.traders = [];
          this.rowGroupValueByTrader = [];
          console.error(group.resultCode + ": " + group.resultMessage);
          this.$Swal.fire("Error", "Incorrect Trade ID.", "error");
        }
      }

      if (this.gridGroupValueBySideApi) {
        this.gridGroupValueBySideApi.hideOverlay();
      }

      if (this.gridGroupValueByTraderApi) {
        this.gridGroupValueByTraderApi.hideOverlay();
      }

      this.$EventBus.$emit("onValueStopRefresh");
    },
    onGroupValueBySideGridReady(params) {
      this.gridGroupValueBySideApi = params.api;
      this.columnGroupValueBySideApi = params.columnApi;
    },
    onGroupValueByTraderGridReady(params) {
      this.gridGroupValueByTraderApi = params.api;
      this.columnGroupValueByTraderApi = params.columnApi;
    },
    onCellGroupValueBySideFocused(event) {
      this.gridGroupValueBySideOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    onCellGroupValueByTraderFocused(event) {
      this.gridGroupValueByTraderOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    onRowByTraderDoubleClicked(event) {
      this.traderID = event.data.traderId;
      this.sections.forEach((section, index) => {
        this.sections[index].active = false;
        this.sections[index].link = true;
      });
      const index = this.$_.findLastIndex(this.sections, ["key", event.data.traderId]);
      if (index === -1) {
        this.sections.push({ key: event.data.traderId, content: event.data.traderId, active: true });
      } else {
        this.sections = this.sections.slice(0, index + 1);
        this.sections[index] = {
          key: event.data.traderId,
          content: event.data.traderId,
          active: true,
        };
      }
      this.filterChanged();
    },
    onBreadcrumbClicked(event) {
      if (event.target.text) {
        const index = this.$_.findLastIndex(this.sections, ["key", event.target.text]);
        this.sections = this.sections.slice(0, index + 1);
        this.sections.forEach((section, index) => {
          this.sections[index].active = false;
          this.sections[index].link = true;
        });
        this.sections[index] = {
          key: event.target.text,
          content: event.target.text,
          active: true,
        };
        this.traderID = event.target.text;
      }
      this.filterChanged();
    },
  },
  computed: {
    theme: sync("global/theme"),
    traders: get("model/systemRefData@traders"),
  },
  created() {
    this.gridGroupValueBySideOptions = {};
    this.gridGroupValueByTraderOptions = {};

    this.$EventBus.$on("ctinf/BS06", this.updateRowGroupValueBySide);
    this.$EventBus.$on("ctinf/BS56", this.updateRowGroupValueBySide);

    this.$EventBus.$on("onValueStartRefresh", this.onValueStartRefresh);
  },
  mounted() {
    const trader = this.$_.find(this.traders, ["exchangeId", 1]);

    if (trader?.traderCode) {
      this.traderID = trader.traderCode;
      this.sections = [{ key: trader.traderCode, content: trader.traderCode, active: true }];
      this.onValueStartRefresh();
    }
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/BS06", this.updateRowGroupValueBySide);
    this.$EventBus.$off("ctinf/BS56", this.updateRowGroupValueBySide);

    this.$EventBus.$off("onValueStartRefresh", this.onValueStartRefresh);
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
@import "~@/assets/styles/company/group-value.scss";
</style>
