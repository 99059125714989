import { render, staticRenderFns } from "./OrderApproveModal.vue?vue&type=template&id=382d66fd&"
import script from "./OrderApproveModal.vue?vue&type=script&lang=js&"
export * from "./OrderApproveModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports