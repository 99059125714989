<template>
  <div>
    <sui-modal v-model="dialog" size="small" class="ht-ptdeal-change-modal">
      <sui-modal-header>
        <span>Change Trade Report</span>
        <div class="close" @click="close"><i class="close grey icon" /></div>
      </sui-modal-header>
      <div style="clear: float" />
      <sui-modal-content ref="content">
        <div class="ui grid padded">
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0">Order No.:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0">{{ currentDeal.orderId }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 v-if="currentDeal.side === 'B'" class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0; color: #2185d0">
                Buy
              </h5>
              <h5
                v-else-if="currentDeal.side === 'S'"
                class="ui header inverted"
                style="display: inline-block; width: 8rem; margin: 0; color: #e03997"
              >
                Sell
              </h5>
              <h5 v-else class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0">Symbol</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0">{{ currentDeal.symbol }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0">Volume:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0">{{ currentDeal.volume }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0">Price:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0">
                {{ $numeral(currentDeal.price).format("0,0.000000") }}
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0">Total Amount:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0">
                {{ totalAmount }}
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0">Board:</h5>
              <h5 v-if="board === 'M'" class="ui header inverted" style="display: inline-block; margin: 0">Main Board</h5>
              <h5 v-else-if="board === 'B'" class="ui header inverted" style="display: inline-block; margin: 0">Big Lot Board</h5>
              <h5 v-else-if="board === 'F'" class="ui header inverted" style="display: inline-block; margin: 0">Foreign Board</h5>
              <h5 v-else-if="board === 'O'" class="ui header inverted" style="display: inline-block; margin: 0">Odd Lot Board</h5>
              <h5 v-else class="ui header inverted" style="display: inline-block; margin: 0">-</h5>
            </div>
          </div>
        </div>
        <br />
        <strong v-if="currentDeal.side === 'B'">Buy Information</strong>
        <strong v-if="currentDeal.side === 'S'">Sell Information</strong>
        <form class="ui form" style="margin-top: 0.5rem">
          <div class="three fields">
            <div class="field">
              <label>Account ID</label>
              <InputAccount
                ref="account"
                v-model="accountId"
                input-style="width: 12rem;"
                name="Account"
                placeholder="Account ID"
                is-validate
                required
                :sync="false"
                @enter="$refs.ttf.select()"
              />
            </div>
            <div class="field">
              <label>TTF</label>
              <TTFDropdown ref="ttf" v-model="ttf" style="width: 12rem" @enter="submit" />
            </div>
          </div>
        </form>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button type="button" positive :loading="processing" :disabled="processing" @click="submit">Confirm</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import InputAccount from "@/components/InputAccount";
import TTFDropdown from "@/components/TTFDropdown";

export default {
  components: {
    InputAccount,
    TTFDropdown,
  },
  data: () => ({
    dialog: false,
    processing: false,

    accountId: null,
    ttf: null,

    currentDeal: {},
    board: "",
    totalAmount: 0,
    securityInfoLatest: {},
    etsPTDealChangeRefId: null,
    inputsFilterable: ["InputAccount"],
  }),
  created() {
    this.$EventBus.$on("ptdealChange", this.onChangePTDeal);

    this.$EventBus.$on("ctinf/SS01", this.securityInfoUpdateCinf);

    //ptdeal change
    this.$EventBus.$on("ctord/DS05", this.etsPTDealChangeCtord);
  },
  beforeDestroy() {
    this.$EventBus.$off("ptdealChange", this.onChangePTDeal);

    this.$EventBus.$off("ctinf/SS01", this.securityInfoUpdateCinf);

    //ptdeal change
    this.$EventBus.$off("ctord/DS05", this.etsPTDealChangeCtord);
  },
  methods: {
    close() {
      this.dialog = false;
    },
    onChangePTDeal(deal) {
      this.dialog = true;
      this.currentDeal = deal;
      this.accountId = deal.accountCode;
      this.ttf = deal.ttf;
      this.calcBoard(deal);
    },
    calcBoard(deal) {
      const value = this.$numeral(deal.volume).value() * this.$numeral(deal.price).value();
      this.totalAmount = this.$numeral(value).format("0,0.000000");

      if (this.$numeral(deal.volume).value() >= 1000000 || value > 3000000) {
        this.board = "B";
      } else if (this.securityInfoLatest.securityType === "F") {
        this.board = "F";
      } else if (this.$numeral(deal.volume).value() >= Number(this.securityInfoLatest.boardLot)) {
        this.board = "M";
      } else if (value > 0) {
        this.board = "O";
      } else {
        this.board = "";
      }
    },
    securityInfoUpdateCinf(securityInfoUpdateCinf) {
      this.securityInfoLatest = Object.assign({}, securityInfoUpdateCinf);
    },
    DR05() {
      this.processing = true;

      const msg = this.$messageFactory.createMessage("DR05");

      msg.firm.set(this.currentDeal.firm);
      msg.orderNo.set(this.currentDeal.orderId);
      msg.traderId.set(this.currentDeal.traderId);
      msg.symbol.set(this.currentDeal.symbol);
      msg.side.set(this.currentDeal.side);
      msg.custId.set(this.accountId);
      msg.trustId.set(this.ttf);

      this.etsPTDealChangeRefId = this.$shortid.generate();
      this.$ws.send(msg, this.etsPTDealChangeRefId);
    },
    submit() {
      let validate = true;
      const filterErrors = this.$refs.content.$children.filter((c) => {
        if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
          if (c.isValidate) {
            const result = c.validate();
            validate &= result;
            return !result;
          } else {
            c.clearValidate();
          }
        }
        return false;
      });

      if (validate) {
        let side = "Symbol";
        if (this.currentDeal.side === "B") {
          side = "Buy";
        } else if (this.currentDeal.side === "S") {
          side = "Sell";
        }

        let board = "-";
        if (this.board === "M") {
          board = "Main Board";
        } else if (this.board === "B") {
          board = "Big Lot Board";
        } else if (this.board === "F") {
          board = "Foreign Board";
        } else if (this.board === "O") {
          board = "Odd Lot Board";
        }

        let sider = "Sell Information";
        if (this.currentDeal.side === "B") {
          sider = "Buy Information";
        }

        let ttf = "-";
        if (this.ttf === "1") {
          ttf = "TTF";
        } else if (this.ttf === "2") {
          ttf = "NVDR";
        }

        const html = `
          <div class="ui two column grid">
            <div class="right aligned column ht-${side.toLowerCase()}-label" style="padding-right: 1rem !important;">${side}:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.currentDeal.symbol}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Volume:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.currentDeal.volume}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Price:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.$numeral(this.currentDeal.price).format(
              "0,0.000000"
            )}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Total Amount:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.totalAmount}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Board:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${board}</div>
            <br><br>
            <div class="right aligned column" style="padding-right: 1rem !important;">Change:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;"><b>${sider}</b></div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Account ID:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.accountId || "-"}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">TTF:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${ttf}</div>
          </div>
        `;

        this.$Swal
          .fire({
            title: "Change Trade Report Confirmation",
            html: html,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              this.DR05();
            }
          });
      } else {
        filterErrors[0].select();
      }
    },
    etsPTDealChangeCtord(data) {
      if (this.etsPTDealChangeRefId !== data.refId) {
        return;
      }
      this.processing = false;

      if (data.resultCode === 1001) {
        this.clearForm();
        this.clearValidate();
        this.close();
        this.$Swal.fire(`Complete`, `Your Trade Report number ${data.orderId} has been changed.`, "success");
      } else {
        this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error");
      }
    },
    clearForm() {
      this.accountId = null;
      this.ttf = null;
      this.currentDeal = {};
      this.board = "";
      this.totalAmount = 0;
    },
    clearValidate() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.content.$children.forEach((c) => {
            if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
              c.clearValidate();
            }
          });
        }, 10);
      });
    },
  },
};
</script>

<style>
.ht-ptdeal-change-modal h5 {
  line-height: 2rem !important;
}
.ht-ptdeal-change-modal .ui.inverted.header {
  color: #dcddde;
}

.ht-buy-label {
  color: #2185d0;
}

.ht-sell-label {
  color: #e03997;
}
</style>
