import { totalColorStyle, ceilColorStyle } from "../Formatter.js";

const columnDefsMarketMessage = [
  {
    headerName: "Time",
    field: "time",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    lockPosition: true,
    width: 80,
    cellStyle: () => ({ ...totalColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Message",
    field: "message",
    lockPosition: true,
    valueFormatter: params => params.value.replace("\u0002", "[").replace("\u0003", "]"),
    width: 1000,
    minWidth: 385,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  }
];

export { columnDefsMarketMessage };
