import { ref, watch, getCurrentInstance } from "vue";

export default function useBBO(props) {
  const vm = getCurrentInstance().proxy;

  const selectedSymbol = ref(null);
  const isShowAllBBO = ref(false);

  watch(
    () => props.value,
    (updatedValue) => {
      if (updatedValue && Object.keys(updatedValue).length !== 0) {
        selectedSymbol.value = updatedValue;
      }
    },
    { immediate: true }
  );

  watch(
    () => props.isShowAll,
    (updatedValue) => {
      isShowAllBBO.value = updatedValue || false;
    },
    { immediate: true }
  );

  watch(selectedSymbol, () => {
    vm.$emit("input", { symbol: selectedSymbol.value, isShowAllBBO: isShowAllBBO.value });
    vm.$EventBus.$emit("onBBOsSymbolSelected", selectedSymbol.value);
  });

  return { selectedSymbol };
}
