/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class FS03 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._conditions = new ArrayMessageBase(Condition);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get conditions() {
		return this._conditions;
	}
}

export class Condition {
	constructor() {
		this._alertId = new StringMessageBase();
		this._userId = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._conditionType = new StringMessageBase();
		this._conditionValue = new StringMessageBase();
	}
	
	get alertId() {
		return this._alertId;
	}
	
	get userId() {
		return this._userId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get conditionType() {
		return this._conditionType;
	}
	
	get conditionValue() {
		return this._conditionValue;
	}
}

export default FS03;
