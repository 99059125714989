/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class CR54 extends WsReqMsg {
	constructor() {
		super(1);
		this._reqTid = new StringMessageBase();
	}
	
	get reqTid() {
		return this._reqTid;
	}
	
	set reqTid(reqTid) {
		this._reqTid = new StringMessageBase(reqTid);
	}
}

export default CR54;
