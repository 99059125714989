/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class CS02 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._records = new ArrayMessageBase(Record);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get records() {
		return this._records;
	}
}

export class Record {
	constructor() {
		this._crdtBalRec = new NumberMessageBase();
		this._signBit = new NumberMessageBase();
		this._markEE = new NumberMessageBase();
		this._equity = new NumberMessageBase();
		this._curMR = new NumberMessageBase();
		this._curCall = new NumberMessageBase();
		this._curForce = new NumberMessageBase();
		this._prvCall = new NumberMessageBase();
		this._prvForce = new NumberMessageBase();
		this._buyLmt = new NumberMessageBase();
		this._MMPct = new NumberMessageBase();
		this._custId = new StringMessageBase();
		this._mobile = new StringMessageBase();
		this._custName = new StringMessageBase();
		this._custThaiName = new StringMessageBase();
	}
	
	get crdtBalRec() {
		return this._crdtBalRec;
	}
	
	get signBit() {
		return this._signBit;
	}
	
	get markEE() {
		return this._markEE;
	}
	
	get equity() {
		return this._equity;
	}
	
	get curMR() {
		return this._curMR;
	}
	
	get curCall() {
		return this._curCall;
	}
	
	get curForce() {
		return this._curForce;
	}
	
	get prvCall() {
		return this._prvCall;
	}
	
	get prvForce() {
		return this._prvForce;
	}
	
	get buyLmt() {
		return this._buyLmt;
	}
	
	get MMPct() {
		return this._MMPct;
	}
	
	get custId() {
		return this._custId;
	}
	
	get mobile() {
		return this._mobile;
	}
	
	get custName() {
		return this._custName;
	}
	
	get custThaiName() {
		return this._custThaiName;
	}
}

export default CS02;
