<template>
  <div class="ui equal width form" style="padding: 0.5rem; height: calc(25vh - 32px - 5px); overflow: auto;">
    <div class="fields">
      <table class="ui small very compact inverted table">
        <thead>
          <tr>
            <th style="background-color: #262D33;" id="blank" />
            <th class="right aligned" style="background-color: #262D33;" id="previous">
              <h5 class="ui grey header inverted">Previous</h5>
            </th>
            <th class="right aligned" style="background-color: #262D33;" id="current">
              <h5 class="ui grey header inverted">Current</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><h5 class="ui inverted grey header">Cash Balance</h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.prevCashBalance }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.curCashBalance }}
              </h5>
            </td>
          </tr>
          <tr>
            <td><h5 class="ui inverted grey header">LMV</h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.prevLMV }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.curLMV }}
              </h5>
            </td>
          </tr>
          <tr>
            <td><h5 class="ui inverted grey header">Collateral</h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.prevCollateral }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.curCollateral }}
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  props: ["onShow"],
  data: () => ({
    accountCreditBalance: {
      prevCashBalance: "",
      curCashBalance: "",
      prevLMV: "",
      curLMV: "",
      prevCollateral: "",
      curCollateral: ""
    }
  }),
  watch: {
    currentSelectedAccount() {
      if (!this.onShow) return;

      this.AR03();
    }
  },
  created() {
    this.AR03();

    this.$EventBus.$on("ctinf/AS03", this.etsAccountCreditBalanceCinf);

    this.$EventBus.$on("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS03", this.etsAccountCreditBalanceCinf);

    this.$EventBus.$off("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
  },
  methods: {
    onPortfolioInfoStartRefresh() {
      if (!this.onShow) return;

      this.AR03();
    },
    AR03() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount.exchangeId == 0) {
        this.accountCreditBalance = {
          prevCashBalance: "",
          curCashBalance: "",
          prevLMV: "",
          curLMV: "",
          prevCollateral: "",
          curCollateral: ""
        };
        this.$EventBus.$emit("onPortfolioInfoStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("AR03");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);

      this.$ws.send(msg);
    },
    etsAccountCreditBalanceCinf(info) {
      if (!this.onShow) return;

      this.accountCreditBalance = { ...this.accountCreditBalance, ...info };
      this.$EventBus.$emit("onPortfolioInfoStopRefresh");
    }
  },
  computed: {
    currentSelectedAccount: get("global/currentSelectedAccount")
  }
};
</script>

<style scoped>
.fields {
  margin: 0.25rem !important;
}

th.right.aligned {
  padding-top: 0.25rem !important;
  padding-bottom: 0.5rem !important;
}
</style>
