<template>
  <div class="ui input">
    <InputNumber
      v-if="params.type === 'number'"
      :ref="'input'"
      v-model="displayValue"
      :name="params.column.colDef.headerName + ' Filter'"
      @enter="onEnter"
    />
    <InputText
      v-else
      :ref="'input'"
      v-model="displayValue"
      :name="params.column.colDef.headerName + ' Filter'"
      :uppercase="uppercase"
      @enter="onEnter"
    />
  </div>
</template>

<script>
import InputText from "@/components/InputText";
import InputNumber from "@/components/InputNumber";
export default {
  components: {
    InputText,
    InputNumber
  },
  data() {
    return {
      displayValue: null,
      uppercase: false
    };
  },
  created() {
    this.uppercase = this.params.uppercase;
  },
  methods: {
    onEnter() {
      let valueToUse = !this.displayValue ? null : this.displayValue;
      const filters = this.params.api.getFilterModel();
      const oldFilter = filters[this.params.filterParams.colDef.field];
      if (oldFilter && oldFilter.filter === valueToUse) {
        return;
      } else {
        if (!valueToUse && !oldFilter) {
          return;
        }
      }
      this.params.filterParams.context.resetFilter = true;
      this.params.api.deselectAll();
      this.params.api.purgeInfiniteCache();
      this.params.parentFilterInstance(instance => {
        instance.onFloatingFilterChanged("equals", valueToUse);
      });
    },
    onParentModelChanged(parentModel) {
      this.displayValue = !parentModel ? "" : parentModel.filter;
    },
    buildModel() {
      if (!this.displayValue) {
        return null;
      }
      return {
        filterType: "text",
        type: "equals",
        filter: this.displayValue,
        filterTo: null
      };
    }
  }
};
</script>
