const values = {
  S: "VPort",
  A: "AVPort"
};

const getMarginTypeByKey = key => {
  return values[key] || key;
};

export { getMarginTypeByKey };
