<template>
  <button ref="tfex-dropdown" @click="onOpenDropdown" @blur="onBlur" class="tfex-dropdown">
    <div>{{ TFEXMarketNameFormatter(selectedValue) }}</div>
    <i class="caret down icon" />
  </button>
</template>

<script setup>
import { getCurrentInstance, ref, onBeforeMount, watch } from "vue";

const props = defineProps({ options: { type: Array, default: [] }, selectValue: { type: String, default: "TXI" } });
const emits = defineEmits(["select-item"]);

const vm = getCurrentInstance().proxy;
const selectedValue = ref(props.selectValue);
const uid = vm._uid;

const TFEXMarketNameFormatter = (name) => {
  switch (name) {
    case "TXI":
      return "Equity Index";
    case "TXS":
      return "Single Stock";
    case "TXR":
      return "Interest Rate";
    case "TXM":
      return "Metal";
    case "TXE":
      return "Energy";
    case "TXC":
      return "Currency";
    case "TXA":
      return "Aqricultural";
    case "TXD":
      return "Deferred";
    default:
      return name;
  }
};

const onOpenDropdown = () => {
  const rect = vm.$refs["tfex-dropdown"].getBoundingClientRect();

  vm.$EventBus.$emit("dropdown", {
    inputId: uid,
    items: props.options,
    placeholder: "",
    freeText: "",
    keyText: "name",
    keyValue: "name",
    rect: rect,
    callbackData: onSelectItem,
    isSymbol: null,
    forceWidth: null,
    forceUpdateOptions: false,
    scroller: false,
    selectableCallback: null,
    displayFormatter: TFEXMarketNameFormatter,
  });

  vm.$EventBus.$emit("opendropdown", rect);
};

const onSelectItem = (data) => {
  selectedValue.value = data.name;
  emits("select-item", data.name);
  vm.$EventBus.$emit("closedropdown");
};

const onBlur = () => {
  setTimeout(() => {
    vm.$EventBus.$emit("closedropdown");
  }, 120);
};

const initDropdown = () => {
  const selectedOption = props.options.find((option) => option.name === selectedValue);

  if (selectedOption) {
    selectedValue.value = selectedOption.name;
    return;
  }

  selectedValue.value = props.selectValue;
  return;
};

watch([() => props.options, () => props.selectValue], () => {
  initDropdown();
});

onBeforeMount(() => {
  initDropdown();
});
</script>

<style lang="scss" scoped>
.tfex-dropdown {
  display: flex;
  align-items: center;
  font-size: 1.07rem;
  font-weight: 700;
  color: #dcddde;
  padding: 0;
  border: 1px solid transparent;
  background-color: transparent;
  column-gap: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  .icon {
    color: #dcddde;
  }
}
</style>
