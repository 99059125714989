<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    inputUpperCase: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: String,
      default: "startsWith",
    },
    selectableCallback: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      style: "width: 100%;",
      defaultValue: "",
      displayValue: "",
      options: [
        {
          text: "-",
          value: "",
        },
        {
          text: "FOK",
          value: "F",
        },
        {
          text: "IOC",
          value: "I",
        },
      ],
    };
  },
};
</script>
