import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle
} from "../Formatter.js";

const columnDefsAdvertisement = [
  {
    headerName: "Time",
    field: "time",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 100,
    cellStyle: params => ({ ...totalColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Symbol",
    field: "symbol",
    pinned: "left",
    width: 200,
    cellStyle: params => ({ ...totalColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Side",
    field: "side",
    width: 65,
    cellStyle: sideColorStyle
  },
  {
    headerName: "Volume",
    field: "volume",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Price",
    field: "price",
    width: 200,
    valueFormatter: params => numeralCellFormatter(params, "0,0.000000"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Status",
    field: "status",
    width: 120,
    cellStyle: params => ({ ...ceilColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Member",
    field: "member",
    width: 120,
    cellStyle: params => ({ ...ceilColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Contact Information",
    field: "contactInfo",
    width: 200,
    cellStyle: params => ({ ...ceilColorStyle(params), "justify-content": "flex-start" })
  }
];

export { columnDefsAdvertisement };
