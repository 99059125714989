/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import AR01 from "./AR01";
import AR02 from "./AR02";
import AR03 from "./AR03";
import AR04 from "./AR04";
import AR05 from "./AR05";
import AR06 from "./AR06";
import AR07 from "./AR07";
import AR53 from "./AR53";
import AR55 from "./AR55";
import AS01 from "./AS01";
import AS02 from "./AS02";
import AS03 from "./AS03";
import AS04 from "./AS04";
import AS05 from "./AS05";
import AS06 from "./AS06";
import AS07 from "./AS07";
import AS51 from "./AS51";
import AS52 from "./AS52";
import AS53 from "./AS53";
import AS54 from "./AS54";
import AS55 from "./AS55";
import AS57 from "./AS57";
import BR01 from "./BR01";
import BR02 from "./BR02";
import BR03 from "./BR03";
import BR04 from "./BR04";
import BR05 from "./BR05";
import BR06 from "./BR06";
import BR51 from "./BR51";
import BR52 from "./BR52";
import BR53 from "./BR53";
import BR54 from "./BR54";
import BR55 from "./BR55";
import BR56 from "./BR56";
import BS01 from "./BS01";
import BS02 from "./BS02";
import BS03 from "./BS03";
import BS04 from "./BS04";
import BS05 from "./BS05";
import BS06 from "./BS06";
import BS51 from "./BS51";
import BS52 from "./BS52";
import BS53 from "./BS53";
import BS54 from "./BS54";
import BS55 from "./BS55";
import BS56 from "./BS56";
import CR01 from "./CR01";
import CR02 from "./CR02";
import CR03 from "./CR03";
import CR04 from "./CR04";
import CR51 from "./CR51";
import CR52 from "./CR52";
import CR53 from "./CR53";
import CR54 from "./CR54";
import CS01 from "./CS01";
import CS02 from "./CS02";
import CS03 from "./CS03";
import CS04 from "./CS04";
import CS51 from "./CS51";
import CS52 from "./CS52";
import CS53 from "./CS53";
import CS54 from "./CS54";
import DR01 from "./DR01";
import DR02 from "./DR02";
import DR03 from "./DR03";
import DR04 from "./DR04";
import DR05 from "./DR05";
import DR06 from "./DR06";
import DR51 from "./DR51";
import DR52 from "./DR52";
import DR53 from "./DR53";
import DR54 from "./DR54";
import DS01 from "./DS01";
import DS02 from "./DS02";
import DS03 from "./DS03";
import DS04 from "./DS04";
import DS05 from "./DS05";
import DS06 from "./DS06";
import DS51 from "./DS51";
import DS52 from "./DS52";
import DS53 from "./DS53";
import DS54 from "./DS54";
import FR01 from "./FR01";
import FR02 from "./FR02";
import FR03 from "./FR03";
import FR04 from "./FR04";
import FR05 from "./FR05";
import FR06 from "./FR06";
import FS01 from "./FS01";
import FS02 from "./FS02";
import FS03 from "./FS03";
import FS04 from "./FS04";
import FS05 from "./FS05";
import FS06 from "./FS06";
import IB01 from "./IB01";
import IB51 from "./IB51";
import IR01 from "./IR01";
import IR02 from "./IR02";
import IR03 from "./IR03";
import IS01 from "./IS01";
import IS02 from "./IS02";
import IS03 from "./IS03";
import MB01 from "./MB01";
import MB02 from "./MB02";
import MB03 from "./MB03";
import MB51 from "./MB51";
import MB52 from "./MB52";
import MR01 from "./MR01";
import MS01 from "./MS01";
import NB01 from "./NB01";
import NR01 from "./NR01";
import NR02 from "./NR02";
import NS01 from "./NS01";
import NS02 from "./NS02";
import ON01 from "./ON01";
import ON02 from "./ON02";
import ON03 from "./ON03";
import ON04 from "./ON04";
import ON05 from "./ON05";
import ON06 from "./ON06";
import ON07 from "./ON07";
import ON08 from "./ON08";
import ON09 from "./ON09";
import ON49 from "./ON49";
import ON51 from "./ON51";
import ON52 from "./ON52";
import ON53 from "./ON53";
import ON54 from "./ON54";
import ON55 from "./ON55";
import ON56 from "./ON56";
import ON57 from "./ON57";
import ON58 from "./ON58";
import ON59 from "./ON59";
import OR01 from "./OR01";
import OR02 from "./OR02";
import OR03 from "./OR03";
import OR04 from "./OR04";
import OR05 from "./OR05";
import OR06 from "./OR06";
import OR07 from "./OR07";
import OR08 from "./OR08";
import OR09 from "./OR09";
import OR51 from "./OR51";
import OR52 from "./OR52";
import OR53 from "./OR53";
import OR54 from "./OR54";
import OR55 from "./OR55";
import OR56 from "./OR56";
import OR58 from "./OR58";
import OS01 from "./OS01";
import OS02 from "./OS02";
import OS03 from "./OS03";
import OS04 from "./OS04";
import OS05 from "./OS05";
import OS06 from "./OS06";
import OS07 from "./OS07";
import OS08 from "./OS08";
import OS09 from "./OS09";
import OS51 from "./OS51";
import OS52 from "./OS52";
import OS53 from "./OS53";
import OS54 from "./OS54";
import OS55 from "./OS55";
import OS56 from "./OS56";
import OS58 from "./OS58";
import PB01 from "./PB01";
import PB03 from "./PB03";
import PB04 from "./PB04";
import PB21 from "./PB21";
import PB22 from "./PB22";
import PB51 from "./PB51";
import PB52 from "./PB52";
import PR01 from "./PR01";
import PR51 from "./PR51";
import PS01 from "./PS01";
import PS51 from "./PS51";
import RB01 from "./RB01";
import SB51 from "./SB51";
import SR01 from "./SR01";
import SR02 from "./SR02";
import SR03 from "./SR03";
import SR04 from "./SR04";
import SR05 from "./SR05";
import SR06 from "./SR06";
import SR07 from "./SR07";
import SR08 from "./SR08";
import SR09 from "./SR09";
import SR10 from "./SR10";
import SS01 from "./SS01";
import SS02 from "./SS02";
import SS03 from "./SS03";
import SS04 from "./SS04";
import SS05 from "./SS05";
import SS06 from "./SS06";
import SS07 from "./SS07";
import SS08 from "./SS08";
import SS09 from "./SS09";
import SS10 from "./SS10";
import SS51 from "./SS51";
import UR01 from "./UR01";
import UR02 from "./UR02";
import UR03 from "./UR03";
import UR04 from "./UR04";
import UR05 from "./UR05";
import UR06 from "./UR06";
import US01 from "./US01";
import US02 from "./US02";
import US03 from "./US03";
import US04 from "./US04";
import US05 from "./US05";
import US06 from "./US06";
import VR01 from "./VR01";
import VS01 from "./VS01";
import XB01 from "./XB01";
import XN01 from "./XN01";
import XN51 from "./XN51";
import XR01 from "./XR01";
import XR02 from "./XR02";
import XR03 from "./XR03";
import XR04 from "./XR04";
import XS01 from "./XS01";
import XS02 from "./XS02";
import XS03 from "./XS03";
import XS04 from "./XS04";

const classes = {
	AR01,
	AR02,
	AR03,
	AR04,
	AR05,
	AR06,
	AR07,
	AR53,
	AR55,
	AS01,
	AS02,
	AS03,
	AS04,
	AS05,
	AS06,
	AS07,
	AS51,
	AS52,
	AS53,
	AS54,
	AS55,
	AS57,
	BR01,
	BR02,
	BR03,
	BR04,
	BR05,
	BR06,
	BR51,
	BR52,
	BR53,
	BR54,
	BR55,
	BR56,
	BS01,
	BS02,
	BS03,
	BS04,
	BS05,
	BS06,
	BS51,
	BS52,
	BS53,
	BS54,
	BS55,
	BS56,
	CR01,
	CR02,
	CR03,
	CR04,
	CR51,
	CR52,
	CR53,
	CR54,
	CS01,
	CS02,
	CS03,
	CS04,
	CS51,
	CS52,
	CS53,
	CS54,
	DR01,
	DR02,
	DR03,
	DR04,
	DR05,
	DR06,
	DR51,
	DR52,
	DR53,
	DR54,
	DS01,
	DS02,
	DS03,
	DS04,
	DS05,
	DS06,
	DS51,
	DS52,
	DS53,
	DS54,
	FR01,
	FR02,
	FR03,
	FR04,
	FR05,
	FR06,
	FS01,
	FS02,
	FS03,
	FS04,
	FS05,
	FS06,
	IB01,
	IB51,
	IR01,
	IR02,
	IR03,
	IS01,
	IS02,
	IS03,
	MB01,
	MB02,
	MB03,
	MB51,
	MB52,
	MR01,
	MS01,
	NB01,
	NR01,
	NR02,
	NS01,
	NS02,
	ON01,
	ON02,
	ON03,
	ON04,
	ON05,
	ON06,
	ON07,
	ON08,
	ON09,
	ON49,
	ON51,
	ON52,
	ON53,
	ON54,
	ON55,
	ON56,
	ON57,
	ON58,
	ON59,
	OR01,
	OR02,
	OR03,
	OR04,
	OR05,
	OR06,
	OR07,
	OR08,
	OR09,
	OR51,
	OR52,
	OR53,
	OR54,
	OR55,
	OR56,
	OR58,
	OS01,
	OS02,
	OS03,
	OS04,
	OS05,
	OS06,
	OS07,
	OS08,
	OS09,
	OS51,
	OS52,
	OS53,
	OS54,
	OS55,
	OS56,
	OS58,
	PB01,
	PB03,
	PB04,
	PB21,
	PB22,
	PB51,
	PB52,
	PR01,
	PR51,
	PS01,
	PS51,
	RB01,
	SB51,
	SR01,
	SR02,
	SR03,
	SR04,
	SR05,
	SR06,
	SR07,
	SR08,
	SR09,
	SR10,
	SS01,
	SS02,
	SS03,
	SS04,
	SS05,
	SS06,
	SS07,
	SS08,
	SS09,
	SS10,
	SS51,
	UR01,
	UR02,
	UR03,
	UR04,
	UR05,
	UR06,
	US01,
	US02,
	US03,
	US04,
	US05,
	US06,
	VR01,
	VS01,
	XB01,
	XN01,
	XN51,
	XR01,
	XR02,
	XR03,
	XR04,
	XS01,
	XS02,
	XS03,
	XS04
};

class MessageFactory {
  createMessage(className) {
    let obj = new classes[className]();
    obj.code.set(className);
    return obj;
  }
}

export default new MessageFactory();
