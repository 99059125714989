/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class US01 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._favoriteId = new StringMessageBase();
		this._updateType = new StringMessageBase();
		this._favorites = new ArrayMessageBase(Favorite);
		this._favoriteName = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get favoriteId() {
		return this._favoriteId;
	}
	
	get updateType() {
		return this._updateType;
	}
	
	get favorites() {
		return this._favorites;
	}
	
	get favoriteName() {
		return this._favoriteName;
	}
}

export class Favorite {
	constructor() {
		this._favoriteId = new NumberMessageBase();
		this._name = new StringMessageBase();
	}
	
	get favoriteId() {
		return this._favoriteId;
	}
	
	get name() {
		return this._name;
	}
}

export default US01;
