<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    value: {
      type: String
    },
    filterable: {
      type: String,
      default: "startsWith"
    }
  },
  data() {
    return {
      style: "width: 12rem; min-width: 12rem;",
      defaultValue: "O",
      options: [
        {
          text: "Open",
          value: "O"
        },
        {
          text: "Close",
          value: "C"
        }
      ]
    };
  }
};
</script>
