<template>
  <div>
    <sui-modal v-model="dialog" size="large">
      <sui-modal-header>
        <span>Cancel Trade Report</span>
        <div class="close" @click="close"><i class="close grey icon"></i></div>
      </sui-modal-header>
      <div style="clear: float;"></div>
      <sui-modal-content>
        <div style="height: 25vh;">
          <ag-grid-vue
            style="width: 100%; height: 100%; padding: 0px;"
            :class="{
              'ag-theme-balham-dark': theme === 'Dark',
              'ag-theme-balham': theme === 'Light'
            }"
            :column-defs="ColumnDefs"
            :row-data="data"
            :row-height="21"
            :header-height="32"
            :default-col-def="{ resizable: true, sortable: true }"
            :animate-rows="true"
            :suppress-drag-leave-hides-columns="false"
            :locale-text="{ noRowsToShow: `Don't have any Trade Report.` }"
            @grid-ready="onGridReady"
          />
        </div>
      </sui-modal-content>
      <sui-modal-actions>
        <form @submit.prevent="confirm">
          <sui-button type="submit" negative :loading="processing" :disabled="processing">Confirm</sui-button>
        </form>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import ColumnDefs from "@/components/grid/ptdealCancel/ColumnDefs";

import { parseStringEtsDateToEtsDate } from "@/utils/DateUtils";
export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      ColumnDefs,
      processing: false,
      dialog: false,
      data: [],
      gridApi: null,
      columnApi: null,
      refIds: [],
      successReponses: [],
      errorReponses: []
    };
  },
  created() {
    this.$EventBus.$on("ptdealCancel", this.onPTDealCancel);

    this.$EventBus.$on("ctord/DS04", this.etsPTDealCancelCtord);
    this.$EventBus.$on("ctord/DS54", this.etsPTDealCancelCtord);
  },
  beforeDestroy() {
    this.$EventBus.$off("ptdealCancel", this.onPTDealCancel);

    this.$EventBus.$off("ctord/DS04", this.etsPTDealCancelCtord);
    this.$EventBus.$off("ctord/DS54", this.etsPTDealCancelCtord);
  },
  methods: {
    onPTDealCancel(rows) {
      this.processing = false;
      this.refIds = [];
      this.successReponses = [];
      this.errorReponses = [];
      this.dialog = true;
      this.data = rows;
    },
    close() {
      this.dialog = false;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    confirm() {
      this.processing = true;

      this.data.forEach(d => {
        if (d.exchangeId == 1) {
          this.DR04(d);
        } else if (d.exchangeId == 2) {
          this.DR54(d);
        }
      });
    },
    DR04(data) {
      const msg = this.$messageFactory.createMessage("DR04");

      msg.firm.set(data.firm);
      msg.orderNo.set(data.orderId);
      msg.traderId.set(data.traderId);
      msg.symbol.set(data.symbol);
      msg.side.set(data.side);

      const refId = this.$shortid.generate();
      this.refIds.push(refId);
      this.$ws.send(msg, refId);
    },
    DR54(data) {
      const msg = this.$messageFactory.createMessage("DR54");

      const newSendDate = this.$moment(data.sendDate, "DD/MM/YYYY").format("YYYYMMDD");

      msg.traderId.set(data.traderCode);
      msg.symbol.set(data.symbol);
      msg.side.set(data.side);
      msg.positionType.set(data.positionType);
      msg.vol.set(this.$numeral(data.volume).value());
      msg.price.set(this.$numeral(data.price).value());
      msg.accountCode.set(data.accountCode);
      msg.extOrdNo.set(data.orderId);
      msg.extOrdDate.set(newSendDate);

      const refId = this.$shortid.generate();
      this.refIds.push(refId);
      this.$ws.send(msg, refId);
    },
    cancelFinish() {
      const type = this.errorReponses.length > 0 ? "error" : "success";
      if (type === "error") {
        const code = this.errorReponses[0].resultCode;
        if (code === 2002) {
          this.$Swal.fire({
            icon: type,
            text: this.errorReponses[0].resultMessage,
            title: `Error ${code}!`,
            onClose: () => {
              this.processing = false;
            }
          });
          return;
        }
      }

      let html = "";
      if (this.successReponses.length > 0) {
        html += `<h3 class="ui green header">Success ${this.successReponses.length} order(s).</h3>`;
        html += `<div class="ui two column grid">`;
        this.successReponses.forEach(r => {
          html += `<div class="column">
            <div class="ui two column grid">
              <div class="column">${r.orderId}</div>
              <div class="column">${r.sendDate}</div>
            </div>
          </div>
          <div class="column" style="text-align: left; color: #00aa00;">Success</div>`;
        });
        html += "</div>";
      }
      if (this.errorReponses.length > 0) {
        html += `<h3 class="ui red header">Error ${this.errorReponses.length} order(s).</h3>`;
        html += `<div class="ui two column grid">`;
        this.errorReponses.forEach(r => {
          html += `<div class="column">
            <div class="ui two column grid">
              <div class="column">${r.orderId}</div>
              <div class="column">${r.sendDate}</div>
            </div>
          </div>
          <div class="column" style="text-align: left; color: #d91e18">${r.resultCode}: ${r.resultMessage}</div>`;
        });
        html += "</div>";
      }
      this.$Swal.fire({
        icon: type,
        html: html,
        onClose: () => {
          this.processing = false;
          this.dialog = false;
          this.$EventBus.$emit("ptdealCancelled");
        }
      });
    },
    etsPTDealCancelCtord(data) {
      const index = this.$_.indexOf(this.refIds, data.refId);
      if (index !== -1) {
        this.refIds.splice(index, 1);
        if (data.resultCode === 1001) {
          this.successReponses.push(data);
        } else {
          this.errorReponses.push(data);
        }

        if (this.refIds.length === 0) {
          // finish
          this.cancelFinish();
        }
      }
    }
  },
  computed: {
    theme: sync("global/theme")
  }
};
</script>
