<template>
  <sui-tab :menu="{ attached: 'top' }" class="quote-table">
    <sui-tab-pane title="Ticker" attached="top" :style="{ height: 'calc(100% - 32px)' }">
      <div class="quote-table-tab">
        <QuoteTicker :ticker-full-page-height="tickerFullPageHeight" />
      </div>
    </sui-tab-pane>
    <sui-tab-pane title="Sale by Time" attached="top" :style="{ height: 'calc(100% - 32px)' }">
      <QuoteSaleByTime />
    </sui-tab-pane>
    <sui-tab-pane title="Sale by Price" attached="top" :style="{ height: 'calc(100% - 32px)' }">
      <QuoteSaleByPrice />
    </sui-tab-pane>
    <sui-tab-pane title="Sale by Date" attached="top" :style="{ height: 'calc(100% - 32px)' }">
      <QuoteSaleByDate />
    </sui-tab-pane>
    <sui-tab-pane
      :title="
        currentQuoteSelectedSymbol && currentQuoteSelectedSymbol.value && currentQuoteSelectedSymbol.value.exchangeId === 2
          ? 'Series In Play'
          : 'Stock In Play'
      "
      :style="{ height: 'calc(100% - 32px)' }"
      attached="top"
    >
      <QuoteStockInPlay />
    </sui-tab-pane>
    <sui-tab-pane title="News" attached="top" :style="{ height: 'calc(100% - 32px)' }">
      <QuoteNews />
    </sui-tab-pane>
    <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
      <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
    </div>
  </sui-tab>
</template>

<script>
import { get, sync } from "vuex-pathify";

import QuoteTicker from "@/components/quote/QuoteTicker";
import QuoteSaleByTime from "@/components/quote/QuoteSaleByTime";
import QuoteSaleByPrice from "@/components/quote/QuoteSaleByPrice";
import QuoteSaleByDate from "@/components/quote/QuoteSaleByDate";
import QuoteNews from "@/components/quote/QuoteNews";
import QuoteStockInPlay from "@/components/quote/QuoteStockInPlay";

export default {
  name: "QuoteTable",
  components: {
    QuoteTicker,
    QuoteSaleByTime,
    QuoteSaleByPrice,
    QuoteSaleByDate,
    QuoteNews,
    QuoteStockInPlay,
  },
  data: () => ({
    isLoading: true,
    tickerFullPageHeight: 0,
  }),
  created() {
    this.reCalculateTickerFullPageHeight();
    this.$EventBus.$on("onResize", this.reCalculateTickerFullPageHeight);

    this.$EventBus.$on("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);
    this.$EventBus.$on("onQuoteTableStopRefresh", this.onQuoteTableStopRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("onResize", this.reCalculateTickerFullPageHeight);

    this.$EventBus.$off("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);
    this.$EventBus.$off("onQuoteTableStopRefresh", this.onQuoteTableStopRefresh);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onQuoteTableStartRefresh");
    },
    onQuoteTableStartRefresh() {
      this.isLoading = true;
    },
    onQuoteTableStopRefresh() {
      this.isLoading = false;
    },
    reCalculateTickerFullPageHeight() {
      this.$nextTick((e) => {
        this.tickerFullPageHeight = this.$vhToPx(55) - 144 - 32 - 5;
      });
    },
  },
  computed: {
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
  },
};
</script>

<style scoped>
.ticker {
  background-color: rgb(38, 45, 51) !important;
}

.ht-border {
  border-style: solid;
  border-color: black;
}

.quote-table {
  height: 100%;
}

.quote-table .quote-table-tab {
  height: 100%;
  overflow: auto !important;
}

.quote-table :deep(.ui.top.attached.menu) {
  margin: 0;
}

.quote-table :deep(.ui.tab.segment.attached.active.top) {
  margin: 0;
}
</style>
