import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  priceColorStyle,
} from "../Formatter.js";

const columnDefsQuoteSaleByPrice = [
  {
    headerName: "#Buy Deals",
    field: "buyCount",
    width: 120,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Buy Volume",
    field: "buyVolume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Price",
    field: "price",
    width: 120,
    cellStyle: priceColorStyle,
  },
  {
    headerName: "Sell Volume",
    field: "sellVolume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "#Sell Deals",
    field: "sellCount",
    width: 120,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Volume",
    field: "volume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Value",
    field: "value",
    width: 150,
    cellStyle: totalColorStyle,
  },
];

export { columnDefsQuoteSaleByPrice };
