<template>
  <div class="quote-info-detail">
    <div class="dropdown-section">
      <div style="padding: 0.5rem 0 0 1rem">
        <SymbolDropdown
          ref="symbol"
          v-model="selectedSymbol"
          :market="market"
          image
          notification
          style="min-height: 3rem !important; line-height: 3rem !important; width: 10rem !important; min-width: 10rem !important"
          @enter="$refs.symbol.select()"
        />
      </div>
      <div style="padding: 0.75rem 0.5rem 0.75rem 0">
        <QuoteLastsale :current-quote-selected-symbol="selectedSymbol" />
      </div>
    </div>
    <div class="quote-ets-info-container">
      <QuoteETSInfo
        v-if="!selectedSymbol || (selectedSymbol && selectedSymbol.value.exchangeId === 1)"
        :current-quote-selected-symbol="selectedSymbol"
      />
      <QuoteDTSInfo v-else :current-quote-selected-symbol="selectedSymbol" />
    </div>
  </div>
</template>

<script>
import SymbolDropdown from "@/components/SymbolDropdown";
import QuoteLastsale from "@/components/quote/QuoteLastsale";
import QuoteETSInfo from "@/components/quote/QuoteETSInfo";
import QuoteDTSInfo from "@/components/quote/QuoteDTSInfo";

import { SecurityItem } from "@/messages/websocket/XR03";

export default {
  name: "QuoteInfo",
  components: {
    SymbolDropdown,
    QuoteETSInfo,
    QuoteDTSInfo,
    QuoteLastsale,
  },
  props: {
    market: {
      type: String,
      default: "ALL",
    },
  },
  data: () => ({
    selectedSymbol: null,
  }),
  watch: {
    selectedSymbol(selectedSymbol) {
      this.XR03(selectedSymbol);
    },
  },
  methods: {
    XR03(currentQuoteSelectedSymbol) {
      const msg = this.$messageFactory.createMessage("XR03");

      if (currentQuoteSelectedSymbol) {
        const securityItem = new SecurityItem();

        securityItem.securityId = Number(currentQuoteSelectedSymbol.value.securityId);
        msg.securityList.get().push(securityItem);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.quote-info-detail {
  height: 100%;
  display: flex;
  flex-direction: column;

  .dropdown-section {
    height: 4rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .quote-ets-info-container {
    padding: 0.8rem 0;
    flex: 1;
    overflow: auto;
  }
}
</style>
