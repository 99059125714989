/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class OS05 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._orderId = new StringMessageBase();
		this._side = new StringMessageBase();
		this._entrytime = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._board = new StringMessageBase();
		this._account = new StringMessageBase();
		this._matchedVol = new StringMessageBase();
		this._publishedVol = new StringMessageBase();
		this._trader = new StringMessageBase();
		this._entrytrader = new StringMessageBase();
		this._cond = new StringMessageBase();
		this._quote = new StringMessageBase();
		this._quoteTime = new StringMessageBase();
		this._pc = new StringMessageBase();
		this._origPrice = new StringMessageBase();
		this._approver = new StringMessageBase();
		this._status = new StringMessageBase();
		this._credit = new StringMessageBase();
		this._terminal = new StringMessageBase();
		this._sbkCustId = new StringMessageBase();
		this._cancelTraderId = new StringMessageBase();
		this._cancelTime = new StringMessageBase();
		this._custName = new StringMessageBase();
		this._bbNo = new StringMessageBase();
		this._specialOrderType = new StringMessageBase();
		this._dma = new StringMessageBase();
		this._tradeChanel = new StringMessageBase();
		this._webServerIp = new StringMessageBase();
		this._ispAssidnIp = new StringMessageBase();
		this._clientIpFld = new StringMessageBase();
		this._setOrdId = new StringMessageBase();
		this._positionType = new StringMessageBase();
		this._validity = new StringMessageBase();
		this._validityType = new StringMessageBase();
		this._validityData = new StringMessageBase();
		this._origOrdDate = new StringMessageBase();
		this._origOrdNo = new StringMessageBase();
		this._origOrdVol = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._inActivate = new StringMessageBase();
		this._sendDate = new StringMessageBase();
		this._remark = new StringMessageBase();
		this._icebergVol = new StringMessageBase();
		this._ttf = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get orderId() {
		return this._orderId;
	}
	
	get side() {
		return this._side;
	}
	
	get entrytime() {
		return this._entrytime;
	}
	
	get volume() {
		return this._volume;
	}
	
	get price() {
		return this._price;
	}
	
	get board() {
		return this._board;
	}
	
	get account() {
		return this._account;
	}
	
	get matchedVol() {
		return this._matchedVol;
	}
	
	get publishedVol() {
		return this._publishedVol;
	}
	
	get trader() {
		return this._trader;
	}
	
	get entrytrader() {
		return this._entrytrader;
	}
	
	get cond() {
		return this._cond;
	}
	
	get quote() {
		return this._quote;
	}
	
	get quoteTime() {
		return this._quoteTime;
	}
	
	get pc() {
		return this._pc;
	}
	
	get origPrice() {
		return this._origPrice;
	}
	
	get approver() {
		return this._approver;
	}
	
	get status() {
		return this._status;
	}
	
	get credit() {
		return this._credit;
	}
	
	get terminal() {
		return this._terminal;
	}
	
	get sbkCustId() {
		return this._sbkCustId;
	}
	
	get cancelTraderId() {
		return this._cancelTraderId;
	}
	
	get cancelTime() {
		return this._cancelTime;
	}
	
	get custName() {
		return this._custName;
	}
	
	get bbNo() {
		return this._bbNo;
	}
	
	get specialOrderType() {
		return this._specialOrderType;
	}
	
	get dma() {
		return this._dma;
	}
	
	get tradeChanel() {
		return this._tradeChanel;
	}
	
	get webServerIp() {
		return this._webServerIp;
	}
	
	get ispAssidnIp() {
		return this._ispAssidnIp;
	}
	
	get clientIpFld() {
		return this._clientIpFld;
	}
	
	get setOrdId() {
		return this._setOrdId;
	}
	
	get positionType() {
		return this._positionType;
	}
	
	get validity() {
		return this._validity;
	}
	
	get validityType() {
		return this._validityType;
	}
	
	get validityData() {
		return this._validityData;
	}
	
	get origOrdDate() {
		return this._origOrdDate;
	}
	
	get origOrdNo() {
		return this._origOrdNo;
	}
	
	get origOrdVol() {
		return this._origOrdVol;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get inActivate() {
		return this._inActivate;
	}
	
	get sendDate() {
		return this._sendDate;
	}
	
	get remark() {
		return this._remark;
	}
	
	get icebergVol() {
		return this._icebergVol;
	}
	
	get ttf() {
		return this._ttf;
	}
}

export default OS05;
