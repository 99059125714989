<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    placeholder: {
      type: String,
      default: "Trailing Type"
    },
    filterable: {
      type: String,
      default: "startsWith"
    }
  },
  data() {
    return {
      style: "width: 8.5rem; min-width: 8.5rem;",
      defaultValue: "S",
      displayValue: "S",
      options: [
        {
          text: "Spread",
          value: "S"
        },
        {
          text: "Percentage",
          value: "P"
        }
      ]
    };
  }
};
</script>
