<template>
  <div class="top-5-trader" ref="top-5-trader">
    <sui-tab :menu="{ attached: 'top' }" class="top-5-trader-tabs">
      <sui-tab-pane class="top-5-trader-tab" :title="mode === 'Company' ? 'Top Department' : 'Top Trader'" attached="top">
        <apexchart type="bar" :height="dynamicHeight" :width="dynamicWidth" :options="chartOptions" :series="series" :key="updateKey" />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

export default {
  name: "MarketStockGraph",
  components: {},
  props: ["mode", "data"],
  data: () => ({
    isLoading: false,
    dynamicHeight: 100,
    dynamicWidth: 100,
    updateKey: 0,
    series: [
      {
        name: "Buy",
        data: [0, 0, 0, 0, 0],
      },
      {
        name: "Sell",
        data: [0, 0, 0, 0, 0],
      },
    ],
    chartOptions: {
      theme: {
        mode: "dark",
      },
      grid: {
        borderColor: "#2F383F",
      },
      fill: {
        type: "gradient",
      },
      chart: {
        stacked: true,
        toolbar: {
          show: false,
          tools: {
            selection: false,
            zoom: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          barHeight: "60%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#2185d0", "#E03997"],
      stroke: {
        width: 0,
        color: "#1B2025",
      },
      xaxis: {
        categories: [],
        labels: {
          formatter: (value) => {
            if (typeof value === "number") {
              return "";
            }
            return value.length > 10 ? value.slice(0, 7) + "..." : value;
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (value) => {
            if (value > 1000000) {
              value /= 1000000;
              return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " MB";
            } else {
              return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          },
        },
      },
      tooltip: {
        x: {
          formatter: (value) => value,
        },
        y: {
          formatter: (value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 0,
      },
    },
  }),
  watch: {
    data(data) {
      if (data.records?.length && this.mode === "Company") {
        this.series = [
          {
            name: "Buy",
            data: data.records.slice(0, 5).map((value) => this.$numeral(value.buyVal.toFixed(2)).value()),
          },
          {
            name: "Sell",
            data: data.records.slice(0, 5).map((value) => this.$numeral(value.sellVal.toFixed(2)).value()),
          },
        ];
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: data.records.slice(0, 5).map((record) => record.departmentName),
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: data.records.slice(0, 5).length <= 3 ? "20%" : "60%",
            },
          },
        };
      }

      if (data.records?.length && this.mode === "Trader") {
        this.series = [
          {
            name: "Buy",
            data: data.records.slice(0, 5).map((value) => this.$numeral(value.boughtVal.toFixed(2)).value()),
          },
          {
            name: "Sell",
            data: data.records.slice(0, 5).map((value) => this.$numeral(value.soldVal.toFixed(2)).value()),
          },
        ];
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: data.records.slice(0, 5).map((record) => record.traderId),
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: data.records.slice(0, 5).length <= 3 ? "20%" : "60%",
            },
          },
        };
      }
    },
  },
  created() {
    this.$EventBus.$on("onValueStartRefresh", this.onValueStartRefresh);
    this.$EventBus.$on("onValueStopRefresh", this.onValueStopRefresh);
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    this.$EventBus.$off("onValueStartRefresh", this.onValueStartRefresh);
    this.$EventBus.$off("onValueStopRefresh", this.onValueStopRefresh);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onValueStartRefresh");
    },
    onValueStartRefresh() {
      this.isLoading = true;
    },
    onValueStopRefresh() {
      this.isLoading = false;
    },
    onResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      this.dynamicHeight = 0;
      this.dynamicWidth = 0;

      this.resizeTimeout = setTimeout(() => {
        const chartContainer = this.$refs["top-5-trader"];
        this.dynamicHeight = chartContainer.clientHeight - 32;
        this.dynamicWidth = chartContainer.clientWidth - 40;
        this.updateKey++;
      }, 200);
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
};
</script>

<style lang="scss" scoped>
.top-5-trader {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .top-5-trader-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    .top-5-trader-tab {
      flex: 1;
      align-items: center;
      display: flex;
      justify-content: center;

      .empty-text {
        font-size: 12px;
        color: #dcddde;
      }
    }
  }
}
.apexcharts-canvas.dark {
  background: #262d33 !important;
}

.item.active {
  background-color: #262d33 !important;
}

.ui.attached.menu.top {
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
  min-height: unset !important;
}

a.item {
  height: 32px !important;
  padding: 0px 1rem !important;
  font-weight: 800 !important;
  color: #dcddde !important;
}

a.item > span {
  padding: 0.25rem !important;
}

a.item.active > span {
  color: #c88841 !important;
  border-radius: 0.5rem !important;
  padding: 0.25rem !important;
  border: 1px solid #c88841 !important;
}

.ui.tab.segment.attached.active.top {
  background-color: #262d33 !important;
  border: 0px !important;
  padding: 0px !important;
  /* margin-top: -8px !important; */
}
</style>
