/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class CR52 extends WsReqMsg {
	constructor() {
		super(1);
		this._ownTid = new StringMessageBase();
		this._forceCallFlag = new StringMessageBase();
		this._status = new StringMessageBase();
		this._recordCount = new NumberMessageBase();
		this._lastRecordId = new NumberMessageBase();
	}
	
	get ownTid() {
		return this._ownTid;
	}
	
	set ownTid(ownTid) {
		this._ownTid = new StringMessageBase(ownTid);
	}
	
	get forceCallFlag() {
		return this._forceCallFlag;
	}
	
	set forceCallFlag(forceCallFlag) {
		this._forceCallFlag = new StringMessageBase(forceCallFlag);
	}
	
	get status() {
		return this._status;
	}
	
	set status(status) {
		this._status = new StringMessageBase(status);
	}
	
	get recordCount() {
		return this._recordCount;
	}
	
	set recordCount(recordCount) {
		this._recordCount = new NumberMessageBase(recordCount);
	}
	
	get lastRecordId() {
		return this._lastRecordId;
	}
	
	set lastRecordId(lastRecordId) {
		this._lastRecordId = new NumberMessageBase(lastRecordId);
	}
}

export default CR52;
