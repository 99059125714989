import { BLUE_COLOR, sideColorStyle, numeralCellFormatter, baseStyle, conditionFormatter, ttfFormatter } from "../Formatter.js";
import { price as PriceFormatter } from "@/utils/NumberFormatter";
import { isDisplayOrderCheckbox } from "@/utils/ValidationUtils";

const columnDefs = [
  {
    headerName: "",
    field: "status",
    checkboxSelection: true,
    pinned: "left",
    width: 35,
    cellStyle: params => {
      if (params.data) {
        const result = isDisplayOrderCheckbox(params.data);
        if (!result) {
          return {
            "pointer-events": "none"
          };
        }
      }
      return null;
    }
  },
  {
    headerName: "Order No.",
    field: "orderId",
    pinned: "left",
    width: 140,
    cellStyle: {
      display: "flex",
      "align-items": "center",
      ...baseStyle,
      color: BLUE_COLOR
    }
  },
  {
    headerName: "Time",
    field: "time",
    width: 100,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR
    }
  },
  {
    headerName: "Symbol",
    field: "symbol",
    width: 100,
    cellStyle: baseStyle
  },
  {
    headerName: "Side",
    field: "side",
    width: 65,
    cellStyle: sideColorStyle
  },
  {
    headerName: "Volume",
    field: "volume",
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    width: 100,
    cellStyle: baseStyle
  },
  {
    headerName: "Price",
    field: "price",
    valueFormatter: params => {
      if (!params.data) {
        return;
      }
      return PriceFormatter(params.value);
    },
    width: 100,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle
    }
  },
  {
    headerName: "Matched",
    field: "matchedVol",
    width: 100,
    cellStyle: baseStyle
  },
  {
    headerName: "Published",
    field: "published",
    width: 100,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR
    }
  },
  {
    headerName: "Status",
    field: "status",
    width: 100,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR
    }
  },
  {
    headerName: "Quote",
    field: "quote",
    width: 100,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR
    }
  },
  {
    headerName: "Iceberg",
    field: "iceberg",
    width: 100,
    cellStyle: baseStyle
  },
  {
    headerName: "Condition",
    field: "condition",
    width: 100,
    cellStyle: baseStyle,
    valueFormatter: conditionFormatter
  },
  {
    headerName: "Flag",
    field: "ttf",
    width: 100,
    cellStyle: baseStyle,
    valueFormatter: ttfFormatter
  },
  {
    headerName: "Account",
    field: "accountCode",
    width: 140,
    cellStyle: baseStyle
  },
  {
    headerName: "Send Date",
    field: "sendDate",
    width: 120,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR
    }
  },
  {
    headerName: "Validity",
    field: "validity",
    width: 100,
    cellStyle: baseStyle
  },
  {
    headerName: "Validity Data",
    field: "validityData",
    width: 120,
    cellStyle: baseStyle
  }
];

export default columnDefs;
