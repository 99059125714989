<template>
  <div class="broker-ranking">
    <div class="options-container">
      <sui-checkbox v-model="selectedMarket" radio value="SET" :disabled="!isEnableETS()" class="custom-checkbox">
        <h5 class="ui grey inverted tiny header">{{ "SET" }}</h5>
      </sui-checkbox>
      <sui-checkbox v-model="selectedMarket" radio value="TFEX" :disabled="!isEnableDTS()" class="custom-checkbox">
        <h5 class="ui grey inverted tiny header">{{ "TFEX" }}</h5>
      </sui-checkbox>
      <div class="input-field">
        <h5 class="ui grey header inverted">{{ "Market" }}</h5>
        <sui-dropdown
          v-model="selectedAllMarket"
          fluid
          text="All Markets"
          button
          pointing
          :options="selectedMarket === 'SET' ? allSETMarketOptions : allTFEXMarketOptions"
          class="ht-indexsector-dropdown"
        />
      </div>
      <div class="broker-ranking-detail">
        <h5 class="ui grey header inverted ht-text-header">{{ "Date" }}</h5>
        <span class="ht-text-value">{{ this.$moment(date, "DD/MM/YYYY").format("DD MMM YYYY") }}</span>
      </div>
      <div class="broker-ranking-detail">
        <h5 class="ui grey header inverted ht-text-header">{{ "Total Market Volume" }}</h5>
        <span class="ht-text-value">{{ totalMktVol }}</span>
      </div>
      <div class="broker-ranking-detail">
        <h5 class="ui grey header inverted ht-text-header">{{ "Total Market Value" }}</h5>
        <span class="ht-text-value">{{ totalMktVal }}</span>
      </div>
    </div>
    <div class="ui grid content-grid">
      <div class="row broker-table-container">
        <ag-grid-vue
          class="broker-table"
          :class="{
            'ag-theme-balham-dark': theme === 'Dark',
            'ag-theme-balham': theme === 'Light',
          }"
          row-selection="single"
          :grid-options="gridBrokerRankingOptions"
          :column-defs="columnDefsBrokerRanking"
          :row-data="rowBrokerRanking"
          :row-height="21"
          :header-height="32"
          :default-col-def="{ sortable: true, resizable: true }"
          :animate-rows="true"
          :suppress-drag-leave-hides-columns="true"
          :enable-cell-change-flash="true"
          :locale-text="{ noRowsToShow: `Don't have any Broker.` }"
          :row-drag-managed="false"
          :pinned-bottom-row-data="pinnedBottomRowETSBrokerRanking"
          overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
          @grid-ready="onBrokerRankingGridReady"
          @cell-focused="onCellBrokerRankingFocused"
        />
      </div>
      <div class="row chart-container">
        <div class="left-side-container sixteen wide computer twelve wide large screen column twelve wide widescreen monitor column">
          <TopBroker :data="chartData" />
        </div>
        <div class="four wide column large screen only right-side-container">
          <ProgressBarBroker :focus="brokerRankingFocused" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsBrokerRanking } from "@/components/grid/broker/BrokerRankingColumnDefs.js";
import InputText from "@/components/InputText";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

Object.freeze(columnDefsBrokerRanking);

import TopBroker from "@/components/broker/TopBroker.vue";
import ProgressBarBroker from "@/components/broker/ProgressBarBroker.vue";

export default {
  name: "BrokerRanking",
  components: {
    AgGridVue,
    TopBroker,
    ProgressBarBroker,
  },
  data: () => ({
    columnDefsBrokerRanking,

    gridBrokerRankingApi: null,
    columnBrokerRankingApi: null,
    rowBrokerRanking: [],

    selectedMarket: "SET",
    isEnableETS,
    isEnableDTS,

    selectedAllMarket: "A",
    allSETMarketOptions: [
      {
        text: "All Markets",
        value: "A",
      },
      {
        text: "SET",
        value: "S",
      },
      {
        text: "mai",
        value: "M",
      },
    ],
    allTFEXMarketOptions: [
      {
        text: "All Markets",
        value: "A",
      },
      {
        text: "SME",
        value: "S",
      },
      {
        text: "OPT",
        value: "O",
      },
    ],
    pinnedBottomRowETSBrokerRanking: [
      {
        rank: "Total",
        brokerName: "0 Broker(s)",
        chg: "",
        chgPct: "",
        buyVal: "",
        buyValMktPct: "",
        sellVal: "",
        sellValMktPct: "",
        netVal: "",
        totalVal: "",
        totalValMktPct: "",
        buyShare: "",
        buyShareMktPct: "",
        sellShare: "",
        sellShareMktPct: "",
        totalShare: "",
        totalShareMktPct: "",
      },
    ],

    date: "00/00/0000",
    totalMktVol: "0",
    totalMktVal: "0",

    BRRefId: null,
    chartData: {},
    brokerRankingFocused: null,
  }),
  watch: {
    selectedAllMarket() {
      this.filterChanged();
    },
    selectedMarket() {
      this.selectedAllMarket = "A";
      this.filterChanged();
    },
  },
  methods: {
    onBrokerStartRefresh() {
      this.rowBrokerRanking = [];
      this.pinnedBottomRowETSBrokerRanking = [
        {
          rank: "Total",
          brokerName: "0 Broker(s)",
          chg: "",
          chgPct: "",
          buyVal: "",
          buyValMktPct: "",
          sellVal: "",
          sellValMktPct: "",
          netVal: "",
          totalVal: "",
          totalValMktPct: "",
          buyShare: "",
          buyShareMktPct: "",
          sellShare: "",
          sellShareMktPct: "",
          totalShare: "",
          totalShareMktPct: "",
        },
      ];
      this.chartData = {};
      this.date = "00/00/0000";
      this.totalMktVol = "0";
      this.totalMktVal = "0";
      this.brokerRankingFocused = null;

      if (this.selectedMarket === "SET") {
        this.BR03();
      } else if (this.selectedMarket === "TFEX") {
        this.BR53();
      }
    },
    onEnter() {
      this.onBrokerStartRefresh();
    },
    filterChanged() {
      this.onBrokerStartRefresh();
    },
    BR03() {
      const msg = this.$messageFactory.createMessage("BR03");
      this.BRRefId = this.$shortid.generate();

      msg.marketId.set(this.selectedAllMarket);
      msg.recordCount.set(0);
      msg.lastRecordId.set(0);
      msg.sortBy.set("G");

      this.$ws.send(msg, this.BRRefId);

      if (this.gridBrokerRankingApi) {
        this.gridBrokerRankingApi.showLoadingOverlay();
      }
    },
    BR53() {
      const msg = this.$messageFactory.createMessage("BR53");
      this.BRRefId = this.$shortid.generate();

      msg.marketId.set(this.selectedAllMarket);
      msg.recordCount.set(0);
      msg.lastRecordId.set(0);
      msg.sortBy.set("G");

      this.$ws.send(msg, this.BRRefId);

      if (this.gridBrokerRankingApi) {
        this.gridBrokerRankingApi.showLoadingOverlay();
      }
    },
    updateRowBrokerRanking(ranking) {
      if (this.BRRefId !== ranking.refId) {
        return;
      }

      if (ranking.resultCode !== 1001) {
        this.chartData = {};
        this.date = "00/00/0000";
        this.totalMktVol = "0";
        this.totalMktVal = "0";
        this.brokerRankingFocused = null;
        if (this.gridBrokerRankingApi) {
          this.gridBrokerRankingApi.hideOverlay();
        }
        console.error(ranking.resultCode + ": " + ranking.resultMessage);
        this.$Swal.fire("Error", "Can't get Broker Ranking.", "error");
        return;
      }

      this.date = ranking.date;
      this.totalMktVol = this.$numeral(ranking.totalMktVol).format("0,0");
      this.totalMktVal = this.$numeral(ranking.totalMktVal).format("0,0");

      this.rowBrokerRanking = ranking.records;

      this.$_.remove(ranking.records, (record) => {
        return record.totalVal == 0;
      });

      this.pinnedBottomRowETSBrokerRanking = [
        {
          rank: "Total",
          brokerName: ranking.records.length + " Broker(s)",
          chg: "",
          chgPct: "",
          buyVal: "",
          buyValMktPct: "",
          sellVal: "",
          sellValMktPct: "",
          netVal: "",
          totalVal: "",
          totalValMktPct: "",
          buyShare: "",
          buyShareMktPct: "",
          sellShare: "",
          sellShareMktPct: "",
          totalShare: "",
          totalShareMktPct: "",
        },
      ];
      if (this.gridBrokerRankingApi) {
        this.gridBrokerRankingApi.hideOverlay();
      }

      this.chartData = ranking;

      this.$nextTick(() => {
        this.gridBrokerRankingOptions.api.forEachNode((node) => {
          if (node.rowIndex === 0) {
            node.setSelected(true);
            this.brokerRankingFocused = node.data;
            return;
          }
        });
      });

      this.$EventBus.$emit("onBrokerStopRefresh");
    },
    onBrokerRankingGridReady(params) {
      this.gridBrokerRankingApi = params.api;
      this.columnBrokerRankingApi = params.columnApi;
    },
    onCellBrokerRankingFocused(event) {
      this.gridBrokerRankingOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          this.brokerRankingFocused = node.data;
          return;
        }
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
  created() {
    this.gridBrokerRankingOptions = {};

    this.$EventBus.$on("ctinf/BS03", this.updateRowBrokerRanking);
    this.$EventBus.$on("ctinf/BS53", this.updateRowBrokerRanking);

    this.$EventBus.$on("onBrokerStartRefresh", this.onBrokerStartRefresh);
  },
  mounted() {
    this.onBrokerStartRefresh();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/BS03", this.updateRowBrokerRanking);
    this.$EventBus.$off("ctinf/BS53", this.updateRowBrokerRanking);

    this.$EventBus.$off("onBrokerStartRefresh", this.onBrokerStartRefresh);
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
@import "~@/assets/styles/company/broker-ranking.scss";
.ht-border {
  border-style: solid;
  border-color: black;
}

.ui.button.fluid.dropdown {
  background-color: #2f383f;
}

.ui.grey.header {
  color: #dcddde !important;
}

.ui.secondary.menu {
  min-height: unset;
  height: 32px !important;
  border: 0px !important;
  background-color: unset !important;
}

.dropdown.icon {
  color: #999999;
}
</style>

<style>
.ui.fluid.dropdown > .dropdown.icon {
  color: #999999;
}

.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.ht-news-datepicker .el-range-separator {
  padding: 0px !important;
  margin-top: -10px !important;
  color: #dcddde !important;
}

.ht-news-datepicker .el-input__icon.el-range__icon.el-icon-date {
  margin-top: -10px !important;
}

.el-picker-panel__sidebar {
  background-color: #2f383f !important;
}

.el-picker-panel__shortcut {
  color: #dcddde !important;
}

.el-picker-panel__shortcut:hover {
  color: #409eff !important;
}

.el-date-table__row > .available.in-range > div {
  background-color: #1b2025 !important;
}

.ht-trader-id > input {
  border: 0px !important;
}

.ht-custlist-dropdown {
  height: 2rem;
  min-height: 2rem;
  top: calc(50% - 1.5rem) !important;
}
.ht-custlist-dropdown .text {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 1rem;
  right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-custlist-dropdown i.dropdown {
  line-height: 0 !important;
  margin-top: 3px !important;
}

.ht-text-header {
  display: inline;
  margin: 0 !important;
  padding: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-text-value {
  color: #e4af0a !important;
  padding-left: 1rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
