import store from "@/store";
import { find as _find } from "lodash";

export const getBySymbol = symbol => {
  if (!symbol) {
    return null;
  }
  const setSecList = store.get("global/etsSecurities");
  const tfexSecList = store.get("global/dtsSecurities");
  const setFound = setSecList[symbol];
  const tfexFound = tfexSecList[symbol];
  let result = {};
  if (setFound) {
    result.set = setFound;
  }
  if (tfexFound) {
    result.tfex = tfexFound;
  }
  if (!setFound && !tfexFound) {
    return null;
  }
  return result;
};

export const getBySymbolAndExchangeId = (symbol, exchangeId) => {
  if (!symbol || !exchangeId) {
    return null;
  }
  if (exchangeId == 1) {
    const setSecList = store.get("global/etsSecurities");
    return setSecList[symbol];
  } else if (exchangeId == 2) {
    const tfexSecList = store.get("global/dtsSecurities");
    return tfexSecList[symbol];
  } else {
    return null;
  }
};

export const getImage = (etsSymbols, symbol, exchangeId, test) => {
  const image = require.context(`@/assets/company-logo-compress/`, false, /\.png$/);
  let imageFile = image(`./PLACEHOLDER.png`);
  if (exchangeId == 1) {
    let newImagePath = "";
    // find for warrent
    const index = symbol.lastIndexOf("-");
    if (index >= 0) {
      const key = symbol.substring(index, symbol.length);
      const result = key.match(/(-F$)|(-W.*$)|(-P$)|(-Q$)/);
      if (result) {
        newImagePath = symbol.substring(0, index);
      }
    }
    try {
      // hardcode windows can't have COM7.png file
      if (newImagePath === "COM7") newImagePath = "COM77";
      return image(`./${newImagePath}.png`);
      // eslint-disable-next-line no-empty
    } catch (err) {}

    // find DW
    newImagePath = symbol;
    if (symbol.length > 8) {
      newImagePath = symbol.substring(0, symbol.length - 8);
      const found = _find(etsSymbols, o => {
        return o.symbol.startsWith(newImagePath);
      });
      if (found) {
        newImagePath = found.symbol;
      }
    }
    try {
      // hardcode windows can't have COM7.png file
      if (newImagePath === "COM7") newImagePath = "COM77";
      imageFile = image(`./${newImagePath}.png`);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  } else if (exchangeId == 2) {
    symbol = symbol.substring(0, symbol.length - 3);

    // find combo
    const checker = symbol.substring(symbol.length - 3, symbol.length);
    if (checker.match(/^[A-Z]\d{2}$/)) {
      symbol = symbol.substring(0, symbol.length - 3);
    }
    try {
      // hardcode windows can't have COM7.png file
      if (symbol === "COM7") symbol = "COM77";
      imageFile = image(`./${symbol}.png`);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  return imageFile;
};

export const getImageName = (etsSymbols, symbol, exchangeId) => {
  const image = require.context(`@/assets/company-logo-compress/`, false, /\.png$/);
  let imageFile = "PLACEHOLDER";
  if (exchangeId == 1) {
    let newImagePath = "";
    // find for warrent
    const index = symbol.lastIndexOf("-");
    if (index >= 0) {
      const key = symbol.substring(index, symbol.length);
      const result = key.match(/(-F$)|(-W.*$)|(-P$)|(-Q$)/);
      if (result) {
        newImagePath = symbol.substring(0, index);
      }
    }
    try {
      // hardcode windows can't have COM7.png file
      if (newImagePath === "COM7") newImagePath = "COM77";
      image(`./${newImagePath}.png`);

      imageFile = newImagePath;
      // eslint-disable-next-line no-empty
    } catch (err) {}

    // find DW
    newImagePath = symbol;
    if (symbol.length > 8) {
      newImagePath = symbol.substring(0, symbol.length - 8);
      const found = _find(etsSymbols, o => {
        return o.symbol.startsWith(newImagePath);
      });
      if (found) {
        newImagePath = found.symbol;
      }
    }
    try {
      // hardcode windows can't have COM7.png file
      if (newImagePath === "COM7") newImagePath = "COM77";
      image(`./${newImagePath}.png`);

      imageFile = newImagePath;
      // eslint-disable-next-line no-empty
    } catch (err) {}
  } else if (exchangeId == 2) {
    symbol = symbol.substring(0, symbol.length - 3);

    // find combo
    const checker = symbol.substring(symbol.length - 3, symbol.length);
    if (checker.match(/^[A-Z]\d{2}$/)) {
      symbol = symbol.substring(0, symbol.length - 3);
    }
    try {
      // hardcode windows can't have COM7.png file
      if (symbol === "COM7") symbol = "COM77";
      image(`./${symbol}.png`);

      imageFile = symbol;
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  return imageFile;
};
