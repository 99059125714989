/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class CR02 extends WsReqMsg {
	constructor() {
		super(1);
		this._ownTid = new StringMessageBase();
		this._crdtBalFlag = new StringMessageBase();
		this._period = new StringMessageBase();
		this._recordCount = new NumberMessageBase();
		this._lastRecordId = new NumberMessageBase();
	}
	
	get ownTid() {
		return this._ownTid;
	}
	
	set ownTid(ownTid) {
		this._ownTid = new StringMessageBase(ownTid);
	}
	
	get crdtBalFlag() {
		return this._crdtBalFlag;
	}
	
	set crdtBalFlag(crdtBalFlag) {
		this._crdtBalFlag = new StringMessageBase(crdtBalFlag);
	}
	
	get period() {
		return this._period;
	}
	
	set period(period) {
		this._period = new StringMessageBase(period);
	}
	
	get recordCount() {
		return this._recordCount;
	}
	
	set recordCount(recordCount) {
		this._recordCount = new NumberMessageBase(recordCount);
	}
	
	get lastRecordId() {
		return this._lastRecordId;
	}
	
	set lastRecordId(lastRecordId) {
		this._lastRecordId = new NumberMessageBase(lastRecordId);
	}
}

export default CR02;
