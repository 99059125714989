/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:57
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class AS02 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._sumCostValue = new StringMessageBase();
		this._sumMarketValue = new StringMessageBase();
		this._sumUnrealized = new StringMessageBase();
		this._sumUnrealizedPercent = new StringMessageBase();
		this._sumRealized = new StringMessageBase();
		this._records = new ArrayMessageBase(Record);
		this._marketCharts = new ArrayMessageBase(MarketChart);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get sumCostValue() {
		return this._sumCostValue;
	}
	
	get sumMarketValue() {
		return this._sumMarketValue;
	}
	
	get sumUnrealized() {
		return this._sumUnrealized;
	}
	
	get sumUnrealizedPercent() {
		return this._sumUnrealizedPercent;
	}
	
	get sumRealized() {
		return this._sumRealized;
	}
	
	get records() {
		return this._records;
	}
	
	get marketCharts() {
		return this._marketCharts;
	}
}

export class Record {
	constructor() {
		this._symbol = new StringMessageBase();
		this._positionType = new StringMessageBase();
		this._ttf = new StringMessageBase();
		this._onHandVol = new StringMessageBase();
		this._sellableVol = new StringMessageBase();
		this._averagePrice = new StringMessageBase();
		this._marketPrice = new StringMessageBase();
		this._costValue = new StringMessageBase();
		this._marketValue = new StringMessageBase();
		this._unrealized = new StringMessageBase();
		this._unrealizedPercent = new StringMessageBase();
		this._realized = new StringMessageBase();
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get positionType() {
		return this._positionType;
	}
	
	get ttf() {
		return this._ttf;
	}
	
	get onHandVol() {
		return this._onHandVol;
	}
	
	get sellableVol() {
		return this._sellableVol;
	}
	
	get averagePrice() {
		return this._averagePrice;
	}
	
	get marketPrice() {
		return this._marketPrice;
	}
	
	get costValue() {
		return this._costValue;
	}
	
	get marketValue() {
		return this._marketValue;
	}
	
	get unrealized() {
		return this._unrealized;
	}
	
	get unrealizedPercent() {
		return this._unrealizedPercent;
	}
	
	get realized() {
		return this._realized;
	}
}

export class MarketChart {
	constructor() {
		this._symbol = new StringMessageBase();
		this._marketValue = new StringMessageBase();
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get marketValue() {
		return this._marketValue;
	}
}

export default AS02;
