/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class OS58 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._orderNo = new StringMessageBase();
		this._stopSymbol = new StringMessageBase();
		this._stopCondition = new StringMessageBase();
		this._stopPrice = new StringMessageBase();
		this._stopOrdStat = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get orderNo() {
		return this._orderNo;
	}
	
	get stopSymbol() {
		return this._stopSymbol;
	}
	
	get stopCondition() {
		return this._stopCondition;
	}
	
	get stopPrice() {
		return this._stopPrice;
	}
	
	get stopOrdStat() {
		return this._stopOrdStat;
	}
}

export default OS58;
