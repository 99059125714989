/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class MS01 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._marketId = new NumberMessageBase();
		this._mktStCode = new StringMessageBase();
		this._up = new StringMessageBase();
		this._down = new StringMessageBase();
		this._unchange = new StringMessageBase();
		this._buyValue = new StringMessageBase();
		this._sellValue = new StringMessageBase();
		this._openValue = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get marketId() {
		return this._marketId;
	}
	
	get mktStCode() {
		return this._mktStCode;
	}
	
	get up() {
		return this._up;
	}
	
	get down() {
		return this._down;
	}
	
	get unchange() {
		return this._unchange;
	}
	
	get buyValue() {
		return this._buyValue;
	}
	
	get sellValue() {
		return this._sellValue;
	}
	
	get openValue() {
		return this._openValue;
	}
}

export default MS01;
