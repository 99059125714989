import {
  totalColorStyle,
  qtyColorStyle,
  comparePriceNoCeilingFloorColorStyle,
  priceColorStyle,
  symbolColorStyle,
  openColorStyle,
} from "../Formatter.js";

const columnDefsIndexSectorIndustry = [
  {
    headerName: "Sector & Industry",
    field: "name",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 200,
    cellStyle: symbolColorStyle,
  },
  {
    headerName: "Last",
    field: "last",
    width: 120,
    cellStyle: priceColorStyle,
  },
  {
    headerName: "Change",
    field: "change",
    width: 120,
    cellStyle: priceColorStyle,
  },
  {
    headerName: "%Change",
    field: "changePercent",
    width: 120,
    cellStyle: priceColorStyle,
  },
  {
    headerName: "Volume",
    field: "volume",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Value",
    field: "value",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "High",
    field: "high",
    width: 100,
    cellStyle: comparePriceNoCeilingFloorColorStyle,
  },
  {
    headerName: "Low",
    field: "low",
    width: 100,
    cellStyle: comparePriceNoCeilingFloorColorStyle,
  },
  {
    headerName: "Prior",
    field: "prior",
    width: 100,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Open",
    field: "open1",
    width: 100,
    cellStyle: openColorStyle,
  },
];

export { columnDefsIndexSectorIndustry };
