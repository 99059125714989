/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class DR03 extends WsReqMsg {
	constructor() {
		super(0);
		this._firm = new StringMessageBase();
		this._traderId = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._price = new StringMessageBase();
		this._board = new StringMessageBase();
		this._custId = new StringMessageBase();
		this._trustId = new StringMessageBase();
		this._vol = new StringMessageBase();
		this._SETTraderId = new StringMessageBase();
		this._side = new StringMessageBase();
		this._contractFirm = new StringMessageBase();
		this._controlKey = new StringMessageBase();
		this._trCode = new StringMessageBase();
	}
	
	get firm() {
		return this._firm;
	}
	
	set firm(firm) {
		this._firm = new StringMessageBase(firm);
	}
	
	get traderId() {
		return this._traderId;
	}
	
	set traderId(traderId) {
		this._traderId = new StringMessageBase(traderId);
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get price() {
		return this._price;
	}
	
	set price(price) {
		this._price = new StringMessageBase(price);
	}
	
	get board() {
		return this._board;
	}
	
	set board(board) {
		this._board = new StringMessageBase(board);
	}
	
	get custId() {
		return this._custId;
	}
	
	set custId(custId) {
		this._custId = new StringMessageBase(custId);
	}
	
	get trustId() {
		return this._trustId;
	}
	
	set trustId(trustId) {
		this._trustId = new StringMessageBase(trustId);
	}
	
	get vol() {
		return this._vol;
	}
	
	set vol(vol) {
		this._vol = new StringMessageBase(vol);
	}
	
	get SETTraderId() {
		return this._SETTraderId;
	}
	
	set SETTraderId(SETTraderId) {
		this._SETTraderId = new StringMessageBase(SETTraderId);
	}
	
	get side() {
		return this._side;
	}
	
	set side(side) {
		this._side = new StringMessageBase(side);
	}
	
	get contractFirm() {
		return this._contractFirm;
	}
	
	set contractFirm(contractFirm) {
		this._contractFirm = new StringMessageBase(contractFirm);
	}
	
	get controlKey() {
		return this._controlKey;
	}
	
	set controlKey(controlKey) {
		this._controlKey = new StringMessageBase(controlKey);
	}
	
	get trCode() {
		return this._trCode;
	}
	
	set trCode(trCode) {
		this._trCode = new StringMessageBase(trCode);
	}
}

export default DR03;
