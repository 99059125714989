<template>
  <div>
    <sui-modal v-model="dialog" size="large">
      <sui-modal-header>
        <span>Order Cancel</span>
        <div class="close" @click="close"><i class="close grey icon"></i></div>
      </sui-modal-header>
      <div style="clear: float;"></div>
      <sui-modal-content>
        <div style="height: 25vh;">
          <ag-grid-vue
            style="width: 100%; height: 100%; padding: 0px;"
            :class="{
              'ag-theme-balham-dark': theme === 'Dark',
              'ag-theme-balham': theme === 'Light'
            }"
            :column-defs="ColumnDefs"
            :row-data="data"
            :row-height="21"
            :header-height="32"
            :default-col-def="{ resizable: true, sortable: true }"
            :animate-rows="true"
            :suppress-drag-leave-hides-columns="false"
            :locale-text="{ noRowsToShow: `Don't have any Order.` }"
            @grid-ready="onGridReady"
          />
        </div>
      </sui-modal-content>
      <sui-modal-actions>
        <form @submit.prevent="confirm">
          <InputPIN
            v-if="userType === 'I'"
            ref="pin"
            v-model="pin"
            class="ht-order-cancel-modal-pin"
            name="PIN"
            placeholder="PIN"
            autofocus
            required
          />
          <sui-button type="submit" negative :loading="processing" :disabled="processing">Confirm</sui-button>
        </form>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import ColumnDefs from "@/components/grid/orderCancel/ColumnDefs";
import InputPIN from "@/components/InputPIN";
import { parseStringEtsDateToEtsDate } from "@/utils/DateUtils";
export default {
  components: {
    AgGridVue,
    InputPIN
  },
  data() {
    return {
      ColumnDefs,
      processing: false,
      dialog: false,
      data: [],
      pin: null,
      gridApi: null,
      columnApi: null,
      refIds: [],
      successReponses: [],
      errorReponses: []
    };
  },
  created() {
    this.$EventBus.$on("orderCancel", this.onOrderCancel);

    this.$EventBus.$on("ctord/OS01", this.etsOrderCancelCtord);
    this.$EventBus.$on("ctord/OS03", this.etsOrderCancelSuccessCtord);
    this.$EventBus.$on("ctord/OS53", this.dtsOrderCancelCtord);
  },
  beforeDestroy() {
    this.$EventBus.$off("orderCancel", this.onOrderCancel);

    this.$EventBus.$off("ctord/OS01", this.etsOrderCancelCtord);
    this.$EventBus.$off("ctord/OS03", this.etsOrderCancelSuccessCtord);
    this.$EventBus.$off("ctord/OS53", this.dtsOrderCancelCtord);
  },
  methods: {
    onOrderCancel(rows) {
      this.processing = false;
      this.refIds = [];
      this.successReponses = [];
      this.errorReponses = [];
      this.pin = null;
      this.dialog = true;
      this.data = rows;
      if (this.userType === "I") {
        this.$nextTick(() => {
          this.$refs.pin.focus();
        });
      }
    },
    close() {
      this.dialog = false;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    confirm() {
      if (this.userType === "I") {
        const result = this.$refs.pin.validate();
        if (!result) {
          this.$refs.pin.focus();
          return;
        }
        this.$refs.pin.clearValidate();
      }

      this.processing = true;

      this.data.forEach(d => {
        if (d.exchangeId == 1) {
          this.OR03(d);
        } else if (d.exchangeId == 2) {
          this.OR53(d);
        }
      });
    },
    OR03(data) {
      // SET Cancel Order
      const msg = this.$messageFactory.createMessage("OR03");

      msg.orderId.set(data.orderId);
      msg.accountCode.set(data.accountCode);
      if (this.userType === "I") {
        msg.pinCode.set(this.pin);
      }
      msg.sendDate.set(parseStringEtsDateToEtsDate(data.sendDate));

      const refId = this.$shortid.generate();
      this.refIds.push(refId);
      this.$ws.send(msg, refId);
    },
    OR53(data) {
      // TFEX Cancel Order
      const msg = this.$messageFactory.createMessage("OR53");

      msg.orderId.set(data.orderId);
      msg.accountCode.set(data.accountCode);
      if (this.userType === "I") {
        msg.pinCode.set(this.pin);
      }
      msg.sendDate.set(parseStringEtsDateToEtsDate(data.sendDate));

      const refId = this.$shortid.generate();
      this.refIds.push(refId);
      this.$ws.send(msg, refId);
    },
    cancelFinish() {
      const type = this.errorReponses.length > 0 ? "error" : "success";
      if (type === "error") {
        const code = this.errorReponses[0].resultCode;
        if (code === 2002) {
          this.$Swal.fire({
            icon: type,
            text: this.errorReponses[0].resultMessage,
            title: `Error ${code}!`,
            onClose: () => {
              this.processing = false;
              this.$nextTick(() => {
                this.$refs.pin.focus();
                this.$refs.pin.select();
              });
            }
          });
          return;
        }
      }

      let html = "";
      if (this.successReponses.length > 0) {
        html += `<h3 class="ui green header">Success ${this.successReponses.length} order(s).</h3>`;
        html += `<div class="ui two column grid">`;
        this.successReponses.forEach(r => {
          html += `<div class="column">
            <div class="ui two column grid">
              <div class="column">${r.orderId}</div>
              <div class="column">${r.sendDate}</div>
            </div>
          </div>
          <div class="column" style="text-align: left; color: #00aa00;">Success</div>`;
        });
        html += "</div>";
      }
      if (this.errorReponses.length > 0) {
        html += `<h3 class="ui red header">Error ${this.errorReponses.length} order(s).</h3>`;
        html += `<div class="ui two column grid">`;
        this.errorReponses.forEach(r => {
          html += `<div class="column">
            <div class="ui two column grid">
              <div class="column">${r.orderId}</div>
              <div class="column">${r.sendDate}</div>
            </div>
          </div>
          <div class="column" style="text-align: left; color: #d91e18">${r.resultCode}: ${r.resultMessage}</div>`;
        });
        html += "</div>";
      }
      this.$Swal.fire({
        icon: type,
        html: html,
        onClose: () => {
          this.processing = false;
          this.dialog = false;
          this.$EventBus.$emit("orderCancelled");
        }
      });
    },
    etsOrderCancelCtord(data) {
      const index = this.$_.indexOf(this.refIds, data.refId);
      if (index !== -1) {
        this.refIds.splice(index, 1);
        if (data.resultCode === 1001) {
          this.successReponses.push(data);
        } else {
          this.errorReponses.push(data);
        }

        if (this.refIds.length === 0) {
          // finish
          this.cancelFinish();
        }
      }
    },
    etsOrderCancelSuccessCtord(data) {
      const index = this.$_.indexOf(this.refIds, data.refId);
      if (index !== -1) {
        this.refIds.splice(index, 1);
        if (data.resultCode === 1001) {
          this.successReponses.push(data);
        } else {
          this.errorReponses.push(data);
        }

        if (this.refIds.length === 0) {
          // finish
          this.cancelFinish();
        }
      }
    },
    dtsOrderCancelCtord(data) {
      const index = this.$_.indexOf(this.refIds, data.refId);
      if (index !== -1) {
        this.refIds.splice(index, 1);
        if (data.resultCode === 1001) {
          this.successReponses.push(data);
        } else {
          this.errorReponses.push(data);
        }

        if (this.refIds.length === 0) {
          // finish
          this.cancelFinish();
        }
      }
    }
  },
  computed: {
    theme: sync("global/theme"),
    userType: get("model/systemRefData@userType")
  }
};
</script>

<style>
.ht-order-cancel-modal-pin {
  height: 37px !important;
  min-height: 37px !important;
  width: 100px !important;
  min-width: 100px !important;
  margin-right: 4rem;
}
.ht-order-cancel-modal-pin input {
  padding: 0 1rem !important;
}
</style>
