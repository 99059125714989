import axios from "axios";
import { EventBus } from "@/event-bus";
import store from "@/store";
import * as shortid from "shortid";
import worker from "workerize-loader!./WebSocketParser";

import MessageFactory from "@/messages/websocket/MessageFactory";

const workerInstanceCtord = worker();
const workerInstanceCtinf = worker();
const workerInstanceBcst = worker();
const workerInstanceNtf = worker();

let SOCKET_CTORD_URL = process.env.VUE_APP_SOCKET_CTOD_PATH;
let SOCKET_CTINF_URL = process.env.VUE_APP_SOCKET_CTIN_PATH;
let SOCKET_BCST_URL = process.env.VUE_APP_SOCKET_BCST_PATH;
let SOCKET_NTF_URL = process.env.VUE_APP_SOCKET_CTNF_PATH;

let webSocketProtocol = "ws://";
/* Change protocol ws to wss */
if (window.location.protocol === "https:") {
  webSocketProtocol = "wss://";
}

const getConfigs = async () => {
  const API_CONFIG_URL = window.location.origin + "/config.json";
  const response = await axios.get(API_CONFIG_URL);
  const { CONFIG_SOCKET_CTOD_PATH, CONFIG_SOCKET_CTIN_PATH, CONFIG_SOCKET_BCST_PATH, CONFIG_SOCKET_CTNF_PATH } = response.data;
  let { CONFIG_EGW_IP } = response.data;

  if (!CONFIG_EGW_IP) {
    CONFIG_EGW_IP = window.location.host;
  }

  if (CONFIG_SOCKET_CTOD_PATH) {
    SOCKET_CTORD_URL = webSocketProtocol + "//" + CONFIG_EGW_IP + CONFIG_SOCKET_CTOD_PATH;
  } else {
    SOCKET_CTORD_URL = webSocketProtocol + "//" + CONFIG_EGW_IP + process.env.VUE_APP_SOCKET_CTOD_PATH;
  }
  if (CONFIG_SOCKET_CTIN_PATH) {
    SOCKET_CTINF_URL = webSocketProtocol + "//" + CONFIG_EGW_IP + CONFIG_SOCKET_CTIN_PATH;
  } else {
    SOCKET_CTINF_URL = webSocketProtocol + "//" + CONFIG_EGW_IP + process.env.VUE_APP_SOCKET_CTIN_PATH;
  }
  if (CONFIG_SOCKET_BCST_PATH) {
    SOCKET_BCST_URL = webSocketProtocol + "//" + CONFIG_EGW_IP + CONFIG_SOCKET_BCST_PATH;
  } else {
    SOCKET_BCST_URL = webSocketProtocol + "//" + CONFIG_EGW_IP + process.env.VUE_APP_SOCKET_BCST_PATH;
  }
  if (CONFIG_SOCKET_CTNF_PATH) {
    SOCKET_NTF_URL = webSocketProtocol + "//" + CONFIG_EGW_IP + CONFIG_SOCKET_CTNF_PATH;
  } else {
    SOCKET_NTF_URL = webSocketProtocol + "//" + CONFIG_EGW_IP + process.env.VUE_APP_SOCKET_CTNF_PATH;
  }
};

if (process.env.NODE_ENV === "production") {
  SOCKET_CTORD_URL = webSocketProtocol + window.location.host + process.env.VUE_APP_SOCKET_CTOD_PATH;
  SOCKET_CTINF_URL = webSocketProtocol + window.location.host + process.env.VUE_APP_SOCKET_CTIN_PATH;
  SOCKET_BCST_URL = webSocketProtocol + window.location.host + process.env.VUE_APP_SOCKET_BCST_PATH;
  SOCKET_NTF_URL = webSocketProtocol + window.location.host + process.env.VUE_APP_SOCKET_CTNF_PATH;
  getConfigs();
}

let sockets = {};
let socketsHeartBeat = {};
let socketTimer = null;
let socketMap = new Map();

const setSocketState = (keyId) => {
  let state = "CLOSED";

  switch (sockets[keyId].readyState) {
    case 0:
      state = "CONNECTING";
      break;
    case 1:
      state = "OPEN";
      break;
    case 2:
      state = "CLOSING";
      break;
    case 3:
      break;
  }

  switch (keyId) {
    case "ctord":
      store.set("websocket/ctordState", state);
      break;
    case "ctinf":
      store.set("websocket/ctinfState", state);
      break;
    case "bcst":
      store.set("websocket/bcstState", state);
      break;
    case "ntf":
      store.set("websocket/ntfState", state);
      break;
  }
};

const createWebSocket = (url, keyId) => {
  let socket = null;
  const token = localStorage.getItem("token");
  if ("WebSocket" in window || "MozWebSocket" in window) {
    socket = new WebSocket(url, token);
  }
  if (!socket) {
    if (process.env.NODE_ENV === "development") {
      console.error("Can't create web socket at " + url);
    }
    store.set("websocket/socketStatus", false);
    return;
  }
  store.set("websocket/socketStatus", true);

  socket.binaryType = "arraybuffer";
  socket.onopen = function (e) {
    if (process.env.NODE_ENV === "development") {
      console.log("Connected web socket channel: " + keyId, e);
    }

    setSocketState(keyId);
  };
  socket.onmessage = async function (msg) {
    let result = null;
    let channel = "err";
    switch (msg.srcElement.url.slice(-3)) {
      case "ord": //ctord
        result = await workerInstanceCtord.messageDecode(msg.data);
        if (socketMap.has(result.refId)) {
          socketMap.delete(result.refId);
        }
        channel = "ctord";
        break;
      case "inf": //ctinf
        result = await workerInstanceCtinf.messageDecode(msg.data);
        channel = "ctinf";
        break;
      case "cst": //bcst
        result = await workerInstanceBcst.messageDecode(msg.data);
        channel = "bcst";
        break;
      case "ntf": //ntf
        result = await workerInstanceNtf.messageDecode(msg.data);
        channel = "ntf";
        break;
    }

    if (result) {
      EventBus.$emit(`${channel}/${result.code}`, result);
    }

    if (process.env.NODE_ENV === "development" && result) {
      console.log("Receive <<<", channel, result.code, result);
    }
  };
  socket.onerror = function (e) {
    if (process.env.NODE_ENV === "development") {
      console.error("Error web socket channel: " + keyId, e);
    }

    setSocketState(keyId);
  };
  socket.onclose = function (e) {
    if (process.env.NODE_ENV === "development") {
      console.log("Disconnected web socket channel: " + keyId, e);
    }

    setSocketState(keyId);
    sockets[keyId] = null;
  };
  return socket;
};

const createCtciOrder = () => {
  sockets["ctord"] = createWebSocket(SOCKET_CTORD_URL, "ctord");
  socketsHeartBeat["ctord"] = setInterval(() => {
    const msg = MessageFactory.createMessage("XR04");
    msg.setChannelId(0);
    send(msg);
  }, process.env.VUE_APP_HEARTBEAT_INTERVAL);
};

const createCtciInfo = () => {
  sockets["ctinf"] = createWebSocket(SOCKET_CTINF_URL, "ctinf");
  socketsHeartBeat["ctinf"] = setInterval(() => {
    const msg = MessageFactory.createMessage("XR04");
    msg.setChannelId(1);
    send(msg);
  }, process.env.VUE_APP_HEARTBEAT_INTERVAL);
};

const createBroadcast = () => {
  sockets["bcst"] = createWebSocket(SOCKET_BCST_URL, "bcst");
  socketsHeartBeat["bcst"] = setInterval(() => {
    const msg = MessageFactory.createMessage("XR04");
    msg.setChannelId(2);
    send(msg);
  }, process.env.VUE_APP_HEARTBEAT_INTERVAL);
};

const createNotify = () => {
  sockets["ntf"] = createWebSocket(SOCKET_NTF_URL, "ntf");
  socketsHeartBeat["ntf"] = setInterval(() => {
    const msg = MessageFactory.createMessage("XR04");
    msg.setChannelId(3);
    send(msg);
  }, process.env.VUE_APP_HEARTBEAT_INTERVAL);
};

const start = () => {
  if (!sockets["ctord"]) {
    createCtciOrder();
  }
  if (!sockets["ctinf"]) {
    createCtciInfo();
  }
  if (!sockets["bcst"]) {
    createBroadcast();
  }
  if (!sockets["ntf"]) {
    createNotify();
  }

  checkTimeoutMessages();
};

const stop = () => {
  for (let key in sockets) {
    const socket = sockets[key];
    if (socket) socket.close();
    const heartbeat = socketsHeartBeat[key];
    if (heartbeat) clearInterval(heartbeat);
  }

  if (socketTimer) {
    socketMap = new Map();
    clearInterval(socketTimer);
  }
};

const socket = (keyId) => {
  return sockets[keyId];
};

const send = async (msg, refId = "") => {
  msg.refId.set(refId);
  if (!refId) {
    msg.refId.set(shortid.generate());
  }

  let channel = "";
  let result = null;
  switch (msg.channelId.value) {
    case 0:
      channel = "ctord";
      socketMap.set(msg.refId.value, { msg: msg, sendDate: new Date().getTime() });
      result = await workerInstanceCtord.messageEncode(msg.toSeparatedValuesString());
      break;
    case 1:
      channel = "ctinf";
      result = await workerInstanceCtinf.messageEncode(msg.toSeparatedValuesString());
      break;
    case 2:
      channel = "bcst";
      result = await workerInstanceBcst.messageEncode(msg.toSeparatedValuesString());
      break;
    case 3:
      channel = "ntf";
      result = await workerInstanceNtf.messageEncode(msg.toSeparatedValuesString());
      break;
    default:
      return new Error("channelId is wrong.");
  }

  if (process.env.NODE_ENV === "development") {
    console.log("Send >>>", channel, msg.toJsonObject());
  }

  if (sockets[channel]) {
    sockets[channel].send(result);
  }
};

const install = (Vue) => {
  Vue.mixin({
    data() {
      return {
        isBcstConnected: false,
        isCTInfoConnected: false,
        isCTOrderConnected: false,
        isNtfConnected: false,
      };
    },
    created() {
      if (!this.isBcstConnected && sockets["ctord"].readyState === 1) {
        this.isBcstConnected = true;
      }
      if (!this.isCTInfoConnected && sockets["ctinf"].readyState === 1) {
        this.isCTInfoConnected = true;
      }
      if (!this.isBcstConnected && sockets["bcst"].readyState === 1) {
        this.isBcstConnected = true;
      }
      if (!this.isBcstConnected && sockets["ntf"].readyState === 1) {
        this.isBcstConnected = true;
      }
    },
  });
};

const checkTimeoutMessages = () => {
  socketTimer = setInterval(() => {
    if (socketMap.size) {
      socketMap.forEach((value, refId) => {
        if (new Date().getTime() - value.sendDate > process.env.VUE_APP_SOCKET_TIMEOUT) {
          socketMap.delete(refId);
          generateTimeoutMessage(refId, value.msg);
        }
      });
    }
  }, 1000);
};

const generateTimeoutMessage = (refId, msg) => {
  const result = {
    channelId: msg.channelId.value,
    code: msg.code.value.replace("R", "S"),
    orderId: "",
    refId: refId,
    resultCode: 2012, //No response from ETS/DTS server
    resultMessage: `The system has timed out, please check your order before submit again.`,
    sendDate: "",
  };

  let channel = "";
  switch (msg.channelId.value) {
    case 0:
      channel = "ctord";
      break;
    case 1:
      channel = "ctinf";
      break;
    case 2:
      channel = "bcst";
      break;
    case 3:
      channel = "ntf";
      break;
    default:
      return new Error("channelId is wrong.");
  }
  EventBus.$emit(`${channel}/${result.code}`, result);
};

export { start, stop, socket, send, install };
