<template>
  <div>
    <sui-modal v-model="dialog" size="small" :closable="false">
      <sui-modal-header>
        <span>Shortcut</span>
        <div class="close" @click="close"><i class="close grey icon"></i></div>
      </sui-modal-header>
      <div style="clear: float"></div>
      <sui-modal-content style="text-align: center">
        <h4 style="color: grey">{{ displayText }}</h4>
        <sui-button type="button" @click="clear">Clear</sui-button>
      </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

import { convertKeyCodeToChar } from "@/utils/UIUtils.js";

export default {
  data() {
    return {
      param: null,
      dialog: false,
      inputKeys: [],
      displayText: "Enter your keys.",
    };
  },
  created() {
    this.$EventBus.$on("shortcut", this.onShortcut);
  },
  beforeDestroy() {
    this.$EventBus.$off("shortcut", this.onShortcut);
  },
  methods: {
    close() {
      this.dialog = false;
      window.removeEventListener("keydown", this.onKeyDown);
      window.removeEventListener("keyup", this.onKeyUp);

      this.isEditingShortcut = false;
    },
    onShortcut(param) {
      this.isEditingShortcut = true;

      this.param = param;
      this.inputKeys = [];
      this.dialog = true;
      window.addEventListener("keydown", this.onKeyDown);
      window.addEventListener("keyup", this.onKeyUp);
    },
    clear() {
      let newParam = Object.assign({}, this.param);
      newParam.shift = false;
      newParam.ctrl = false;
      newParam.alt = false;
      newParam.keyCode = null;
      newParam.keyName = "";
      this.$EventBus.$emit("onShortcut", newParam);
      this.close();
    },
    onKeyDown(e) {
      this.displayText = "Enter your keys.";
      const keyCode = e.keyCode;
      if (this.inputKeys.indexOf(keyCode) == -1) {
        this.inputKeys.push(keyCode);
      }
      e.preventDefault();
    },
    onKeyUp(e) {
      if (this.inputKeys.length === 0) {
        this.displayText = "Does not support the input key. Please use another.";
        this.inputKeys = [];
        e.preventDefault();
        return false;
      }
      const checker = this.$_.findIndex(this.inputKeys, (k) => {
        return k >= 91 && k <= 93;
      });
      if (checker !== -1) {
        this.displayText = "Does not support the input key. Please use another.";
        this.inputKeys = [];
        e.preventDefault();
        return false;
      }
      this.getKeys();
      this.close();
      e.preventDefault();
    },
    getKeys() {
      let keyName = "";
      this.inputKeys.forEach((k, i) => {
        if (i === 0) {
          keyName += convertKeyCodeToChar(k);
        } else {
          keyName += " + " + convertKeyCodeToChar(k);
        }
      });
      let checker = [...this.inputKeys];
      let newParam = Object.assign({}, this.param);
      newParam.shift = this.inputKeys.indexOf(16) !== -1;
      newParam.ctrl = this.inputKeys.indexOf(17) !== -1;
      newParam.alt = this.inputKeys.indexOf(18) !== -1;
      this.$_.remove(checker, (k) => {
        return (k >= 16 && k <= 18) || (k >= 91 && k <= 93);
      });
      newParam.keyCode = checker[0];
      newParam.keyName = keyName;
      this.$EventBus.$emit("onShortcut", newParam);
    },
  },
  computed: {
    isEditingShortcut: sync("model/isEditingShortcut"),
  },
};
</script>
