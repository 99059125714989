import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle,
} from "../Formatter.js";

const columnDefsETSPosition = [
  {
    headerName: "Symbol",
    field: "symbol",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 200,
    cellStyle: (params) => ({ ...unrealizedColorStyle(params), "justify-content": "flex-start" }),
  },
  {
    headerName: "TTF",
    field: "ttf",
    width: 80,
    cellStyle: (params) => ({ ...ceilColorStyle(params), "justify-content": "flex-start" }),
  },
  {
    headerName: "Type",
    field: "positionType",
    width: 80,
    cellStyle: sideColorStyle,
  },
  {
    headerName: "Available Vol",
    field: "onHandVol",
    width: 180,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Actual Vol",
    field: "sellableVol",
    width: 180,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Average",
    field: "averagePrice",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Market Price",
    field: "marketPrice",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Cost Value",
    field: "costValue",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Market Value",
    field: "marketValue",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Unrealized P/L",
    field: "unrealized",
    width: 180,
    cellStyle: unrealizedColorStyle,
    valueFormatter: (params) => unrealizedCellFormatter(params.value, "+0,0.00"),
  },
  {
    headerName: "%Unreal",
    field: "unrealizedPercent",
    width: 180,
    cellStyle: unrealizedColorStyle,
    valueFormatter: (params) => unrealizedCellFormatter(params.value, "+0,0.00%", true),
  },
  {
    headerName: "Realized P/L",
    field: "realized",
    width: 180,
    cellStyle: realizedColorStyle,
    valueFormatter: (params) => unrealizedCellFormatter(params.value, "+0,0.00"),
  },
];

const columnDefsDTSPosition = [
  {
    headerName: "Series ID",
    field: "seriesId",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 200,
    cellStyle: (params) => ({ ...unrealPlColorStyle(params), "justify-content": "flex-start" }),
  },
  {
    headerName: "Side",
    field: "side",
    width: 80,
    cellStyle: sideColorStyle,
  },
  {
    headerName: "Actual Vol",
    field: "sellableQty",
    width: 120,
    cellStyle: qtyColorStyle,
  },
  // {
  //   headerName: "Actual Value",
  //   field: "sellableQtyVal",
  //   width: 120,
  //   cellStyle: qtyColorStyle
  // },
  {
    headerName: "Available Vol",
    field: "onHandQty",
    width: 120,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Open Average",
    field: "openAvg",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Mark Average",
    field: "markAvg",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Market Price",
    field: "lastSale",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Settlement Price",
    field: "settlementPrice",
    width: 150,
    cellStyle: totalColorStyle,
  },
  // {
  //   headerName: "Market Value",
  //   field: "marketValue",
  //   width: 150,
  //   cellStyle: totalColorStyle
  // },
  {
    headerName: "Unrealized P/L",
    field: "unrealPl",
    width: 150,
    cellStyle: unrealPlColorStyle,
    valueFormatter: (params) => unrealizedCellFormatter(params.value, "+0,0.00"),
  },
  {
    headerName: "%Unreal",
    field: "unrealPercent",
    width: 150,
    cellStyle: unrealPlColorStyle,
    valueFormatter: (params) => unrealizedCellFormatter(params.value, "+0,0.00%", true),
  },
  // {
  //   headerName: "Unrealized P/L Value",
  //   field: "unrealPlVal",
  //   width: 180,
  //   cellStyle: unrealPlColorStyle,
  //   valueFormatter: params => unrealizedCellFormatter(params.value, "+0,0.00")
  // },
  {
    headerName: "Realized P/L",
    field: "realPl",
    width: 150,
    cellStyle: unrealPlColorStyle,
    valueFormatter: (params) => unrealizedCellFormatter(params.value, "+0,0.00"),
  },
  {
    headerName: "Closed",
    field: "closedQty",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Closed Average",
    field: "closedAvg",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Closed-Cost Average",
    field: "closedCostAvg",
    width: 175,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Open",
    field: "openQty",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Start Of Day",
    field: "startOfDayQty",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Today Open",
    field: "todayOpenQty",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Cost Average",
    field: "costAvg",
    width: 150,
    cellStyle: totalColorStyle,
  },
  // {
  //   headerName: "Cost Value",
  //   field: "costValue",
  //   width: 150,
  //   cellStyle: totalColorStyle
  // },
  {
    headerName: "Unrealized Cost P/L",
    field: "unrealCostPl",
    width: 180,
    cellStyle: unrealPlColorStyle,
    valueFormatter: (params) => unrealizedCellFormatter(params.value, "+0,0.00"),
  },
  {
    headerName: "Available Vol",
    field: "availableQty",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Outstanding Open",
    field: "outstandingOpenQty",
    width: 160,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Outstanding Close",
    field: "outstandingCloseQty",
    width: 160,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Today Premium",
    field: "todayPremium",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Expected Today Premium",
    field: "expectedTodayPremium",
    width: 205,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Order Magin Average",
    field: "ordMarginAvg",
    width: 180,
    cellStyle: totalColorStyle,
  },
];

export { columnDefsETSPosition, columnDefsDTSPosition };
