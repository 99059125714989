import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle
} from "../Formatter.js";
import numeral from "numeral";

const columnDefsETSSeriesPosition = [
  {
    headerName: "Account",
    field: "custId",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 100,
    cellStyle: params => ({ ...totalColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Shares",
    field: "shares",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Average",
    field: "avgPri",
    width: 150,
    valueFormatter: params => (numeral(params.value).value() ? numeralCellFormatter(params, "0,0.0000") : ""),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Cost Amount",
    field: "totPricePaid",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Current Value",
    field: "currentValue",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Unreal P/L",
    field: "unrealPL",
    width: 180,
    valueFormatter: params => (numeral(params.value).value() ? unrealizedCellFormatter(params.value, "+0,0.00") : "0.00"),
    cellStyle: params => {
      if (numeral(params.value).value() === 0) {
        return totalColorStyle();
      } else {
        return numeral(params.value).value() > 0
          ? { ...sideColorStyle({ value: "B" }), "justify-content": "flex-end" }
          : { ...sideColorStyle({ value: "S" }), "justify-content": "flex-end" };
      }
    }
  },
  {
    headerName: "Trader ID",
    field: "ownTid",
    width: 100,
    cellStyle: () => ({ ...totalColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Trader Name",
    field: "ownTidName",
    width: 200,
    cellStyle: () => ({ ...qtyColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Customer Name",
    field: "custName",
    width: 200,
    cellStyle: () => ({ ...qtyColorStyle(), "justify-content": "flex-start" })
  }
  // {
  //   headerName: "Telephone",
  //   field: "mobile",
  //   width: 150,
  //   cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  // }
];

const columnDefsDTSSeriesPosition = [
  {
    headerName: "Account",
    field: "custId",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 100,
    cellStyle: params => ({ ...totalColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Side",
    field: "side",
    width: 65,
    cellStyle: sideColorStyle
  },
  {
    headerName: "Actual Vol",
    field: "sellableVol",
    width: 150,
    valueFormatter: params => (numeral(params.value).value() ? numeralCellFormatter(params, "0,0") : ""),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Cost Avg",
    field: "avgPri",
    width: 150,
    valueFormatter: params => (numeral(params.value).value() ? numeralCellFormatter(params, "0,0.0000") : ""),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Last Sale",
    field: "lstSale",
    width: 150,
    valueFormatter: params => (numeral(params.value).value() ? numeralCellFormatter(params, "0,0.0000") : ""),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Mark Avg",
    field: "markAvg",
    width: 150,
    valueFormatter: params => (numeral(params.value).value() ? numeralCellFormatter(params, "0,0.0000") : ""),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Cost Amount",
    field: "totPricePaid",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Current Value",
    field: "currentValue",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Unreal P/L",
    field: "unrealPL",
    width: 180,
    valueFormatter: params => (numeral(params.value).value() ? unrealizedCellFormatter(params.value, "+0,0.00") : "0.00"),
    cellStyle: params => {
      if (numeral(params.value).value() === 0) {
        return totalColorStyle();
      } else {
        return numeral(params.value).value() > 0
          ? { ...sideColorStyle({ value: "B" }), "justify-content": "flex-end" }
          : { ...sideColorStyle({ value: "S" }), "justify-content": "flex-end" };
      }
    }
  },
  {
    headerName: "Trader ID",
    field: "ownTid",
    width: 100,
    cellStyle: () => ({ ...totalColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Trader Name",
    field: "ownTidName",
    width: 200,
    cellStyle: () => ({ ...qtyColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Customer Name",
    field: "custName",
    width: 200,
    cellStyle: () => ({ ...qtyColorStyle(), "justify-content": "flex-start" })
  }
];

export { columnDefsETSSeriesPosition, columnDefsDTSSeriesPosition };
