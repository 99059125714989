<template>
  <div class="ui grid" style="height: 100%">
    <div class="sixteen wide column">
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer; z-index: 1">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
      <ag-grid-vue
        style="width: 100%; height: 100%; padding: 0px"
        :class="{
          'ag-theme-balham-dark': theme === 'Dark',
          'ag-theme-balham': theme === 'Light',
        }"
        row-selection="single"
        :grid-options="gridMarketMessageOptions"
        :column-defs="columnDefsMarketMessage"
        :row-data="rowMarketMessage"
        :row-height="21"
        :header-height="32"
        :default-col-def="{ sortable: false, resizable: true }"
        :animate-rows="true"
        :suppress-drag-leave-hides-columns="true"
        :enable-cell-change-flash="false"
        :locale-text="{ noRowsToShow: `Don't have any Message.` }"
        :row-drag-managed="false"
        overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
        @grid-ready="onMarketMessageGridReady"
        @cell-focused="onCellMarketMessageFocused"
      />
    </div>
  </div>
</template>

<script>
import { sync, get } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsMarketMessage } from "@/components/grid/marketmessage/MarketMessageColumnDefs.js";
import { DateTime } from "luxon";

Object.freeze(columnDefsMarketMessage);

export default {
  name: "MarketMessage",
  components: {
    AgGridVue,
  },
  data: () => ({
    isLoading: false,
    columnDefsMarketMessage,
    gridMarketMessageApi: null,
    columnMarketMessageApi: null,
    rowMarketMessage: [],
    // systemMessageLatest: []
  }),
  created() {
    this.gridMarketMessageOptions = {};
    this.XR02();
    this.XR03("XB01"); /* SystemMessageBcst */
    this.XR03("NB01"); /* NewsMessageBcst */

    this.$EventBus.$on("ctinf/XS02", this.systemMessageCinf);

    this.$EventBus.$on("bcst/XB01", this.systemMessageBcst);
    this.$EventBus.$on("bcst/NB01", this.newsMessageBcst);
    this.$EventBus.$on("ntf/ON01", this.onRecieveETSInsertNotify);
    this.$EventBus.$on("ntf/ON03", this.onRecieveETSMatchedNotify);
    this.$EventBus.$on("ntf/ON04", this.onRecieveETSCancelNotify);
    this.$EventBus.$on("ntf/ON05", this.onRecieveETSUpdateNotify);
    this.$EventBus.$on("ntf/ON06", this.onRecieveETSRejectNotify);
    this.$EventBus.$on("ntf/ON51", this.onRecieveDTSInsertNotify);
    this.$EventBus.$on("ntf/ON53", this.onRecieveDTSMatchedNotify);
    this.$EventBus.$on("ntf/ON54", this.onRecieveDTSCancelNotify);
    this.$EventBus.$on("ntf/ON55", this.onRecieveDTSUpdateNotify);
    this.$EventBus.$on("ntf/ON56", this.onRecieveDTSRejectNotify);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/XS02", this.systemMessageCinf);

    this.$EventBus.$off("bcst/XB01", this.systemMessageBcst);
    this.$EventBus.$off("bcst/NB01", this.newsMessageBcst);
    this.$EventBus.$off("ntf/ON01", this.onRecieveETSInsertNotify);
    this.$EventBus.$off("ntf/ON03", this.onRecieveETSMatchedNotify);
    this.$EventBus.$off("ntf/ON04", this.onRecieveETSCancelNotify);
    this.$EventBus.$off("ntf/ON05", this.onRecieveETSUpdateNotify);
    this.$EventBus.$off("ntf/ON06", this.onRecieveETSRejectNotify);
    this.$EventBus.$off("ntf/ON51", this.onRecieveDTSInsertNotify);
    this.$EventBus.$off("ntf/ON53", this.onRecieveDTSMatchedNotify);
    this.$EventBus.$off("ntf/ON54", this.onRecieveDTSCancelNotify);
    this.$EventBus.$off("ntf/ON55", this.onRecieveDTSUpdateNotify);
    this.$EventBus.$off("ntf/ON56", this.onRecieveDTSRejectNotify);
  },
  methods: {
    onRefresh() {
      this.XR02();
    },
    XR02() {
      this.isLoading = true;
      const msg = this.$messageFactory.createMessage("XR02");

      this.$ws.send(msg);
      if (this.gridMarketMessageApi) {
        this.gridMarketMessageApi.showLoadingOverlay();
      }
    },
    XR03(messageCode) {
      const msg = this.$messageFactory.createMessage("XR03");

      msg.messageCode.set(messageCode);

      this.$ws.send(msg);
    },
    onMarketMessageGridReady(params) {
      this.gridMarketMessageApi = params.api;
      this.columnMarketMessageApi = params.columnApi;
    },
    onCellMarketMessageFocused(event) {
      this.gridMarketMessageOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    systemMessageCinf(systemMessageCinf) {
      this.rowMarketMessage = systemMessageCinf.messages || [];
      this.mergeOrderNotify();
      if (this.gridMarketMessageApi) {
        this.gridMarketMessageApi.hideOverlay();
      }
      // Clone vuex array
      // const newData = systemMessageCinf.messages.map((o, idx) => {
      //   return Object.assign({ index: idx }, o);
      // });
      // this.systemMessageLatest = newData;
      this.isLoading = false;
    },
    systemMessageBcst(systemMessageBcst) {
      // Clone vuex object
      //const newData = Object.assign({}, systemMessageBcst);
      // this.rowMarketMessage.unshift(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },
    newsMessageBcst(newsMessageBcst) {
      const systemMessageBcst = {
        time: newsMessageBcst.datetime.substring(11),
        message: newsMessageBcst.symbol + " " + newsMessageBcst.headline,
      };
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },

    onRecieveETSInsertNotify(response) {
      const orderId = response.orderId.replace("1_", "");
      const systemMessageBcst = {
        time: this.$moment().format("HH:mm:ss"),
        message: `Order No. ${orderId} was sent`,
      };

      this.saveOrderNotifyToStorage(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },
    onRecieveETSMatchedNotify(response) {
      const orderId = response.orderId.replace("1_", "");
      const systemMessageBcst = {
        time: this.$moment().format("HH:mm:ss"),
        message: `Order No. ${orderId} was matched`,
      };

      this.saveOrderNotifyToStorage(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },

    onRecieveETSCancelNotify(response) {
      const orderId = response.orderId.replace("1_", "");
      const systemMessageBcst = {
        time: this.$moment().format("HH:mm:ss"),
        message: `Order No. ${orderId} was cancelled`,
      };

      this.saveOrderNotifyToStorage(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },

    onRecieveETSUpdateNotify(response) {
      const orderId = response.orderId.replace("1_", "");
      const systemMessageBcst = {
        time: this.$moment().format("HH:mm:ss"),
        message: `Order No. ${orderId} was updated`,
      };

      this.saveOrderNotifyToStorage(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },

    onRecieveETSRejectNotify(response) {
      const orderId = response.orderId.replace("1_", "");
      const systemMessageBcst = {
        time: this.$moment().format("HH:mm:ss"),
        message: `Order No. ${orderId} was rejected`,
      };

      this.saveOrderNotifyToStorage(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },

    onRecieveDTSInsertNotify(response) {
      const orderId = response.orderId.replace("2_", "");
      const systemMessageBcst = {
        time: this.$moment().format("HH:mm:ss"),
        message: `TFEX Order No. ${orderId} was matched`,
      };

      this.saveOrderNotifyToStorage(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },

    onRecieveDTSMatchedNotify(response) {
      const orderId = response.orderId.replace("2_", "");
      const systemMessageBcst = {
        time: this.$moment().format("HH:mm:ss"),
        message: `TFEX Order No. ${orderId} was matched`,
      };

      this.saveOrderNotifyToStorage(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },

    onRecieveDTSUpdateNotify(response) {
      const orderId = response.orderId.replace("2_", "");
      const systemMessageBcst = {
        time: this.$moment().format("HH:mm:ss"),
        message: `TFEX Order No. ${orderId} was updated`,
      };

      this.saveOrderNotifyToStorage(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },

    onRecieveDTSCancelNotify(response) {
      const orderId = response.orderId.replace("2_", "");
      const systemMessageBcst = {
        time: this.$moment().format("HH:mm:ss"),
        message: `TFEX Order No. ${orderId} was cancelled`,
      };

      this.saveOrderNotifyToStorage(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },

    onRecieveDTSRejectNotify(response) {
      const orderId = response.orderId.replace("2_", "");
      const systemMessageBcst = {
        time: this.$moment().format("HH:mm:ss"),
        message: `TFEX Order No. ${orderId} was rejected`,
      };

      this.saveOrderNotifyToStorage(systemMessageBcst);
      this.gridMarketMessageOptions.api.batchUpdateRowData({ add: [systemMessageBcst], addIndex: 0 }, () => {});
    },

    saveOrderNotifyToStorage(message) {
      const orderNotify = localStorage.getItem("order-notify");

      if (orderNotify) {
        const parsedOrderNotify = JSON.parse(orderNotify);

        if (parsedOrderNotify.user === this.userName) {
          parsedOrderNotify.messages.splice(0, 0, message);
        }

        return localStorage.setItem("order-notify", JSON.stringify(parsedOrderNotify));
      }

      const temptOrderNotify = { user: this.userName, messages: [message], createdAt: DateTime.now().toFormat("dd-LL-yyyy") };
      return localStorage.setItem("order-notify", JSON.stringify(temptOrderNotify));
    },

    mergeOrderNotify() {
      const orderNotify = localStorage.getItem("order-notify");

      if (orderNotify) {
        const parsedOrderNotify = JSON.parse(orderNotify);

        if (parsedOrderNotify.user !== this.userName) return;

        const marketMessage = [...this.rowMarketMessage, ...parsedOrderNotify.messages];

        marketMessage.sort((message1, message2) => {
          const message1Date = DateTime.fromFormat(message1.time, "HH:mm:ss");
          const message2Date = DateTime.fromFormat(message2.time, "HH:mm:ss");

          return message2Date - message1Date;
        });

        this.rowMarketMessage = marketMessage;
      }
    },
  },
  computed: {
    theme: sync("global/theme"),
    userName: get("model/systemRefData@username"),
  },
};
</script>

<style scoped>
.ui.table thead {
  background: rgb(32, 38, 43);
}

.ui.table.inverted td.active,
.ui.table.inverted tr.active {
  background: rgba(224, 224, 224, 0.2) !important;
}

tbody {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(30vh - 30px - 45px);
  width: 25vw;
}
thead tr {
  display: block;
}

table > tbody > tr > td:nth-child(1) {
  width: 3.8vw;
}

table > tbody > tr > td:nth-child(2) {
  width: 21.2vw;
}

@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}
</style>
