import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle,
  valueSideColorStyle
} from "../Formatter.js";
import { getAccountTypeByKey } from "@/utils/AccountTypeFormatter.js";
import { getCreditTypeByKey } from "@/utils/CreditTypeFormatter.js";
import { getCustomerTypeByKey } from "@/utils/CustomerTypeFormatter.js";
import { getCustomerStatusByKey } from "@/utils/CustomerStatusFormatter.js";
import { getCustomerFlagByKey } from "@/utils/CustomerFlagFormatter.js";
import { getVIPFlagByKey } from "@/utils/VIPFlagFormatter.js";
import { getMutualFlagByKey } from "@/utils/MutualFlagFormatter.js";

const columnDefsETSBrokerInformationByAccountTypeAndCustomerType = [
  {
    headerName: "Account Type",
    field: "type",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 150,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Buy Value",
    field: "buyVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "%B-Value Brk.",
    field: "buyValBrkPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "Sell Value",
    field: "sellVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },
  {
    headerName: "%S-Value Brk.",
    field: "sellValBrkPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },

  {
    headerName: "Total Value",
    field: "totalVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "%Total Val Brk.",
    field: "totalValBrkPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Buy Volume",
    field: "buyVol",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "%B-Vol Brk.",
    field: "buyVolBrkPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "Sell Volume",
    field: "sellVol",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },
  {
    headerName: "%S-Vol Brk.",
    field: "sellVolBrkPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },
  {
    headerName: "Total Volume",
    field: "totalVol",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "%Tot Vol Brk.",
    field: "totalVolBrkPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: qtyColorStyle
  }
];

const columnDefsDTSBrokerInformationByAccountTypeAndCustomerType = columnDefsETSBrokerInformationByAccountTypeAndCustomerType;

export { columnDefsETSBrokerInformationByAccountTypeAndCustomerType, columnDefsDTSBrokerInformationByAccountTypeAndCustomerType };
