<template>
  <div>
    <sui-modal v-model="dialog" size="large">
      <sui-modal-header>
        <span>Change price from {{ fromPrice }} to {{ toPrice }}</span>
        <div class="close" @click="close"><i class="close grey icon"></i></div>
      </sui-modal-header>
      <div style="clear: float;"></div>
      <sui-modal-content>
        <div style="height: 25vh;">
          <ag-grid-vue
            style="width: 100%; height: 100%; padding: 0px;"
            :class="{
              'ag-theme-balham-dark': theme === 'Dark',
              'ag-theme-balham': theme === 'Light'
            }"
            :column-defs="ColumnDefs"
            :row-data="data"
            :row-height="21"
            :header-height="32"
            :default-col-def="{ resizable: true, sortable: true }"
            :animate-rows="true"
            :row-deselection="true"
            row-selection="multiple"
            :suppress-drag-leave-hides-columns="false"
            :locale-text="{ noRowsToShow: `Don't have any Order.` }"
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"
          />
        </div>
      </sui-modal-content>
      <sui-modal-actions>
        <form @submit.prevent="confirm">
          <sui-button type="button" negative @click="confirm">Confirm</sui-button>
        </form>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import ColumnDefs from "@/components/grid/orderSelect/ColumnDefs";
import { parseStringEtsDateToEtsDate } from "@/utils/DateUtils";
import { isEditableOrderStatus } from "@/utils/ValidationUtils";
export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      ColumnDefs,
      fromPrice: 0,
      toPrice: 0,
      dialog: false,
      rowSelected: [],
      data: [],
      pin: null,
      gridApi: null,
      columnApi: null,
      refIds: [],
      successReponses: [],
      errorReponses: [],
      resolve: null
    };
  },
  methods: {
    manageOrderColumns() {
      const derivativeColumns = ["validity", "validityData"];
      if (this.displayState === "all") {
        this.columnApi.setColumnsVisible([...derivativeColumns], true);
      } else if (this.displayState === "equity") {
        this.columnApi.setColumnsVisible(derivativeColumns, false);
      } else if (this.displayState === "derivative") {
        this.columnApi.setColumnsVisible(derivativeColumns, true);
      }
    },
    selectOrder(market, fromPrice, toPrice, pin, rows) {
      this.refIds = [];
      this.successReponses = [];
      this.errorReponses = [];
      this.fromPrice = fromPrice;
      this.toPrice = toPrice;
      this.pin = pin;
      this.dialog = true;
      this.data = rows;

      const derivativeColumns = ["validity", "validityData"];
      this.columnApi.setColumnsVisible([...derivativeColumns], true);
      if (market === "SET") {
        this.columnApi.setColumnsVisible(derivativeColumns, false);
      } else if (market === "TFEX") {
        this.columnApi.setColumnsVisible(derivativeColumns, true);
      }

      this.$nextTick(() => {
        this.gridApi.forEachLeafNode(node => {
          node.setSelected(true);
        });
      });
      return new Promise(resolve => {
        this.resolve = resolve;
      });
    },
    close() {
      this.dialog = false;
      this.resolve(null);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onSelectionChanged(event, row) {
      let selectedDataRows = this.gridApi.getSelectedRows();
      selectedDataRows = this.$_.filter(selectedDataRows, r => {
        return isEditableOrderStatus(r);
      });
      this.rowSelected = selectedDataRows;
    },
    confirm() {
      this.resolve(this.rowSelected);
      this.dialog = false;
    }
  },
  computed: {
    theme: sync("global/theme")
  }
};
</script>
