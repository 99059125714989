<template>
  <div
    class="ui input"
    :class="{ error: displayErrorText, disabled: disabled }"
    :data-tooltip="displayErrorText"
    data-position="top center"
    data-inverted="inverted"
  >
    <input
      ref="input"
      v-model="displayNumber"
      :style="inputStyle"
      type="text"
      :placeholder="placeholder"
      @focus="onFocus"
      @blur="onBlur"
      @keydown="onKeyDown"
      @keyup.enter="onEnter"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    format: {
      type: [String, Function],
      default: "0,0.00",
    },
    isValidate: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    errorText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputStyle: {
      type: String,
    },
  },
  data() {
    return {
      type: "text",
      displayNumber: null,
      rawNumber: null,
      displayErrorText: null,
    };
  },
  watch: {
    value(value) {
      if (value === null) {
        this.displayNumber = null;
        this.rawNumber = null;
      } else if (value != undefined && value != null && typeof this.value === "number") {
        this.displayNumber = this.getFormatValue(value);
        this.rawNumber = value;
      }

      this.validate();
    },
    displayNumber(value) {
      if (this.type === "number") {
        this.rawNumber = value || null;
      }
    },
    errorText(value) {
      this.displayErrorText = value;
    },
  },
  created() {
    if (this.value != undefined && this.value != null && typeof this.value === "number") {
      this.displayNumber = this.getFormatValue(this.value);
      this.rawNumber = this.value;
    }
    if (!this.errorText) {
      this.displayErrorText = this.errorText;
    }
  },
  methods: {
    getFormatValue(value) {
      if (typeof this.format === "function") {
        return this.format(value);
      } else if (typeof this.format === "string") {
        return this.$numeral(value).format(this.format);
      } else {
        return value;
      }
    },
    clearValidate() {
      this.displayErrorText = null;
    },
    validate() {
      this.clearValidate();

      if (this.disabled) {
        return true;
      }

      let value = null;
      if (this.displayNumber) {
        value = this.rawNumber;
      }

      if (this.required && (value === null || value === undefined)) {
        this.displayErrorText = this.name + " is requried.";
        this.$emit("onErrorText", this.displayErrorText);
        return false;
      }
      if (this.min != null && this.min != undefined && value < this.min) {
        this.displayErrorText = `${this.name} must be more than ${this.min}.`;
        this.$emit("onErrorText", this.displayErrorText);
        return false;
      }
      if (this.max != null && this.max != undefined && value > this.max) {
        this.displayErrorText = `${this.name} must be less than ${this.max}.`;
        this.$emit("onErrorText", this.displayErrorText);
        return false;
      }

      return true;
    },
    customError(text) {
      this.displayErrorText = text;
      this.$emit("onErrorText", this.displayErrorText);
    },
    onFocus() {
      this.type = "number";
      this.displayNumber = null;
      let value = null;
      if (this.rawNumber != null) {
        value = this.rawNumber;
      }
      this.displayNumber = value ? value.toString() : null;
      this.$nextTick(() => {
        this.$refs.input.select();
      });
    },
    onBlur() {
      this.type = "text";

      let value = null;

      if (this.displayNumber != null && !isNaN(this.displayNumber.replaceAll(",", "")) && this.displayNumber !== "")
        value = Number(this.displayNumber.replaceAll(",", ""));

      this.rawNumber = value;
      this.displayErrorText = null;

      const validate = this.validate();

      if (!validate) {
        this.$emit("input", null);
        return;
      }

      this.$emit("input", this.rawNumber);

      if (value) {
        this.displayNumber = this.getFormatValue(value);
      } else {
        this.displayNumber = value;
      }
      this.$refs.input.blur();
    },
    onKeyDown(e) {
      if (e.ctrlKey || e.shiftKey || e.altKey) {
        return true;
      }
      const allowKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
      const allowKeyCodes = [8, 46, 35, 36, 37, 38, 39, 40, 9];
      if (!allowKeys.includes(e.key) && !allowKeyCodes.includes(e.keyCode)) {
        e.preventDefault();
        return false;
      }
      return true;
    },
    onEnter(e) {
      this.type = "text";

      let value = null;

      if (this.displayNumber != null && !isNaN(this.displayNumber.replaceAll(",", "")) && this.displayNumber !== "")
        value = Number(this.displayNumber.replaceAll(",", ""));

      this.rawNumber = value;
      this.displayErrorText = null;

      const validate = this.validate();

      if (!validate) {
        this.$emit("enter", null);
        return;
      }

      this.$emit("enter", this.rawNumber);

      if (value) {
        this.displayNumber = this.getFormatValue(value);
      } else {
        this.displayNumber = value;
      }
      this.$refs.input.blur();
    },
    isDisabled() {
      return this.disabled;
    },
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
  },
};
</script>

<style scoped>
.ui.input input {
  color: #dcddde;
  background: #2f383f;
  padding: 0 0.25rem;
  font-weight: 700;
  height: 2rem;
}
.ui.input input:focus {
  color: #dcddde !important;
  background: #2f383f !important;
}
input:read-only {
  border: 0 !important;
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
</style>
