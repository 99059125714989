<template>
  <div class="top-broker" ref="top-broker">
    <sui-tab :menu="{ attached: 'top' }" :active-index.sync="activeIndex" class="top-broker-tabs">
      <sui-tab-pane class="top-broker-tab" title="Highest Total Value" attached="top">
        <apexchart ref="chart" type="bar" :width="dynamicWidth" :height="dynamicHeight" :options="chartOptions" :series="series" :key="updateKey" />
      </sui-tab-pane>
      <sui-tab-pane class="top-broker-tab" title="Lowest Total Value" attached="top">
        <apexchart type="bar" :width="dynamicWidth" :height="dynamicHeight" :options="chartOptions" :series="series" :key="updateKey" />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

const series = [
  {
    name: "Buy",
    data: [],
  },
  {
    name: "Sell",
    data: [],
  },
];

const chartOptions = {
  theme: {
    mode: "dark",
  },
  grid: {
    borderColor: "#2F383F",
  },
  fill: {
    type: "gradient",
  },
  chart: {
    stacked: true,
    toolbar: {
      show: false,
      tools: {
        selection: false,
        zoom: false,
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      barHeight: "60%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#2185d0", "#E03997"],
  stroke: {
    width: 0,
    color: "#1B2025",
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    labels: {
      formatter: (value) => {
        if (value > 1000000) {
          value /= 1000000;
          return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " MB";
        } else {
          return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
    },
  },
  tooltip: {
    y: {
      formatter: (value) => {
        return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    offsetX: 0,
  },
};

export default {
  name: "MarketStockGraph",
  components: {},
  props: ["data"],
  data: () => ({
    isLoading: false,
    series: series,
    chartOptions: chartOptions,
    activeIndex: 0,
    dynamicWidth: 100,
    dynamicHeight: 100,
    updateKey: 0,
  }),
  watch: {
    data(data) {
      if (data.records?.length) {
        this.drawTopBroker(data);
      } else {
        this.series = series;
        this.chartOptions = chartOptions;
      }
    },
    activeIndex(activeIndex) {
      if (activeIndex === 0) {
        this.drawTopBroker(this.data);
      } else {
        const reverseData = this.$_.cloneDeep(this.data);
        reverseData.records.reverse();
        this.drawTopBroker(reverseData);
      }
    },
  },
  created() {
    this.$EventBus.$on("onBrokerStartRefresh", this.onBrokerStartRefresh);
    this.$EventBus.$on("onBrokerStopRefresh", this.onBrokerStopRefresh);
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.$EventBus.$off("onBrokerStartRefresh", this.onBrokerStartRefresh);
    this.$EventBus.$off("onBrokerStopRefresh", this.onBrokerStopRefresh);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onBrokerStartRefresh");
      this.activeIndex = 0;
    },
    onBrokerStartRefresh() {
      this.isLoading = true;
    },
    onBrokerStopRefresh() {
      this.isLoading = false;
    },
    drawTopBroker(data) {
      this.series = [
        {
          name: "Buy",
          data: data.records.slice(0, 10).map((record) => record.buyVal),
        },
        {
          name: "Sell",
          data: data.records.slice(0, 10).map((record) => record.sellVal),
        },
      ];
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: data.records.slice(0, 10).map((record) => record.brokerName),
        },
      };
    },
    onResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      this.dynamicHeight = 0;
      this.dynamicWidth = 0;

      this.resizeTimeout = setTimeout(() => {
        const chartContainer = this.$refs["top-broker"];

        this.dynamicHeight = chartContainer.clientHeight - 32;
        this.dynamicWidth = chartContainer.clientWidth - 40;
        this.updateKey++;
      }, 200);
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/company/top-broker.scss";
</style>
