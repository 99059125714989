/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class FR06 extends WsReqMsg {
	constructor() {
		super(1);
		this._alertId = new StringMessageBase();
		this._userId = new StringMessageBase();
	}
	
	get alertId() {
		return this._alertId;
	}
	
	set alertId(alertId) {
		this._alertId = new StringMessageBase(alertId);
	}
	
	get userId() {
		return this._userId;
	}
	
	set userId(userId) {
		this._userId = new StringMessageBase(userId);
	}
}

export default FR06;
