<template>
  <div
    class="ui input"
    :class="{ error: displayErrorText, disabled: disabled }"
    :data-tooltip="displayErrorText"
    data-position="top center"
    data-inverted="inverted"
  >
    <input
      ref="input"
      v-model="displayNumber"
      :style="inputStyle"
      type="text"
      :placeholder="placeholder"
      @focus="onFocus"
      @blur="onBlur"
      @keydown="onKeyDown"
      @keyup.enter="onEnter"
    />
  </div>
</template>

<script>
export default {
  props: {
    market: {
      type: String,
      default: "SET",
    },
    inputStyle: {
      type: String,
      default: "text-transform: uppercase;",
    },
    value: {
      type: [Number, String],
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    format: {
      type: [String, Function],
      default: "0,0.00",
    },
    isValidate: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    errorText: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allowedText: ["MO", "ML"],
      displayNumber: "",
    };
  },
  watch: {
    value(value) {
      if (value === null) {
        this.displayNumber = null;
        this.rawNumber = null;
      } else if (value != undefined && value != null) {
        if (!isNaN(value)) {
          this.displayNumber = this.getFormatValue(value);
        } else {
          this.displayNumber = value.toUpperCase();
          this.rawNumber = value.toUpperCase();
        }
      }
      this.validate();
    },
    displayNumber(value) {
      if (isNaN(value)) {
        this.displayNumber = value.toUpperCase();
        const checker = this.$numeral(this.displayNumber).value();
        if (checker === null) {
          this.rawNumber = value.toUpperCase();
        }
      } else {
        let newValue = null;
        if (value) {
          newValue = parseFloat(value);
        }
        this.rawNumber = newValue;
      }
    },
  },
  created() {
    if (this.market === "SET") {
      const SETOptions = ["ATO", "ATC"];
      this.allowedText = [...SETOptions, ...this.allowedText];
    }
  },
  methods: {
    getFormatValue(value) {
      if (typeof this.format === "function") {
        return this.format(value);
      } else if (typeof this.format === "string") {
        return this.$numeral(value).format(this.format);
      } else {
        return value;
      }
    },
    clearValidate() {
      this.displayErrorText = null;
    },
    validate() {
      this.clearValidate();
      if (this.disabled) {
        return true;
      }

      let value = null;

      if (this.displayNumber) {
        value = this.rawNumber;
      }

      if (this.required && (value === null || value === undefined)) {
        this.displayErrorText = this.name + " is requried.";
        this.$emit("onErrorText", this.displayErrorText);
        return false;
      }

      if (isNaN(value)) {
        if (value) {
          if (!this.allowedText.includes(value.toUpperCase())) {
            this.displayErrorText = "Invalid price. It should be " + this.allowedText;
            this.$emit("onErrorText", this.displayErrorText);
            return false;
          }
        }
      }

      return true;
    },
    onFocus() {
      let value = null;
      if (this.rawNumber) {
        value = this.rawNumber;
      }
      this.displayNumber = value ? value.toString() : null;
      this.$nextTick(() => {
        this.$refs.input.select();
      });
    },
    onEnter(e) {
      let value = null;
      if (this.displayNumber) {
        value = this.displayNumber.replaceAll(",", "");
      }
      this.displayErrorText = null;

      const validate = this.validate();

      this.$emit("input", !validate ? null : this.rawNumber);

      if (!isNaN(value)) {
        if (value) {
          this.displayNumber = this.getFormatValue(value);
        } else {
          this.displayNumber = value;
        }
      } else {
        this.displayNumber = null;
      }

      this.$nextTick(() => {
        if (validate) return this.$emit("enter", e);
      });
    },
    onBlur() {
      let value = null;
      if (this.displayNumber) {
        value = this.displayNumber.replaceAll(",", "");
      }
      this.displayErrorText = null;

      const validate = this.validate();
      if (!validate) {
        return this.$emit("input", null);
      }

      this.$emit("input", this.rawNumber);

      if (!isNaN(value)) {
        if (value) {
          this.displayNumber = this.getFormatValue(value);
        } else {
          this.displayNumber = value;
        }
      }
    },
    onKeyDown(e) {
      if (e.key === "Enter") e.preventDefault();
      if (e.ctrlKey || e.shiftKey || e.altKey) {
        return true;
      }
      if (this.displayNumber && this.$refs.input.selectionStart === 0 && this.$refs.input.selectionEnd === this.displayNumber.toString().length) {
        return true;
      }
      const allowKeyCodes = [8, 46, 35, 36, 37, 38, 39, 40, 9];

      if (this.displayNumber && !isNaN(this.displayNumber.replaceAll(",", ""))) {
        const allowKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
        if (!allowKeys.includes(e.key) && !allowKeyCodes.includes(e.keyCode)) {
          e.preventDefault();
          return false;
        }
      } else {
        if (this.displayNumber && this.displayNumber.length > 2 && !allowKeyCodes.includes(e.keyCode)) {
          e.preventDefault();
          return false;
        }
        setTimeout(() => {
          this.validate();
        }, 10);
      }
      return true;
    },
    isDisabled() {
      return this.disabled;
    },
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
  },
};
</script>

<style scoped>
.ui.input input {
  color: #dcddde;
  background: #2f383f;
  padding: 0 0.25rem;
  font-weight: 700;
  height: 2rem;
}
.ui.input input:focus {
  color: #dcddde !important;
  background: #2f383f !important;
}
input:read-only {
  border: 0 !important;
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
</style>
