<template>
  <div class="pie-chart-value" ref="pie-chart-value">
    <sui-tab :menu="{ attached: 'top' }" class="pie-chart-value-tabs">
      <sui-tab-pane class="pie-chart-value-tab" title="Value" attached="top">
        <div v-if="$_.sum(seriesValue) === 0" class="empty-text">
          {{ "Don't have any Value." }}
        </div>
        <apexchart
          v-else
          type="pie"
          :width="dynamicWidth"
          :height="dynamicHeight"
          :options="chartOptionsValue"
          :series="seriesValue"
          :key="updateKey"
        />
      </sui-tab-pane>
      <sui-tab-pane class="pie-chart-value-tab" title="Commission" attached="top">
        <div v-if="$_.sum(seriesComm) === 0" style="position: absolute; color: #dcddde; z-index: 1; top: 40%; left: 15%; font-size: 12px">
          {{ "Don't have any Commission." }}
        </div>
        <apexchart
          type="pie"
          :width="dynamicWidth"
          :height="dynamicHeight"
          :options="chartOptionsComm"
          :series="seriesComm"
          :key="updateKey"
          v-else
        />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

const chartOptionsValue = {
  labels: ["N/A"],
  chart: {
    width: 0,
  },
  theme: {
    mode: "dark",
  },
  colors: ["#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800", "#FF4560"],
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: "gradient",
  },
  stroke: {
    width: 0,
    color: "#1B2025",
  },
  legend: {
    position: "right",
    offsetX: 0,
    offsetY: 0,
    formatter: function (val, opts) {
      val = val.length > 10 ? val.slice(0, 7) + "..." : val;
      if (opts.w.globals.seriesPercent[opts.seriesIndex]?.[0]) {
        return " " + val + " : " + opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(2) + "%";
      } else {
        return " " + val + " : " + "0%";
      }
    },
  },
  tooltip: {
    y: {
      formatter: (value) => {
        if (value) {
          return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return "0";
        }
      },
    },
  },
};

const chartOptionsComm = {
  labels: ["N/A"],
  theme: {
    mode: "dark",
  },
  colors: ["#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800", "#FF4560"],
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: "gradient",
  },
  stroke: {
    width: 0,
    color: "#1B2025",
  },
  legend: {
    position: "right",
    offsetX: 0,
    offsetY: 0,
    formatter: function (val, opts) {
      val = val.length > 10 ? val.slice(0, 7) + "..." : val;
      if (opts.w.globals.seriesPercent[opts.seriesIndex]?.[0]) {
        return " " + val + " : " + opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(2) + "%";
      } else {
        return " " + val + " : " + "0%";
      }
    },
  },
  tooltip: {
    y: {
      formatter: (value) => {
        if (value) {
          return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return "0";
        }
      },
    },
  },
};

export default {
  name: "ValuePieChart",
  components: {},
  props: ["mode", "data"],
  data: () => ({
    isLoading: false,
    seriesValue: [100],
    chartOptionsValue: chartOptionsValue,
    seriesComm: [100],
    chartOptionsComm: chartOptionsComm,
    dynamicHeight: 100,
    dynamicWidth: 100,
    updateKey: 0,
  }),
  watch: {
    data(data) {
      if (data.records?.length && this.mode === "Company") {
        this.seriesValue = data.records.slice(0, 5).map((value) => value.totalVal);
        this.seriesValue.push(data.brokerVal - this.$_.sum(this.seriesValue));
        this.chartOptionsValue = { ...chartOptionsValue, labels: [...data.records.slice(0, 5).map((value) => value.departmentName), "Other"] };

        this.seriesComm = data.records.slice(0, 5).map((value) => value.commission);
        this.seriesComm.push(data.commission - this.$_.sum(this.seriesComm));
        this.chartOptionsComm = { ...chartOptionsComm, labels: [...data.records.slice(0, 5).map((value) => value.departmentName), "Other"] };
      }

      if (data.traders?.length && this.mode === "Group") {
        this.seriesValue = data.traders.slice(0, 5).map((value) => value.val);
        this.seriesValue.push(data.totalBrkVal - this.$_.sum(this.seriesValue));
        this.chartOptionsValue = { ...chartOptionsValue, labels: [...data.traders.slice(0, 5).map((value) => value.traderId), "Other"] };

        this.seriesComm = data.traders.slice(0, 5).map((value) => value.commission);
        this.seriesComm.push(data.commission - this.$_.sum(this.seriesComm));
        this.chartOptionsComm = { ...chartOptionsComm, labels: [...data.traders.slice(0, 5).map((value) => value.traderId), "Other"] };
      } else if (this.mode === "Group") {
        this.chartOptionsValue = chartOptionsValue;
        this.chartOptionsComm = chartOptionsComm;
        this.seriesValue = [100];
        this.seriesComm = [100];
      }

      if (data.records?.length && this.mode === "Trader") {
        this.seriesValue = data.records.slice(0, 5).map((value) => value.totalVal);
        this.seriesValue.push(data.totalVal - this.$_.sum(this.seriesValue));
        this.chartOptionsValue = { ...chartOptionsValue, labels: [...data.records.slice(0, 5).map((value) => value.traderId), "Other"] };

        this.seriesComm = data.records.slice(0, 5).map((value) => value.commission);
        this.seriesComm.push(data.commission - this.$_.sum(this.seriesComm));
        this.chartOptionsComm = { ...chartOptionsComm, labels: [...data.records.slice(0, 5).map((value) => value.traderId), "Other"] };
      }
    },
  },
  created() {
    this.resizeTimeout = null;
    this.$EventBus.$on("onValueStartRefresh", this.onValueStartRefresh);
    this.$EventBus.$on("onValueStopRefresh", this.onValueStopRefresh);
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.$EventBus.$off("onValueStartRefresh", this.onValueStartRefresh);
    this.$EventBus.$off("onValueStopRefresh", this.onValueStopRefresh);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onValueStartRefresh");
    },
    onValueStartRefresh() {
      this.isLoading = true;
    },
    onValueStopRefresh() {
      this.isLoading = false;
    },
    onResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      this.dynamicWidth = 0;
      this.dynamicHeight = 0;

      this.resizeTimeout = setTimeout(() => {
        const chartContainer = this.$refs["pie-chart-value"];

        this.dynamicHeight = chartContainer.clientHeight - 32;
        this.dynamicWidth = chartContainer.clientWidth;
        this.updateKey++;
      }, 200);
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
};
</script>

<style scoped lang="scss">
.pie-chart-value {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .pie-chart-value-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    .pie-chart-value-tab {
      height: calc(100% - 32px) !important;
      align-items: center;
      display: flex;
      justify-content: center;

      .empty-text {
        font-size: 12px;
        color: #dcddde;
      }
    }
  }
}

.apexcharts-canvas.dark {
  background: #262d33 !important;
}

.item.active {
  background-color: #262d33 !important;
}

.ui.attached.menu.top {
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
  min-height: unset !important;
}

a.item {
  height: 32px !important;
  padding: 0px 1rem !important;
  font-weight: 800 !important;
  color: #dcddde !important;
}

a.item > span {
  padding: 0.25rem !important;
}

a.item.active > span {
  color: #c88841 !important;
  border-radius: 0.5rem !important;
  padding: 0.25rem !important;
  border: 1px solid #c88841 !important;
}

.ui.tab.segment.attached.active.top {
  background-color: #262d33 !important;
  border: 0px !important;
  padding: 0px !important;
  /* margin-top: -8px !important; */
}
</style>
