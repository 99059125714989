import axios from "axios";
import Swal from "sweetalert2";
import { EventBus } from "@/event-bus.js";
import store from "@/store";

let API_LOGIN_URL = process.env.VUE_APP_AUTH_LOGIN_PATH;
let API_LOGOUT_URL = process.env.VUE_APP_AUTH_LOGOUT_PATH;
let API_CHECK_URL = process.env.VUE_APP_AUTH_CHECK_PATH;
let getConfiged = false;

axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token") || "";

const getConfigs = async () => {
  const API_CONFIG_URL = window.location.origin + "/config.json";
  const response = await axios.get(API_CONFIG_URL);
  const { CONFIG_AUTH_LOGIN_PATH, CONFIG_AUTH_LOGOUT_PATH, CONFIG_AUTH_CHECK_PATH } = response.data;
  let { CONFIG_EGW_IP } = response.data;
  if (!CONFIG_EGW_IP) {
    CONFIG_EGW_IP = window.location.host;
  }

  if (CONFIG_AUTH_LOGIN_PATH) {
    API_LOGIN_URL = window.location.protocol + "//" + CONFIG_EGW_IP + CONFIG_AUTH_LOGIN_PATH;
  } else {
    API_LOGIN_URL = window.location.protocol + "//" + CONFIG_EGW_IP + process.env.VUE_APP_AUTH_LOGIN_PATH;
  }
  if (CONFIG_AUTH_LOGOUT_PATH) {
    API_LOGOUT_URL = window.location.protocol + "//" + CONFIG_EGW_IP + CONFIG_AUTH_LOGOUT_PATH;
  } else {
    API_LOGOUT_URL = window.location.protocol + "//" + CONFIG_EGW_IP + process.env.VUE_APP_AUTH_LOGOUT_PATH;
  }
  if (CONFIG_AUTH_CHECK_PATH) {
    API_CHECK_URL = window.location.protocol + "//" + CONFIG_EGW_IP + CONFIG_AUTH_CHECK_PATH;
  } else {
    API_CHECK_URL = window.location.protocol + "//" + CONFIG_EGW_IP + process.env.VUE_APP_AUTH_CHECK_PATH;
  }
  getConfiged = true;
};

if (process.env.NODE_ENV === "production") {
  API_LOGIN_URL = window.location.origin + process.env.VUE_APP_AUTH_LOGIN_PATH;
  API_LOGOUT_URL = window.location.origin + process.env.VUE_APP_AUTH_LOGOUT_PATH;
  API_CHECK_URL = window.location.origin + process.env.VUE_APP_AUTH_CHECK_PATH;
  getConfigs();
}

const auth = (data) => {
  delete axios.defaults.headers.common["Authorization"];

  return axios
    .post(API_LOGIN_URL, data)
    .then((response) => {
      if (response.data.code === "1000") {
        const { token } = response.data;

        localStorage.setItem("token", token);
        localStorage.setItem("tradingview_token", token);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        store.set("auth/me", response.data);

        EventBus.$emit("onAuthSuccess", response);
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("tradingview_token");
        delete axios.defaults.headers.common["Authorization"];
        store.set("auth/me", {});

        if (response.data.code === "5001") {
          EventBus.$emit("onTwoFactorAuthentication", response.data.otp);
          return response;
        }
        if (response.data.code === "5002") {
          EventBus.$emit("onKYCAuthentication", response.data.kyc);
          return response;
        }
        Swal.fire({
          icon: "error",
          text: response.data.message,
          title: `Error ${response.data.code}!`,
          onClose: () => {
            if (response.data.code === "2004") {
              EventBus.$emit("onPasswordNotInitialized");
            }
            if (response.data.code === "2005") {
              EventBus.$emit("onPasswordNotInitialized");
            }
          },
        });

        EventBus.$emit("onAuthFailure", response);
      }
      return response;
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        text: "Can't connect to server.",
        title: `Error !`,
      });
      throw error;
    });
};

const login = (arg) => {
  let data = new URLSearchParams();
  data.append("username", arg.username);
  data.append("password", arg.password);
  data.append("source_type", "W");
  if (arg.pass_through) {
    data.append("pass_through", true);
  } else {
    data.append("pass_through", false);
  }
  if (arg.skip_kyc) {
    data.append("skip_kyc", true);
  } else {
    data.append("skip_kyc", false);
  }
  if (arg.two_factor_type && arg.two_factor_code) {
    data.append("two_factor_type", arg.two_factor_type);
    data.append("two_factor_code", arg.two_factor_code);
  }
  data.append("source_version", process.env.VUE_APP_EGW_VERSION);

  return auth(data);
};

const newPassword = (arg) => {
  let data = new URLSearchParams();
  data.append("username", arg.username);
  data.append("password", arg.password);
  data.append("new_password", arg.newPassword);
  data.append("source_type", "W");
  if (arg.pass_through) {
    data.append("pass_through", true);
  } else {
    data.append("pass_through", false);
  }
  if (arg.two_factor_type && arg.two_factor_code) {
    data.append("two_factor_type", arg.two_factor_type);
    data.append("two_factor_code", arg.two_factor_code);
  }
  data.append("source_version", process.env.VUE_APP_EGW_VERSION);

  return auth(data);
};

const passthru = (arg) => {
  let data = new URLSearchParams();
  data.append("refer_key", arg.referKey);
  data.append("iv", arg.iv);
  data.append("source_type", "W");
  data.append("source_version", process.env.VUE_APP_EGW_VERSION);

  delete axios.defaults.headers.common["Authorization"];
  localStorage.removeItem("token");
  localStorage.removeItem("tradingview_token");
  return auth(data);
};

const logout = () => {
  return axios
    .get(API_LOGOUT_URL + "?noRedirect=true")
    .then((response) => {
      delete axios.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
      localStorage.removeItem("tradingview_token");
      store.set("auth/me", {});
      clearState();

      return response;
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        text: "Can't connect to server.",
        title: `Error !`,
      });
      throw error;
    });
};

const checkAuth = async () => {
  if (process.env.NODE_ENV === "production") {
    if (!getConfiged) {
      await getConfigs();
    }

    return axios
      .post(API_CHECK_URL)
      .then((response) => response)
      .catch((error) => {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
        localStorage.removeItem("tradingview_token");
        store.set("auth/me", {});

        Swal.fire({
          icon: "error",
          text: "Can't connect to server.",
          title: `Error !`,
        });

        throw error;
      });
  } else {
    return axios
      .post(API_CHECK_URL)
      .then((response) => response)
      .catch((error) => {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
        localStorage.removeItem("tradingview_token");
        store.set("auth/me", {});

        Swal.fire({
          icon: "error",
          text: "Can't connect to server.",
          title: `Error !`,
        });

        throw error;
      });
  }
};

const clearState = () => {
  let oldState = store.state;

  Object.keys(oldState).forEach((key) => {
    if (["model"].includes(key)) {
      Object.keys(oldState[key]).forEach((subKey) => {
        store.set(`${key}/${subKey}`, {});
      });
    }
  });

  store.set("global/currentAccount", null);
  store.set("global/isFavoriteMode", false);
  store.set("global/currentMaketWatchList", []);
  store.set("global/currentQuoteSelectedSymbol", null);
  store.set("global/currentSelectedAccount", null);
  store.set("global/modeOrder", false);
  store.set("global/useMyCustomer", true);
  store.set("global/pin", null);
  store.set("global/currentBBOTable", [
    [{}, {}, {}],
    [{}, {}, {}],
    [{}, {}, {}],
    [{}, {}, {}],
  ]);
};

export default {
  login,
  newPassword,
  passthru,
  logout,
  checkAuth,
};
