import { ref, computed, getCurrentInstance, onBeforeMount, onBeforeUnmount } from "vue";

import store from "@/store";
import router from "@/router";

export default function useCustomerView() {
  const vm = getCurrentInstance().proxy;

  const isLoading = ref(false);

  const userType = computed(() => store.getters["model/systemRefData"]?.userType);

  const onRefresh = () => {
    vm.$EventBus.$emit("onCustomerStartRefresh");
  };

  const onCustomerStartRefresh = () => {
    isLoading.value = true;
  };

  const onCustomerStopRefresh = () => {
    isLoading.value = false;
  };

  onBeforeMount(() => {
    if (userType.value !== "T") {
      router.push("/market");
    }

    vm.$EventBus.$on("onCustomerStartRefresh", onCustomerStartRefresh);
    vm.$EventBus.$on("onCustomerStopRefresh", onCustomerStopRefresh);
  });

  onBeforeUnmount(() => {
    vm.$EventBus.$off("onCustomerStartRefresh", onCustomerStartRefresh);
    vm.$EventBus.$off("onCustomerStopRefresh", onCustomerStopRefresh);
  });

  return { isLoading, onRefresh };
}
