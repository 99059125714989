<template>
  <div class="broker-information" style="overflow: hidden">
    <div class="options-container">
      <sui-checkbox v-model="selectedMarket" radio value="SET" :disabled="!isEnableETS()" class="custom-checkbox">
        <h5 class="ui grey inverted tiny header">{{ "SET" }}</h5>
      </sui-checkbox>
      <sui-checkbox v-model="selectedMarket" radio value="TFEX" :disabled="!isEnableDTS()" class="custom-checkbox">
        <h5 class="ui grey inverted tiny header">{{ "TFEX" }}</h5>
      </sui-checkbox>
      <div class="total-market-volume">
        <h5 class="ui grey inverted tiny header">
          {{ "Total Market Volume" }}
        </h5>
        <span class="ht-text-value">{{ totalMktVol }}</span>
      </div>
      <div class="total-market-value">
        <h5 class="ui grey inverted tiny header">
          {{ "Total Market Value" }}
        </h5>
        <span class="ht-text-value">{{ totalMktVal }}</span>
      </div>
    </div>
    <div class="ui grid content-grid">
      <div class="two column row content-container">
        <div class="sixteen wide computer twelve wide large screen column twelve wide widescreen monitor column left-side-container">
          <div class="broker-information-by-type">
            <ag-grid-vue
              :class="{
                'ag-theme-balham-dark': theme === 'Dark',
                'ag-theme-balham': theme === 'Light',
              }"
              class="broker-information-by-type-table"
              row-selection="single"
              :grid-options="gridBrokerInformationBySideOptions"
              :column-defs="selectedMarket === 'SET' ? columnDefsETSBrokerInformationBySide : columnDefsDTSBrokerInformationBySide"
              :row-data="rowBrokerInformationBySide"
              :row-height="21"
              :header-height="32"
              :default-col-def="{ sortable: false, resizable: true }"
              :animate-rows="true"
              :suppress-drag-leave-hides-columns="true"
              :enable-cell-change-flash="true"
              :locale-text="{ noRowsToShow: `Don't have any Broker Information by Side.` }"
              :row-drag-managed="false"
              :pinned-bottom-row-data="pinnedBottomRowBrokerInformationBySide"
              overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
              @grid-ready="onBrokerInformationBySideGridReady"
              @cell-focused="onCellBrokerInformationBySideFocused"
            />
          </div>

          <div class="broker-information-by-account">
            <ag-grid-vue
              :class="{
                'ag-theme-balham-dark': theme === 'Dark',
                'ag-theme-balham': theme === 'Light',
              }"
              class="broker-information-by-account-table"
              row-selection="single"
              :grid-options="gridBrokerInformationByAccountTypeAndCustomerTypeOptions"
              :column-defs="
                selectedMarket === 'SET'
                  ? columnDefsETSBrokerInformationByAccountTypeAndCustomerType
                  : columnDefsDTSBrokerInformationByAccountTypeAndCustomerType
              "
              :row-data="rowBrokerInformationByAccountTypeAndCustomerType"
              :row-height="21"
              :header-height="32"
              :default-col-def="{ sortable: false, resizable: true }"
              :animate-rows="true"
              :suppress-drag-leave-hides-columns="true"
              :enable-cell-change-flash="true"
              :locale-text="{ noRowsToShow: `Don't have any Broker Information by Account & Customer Type.` }"
              :row-drag-managed="false"
              overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
              :is-full-width-cell="isBrokerInformationByAccountTypeAndCustomerTypeFullWidthCell"
              :full-width-cell-renderer="fullWidthBrokerInformationByAccountTypeAndCustomerTypeCellRenderer"
              :get-row-height="getBrokerInformationByAccountTypeAndCustomerTypeRowHeight"
              @grid-ready="onBrokerInformationByAccountTypeAndCustomerTypeGridReady"
              @cell-focused="onCellBrokerInformationByAccountTypeAndCustomerTypeFocused"
            />
          </div>
        </div>

        <div class="four wide column large screen only right-side-container">
          <div class="row pie-chart-information">
            <PieChartInformation :data="chartData" />
          </div>
          <div class="row progress-bar-information">
            <ProgressBarInformation mode="Broker" :data="chartData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import {
  columnDefsETSBrokerInformationBySide,
  columnDefsDTSBrokerInformationBySide,
} from "@/components/grid/information/BrokerInformationBySideColumnDefs.js";
import {
  columnDefsETSBrokerInformationByAccountTypeAndCustomerType,
  columnDefsDTSBrokerInformationByAccountTypeAndCustomerType,
} from "@/components/grid/information/BrokerInformationByAccountTypeAndCustomerTypeColumnDefs.js";
import InputText from "@/components/InputText";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";
import { getCustomerTypeByKey } from "@/utils/CustomerTypeFormatter.js";
import { getAccountTypeByKey } from "@/utils/AccountTypeFormatter.js";

Object.freeze(columnDefsETSBrokerInformationBySide);
Object.freeze(columnDefsDTSBrokerInformationBySide);
Object.freeze(columnDefsETSBrokerInformationByAccountTypeAndCustomerType);
Object.freeze(columnDefsDTSBrokerInformationByAccountTypeAndCustomerType);

import PieChartInformation from "@/components/information/PieChartInformation.vue";
import ProgressBarInformation from "@/components/information/ProgressBarInformation.vue";

export default {
  name: "BrokerInformation",
  components: {
    AgGridVue,
    PieChartInformation,
    ProgressBarInformation,
  },
  data: () => ({
    columnDefsETSBrokerInformationBySide,
    columnDefsDTSBrokerInformationBySide,
    columnDefsETSBrokerInformationByAccountTypeAndCustomerType,
    columnDefsDTSBrokerInformationByAccountTypeAndCustomerType,

    gridBrokerInformationBySideApi: null,
    gridBrokerInformationByAccountTypeAndCustomerTypeApi: null,
    columnBrokerInformationBySideApi: null,
    columnBrokerInformationByAccountTypeAndCustomerTypeApi: null,

    rowBrokerInformationBySide: [],
    rowBrokerInformationByAccountTypeAndCustomerType: [],
    pinnedBottomRowBrokerInformationBySide: [
      {
        side: "Total",
        vol: 0,
        volBrkPct: 0,
        volMktPct: 0,
        val: 0,
        valBrkPct: 0,
        valMktPct: 0,
        commission: 0,
        commissionBrkPct: 0,
      },
    ],

    selectedMarket: "SET",
    isEnableETS,
    isEnableDTS,

    totalMktVol: "0",
    totalMktVal: "0",

    BRRefId: null,
    chartData: {},
  }),
  watch: {
    selectedMarket() {
      this.filterChanged();
    },
  },
  methods: {
    onInformationStartRefresh() {
      this.rowBrokerInformationBySide = [];
      this.rowBrokerInformationByAccountTypeAndCustomerType = [];
      this.pinnedBottomRowBrokerInformationBySide = [
        {
          side: "Total",
          vol: 0,
          volBrkPct: 0,
          volMktPct: 0,
          val: 0,
          valBrkPct: 0,
          valMktPct: 0,
          commission: 0,
          commissionBrkPct: 0,
        },
      ];

      this.chartData = {};
      this.totalMktVol = "0";
      this.totalMktVal = "0";

      if (this.selectedMarket === "SET") {
        this.BR01();
      } else if (this.selectedMarket === "TFEX") {
        this.BR51();
      }
    },
    filterChanged() {
      this.onInformationStartRefresh();
    },
    BR01() {
      const msg = this.$messageFactory.createMessage("BR01");
      this.BRRefId = this.$shortid.generate();
      this.$ws.send(msg, this.BRRefId);

      if (this.gridBrokerInformationBySideApi) {
        this.gridBrokerInformationBySideApi.showLoadingOverlay();
      }
      if (this.gridBrokerInformationByAccountTypeAndCustomerTypeApi) {
        this.gridBrokerInformationByAccountTypeAndCustomerTypeApi.showLoadingOverlay();
      }
    },
    BR51() {
      const msg = this.$messageFactory.createMessage("BR51");
      this.BRRefId = this.$shortid.generate();
      this.$ws.send(msg, this.BRRefId);

      if (this.gridBrokerInformationBySideApi) {
        this.gridBrokerInformationBySideApi.showLoadingOverlay();
      }
      if (this.gridBrokerInformationByAccountTypeAndCustomerTypeApi) {
        this.gridBrokerInformationByAccountTypeAndCustomerTypeApi.showLoadingOverlay();
      }
    },
    updateRowBrokerInformationBySide(information) {
      if (this.BRRefId !== information.refId) {
        return;
      }

      this.totalMktVol = this.$numeral(information.totalMktVol).format("0,0");
      this.totalMktVal = this.$numeral(information.totalMktVal).format("0,0");

      this.rowBrokerInformationBySide = information.sides.slice(0, 2);
      const lastIndexLength = information.sides.length - 1;
      this.pinnedBottomRowBrokerInformationBySide = [
        {
          side: "Total",
          vol: information.sides[lastIndexLength].vol,
          volBrkPct: information.sides[lastIndexLength].volBrkPct,
          volMktPct: information.sides[lastIndexLength].volMktPct,
          val: information.sides[lastIndexLength].val,
          valBrkPct: information.sides[lastIndexLength].valBrkPct,
          valMktPct: information.sides[lastIndexLength].valMktPct,
          commission: information.sides[lastIndexLength].commission,
          commissionBrkPct: information.sides[lastIndexLength].commissionBrkPct,
        },
      ];
      if (this.gridBrokerInformationBySideApi) {
        this.gridBrokerInformationBySideApi.hideOverlay();
      }

      information.accountTypes.forEach((accountType, index) => {
        information.accountTypes[index].type = getAccountTypeByKey(accountType.type);
      });

      information.pcs.forEach((customerType, index) => {
        information.pcs[index].type = getCustomerTypeByKey(customerType.type);
      });

      //Remove Sub PC
      this.$_.remove(information.pcs, (pc) => {
        return pc.type.includes("Sub");
      });

      this.chartData = information;

      this.rowBrokerInformationByAccountTypeAndCustomerType = [...information.accountTypes, { fullWidth: true }, ...information.pcs];
      if (this.gridBrokerInformationByAccountTypeAndCustomerTypeApi) {
        this.gridBrokerInformationByAccountTypeAndCustomerTypeApi.hideOverlay();
      }

      this.$EventBus.$emit("onInformationStopRefresh");
    },
    onBrokerInformationBySideGridReady(params) {
      this.gridBrokerInformationBySideApi = params.api;
      this.columnBrokerInformationBySideApi = params.columnApi;
    },
    onBrokerInformationByAccountTypeAndCustomerTypeGridReady(params) {
      this.gridBrokerInformationByAccountTypeAndCustomerTypeApi = params.api;
      this.columnBrokerInformationByAccountTypeAndCustomerTypeApi = params.columnApi;
    },
    onCellBrokerInformationBySideFocused(event) {
      this.gridBrokerInformationBySideOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    onCellBrokerInformationByAccountTypeAndCustomerTypeFocused(event) {
      this.gridBrokerInformationByAccountTypeAndCustomerTypeOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    isBrokerInformationByAccountTypeAndCustomerTypeFullWidthCell(rowNode) {
      return rowNode.data.fullWidth;
    },
    fullWidthBrokerInformationByAccountTypeAndCustomerTypeCellRenderer(params) {
      const eDiv = document.createElement("div");
      eDiv.innerHTML = `<div style="font-weight: 700; font-family: Lato,'Helvetica Neue', Arial, Helvetica, sans-serif; font-size: 1rem; background-color: #1B2025; padding-left: 12px; line-height: 30px;">PC</div>`;
      return eDiv.firstChild;
    },
    getBrokerInformationByAccountTypeAndCustomerTypeRowHeight(params) {
      const isFullWidth = params.node.data.fullWidth;
      if (isFullWidth) {
        return 30;
      } else {
        return 21;
      }
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
  created() {
    this.gridBrokerInformationBySideOptions = {};
    this.gridBrokerInformationByAccountTypeAndCustomerTypeOptions = {};

    this.$EventBus.$on("ctinf/BS01", this.updateRowBrokerInformationBySide);
    this.$EventBus.$on("ctinf/BS51", this.updateRowBrokerInformationBySide);

    this.$EventBus.$on("onInformationStartRefresh", this.onInformationStartRefresh);
  },
  mounted() {
    this.onInformationStartRefresh();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/BS01", this.updateRowBrokerInformationBySide);
    this.$EventBus.$off("ctinf/BS51", this.updateRowBrokerInformationBySide);

    this.$EventBus.$off("onInformationStartRefresh", this.onInformationStartRefresh);
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

/* .column {
  background-color: rgb(38, 45, 51) !important;
} */

.broker-information {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  background-color: black;

  .options-container {
    height: 3rem;
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 1rem;
    padding: 0 1rem;
    background-color: #262d33;

    .custom-checkbox {
      h5 {
        margin: 0 !important;
      }
    }

    .total-market-value,
    .total-market-volume {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      h5 {
        margin: 0;
      }

      span {
        font-weight: 700;
        padding: 0 !important;
      }
    }
  }

  .content-grid {
    flex: 1;
    background-color: black;
    display: flex;

    .content-container {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      flex-wrap: nowrap;

      .left-side-container {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        .broker-information-by-type {
          width: 100%;
          height: 30%;

          .broker-information-by-type-table {
            width: 100%;
            height: 100%;
          }
        }

        .broker-information-by-account {
          width: 100%;
          height: 70%;

          .broker-information-by-account-table {
            width: 100%;
            height: 100%;
          }
        }
      }

      .right-side-container {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        .pie-chart-information {
          flex: 1;
          overflow: hidden;
        }

        .progress-bar-information {
          flex: 1;
          overflow: hidden;
        }
      }
    }
  }
}

.ht-border {
  border-style: solid;
  border-color: black;
}

.ui.button.fluid.dropdown {
  background-color: #2f383f;
}

.ui.grey.header {
  color: #dcddde !important;
}

.ui.secondary.menu {
  min-height: unset;
  height: 32px !important;
  border: 0px !important;
  background-color: unset !important;
}

.dropdown.icon {
  color: #999999;
}
</style>

<style>
.ui.fluid.dropdown > .dropdown.icon {
  color: #999999;
}

.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.ht-news-datepicker .el-range-separator {
  padding: 0px !important;
  margin-top: -10px !important;
  color: #dcddde !important;
}

.ht-news-datepicker .el-input__icon.el-range__icon.el-icon-date {
  margin-top: -10px !important;
}

.el-picker-panel__sidebar {
  background-color: #2f383f !important;
}

.el-picker-panel__shortcut {
  color: #dcddde !important;
}

.el-picker-panel__shortcut:hover {
  color: #409eff !important;
}

.el-date-table__row > .available.in-range > div {
  background-color: #1b2025 !important;
}

.ht-trader-id > input {
  border: 0px !important;
}

.ht-custlist-dropdown {
  height: 2rem;
  min-height: 2rem;
  top: calc(50% - 1.5rem) !important;
}
.ht-custlist-dropdown .text {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 1rem;
  right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-custlist-dropdown i.dropdown {
  line-height: 0 !important;
  margin-top: 3px !important;
}

.ht-text-header {
  display: inline;
  margin: 0 !important;
  padding: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-text-value {
  color: #e4af0a !important;
  padding-left: 1rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
