<template>
  <div :class="{ black: theme === 'Dark' }" :style="{ height: '100%' }">
    <div class="ui grid" :style="{ height: '100%' }">
      <div class="four columns row" style="padding-bottom: 0px">
        <div
          v-for="(_, column) in 4"
          :key="column"
          class="column"
          :style="[
            theme === 'Light' ? { 'border-right': '0px solid #000000' } : { 'border-right': '0px solid #000000' },
            column === 0 ? { 'padding-right': '0px' } : {},
            column === 1 ? { padding: '0px' } : {},
            column === 2 ? { padding: '0px' } : {},
            column === 3 ? { 'padding-left': '0px' } : {},
            { height: `${tickerFullPageHeight}px` },
            theme === 'Light' ? { 'border-top': '0px solid #000000' } : { 'border-top': '0px solid #000000' },
            { height: '100%' },
          ]"
        >
          <table
            style="border: 0px; border-radius: 0px"
            :class="{ inverted: theme === 'Dark' }"
            class="ui very compact small single line unstackable striped table"
          >
            <thead>
              <TickerHeader hide-symbol />
            </thead>
            <tbody>
              <tr
                v-for="(ticker, index) in arrayTickerColumn[column]"
                :key="index"
                :class="{
                  active: index === lastPosition - (maximunTickers / 4) * column - 1,
                }"
              >
                <TickerBody :ticker="ticker" hide-symbol />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { SecurityItem } from "@/messages/websocket/XR03";
import TickerHeader from "@/components/ticker/TickerHeader.vue";
import TickerBody from "@/components/ticker/TickerBody.vue";

export default {
  name: "QuoteTicker",
  components: {
    TickerHeader,
    TickerBody,
  },
  props: ["tickerFullPageHeight"],
  data: () => ({
    tickerTaker: null,

    maximunTickers: 0,
    maximunTickersColumn: 0,

    lastPosition: 0,

    arrayTickerColumn: [[], [], [], []],
    fetchTicker: 20,

    securityInfo: {},
    dtsSecurityInfo: {},

    SR01RefId: "",
  }),
  watch: {
    currentQuoteSelectedSymbol() {
      this.initTickerValue();
    },
    tickerFullPageHeight: {
      immediate: true,
      handler() {
        this.calMaximunTickers();
        this.initTickerValue();
      },
    },
  },
  created() {
    this.$EventBus.$on("bcst/PB01", this.etsLastsaleUpdateBcst);
    this.$EventBus.$on("bcst/PB51", this.dtsLastsaleUpdateBcst);

    this.$EventBus.$on("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$on("ctinf/SS51", this.dtsSecurityInfoUpdateCinf);

    this.$EventBus.$on("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);

    this.initTickerValue();
  },
  beforeDestroy() {
    this.$EventBus.$off("bcst/PB01", this.etsLastsaleUpdateBcst);
    this.$EventBus.$off("bcst/PB51", this.dtsLastsaleUpdateBcst);

    this.$EventBus.$off("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$off("ctinf/SS51", this.dtsSecurityInfoUpdateCinf);

    this.$EventBus.$off("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);

    if (this.tickerTaker) {
      clearInterval(this.tickerTaker);
    }
  },
  methods: {
    onQuoteTableStartRefresh() {
      this.initTickerValue();
    },
    calMaximunTickers() {
      const tableHeaderHeight = 32;
      const tableRowHeight = 21;
      const tablePaddingHeight = 0;

      let maximunTickersColumn = Math.floor((this.tickerFullPageHeight - (tableHeaderHeight + tablePaddingHeight)) / tableRowHeight);
      this.maximunTickersColumn = maximunTickersColumn;
      this.maximunTickers = maximunTickersColumn * 4;
    },
    SR01(selectedSymbol) {
      if (!selectedSymbol) return;

      const msg = this.$messageFactory.createMessage("SR01");

      msg.securityId.set(selectedSymbol.securityId);
      msg.lastTradeCount.set(5);
      msg.full.set("Y");
      msg.exchangeId.set(selectedSymbol.exchangeId);

      this.SR01RefId = this.$shortid.generate();
      this.$ws.send(msg, this.SR01RefId);
    },
    XR03(currentQuoteSelectedSymbol, messageCode) {
      const msg = this.$messageFactory.createMessage("XR03");

      if (currentQuoteSelectedSymbol) {
        const securityItem = new SecurityItem();

        securityItem.securityId = Number(currentQuoteSelectedSymbol.value.securityId);
        msg.securityList.get().push(securityItem);
      }

      msg.messageCode.set(messageCode);
      this.$ws.send(msg);
    },
    initTickerValue() {
      this.lastPosition = 0;
      this.tickerBuffer = [];
      this.arrayTicker = [];
      this.arrayTickerColumn = [[], [], [], []];
      this.takeTickerFromBuffer();

      /* Subscribe all */
      if (this.currentQuoteSelectedSymbol) {
        if (this.currentQuoteSelectedSymbol.value.exchangeId === 1) {
          this.SR01(this.currentQuoteSelectedSymbol.value);
        } else if (this.currentQuoteSelectedSymbol.value.exchangeId === 2) {
          this.SR01(this.currentQuoteSelectedSymbol.value);
        }
      } else {
        this.$EventBus.$emit("onQuoteTableStopRefresh");
      }
    },
    updateInfo(update) {
      update.trades
        .slice(0)
        .reverse()
        .forEach((ticker) => {
          let changePrice = 0;
          if (update.exchangeId == 1) {
            changePrice = this.$numeral(this.$numeral(ticker.price).value() - this.$numeral(update.prior).value()).format("0,0.00");
          } else if (update.exchangeId == 2) {
            changePrice = this.$numeral(this.$numeral(ticker.price).value() - this.$numeral(update.prevSettlePrice).value()).format("0,0.00");
          }
          this.tickerToBuffer({
            ...ticker,
            changePrice: changePrice,
          });
        });
    },
    updateTicker(update) {
      this.tickerToBuffer(update);
    },
    tickerToBuffer(ticker) {
      this.tickerBuffer.push(ticker);
    },
    takeTickerFromBuffer() {
      if (this.tickerTaker) {
        clearInterval(this.tickerTaker);
      }

      this.tickerTaker = setInterval(() => {
        let tickerBuffer = this.tickerBuffer.slice(-parseInt(this.maximunTickers));
        this.tickerBuffer = [];

        if (tickerBuffer.length > 0) {
          this.renderTickerFromBuffer(tickerBuffer);
        }
      }, Number(process.env.VUE_APP_TICKER_RATE));
    },
    renderTickerFromBuffer(tickerBuffer) {
      if (this.lastPosition >= this.maximunTickers) {
        this.lastPosition -= this.maximunTickers;
      }

      if (this.lastPosition + tickerBuffer.length <= this.maximunTickers) {
        this.arrayTicker.splice(this.lastPosition, tickerBuffer.length, ...tickerBuffer);

        this.lastPosition += tickerBuffer.length;
      } else {
        let tickerBufferTail = tickerBuffer.slice(0, this.maximunTickers - this.lastPosition);
        let tickerBufferHead = tickerBuffer.slice(this.maximunTickers - this.lastPosition);

        this.arrayTicker.splice(this.lastPosition, this.maximunTickers, ...tickerBufferTail);
        this.arrayTicker.splice(0, tickerBufferHead.length, ...tickerBufferHead);

        this.lastPosition = tickerBufferHead.length;
      }

      //sort with time

      this.arrayTickerColumn[0].splice(0, this.maximunTickersColumn, ...this.arrayTicker.slice(0, this.maximunTickersColumn));
      this.arrayTickerColumn[1].splice(
        0,
        this.maximunTickersColumn,
        ...this.arrayTicker.slice(this.maximunTickersColumn, this.maximunTickersColumn * 2)
      );
      this.arrayTickerColumn[2].splice(
        0,
        this.maximunTickersColumn,
        ...this.arrayTicker.slice(this.maximunTickersColumn * 2, this.maximunTickersColumn * 3)
      );
      this.arrayTickerColumn[3].splice(0, this.maximunTickers, ...this.arrayTicker.slice(this.maximunTickersColumn * 3, this.maximunTickers));
      for (let index = 0; index <= 3; index++) {
        if (this.arrayTickerColumn[index].length > this.maximunTickersColumn) {
          this.arrayTickerColumn[index].length = this.maximunTickersColumn;
        }
      }
    },
    securityInfoUpdateCinf(update) {
      if (update.refId !== this.SR01RefId) {
        return;
      }

      this.lastPosition = 0;
      this.tickerBuffer = [];
      this.arrayTicker = [];

      this.securityInfo = update;

      this.updateInfo(update);
      this.$EventBus.$emit("onQuoteTableStopRefresh");
    },
    etsLastsaleUpdateBcst(update) {
      if (!this.currentQuoteSelectedSymbol) return;
      if (this.currentQuoteSelectedSymbol.value.securityId !== update.securityId) return;
      this.updateTicker(update);
    },
    dtsSecurityInfoUpdateCinf(update) {
      if (update.refId !== this.SR01RefId) {
        return;
      }

      this.lastPosition = 0;
      this.tickerBuffer = [];
      this.arrayTicker = [];

      this.dtsSecurityInfo = update;

      this.updateInfo(update);
      this.$EventBus.$emit("onQuoteTableStopRefresh");
    },
    dtsLastsaleUpdateBcst(update) {
      if (!this.currentQuoteSelectedSymbol) return;
      if (this.currentQuoteSelectedSymbol.value.securityId !== update.securityId) return;
      this.updateTicker(update);
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
  },
};
</script>

<style scoped>
.ui.table.inverted td.active,
.ui.table.inverted tr.active {
  background: rgba(224, 224, 224, 0.2) !important;
}

.ht-ticker-td {
  border: 0px !important;
  font-size: 1rem !important;
  line-height: 21px;
  height: 21px !important;
}

.icon {
  line-height: 0.75em !important;
  margin: -2.5px 0 !important;
  font-size: 1.15em !important;
}

@media screen and (max-width: 1599px) {
  .wide-col {
    display: none !important;
  }
}
</style>
