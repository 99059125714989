<template>
  <div class="market-stock-ticker">
    <table style="border: 0px" :class="{ inverted: theme === 'Dark' }" class="ui very compact small single line unstackable striped table">
      <thead>
        <TickerHeader hide-symbol />
      </thead>
      <tbody>
        <tr
          v-for="(ticker, index) in arrayTickerColumn"
          :key="index"
          :ref="`TickerRow${index}`"
          :class="[{ active: componentsClass.tickerRow[index] }]"
        >
          <TickerBody :ticker="ticker" hide-symbol />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import TickerHeader from "@/components/ticker/TickerHeader.vue";
import TickerBody from "@/components/ticker/TickerBody.vue";

export default {
  name: "MarketStockTicker",
  components: {
    TickerHeader,
    TickerBody,
  },
  props: {
    maximumTickersColumn: {
      type: Number,
      default: 5,
    },
  },
  data: () => ({
    tickerTaker: null,
    arrayTickerColumn: [],
    componentsClass: {
      tickerRow: [],
    },
  }),
  watch: {
    currentQuoteSelectedSymbol(currentQuoteSelectedSymbol, old) {
      if (currentQuoteSelectedSymbol && old && old.symbol === currentQuoteSelectedSymbol.symbol) {
        return;
      }

      this.componentsClass.tickerRow = [];
      this.arrayTickerColumn = [];
      this.tickerBuffer = [];
      this.arrayTicker = [];
      this.takeTickerFromBuffer();
    },
  },
  created() {
    this.tickerBuffer = [];
    this.arrayTicker = [];

    this.$EventBus.$on("ctinf/SS01", this.updateInfo);
    this.$EventBus.$on("ctinf/SS51", this.updateInfo);
    this.$EventBus.$on("bcst/PB01", this.updateTicker);
    this.$EventBus.$on("bcst/PB51", this.updateTicker);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS01", this.updateInfo);
    this.$EventBus.$off("ctinf/SS51", this.updateInfo);
    this.$EventBus.$off("bcst/PB01", this.updateTicker);
    this.$EventBus.$off("bcst/PB51", this.updateTicker);

    if (this.tickerTaker) {
      clearInterval(this.tickerTaker);
    }
  },
  methods: {
    updateInfo(update) {
      if (!this.currentQuoteSelectedSymbol) {
        return;
      }

      if (update.securityId !== this.currentQuoteSelectedSymbol.value.securityId) {
        return;
      }

      this.tickerBuffer = [];
      this.arrayTicker = [];

      update.trades
        .slice(0)
        .reverse()
        .forEach((ticker) => {
          let changePrice = 0;
          if (update.exchangeId == 1) {
            changePrice = this.$numeral(this.$numeral(ticker.price).value() - this.$numeral(update.prior).value()).format("0,0.00");
          } else if (update.exchangeId == 2) {
            changePrice = this.$numeral(this.$numeral(ticker.price).value() - this.$numeral(update.prevSettlePrice).value()).format("0,0.00");
          }
          this.tickerToBuffer({
            ...ticker,
            changePrice: changePrice,
          });
        });

      this.takeTickerFromBuffer();
    },
    updateTicker(update) {
      if (!this.currentQuoteSelectedSymbol) {
        return;
      }

      if (update.securityId !== this.currentQuoteSelectedSymbol.value.securityId) {
        return;
      }

      this.tickerToBuffer(update);
    },
    tickerToBuffer(ticker) {
      this.tickerBuffer.push(ticker);
    },
    takeTickerFromBuffer() {
      if (this.tickerTaker) {
        clearInterval(this.tickerTaker);
      }

      this.tickerTaker = setInterval(() => {
        let tickerBufferTempt = this.tickerBuffer.slice(-parseInt(this.maximumTickersColumn));
        this.tickerBuffer = [];

        if (tickerBufferTempt.length > 0) {
          this.renderTickerFromBuffer(tickerBufferTempt);
        }
      }, Number(process.env.VUE_APP_TICKER_RATE));
    },
    renderTickerFromBuffer(tickerBuffer) {
      if (tickerBuffer.length >= this.maximumTickersColumn) {
        this.arrayTicker = tickerBuffer.slice(0, this.maximumTickersColumn);
        for (let index = 0; index < tickerBuffer.length; index++) {
          this.$nextTick(() => {
            this.activeTickerRow(index);
          });
        }
      } else {
        this.arrayTicker.unshift(...tickerBuffer);
        this.arrayTicker = this.arrayTicker.slice(0, this.maximumTickersColumn);
        for (let index = 0; index < tickerBuffer.length; index++) {
          this.$nextTick(() => {
            this.activeTickerRow(index);
          });
        }
      }
      this.arrayTickerColumn = this.arrayTicker.slice(0, this.maximumTickersColumn);
    },
    activeTickerRow(index) {
      this.$nextTick(() => {
        this.$refs[`TickerRow${index}`][0].classList.add("active");
      });
      setTimeout(() => {
        this.$nextTick(() => {
          if (this.$refs[`TickerRow${index}`][0]) {
            this.$refs[`TickerRow${index}`][0].classList.remove("active");
          }
        });
      }, 250);
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
  },
};
</script>

<style scoped>
.ui.table.inverted td.active,
.ui.table.inverted tr.active {
  background: rgba(224, 224, 224, 0.2) !important;
}

.market-stock-ticker {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #262d33;
}
</style>
