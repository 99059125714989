import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  valueColorStyle,
  totalColorStyle,
  qtyColorStyle,
  valueSideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle
} from "../Formatter.js";

const columnDefsBrokerRanking = [
  {
    headerName: "Rank",
    field: "rank",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 80,
    cellRenderer: params => {
      if (params.value === "Total") {
        return params.value;
      }
      if (params.data.rankFlag > 0) {
        return `${params.value} <i class="sort up green icon" style="position: relative; top: 3px;"></i>`;
      } else if (params.data.rankFlag < 0) {
        return `${params.value} <i class="sort down red icon" style="position: relative; top: -3px;"></i>`;
      } else {
        return `${params.value} <i class="sort rotated yellow icon" style="line-height: 1rem;"></i>`;
      }
    },
    cellStyle: totalColorStyle
  },
  {
    headerName: "Broker No.",
    field: "brokerNo",
    width: 100,
    cellStyle: () => ({ ...totalColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Broker Name",
    field: "brokerName",
    width: 150,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Change",
    field: "chg",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: valueColorStyle
  },
  {
    headerName: "%Change",
    field: "chgPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: valueColorStyle
  },
  {
    headerName: "Buy Value",
    field: "buyVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "%B-Val Mkt.",
    field: "buyValMktPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "Sell Value",
    field: "sellVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },
  {
    headerName: "%S-Val Mkt.",
    field: "sellValMktPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },
  {
    headerName: "Net Value",
    field: "netVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: valueColorStyle
  },
  {
    headerName: "Total Value",
    field: "totalVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "%Total Value Mkt.",
    field: "totalValMktPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Buy Share",
    field: "buyShare",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "%B-Shr Mkt.",
    field: "buyShareMktPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "Sell Share",
    field: "sellShare",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },
  {
    headerName: "%S-Shr Mkt.",
    field: "sellShareMktPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },
  {
    headerName: "Total Share",
    field: "totalShare",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "%Tot-Shr Mtk.",
    field: "totalShareMktPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: qtyColorStyle
  }
];

export { columnDefsBrokerRanking };
