import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  priceColorStyle,
  valueColorStyle,
} from "../Formatter.js";

import { EventBus } from "@/event-bus";

const columnDefsCustByStockETS = [
  {
    headerName: "Order",
    field: "buyOrders",
    flex: 2,
    cellStyle: (params) => ({ ...totalColorStyle(params), "justify-content": "flex-end" }),
    cellRendererFramework: "CustByStockOrderCellRenderer",
    cellRendererParams: {
      mode: "BUY",
    },
  },
  {
    headerName: "Buy",
    field: "bidVolume",
    flex: 1,
    cellStyle: (params) => ({ ...qtyColorStyle(params), "justify-content": "flex-end", cursor: "pointer" }),
    onCellDoubleClicked: (params) => {
      EventBus.$emit("custByStockOrderDBClicked", "B", params.data);
    },
    // cellRendererFramework: "CustByStockBBOCellRenderer"
  },
  {
    headerName: "Price",
    field: "price",
    flex: 1,
    cellStyle: (params) => ({ ...priceColorStyle(params), "justify-content": "center" }),
  },
  {
    headerName: "Sell",
    field: "offerVolume",
    flex: 1,
    cellStyle: (params) => ({ ...qtyColorStyle(params), "justify-content": "flex-start", cursor: "pointer" }),
    onCellDoubleClicked: (params) => {
      EventBus.$emit("custByStockOrderDBClicked", "S", params.data);
    },
    // cellRendererFramework: "CustByStockBBOCellRenderer"
  },
  {
    headerName: "Order",
    field: "sellOrders",
    flex: 2,
    cellStyle: (params) => ({ ...totalColorStyle(params), "justify-content": "flex-start" }),
    cellRendererFramework: "CustByStockOrderCellRenderer",
    cellRendererParams: {
      mode: "SELL",
    },
  },
];

const columnDefsCustByStockDTS = [
  {
    headerName: "Order",
    field: "buyOrders",
    flex: 2,
    cellStyle: (params) => ({ ...totalColorStyle(params), "justify-content": "flex-end" }),
    cellRendererFramework: "CustByStockOrderCellRenderer",
    cellRendererParams: {
      mode: "BUY",
    },
  },
  {
    headerName: "Long",
    field: "bidVolume",
    flex: 1,
    cellStyle: (params) => ({ ...qtyColorStyle(params), "justify-content": "flex-end", cursor: "pointer" }),
    onCellDoubleClicked: (params) => {
      EventBus.$emit("custByStockOrderDBClicked", "B", params.data);
    },
    // cellRendererFramework: "CustByStockBBOCellRenderer"
  },
  {
    headerName: "Price",
    field: "price",
    flex: 1,
    cellStyle: (params) => ({ ...priceColorStyle(params), "justify-content": "center" }),
  },
  {
    headerName: "Short",
    field: "offerVolume",
    flex: 1,
    cellStyle: (params) => ({ ...qtyColorStyle(params), "justify-content": "flex-start", cursor: "pointer" }),
    onCellDoubleClicked: (params) => {
      EventBus.$emit("custByStockOrderDBClicked", "S", params.data);
    },
    // cellRendererFramework: "CustByStockBBOCellRenderer"
  },
  {
    headerName: "Order",
    field: "sellOrders",
    flex: 2,
    cellStyle: (params) => ({ ...totalColorStyle(params), "justify-content": "flex-start" }),
    cellRendererFramework: "CustByStockOrderCellRenderer",
    cellRendererParams: {
      mode: "SELL",
    },
  },
];

export { columnDefsCustByStockETS, columnDefsCustByStockDTS };
