<template>
  <div>
    <ag-grid-vue
      style="width: 100%; height: calc(30vh - 32px - 5px); padding: 0px"
      :class="{
        'ag-theme-balham-dark': theme === 'Dark',
        'ag-theme-balham': theme === 'Light',
      }"
      row-selection="single"
      :grid-options="gridDTSPositionOptions"
      :column-defs="columnDefsDTSPosition"
      :row-data="rowDTSPosition"
      :row-height="21"
      :header-height="32"
      :default-col-def="{ sortable: true, resizable: true }"
      :animate-rows="true"
      :suppress-drag-leave-hides-columns="true"
      :enable-cell-change-flash="true"
      :locale-text="{ noRowsToShow: `Don't have any Position.` }"
      :row-drag-managed="false"
      :pinned-bottom-row-data="pinnedBottomRowDTSPosition"
      overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
      @grid-ready="onDTSPositionGridReady"
      @row-selected="onRowDTSPositionSelected"
      @cell-focused="onCellDTSPositionFocused"
    />
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsDTSPosition } from "@/components/grid/position/ColumnDefs.js";

import { getBySymbolAndExchangeId } from "@/utils/SymbolUtils";

Object.freeze(columnDefsDTSPosition);

export default {
  name: "PortfolioPositionTable",
  components: {
    AgGridVue,
  },
  props: ["onShow"],
  data: () => ({
    columnDefsDTSPosition,
    refId: null,
    gridDTSPositionApi: null,
    columnDTSPositionApi: null,
    rowDTSPosition: [],
    pinnedBottomRowDTSPosition: [
      {
        seriesId: "Total",
        sellableQty: "0",
        onHandQty: "0",
        unrealPl: "0.00",
        unrealPercent: "0.00%",
        closedQty: "0",
        realPl: "0.00",
        openQty: "0",
        startOfDayQty: "0",
        todayOpenQty: "0",
        unrealCostPl: "0.00",
        outstandingOpenQty: "0",
        outstandingCloseQty: "0",
      },
    ],
  }),
  watch: {
    currentSelectedAccount(currentSelectedAccount) {
      if (!this.onShow) return;

      if (currentSelectedAccount.exchangeId == 0) {
        this.initPositionTable();
      }

      this.AR02();
    },
  },
  methods: {
    initPositionTable() {
      this.rowDTSPosition = [];
      this.pinnedBottomRowDTSPosition = [
        {
          seriesId: "Total",
          sellableQty: "0",
          onHandQty: "0",
          unrealPl: "0.00",
          unrealPercent: "0.00%",
          closedQty: "0",
          realPl: "0.00",
          openQty: "0",
          startOfDayQty: "0",
          todayOpenQty: "0",
          unrealCostPl: "0.00",
          outstandingOpenQty: "0",
          outstandingCloseQty: "0",
        },
      ];
    },
    onPortfolioTableStartRefresh() {
      if (!this.onShow) return;

      this.AR02();
    },
    AR02() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount.exchangeId == 0 || !this.currentSelectedAccount?.accountCode) {
        this.$EventBus.$emit("onPortfolioTableStopRefresh");
        return;
      }
      const msg = this.$messageFactory.createMessage("AR02");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);
      msg.exchangeId.set(this.currentSelectedAccount.exchangeId);
      this.refId = this.$shortid.generate();

      this.$ws.send(msg, this.refId);
      if (this.gridDTSPositionApi) {
        this.gridDTSPositionApi.showLoadingOverlay();
      }
    },
    updateRowDTSPosition(position) {
      this.rowDTSPosition = position.records;
      this.pinnedBottomRowDTSPosition = [
        {
          seriesId: "Total",
          sellableQty: position.sumSellableQty,
          onHandQty: position.sumOnHandQty,
          unrealPl: this.$numeral(position.sumUnrealPl).format("+0,0.00"),
          unrealPercent: this.$numeral(this.$numeral(position.sumUnrealizedPercent).value() / 100).format("+0,0.00%"),
          closedQty: position.sumClosedQty,
          realPl: this.$numeral(position.sumRealPl).format("+0,0.00"),
          openQty: position.sumOpenQty,
          startOfDayQty: position.sumStartOfDayQty,
          todayOpenQty: position.sumTodayOpenQty,
          unrealCostPl: this.$numeral(position.sumUnrealCostPl).format("+0,0.00"),
          outstandingOpenQty: position.sumOutstandingOpenQty,
          outstandingCloseQty: position.sumOutstandingCloseQty,
          // unrealPlVal: position.sumUnrealPlVal,
          // unrealCostPl: position.sumUnrealCostPlVal,
          // costValue: position.sumCostValue,
          // marketValue: position.sumMarketValue,
          // unrealizedPercent: this.$numeral(this.$numeral(position.sumUnrealizedPercent).value() / 100).format("+0,0.00%"),
          // realized: this.$numeral(position.sumRealized).format("+0,0.00")
        },
      ];
    },
    onDTSPositionGridReady(params) {
      this.gridDTSPositionApi = params.api;
      this.columnDTSPositionApi = params.columnApi;
    },
    onCellDTSPositionFocused(event) {
      this.gridDTSPositionOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    onRowDTSPositionSelected(params) {
      const symbolSelectedRows = params.api.getSelectedRows();

      if (symbolSelectedRows.length === 1) {
        if (params.node.selected && params.data) {
          const symbol = {
            symbol: params.data.symbol,
            value: {
              ...getBySymbolAndExchangeId(params.data.symbol, 2),
            },
            fromUID: "outside",
          };
          this.$store.set("global/currentQuoteSelectedSymbol", symbol);
        }
      }
    },
    dtsAccountPositionCinf(position) {
      if (position.refId !== this.refId) return;
      if (!this.onShow) return;

      this.updateRowDTSPosition(position);
      if (this.gridDTSPositionApi) {
        this.gridDTSPositionApi.hideOverlay();
      }
      this.$EventBus.$emit("onPortfolioTableStopRefresh");
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
  },
  created() {
    this.gridDTSPositionOptions = {};
    this.AR02();

    this.$EventBus.$on("ctinf/AS52", this.dtsAccountPositionCinf);

    this.$EventBus.$on("onPortfolioTableStartRefresh", this.onPortfolioTableStartRefresh);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS52", this.dtsAccountPositionCinf);

    this.$EventBus.$off("onPortfolioTableStartRefresh", this.onPortfolioTableStartRefresh);
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

div.ag-root-wrapper-body.ag-layout-normal > div > div.ag-floating-bottom > div.ag-pinned-left-floating-bottom > div > div {
  color: #dcddde !important;
}
</style>
