<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    value: {
      type: Number
    },
    filterable: {
      type: String,
      default: "startsWith"
    }
  },
  data() {
    return {
      style: "width: 8rem; min-width: 8rem;",
      defaultValue: 0,
      options: [
        {
          text: "Option 1",
          value: 0
        },
        {
          text: "Option 2",
          value: 1
        },
        {
          text: "Option 3",
          value: 2
        }
      ]
    };
  }
};
</script>
