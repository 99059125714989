import Vue from "vue";
import Router from "vue-router";
import Layout from "@/components/layout/Index.vue";
import Market from "@/views/market/Market.vue";
import Portfolio from "@/views/portfolio/Portfolio.vue";
import BBOs from "@/views/bbo/BBOs.vue";
import Ticker from "@/views/ticker/Ticker.vue";
import Index from "@/views/index/Index.vue";
import News from "@/views/news/News.vue";
import Quote from "@/views/quote/Quote.vue";
import Technical from "@/views/Technical.vue";
import Customers from "@/views/customers/Customers.vue";
import CustByStock from "@/views/cust-by-stock/CustByStock.vue";
import Information from "@/views/company/Information.vue";
import Value from "@/views/Value.vue";
import BrokerRanking from "@/views/BrokerRanking.vue";
import BrokerPage from "@/views/BrokerPage.vue";

import store from "./store/";
import _api from "@/api/authentication";
import Swal from "sweetalert2";

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active", // active class for *exact* links.
  routes: [
    {
      path: "/",
      component: Layout,
      children: [
        {
          path: "",
          redirect: "market",
        },
        {
          path: "market",
          // alias: "/",
          name: "market",
          component: Market,
        },
        {
          path: "portfolio",
          name: "portfolio",
          component: Portfolio,
        },
        {
          path: "bbos",
          name: "bbos",
          component: BBOs,
        },
        {
          path: "ticker",
          name: "ticker",
          component: Ticker,
        },
        {
          path: "index",
          name: "index",
          component: Index,
        },
        {
          path: "news",
          name: "news",
          component: News,
        },
        {
          path: "quote",
          name: "quote",
          component: Quote,
        },
        {
          path: "technical",
          name: "technical",
          component: Technical,
        },
        {
          path: "customers",
          name: "customers",
          component: Customers,
        },
        {
          path: "custbystock",
          name: "custbystock",
          component: CustByStock,
        },
        {
          path: "information",
          name: "information",
          component: Information,
        },
        {
          path: "value",
          name: "value",
          component: Value,
        },
        {
          path: "brokerranking",
          name: "brokerranking",
          component: BrokerRanking,
        },
        {
          path: "brokerpage",
          name: "brokerpage",
          component: BrokerPage,
        },
      ],
      meta: { requireAuth: true },
    },
    {
      path: "/login",
      name: "login",
      component: () => import(/* webpackChunkName: "auth" */ "@/views/login/Login.vue"),
    },
    {
      path: "/otp",
      name: "otp",
      component: () => import(/* webpackChunkName: "auth" */ "@/views/OTP.vue"),
    },
    {
      path: "/newpassword",
      name: "newpassword",
      component: () => import(/* webpackChunkName: "auth" */ "@/views/NewPassword.vue"),
    },
    {
      path: "/passthru",
      name: "passthru",
      component: () => import(/* webpackChunkName: "auth" */ "@/views/Passthru.vue"),
    },
    {
      path: "/kyc",
      name: "kyc",
      component: () => import(/* webpackChunkName: "auth" */ "@/views/KYC.vue"),
    },
    // {
    //   path: "/logout",
    //   name: "logout",
    //   component: () =>
    //     import(/* webpackChunkName: "logout" */ "@/views/Logout.vue")
    // }
  ],
});

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");

  if (!to.matched.length) return next("/market");

  if (to.matched.some((record) => record.meta.requireAuth) && !token?.length) {
    return next("/login");
  }

  return next();

  // let isLogined = false;

  // try {
  //   isLogined = await _api.checkAuth();
  // } catch (error) {
  //   isLogined = false;
  // }

  // if (to.name == "login" && !isLogined) {
  //   return next();
  // }

  // if (to.matched.some((record) => record.meta.requireAuth)) {
  //   if (!isLogined) {
  //     return next({
  //       path: "/login",
  //       query: { redirect: to.fullPath },
  //     });
  //   } else {
  //     return next();
  //   }
  // } else {
  //   if (to.path.startsWith("/passthru") || to.matched.length) {
  //     return next();
  //   }

  //   return next({ path: "/market" });
  // }
});

router.afterEach(async (to) => {
  const token = localStorage.getItem("token");

  if (to.name === "login" && !token?.length) return;

  if (to.name === "login") return router.replace("/market");

  if (to.matched.some((record) => record.meta.requireAuth)) {
    try {
      const isLogined = await _api.checkAuth();

      if (!isLogined) {
        return router.replace("/login");
      }
    } catch (err) {
      return router.replace("/login");
    }
  }
});

export default router;
