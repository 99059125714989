import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle,
  informationSideFormatter,
  valueSideColorStyle
} from "../Formatter.js";
import { getAccountTypeByKey } from "@/utils/AccountTypeFormatter.js";
import { getCreditTypeByKey } from "@/utils/CreditTypeFormatter.js";
import { getUserTypeByKey } from "@/utils/UserTypeFormatter.js";
import { getCustomerStatusByKey } from "@/utils/CustomerStatusFormatter.js";
import { getCustomerFlagByKey } from "@/utils/CustomerFlagFormatter.js";
import { getVIPFlagByKey } from "@/utils/VIPFlagFormatter.js";
import { getMutualFlagByKey } from "@/utils/MutualFlagFormatter.js";

const columnDefsETSTraderValue = [
  {
    headerName: "Trader ID",
    field: "traderId",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 150,
    cellStyle: params => ({ ...sideColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Level",
    field: "level",
    width: 80,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Type",
    field: "type",
    width: 200,
    valueFormatter: key => getUserTypeByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Bought Value",
    field: "boughtVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "%B-Val",
    field: "boughtValPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "Sold Value",
    field: "soldVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },
  {
    headerName: "%S-Val",
    field: "soldValPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },
  {
    headerName: "Total Value",
    field: "totalVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "%Value",
    field: "totalValBrkPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Total Volume",
    field: "vol",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "%Volume",
    field: "volPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Commission",
    field: "commission",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "%Comm",
    field: "commissionBrkPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: totalColorStyle
  }
];

const columnDefsDTSTraderValue = columnDefsETSTraderValue;

export { columnDefsETSTraderValue, columnDefsDTSTraderValue };
