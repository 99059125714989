import { baseStyle, sideFormatter, sideColorStyle, auditCodeFormatter } from "../Formatter.js";

export default [
  {
    headerName: "Time",
    field: "time",
    width: 80,
    suppressSizeToFit: true,
    cellStyle: baseStyle
  },
  {
    headerName: "Action",
    field: "action",
    width: 80,
    suppressSizeToFit: true,
    cellStyle: params => {
      if (params.data.from === "deal") {
        return sideColorStyle(params);
      }
      return baseStyle;
    },
    valueFormatter: params => {
      if (params.data.from === "deal") {
        return sideFormatter(params, "TFEX");
      } else if (params.data.from === "audit") {
        return auditCodeFormatter(params);
      } else {
        return params.value;
      }
    }
  },
  {
    headerName: "Volume",
    field: "newVol",
    cellStyle: baseStyle
  },
  {
    headerName: "Price",
    field: "newPrice",
    cellStyle: baseStyle
  },
  {
    headerName: "Iceberg",
    field: "newPub",
    cellStyle: baseStyle
  },
  {
    headerName: "Account",
    field: "newCust",
    cellStyle: baseStyle,
    valueGetter: params => {
      if (params.data.oldCust && params.data.newCust) {
        return params.data.oldCust + " &#8594; " + params.data.newCust;
      }
      return "";
    }
  },
  {
    headerName: "Trade ID",
    field: "traderId",
    cellStyle: baseStyle
  },
  {
    headerName: "Cause",
    field: "cause",
    cellStyle: baseStyle
  }
];
