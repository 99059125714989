<template>
  <div class="stock-news">
    <div style="height: 4rem; line-height: 4rem">
      <div class="ui form">
        <div class="fields">
          <div class="field">
            <h5 class="ui grey header inverted" style="line-height: 4rem; margin-left: 1rem">Symbol</h5>
          </div>
          <div class="field">
            <div style="margin-top: 0.5rem">
              <SymbolDropdown
                ref="symbol"
                v-model="selectedSymbol"
                market="ALL"
                image
                style="min-height: 3rem !important; line-height: 3rem !important; width: 12rem !important; min-width: 12rem !important"
                @enter="$refs.symbol.select()"
              />
            </div>
          </div>
          <div class="field">
            <h5 class="ui grey header inverted" style="line-height: 4rem; margin-left: 1rem">Date</h5>
          </div>
          <div class="field">
            <div style="margin-left: 1rem; margin-top: 0.4rem" class="ht-news-datepicker">
              <el-date-picker
                v-model="selectedDate"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                format="dd MMM yyyy"
                :clearable="false"
                :picker-options="pickerOptions"
                @change="NR01"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ui grid" style="height: calc(55vh - 4rem - 32px - 5px)">
      <div class="eight wide column">
        <ag-grid-vue
          style="width: 100%; height: calc(55vh - 4rem - 32px - 5px); padding: 0px"
          :class="{
            'ag-theme-balham-dark': theme === 'Dark',
            'ag-theme-balham': theme === 'Light',
          }"
          row-selection="single"
          :grid-options="gridQuoteNewsOptions"
          :column-defs="columnDefsQuoteNews"
          :row-data="rowQuoteNews"
          :get-row-node-id="getQuoteNewsRowNodeId"
          :row-height="21"
          :header-height="32"
          :default-col-def="{ sortable: true, resizable: true }"
          :animate-rows="true"
          :suppress-drag-leave-hides-columns="true"
          :enable-cell-change-flash="true"
          :locale-text="{ noRowsToShow: `Don't have any News.` }"
          :row-drag-managed="false"
          overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
          @grid-ready="onQuoteNewsGridReady"
          @cell-focused="onCellQuoteNewsFocused"
        />
      </div>
      <div class="eight wide column right-side-container">
        <div class="ui grid">
          <div class="sixteen wide column" style="height: 32px; background-color: #1b2025">
            <h5 class="ui grey header inverted news-header" style="padding: 8px">{{ newsHeadline }}</h5>
          </div>
          <div class="sixteen wide column" style="background-color: #1b2025" v-show="selectedRow">
            <div class="mode-options">
              <div class="option">
                <input
                  type="radio"
                  id="content-option"
                  name="mode-option"
                  value="content"
                  @change="onSelectMode('content')"
                  v-model="viewMode"
                  :disabled="!enableContent"
                />
                <label for="content-option">
                  <div class="option-label">{{ "CONTENT" }}</div>
                </label>
              </div>
              <div class="option">
                <input
                  type="radio"
                  id="pdf-option"
                  name="mode-option"
                  value="pdf"
                  v-model="viewMode"
                  @change="onSelectMode('pdf')"
                  :disabled="!enablePDF"
                />
                <label for="pdf-option">
                  <div class="option-label">{{ "PDF" }}</div>
                </label>
              </div>
              <div class="option">
                <input
                  type="radio"
                  id="txt-option"
                  name="mode-option"
                  value="txt"
                  @change="onSelectMode('txt')"
                  v-model="viewMode"
                  :disabled="!enableTXT"
                />
                <label for="txt-option">
                  <div class="option-label">{{ "TXT" }}</div>
                </label>
              </div>
            </div>
          </div>
          <div class="sixteen wide column" style="height: calc(55vh - 4rem - 64px - 26px); overflow: hidden">
            <div
              v-if="(viewMode === 'pdf' || viewMode === 'txt') && (enableTXT || enablePDF)"
              style="margin: 0px; height: calc(55vh - 4rem - 64px - 26px)"
            >
              <iframe
                class="pdfobject"
                :src="reportFileLink"
                type="application/pdf"
                style="overflow: auto; width: 100%; height: 100%"
                ref="pdfViewer"
                title="pdf-news"
              />
            </div>
            <div v-if="viewMode === 'content'" style="height: calc(55vh - 4rem - 64px - 26px); overflow: auto">
              <div v-if="haveZip" style="padding: 0.5rem">
                <a :href="reportFileLink" target="_blank">
                  <sui-button animated="vertical" color="blue" style="width: 7rem">
                    <sui-button-content hidden>Download</sui-button-content>
                    <sui-button-content visible>
                      <sui-icon name="cloud download" />
                    </sui-button-content>
                  </sui-button>
                </a>
                <span style="padding-left: 0.5rem" class="ui grey tiny header inverted">File : {{ reportFileName }}.zip</span>
              </div>
              <h5 class="ui grey header inverted" style="font-family: 'Courier New', Courier, monospace" v-html="newsContent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SymbolDropdown from "@/components/SymbolDropdown";
import { DatePicker } from "element-ui";

import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsQuoteNews } from "@/components/grid/news/ColumnDefs.js";

Object.freeze(columnDefsQuoteNews);

export default {
  name: "News",
  components: {
    AgGridVue,
    SymbolDropdown,
    "el-date-picker": DatePicker,
  },
  data: () => ({
    columnDefsQuoteNews,
    viewMode: "pdf",
    enablePDF: false,
    enableTXT: false,
    enableContent: false,
    selectedRow: null,
    gridQuoteNewsApi: null,
    columnQuoteNewsApi: null,
    rowQuoteNews: [],
    selectedSymbol: null,
    pickerOptions: {
      shortcuts: [
        {
          text: "Last week",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Last month",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "Last 3 months",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    },
    selectedDate: [new Date(), new Date()],
    newsHeadline: "",
    newsContent: "",
    reportFileName: "",
    reportFileType: "",
    reportFileLink: "",
    haveZip: false,
  }),
  watch: {
    selectedSymbol(selectedSymbol) {
      this.enablePDF = false;
      this.enableTXT = false;
      this.enableContent = false;
      this.selectedRow = null;
      this.newsContent = "";
      this.haveZip = false;

      this.NR01();
    },
  },
  methods: {
    resolveNewsRefresh(isRefresh) {
      if (isRefresh) {
        return this.onNewsStartRefresh();
      }
    },
    onNewsStartRefresh() {
      this.enablePDF = false;
      this.enableTXT = false;
      this.enableContent = false;
      this.selectedRow = null;
      this.newsContent = "";
      this.haveZip = false;

      this.NR01();
    },
    NR01() {
      if (!this.selectedDate) {
        this.$EventBus.$emit("onNewsRefresh", false);
        return;
      }

      this.newsHeadline = "";
      this.newsContent = "";
      this.reportFileName = "";
      this.reportFileType = "";
      this.haveZip = false;
      this.reportFileLink = "";

      const msg = this.$messageFactory.createMessage("NR01");

      if (this.selectedSymbol) {
        msg.symbol.set(this.selectedSymbol.value.symbol);
      }
      msg.fromDate.set(this.$moment(this.selectedDate[0]).format("DD/MM/YYYY"));
      msg.toDate.set(this.$moment(this.selectedDate[1]).format("DD/MM/YYYY"));

      this.$ws.send(msg);
      if (this.gridQuoteNewsApi) {
        this.gridQuoteNewsApi.showLoadingOverlay();
      }
    },
    NR02(newsId) {
      const msg = this.$messageFactory.createMessage("NR02");

      msg.newsId.set(newsId);

      this.$ws.send(msg);
    },
    updateRowQuoteNews(news) {
      this.rowQuoteNews = news.newsList;
      if (this.gridQuoteNewsApi) {
        this.gridQuoteNewsApi.hideOverlay();
      }

      this.$EventBus.$emit("onNewsRefresh", false);
    },
    onQuoteNewsGridReady(params) {
      this.gridQuoteNewsApi = params.api;
      this.columnQuoteNewsApi = params.columnApi;
    },
    getQuoteNewsRowNodeId(data) {
      return data.newsId;
    },
    onCellQuoteNewsFocused(event) {
      this.enablePDF = false;
      this.enableTXT = false;
      this.enableContent = false;
      this.viewMode = null;
      this.newsContent = "";

      this.gridQuoteNewsOptions.api.forEachNode(async (node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          this.selectedRow = node.data;
          this.newsHeadline = node.data.headline;
          this.reportFileName = node.data.reportFileName;
          this.reportFileType = node.data.reportFileType;
          this.enableContent = true;
          this.viewMode = "content";
          this.haveZip = false;
          let fileExtension = ".pdf";

          if (["2", "3", "6", "7"].includes(node.data.reportFileType)) {
            this.enablePDF = true;
          }

          if (["1", "3", "5", "7"].includes(node.data.reportFileType)) this.enableTXT = true;

          if (["4", "5", "6", "7"].includes(node.data.reportFileType)) {
            fileExtension = ".zip";
            this.haveZip = true;
          }

          const y = node.data.reportFileName.substring(11, 15);
          const m = node.data.reportFileName.substring(9, 11);
          const d = node.data.reportFileName.substring(7, 9);
          const linkDate = y + m + d;

          this.reportFileLink = `${this.newsServerUrl}/${linkDate}/${node.data.reportFileName + fileExtension}`;

          this.NR02(node.data.newsId);
          return;
        }
      });
    },
    newsContentCinf(news) {
      this.newsContent = news.newsContent.split(" ").join("&nbsp;").split("\r\n").join("<br />");
    },
    onSelectMode(mode) {
      const y = this.selectedRow.reportFileName.substring(11, 15);
      const m = this.selectedRow.reportFileName.substring(9, 11);
      const d = this.selectedRow.reportFileName.substring(7, 9);
      const linkDate = y + m + d;

      if (mode === "pdf") {
        this.reportFileLink = `${this.newsServerUrl}/${linkDate}/${this.selectedRow.reportFileName + ".pdf"}`;
      }

      if (mode === "txt") {
        this.reportFileLink = `${this.newsServerUrl}/${linkDate}/${this.selectedRow.reportFileName + ".txt"}`;
      }

      if (mode === "content" && this.haveZip) {
        this.reportFileLink = `${this.newsServerUrl}/${linkDate}/${this.selectedRow.reportFileName + ".zip"}`;
      }
    },
  },
  computed: {
    theme: sync("global/theme"),
    newsServerUrl: get("model/systemRefData@newsServerUrl"),
  },
  created() {
    this.gridQuoteNewsOptions = {};

    this.$EventBus.$on("ctinf/NS01", this.updateRowQuoteNews);
    this.$EventBus.$on("ctinf/NS02", this.newsContentCinf);
    this.$EventBus.$on("onNewsRefresh", this.resolveNewsRefresh);

    this.NR01();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/NS01", this.updateRowQuoteNews);
    this.$EventBus.$off("ctinf/NS02", this.newsContentCinf);
    this.$EventBus.$off("onNewsRefresh", this.resolveNewsRefresh);
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.stock-news .news-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stock-news .mode-options {
  display: flex;
  gap: 0 0.2rem;
  padding: 2px 8px;
}

.stock-news .mode-options .option {
  display: flex;
}

.stock-news .mode-options .option-label {
  background-color: transparent;
  padding: 0 1rem;
  font-weight: bold;
  border-radius: 4px;
  color: #909294;
}

.stock-news .mode-options .option-label:hover {
  cursor: pointer;
  background-color: #c88841cc;
  color: white;
  /* background-color: #c88841 !important; */
}

.stock-news .mode-options input {
  display: none;
}

.stock-news .mode-options input:disabled ~ label .option-label {
  background-color: #303133a6 !important;
  color: #909294 !important;
}

.stock-news .mode-options input:disabled ~ label .option-label:hover {
  cursor: default;
}

.stock-news .mode-options input:checked ~ label .option-label {
  background-color: #c88841;
  color: white;
}
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.ht-news-datepicker {
  height: 3rem !important;
  line-height: 3rem !important;
}

.ht-news-datepicker .el-date-editor {
  height: 3rem !important;
}

.ht-news-datepicker .el-input__icon {
  line-height: 3rem !important;
}

.ht-news-datepicker .el-range-separator {
  padding: 0px !important;
  color: #dcddde !important;
  line-height: 3rem !important;
}

.el-picker-panel__sidebar {
  background-color: #2f383f !important;
}

.el-picker-panel__shortcut {
  color: #dcddde !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

.el-picker-panel__shortcut:hover {
  color: #409eff !important;
}

.el-date-table__row > .available.in-range > div {
  background-color: #1b2025 !important;
}

.pdfobject {
  border: 0px;
  background-color: white;
}

.multiselect__input:focus {
  border: none !important;
  outline: none !important;
}

.el-input__icon.el-range__icon.el-icon-date {
  padding-left: 0.5rem;
}

.el-range-input {
  font-weight: 800;
}
</style>
