const values = {
  S: "Common Stock",
  F: "Foreign Common Stock",
  V: "Derivatives Warrant",
  W: "Warrant",
  L: "ETF",
  U: "Unit Trust",
  P: "Preferred",
  Q: "Preferred Foreign",
  X: "Depository Receipts"
};

const getSecurityTypeByKey = key => {
  return values[key] || key;
};

export { getSecurityTypeByKey };
