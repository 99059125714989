/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class PB21 extends WsMsg {
	constructor() {
		super(2);
		this._securityId = new NumberMessageBase();
		this._side = new StringMessageBase();
		this._price1 = new StringMessageBase();
		this._volume1 = new StringMessageBase();
		this._price2 = new StringMessageBase();
		this._volume2 = new StringMessageBase();
		this._price3 = new StringMessageBase();
		this._volume3 = new StringMessageBase();
		this._price4 = new StringMessageBase();
		this._volume4 = new StringMessageBase();
		this._price5 = new StringMessageBase();
		this._volume5 = new StringMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get side() {
		return this._side;
	}
	
	get price1() {
		return this._price1;
	}
	
	get volume1() {
		return this._volume1;
	}
	
	get price2() {
		return this._price2;
	}
	
	get volume2() {
		return this._volume2;
	}
	
	get price3() {
		return this._price3;
	}
	
	get volume3() {
		return this._volume3;
	}
	
	get price4() {
		return this._price4;
	}
	
	get volume4() {
		return this._volume4;
	}
	
	get price5() {
		return this._price5;
	}
	
	get volume5() {
		return this._volume5;
	}
}

export default PB21;
