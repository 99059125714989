<template>
  <draggable
    v-if="params.mode === 'BUY'"
    style="width: 100%; text-align: end;"
    class="ht-draggable"
    :group="{ name: 'buy-order' }"
    :data-index="params.rowIndex"
    @end="onOrderDroped"
  >
    <div v-if="params.data.buyOrders.length" class="ui mini buttons" style="height: 18px;">
      <button class="ui labeled icon blue button" style="padding: 5px 0px; min-width: 8rem;" @click="onOrderClicked">
        <i class="right delete icon" @click="onCancelClicked" />
        {{
          $numeral(
            $_.sumBy(params.data.buyOrders, order => {
              return $numeral(order.volume).value() - $numeral(order.matchedVolume).value();
            })
          ).format("0,0")
        }}
      </button>
      <button class="ui grey button" style="padding: 5px 10px; min-width: 2.5rem;" @click="onOrderClicked">
        {{ $numeral(params.data.buyOrders.length).format("0,0") }}
      </button>
    </div>
    <template v-else>&nbsp;</template>
  </draggable>
  <draggable
    v-else
    style="width: 100%; text-align: start;"
    class="ht-draggable"
    :group="{ name: 'sell-order' }"
    :data-index="params.rowIndex"
    @end="onOrderDroped"
  >
    <div v-if="params.data.sellOrders.length" class="ui mini buttons" style="height: 18px;">
      <button class="ui grey button" style="padding: 5px 10px;  min-width: 2.5rem;" @click="onOrderClicked">
        {{ $numeral(params.data.sellOrders.length).format("0,0") }}
      </button>
      <button class="ui right labeled icon pink button" style="padding: 5px 0px; min-width: 8rem;" @click="onOrderClicked">
        <i class="right delete icon" @click="onCancelClicked" />
        {{
          $numeral(
            $_.sumBy(params.data.sellOrders, order => {
              return $numeral(order.volume).value() - $numeral(order.matchedVolume).value();
            })
          ).format("0,0")
        }}
      </button>
    </div>
    <template v-else>&nbsp;</template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },

  methods: {
    // onTest(e) {
    //   console.log("1", e.currentTarget);
    // },
    // onTest2(e) {
    //   console.log("2", e.currentTarget);
    //   e.cancelBubble = true;
    // },
    onOrderDroped(e) {
      const fromIndex = e.from.getAttribute("data-index");
      const toIndex = e.to.getAttribute("data-index");
      this.$EventBus.$emit("custByStockChangeOrder", this.params, fromIndex, toIndex);
    },
    onOrderClicked(e) {
      this.$EventBus.$emit("custByStockInfoOrderClicked", this.params.value);
    },
    onCancelClicked(e) {
      this.$EventBus.$emit("custByStockCancelOrderClicked", this.params.value);
      e.cancelBubble = true;
    }
  }
  // created() {
  //   console.log(this.params);
  // }
};
</script>
