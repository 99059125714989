<template>
  <div>
    <sui-modal v-model="dialog" size="small" class="ht-settings-modal">
      <sui-modal-header>
        <span>About</span>
        <div class="close" @click="dialog = false"><i class="close grey icon"></i></div>
      </sui-modal-header>
      <div style="clear: float;"></div>
      <sui-modal-content>
        <div class="ui grid padded">
          <div class="sixteen wide column" style="padding-bottom: 1rem !important;">
            <div class="ht-logo-navbar" style="margin: 0vw 1vw;">
              <img
                :src="`${logo_path}/logo-large.png`"
                v-if="showBrokerLogo"
                style="margin-left: 10rem; height: 3rem;"
                alt="broker-logo"
                @error="replaceByDefault"
              />
            </div>
          </div>
          <div class="four wide column ht-label right aligned">
            <h5 class="ui header inverted">Version:</h5>
          </div>
          <div class="twelve wide column">
            <h5 class="ui yellow header">
              {{ version }} <span>{{ mode }}</span>
            </h5>
          </div>
          <div class="four wide column ht-label right aligned">
            <h5 class="ui header inverted">Build Date:</h5>
          </div>
          <div class="twelve wide column">
            <h5 class="ui blue header">{{ buildDate }}</h5>
          </div>
        </div>
      </sui-modal-content>
      <sui-modal-actions>
        <h5 class="ui header inverted">Copyright © SS&C 2019. All rights reserved.</h5>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
let BROKER_LOGO_PATH = process.env.VUE_APP_BROKER_LOGO_PATH;

if (process.env.NODE_ENV === "production") {
  BROKER_LOGO_PATH = window.location.origin + process.env.VUE_APP_BROKER_LOGO_PATH;
}

export default {
  data() {
    return {
      dialog: false,
      version: process.env.VUE_APP_VERSION,
      buildDate: process.env.VUE_APP_BUILD_DATE,

      logo_path: BROKER_LOGO_PATH,
      showBrokerLogo: true
    };
  },
  computed: {
    mode() {
      if (process.env.NODE_ENV === "development") return "(DEV)";
      else if (process.env.NODE_ENV === "production") return "";
      else return process.env.NODE_ENV;
    }
  },
  created() {
    this.$EventBus.$on("about", this.onAbout);
  },
  beforeDestroy() {
    this.$EventBus.$off("about", this.onAbout);
  },
  methods: {
    replaceByDefault() {
      this.showBrokerLogo = false;
    },
    onAbout() {
      this.dialog = true;
    }
  }
};
</script>

<style scoped>
.ht-logo-navbar {
  position: relative;
  background: url("../../assets/images/logo.svg") no-repeat center;
  background-size: contain;
  width: 9em;
  min-width: 5em;
  height: 5vh;
}
.ht-label {
  padding-right: 1rem !important;
  padding-bottom: 1rem !important;
}
</style>
