const values = {
  B: "Can Buy Only",
  S: "Can Sell Only",
  C: "Can Change Only",
  H: "Halt Trade",
  X: "Halt Login",
  L: "Can Short & Cover Only",
  M: "Can Short Only",
  P: "Can Cover Only",
  T: " "
};

const getCustomerFlagByKey = key => {
  return values[key] || "Normal";
};

export { getCustomerFlagByKey };
