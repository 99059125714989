<template>
  <tr>
    <th class="two wide wide-col ticker-table-header" id="time">
      <h5 class="ui grey inverted header">Time</h5>
    </th>
    <th v-if="!hideSymbol" class="four wide ticker-table-header" id="symbol">
      <h5 class="ui grey inverted header">Symbol</h5>
    </th>
    <th class="right aligned five wide ticker-table-header" id="vol">
      <h5 class="ui grey inverted header">Volume</h5>
    </th>
    <th class="right aligned four wide ticker-table-header" id="price">
      <h5 class="ui grey inverted header">Price</h5>
    </th>
    <th class="right aligned five wide wide-col-mini ticker-table-header" id="change">
      <h5 class="ui grey inverted header">Change</h5>
    </th>
  </tr>
</template>

<script>
export default {
  props: {
    hideSymbol: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.ticker-table-header {
  position: sticky;
  top: 0;
  background-color: #1b2025 !important;
}

@media screen and (max-width: 1599px) {
  .wide-col {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .wide-col-mini {
    display: none !important;
  }
}

th {
  border-radius: 0 !important;
  padding: 0.4rem 0.6rem !important;
  /* line-height: 32px !important; */
  position: sticky;
  top: 0;
}
</style>
