<template>
  <div class="quote-view">
    <div class="ui equal width grid content-container">
      <div class="row quote-info-section" ref="quoteInfoSection">
        <div class="four wide column quote-info-container">
          <QuoteInfo />
        </div>
        <div class="column quote-table-container">
          <QuoteTable />
        </div>
      </div>
      <div class="row market-info-section" ref="marketInfoSection">
        <div class="four wide column ht-border market-bbo-container">
          <MarketBBO :current-quote-selected-symbol="currentQuoteSelectedSymbol" @switch-level="onSwitchLevel" />
        </div>
        <div class="column ht-border market-stock-graph-intraday-column" ref="marketStockGraphIntradayColumn">
          <sui-tab :menu="{ attached: 'top' }" class="market-stock-graph-tab">
            <sui-tab-pane title="Intraday" attached="top" class="market-stock-graph-tab-pane">
              <MarketStockGraphIntraday :fetch-data="true" />
            </sui-tab-pane>
            <div class="refresh-icon-container">
              <i class="refresh alternate icon" :class="{ loading: isMarketStockGraphIntradayLoading }" @click="refreshMarketStockGraphIntraday" />
            </div>
          </sui-tab>
        </div>
        <div class="column market-stock-graph-buy-sell-column" ref="marketStockGraphBuySellColumn">
          <sui-tab :menu="{ attached: 'top' }" class="market-stock-graph-tab">
            <sui-tab-pane title="Buy/Sell" attached="top" class="market-stock-graph-tab-pane">
              <MarketStockGraphBuySell />
            </sui-tab-pane>
            <div class="refresh-icon-container">
              <i class="refresh alternate icon" :class="{ loading: isMarketStockGraphBuySellLoading }" @click="refreshMarketStockGraphBuySell" />
            </div>
          </sui-tab>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useQuoteView from "./hooks/useQuoteView";

import QuoteInfo from "@/components/quote/QuoteInfo";
import QuoteTable from "@/components/quote/QuoteTable";
import MarketBBO from "@/components/market/MarketBBO";
import MarketStockGraphIntraday from "@/components/market/MarketStockGraphIntraday";
import MarketStockGraphBuySell from "@/components/market/MarketStockGraphBuySell";

const {
  isMarketStockGraphBuySellLoading,
  isMarketStockGraphIntradayLoading,
  marketInfoSectionHeight,
  currentQuoteSelectedSymbol,
  onSwitchLevel,
  refreshMarketStockGraphIntraday,
  refreshMarketStockGraphBuySell,
} = useQuoteView();
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/quote/quote-view.scss";
</style>
