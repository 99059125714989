export const fullSide2ShortSide = (market, side) => {
  if (!market || !side) {
    return null;
  }
  if (market === "SET") {
    if (side.toLowerCase() === "buy") {
      return "B";
    } else if (side.toLowerCase() === "sell") {
      return "S";
    } else if (side.toLowerCase() === "cover") {
      return "C";
    } else if (side.toLowerCase() === "short") {
      return "H";
    }
  } else if (market === "TFEX") {
    if (side.toLowerCase() === "long") {
      return "L";
    } else if (side.toLowerCase() === "short") {
      return "S";
    }
  }
  return null;
};

export const shortSide2FullSide = (market, side) => {
  if (!market || !side) {
    return null;
  }
  if (market === "SET") {
    if (side.toLowerCase() === "b") {
      return "buy";
    } else if (side.toLowerCase() === "s") {
      return "sell";
    } else if (side.toLowerCase() === "c") {
      return "cover";
    } else if (side.toLowerCase() === "h") {
      return "short";
    }
  } else if (market === "TFEX") {
    if (side.toLowerCase() === "l") {
      return "long";
    } else if (side.toLowerCase() === "s") {
      return "short";
    }
  }
  return null;
};

export const convertKeyCodeToChar = keyCode => {
  switch (keyCode) {
    case 8:
      return "&#9003;";
    case 9:
      return "&#8633;";
    case 13:
      return "&crarr;";
    case 16:
      return "&#8679;";
    case 17:
      return "Ctrl";
    case 18:
      if (navigator.platform.indexOf("Mac") >= 0) {
        return "&#8997;";
      }
      return "Alt";
    case 19:
      return "Pause";
    case 20:
      return "CapsLck";
    case 27:
      return "Esc";
    case 33:
      return "PgUp";
    case 34:
      return "PgDn";
    case 35:
      return "End";
    case 36:
      return "Home";
    case 37:
      return "&larr;";
    case 38:
      return "&uarr;";
    case 39:
      return "&rarr;";
    case 40:
      return "&darr;";
    case 45:
      return "Ins";
    case 46:
      return "Del";
    case 91:
      if (navigator.platform.indexOf("Mac") >= 0) {
        return "&#8984;";
      }
      return "&#10070;";
    case 92:
      return "&#10070;";
    case 93:
      if (navigator.platform.indexOf("Mac") >= 0) {
        return "&#8984;";
      }
      return "&#9636;";
    case 96:
      return "Numpad 0";
    case 97:
      return "Numpad 1";
    case 98:
      return "Numpad 2";
    case 99:
      return "Numpad 3";
    case 100:
      return "Numpad 4";
    case 101:
      return "Numpad 5";
    case 102:
      return "Numpad 6";
    case 103:
      return "Numpad 7";
    case 104:
      return "Numpad 8";
    case 105:
      return "Numpad 9";
    case 106:
      return "Numpad *";
    case 107:
      return "Numpad +";
    case 109:
      return "Numpad -";
    case 110:
      return "Numpad .";
    case 111:
      return "Numpad /";
    case 192:
      return "~";
    case 112:
      return "F1";
    case 113:
      return "F2";
    case 114:
      return "F3";
    case 115:
      return "F4";
    case 116:
      return "F5";
    case 117:
      return "F6";
    case 118:
      return "F7";
    case 119:
      return "F8";
    case 120:
      return "F9";
    case 121:
      return "F10";
    case 122:
      return "F11";
    case 123:
      return "F12";
    case 144:
      return "&#128274;";
    case 145:
      return "Scrlk";
    case 186:
      return ";";
    case 187:
      return "=";
    case 188:
      return ",";
    case 173:
    /* eslint-disable no-fallthrough */
    case 189:
      return "-";
    case 190:
      return ".";
    case 191:
      return "/";
    case 219:
      return "[";
    case 220:
      return "\\";
    case 221:
      return "]";
    case 222:
      return "'";
    default:
      return String.fromCharCode(keyCode);
  }
};
