<template>
  <div style="cursor: pointer;" @click="onBBOPriceClicked">
    {{ params.data.topBid }}
  </div>
</template>

<script>
export default {
  methods: {
    onBBOPriceClicked() {
      const { symbol, exchangeId, topBid } = this.params.data;
      this.$EventBus.$emit("onBBOPriceClicked", symbol, exchangeId, "bid", topBid);
    }
  }
};
</script>
