<template>
  <div class="ui grid market-view">
    <div class="two column row market-view-content-container">
      <div class="sixteen wide computer twelve wide large screen column twelve wide widescreen monitor column left-side-section">
        <div class="left-side-container">
          <div class="row market-watch-table-section">
            <div class="column market-watch-table-container" :class="{ 'hide-overlay': showAllBBOMode }">
              <MarketWatchTable />
            </div>
          </div>
          <div class="market-stock-quote-section">
            <div class="column">
              <MarketStockQuote />
            </div>
          </div>
          <div class="row ui grid quote-info-section">
            <div class="three column row quote-info-container">
              <div class="column market-bbo-container" ref="marketBBOContainer">
                <MarketBBO :current-quote-selected-symbol="currentQuoteSelectedSymbol" @switch-level="onSwitchLevel" />
              </div>
              <div class="column market-stock-ticker-container">
                <MarketStockTicker :maximumTickersColumn="maxBBOLevel" />
              </div>
              <div class="column market-stock-graph-container">
                <MarketStockGraph />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="four wide column large screen only right-side-section">
        <div class="ui grid ticker-container">
          <div class="row">
            <div class="header-section">
              <div class="ui form">
                <div class="fields">
                  <div class="one wide field set-ticker-checkbox">
                    <sui-checkbox v-model="tickerFilterSET" checkbox :disabled="!isEnableETS()">
                      <h4 class="ui grey tiny header">{{ "SET" }}</h4>
                    </sui-checkbox>
                  </div>
                  <div class="one wide field">
                    <sui-checkbox v-model="tickerFilterTFEX" checkbox :disabled="!isEnableDTS()">
                      <h4 class="ui grey tiny header">{{ "TFEX" }}</h4>
                    </sui-checkbox>
                  </div>
                  <div class="fourteen wide field mode-sum-checkbox">
                    <sui-checkbox v-model="tickerFilterModeSum" checkbox>
                      <h4 class="ui grey tiny header">{{ "Mode Sum" }}</h4>
                    </sui-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tickerFilterSET" class="row full">
            <div class="column market-ticker-container" ref="marketTickerSETContainer">
              <MarketTickerSET :market-ticker-s-e-t-height="marketTickerSETHeight" />
            </div>
          </div>
          <div v-if="tickerFilterTFEX" class="row full" :class="{ full: !tickerFilterSET, half: tickerFilterSET }">
            <div class="column market-ticker-container" ref="marketTickerTFEXContainer">
              <MarketTickerTFEX :market-ticker-t-f-e-x-height="marketTickerTFEXHeight" />
            </div>
          </div>
          <div v-if="!tickerFilterSET && !tickerFilterTFEX" class="row">
            <div class="column ht-border ticker-table-container">
              <table class="ui very compact small single line unstackable striped inverted table">
                <thead>
                  <TickerHeader />
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useMarketView from "./hooks/useMarketView";

import MarketWatchTable from "@/components/market/MarketWatchTable";
import MarketBBO from "@/components/market/MarketBBO";
import MarketTickerSET from "@/components/market/MarketTickerSET";
import MarketTickerTFEX from "@/components/market/MarketTickerTFEX";
import MarketStockGraph from "@/components/market/MarketStockGraph";
import MarketStockTicker from "@/components/market/MarketStockTicker";
import MarketStockQuote from "@/components/market/MarketStockQuote";
import TickerHeader from "@/components/ticker/TickerHeader.vue";

const {
  marketTickerSETHeight,
  marketTickerTFEXHeight,
  currentQuoteSelectedSymbol,
  tickerFilterSET,
  tickerFilterTFEX,
  tickerFilterModeSum,
  marketBBOContainer,
  showAllBBOMode,
  marketTickerSETContainer,
  marketTickerTFEXContainer,
  maxBBOLevel,
  isEnableDTS,
  isEnableETS,
  onSwitchLevel,
} = useMarketView();
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/market/market-view.scss";
</style>
