<template>
  <div class="quote-info">
    <sui-tab :menu="{ attached: 'top' }" class="quote-info-tabs">
      <sui-tab-pane title="Quote Info" attached="top" class="quote-info-tab">
        <QuoteInfoDetail />
      </sui-tab-pane>
      <sui-tab-pane title="Buy% / Sell%" attached="top" :style="{ height: `calc(100% - 32px)` }">
        <BuyAndSellPercent />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import QuoteInfoDetail from "@/components/quote/QuoteInfoDetail";
import BuyAndSellPercent from "./BuyAndSellPercent.vue";

export default {
  name: "QuoteInfo",
  components: {
    QuoteInfoDetail,
    BuyAndSellPercent,
  },
  data: () => ({
    isLoading: true,
  }),
  created() {
    this.$EventBus.$on("onQuoteInfoStartRefresh", this.onQuoteInfoStartRefresh);
    this.$EventBus.$on("onQuoteInfoStopRefresh", this.onQuoteInfoStopRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("h", this.onQuoteInfoStartRefresh);
    this.$EventBus.$off("onQuoteInfoStopRefresh", this.onQuoteInfoStopRefresh);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onQuoteInfoStartRefresh");
    },
    onQuoteInfoStartRefresh() {
      this.isLoading = true;
    },
    onQuoteInfoStopRefresh() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ui.top.attached.menu,
.ui.tab.segment.attached.active.top {
  margin: 0px;
  width: 100%;
}

.quote-info {
  height: 100%;
  overflow: hidden;

  .quote-info-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    .quote-info-tab {
      height: calc(100% - 32px) !important;
    }
  }
}
</style>
