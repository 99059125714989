import { baseStyle, auditCodeFormatter, sideFormatter, sideColorStyle, validityFormatter } from "../Formatter.js";
import moment from "moment";

export default [
  {
    headerName: "Time",
    field: "time",
    width: 80,
    suppressSizeToFit: true,
    cellStyle: baseStyle
  },
  {
    headerName: "Action",
    field: "action",
    width: 80,
    suppressSizeToFit: true,
    cellStyle: params => {
      if (params.data.confirmNo) {
        return sideColorStyle(params);
      }
      return baseStyle;
    },
    valueFormatter: params => {
      if (params.data.confirmNo) {
        return sideFormatter(
          {
            value: params.data.action
          },
          "SET"
        );
      } else {
        return auditCodeFormatter({
          value: params.data.action
        });
      }
    }
  },
  {
    headerName: "#Confirm",
    field: "confirmNo",
    cellStyle: baseStyle
  },
  {
    headerName: "Price",
    field: "price",
    cellStyle: baseStyle
  },
  {
    headerName: "Volume",
    field: "volume",
    cellStyle: baseStyle
  },
  {
    headerName: "Trade ID",
    field: "traderId",
    cellStyle: baseStyle
  },
  {
    headerName: "Old Price",
    field: "oldPrice",
    cellStyle: baseStyle
  },
  {
    headerName: "Old Vol",
    field: "oldVol",
    cellStyle: baseStyle
  },
  {
    headerName: "Old Pub",
    field: "oldPub",
    cellStyle: baseStyle
  },
  {
    headerName: "Old Cust",
    field: "oldCust",
    cellStyle: baseStyle
  },
  {
    headerName: "Old PC",
    field: "oldPc",
    cellStyle: baseStyle
  },
  {
    headerName: "Old TR",
    field: "oldTr",
    cellStyle: baseStyle
  },
  {
    headerName: "Cause",
    field: "cause",
    cellStyle: baseStyle
  },
  {
    headerName: "Org Web Server",
    field: "orgWebServerIp",
    cellStyle: baseStyle
  },
  {
    headerName: "Org ISP Assign",
    field: "orgISPAssgIp",
    cellStyle: baseStyle
  },
  {
    headerName: "Org Client IP",
    field: "orgClientIp",
    cellStyle: baseStyle
  },
  {
    headerName: "Old Validity",
    field: "oldValidity",
    cellStyle: baseStyle
  },
  {
    headerName: "Old Validity Data",
    field: "oldValidityData",
    cellStyle: baseStyle,
    valueFormatter: params => {
      const validity = params.data.oldValidity;

      switch (validity) {
        case "DATE":
          return moment(params.data.oldValidityData, "YYMMDD").format("DD/MM/YYYY");
        default:
          return params.data.validityData;
      }
    }
  }
];
