<template>
  <div class="quote-stock-in-play">
    <ag-grid-vue
      style="width: 100%; height: 100%; padding: 0px"
      :class="{
        'ag-theme-balham-dark': theme === 'Dark',
        'ag-theme-balham': theme === 'Light',
      }"
      row-selection="single"
      :grid-options="gridQuoteStockInPlayOptions"
      :column-defs="
        currentQuoteSelectedSymbol && currentQuoteSelectedSymbol.value && currentQuoteSelectedSymbol.value.exchangeId === 2
          ? columnDefsDTSQuoteStockInPlay
          : columnDefsETSQuoteStockInPlay
      "
      :row-data="rowQuoteStockInPlay"
      :get-row-node-id="getQuoteStockInPlayRowNodeId"
      :row-height="21"
      :header-height="32"
      :default-col-def="{ sortable: false, resizable: true }"
      :animate-rows="true"
      :suppress-drag-leave-hides-columns="true"
      :enable-cell-change-flash="true"
      :locale-text="{ noRowsToShow: `Don't have any Stock In Play.` }"
      :row-drag-managed="false"
      :pinned-bottom-row-data="pinnedBottomRowQuoteStockInPlay"
      overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
      @grid-ready="onQuoteStockInPlayGridReady"
      @cell-focused="onCellQuoteStockInPlayFocused"
    />
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import worker from "workerize-loader!@/utils/SpreadGenerator.js";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsETSQuoteStockInPlay, columnDefsDTSQuoteStockInPlay } from "@/components/grid/quote/QuoteStockInPlayColumnDefs.js";

Object.freeze(columnDefsETSQuoteStockInPlay);
Object.freeze(columnDefsDTSQuoteStockInPlay);

const workerInstance = worker();

export default {
  name: "QuoteStockInPlay",
  components: {
    AgGridVue,
  },
  data: () => ({
    columnDefsETSQuoteStockInPlay,
    columnDefsDTSQuoteStockInPlay,

    gridQuoteStockInPlayApi: null,
    columnQuoteStockInPlayApi: null,
    rowQuoteStockInPlay: [],
    pinnedBottomRowQuoteStockInPlay: [
      {
        buyCount: "",
        buyVolume: "",
        bidVolume: "",
        price: "",
        offerVolume: "",
        sellVolume: "",
        sellCount: "",
      },
    ],

    securityInfo: {},
    realHigh: 0,
    realLow: 0,
    bboLevel: 10,
    SR01RefId: "",
  }),
  watch: {
    currentQuoteSelectedSymbol() {
      this.onQuoteTableStartRefresh();
    },
  },
  methods: {
    onQuoteTableStartRefresh() {
      if (this.currentQuoteSelectedSymbol) {
        this.SR01(this.currentQuoteSelectedSymbol.value);
      }
    },
    SR01(selectedSymbol) {
      const msg = this.$messageFactory.createMessage("SR01");

      msg.securityId.set(selectedSymbol.securityId);
      msg.lastTradeCount.set(0);
      msg.full.set("Y");
      msg.exchangeId.set(selectedSymbol.exchangeId);

      this.SR01RefId = this.$shortid.generate();
      this.$ws.send(msg, this.SR01RefId);
    },
    SR06() {
      if (!this.currentQuoteSelectedSymbol) {
        this.$EventBus.$emit("onQuoteTableStopRefresh");
        return;
      }
      const msg = this.$messageFactory.createMessage("SR06");

      msg.securityId.set(this.currentQuoteSelectedSymbol.value.securityId);
      msg.exchangeId.set(this.currentQuoteSelectedSymbol.value.exchangeId);

      this.$ws.send(msg);
      if (this.gridQuoteStockInPlayApi) {
        this.gridQuoteStockInPlayApi.showLoadingOverlay();
      }
    },
    async updateRowQuoteStockInPlay(trade) {
      let allSpread = [];

      if (this.securityInfo.exchangeId === 1) {
        if (this.securityInfo.securityType === "L") {
          allSpread = await workerInstance.spreadGenerator(this.securityInfo.ceiling, this.securityInfo.floor, this.securityInfo.spread);
        } else {
          allSpread = await workerInstance.spreadGenerator(this.securityInfo.ceiling, this.securityInfo.floor);
        }
      } else if (this.securityInfo.exchangeId === 2) {
        allSpread = await workerInstance.spreadGenerator(this.securityInfo.ceiling, this.securityInfo.floor, this.securityInfo.tickSize);
      }

      let domData = [];
      allSpread.forEach((spread) => {
        this.realHigh = this.securityInfo.high;
        this.realLow = this.securityInfo.low;
        const allBidAndOffer = [];

        for (let i = 1; i <= this.bboLevel; i++) {
          allBidAndOffer.push(this.securityInfo[`offer${i}`]);
          allBidAndOffer.push(this.securityInfo[`bid${i}`]);
        }

        allBidAndOffer.push(
          this.securityInfo.exchangeId === 1
            ? this.$numeral(this.securityInfo.prior).value()
            : this.$numeral(this.securityInfo.prevSettlePrice).value()
        );

        const maxOffer = this.$_.maxBy(allBidAndOffer, (offer) => {
          return this.$numeral(offer).value();
        });
        if (this.$numeral(this.realHigh).value() < maxOffer) {
          this.realHigh = this.$numeral(maxOffer).format("0,0.00");
        }

        const minBid = this.$_.minBy(allBidAndOffer, (bid) => {
          return this.$numeral(bid).value();
        });

        if (this.$numeral(this.realLow).value() > minBid) {
          this.realLow = this.$numeral(minBid).format("0,0.00");
        }

        if (
          (this.$numeral(this.realHigh).value() &&
            this.$numeral(spread).value() > this.$numeral(this.realHigh).value() &&
            this.$numeral(spread).value() !== this.$numeral(this.securityInfo.ceiling).value()) ||
          (this.$numeral(this.realLow).value() &&
            this.$numeral(spread).value() < this.$numeral(this.realLow).value() &&
            this.$numeral(spread).value() !== this.$numeral(this.securityInfo.floor).value()) ||
          (!this.$numeral(this.realHigh).value() &&
            !this.$numeral(this.realLow).value() &&
            this.$numeral(spread).value() !== this.$numeral(this.securityInfo.ceiling).value() &&
            this.$numeral(spread).value() !== this.$numeral(this.securityInfo.floor).value() &&
            this.$numeral(spread).value() !== this.$numeral(this.securityInfo.prior).value())
        ) {
          return;
        }

        domData.push({
          price: this.$numeral(spread).format("0,0.00"),
          changeFlag: this.calcChageFlag(
            spread,
            this.securityInfo.prior,
            this.securityInfo.ceiling,
            this.securityInfo.floor,
            this.securityInfo.prevSettlePrice
          ),
        });
      });

      // let totalBidVolume = 0;
      // let totalOfferVolume = 0;
      //make bbo
      for (let i = 1; i <= this.bboLevel; i++) {
        let index = -1;

        index = this.$_.findIndex(domData, (o) => {
          return this.$numeral(o.price).value() === this.$numeral(this.securityInfo[`bid${i}`]).value();
        });

        if (index >= 0) {
          domData[index].bidVolume = this.securityInfo[`bidVol${i}`];
        }

        index = this.$_.findIndex(domData, (o) => {
          return this.$numeral(o.price).value() === this.$numeral(this.securityInfo[`offer${i}`]).value();
        });

        if (index >= 0) {
          domData[index].offerVolume = this.securityInfo[`offerVol${i}`];
        }
      }

      let totalBuyCount = 0;
      let totalSellCount = 0;
      //make deal
      trade.tradeGroups.forEach((tradeGroup) => {
        let index = this.$_.findIndex(domData, (o) => {
          return this.$numeral(o.price).value() === this.$numeral(tradeGroup.price).value();
        });
        if (index >= 0) {
          if (this.$numeral(tradeGroup.buyCount).value()) {
            domData[index].buyCount = tradeGroup.buyCount;
          }
          if (this.$numeral(tradeGroup.buyVolume).value()) {
            domData[index].buyVolume = tradeGroup.buyVolume;
          }
          if (this.$numeral(tradeGroup.sellVolume).value()) {
            domData[index].sellVolume = tradeGroup.sellVolume;
          }
          if (this.$numeral(tradeGroup.sellCount).value()) {
            domData[index].sellCount = tradeGroup.sellCount;
          }
        }

        totalBuyCount += this.$numeral(tradeGroup.buyCount).value();
        totalSellCount += this.$numeral(tradeGroup.sellCount).value();
      });

      let totalBuyVolume = 0;
      let totalSellVolume = 0;
      const totalVolume = this.$numeral(this.securityInfo.volume).value();

      if (this.securityInfo.exchangeId === 1) {
        totalBuyVolume = this.$numeral(this.securityInfo.buyVolume).value();
        totalSellVolume = this.$numeral(this.securityInfo.sellVolume).value();
      } else if (this.securityInfo.exchangeId === 2) {
        totalBuyVolume = this.$numeral(this.securityInfo.longVolume).value();
        totalSellVolume = this.$numeral(this.securityInfo.shortVolume).value();
      }
      const totalBuyVolumePercent = totalBuyVolume / totalVolume;
      const totalSellVolumePercent = totalSellVolume / totalVolume;

      this.pinnedBottomRowQuoteStockInPlay = [
        {
          buyCount: totalBuyCount,
          buyVolume: this.$numeral(totalBuyVolumePercent).format("0.00%"),
          bidVolume: "",
          price: "Total",
          offerVolume: "",
          sellVolume: this.$numeral(totalSellVolumePercent).format("0.00%"),
          sellCount: totalSellCount,
        },
      ];
      this.rowQuoteStockInPlay = domData;

      if (this.gridQuoteStockInPlayApi) {
        this.gridQuoteStockInPlayApi.hideOverlay();
      }

      this.$nextTick(() => {
        if (this.$numeral(this.securityInfo.last).value()) {
          const currentLastsaleNode = this.gridQuoteStockInPlayApi.getRowNode(this.$numeral(this.securityInfo.last).format("0,0.00"));
          if (currentLastsaleNode?.rowIndex) {
            const offset = currentLastsaleNode.rowIndex + 5 > domData.length ? 0 : 5;
            this.gridQuoteStockInPlayOptions.api.ensureIndexVisible(currentLastsaleNode.rowIndex + offset);
            this.gridQuoteStockInPlayOptions.api.setFocusedCell(currentLastsaleNode.rowIndex);
          }
        } else {
          const currentPriorNode = this.gridQuoteStockInPlayApi.getRowNode(this.$numeral(this.securityInfo.prior).format("0,0.00"));
          if (currentPriorNode?.rowIndex) {
            const offset = currentPriorNode.rowIndex + 5 > domData.length ? 0 : 5;
            this.gridQuoteStockInPlayOptions.api.ensureIndexVisible(currentPriorNode.rowIndex + offset);
            this.gridQuoteStockInPlayOptions.api.setFocusedCell(currentPriorNode.rowIndex);
          }
        }
      });
      this.$EventBus.$emit("onQuoteTableStopRefresh");
    },
    onQuoteStockInPlayGridReady(params) {
      this.gridQuoteStockInPlayApi = params.api;
      this.columnQuoteStockInPlayApi = params.columnApi;
    },
    getQuoteStockInPlayRowNodeId(data) {
      return data.price;
    },
    onCellQuoteStockInPlayFocused(event) {
      this.gridQuoteStockInPlayOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    securityInfoUpdateCinf(update) {
      if (update.refId !== this.SR01RefId) {
        this.$EventBus.$emit("onQuoteTableStopRefresh");
        return;
      }

      this.securityInfo = update;
      this.SR06();
    },
    calcChageFlag(price, prior, ceiling, floor, prevSettlePrice) {
      if (prevSettlePrice) {
        prior = prevSettlePrice;
      }

      if (this.$numeral(price).value() === this.$numeral(ceiling).value()) {
        return "C";
      } else if (this.$numeral(price).value() === this.$numeral(floor).value()) {
        return "F";
      } else if (this.$numeral(price).value() === this.$numeral(prior).value()) {
        return "N";
      } else if (this.$numeral(price).value() > this.$numeral(prior).value()) {
        return "U";
      } else if (this.$numeral(price).value() < this.$numeral(prior).value()) {
        return "D";
      }
    },
    updateBBO(update) {
      if (update.securityId !== this.currentQuoteSelectedSymbol.value.securityId) {
        return;
      }

      let allPrice = [];
      for (let i = 1; i <= this.bboLevel; i++) {
        if (!update[`price${i}`]) {
          continue;
        }
        allPrice.push(update[`price${i}`]);
      }

      //remove old bbos when bbos move
      this.gridQuoteStockInPlayApi.forEachNode((rowNode) => {
        if ((rowNode.data.bidVolume || rowNode.data.offerVolume) && !allPrice.includes(rowNode.data.price) && rowNode.data.side === update.side) {
          let updateNode = Object.assign({}, this.gridQuoteStockInPlayApi.getRowNode(rowNode.data.price).data);

          updateNode.bidVolume = "";
          updateNode.offerVolume = "";

          this.gridQuoteStockInPlayOptions.api.batchUpdateRowData({ update: [updateNode] }, () => {});
        }
      });

      if (update.side === "B" || update.side === "L") {
        for (let i = 1; i <= this.bboLevel; i++) {
          if (!update[`price${i}`]) {
            continue;
          }

          if (
            this.$numeral(update[`price${i}`]).value() > this.$numeral(this.realHigh).value() ||
            this.$numeral(update[`price${i}`]).value() < this.$numeral(this.realLow).value()
          ) {
            this.onQuoteTableStartRefresh();
            return;
          }

          let updateNode = Object.assign({}, this.gridQuoteStockInPlayApi.getRowNode(update[`price${i}`]).data);

          updateNode.bidVolume = update[`volume${i}`];

          this.gridQuoteStockInPlayOptions.api.batchUpdateRowData({ update: [updateNode] }, () => {});
        }
      } else if (update.side === "S") {
        for (let i = 1; i <= this.bboLevel; i++) {
          if (!update[`price${i}`]) {
            continue;
          }

          if (
            this.$numeral(update[`price${i}`]).value() > this.$numeral(this.realHigh).value() ||
            this.$numeral(update[`price${i}`]).value() < this.$numeral(this.realLow).value()
          ) {
            this.onQuoteTableStartRefresh();
            return;
          }

          let updateNode = Object.assign({}, this.gridQuoteStockInPlayApi.getRowNode(update[`price${i}`]).data);

          updateNode.offerVolume = update[`volume${i}`];

          this.gridQuoteStockInPlayOptions.api.batchUpdateRowData({ update: [updateNode] }, () => {});
        }
      }
    },
    lastsaleUpdateBcst(update) {
      if (update.side === "B" || update.side === "L") {
        let updateNode = Object.assign({}, this.gridQuoteStockInPlayApi.getRowNode(update.price).data);

        updateNode.buyCount = this.$numeral(this.$numeral(updateNode.buyCount).value() + 1).format("0,0");
        updateNode.buyVolume = this.$numeral(this.$numeral(updateNode.buyVolume).value() + this.$numeral(update.volume).value()).format("0,0");

        this.gridQuoteStockInPlayOptions.api.applyTransaction({ update: [updateNode] });

        const totalBuyVolumePercent = this.$numeral(update.buyVolume).value() / this.$numeral(update.totalVolume).value();
        this.pinnedBottomRowQuoteStockInPlay = [
          {
            ...this.pinnedBottomRowQuoteStockInPlay[0],
            buyCount: this.$numeral(this.$numeral(this.pinnedBottomRowQuoteStockInPlay[0].buyCount).value() + 1).format("0,0"),
            buyVolume: this.$numeral(totalBuyVolumePercent).format("0.00%"),
          },
        ];
      } else if (update.side === "S") {
        let updateNode = Object.assign({}, this.gridQuoteStockInPlayApi.getRowNode(update.price).data);

        updateNode.sellCount = this.$numeral(this.$numeral(updateNode.sellCount).value() + 1).format("0,0");
        updateNode.sellVolume = this.$numeral(this.$numeral(updateNode.sellVolume).value() + this.$numeral(update.volume).value()).format("0,0");

        this.gridQuoteStockInPlayOptions.api.applyTransaction({ update: [updateNode] });

        const totalSellVolumePercent = this.$numeral(update.sellVolume).value() / this.$numeral(update.totalVolume).value();
        this.pinnedBottomRowQuoteStockInPlay = [
          {
            ...this.pinnedBottomRowQuoteStockInPlay[0],
            sellCount: this.$numeral(this.$numeral(this.pinnedBottomRowQuoteStockInPlay[0].sellCount).value() + 1).format("0,0"),
            sellVolume: this.$numeral(totalSellVolumePercent).format("0.00%"),
          },
        ];
      } else {
        this.onQuoteTableStartRefresh();
      }
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
  },
  created() {
    this.gridQuoteStockInPlayOptions = {};
    this.onQuoteTableStartRefresh();

    this.$EventBus.$on("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$on("ctinf/SS51", this.securityInfoUpdateCinf);
    this.$EventBus.$on("ctinf/SS06", this.updateRowQuoteStockInPlay);
    this.$EventBus.$on("bcst/PB21", this.updateBBO);
    this.$EventBus.$on("bcst/PB52", this.updateBBO);
    this.$EventBus.$on("bcst/PB01", this.lastsaleUpdateBcst);
    this.$EventBus.$on("bcst/PB51", this.lastsaleUpdateBcst);

    this.$EventBus.$on("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$off("ctinf/SS51", this.securityInfoUpdateCinf);
    this.$EventBus.$off("ctinf/SS06", this.updateRowQuoteStockInPlay);
    this.$EventBus.$off("bcst/PB21", this.updateBBO);
    this.$EventBus.$off("bcst/PB52", this.updateBBO);
    this.$EventBus.$off("bcst/PB01", this.lastsaleUpdateBcst);
    this.$EventBus.$off("bcst/PB51", this.lastsaleUpdateBcst);

    this.$EventBus.$off("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.quote-stock-in-play {
  height: 100%;
}
</style>
