/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class SR05 extends WsReqMsg {
	constructor() {
		super(1);
		this._securityId = new NumberMessageBase();
		this._recordCount = new NumberMessageBase();
		this._lastRecordId = new NumberMessageBase();
		this._fromTime = new StringMessageBase();
		this._toTime = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	set securityId(securityId) {
		this._securityId = new NumberMessageBase(securityId);
	}
	
	get recordCount() {
		return this._recordCount;
	}
	
	set recordCount(recordCount) {
		this._recordCount = new NumberMessageBase(recordCount);
	}
	
	get lastRecordId() {
		return this._lastRecordId;
	}
	
	set lastRecordId(lastRecordId) {
		this._lastRecordId = new NumberMessageBase(lastRecordId);
	}
	
	get fromTime() {
		return this._fromTime;
	}
	
	set fromTime(fromTime) {
		this._fromTime = new StringMessageBase(fromTime);
	}
	
	get toTime() {
		return this._toTime;
	}
	
	set toTime(toTime) {
		this._toTime = new StringMessageBase(toTime);
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	set exchangeId(exchangeId) {
		this._exchangeId = new NumberMessageBase(exchangeId);
	}
}

export default SR05;
