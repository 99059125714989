<template>
  <div></div>
</template>

<script>
import { isDisplayPTDealCheckbox } from "@/utils/ValidationUtils";

export default {
  mounted() {
    if (this.params?.data) {
      this.$nextTick(() => {
        const result = isDisplayPTDealCheckbox(this.params.data);
        if (!result) {
          this.params.node.setSelected(false);
        }
        try {
          const el = this.$el.parentElement.parentElement.querySelector(".ag-selection-checkbox");
          if (el) {
            el.style.display = result ? "block" : "none";
            this.params.node.setSelected(false);
          }
        } catch (err) {
          // no handler
        }
      });
    }
  },
  methods: {
    refresh(params) {
      this.params = params;
      if (this.params.data) {
        this.$nextTick(() => {
          const result = isDisplayPTDealCheckbox(this.params.data);
          if (!result) {
            this.params.node.setSelected(false);
          }
          const el = this.$el.parentElement.parentElement.querySelector(".ag-selection-checkbox");
          if (el) {
            el.style.display = result ? "block" : "none";
          }
        });
      }
      return true;
    },
  },
};
</script>
