<template>
  <div class="cust-by-stock-table">
    <div style="height: 5vh">
      <div class="ui form">
        <div class="fields">
          <div v-if="securityInfo.exchangeId === 1" class="field" style="padding-left: 1.5rem">
            <div class="ui form" style="position: relative; top: 50%; transform: translateY(-50%)">
              <div class="inline fields">
                <div class="field">
                  <sui-checkbox v-model="selectedNVDR" checkbox @click="selectedTTF = false">
                    <h4 class="ui grey inverted tiny header">NVDR</h4>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox v-model="selectedTTF" checkbox @click="selectedNVDR = false">
                    <h4 class="ui grey inverted tiny header">TTF</h4>
                  </sui-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="field" v-if="securityInfo.exchangeId === 2">
            <h5 class="ui grey header inverted" style="padding: 1rem 0px 1rem 1.25rem; width: 4.5rem">Position:</h5>
          </div>
          <div v-if="securityInfo.exchangeId === 2" class="field" style="padding-left: 1.5rem">
            <div class="ui form" style="position: relative; top: 50%; transform: translateY(-50%)">
              <div class="inline fields">
                <div class="field">
                  <sui-checkbox v-model="selectedPosition" radio value="O">
                    <h4 class="ui grey tiny header inverted">Open</h4>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox v-model="selectedPosition" radio value="C">
                    <h4 class="ui grey tiny header inverted">Close</h4>
                  </sui-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem 0px 1rem 1.25rem; width: 5rem">Volume:</h5>
          </div>
          <div class="field" style="width: 12rem">
            <el-input-number v-model="volume" size="medium" :step="100" step-strictly :min="100" :max="10000000" style="width: 12rem" />
          </div>
          <div v-if="userType === 'I'" class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-right: 0px; width: 2.5rem">PIN:</h5>
          </div>
          <div v-if="userType === 'I'" class="field" style="width: 12rem; padding: 0px 0px 0px 1rem !important">
            <div style="padding-top: 0.55vh">
              <InputPIN ref="pin" v-model="pin" input-style="height: 36px;" name="PIN" placeholder="PIN" required />
            </div>
          </div>
        </div>
      </div>
    </div>
    <draggable v-model="draggableData" disabled style="flex: 1">
      <ag-grid-vue
        class="custbystock"
        :class="{
          'ag-theme-balham-dark': theme === 'Dark',
          'ag-theme-balham': theme === 'Light',
        }"
        row-selection="single"
        :grid-options="gridCustByStockOptions"
        :column-defs="securityInfo.exchangeId === 2 ? columnDefsCustByStockDTS : columnDefsCustByStockETS"
        :row-data="rowCustByStock"
        :get-row-node-id="getCustByStockRowNodeId"
        :row-height="21"
        :header-height="32"
        :default-col-def="{ sortable: false, resizable: true }"
        :animate-rows="true"
        :suppress-drag-leave-hides-columns="true"
        :enable-cell-change-flash="true"
        :locale-text="{ noRowsToShow: `Don't have any Cust. By Stock.` }"
        :row-drag-managed="false"
        overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
        @grid-ready="onCustByStockGridReady"
        @cell-focused="onCellCustByStockFocused"
      />
    </draggable>
    <div class="footer">
      <div class="ui center aligned grid">
        <sui-button type="button" positive :loading="processing" :disabled="processing" @click="onCancelAllBuyClick"
          ><i class="trash alternate icon"></i> Cancel All {{ securityInfo.exchangeId === 2 ? "Long" : "Buy" }} Orders
        </sui-button>
        <button class="ui button" @click="onLastPriceClick">
          <i class="crosshairs icon"></i>
          Last Price
        </button>
        <sui-button type="button" negative :loading="processing" :disabled="processing" @click="onCancelAllSellClick"
          ><i class="trash alternate icon"></i>Cancel All {{ securityInfo.exchangeId === 2 ? "Short" : "Sell" }} Orders</sui-button
        >
      </div>
    </div>
    <SelectAccountModal ref="accountModal" />
    <SelectOrderChangeModal ref="changeModal" />
    <SelectOrderCancelModel ref="cancelModal" />
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import worker from "workerize-loader!@/utils/SpreadGenerator.js";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsCustByStockETS, columnDefsCustByStockDTS } from "@/components/grid/custbystock/ColumnDefs.js";
import CustByStockOrderCellRenderer from "@/components/grid/custbystock/CustByStockOrderCellRenderer";
import CustByStockBBOCellRenderer from "@/components/grid/custbystock/CustByStockBBOCellRenderer";
import InputPIN from "@/components/InputPIN";
import { InputNumber } from "element-ui";
import SelectAccountModal from "@/components/modal/SelectAccountModal";
import SelectOrderChangeModal from "@/components/modal/SelectOrderChangeModal";
import SelectOrderCancelModel from "@/components/modal/SelectOrderCancelModel";
import { price as PriceFormatter, volume as VolumeFormatter } from "@/utils/NumberFormatter";
import { parseStringEtsDateToEtsDate } from "@/utils/DateUtils";

Object.freeze(columnDefsCustByStockETS);
Object.freeze(columnDefsCustByStockDTS);

const workerInstance = worker();

export default {
  name: "CustByStockTable",
  components: {
    AgGridVue,
    /* eslint-disable */
    // it use for aggrid column define
    CustByStockOrderCellRenderer,
    /* eslint-disable */
    // it use for aggrid column define
    CustByStockBBOCellRenderer,
    InputPIN,
    "el-input-number": InputNumber,
    SelectAccountModal,
    SelectOrderChangeModal,
    SelectOrderCancelModel,
  },
  data: () => ({
    columnDefsCustByStockETS,
    columnDefsCustByStockDTS,
    selectedNVDR: false,
    selectedTTF: false,
    selectedPosition: "O",
    volume: 100,
    pin: "",
    account: null,
    gridCustByStockApi: null,
    columnCustByStockApi: null,
    rowCustByStock: [],
    securityInfo: {},
    SR01RefId: "",
    orderInsertRefId: [],
    orderChangeRefId: [],
    orderCancelRefId: [],
    changeFromIndex: null,
    changeToIndex: null,
    changeMode: null,
    currentTableTop: 0,
    errorChangeReponses: [],
    errorCancelReponses: [],
    successCancelReponses: [],
    draggableData: null,
    bboLevel: 10,
  }),
  watch: {
    currentQuoteSelectedSymbol() {
      this.onCustByStockStartRefresh();
    },
    currentAccount() {
      this.init();
      this.onCustByStockStartRefresh();
    },
    volume(volume) {
      if (!volume) {
        this.volume = 100;
      }
    },
  },
  methods: {
    init() {
      if (this.currentAccount) {
        if (!this.currentAccount || this.currentAccount === "all_accounts") {
          this.account = null;
        } else if (this.currentAccount === "all_equity_accounts") {
          this.account = null;
        } else if (this.currentAccount === "all_derivative_accounts") {
          this.account = null;
        } else {
          this.account = this.currentAccount;
        }
      }
    },
    onCustByStockStartRefresh() {
      if (this.gridCustByStockApi) {
        this.currentTableTop = parseInt(this.gridCustByStockApi.getVerticalPixelRange().top / 21);
      }
      this.rowCustByStock = [];
      if (this.currentQuoteSelectedSymbol) {
        this.SR01(this.currentQuoteSelectedSymbol.value);
      }
    },
    SR01(selectedSymbol) {
      const msg = this.$messageFactory.createMessage("SR01");

      msg.securityId.set(selectedSymbol.securityId);
      msg.lastTradeCount.set(5);
      msg.full.set("Y");
      msg.exchangeId.set(selectedSymbol.exchangeId);

      this.SR01RefId = this.$shortid.generate();
      this.$ws.send(msg, this.SR01RefId);
      if (this.gridCustByStockApi) {
        this.gridCustByStockApi.showLoadingOverlay();
      }
    },
    OR04(securityId, accountCode) {
      const msg = this.$messageFactory.createMessage("OR04");
      if (accountCode) {
        msg.accountCode.set(accountCode);
      }
      msg.securityId.set(securityId);
      msg.recordCount.set(Number.MAX_SAFE_INTEGER);
      msg.lastRecordId.set(0);

      this.OR04RefId = this.$shortid.generate();
      this.$ws.send(msg, this.OR04RefId);
    },
    OR54(securityId, accountCode) {
      const msg = this.$messageFactory.createMessage("OR54");
      if (accountCode) {
        msg.accountCode.set(accountCode);
      }
      msg.securityId.set(securityId);
      msg.recordCount.set(Number.MAX_SAFE_INTEGER);
      msg.lastRecordId.set(0);

      this.OR54RefId = this.$shortid.generate();
      this.$ws.send(msg, this.OR54RefId);
    },
    async securityInfoUpdateCinf(update) {
      if (update.refId !== this.SR01RefId) {
        this.$EventBus.$emit("onCustByStockStopRefresh");
        return;
      }

      this.securityInfo = update;

      let allSpread = [];
      if (this.securityInfo.exchangeId === 1) {
        if (this.securityInfo.securityType === "L") {
          allSpread = await workerInstance.spreadGenerator(this.securityInfo.ceiling, this.securityInfo.floor, this.securityInfo.spread);
        } else {
          allSpread = await workerInstance.spreadGenerator(this.securityInfo.ceiling, this.securityInfo.floor);
        }
      } else if (this.securityInfo.exchangeId === 2) {
        allSpread = await workerInstance.spreadGenerator(this.securityInfo.ceiling, this.securityInfo.floor, this.securityInfo.tickSize);
      }

      let domData = [];

      allSpread.forEach((spread, _index) => {
        domData.push({
          buyOrders: [],
          bidVolume: "",
          price: spread,
          changeFlag: this.calcChageFlag(
            spread,
            this.securityInfo.prior,
            this.securityInfo.ceiling,
            this.securityInfo.floor,
            this.securityInfo.prevSettlePrice
          ),
          offerVolume: "",
          sellOrders: [],
        });
      });

      //make bbo
      for (let i = 1; i <= this.bboLevel; i++) {
        let index = -1;

        index = this.$_.findIndex(domData, (o) => {
          return this.$numeral(o.price).value() === this.$numeral(this.securityInfo[`bid${i}`]).value();
        });

        if (index >= 0) {
          domData[index].bidVolume = this.securityInfo[`bidVol${i}`];
        }

        index = this.$_.findIndex(domData, (o) => {
          return this.$numeral(o.price).value() === this.$numeral(this.securityInfo[`offer${i}`]).value();
        });

        if (index >= 0) {
          domData[index].offerVolume = this.securityInfo[`offerVol${i}`];
        }
      }

      this.rowCustByStock = domData;
      this.onLastPriceClick();

      if (this.securityInfo.exchangeId === 1) {
        this.OR04(this.securityInfo.securityId, this.account);
      } else if (this.securityInfo.exchangeId === 2) {
        this.OR54(this.securityInfo.securityId, this.account);
      }
    },
    etsOrderQueryCtinf(orders) {
      if (this.OR04RefId !== orders.refId) {
        return;
      }
      if (orders.resultCode === 1001) {
        orders.records.forEach((order) => {
          const priceIndex = this.$_.findIndex(this.rowCustByStock, (o) => {
            return this.$numeral(o.price).value() === this.$numeral(order.price).value();
          });

          if (priceIndex !== -1) {
            if (order.side === "B") {
              if (!this.rowCustByStock[priceIndex].buyOrders) {
                this.rowCustByStock[priceIndex].buyOrders = [];
              }
              if (["O", "PO"].includes(order.status)) {
                this.rowCustByStock[priceIndex].buyOrders.push(order);
              }
            } else if (order.side === "S") {
              if (!this.rowCustByStock[priceIndex].sellOrders) {
                this.rowCustByStock[priceIndex].buyOrders = [];
              }
              if (["O", "PO"].includes(order.status)) {
                this.rowCustByStock[priceIndex].sellOrders.push(order);
              }
            }
          }
        });
      } else {
        this.$Swal.fire(`Error ${orders.resultCode}!`, orders.resultMessage, "error");
      }
      if (this.gridCustByStockApi) {
        this.gridCustByStockApi.hideOverlay();
        if (this.currentTableTop) {
          this.gridCustByStockApi.ensureIndexVisible(this.currentTableTop, "top");
        }
      }
      this.$EventBus.$emit("onCustByStockStopRefresh");
    },
    dtsOrderQueryCtinf(orders) {
      if (this.OR54RefId !== orders.refId) {
        return;
      }
      if (orders.resultCode === 1001) {
        orders.records.forEach((order) => {
          const priceIndex = this.$_.findIndex(this.rowCustByStock, (o) => {
            return this.$numeral(o.price).value() === this.$numeral(order.price).value();
          });

          if (priceIndex !== -1) {
            if (order.side === "L") {
              if (!this.rowCustByStock[priceIndex].buyOrders) {
                this.rowCustByStock[priceIndex].buyOrders = [];
              }
              if (["O", "PO"].includes(order.status)) {
                this.rowCustByStock[priceIndex].buyOrders.push(order);
              }
            } else if (order.side === "S") {
              if (!this.rowCustByStock[priceIndex].sellOrders) {
                this.rowCustByStock[priceIndex].buyOrders = [];
              }
              if (["O", "PO"].includes(order.status)) {
                this.rowCustByStock[priceIndex].sellOrders.push(order);
              }
            }
          }
        });
      } else {
        this.$Swal.fire(`Error ${orders.resultCode}!`, orders.resultMessage, "error");
      }
      if (this.gridCustByStockApi) {
        this.gridCustByStockApi.hideOverlay();
      }
      this.$EventBus.$emit("onCustByStockStopRefresh");
    },
    calcChageFlag(price, prior, ceiling, floor, prevSettlePrice) {
      if (prevSettlePrice) {
        prior = prevSettlePrice;
      }

      if (this.$numeral(price).value() === this.$numeral(ceiling).value()) {
        return "C";
      } else if (this.$numeral(price).value() === this.$numeral(floor).value()) {
        return "F";
      } else if (this.$numeral(price).value() === this.$numeral(prior).value()) {
        return "N";
      } else if (this.$numeral(price).value() > this.$numeral(prior).value()) {
        return "U";
      } else if (this.$numeral(price).value() < this.$numeral(prior).value()) {
        return "D";
      }
    },
    onCustByStockGridReady(params) {
      this.gridCustByStockApi = params.api;
      this.columnCustByStockApi = params.columnApi;
    },
    getCustByStockRowNodeId(data) {
      return this.$numeral(data.price).value();
    },
    onCellCustByStockFocused(event) {
      this.gridCustByStockOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    async onCancelAllBuyClick() {
      let allBuyOrders = [];
      this.rowCustByStock.forEach((row) => {
        if (row.buyOrders.length) {
          allBuyOrders.push(...row.buyOrders);
        }
      });

      if (this.securityInfo.exchangeId === 1) {
        const selectOrders = await this.$refs.cancelModal.selectOrder("SET", this.pin, allBuyOrders);

        this.orderCancelRefId = [];
        this.errorCancelReponses = [];
        this.successCancelReponses = [];
        selectOrders.forEach((order) => {
          const refId = this.sendSETCancelOrder(order);
          this.orderCancelRefId.push(refId);
        });
      } else if (this.securityInfo.exchangeId === 2) {
        const selectOrders = await this.$refs.cancelModal.selectOrder("TFEX", this.pin, allBuyOrders);

        this.orderCancelRefId = [];
        this.errorCancelReponses = [];
        this.successCancelReponses = [];
        selectOrders.forEach((order) => {
          const refId = this.sendTFEXCancelOrder(order);
          this.orderCancelRefId.push(refId);
        });
      }
    },
    async onCancelAllSellClick() {
      let allSellOrders = [];
      this.rowCustByStock.forEach((row) => {
        if (row.sellOrders.length) {
          allSellOrders.push(...row.sellOrders);
        }
      });

      if (this.securityInfo.exchangeId === 1) {
        const selectOrders = await this.$refs.cancelModal.selectOrder("SET", this.pin, allSellOrders);

        this.orderCancelRefId = [];
        this.errorCancelReponses = [];
        this.successCancelReponses = [];
        selectOrders.forEach((order) => {
          const refId = this.sendSETCancelOrder(order);
          this.orderCancelRefId.push(refId);
        });
      } else if (this.securityInfo.exchangeId === 2) {
        const selectOrders = await this.$refs.cancelModal.selectOrder("TFEX", this.pin, allSellOrders);

        this.orderCancelRefId = [];
        this.errorCancelReponses = [];
        this.successCancelReponses = [];
        selectOrders.forEach((order) => {
          const refId = this.sendTFEXCancelOrder(order);
          this.orderCancelRefId.push(refId);
        });
      }
    },
    onUpClick() {},
    onLastPriceClick() {
      this.$nextTick(() => {
        if (this.$numeral(this.securityInfo.last).value() && this.gridCustByStockApi) {
          const currentLastsaleNode = this.gridCustByStockApi.getRowNode(this.$numeral(this.securityInfo.last).value());
          if (currentLastsaleNode?.rowIndex) {
            const offset = currentLastsaleNode.rowIndex + 5 > this.rowCustByStock.length ? 0 : 5;
            this.gridCustByStockOptions.api.ensureIndexVisible(currentLastsaleNode.rowIndex + offset);
            this.gridCustByStockOptions.api.setFocusedCell(currentLastsaleNode.rowIndex);
          }
        } else if (this.$numeral(this.securityInfo.prior).value() && this.gridCustByStockApi) {
          const currentPriorNode = this.gridCustByStockApi.getRowNode(this.$numeral(this.securityInfo.prior).value());

          if (currentPriorNode?.rowIndex) {
            const offset = currentPriorNode.rowIndex + 5 > this.rowCustByStock.length ? 0 : 5;
            this.gridCustByStockOptions.api.ensureIndexVisible(currentPriorNode.rowIndex + offset);
            this.gridCustByStockOptions.api.setFocusedCell(currentPriorNode.rowIndex);
          }
        }
      });
    },
    onDownClick() {},
    async onOrderInsert(side, params) {
      if (!this.securityInfo) {
        return;
      }

      let securityMarket = null;
      if (this.securityInfo.exchangeId === 1) {
        securityMarket = "SET";
      } else if (this.securityInfo.exchangeId === 2) {
        securityMarket = "TFEX";
      }

      const price = params.price;
      let account = this.account;
      if (!this.account) {
        account = await this.$refs.accountModal.selectAccount(securityMarket);
        if (!account) {
          return;
        }
      }

      if (this.securityInfo.exchangeId === 1) {
        this.sendSETLimitOrder(side, account, this.volume, price);
      } else if (this.securityInfo.exchangeId === 2) {
        this.sendTFEXLimitOrder(side, this.selectedPosition, account, this.volume, price);
      }
    },
    sendSETLimitOrder(side, account, volume, price) {
      if (this.userType === "I") {
        const result = this.$refs.pin.validate();
        if (!result) {
          this.$Swal.fire(`Warning!`, `The PIN is required.`, "warning");
          return;
        }
      }

      let sideLabel = side;
      if (side === "B") {
        sideLabel = "Buy";
      } else if (side === "S") {
        sideLabel = "Sell";
      }

      const html = `
        <div class="ui two column grid">
          <div class="right aligned column ht-${this.side}" style="padding-right: 1rem !important;">${sideLabel}:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${this.securityInfo.symbol}</div>
          <div class="right aligned column" style="padding-right: 1rem !important;">Volume:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${VolumeFormatter(volume)}</div>
          <div class="right aligned column" style="padding-right: 1rem !important;">Price:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${PriceFormatter(price)}</div>
          <div class="right aligned column" style="padding-right: 1rem !important;">Account:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${account}</div>
          <div class="right aligned column" style="padding-right: 1rem !important;">Total Amount:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${PriceFormatter((price * volume).toFixed(2))}</div>
        </div>
      `;
      this.$Swal
        .fire({
          title: "Order Confirmation",
          html: html,
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            const msg = this.$messageFactory.createMessage("OR01");
            msg.symbol.set(this.securityInfo.symbol);
            msg.side.set(side);
            msg.volume.set(volume);
            msg.price.set(price);
            msg.accountCode.set(account);
            if (this.selectedTTF) {
              msg.ttf.set("1");
            }
            if (this.selectedNVDR) {
              msg.ttf.set("2");
            }

            if (this.userType === "I") {
              msg.pinCode.set(this.pin);
            }

            this.orderInsertRefId = this.$shortid.generate();
            this.$ws.send(msg, this.orderInsertRefId);
          } else if (result.dismiss === this.$Swal.DismissReason.cancel) {
            return false;
          }
        });
    },
    sendTFEXLimitOrder(side, position, account, volume, price) {
      if (this.userType === "I") {
        const result = this.$refs.pin.validate();
        if (!result) {
          this.$Swal.fire(`Warning!`, `The PIN is required.`, "warning");
          return;
        }
      }

      let sideLabel = side;
      if (side === "B") {
        side = "L";
        sideLabel = "Long";
      } else if (side === "S") {
        sideLabel = "Short";
      }

      let positionLabel = position;
      if (position === "O") {
        positionLabel = "Open";
      } else if (position === "C") {
        positionLabel = "Close";
      }

      const html = `
        <div class="ui two column grid">
          <div class="right aligned column ht-${this.side}" style="padding-right: 1rem !important;">${sideLabel}:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${this.securityInfo.symbol}</div>
          <div class="right aligned column" style="padding-right: 1rem !important;">Position:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${positionLabel}</div>
          <div class="right aligned column" style="padding-right: 1rem !important;">Volume:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${VolumeFormatter(volume)}</div>
          <div class="right aligned column" style="padding-right: 1rem !important;">Price:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${PriceFormatter(price)}</div>
          <div class="right aligned column" style="padding-right: 1rem !important;">Account:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${account}</div>
          <div class="right aligned column" style="padding-right: 1rem !important;">Total Amount:</div>
          <div class="left aligned column" style="padding-left: 1rem !important;">${PriceFormatter(price * volume)}</div>
        </div>
      `;
      this.$Swal
        .fire({
          title: "Order Confirmation",
          html: html,
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            const msg = this.$messageFactory.createMessage("OR51");
            msg.symbol.set(this.securityInfo.symbol);
            msg.side.set(side);
            msg.positionType.set(position);
            msg.volume.set(volume);
            msg.price.set(price);
            msg.accountCode.set(account);

            if (this.userType === "I") {
              msg.pinCode.set(this.pin);
            }

            this.orderInsertRefId = this.$shortid.generate();
            this.$ws.send(msg, this.orderInsertRefId);
          } else if (result.dismiss === this.$Swal.DismissReason.cancel) {
            return false;
          }
        });
    },
    clearForm() {
      this.pin = null;
    },
    reloadData() {
      this.$EventBus.$emit("onQuoteInfoStartRefresh");
      this.$EventBus.$emit("onCustByStockStartRefresh");
    },
    etsOrderInsertCtord(data) {
      if (this.orderInsertRefId !== data.refId) {
        return;
      }
      if (data.resultCode === 1001) {
        this.clearForm();
      } else {
        let input = null;
        if (data.resultCode === 2002) {
          // Invalid pin
          input = this.$refs.pin;
        }
        this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error").then(() => {
          if (input) {
            input.customError(data.resultMessage);
            // break for keyup event
            setTimeout(() => {
              input.$el.querySelector("input").select();
            }, 200);
          }
        });
      }
    },
    dtsOrderInsertCtord(data) {
      if (this.orderInsertRefId !== data.refId) {
        return;
      }
      if (data.resultCode === 1001) {
        this.clearForm();
      } else {
        let input = null;
        if (data.resultCode === 2002) {
          // Invalid pin
          input = this.$refs.pin;
        }
        this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error").then(() => {
          if (input) {
            input.customError(data.resultMessage);
            // break for keyup event
            setTimeout(() => {
              input.$el.querySelector("input").select();
            }, 200);
          }
        });
      }
    },
    updateBBO(update) {
      if (update.securityId !== this.currentQuoteSelectedSymbol.value.securityId) {
        return;
      }

      let allPrice = [];
      for (let i = 1; i <= this.bboLevel; i++) {
        if (!update[`price${i}`]) {
          continue;
        }
        allPrice.push(update[`price${i}`]);
      }

      //remove old bbos when bbos move
      this.gridCustByStockApi.forEachNode((rowNode, index) => {
        if ((update.side === "B" || update.side === "L") && this.gridCustByStockApi) {
          if (rowNode.data.bidVolume && !allPrice.includes(rowNode.data.price)) {
            let updateNode = Object.assign({}, this.gridCustByStockApi.getRowNode(this.$numeral(rowNode.data.price).value()).data);

            updateNode.bidVolume = "";

            this.gridCustByStockOptions.api.batchUpdateRowData({ update: [updateNode] }, () => {});
          }
        } else if (update.side === "S" && this.gridCustByStockApi) {
          if (rowNode.data.offerVolume && !allPrice.includes(rowNode.data.price)) {
            let updateNode = Object.assign({}, this.gridCustByStockApi.getRowNode(this.$numeral(rowNode.data.price).value()).data);

            updateNode.offerVolume = "";

            this.gridCustByStockOptions.api.batchUpdateRowData({ update: [updateNode] }, () => {});
          }
        }
      });

      const high = this.rowCustByStock.slice(1)[0].price;
      const low = this.rowCustByStock.slice(-2)[0].price;

      if ((update.side === "B" || update.side === "L") && this.gridCustByStockApi) {
        for (let i = 1; i <= this.bboLevel; i++) {
          if (!update[`price${i}`]) {
            continue;
          }

          if (
            this.$numeral(update[`price${i}`]).value() > this.$numeral(high).value() ||
            this.$numeral(update[`price${i}`]).value() < this.$numeral(low).value()
          ) {
            this.onCustByStockStartRefresh();
            return;
          }

          let updateNode = Object.assign({}, this.gridCustByStockApi.getRowNode(this.$numeral(update[`price${i}`]).value()).data);

          updateNode.bidVolume = update[`volume${i}`];

          this.gridCustByStockOptions.api.batchUpdateRowData({ update: [updateNode] }, () => {});
        }
      } else if (update.side === "S" && this.gridCustByStockApi) {
        for (let i = 1; i <= this.bboLevel; i++) {
          if (!update[`price${i}`]) {
            continue;
          }

          if (
            this.$numeral(update[`price${i}`]).value() > this.$numeral(high).value() ||
            this.$numeral(update[`price${i}`]).value() < this.$numeral(low).value()
          ) {
            this.onCustByStockStartRefresh();
            return;
          }

          let updateNode = Object.assign({}, this.gridCustByStockApi.getRowNode(this.$numeral(update[`price${i}`]).value()).data);

          updateNode.offerVolume = update[`volume${i}`];

          this.gridCustByStockOptions.api.batchUpdateRowData({ update: [updateNode] }, () => {});
        }
      }
    },
    async onOrderDroped(params, fromIndex, toIndex) {
      if (fromIndex === toIndex) {
        return;
      }
      const { mode } = params;

      if (this.securityInfo.exchangeId === 1) {
        if (this.userType === "I") {
          const result = this.$refs.pin.validate();
          if (!result) {
            this.$Swal.fire(`Warning!`, `The PIN is required.`, "warning");
            this.reloadData();
            return;
          }
        }

        let orders = [];
        if (mode === "BUY") {
          orders = this.rowCustByStock[fromIndex].buyOrders;
        } else if (mode === "SELL") {
          orders = this.rowCustByStock[fromIndex].sellOrders;
        }

        const selectOrders = await this.$refs.changeModal.selectOrder(
          "SET",
          this.rowCustByStock[fromIndex].price,
          this.rowCustByStock[toIndex].price,
          this.pin,
          orders
        );

        if (!selectOrders) {
          this.reloadData();
          return;
        }

        this.orderChangeRefId = [];
        this.errorChangeReponses = [];
        selectOrders.forEach((order) => {
          const refId = this.sendSETChangeOrder(order, this.rowCustByStock[toIndex].price);
          this.orderChangeRefId.push(refId);
          this.changeFromIndex = fromIndex;
          this.changeToIndex = toIndex;
          this.changeMode = mode;
        });
      } else if (this.securityInfo.exchangeId == 2) {
        if (this.userType === "I") {
          const result = this.$refs.pin.validate();
          if (!result) {
            this.$Swal.fire(`Warning!`, `The PIN is required.`, "warning");
            this.reloadData();
            return;
          }
        }

        let orders = [];
        if (mode === "BUY") {
          orders = this.rowCustByStock[fromIndex].buyOrders;
        } else if (mode === "SELL") {
          orders = this.rowCustByStock[fromIndex].sellOrders;
        }

        const selectOrders = await this.$refs.changeModal.selectOrder(
          "TFEX",
          this.rowCustByStock[fromIndex].price,
          this.rowCustByStock[toIndex].price,
          this.pin,
          orders
        );

        if (!selectOrders) {
          this.reloadData();
          return;
        }

        this.orderChangeRefId = [];
        this.errorChangeReponses = [];
        selectOrders.forEach((order) => {
          const refId = this.sendTFEXChangeOrder(order, this.rowCustByStock[toIndex].price);
          this.orderChangeRefId.push(refId);
          this.changeFromIndex = fromIndex;
          this.changeToIndex = toIndex;
          this.changeMode = mode;
        });
      }
    },
    sendSETChangeOrder(order, price) {
      if (this.userType === "I") {
        const result = this.$refs.pin.validate();
        if (!result) {
          this.$Swal.fire(`Warning!`, `The PIN is required.`, "warning");
          return;
        }
      }

      const msg = this.$messageFactory.createMessage("OR02");
      msg.orderId.set(order.orderId);
      msg.accountCode.set(order.accountCode);
      msg.price.set(price);

      if (this.userType === "I") {
        msg.pinCode.set(this.pin);
      }

      const orderChangeRefId = this.$shortid.generate();
      this.$ws.send(msg, orderChangeRefId);
      return orderChangeRefId;
    },
    sendTFEXChangeOrder(order, price) {
      if (this.userType === "I") {
        const result = this.$refs.pin.validate();
        if (!result) {
          this.$Swal.fire(`Warning!`, `The PIN is required.`, "warning");
          return;
        }
      }

      const msg = this.$messageFactory.createMessage("OR52");
      msg.orderId.set(order.orderId);
      msg.accountCode.set(order.accountCode);
      msg.sendDate.set(parseStringEtsDateToEtsDate(order.sendDate));
      msg.price.set(price);

      if (this.userType === "I") {
        msg.pinCode.set(this.pin);
      }

      const orderChangeRefId = this.$shortid.generate();
      this.$ws.send(msg, orderChangeRefId);
      return orderChangeRefId;
    },
    moveOrderWhenChange(data) {
      if (this.changeMode === "BUY") {
        const changedOrder = this.$_.find(this.rowCustByStock[this.changeFromIndex].buyOrders, ["orderId", data.orderId]);
        this.$_.remove(this.rowCustByStock[this.changeFromIndex].buyOrders, (item) => item.orderId === data.orderId);
        if (changedOrder) {
          this.rowCustByStock[this.changeToIndex].buyOrders.push(changedOrder);
        }
      } else if (this.changeMode === "SELL") {
        const changedOrder = this.$_.find(this.rowCustByStock[this.changeFromIndex].sellOrders, ["orderId", data.orderId]);
        this.$_.remove(this.rowCustByStock[this.changeFromIndex].sellOrders, (item) => item.orderId === data.orderId);
        if (changedOrder) {
          this.rowCustByStock[this.changeToIndex].sellOrders.push(changedOrder);
        }
      }
      this.currentTableTop = parseInt(this.gridCustByStockApi.getVerticalPixelRange().top / 21);
      this.rowCustByStock = Object.assign([], this.rowCustByStock);
      if (this.currentTableTop) {
        this.$nextTick(() => {
          this.gridCustByStockApi.ensureIndexVisible(this.currentTableTop, "top");
        });
      }
    },
    etsOrderChangedCtord(data) {
      if (!this.orderChangeRefId) {
        return;
      }
      if (this.orderChangeRefId.length > 1) {
        if (this.orderChangeRefId.includes(data.refId)) {
          this.$_.remove(this.orderChangeRefId, (d) => d === data.refId);
          if (data.resultCode !== 1001) {
            this.errorChangeReponses.push(data);
          } else {
            this.moveOrderWhenChange(data);
          }
          if (this.orderChangeRefId.length > 0) {
            return;
          }
        }
        this.orderChangeRefId = null;

        if (this.errorChangeReponses.length > 0) {
          let input = null;
          let html = "";
          if (this.errorChangeReponses.length > 0) {
            html += `<h3 class="ui red header">Error ${this.errorChangeReponses.length}</h3>`;
            html += `<div class="ui two column grid">`;
            this.errorChangeReponses.forEach((r) => {
              if (r.resultCode === 2002) {
                // Invalid pin
                input = this.$refs.pin;
              }
              html += `<div class="column">
                <div class="ui two column grid">
                  <div class="column">${r.orderId}</div>
                  <div class="column">${r.sendDate}</div>
                </div>
              </div>
              <div class="column" style="text-align: left; color: #d91e18">${r.resultCode}: ${r.resultMessage}</div>`;
            });
            html += "</div>";
          }
          this.$Swal.fire({
            icon: "error",
            html: html,
            onClose: () => {
              this.clearForm();
              this.reloadData();
              if (input) {
                input.customError(data.resultMessage);
                // break for keyup event
                setTimeout(() => {
                  input.$el.querySelector("input").select();
                }, 200);
              }
            },
          });
        } else {
          this.clearForm();
        }
      } else {
        if (data.resultCode === 1001) {
          this.moveOrderWhenChange(data);
          this.clearForm();
        } else {
          let input = null;
          if (data.resultCode === 2002) {
            // Invalid pin
            input = this.$refs.pin;
          }
          this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error").then(() => {
            this.reloadData();
            if (input) {
              input.customError(data.resultMessage);
              // break for keyup event
              setTimeout(() => {
                input.$el.querySelector("input").select();
              }, 200);
            }
          });
        }
      }
    },
    dtsOrderChangedCtord(data) {
      if (this.dtsChangeOrderRefId !== data.refId) {
        return;
      }
      if (data.resultCode === 1001) {
        this.clearForm();
      } else {
        let input = null;
        if (data.resultCode === 2002) {
          // Invalid pin
          input = this.$refs.pin;
        }
        this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error").then(() => {
          if (input) {
            input.customError(data.resultMessage);
            // break for keyup event
            setTimeout(() => {
              input.$el.querySelector("input").select();
            }, 200);
          }
        });
      }
    },
    sendSETCancelOrder(order) {
      if (this.userType === "I") {
        const result = this.$refs.pin.validate();
        if (!result) {
          this.$Swal.fire(`Warning!`, `The PIN is required.`, "warning");
          return;
        }
      }

      const msg = this.$messageFactory.createMessage("OR03");

      msg.orderId.set(order.orderId);
      msg.accountCode.set(order.accountCode);
      if (this.userType === "I") {
        msg.pinCode.set(this.pin);
      }
      msg.sendDate.set(parseStringEtsDateToEtsDate(order.sendDate));

      const orderCancelRefId = this.$shortid.generate();
      this.$ws.send(msg, orderCancelRefId);
      return orderCancelRefId;
    },
    sendTFEXCancelOrder(order) {
      if (this.userType === "I") {
        const result = this.$refs.pin.validate();
        if (!result) {
          this.$Swal.fire(`Warning!`, `The PIN is required.`, "warning");
          return;
        }
      }

      const msg = this.$messageFactory.createMessage("OR53");

      msg.orderId.set(order.orderId);
      msg.accountCode.set(order.accountCode);
      if (this.userType === "I") {
        msg.pinCode.set(this.pin);
      }
      msg.sendDate.set(parseStringEtsDateToEtsDate(order.sendDate));

      const orderCancelRefId = this.$shortid.generate();
      this.$ws.send(msg, orderCancelRefId);
      return orderCancelRefId;
    },
    etsOrderCancelCtord(data) {
      const index = this.$_.indexOf(this.orderCancelRefId, data.refId);
      if (index !== -1) {
        this.orderCancelRefId.splice(index, 1);
        if (data.resultCode === 1001) {
          this.clearForm();
          this.successCancelReponses.push(data);
        } else {
          this.errorCancelReponses.push(data);
        }

        if (this.orderCancelRefId.length === 0) {
          // finish
          this.cancelFinish();
        }
      }
    },
    etsOrderCancelSuccessCtord(data) {
      const index = this.$_.indexOf(this.orderCancelRefId, data.refId);
      if (index !== -1) {
        this.orderCancelRefId.splice(index, 1);
        if (data.resultCode === 1001) {
          this.clearForm();
          this.successCancelReponses.push(data);
        } else {
          this.errorCancelReponses.push(data);
        }

        if (this.orderCancelRefId.length === 0) {
          // finish
          this.cancelFinish();
        }
      }
    },
    dtsOrderCancelCtord(data) {
      const index = this.$_.indexOf(this.orderCancelRefId, data.refId);
      if (index !== -1) {
        this.orderCancelRefId.splice(index, 1);
        if (data.resultCode === 1001) {
          this.clearForm();
          this.successCancelReponses.push(data);
        } else {
          this.errorCancelReponses.push(data);
        }

        if (this.orderCancelRefId.length === 0) {
          // finish
          this.cancelFinish();
        }
      }
    },
    cancelFinish() {
      const type = this.errorCancelReponses.length > 0 ? "error" : "success";
      if (type === "error") {
        const code = this.errorCancelReponses[0].resultCode;
        if (code === 2002) {
          this.$Swal.fire({
            icon: type,
            text: this.errorCancelReponses[0].resultMessage,
            title: `Error ${code}!`,
            onClose: () => {
              this.processing = false;
              this.$nextTick(() => {
                this.$refs.pin.focus();
                this.$refs.pin.select();
              });
            },
          });
          return;
        }
      }

      let html = "";
      if (this.successCancelReponses.length > 0) {
        html += `<h3 class="ui green header">Success ${this.successCancelReponses.length}</h3>`;
        html += `<div class="ui two column grid">`;
        this.successCancelReponses.forEach((r) => {
          html += `<div class="column">
            <div class="ui two column grid">
              <div class="column">${r.orderId}</div>
              <div class="column">${r.sendDate}</div>
            </div>
          </div>
          <div class="column" style="text-align: left; color: #00aa00;">Success</div>`;
        });
        html += "</div>";
      }
      if (this.errorCancelReponses.length > 0) {
        html += `<h3 class="ui red header">Error ${this.errorCancelReponses.length}</h3>`;
        html += `<div class="ui two column grid">`;
        this.errorCancelReponses.forEach((r) => {
          html += `<div class="column">
            <div class="ui two column grid">
              <div class="column">${r.orderId}</div>
              <div class="column">${r.sendDate}</div>
            </div>
          </div>
          <div class="column" style="text-align: left; color: #d91e18">${r.resultCode}: ${r.resultMessage}</div>`;
        });
        html += "</div>";
      }
      this.$Swal.fire({
        icon: type,
        html: html,
      });
    },
    etsOrderCancelNtf(data) {
      if (this.securityInfo.symbol !== data.symbol) {
        return;
      }
      this.reloadData();
    },
    dtsOrderCancelNtf(data) {
      if (this.securityInfo.symbol !== data.symbol) {
        return;
      }
      this.reloadData();
    },
    etsOrderInsertNtf(data) {
      if (this.securityInfo.symbol !== data.symbol) {
        return;
      }
      this.reloadData();
    },
    checkOrderIncludes(data) {
      if (!this.rowCustByStock) {
        return;
      }
      const buy = this.rowCustByStock.filter((row) => {
        return row.buyOrders.orderId === data.orderId;
      });

      const sell = this.rowCustByStock.filter((row) => {
        return row.sellOrders.orderId === data.orderId;
      });

      return buy.length + sell.length;
    },
    etsOrderQuoteNtf(data) {
      if (!this.checkOrderIncludes(data)) {
        return;
      }
      this.reloadData();
    },
    etsOrderMatchNtf(data) {
      if (!this.checkOrderIncludes(data)) {
        return;
      }
      this.reloadData();
    },
    etsOrderUpdateNtf(data) {
      if (!this.checkOrderIncludes(data)) {
        return;
      }
      this.reloadData();
    },
    etsOrderRejectNtf(data) {
      if (!this.checkOrderIncludes(data)) {
        return;
      }
      this.reloadData();
    },
    dtsOrderInsertNtf(data) {
      if (this.securityInfo.symbol !== data.symbol) {
        return;
      }
      this.reloadData();
    },
    dtsOrderQuoteNtf(data) {
      if (!this.checkOrderIncludes(data)) {
        return;
      }
      this.reloadData();
    },
    dtsOrderMatchNtf(data) {
      if (!this.checkOrderIncludes(data)) {
        return;
      }
      this.reloadData();
    },
    dtsOrderUpdateNtf(data) {
      if (!this.checkOrderIncludes(data)) {
        return;
      }
      this.reloadData();
    },
    dtsOrderRejectNtf(data) {
      if (!this.checkOrderIncludes(data)) {
        return;
      }
      this.reloadData();
    },
    async onOrderCancel(orders) {
      if (this.securityInfo.exchangeId === 1) {
        const selectOrders = await this.$refs.cancelModal.selectOrder("SET", this.pin, orders);

        this.orderCancelRefId = [];
        this.errorCancelReponses = [];
        this.successCancelReponses = [];
        selectOrders.forEach((order) => {
          const refId = this.sendSETCancelOrder(order);
          this.orderCancelRefId.push(refId);
        });
      } else if (this.securityInfo.exchangeId === 2) {
        const selectOrders = await this.$refs.cancelModal.selectOrder("TFEX", this.pin, orders);

        this.orderCancelRefId = [];
        this.errorCancelReponses = [];
        this.successCancelReponses = [];
        selectOrders.forEach((order) => {
          const refId = this.sendTFEXCancelOrder(order);
          this.orderCancelRefId.push(refId);
        });
      }
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentAccount: get("global/currentAccount"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
    userType: get("model/systemRefData@userType"),
  },
  created() {
    this.gridCustByStockOptions = {};
    this.onCustByStockStartRefresh();

    this.$EventBus.$on("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$on("ctinf/SS51", this.securityInfoUpdateCinf);
    this.$EventBus.$on("bcst/PB21", this.updateBBO);
    this.$EventBus.$on("bcst/PB22", this.updateBBO);
    this.$EventBus.$on("bcst/PB52", this.updateBBO);

    // order notify
    this.$EventBus.$on("ntf/ON01", this.etsOrderInsertNtf);
    this.$EventBus.$on("ntf/ON02", this.etsOrderQuoteNtf);
    this.$EventBus.$on("ntf/ON03", this.etsOrderMatchNtf);
    this.$EventBus.$on("ntf/ON04", this.etsOrderCancelNtf);
    this.$EventBus.$on("ntf/ON05", this.etsOrderUpdateNtf);
    this.$EventBus.$on("ntf/ON06", this.etsOrderRejectNtf);

    this.$EventBus.$on("ntf/ON51", this.dtsOrderInsertNtf);
    this.$EventBus.$on("ntf/ON52", this.dtsOrderQuoteNtf);
    this.$EventBus.$on("ntf/ON53", this.dtsOrderMatchNtf);
    this.$EventBus.$on("ntf/ON54", this.dtsOrderCancelNtf);
    this.$EventBus.$on("ntf/ON55", this.dtsOrderUpdateNtf);
    this.$EventBus.$on("ntf/ON56", this.dtsOrderRejectNtf);

    // order query
    this.$EventBus.$on("ctinf/OS04", this.etsOrderQueryCtinf);
    this.$EventBus.$on("ctinf/OS54", this.dtsOrderQueryCtinf);

    // order insert
    this.$EventBus.$on("ctord/OS01", this.etsOrderInsertCtord);
    this.$EventBus.$on("ctord/OS51", this.dtsOrderInsertCtord);

    // order changed
    this.$EventBus.$on("ctord/OS02", this.etsOrderChangedCtord);
    this.$EventBus.$on("ctord/OS52", this.dtsOrderChangedCtord);

    //order cancel
    this.$EventBus.$on("ctord/OS01", this.etsOrderCancelCtord);
    this.$EventBus.$on("ctord/OS03", this.etsOrderCancelSuccessCtord);
    this.$EventBus.$on("ctord/OS53", this.dtsOrderCancelCtord);

    this.$EventBus.$on("custByStockOrderDBClicked", this.onOrderInsert);
    this.$EventBus.$on("custByStockChangeOrder", this.onOrderDroped);
    this.$EventBus.$on("custByStockCancelOrderClicked", this.onOrderCancel);

    this.$EventBus.$on("onCustByStockStartRefresh", this.onCustByStockStartRefresh);
    this.init();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$off("ctinf/SS51", this.securityInfoUpdateCinf);
    this.$EventBus.$off("bcst/PB21", this.updateBBO);
    this.$EventBus.$off("bcst/PB52", this.updateBBO);
    this.$EventBus.$off("bcst/PB22", this.updateBBO);

    // order notify
    this.$EventBus.$off("ntf/ON01", this.etsOrderInsertNtf);
    this.$EventBus.$off("ntf/ON02", this.etsOrderQuoteNtf);
    this.$EventBus.$off("ntf/ON03", this.etsOrderMatchNtf);
    this.$EventBus.$off("ntf/ON04", this.etsOrderCancelNtf);
    this.$EventBus.$off("ntf/ON05", this.etsOrderUpdateNtf);
    this.$EventBus.$off("ntf/ON06", this.etsOrderRejectNtf);

    this.$EventBus.$off("ntf/ON51", this.dtsOrderInsertNtf);
    this.$EventBus.$off("ntf/ON52", this.dtsOrderQuoteNtf);
    this.$EventBus.$off("ntf/ON53", this.dtsOrderMatchNtf);
    this.$EventBus.$off("ntf/ON54", this.dtsOrderCancelNtf);
    this.$EventBus.$off("ntf/ON55", this.dtsOrderUpdateNtf);
    this.$EventBus.$off("ntf/ON56", this.dtsOrderRejectNtf);

    // order query
    this.$EventBus.$off("ctinf/OS04", this.etsOrderQueryCtinf);
    this.$EventBus.$off("ctinf/OS54", this.dtsOrderQueryCtinf);

    // order insert
    this.$EventBus.$off("ctord/OS01", this.etsOrderInsertCtord);
    this.$EventBus.$off("ctord/OS51", this.dtsOrderInsertCtord);

    // order changed
    this.$EventBus.$off("ctord/OS02", this.etsOrderChangedCtord);
    this.$EventBus.$off("ctord/OS52", this.dtsOrderChangedCtord);

    //order cancel
    this.$EventBus.$off("ctord/OS01", this.etsOrderCancelCtord);
    this.$EventBus.$off("ctord/OS03", this.etsOrderCancelSuccessCtord);
    this.$EventBus.$off("ctord/OS53", this.dtsOrderCancelCtord);

    this.$EventBus.$off("custByStockOrderDBClicked", this.onOrderInsert);
    this.$EventBus.$off("custByStockChangeOrder", this.onOrderDroped);
    this.$EventBus.$off("custByStockCancelOrderClicked", this.onOrderCancel);

    this.$EventBus.$off("onCustByStockStartRefresh", this.onCustByStockStartRefresh);
  },
};
</script>

<style scoped lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.cust-by-stock-table {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .ui.form {
    height: 100%;

    .fields {
      height: 100%;
      margin: 0;
      align-items: center;

      > div {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }

  .custbystock {
    width: 100%;
    height: 100%;
    padding: 0px;
  }

  .footer {
    background-color: #1b2025;

    .grid {
      height: 100%;
      justify-content: space-between;
      padding: 0.2rem !important;

      .column {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.custbystock .ag-header-cell-label {
  justify-content: center;
}

.el-input.el-input--medium .el-input__inner {
  color: #dcddde !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: 700 !important;
  background: #2f383f !important;
}

.el-input-number__decrease,
.el-input-number__increase {
  color: #dcddde !important;
  background: #2f383f !important;
  border: 0px !important;
}
</style>
