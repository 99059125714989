const values = {
  B: "Sub-Broker",
  D: "Dealer",
  F: "DMA",
  H: "In House",
  I: "Internet",
  R: "Romote Investor (ITW)",
  S: "User Defined",
  T: "Tele Broker",
  X: "Default",
  Z: "Minimum Charge"
};

const getCommissionServiceTypeByKey = key => {
  return values[key] || " ";
};

export { getCommissionServiceTypeByKey };
