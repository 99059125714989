<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    filterable: {
      type: String,
      default: "startsWith"
    }
  },
  data() {
    return {
      style: "width: 7rem; min-width: 7rem;",
      defaultValue: "",
      displayValue: "",
      options: [
        {
          text: "Normal",
          value: ""
        },
        {
          text: "Collateral",
          value: "C"
        },
        {
          text: "Deposit",
          value: "D"
        }
      ]
    };
  }
};
</script>
