import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle
} from "../Formatter.js";
import numeral from "numeral";

const columnDefsETSHaveCallForce = [
  {
    headerName: "Account",
    field: "custId",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 100,
    cellStyle: params => ({ ...totalColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Balance EE",
    field: "buyLmt",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Equity",
    field: "equity",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "MR",
    field: "curMR",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Mark. EE",
    field: "markEE",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Force Sell",
    field: "curForce",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: () => ({ ...sideColorStyle({ value: "S" }), "justify-content": "flex-end" })
  },
  {
    headerName: "Call Margin",
    field: "curCall",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: () => ({ ...sideColorStyle({ value: "S" }), "justify-content": "flex-end" })
  },
  {
    headerName: "Force Sell",
    field: "prvForce",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: () => ({ ...sideColorStyle({ value: "S" }), "justify-content": "flex-end" }),
    hide: true
  },
  {
    headerName: "Call Margin",
    field: "prvCall",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: () => ({ ...sideColorStyle({ value: "S" }), "justify-content": "flex-end" }),
    hide: true
  },
  {
    headerName: "MM%",
    field: "MMPct",
    width: 200,
    valueFormatter: params => numeralCellFormatter(params, "0,0.000000"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Telephone",
    field: "mobile",
    width: 150,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  }
];

const columnDefsDTSHaveCallForce = [
  {
    headerName: "Account",
    field: "custId",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 100,
    cellStyle: params => ({ ...totalColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Cash Balance",
    field: "cashBal",
    width: 180,
    valueFormatter: params => (numeral(params.value).value() ? unrealizedCellFormatter(params.value, "+0,0.00") : "0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Unrealized P&L",
    field: "unrealPl",
    width: 180,
    valueFormatter: params => (numeral(params.value).value() ? unrealizedCellFormatter(params.value, "+0,0.00") : "0.00"),
    cellStyle: params => {
      if (numeral(params.value).value() === 0) {
        return totalColorStyle();
      } else {
        return numeral(params.value).value() > 0
          ? { ...sideColorStyle({ value: "B" }), "justify-content": "flex-end" }
          : { ...sideColorStyle({ value: "S" }), "justify-content": "flex-end" };
      }
    },
    hide: true
  },
  {
    headerName: "Floating P&L",
    field: "floatPl",
    width: 180,
    valueFormatter: params => (numeral(params.value).value() ? unrealizedCellFormatter(params.value, "+0,0.00") : "0.00"),
    cellStyle: params => {
      if (numeral(params.value).value() === 0) {
        return totalColorStyle();
      } else {
        return numeral(params.value).value() > 0
          ? { ...sideColorStyle({ value: "B" }), "justify-content": "flex-end" }
          : { ...sideColorStyle({ value: "S" }), "justify-content": "flex-end" };
      }
    },
    hide: true
  },
  {
    headerName: "EE Balance",
    field: "EE",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Margin Balance",
    field: "MB",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Unrealized Margin Balance",
    field: "unrealMB",
    width: 250,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Equity Balance",
    field: "equityBal",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Total MM",
    field: "MMVal",
    width: 200,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle,
    hide: true
  },
  {
    headerName: "Total Force Close",
    field: "force",
    width: 200,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle,
    hide: true
  },
  {
    headerName: "Amount",
    field: "forceCallValue",
    width: 180,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Trader ID",
    field: "ownTid",
    width: 100,
    cellStyle: () => ({ ...totalColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Trader Name",
    field: "ownTidName",
    width: 200,
    cellStyle: () => ({ ...qtyColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Customer Name",
    field: "custName",
    width: 200,
    cellStyle: () => ({ ...qtyColorStyle(), "justify-content": "flex-start" })
  }
  // {
  //   headerName: "Telephone",
  //   field: "mobile",
  //   width: 150,
  //   cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  // }
];

export { columnDefsETSHaveCallForce, columnDefsDTSHaveCallForce };
