var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ht-main-layout"},[(
      this.socketStatus &&
      this.ctordState === 'OPEN' &&
      this.ctinfState === 'OPEN' &&
      this.bcstState === 'OPEN' &&
      this.ntfState === 'OPEN' &&
      _vm.ready
    )?_c('div',{staticClass:"ui grid main-layout-container"},[_c('div',{staticClass:"row header-section"},[_c('div',{staticClass:"sixteen wide column nav-bar-container"},[_c('Navbar')],1),_c('div',{staticClass:"sixteen wide column index-bar-container"},[_c('IndexBar')],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"sixteen wide column content-section"},[_c('router-view')],1)]),_c('div',{staticClass:"two column row order-entry-section"},[_c('div',{staticClass:"order-entry-container",class:{
          'extend-mode': _vm.modeOrder,
          'sixteen wide computer twelve wide large screen column twelve wide widescreen monitor column': !_vm.modeOrder,
        }},[_c('OrderEntry')],1),(!_vm.modeOrder)?_c('div',{staticClass:"four wide column large screen only"},[_c('MarketMessage')],1):_vm._e()]),_c('div',{staticClass:"row status-bar-section"},[_c('div',{staticClass:"status-bar-container"},[_c('StatusBar')],1)]),_c('DropdownItems')],1):_c('div',[_c('sui-dimmer',{attrs:{"active":""}},[_c('sui-loader',{attrs:{"inverted":""}},[_vm._v("Loading...")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }