<template>
  <div class="market-stock-graph" ref="market-stock-graph">
    <sui-tab :menu="{ attached: 'top' }" class="tab-container">
      <sui-tab-pane title="Intraday" attached="top" class="custom-tab">
        <MarketStockGraphIntraday />
      </sui-tab-pane>
      <sui-tab-pane title="Buy/Sell" attached="top" class="custom-tab">
        <MarketStockGraphBuySell />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";
import MarketStockGraphIntraday from "@/components/market/MarketStockGraphIntraday";
import MarketStockGraphBuySell from "@/components/market/MarketStockGraphBuySell";

export default {
  name: "MarketStockGraph",
  components: {
    MarketStockGraphIntraday,
    MarketStockGraphBuySell,
  },
  data: () => ({
    isLoading: false,
    resizeObserver: null,
    height: 170,
  }),
  watch: {},
  created() {
    this.$EventBus.$on("onMarketGraphIntradayStartRefresh", this.onMarketGraphIntradayStartRefresh);
    this.$EventBus.$on("onMarketGraphBuySellStartRefresh", this.onMarketGraphBuySellStartRefresh);

    this.$EventBus.$on("onMarketGraphIntradayStopRefresh", this.onMarketGraphIntradayStopRefresh);
    this.$EventBus.$on("onMarketGraphBuySellStopRefresh", this.onMarketGraphBuySellStopRefresh);
    this.$EventBus.$on("market-bbo-size-change", this.onSizeChange);
  },

  mounted() {
    this.onSizeChange();
  },

  beforeDestroy() {
    this.$EventBus.$off("onMarketGraphIntradayStartRefresh", this.onMarketGraphIntradayStartRefresh);
    this.$EventBus.$off("onMarketGraphBuySellStartRefresh", this.onMarketGraphBuySellStartRefresh);

    this.$EventBus.$off("onMarketGraphIntradayStopRefresh", this.onMarketGraphIntradayStopRefresh);
    this.$EventBus.$off("onMarketGraphBuySellStopRefresh", this.onMarketGraphBuySellStopRefresh);
    this.$EventBus.$off("market-bbo-size-change", this.onSizeChange);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onMarketGraphIntradayStartRefresh");
      this.$EventBus.$emit("onMarketGraphBuySellStartRefresh");
    },
    onMarketGraphIntradayStartRefresh() {
      this.isLoading = true;
    },
    onMarketGraphBuySellStartRefresh() {
      this.isLoading = true;
    },
    onMarketGraphIntradayStopRefresh() {
      this.isLoading = false;
    },
    onMarketGraphBuySellStopRefresh() {
      this.isLoading = false;
    },
    onSizeChange() {
      // reset to default height first
      this.height = 170;

      this.$nextTick(() => {
        const marketStockGraph = this.$refs["market-stock-graph"];
        this.height = marketStockGraph.clientHeight;
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
};
</script>

<style scoped lang="scss">
.market-stock-graph {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .tab-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;

    .custom-tab {
      flex: 1;
      min-height: 0;
    }

    &:deep(.ui.attached.menu.top) {
      height: auto !important;

      a.item {
        height: auto !important;
        padding: 0.2rem 0.6rem !important;
      }
    }
  }

  .market-stock-graph > div {
    height: 100%;
  }

  // .market-stock-graph .custom-tab {
  //   height: calc(100% - 32px) !important;
  // }

  .apexcharts-canvas.dark {
    background: #262d33 !important;
  }

  .item.active {
    background-color: #262d33 !important;
  }

  .ui.attached.menu.top {
    height: 32px !important;
    border: 0px !important;
    background-color: #1b2025 !important;
    min-height: unset !important;
  }

  a.item {
    /* height: 32px !important; */
    /* padding: 0px 1rem !important; */
    font-weight: 800 !important;
    color: #dcddde !important;
  }

  a.item > span {
    padding: 0 !important;
  }

  a.item.active > span {
    color: c88841 !important;
    border-radius: 0.5rem !important;
    padding: 0.25rem !important;
    border: 1px solid c88841 !important;
  }

  .ui.tab.segment.attached.active.top {
    background-color: #262d33 !important;
    border: 0px !important;
    padding: 0px !important;
  }
}
</style>
