/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class OR02 extends WsReqMsg {
	constructor() {
		super(0);
		this._orderId = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._icebergVol = new StringMessageBase();
		this._ttf = new StringMessageBase();
		this._pinCode = new StringMessageBase();
		this._validity = new StringMessageBase();
		this._validityData = new StringMessageBase();
	}
	
	get orderId() {
		return this._orderId;
	}
	
	set orderId(orderId) {
		this._orderId = new StringMessageBase(orderId);
	}
	
	get volume() {
		return this._volume;
	}
	
	set volume(volume) {
		this._volume = new StringMessageBase(volume);
	}
	
	get price() {
		return this._price;
	}
	
	set price(price) {
		this._price = new StringMessageBase(price);
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get icebergVol() {
		return this._icebergVol;
	}
	
	set icebergVol(icebergVol) {
		this._icebergVol = new StringMessageBase(icebergVol);
	}
	
	get ttf() {
		return this._ttf;
	}
	
	set ttf(ttf) {
		this._ttf = new StringMessageBase(ttf);
	}
	
	get pinCode() {
		return this._pinCode;
	}
	
	set pinCode(pinCode) {
		this._pinCode = new StringMessageBase(pinCode);
	}
	
	get validity() {
		return this._validity;
	}
	
	set validity(validity) {
		this._validity = new StringMessageBase(validity);
	}
	
	get validityData() {
		return this._validityData;
	}
	
	set validityData(validityData) {
		this._validityData = new StringMessageBase(validityData);
	}
}

export default OR02;
