<template>
  <div class="nav-bar">
    <div class="ui tiny fixed menu borderless inverted" :class="{ inverted: theme === 'Dark' }" style="height: 3rem; line-height: 3rem; border: 0px">
      <div class="ui fluid container ht-navbar-container nav-bar-content-container">
        <div class="ui tiny header item logo-container" style="border: 0px; padding: 0px">
          <div class="ht-logo-navbar hitrade-x-logo" ref="hitradeXLogo" />
          <img
            :src="logo_path"
            class="broker-logo"
            v-if="showBrokerLogo"
            style="margin-left: 0.5rem"
            alt="broker-logo"
            @error="replaceByDefault"
            ref="brokerLogo"
          />
        </div>
        <div class="menu-list-container">
          <div ref="nav-menu-list" class="menu-list" v-if="!brokerMenu">
            <div class="menu-item" v-for="menu in menuList" :key="menu.title">
              <router-link active-class="active" class="item" :to="menu.route" exact @click.native="doActionRouter">
                <h5>{{ menu.title }}</h5>
              </router-link>
            </div>
          </div>
          <div class="broker-menu-list" v-else>
            <router-link class="item" :to="index === 0 ? '/brokerpage' : '#'" exact v-for="(link, index) in brokerLinks" :key="link.name">
              <h5>{{ link.name }}</h5>
            </router-link>
          </div>
          <div class="menu-item more-button" v-if="!brokerMenu">
            <a
              class="item"
              ref="nav-menu-more-button"
              :class="{
                active: ![
                  '/market',
                  '/portfolio',
                  '/quote',
                  '/custbystock',
                  '/bbos',
                  '/ticker',
                  '/technical',
                  '/news',
                  '/index',
                  '/brokerpage',
                ].includes($route.path),
              }"
              @click="openSidebar"
            >
              <h5>{{ "More" }}</h5>
            </a>
          </div>
        </div>

        <div class="right menu" style="padding-right: 0.5rem">
          <div class="ui simple dropdown item">
            <i class="user icon"></i>
            <span style="color: #dcddde; font-size: 1rem; font-weight: 700">{{ userType && userTypeParser(userType) }}&nbsp;:&nbsp;</span>
            <span style="color: #e4af0a; font-size: 1rem; font-weight: 700">{{ userFullName && userFullNameParser(userFullName) }}</span>
            <span style="color: #dcddde; font-size: 1rem; font-weight: 700">&nbsp;({{ username }})</span>
            <i class="dropdown icon"></i>
            <div class="menu">
              <a class="item" @click="onSettingsClick('settings')"> <i class="settings icon"></i>Settings</a>
              <a class="item" @click="onSettingsClick('about')"> <i class="info circle icon"></i>About</a>
              <div class="divider"></div>
              <a class="item" @click="onLogoutClicked"> <i class="sign out alternate icon"></i>Logout</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SettingModal />
    <ShortcutModal />
    <AboutModal />
    <Sidebar />
  </div>
</template>

<script>
import _api from "@/api/authentication";

import { get, sync } from "vuex-pathify";

import router from "@/router";
import store from "@/store";

import SettingModal from "@/components/modal/SettingsModal";
import ShortcutModal from "@/components/modal/ShortcutModal";
import AboutModal from "@/components/modal/AboutModal";
import Sidebar from "@/components/layout/Sidebar";

import { getUserTypeByKey } from "@/utils/UserTypeFormatter";

let BROKER_LOGO_PATH = process.env.VUE_APP_BROKER_LOGO_PATH;

if (process.env.NODE_ENV === "production") {
  BROKER_LOGO_PATH = window.location.origin + process.env.VUE_APP_BROKER_LOGO_PATH;
}

export default {
  components: {
    SettingModal,
    ShortcutModal,
    AboutModal,
    Sidebar,
  },
  data() {
    return {
      currentMenu: null,
      brokerMenu: false,

      brokerName: process.env.VUE_APP_BROKER_NAME,
      logo_path: BROKER_LOGO_PATH,
      // TODO fetch links from egw
      brokerLinks: [
        {
          name: "ASL ONNET",
          url: "https://www.aslsecurities.com/Default.aspx",
        },
        {
          name: "ASL B-News",
          url: "https://www.aslsecurities.com/Default.aspx",
        },
        {
          name: "ASL Bis-Chart",
          url: "https://www.aslsecurities.com/Default.aspx",
        },
      ],
      showBrokerLogo: true,
      menuList: [
        { title: "Market", route: "/market" },
        { title: this.userType === "T" ? "Account" : "Portfolio", route: "/portfolio" },
        { title: "Quote", route: "/quote" },
        { title: "Cust. By Stock", route: "/custbystock" },
        { title: "BBOs", route: "/bbos" },
        { title: "Ticker", route: "/ticker" },
        { title: "Technical", route: "/technical" },
        { title: "News", route: "/news" },
        { title: "Index", route: "/index" },
      ],
      fadeInterval: null,
    };
  },
  watch: {
    $route(to, from) {
      this.currentMenu = to.name;

      this.$nextTick(() => {
        this.onResize();
      });
    },
    userType() {
      this.initNavMenu();
    },
    showBrokerLogo(isShowing) {
      if (!isShowing && this.fadeInterval) clearInterval(this.fadeInterval);
    },
  },
  created() {
    this.initNavMenu();
    this.$EventBus.$on("receiveShortcut", this.onReceiveShortcut);
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    this.currentMenu = this.$route.name;
    this.onResize();

    if (this.showBrokerLogo) {
      this.fadeInterval = setInterval(() => {
        const hitradeXLogo = this.$refs["hitradeXLogo"];
        const brokerLogo = this.$refs["brokerLogo"];

        if (hitradeXLogo.classList.contains("fade-out")) {
          hitradeXLogo.classList.remove("fade-out");
          hitradeXLogo.classList.add("fade-in");
          brokerLogo.classList.remove("fade-in");
          brokerLogo.classList.add("fade-out");
        } else if (hitradeXLogo.classList.contains("fade-in")) {
          hitradeXLogo.classList.remove("fade-in");
          hitradeXLogo.classList.add("fade-out");
          brokerLogo.classList.remove("fade-out");
          brokerLogo.classList.add("fade-in");
        } else {
          hitradeXLogo.classList.add("fade-out");
          brokerLogo.classList.add("fade-in");
        }
      }, 5000);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.$EventBus.$off("receiveShortcut", this.onReceiveShortcut);

    if (this.fadeInterval) clearInterval(this.fadeInterval);
  },
  methods: {
    initNavMenu() {
      switch (this.userType) {
        case "T":
          this.menuList = [
            { title: "Market", route: "/market" },
            { title: "Account", route: "/portfolio" },
            { title: "Quote", route: "/quote" },
            { title: "Cust. By Stock", route: "/custbystock" },
            { title: "BBOs", route: "/bbos" },
            { title: "Ticker", route: "/ticker" },
            { title: "Technical", route: "/technical" },
            { title: "News", route: "/news" },
            { title: "Index", route: "/index" },
          ];
          return;
        default:
          this.menuList = [
            { title: "Market", route: "/market" },
            { title: "Portfolio", route: "/portfolio" },
            { title: "Quote", route: "/quote" },
            { title: "Cust. By Stock", route: "/custbystock" },
            { title: "BBOs", route: "/bbos" },
            { title: "Ticker", route: "/ticker" },
            { title: "Technical", route: "/technical" },
            { title: "News", route: "/news" },
            { title: "Index", route: "/index" },
          ];
          return;
      }
    },
    replaceByDefault() {
      this.showBrokerLogo = false;
    },
    doActionRouter() {
      this.modeOrder = false;
    },
    onChangeMenu(e) {
      let menu = e.currentTarget.getAttribute("data-menu");
      this.currentMenu = menu;
      store.dispatch("window/changeMenu", menu);
      router.push({ path: menu });
    },
    userTypeParser(userType) {
      switch (userType) {
        case "M":
        case "U":
        case "T":
        case "O":
        case "I":
          return getUserTypeByKey(userType);
        default:
          return "N/A";
      }
    },
    userFullNameParser(userFullName) {
      const fullNameArray = userFullName.split(" ");
      const fullNameUpperFirst = fullNameArray.map((name) => this.$_.upperFirst(name.toLowerCase()));

      return fullNameUpperFirst.join(" ");
    },
    onSettingsClick(tabName) {
      this.$EventBus.$emit(tabName);
    },
    onReceiveShortcut(funcName) {
      console.log("shortcut >> " + funcName);
      if (funcName === "View Market" && this.$route.path !== "/market") {
        this.$router.push("/market");
      } else if (
        (funcName === "View Portfolio" || funcName === "View Position" || funcName === "View Commission") &&
        this.$route.path !== "/portfolio"
      ) {
        this.$router.push({ name: "portfolio", params: { funcName: funcName } });
      } else if (funcName === "View Quote" && this.$route.path !== "/quote") {
        this.$router.push("/quote");
      } else if (funcName === "Cust. By Stock" && this.$route.path !== "/custbystock") {
        this.$router.push("/custbystock");
      } else if (funcName === "View BBOs" && this.$route.path !== "/bbos") {
        this.$router.push("/bbos");
      } else if (funcName === "View Ticker" && this.$route.path !== "/ticker") {
        this.$router.push("/ticker");
      } else if (funcName === "View Technical" && this.$route.path !== "/technical") {
        this.$router.push("/technical");
      } else if (funcName === "View Index" && this.$route.path !== "/index") {
        this.$router.push("/index");
      } else if (funcName === "View News" && this.$route.path !== "/news") {
        this.$router.push("/news");
      } else if (funcName === "View Customers" && this.$route.path !== "/customers") {
        if (this.userType === "T") {
          this.$router.push("/customers");
        }
      } else if (funcName === "Toggle More Menu") {
        this.toggleSidebar();
      } else if (funcName === "View Information" && this.$route.path !== "/information") {
        if (this.userType === "T") {
          this.$router.push("/information");
        }
      } else if (funcName === "View Value" && this.$route.path !== "/value") {
        if (this.userType === "T") {
          this.$router.push("/value");
        }
      } else if (funcName === "View Broker Ranking" && this.$route.path !== "/brokerranking") {
        if (this.userType === "T") {
          this.$router.push("/brokerranking");
        }
      }
    },
    openSidebar() {
      this.$EventBus.$emit("openSidebar");
    },
    toggleSidebar() {
      this.$EventBus.$emit("toggleSidebar");
    },
    toggleBrokerMenu() {
      this.brokerMenu = !this.brokerMenu;
    },
    onLogoutClicked() {
      _api
        .logout()
        .then((response) => {
          this.$router.replace("/login");
        })
        .catch((error) => error);
    },
    onResize() {
      const navMenuList = this.$refs["nav-menu-list"];
      const moreButton = this.$refs["nav-menu-more-button"];

      if (navMenuList && moreButton) {
        const activeNavMenu = navMenuList.querySelector(".active");

        if (activeNavMenu) {
          const activeNavMenuRect = activeNavMenu.getBoundingClientRect();
          const navMenuListRect = navMenuList.getBoundingClientRect();

          if (activeNavMenuRect.top >= navMenuListRect.bottom) {
            return moreButton.classList.add("active");
          }

          return moreButton.classList.remove("active");
        }
      }
    },
  },
  computed: {
    theme: sync("global/theme"),
    modeOrder: sync("global/modeOrder"),
    userType: get("model/systemRefData@userType"),
    username: get("model/systemRefData@username"),
    userFullName: get("model/systemRefData@userFullName"),
  },
};
</script>

<style lang="scss" scoped>
.fade-transition {
  transition: all 0.3s linear;

  &.fade-out {
    opacity: 0;
  }

  &.fade-in {
    opacity: 1;
  }
}

.nav-bar {
  .logo-container {
    width: 8rem;
    height: 100%;
    margin: 0;
    position: relative;
    margin-left: 0.4rem !important;

    .hitrade-x-logo {
      @extend.fade-transition;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      opacity: 1;
      background-color: #2f383f !important;
    }

    .broker-logo {
      @extend.fade-transition;
      margin: 0 !important;
      position: absolute;
      width: 100% !important;
      height: 100%;
      top: 0;
      object-fit: contain;
      object-position: center;
      animation: fade 10s ease 10s infinite alternate;
      z-index: 1;
      background-color: #2f383f !important;
    }
  }

  .nav-bar-content-container {
    column-gap: 0.5rem;
  }

  .menu-list-container {
    display: flex;
    flex: 1;
    overflow: hidden;
    column-gap: 1rem;

    .menu-list {
      display: flex;
      width: fit-content;
      flex-wrap: wrap;
      column-gap: 1rem;
      overflow: hidden;

      @media screen and (max-width: 1200px) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        grid-template-rows: 100%;
        width: 100%;
        gap: 0;
      }
    }

    .broker-menu-list {
      display: flex;
      column-gap: 1rem;
    }

    .menu-item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1200px) {
        &.more-button {
          width: 90px;
        }
      }

      .item {
        margin: 0 !important;
        width: fit-content;
        padding: 0 0.6rem !important;

        h5 {
          white-space: nowrap;
          margin: auto;
        }
      }
    }
  }
}
.ht-logo-navbar {
  position: relative;
  background: url("../../assets/images/logo.svg") no-repeat center;
  background-size: contain;
  width: 8rem;
  min-width: 5em;
  height: 3rem;
}

.ht-navbar-container {
  background-color: #2f383f;
}

.ui.inverted.menu a.item {
  margin: 0.95vh;
  border-radius: 0.5rem !important;
}

.ui.inverted.menu a.item.active {
  background: #c88841 !important;
}

.ui.inverted.menu a.item:not(.active):hover {
  background: #c88841cc !important;
}

a.item {
  height: 2rem !important;
}

/* .item {
  margin: 0.75vh !important;
} */

.ui.simple.dropdown.item.company.exact {
  background: #c88841 !important;
}

.ui.simple.dropdown.item.company.exact > span {
  color: #fff !important;
}

a.item.drop.active > h5 {
  color: #c88841 !important;
}
</style>
