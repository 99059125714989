<template>
  <div class="buy-and-sell-percent" ref="buySellPercent">
    <div class="symbol-dropdown-section">
      <div class="symbol-dropdown-container">
        <SymbolDropdown image :market="market" notification v-model="selectedSymbol" class="custom-symbol-dropdown" />
      </div>
      <div class="quote-last-sale-container">
        <QuoteLastsale :current-quote-selected-symbol="selectedSymbol" />
      </div>
    </div>
    <div class="chart-section">
      <div class="header-column-section">
        <div></div>
        <div class="header">{{ "Buy vol" }}</div>
        <div class="header">{{ "Sell vol" }}</div>
      </div>
      <div class="symbol-section">
        <div class="chart-container" v-for="(chart, index) in chartList" :key="`buy-sell-chart-${index}`">
          <div class="header-column-section">
            <div class="chart-title">{{ chart.title || "-" }}</div>
            <div class="value">{{ chart.buyVol }}</div>
            <div class="value">{{ chart.sellVol }}</div>
          </div>
          <apexchart type="bar" :options="options" :series="chart.series" :height="80" :width="chartWidth" class="custom-chart" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SymbolDropdown from "@/components/SymbolDropdown";
import QuoteLastsale from "./QuoteLastsale.vue";

const chartOptions = {
  theme: {
    mode: "dark"
  },
  grid: {
    show: false
  },
  chart: {
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false
    },
    parentHeightOffset: -60
  },
  plotOptions: {
    bar: {
      horizontal: true
    }
  },
  xaxis: {
    labels: {
      show: false
    },
    axisBorder: {
      show: false
    },
    axisTicks: { show: false }
  },
  yaxis: {
    show: false
  },
  stoke: {
    show: false
  },
  legend: {
    show: false
  },
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0, 2]
  },
  tooltip: {
    enabled: false
  },
  colors: ["#379237", "#B2B2B2", "#FF1E00"]
};

export default {
  components: { SymbolDropdown, QuoteLastsale },
  props: {
    market: {
      type: String,
      default: "ALL"
    }
  },
  data: () => ({
    selectedSymbol: null,
    options: chartOptions,
    chartList: [],
    resizeObserver: null,
    chartWidth: 100
  }),
  watch: {
    selectedSymbol(selectedSymbol) {
      if (selectedSymbol) {
        return this.SR01(selectedSymbol);
      }

      this.chartList = [];
      this.$EventBus.$emit("onQuoteInfoStopRefresh");
    }
  },
  created() {
    this.$EventBus.$on("ctinf/SS01", this.setChartInformation);
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS01", this.setChartInformation);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    SR01(selectedSymbol) {
      if (!selectedSymbol) {
        this.$EventBus.$emit("onQuoteInfoStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("SR01");

      msg.securityId.set(selectedSymbol.value.securityId);
      msg.lastTradeCount.set(5);
      msg.full.set("Y");
      msg.exchangeId.set(selectedSymbol.exchangeId);

      this.$ws.send(msg);
    },
    setChartInformation(updatedSymbolInfo) {
      const temptChartList = [];
      const symbolBuyVol = this.$numeral(updatedSymbolInfo.buyVolume).value() || 0;
      const symbolSellVol = this.$numeral(updatedSymbolInfo.sellVolume).value() || 0;
      const symbolTotalVol = this.$numeral(updatedSymbolInfo.volume).value() || 0;
      const symbolBuyVolPercent = Math.round((symbolBuyVol / symbolTotalVol) * 100) || 0;
      const symbolSellVolPercent = Math.round((symbolSellVol / symbolTotalVol) * 100) || 0;
      const SymbolTotalVolPercent = 100 - symbolBuyVolPercent - symbolSellVolPercent;
      const sectorBuyVol = this.$numeral(updatedSymbolInfo.sectorBuyVol).value() || 0;
      const sectorSellVol = this.$numeral(updatedSymbolInfo.sectorSellVol).value() || 0;
      const sectorTotalVol = sectorBuyVol + sectorSellVol;
      const sectorBuyVolPercent = Math.round((sectorBuyVol / sectorTotalVol) * 100) || 0;
      const sectorSellVolPercent = Math.round((sectorSellVol / sectorTotalVol) * 100) || 0;
      const sectorToltalVolPercent = 100 - sectorBuyVolPercent - sectorSellVolPercent;
      const marketBuyVol = this.$numeral(updatedSymbolInfo.marketBuyVol).value() || 0;
      const marketSellVol = this.$numeral(updatedSymbolInfo.marketSellVol).value() || 0;
      const marketTotalVol = this.$numeral(updatedSymbolInfo.marketVol).value() || 0;
      const marketBuyVolPercent = Math.round((marketBuyVol / marketTotalVol) * 100) || 0;
      const marketSellVolPercent = Math.round((marketSellVol / marketTotalVol) * 100) || 0;
      const marketTotalVolPercent = 100 - marketBuyVolPercent - marketSellVolPercent;

      const symbolChartInformation = {
        title: "Symbol",
        buyVol: symbolBuyVol,
        sellVol: symbolSellVol,
        series: [
          {
            name: "Buy Vol",
            data: [symbolBuyVolPercent]
          },
          {
            name: "Total Vol",
            data: [SymbolTotalVolPercent]
          },
          {
            name: "Sell Vol",
            data: [symbolSellVolPercent]
          }
        ]
      };

      const sectorChartInformation = {
        title: "ฺBanking",
        buyVol: sectorBuyVol,
        sellVol: sectorSellVol,
        series: [
          {
            name: "Buy Vol",
            data: [sectorBuyVolPercent]
          },
          {
            name: "Total Vol",
            data: [sectorToltalVolPercent]
          },
          {
            name: "Sell Vol",
            data: [sectorSellVolPercent]
          }
        ]
      };

      const marketChartInformation = {
        title: "Market",
        buyVol: marketBuyVol,
        sellVol: marketSellVol,
        series: [
          {
            name: "Buy Vol",
            data: [marketBuyVolPercent]
          },
          {
            name: "Total Vol",
            data: [marketTotalVolPercent]
          },
          {
            name: "Sell Vol",
            data: [marketSellVolPercent]
          }
        ]
      };

      temptChartList.push(symbolChartInformation);
      temptChartList.push(sectorChartInformation);
      temptChartList.push(marketChartInformation);
      this.chartList = temptChartList;
    },
    onResize() {
      const buySellPercent = this.$refs.buySellPercent;

      if (buySellPercent) {
        const fontSize = window.getComputedStyle(document.body).getPropertyValue("font-size");
        const parseSize = parseFloat(fontSize);

        this.chartWidth = buySellPercent.offsetWidth - Math.round(parseSize);
      }
    }
  }
};
</script>

<style lang="css" scoped>
.buy-and-sell-percent {
  padding: 0.5rem 0;
  color: #fff;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.buy-and-sell-percent .symbol-dropdown-section {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  width: 100%;
  height: 3rem;
}

.buy-and-sell-percent .symbol-dropdown-container {
  width: 50%;
}
.buy-and-sell-percent .custom-symbol-dropdown {
  min-height: 3rem;
  line-height: 3rem;
  width: 100% !important;
  min-width: 1rem !important;
}

.buy-and-sell-percent .quote-last-sale-container {
  width: 50%;
}

.buy-and-sell-percent .chart-section {
  height: calc(100% - 3rem);
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.buy-and-sell-percent .chart-section .header-column-section {
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  padding: 0 1.4rem;
}

.buy-and-sell-percent .chart-section .header-column-section .header {
  text-align: end;
  z-index: 10;
}

.buy-and-sell-percent .chart-section .header-column-section .chart-title {
  box-sizing: border-box;
  padding-left: 0rem;
  z-index: 10;
}

.buy-and-sell-percent .chart-section .header-column-section .value {
  color: #e4af0a;
  text-align: end;
  z-index: 10;
}

.buy-and-sell-percent .chart-section .chart-container {
  width: 100%;
}

.buy-and-sell-percent .chart-section .chart-container .custom-chart {
  height: 40px !important;
  position: relative;
  width: 100% !important;
  bottom: 30px;
}

.buy-and-sell-percent .chart-section .chart-container .custom-chart > div {
  width: 100% !important;
}
</style>
