import { ref, computed, onBeforeMount, onBeforeUnmount, getCurrentInstance, onMounted, nextTick, watch } from "vue";
import * as webSocket from "@/core/WebSocket";
import store from "@/store";
import messageFactory from "@/messages/websocket/MessageFactory";
import { SecurityItem } from "@/messages/websocket/XR03";

import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils";

export default function useBBOView() {
  const vm = getCurrentInstance().proxy;
  const bboList = ref([]);

  const etsSecurities = computed(() => store.getters["global/etsSecurities"]);
  const dtsSecurities = computed(() => store.getters["global/dtsSecurities"]);
  const orderEntryModeOrder = computed(() => store.getters["global/modeOrder"]);

  const enableETS = isEnableETS();
  const enableDTS = isEnableDTS();

  const maximumBBO = 8;

  const XR03 = (securityList) => {
    const pb21Msg = messageFactory.createMessage("XR03");
    const pb22Msg = messageFactory.createMessage("XR03");
    const pb01Msg = messageFactory.createMessage("XR03");

    pb21Msg.messageCode.set("PB21");
    pb22Msg.messageCode.set("PB22");
    pb01Msg.messageCode.set("PB01");

    let resolvedSecurityList = [];

    if (securityList) {
      securityList.forEach((security) => {
        if (security?.value?.securityId) {
          const securityItem = new SecurityItem();

          securityItem.securityId = Number(security.value.securityId);

          const duplicateSecurity = resolvedSecurityList.find((security) => security.securityId.get() === securityItem.securityId.get());
          if (!duplicateSecurity) resolvedSecurityList.push(securityItem);
        }
      });

      pb21Msg.securityList.set(resolvedSecurityList);
      pb22Msg.securityList.set(resolvedSecurityList);
      pb01Msg.securityList.set(resolvedSecurityList);

      webSocket.send(pb21Msg);
      webSocket.send(pb22Msg);
      webSocket.send(pb01Msg);

      if (enableDTS) {
        //bbos
        const pb52Msg = vm.$messageFactory.createMessage("XR03");

        pb52Msg.messageCode.set("PB52");
        pb52Msg.securityList.set(resolvedSecurityList);
        webSocket.send(pb52Msg);
      }
    }
  };

  const SR01 = (selectedSymbol) => {
    const message = messageFactory.createMessage("SR01");

    message.securityId.set(selectedSymbol.securityId);
    message.lastTradeCount.set(0);
    message.full.set("N");
    message.exchangeId.set(selectedSymbol.exchangeId);

    webSocket.send(message);
  };

  const initBBOList = () => {
    let selectedBBOList = JSON.parse(localStorage.getItem("selected-bbo-list")) || [];

    if (selectedBBOList.length == 0) {
      selectedBBOList = Array(maximumBBO).fill({});
    }
    if (selectedBBOList.length > 0 && selectedBBOList.length < maximumBBO) {
      selectedBBOList = [...selectedBBOList, ...Array(maximumBBO - selectedBBOList.length).fill({})];
    }
    if (selectedBBOList.length > 0 && selectedBBOList.length > maximumBBO) {
      selectedBBOList = selectedBBOList.slice(0, maximumBBO);
    }

    selectedBBOList.forEach((bbo, index) => {
      let currentSecurityId = bbo.value?.securityId ?? null;

      if (currentSecurityId == null) {
        selectedBBOList[index] = {};
        return;
      }

      if (bbo.value.exchangeId === 1 && enableETS) {
        currentSecurityId = etsSecurities.value[bbo.symbol].securityId;
      } else if (bbo.value.exchangeId === 2 && enableDTS) {
        currentSecurityId = dtsSecurities.value[bbo.symbol].securityId;
      }

      bbo.value.securityId = currentSecurityId;
    });

    bboList.value = selectedBBOList;
    localStorage.setItem("selected-bbo-list", JSON.stringify(bboList.value));

    XR03(bboList.value);

    bboList.value.forEach((bbo) => {
      if (Object.keys(bbo).length !== 0) {
        SR01(bbo.value);
      }
    });
  };

  const onBBOSymbolSelected = (selectedSymbol) => {
    if (selectedSymbol) SR01(selectedSymbol.value);
  };

  const onSelectBBO = (selectedIndex, selectedValue) => {
    if (!selectedValue.symbol) {
      bboList.value[selectedIndex] = {};
    } else {
      bboList.value[selectedIndex] = {
        symbol: selectedValue.symbol.symbol,
        value: {
          exchangeId: selectedValue.symbol.value.exchangeId,
          securityId: selectedValue.symbol.value.securityId,
          symbol: selectedValue.symbol.value.symbol,
        },
        isShowAllBBO: selectedValue.isShowAllBBO,
      };
    }

    localStorage.setItem("selected-bbo-list", JSON.stringify(bboList.value));

    XR03(bboList.value);
  };

  const onSwitchLevel = (selectedIndex, isShowAll) => {
    bboList.value[selectedIndex].isShowAllBBO = isShowAll;
    localStorage.setItem("selected-bbo-list", JSON.stringify(bboList.value));
  };

  const unSubscribe = (code) => {
    const message = vm.$messageFactory.createMessage("XR03");

    message.messageCode.set(code);
    message.mode.set("D");
    vm.$ws.send(message);
  };

  const removeSubscription = () => {
    unSubscribe("PB21");
    unSubscribe("PB22");
    unSubscribe("PB01");

    if (enableDTS) {
      unSubscribe("PB52");
    }
  };

  watch(orderEntryModeOrder, async () => {
    if (!orderEntryModeOrder.value) {
      await nextTick();

      if (bboList.value) return XR03(bboList.value);
    }

    return removeSubscription();
  });

  onBeforeMount(() => {
    vm.$EventBus.$on("onBBOsSymbolSelected", onBBOSymbolSelected);
  });

  onMounted(() => {
    initBBOList();
  });

  onBeforeUnmount(() => {
    vm.$EventBus.$off("onBBOsSymbolSelected", onBBOSymbolSelected);

    removeSubscription();
  });

  return { maximumBBO, bboList, onSelectBBO, onSwitchLevel };
}
