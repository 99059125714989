/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class BR51 extends WsReqMsg {
	constructor() {
		super(1);
		this._underlying = new StringMessageBase();
		this._category = new StringMessageBase();
	}
	
	get underlying() {
		return this._underlying;
	}
	
	set underlying(underlying) {
		this._underlying = new StringMessageBase(underlying);
	}
	
	get category() {
		return this._category;
	}
	
	set category(category) {
		this._category = new StringMessageBase(category);
	}
}

export default BR51;
