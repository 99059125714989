import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStylem,
  priceColorStyle,
  comparePriceNoCeilingFloorColorStyle,
  NO_VALUE,
} from "../Formatter.js";

import numeral from "numeral";

const columnDefsQuoteSaleByDate = [
  {
    headerName: "Date",
    field: "date",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 100,
    cellStyle: (params) => ({ ...totalColorStyle(params), "justify-content": "flex-start" }),
  },
  {
    headerName: "Open",
    field: "open",
    width: 120,
    valueGetter: (params) => {
      if (!params.data.open) {
        return NO_VALUE;
      } else {
        return params.data.open;
      }
    },
    cellStyle: comparePriceNoCeilingFloorColorStyle,
  },
  {
    headerName: "Close",
    field: "close",
    width: 120,
    valueGetter: (params) => {
      if (!params.data.close) {
        return NO_VALUE;
      } else {
        return params.data.close;
      }
    },
    cellStyle: comparePriceNoCeilingFloorColorStyle,
  },
  {
    headerName: "Change",
    field: "change",
    width: 120,
    cellStyle: priceColorStyle,
  },
  {
    headerName: "%Change",
    valueGetter: (params) => {
      return (numeral(params.data.change).value() / numeral(params.data.prior).value()) * 100;
    },
    valueFormatter: (params) => numeral(params.value).format("0.00") + "%",
    width: 120,
    cellStyle: priceColorStyle,
  },
  {
    headerName: "Volume",
    field: "volume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Value",
    field: "value",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "High",
    field: "high",
    width: 120,
    valueGetter: (params) => {
      if (!params.data.high) {
        return NO_VALUE;
      } else {
        return params.data.high;
      }
    },
    cellStyle: comparePriceNoCeilingFloorColorStyle,
  },
  {
    headerName: "Low",
    field: "low",
    width: 120,
    valueGetter: (params) => {
      if (!params.data.low) {
        return NO_VALUE;
      } else {
        return params.data.low;
      }
    },
    cellStyle: comparePriceNoCeilingFloorColorStyle,
  },
];

export { columnDefsQuoteSaleByDate };
