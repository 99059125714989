<template>
  <div class="ui equal width form" style="padding: 0.5rem 0.5rem 0rem 0.5rem; height: calc(25vh - 32px - 5px); overflow: auto;">
    <div class="fields">
      <table class="ui small very compact inverted table">
        <thead>
          <tr>
            <th style="background-color: #262D33;" id="blank" />
            <th class="right aligned" id="previous">
              <h5 class="ui grey header inverted">Previous</h5>
            </th>
            <th class="right aligned" id="currente">
              <h5 class="ui grey header inverted">Current (Expected)</h5>
            </th>
            <th class="right aligned" id="currentp">
              <h5 class="ui grey header inverted">Current (Port)</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><h5 class="ui inverted grey header">Equity Balance</h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ marginInfo.prevEquityBalance }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ marginInfo.eptEquityBalance }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ marginInfo.portEquityBalance }}
              </h5>
            </td>
          </tr>
          <tr>
            <td>
              <h5 class="ui inverted grey header">Excess Equity Balance</h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ marginInfo.prevExcessEquityBalance }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ marginInfo.eptExcessEquityBalance }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ marginInfo.portExcessEquityBalance }}
              </h5>
            </td>
          </tr>
          <tr>
            <td>
              <h5 class="ui inverted grey header">
                Floating P/L or Unrealized P/L
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">{{ marginInfo.prevPL }}</h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">{{ marginInfo.eptPL }}</h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">{{ marginInfo.portPL }}</h5>
            </td>
          </tr>
          <tr>
            <td><h5 class="ui inverted grey header">Margin Balance</h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ marginInfo.prevMarginBalance }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ marginInfo.eptMarginBalance }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ marginInfo.portMarginBalance }}
              </h5>
            </td>
          </tr>
          <tr>
            <td>
              <h5 class="ui inverted grey header">Call Force Flag / Amount</h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ (marginInfo.prevForceFlag || "-") + " / " + marginInfo.prevAmount }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ (marginInfo.eptForceFlag || "-") + " / " + marginInfo.eptAmount }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ (marginInfo.portForceFlag || "-") + " / " + marginInfo.portAmount }}
              </h5>
            </td>
          </tr>
          <tr>
            <td>
              <h5 class="ui inverted grey header">Depository Withdrawal</h5>
            </td>
            <td class="right aligned"><h5 class="ui yellow header"></h5></td>
            <td class="right aligned"><h5 class="ui yellow header"></h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ marginInfo.portDepositWithdrawal }}
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  data: () => ({
    marginInfo: {
      eptAmount: "97,915,000.00",
      eptEquityBalance: "97,915,000.00",
      eptExcessEquityBalance: "97,915,000.00",
      eptForceFlag: "",
      eptMarginBalance: "0.00",
      eptPL: "0.00",
      portAmount: "97,915,000.00",
      portDepositWithdrawal: "0.00",
      portEquityBalance: "97,915,000.00",
      portExcessEquityBalance: "97,915,000.00",
      portForceFlag: "",
      portMarginBalance: "0.00",
      portPL: "-2,085,000.00",
      prevAmount: "100,000,000.00",
      prevEquityBalance: "100,000,000.00",
      prevExcessEquityBalance: "100,000,000.00",
      prevForceFlag: "",
      prevMarginBalance: "0.00",
      prevPL: "0.00"
    }
  }),
  watch: {
    currentSelectedAccount() {
      this.AR55();
    }
  },
  created() {
    this.AR55();

    this.$EventBus.$on("ctinf/AS55", this.dtsAccountMarginCinf);

    this.$EventBus.$on("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS55", this.dtsAccountMarginCinf);

    this.$EventBus.$off("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
  },
  methods: {
    onPortfolioInfoStartRefresh() {
      this.AR55();
    },
    AR55() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount.exchangeId == 0) {
        this.$EventBus.$emit("onPortfolioInfoStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("AR55");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);

      this.$ws.send(msg);
    },
    dtsAccountMarginCinf(info) {
      this.marginInfo = { ...this.marginInfo, ...info };
      this.$EventBus.$emit("onPortfolioInfoStopRefresh");
    }
  },
  computed: {
    currentSelectedAccount: get("global/currentSelectedAccount")
  }
};
</script>

<style scoped>
.fields {
  margin: 0.25rem !important;
}

th.right.aligned {
  padding-top: 0.25rem !important;
  padding-bottom: 0.5rem !important;
  background-color: #262d33 !important;
}
</style>
