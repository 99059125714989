<template>
  <div class="ui equal width form" style="padding: 0.5rem; height: calc(25vh - 32px - 5px); overflow: auto;">
    <div class="fields">
      <table class="ui small very compact inverted table">
        <thead>
          <tr>
            <th style="background-color: #262D33;" id="blank" />
            <th class="right aligned" style="background-color: #262D33;" id="previous">
              <h5 class="ui grey header inverted">Previous</h5>
            </th>
            <th class="right aligned" style="background-color: #262D33;" id="current">
              <h5 class="ui grey header inverted">Current</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><h5 class="ui inverted grey header">Loan</h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.prevLoan }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.curLoan }}
              </h5>
            </td>
          </tr>
          <tr>
            <td><h5 class="ui inverted grey header">SMV</h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.prevSMV }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.curSMV }}
              </h5>
            </td>
          </tr>
          <tr>
            <td><h5 class="ui inverted grey header">Call</h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.prevCall }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.curCall }}
              </h5>
            </td>
          </tr>
          <tr>
            <td><h5 class="ui inverted grey header">Force</h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.prevForce }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.curForce }}
              </h5>
            </td>
          </tr>
          <tr>
            <td><h5 class="ui inverted grey header">Value Force Sale</h5></td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.prevValueForceSale }}
              </h5>
            </td>
            <td class="right aligned">
              <h5 class="ui yellow header">
                {{ accountCreditBalance.curValueForceSale }}
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  props: ["onShow"],
  data: () => ({
    accountCreditBalance: {
      prevLoan: "",
      curLoan: "",
      prevSMV: "",
      curSMV: "",
      prevCall: "",
      curCall: "",
      prevForce: "",
      curForce: "",
      prevValueForceSale: "",
      curValueForceSale: ""
    }
  }),
  watch: {
    currentSelectedAccount() {
      if (!this.onShow) return;

      this.AR03();
    }
  },
  created() {
    this.AR03();

    this.$EventBus.$on("ctinf/AS03", this.etsAccountCreditBalanceCinf);

    this.$EventBus.$on("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS03", this.etsAccountCreditBalanceCinf);

    this.$EventBus.$off("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
  },
  methods: {
    onPortfolioInfoStartRefresh() {
      if (!this.onShow) return;

      this.AR03();
    },
    AR03() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount.exchangeId == 0) {
        this.accountCreditBalance = {
          prevLoan: "",
          curLoan: "",
          prevSMV: "",
          curSMV: "",
          prevCall: "",
          curCall: "",
          prevForce: "",
          curForce: "",
          prevValueForceSale: "",
          curValueForceSale: ""
        };
        this.$EventBus.$emit("onPortfolioInfoStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("AR03");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);

      this.$ws.send(msg);
    },
    etsAccountCreditBalanceCinf(info) {
      if (!this.onShow) return;

      this.accountCreditBalance = { ...this.accountCreditBalance, ...info };
      this.$EventBus.$emit("onPortfolioInfoStopRefresh");
    }
  },
  computed: {
    currentSelectedAccount: get("global/currentSelectedAccount")
  }
};
</script>

<style scoped>
.fields {
  margin: 0.25rem !important;
}

th.right.aligned {
  padding-top: 0.25rem !important;
  padding-bottom: 0.5rem !important;
}
</style>
