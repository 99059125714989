/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class OS04 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._records = new ArrayMessageBase(Record);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get records() {
		return this._records;
	}
}

export class Record {
	constructor() {
		this._recordId = new StringMessageBase();
		this._orderId = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._price = new StringMessageBase();
		this._matchedVol = new StringMessageBase();
		this._publishedVol = new StringMessageBase();
		this._icebergVol = new StringMessageBase();
		this._condition = new StringMessageBase();
		this._ttf = new StringMessageBase();
		this._time = new StringMessageBase();
		this._status = new StringMessageBase();
		this._quote = new StringMessageBase();
		this._accountCode = new StringMessageBase();
		this._approved = new StringMessageBase();
		this._priceFlag = new StringMessageBase();
		this._specialOrderType = new StringMessageBase();
		this._inActivate = new StringMessageBase();
		this._sendDate = new StringMessageBase();
		this._dbRecordId = new StringMessageBase();
		this._orderType = new StringMessageBase();
		this._isAfcOrder = new StringMessageBase();
		this._validity = new StringMessageBase();
		this._validityData = new StringMessageBase();
		this._serviceType = new StringMessageBase();
		this._marketId = new StringMessageBase();
		this._strategyId = new StringMessageBase();
	}
	
	get recordId() {
		return this._recordId;
	}
	
	get orderId() {
		return this._orderId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get side() {
		return this._side;
	}
	
	get volume() {
		return this._volume;
	}
	
	get price() {
		return this._price;
	}
	
	get matchedVol() {
		return this._matchedVol;
	}
	
	get publishedVol() {
		return this._publishedVol;
	}
	
	get icebergVol() {
		return this._icebergVol;
	}
	
	get condition() {
		return this._condition;
	}
	
	get ttf() {
		return this._ttf;
	}
	
	get time() {
		return this._time;
	}
	
	get status() {
		return this._status;
	}
	
	get quote() {
		return this._quote;
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	get approved() {
		return this._approved;
	}
	
	get priceFlag() {
		return this._priceFlag;
	}
	
	get specialOrderType() {
		return this._specialOrderType;
	}
	
	get inActivate() {
		return this._inActivate;
	}
	
	get sendDate() {
		return this._sendDate;
	}
	
	get dbRecordId() {
		return this._dbRecordId;
	}
	
	get orderType() {
		return this._orderType;
	}
	
	get isAfcOrder() {
		return this._isAfcOrder;
	}
	
	get validity() {
		return this._validity;
	}
	
	get validityData() {
		return this._validityData;
	}
	
	get serviceType() {
		return this._serviceType;
	}
	
	get marketId() {
		return this._marketId;
	}
	
	get strategyId() {
		return this._strategyId;
	}
}

export default OS04;
