/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class XR03 extends WsReqMsg {
	constructor() {
		super(2);
		this._securityList = new ArrayMessageBase(SecurityItem);
		this._indexList = new ArrayMessageBase(IndexItem);
		this._favoriteId = new NumberMessageBase();
		this._mode = new StringMessageBase();
		this._messageCode = new StringMessageBase();
	}
	
	get securityList() {
		return this._securityList;
	}
	
	set securityList(securityList) {
		this._securityList = new ArrayMessageBase(securityList);
	}
	
	get indexList() {
		return this._indexList;
	}
	
	set indexList(indexList) {
		this._indexList = new ArrayMessageBase(indexList);
	}
	
	get favoriteId() {
		return this._favoriteId;
	}
	
	set favoriteId(favoriteId) {
		this._favoriteId = new NumberMessageBase(favoriteId);
	}
	
	get mode() {
		return this._mode;
	}
	
	set mode(mode) {
		this._mode = new StringMessageBase(mode);
	}
	
	get messageCode() {
		return this._messageCode;
	}
	
	set messageCode(messageCode) {
		this._messageCode = new StringMessageBase(messageCode);
	}
}

export class SecurityItem {
	constructor() {
		this._securityId = new NumberMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	set securityId(securityId) {
		this._securityId = new NumberMessageBase(securityId);
	}
}

export class IndexItem {
	constructor() {
		this._indexId = new NumberMessageBase();
	}
	
	get indexId() {
		return this._indexId;
	}
	
	set indexId(indexId) {
		this._indexId = new NumberMessageBase(indexId);
	}
}

export default XR03;
