import moment from "moment";

export const ETS_STRING_DATE_FORMAT = "DD/MM/YYYY";
export const ETS_DATE_FORMAT = "YYYYMMDD";
export const ETS_SHORT_DATE_FORMAT = "YYMMDD";

export const parseEtsStrToUTCDate = etsDate => {
  return moment(etsDate, ETS_STRING_DATE_FORMAT)
    .utc()
    .toDate();
};

export const parseEtsDateToUTCDate = etsDate => {
  return moment(etsDate, ETS_DATE_FORMAT)
    .utc()
    .toDate();
};

export const parseStringEtsDateToEtsDate = function(etsStringDate /*String dd/MM/yyyy */) {
  return moment(etsStringDate, ETS_STRING_DATE_FORMAT).format(ETS_DATE_FORMAT);
};

export const parseValidityTimeStrToUTCDate = str => {
  return moment(str, "YYYYMMDDHHmm00")
    .utc()
    .toDate();
};

export const formatDateToEtsDate = date => {
  return moment(date).format(ETS_DATE_FORMAT);
};

export const formatDateToEtsShortDate = date => {
  return moment(date).format(ETS_SHORT_DATE_FORMAT);
};

export const formatETSDateToDisplayDate = etsDate => {
  return moment(etsDate, ETS_DATE_FORMAT).format(ETS_STRING_DATE_FORMAT);
};

export const formatValidityTime = (date, time) => {
  const dateStr = moment(date).format("YYYYMMDD");
  const timeStr = moment(time).format("HHmm00");
  return dateStr + timeStr;
};

export const parseEtsShortDateToUTCDate = etsDate => {
  return moment(etsDate, ETS_SHORT_DATE_FORMAT)
    .utc()
    .toDate();
};
