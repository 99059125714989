/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:57
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class AS03 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._marginRate = new StringMessageBase();
		this._loanLimit = new StringMessageBase();
		this._depositTemporary = new StringMessageBase();
		this._purchasingPower = new StringMessageBase();
		this._buyAvailable = new StringMessageBase();
		this._shortAvailable = new StringMessageBase();
		this._loanOfOpen = new StringMessageBase();
		this._prevEE = new StringMessageBase();
		this._curEE = new StringMessageBase();
		this._markEE = new StringMessageBase();
		this._markEEPercentage = new StringMessageBase();
		this._prevEquity = new StringMessageBase();
		this._curEquity = new StringMessageBase();
		this._prevMR = new StringMessageBase();
		this._curMR = new StringMessageBase();
		this._prevCashBalance = new StringMessageBase();
		this._curCashBalance = new StringMessageBase();
		this._prevLMV = new StringMessageBase();
		this._curLMV = new StringMessageBase();
		this._prevCollateral = new StringMessageBase();
		this._curCollateral = new StringMessageBase();
		this._prevLoan = new StringMessageBase();
		this._curLoan = new StringMessageBase();
		this._prevSMV = new StringMessageBase();
		this._curSMV = new StringMessageBase();
		this._prevCall = new StringMessageBase();
		this._curCall = new StringMessageBase();
		this._prevForce = new StringMessageBase();
		this._curForce = new StringMessageBase();
		this._prevValueForceSale = new StringMessageBase();
		this._curValueForceSale = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get marginRate() {
		return this._marginRate;
	}
	
	get loanLimit() {
		return this._loanLimit;
	}
	
	get depositTemporary() {
		return this._depositTemporary;
	}
	
	get purchasingPower() {
		return this._purchasingPower;
	}
	
	get buyAvailable() {
		return this._buyAvailable;
	}
	
	get shortAvailable() {
		return this._shortAvailable;
	}
	
	get loanOfOpen() {
		return this._loanOfOpen;
	}
	
	get prevEE() {
		return this._prevEE;
	}
	
	get curEE() {
		return this._curEE;
	}
	
	get markEE() {
		return this._markEE;
	}
	
	get markEEPercentage() {
		return this._markEEPercentage;
	}
	
	get prevEquity() {
		return this._prevEquity;
	}
	
	get curEquity() {
		return this._curEquity;
	}
	
	get prevMR() {
		return this._prevMR;
	}
	
	get curMR() {
		return this._curMR;
	}
	
	get prevCashBalance() {
		return this._prevCashBalance;
	}
	
	get curCashBalance() {
		return this._curCashBalance;
	}
	
	get prevLMV() {
		return this._prevLMV;
	}
	
	get curLMV() {
		return this._curLMV;
	}
	
	get prevCollateral() {
		return this._prevCollateral;
	}
	
	get curCollateral() {
		return this._curCollateral;
	}
	
	get prevLoan() {
		return this._prevLoan;
	}
	
	get curLoan() {
		return this._curLoan;
	}
	
	get prevSMV() {
		return this._prevSMV;
	}
	
	get curSMV() {
		return this._curSMV;
	}
	
	get prevCall() {
		return this._prevCall;
	}
	
	get curCall() {
		return this._curCall;
	}
	
	get prevForce() {
		return this._prevForce;
	}
	
	get curForce() {
		return this._curForce;
	}
	
	get prevValueForceSale() {
		return this._prevValueForceSale;
	}
	
	get curValueForceSale() {
		return this._curValueForceSale;
	}
}

export default AS03;
