<template>
  <button ref="set-50-dropdown" @click="onOpenDropdown" @blur="onBlur" class="set-50-dropdown">
    <div>{{ setIndexNameFormatter(selectedValue) }}</div>
    <i class="caret down icon" />
  </button>
</template>

<script setup>
import { getCurrentInstance, ref, onBeforeMount, watch } from "vue";

const props = defineProps({ options: { type: Array, default: [] }, selectValue: { type: String, default: ".SET50" } });
const emits = defineEmits(["select-item"]);

const vm = getCurrentInstance().proxy;
const selectedValue = ref(props.selectValue);
const uid = vm._uid;

const setIndexNameFormatter = (name) => {
  switch (name) {
    case ".MAI":
      return ".mai";
    case ".SSET":
      return ".sSET";
    default:
      return name;
  }
};

const onOpenDropdown = () => {
  const rect = vm.$refs["set-50-dropdown"].getBoundingClientRect();

  vm.$EventBus.$emit("dropdown", {
    inputId: uid,
    items: props.options,
    placeholder: "",
    freeText: "",
    keyText: "name",
    keyValue: "name",
    rect: rect,
    callbackData: onSelectItem,
    isSymbol: null,
    forceWidth: null,
    forceUpdateOptions: false,
    scroller: false,
    selectableCallback: null,
    setIndexNameFormatter,
  });
  vm.$EventBus.$emit("opendropdown", rect);
};

const onSelectItem = (data) => {
  selectedValue.value = data.name;
  emits("select-item", data.name);
  vm.$EventBus.$emit("closedropdown");
};

const onBlur = () => {
  setTimeout(() => {
    vm.$EventBus.$emit("closedropdown");
  }, 120);
};

const initDropdown = () => {
  const selectedOption = props.options.find((option) => option.name === selectedValue);

  if (selectedOption) {
    selectedValue.value = selectedOption.name;
    return;
  }
  selectedValue.value = props.selectValue;
  return;
};

watch([() => props.options, () => props.selectValue], () => {
  initDropdown();
});

onBeforeMount(() => {
  initDropdown();
});
</script>

<style lang="scss" scoped>
.set-50-dropdown {
  display: flex;
  align-items: center;
  font-size: 1.07rem;
  font-weight: 700;
  color: #dcddde;
  padding: 0;
  border: 1px solid transparent;
  background-color: transparent;
  column-gap: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  .icon {
    color: #dcddde;
  }
}
</style>
