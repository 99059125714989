import { make } from "vuex-pathify";

const authentication = () => {
  const state = {
    me: {}
  };
  const mutations = make.mutations(state);
  const actions = make.actions(state);
  const getters = make.getters(state);
  return { namespaced: true, state, mutations, actions, getters };
};

export default authentication;
