<script>
import BaseDropdown from "@/components/BaseDropdown.vue";

export default {
  extends: BaseDropdown,
  props: {
    value: String,
    placeholder: {
      type: String,
      default: "Selection",
    },
    dropdownOptions: {
      tpye: Array,
      default: [],
    },
  },
  data() {
    return {
      style: "width: 8rem; min-width: 8rem;",
      defaultValue: "",
      displayValue: "",
      options: [],
    };
  },
  created() {
    this.initOptions();
  },
  watch: {
    dropdownOptions() {
      this.initOptions();
    },
  },
  methods: {
    initOptions() {
      this.options = this.dropdownOptions || [];

      if (this.value && this.options.length > 0) {
        const selectedValue = this.options.find((option) => option.value == this.value);

        if (selectedValue) {
          this.defaultValue = selectedValue;
          this.displayValue = selectedValue.text;
        }
      }
    },
  },
};
</script>
