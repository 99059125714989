<template>
  <div>
    <div style="height: 3rem; line-height: 3rem;">
      <div class="ui form">
        <div class="fields">
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-right: 0px;">Date</h5>
          </div>
          <div class="field">
            <el-date-picker
              v-model="selectedDate"
              type="date"
              align="left"
              format="dd MMM yyyy"
              :clearable="false"
              :picker-options="pickerOptions"
              style="width: 9rem;"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="ui grid">
      <div class="sixteen wide column">
        <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer; z-index: 1;">
          <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde;" @click="onRefresh" />
        </div>
        <ag-grid-vue
          style="width: 100%; height: calc(25vh - 3rem - 5px); padding: 0px;"
          :class="{
            'ag-theme-balham-dark': theme === 'Dark',
            'ag-theme-balham': theme === 'Light'
          }"
          row-selection="single"
          :grid-options="gridIndexMarketOptions"
          :column-defs="columnDefsIndexMarket"
          :row-data="rowIndexMarket"
          :get-row-node-id="getIndexMarketRowNodeId"
          :row-height="21"
          :header-height="32"
          :default-col-def="{ sortable: true, resizable: true }"
          :animate-rows="true"
          :suppress-drag-leave-hides-columns="true"
          :enable-cell-change-flash="true"
          :locale-text="{ noRowsToShow: `Don't have any Index.` }"
          :row-drag-managed="false"
          overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
          @grid-ready="onIndexMarketGridReady"
          @cell-focused="onCellIndexMarketFocused"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { DatePicker } from "element-ui";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsIndexMarket } from "@/components/grid/index/IndexMarketColumnDefs.js";

Object.freeze(columnDefsIndexMarket);

export default {
  name: "IndexMarket",
  components: {
    AgGridVue,
    "el-date-picker": DatePicker
  },
  data: () => ({
    isLoading: false,
    columnDefsIndexMarket,

    gridIndexMarketApi: null,
    columnIndexMarketApi: null,
    rowIndexMarket: [],
    IR01RefId: "",
    selectedDate: new Date(),
    pickerOptions: {
      disabledDate(time) {
        return time.getTime() > Date.now();
      }
    }
  }),
  watch: {
    selectedDate() {
      this.IR01();
    }
  },
  methods: {
    onRefresh() {
      this.IR01();
    },
    IR01() {
      this.isLoading = true;
      const msg = this.$messageFactory.createMessage("IR01");

      msg.indexType.set("1");
      msg.tradingDate.set(this.$moment(this.selectedDate).format("DD/MM/YYYY"));

      this.IR01RefId = this.$shortid.generate();
      this.$ws.send(msg, this.IR01RefId);
      if (this.gridIndexMarketApi) {
        this.gridIndexMarketApi.showLoadingOverlay();
      }
    },
    updateRowIndexMarket(index) {
      this.rowIndexMarket = index.indexes;
      if (this.gridIndexMarketApi) {
        this.gridIndexMarketApi.hideOverlay();
      }
    },
    onIndexMarketGridReady(params) {
      this.gridIndexMarketApi = params.api;
      this.columnIndexMarketApi = params.columnApi;
    },
    getIndexMarketRowNodeId(data) {
      return data.indexId;
    },
    onCellIndexMarketFocused(event) {
      this.gridIndexMarketOptions.api.forEachNode(node => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    indexListCinf(index) {
      if (index.refId !== this.IR01RefId) {
        this.isLoading = false;
        return;
      }

      this.updateRowIndexMarket(index);
      this.isLoading = false;
    }
  },
  computed: {
    theme: sync("global/theme")
  },
  created() {
    this.gridIndexMarketOptions = {};
    this.IR01();

    this.$EventBus.$on("ctinf/IS01", this.indexListCinf);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/IS01", this.indexListCinf);
  }
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

input.el-input__inner {
  padding-left: 2rem !important;
  color: #dcddde !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-weight: 700 !important;
  background: #2f383f !important;
}
</style>
