import { baseStyle, stopCondFormatter } from "../Formatter.js";

export default [
  {
    headerName: "Stop Series",
    field: "series",
    pinned: "left",
    cellStyle: baseStyle
  },
  {
    headerName: "Stop Flag",
    field: "flag",
    cellStyle: baseStyle
  },
  {
    headerName: "Stop Value",
    field: "value",
    cellStyle: baseStyle
  },
  {
    headerName: "Stop Condition",
    field: "cond",
    cellStyle: baseStyle,
    valueFormatter: stopCondFormatter
  }
];
