import { ref, getCurrentInstance, onBeforeMount, onBeforeUnmount } from "vue";

export default function useNewsView() {
  const vm = getCurrentInstance().proxy;
  const isLoading = ref(false);

  const onClickRefresh = () => {
    vm.$EventBus.$emit("onNewsRefresh", true);
  };

  const onRefresh = (refreshValue) => {
    isLoading.value = refreshValue;
  };

  onBeforeMount(() => {
    vm.$EventBus.$on("onNewsRefresh", onRefresh);
  });

  onBeforeUnmount(() => {
    vm.$EventBus.$off("onNewsRefresh", onRefresh);
  });

  return { isLoading, onClickRefresh };
}
