/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class IS01 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._indexes = new ArrayMessageBase(Index);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get indexes() {
		return this._indexes;
	}
}

export class Index {
	constructor() {
		this._indexId = new NumberMessageBase();
		this._name = new StringMessageBase();
		this._prior = new StringMessageBase();
		this._last = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._value = new StringMessageBase();
		this._change = new StringMessageBase();
		this._changePercent = new StringMessageBase();
		this._changeFlag = new StringMessageBase();
		this._open1 = new StringMessageBase();
	}
	
	get indexId() {
		return this._indexId;
	}
	
	get name() {
		return this._name;
	}
	
	get prior() {
		return this._prior;
	}
	
	get last() {
		return this._last;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get volume() {
		return this._volume;
	}
	
	get value() {
		return this._value;
	}
	
	get change() {
		return this._change;
	}
	
	get changePercent() {
		return this._changePercent;
	}
	
	get changeFlag() {
		return this._changeFlag;
	}
	
	get open1() {
		return this._open1;
	}
}

export default IS01;
