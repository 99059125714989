import Vue from "vue";
import Vuex from "vuex";
import pathify from "vuex-pathify";

import authentication from "./modules/authentication";
import global from "./modules/global";
import websocket from "./modules/websocket";
import model from "./modules/model";

Vue.use(Vuex);
pathify.options.deep = 2;

const debug = process.env.NODE_ENV !== "production";
/* Bind the Vuex functionality to the Vue.js instance */
const store = new Vuex.Store({
  plugins: [pathify.plugin],
  modules: {
    global: global(),
    websocket: websocket(),
    model: model(),
    auth: authentication()
  },
  strict: debug
});

export default store;
