<script>
import AllAccountDropdown from "./AllAccountDropdown";

export default {
  extends: AllAccountDropdown,
  props: {
    inputStyle: {
      type: String,
      default: "",
    },
    buttonStyle: {
      type: String,
      default: "",
    },
    allAccountMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      style: "width: 100%;",
    };
  },
  watch: {
    useMyCustomer(value) {
      this.init();
    },
    market(value) {
      this.ownerOptions = null;
      this.init();
    },
  },
};
</script>
