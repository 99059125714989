<template>
  <div>
    <sui-modal v-model="dialog" size="small" :closable="false">
      <sui-modal-header>
        <span>Select Account</span>
        <div class="close" @click="close"><i class="close grey icon"></i></div>
      </sui-modal-header>
      <div style="clear: float"></div>
      <sui-modal-content>
        <InputAccount
          ref="eaccount"
          v-model="account"
          style="width: 10rem !important"
          name="Account"
          placeholder="Account"
          market="SET"
          :is-validate="marketSelected === 'SET'"
          :disabled="disabledAccount"
          required
          @enter="
            () => {
              this.onAccountChanged();
              this.save();
            }
          "
        />
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button type="button" positive :loading="processing" :disabled="processing" @click="save">Select</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import InputAccount from "@/components/InputAccount";

export default {
  components: {
    InputAccount,
  },
  data() {
    return {
      processing: false,
      dialog: false,
      market: null,
      account: null,
      resolve: null,
    };
  },
  methods: {
    close() {
      this.dialog = false;
      this.resolve(null);
    },
    selectAccount(market) {
      this.market = market;
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.eaccount.select();
      });
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    save() {
      if (this.account) {
        this.resolve(this.account);
        this.resolve = null;
        this.dialog = false;
      }
    },
    onAccountChanged() {
      let exchangeId;
      if (this.market === "SET") {
        exchangeId = "1";
      } else if (this.market === "TFEX") {
        exchangeId = "2";
      }
      this.$store.set("global/currentAccount", exchangeId + "_" + this.account);
      this.$store.set("global/currentSelectedAccount", {
        exchangeId,
        accountCode: this.account,
      });
    },
  },
};
</script>
