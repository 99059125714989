<template>
  <div>
    <i v-if="canApprove && userType === 'T'" class="check icon" :style="`color: ${GREEN_COLOR};`" @click="approveItem"></i>
    <div v-else style="display: inline-block; width: 18px"></div>
    <i v-if="canApprove && userType === 'T'" class="delete icon" :style="`color: ${RED_COLOR};`" @click="disapproveItem"></i>
    <div v-else style="display: inline-block; width: 18px"></div>
    <i v-if="canDelete" class="trash alternate icon" :style="`color: ${GREY_COLOR};`" @click="deleteItem"></i>
    <div v-else style="display: inline-block; width: 18px"></div>
    <i v-if="canEdit" class="edit icon" @click="editItem"></i>
    <div v-else style="display: inline-block; width: 18px"></div>
    <i class="info circle icon" :style="`color: ${BLUE_COLOR};`" @click="detailItem"></i>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import { isEditableOrderStatus, isCancellableOrderStatus, isApproveableOrderStatus } from "@/utils/ValidationUtils";
import { PINK_COLOR, GREEN_COLOR, RED_COLOR, BLUE_COLOR, GREY_COLOR } from "../Formatter";
import { getBySymbolAndExchangeId } from "@/utils/SymbolUtils";
export default {
  data() {
    return {
      PINK_COLOR,
      GREEN_COLOR,
      RED_COLOR,
      BLUE_COLOR,
      GREY_COLOR,
      canApprove: false,
      canDelete: false,
      canEdit: false,
    };
  },
  created() {
    if (this.params?.data) {
      if (this.nightMode === "Y") {
        this.canEdit = false;
      } else {
        this.canEdit = isEditableOrderStatus(this.params.data);
      }

      if (this.nightMode === "Y" && this.params.data.isAfcOrder === "N") {
        this.canDelete = false;
      } else {
        this.canDelete = isCancellableOrderStatus(this.params.data);
      }

      if (this.nightMode === "Y" && this.params.data.isAfcOrder === "N") {
        this.canApprove = false;
      } else {
        this.canApprove = isApproveableOrderStatus(this.params.data);
      }
    }
  },
  methods: {
    refresh(params) {
      this.params = params;
      if (params.data) {
        if (this.nightMode === "Y") {
          this.canEdit = false;
        } else {
          this.canEdit = isEditableOrderStatus(params.data);
        }

        if (this.nightMode === "Y" && params.data.isAfcOrder === "N") {
          this.canDelete = false;
        } else {
          this.canDelete = isCancellableOrderStatus(params.data);
        }

        if (this.nightMode === "Y" && params.data.isAfcOrder === "N") {
          this.canApprove = false;
        } else {
          this.canApprove = isApproveableOrderStatus(params.data);
        }
      }
      return true;
    },
    editItem() {
      if (
        !this.currentQuoteSelectedSymbol ||
        (this.currentQuoteSelectedSymbol && this.currentQuoteSelectedSymbol.symbol !== this.params.data.symbol)
      ) {
        const symbol = {
          symbol: this.params.data.symbol,
          value: {
            exchangeId: this.params.data.exchangeId,
            securityId: getBySymbolAndExchangeId(this.params.data.symbol, this.params.data.exchangeId).securityId,
            symbol: this.params.data.symbol,
          },
          fromUID: "outside",
        };
        this.$store.set("global/currentQuoteSelectedSymbol", symbol);
      }
      this.$EventBus.$emit("orderChange", this.params.data);
    },
    deleteItem() {
      this.$EventBus.$emit("orderCancel", [this.params.data]);
    },
    approveItem() {
      this.$EventBus.$emit("orderApprove", [this.params.data]);
    },
    disapproveItem() {
      this.$EventBus.$emit("orderApprove", [this.params.data], false);
    },
    detailItem() {
      let market = "";
      if (this.params.data.exchangeId == 1) {
        market = "SET";
      } else if (this.params.data.exchangeId == 2) {
        market = "TFEX";
      }
      this.$EventBus.$emit("orderDetail", market, this.params.data.sendDate, this.params.data.orderId);
    },
  },
  computed: {
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
    nightMode: get("model/systemRefData@nightMode"),
    userType: get("model/systemRefData@userType"),
  },
};
</script>

<style scoped>
i {
  cursor: pointer;
}
</style>
