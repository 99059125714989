<template>
  <div class="series-position" style="overflow: hidden">
    <div style="height: 3rem; line-height: 3rem">
      <div class="ui form">
        <div class="fields">
          <div class="field" style="padding-left: 1.5rem">
            <div class="ui form">
              <div class="inline fields">
                <div class="field">
                  <sui-checkbox v-model="selectedMarket" radio value="SET" :disabled="!isEnableETS()">
                    <h4 class="ui grey inverted tiny header">SET</h4>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox v-model="selectedMarket" radio value="TFEX" :disabled="!isEnableDTS()">
                    <h4 class="ui grey inverted tiny header">TFEX</h4>
                  </sui-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-right: 0px; width: 5.5rem">
              {{ selectedMarket === "SET" ? "Security" : "Series ID" }}
            </h5>
          </div>
          <div class="field" style="width: auto; padding: 0px 1rem 0px 1rem !important">
            <SymbolDropdown
              v-show="selectedMarket === 'SET'"
              ref="esymbol"
              v-model="selectedSymbol"
              market="SET"
              image
              notification
              :default-from-store="false"
              style="
                top: calc(50% - 1.5rem);
                min-height: 2rem !important;
                line-height: 2rem !important;
                width: 12rem !important;
                min-width: 10rem !important;
              "
              @enter="$refs.esymbol.select()"
            />
            <SymbolDropdown
              v-show="selectedMarket === 'TFEX'"
              ref="dsymbol"
              v-model="selectedSymbol"
              market="TFEX"
              image
              notification
              :default-from-store="false"
              style="
                top: calc(50% - 1.5rem);
                min-height: 2rem !important;
                line-height: 2rem !important;
                width: 12rem !important;
                min-width: 10rem !important;
              "
              @enter="$refs.dsymbol.select()"
            />
          </div>
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem 0 1rem 0; width: auto">Trader ID</h5>
          </div>
          <div class="field" style="width: 8rem; padding: 0px 0px 0px 1rem !important">
            <InputText
              v-model="traderID"
              class="ht-trader-id"
              style="height: 36px"
              name="Trader ID"
              placeholder="Trader ID"
              :uppercase="true"
              @enter="onEnter"
            />
          </div>
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-right: 0px">
              {{ selectedMarket === "SET" ? "TTF" : "Side" }}
            </h5>
          </div>
          <div class="field" style="width: 13rem; padding: 0px 0px 0px 1rem !important">
            <sui-dropdown
              v-if="selectedMarket === 'SET'"
              v-model="selectedTTF"
              fluid
              button
              pointing
              :options="TTFOptions"
              class="ht-seriesposi-dropdown"
            />
            <sui-dropdown v-else v-model="selectedSide" fluid button pointing :options="sideOptions" class="ht-seriesposi-dropdown" />
          </div>
          <div v-if="selectedMarket === 'SET'" class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-right: 0px">Posi. Type</h5>
          </div>
          <div v-if="selectedMarket === 'SET'" class="field" style="width: 8rem; padding: 0px 0px 0px 1rem !important">
            <sui-dropdown v-model="selectedPosiType" fluid button pointing :options="posiTypeOptions" class="ht-seriesposi-dropdown" />
          </div>
        </div>
      </div>
    </div>
    <div class="ui grid">
      <div class="sixteen wide column" style="height: calc(55vh - 3rem - 32px - 5px)">
        <ag-grid-vue
          style="width: 100%; height: calc(55vh - 3rem - 32px - 5px); padding: 0px"
          :class="{
            'ag-theme-balham-dark': theme === 'Dark',
            'ag-theme-balham': theme === 'Light',
          }"
          row-selection="single"
          :grid-options="gridSeriesPositionOptions"
          :column-defs="selectedMarket === 'SET' ? columnDefsETSSeriesPosition : columnDefsDTSSeriesPosition"
          :row-data="rowSeriesPosition"
          :get-row-node-id="getSeriesPositionRowNodeId"
          :row-height="21"
          :header-height="32"
          :default-col-def="{ sortable: true, resizable: true }"
          :animate-rows="true"
          :suppress-drag-leave-hides-columns="true"
          :enable-cell-change-flash="true"
          :locale-text="{ noRowsToShow: `Don't have any Customer.` }"
          :row-drag-managed="false"
          :pinned-bottom-row-data="selectedMarket === 'SET' ? pinnedBottomRowETSSeriesPosition : pinnedBottomRowDTSSeriesPosition"
          overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
          @grid-ready="onSeriesPositionGridReady"
          @cell-focused="onCellSeriesPositionFocused"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import SymbolDropdown from "@/components/SymbolDropdown";
import { columnDefsETSSeriesPosition, columnDefsDTSSeriesPosition } from "@/components/grid/customers/SeriesPositionColumnDefs.js";
import InputText from "@/components/InputText";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

Object.freeze(columnDefsETSSeriesPosition);
Object.freeze(columnDefsDTSSeriesPosition);

export default {
  name: "SeriesPosition",
  components: {
    AgGridVue,
    SymbolDropdown,
    InputText,
  },
  data: () => ({
    columnDefsETSSeriesPosition,
    columnDefsDTSSeriesPosition,

    gridSeriesPositionApi: null,
    columnSeriesPositionApi: null,
    rowSeriesPosition: [],
    pinnedBottomRowETSSeriesPosition: [
      {
        custId: "Total",
        shares: "0",
        totPricePaid: "0",
        currentValue: "0",
        unrealPL: 0,
      },
    ],
    pinnedBottomRowDTSSeriesPosition: [
      {
        custId: "Total",
        totPricePaid: "0",
        currentValue: "0",
        unrealPL: 0,
      },
    ],
    selectedSymbol: null,
    selectedMarket: "SET",
    selectedTTF: "0",
    TTFOptions: [
      {
        text: "0 - Normal Order",
        value: "0",
      },
      {
        text: "1 - Thai Trust Fund",
        value: "1",
      },
      {
        text: "2 - NVDR",
        value: "2",
      },
      {
        text: "3 - Buy In",
        value: "3",
      },
    ],
    selectedPosiType: "N",
    posiTypeOptions: [
      {
        text: "Normal",
        value: "N",
      },
      {
        text: "Short",
        value: "S",
      },
    ],
    selectedSide: "A",
    sideOptions: [
      {
        text: "All Side",
        value: "A",
      },
      {
        text: "Long",
        value: "L",
      },
      {
        text: "Short",
        value: "S",
      },
    ],
    traderID: "",
    isEnableETS,
    isEnableDTS,
  }),
  watch: {
    selectedMarket(value) {
      this.rowSeriesPosition = [];

      this.selectedSymbol = null;
      this.traderID = "";
      this.selectedTTF = "0";
      this.selectedPosiType = "N";
      this.selectedSide = "A";
    },
    selectedSymbol(selectedSymbol) {
      this.filterChanged();
    },
    selectedTTF() {
      this.filterChanged();
    },
    selectedPosiType() {
      this.filterChanged();
    },
    selectedSide() {
      this.filterChanged();
    },
    traderID(traderID) {
      if (traderID.length === 0 || traderID.length === 4) {
        this.filterChanged();
      }
    },
  },
  methods: {
    onCustomerStartRefresh() {
      if (this.nightMode === "Y") {
        this.$Swal.fire("Error", "Security/Series Position does not support in After Close.", "error");
        return;
      }
      if (this.selectedMarket === "SET") {
        this.CR03();
      } else if (this.selectedMarket === "TFEX") {
        this.CR53();
      }
    },
    onEnter() {
      this.onCustomerStartRefresh();
    },
    filterChanged() {
      this.onCustomerStartRefresh();
    },
    CR03() {
      const msg = this.$messageFactory.createMessage("CR03");

      const trader = this.$_.find(this.traders, ["exchangeId", 1]);
      if (!trader?.traderCode) {
        this.$EventBus.$emit("onCustomerStopRefresh");
        this.$Swal.fire("Error", "User does not have SET account.", "error");
        return;
      }
      msg.ownTid.set(trader.traderCode);

      if (!this.selectedSymbol.symbol) {
        return;
      }
      msg.sec.set(this.selectedSymbol.symbol);
      msg.reqTid.set(this.traderID);
      msg.trustId.set(this.selectedTTF);
      msg.posiType.set(this.selectedPosiType);
      msg.recordCount.set("0");
      msg.lastRecordId.set("0");

      this.$ws.send(msg);
      if (this.gridSeriesPositionApi) {
        this.gridSeriesPositionApi.showLoadingOverlay();
      }
    },
    CR53() {
      const msg = this.$messageFactory.createMessage("CR53");

      const trader = this.$_.find(this.traders, ["exchangeId", 2]);
      if (!trader?.traderCode) {
        this.$EventBus.$emit("onCustomerStopRefresh");
        this.$Swal.fire("Error", "User does not have TFEX account.", "error");
        return;
      }
      msg.ownTid.set(trader.traderCode);

      if (!this.selectedSymbol.symbol) {
        return;
      }
      msg.sec.set(this.selectedSymbol.symbol);
      msg.reqTid.set(this.traderID);
      if (this.selectedSide === "A") {
        msg.posiType.set("");
      } else {
        msg.posiType.set(this.selectedSide);
      }
      msg.recordCount.set("0");
      msg.lastRecordId.set("0");

      this.$ws.send(msg);
      if (this.gridSeriesPositionApi) {
        this.gridSeriesPositionApi.showLoadingOverlay();
      }
    },
    updateRowSeriesPosition(seriesPosition) {
      this.rowSeriesPosition = seriesPosition.records;

      if (this.selectedMarket === "SET") {
        this.pinnedBottomRowETSSeriesPosition = [
          {
            custId: `Total: ${seriesPosition.totalAccount}`,
            shares: seriesPosition.totalShares,
            totPricePaid: seriesPosition.totalCostAmount,
            currentValue: seriesPosition.totalCurrentValue,
            unrealPL: seriesPosition.totalUnrealPL,
          },
        ];
      } else if (this.selectedMarket === "TFEX") {
        this.pinnedBottomRowDTSSeriesPosition = [
          {
            custId: `Total: ${seriesPosition.totalAccount}`,
            totPricePaid: seriesPosition.totalCostAmount,
            currentValue: seriesPosition.totalCurrentValue,
            unrealPL: seriesPosition.totalUnrealPL,
          },
        ];
      }

      if (this.gridSeriesPositionApi) {
        this.gridSeriesPositionApi.hideOverlay();
      }
      this.$EventBus.$emit("onCustomerStopRefresh");
    },
    onSeriesPositionGridReady(params) {
      this.gridSeriesPositionApi = params.api;
      this.columnSeriesPositionApi = params.columnApi;
    },
    getSeriesPositionRowNodeId(data) {
      return data.custId;
    },
    onCellSeriesPositionFocused(event) {
      this.gridSeriesPositionOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
    traders: get("model/systemRefData@traders"),
    nightMode: get("model/systemRefData@nightMode"),
  },
  created() {
    this.gridSeriesPositionOptions = {};

    this.$EventBus.$on("ctinf/CS03", this.updateRowSeriesPosition);
    this.$EventBus.$on("ctinf/CS53", this.updateRowSeriesPosition);

    this.$EventBus.$on("onCustomerStartRefresh", this.onCustomerStartRefresh);
  },
  mounted() {
    if (this.nightMode === "Y") {
      this.$Swal.fire("Error", "Security/Series Position does not support in After Close.", "error");
      return;
    }
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/CS03", this.updateRowSeriesPosition);
    this.$EventBus.$off("ctinf/CS53", this.updateRowSeriesPosition);

    this.$EventBus.$off("onCustomerStartRefresh", this.onCustomerStartRefresh);
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.ui.button.fluid.dropdown {
  background-color: #2f383f;
}

.ui.grey.header {
  color: #dcddde !important;
}

.ui.secondary.menu {
  min-height: unset;
  height: 32px !important;
  border: 0px !important;
  background-color: unset !important;
}
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.ht-news-datepicker .el-range-separator {
  padding: 0px !important;
  margin-top: -10px !important;
  color: #dcddde !important;
}

.ht-news-datepicker .el-input__icon.el-range__icon.el-icon-date {
  margin-top: -10px !important;
}

.el-picker-panel__sidebar {
  background-color: #2f383f !important;
}

.el-picker-panel__shortcut {
  color: #dcddde !important;
}

.el-picker-panel__shortcut:hover {
  color: #409eff !important;
}

.el-date-table__row > .available.in-range > div {
  background-color: #1b2025 !important;
}

.ht-series-position input {
  border: 0px !important;
}

.ht-seriesposi-dropdown {
  height: 2rem;
  min-height: 2rem;
  top: calc(50% - 1.5rem) !important;
}
.ht-seriesposi-dropdown .text {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 1rem;
  right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-seriesposi-dropdown i.dropdown {
  line-height: 0 !important;
  margin-top: 3px !important;
}
</style>
