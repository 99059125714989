import { baseStyle, stopCondFormatter, stopTypeFormatter, operatorFormatter, traillingTypeFormatter, basedOnFormatter } from "../Formatter.js";

export default [
  // Stop
  {
    headerName: "Stop Symbol",
    field: "symbol",
    pinned: "left",
    cellStyle: baseStyle
  },
  {
    headerName: "Stop Type",
    field: "stopType",
    cellStyle: baseStyle,
    valueFormatter: stopTypeFormatter
  },
  {
    headerName: "Stop Value",
    field: "stopValue",
    cellStyle: baseStyle
  },
  {
    headerName: "Ref Value",
    field: "refValue",
    cellStyle: baseStyle
  },
  {
    headerName: "Condition",
    field: "cond",
    cellStyle: baseStyle,
    valueFormatter: stopCondFormatter
  },
  {
    headerName: "Operator",
    field: "oper",
    cellStyle: baseStyle,
    valueFormatter: operatorFormatter
  },
  // Trilling
  {
    headerName: "Trailling Type",
    field: "traillingType",
    cellStyle: baseStyle,
    valueFormatter: traillingTypeFormatter
  },
  {
    headerName: "Trailling Amount",
    field: "traillingAmount",
    cellStyle: baseStyle
  },
  {
    headerName: "Based On",
    field: "basedOn",
    cellStyle: baseStyle,
    valueFormatter: basedOnFormatter
  },
  {
    headerName: "Trailling Step",
    field: "step",
    cellStyle: baseStyle
  },
  {
    headerName: "Interval Time",
    field: "interval",
    cellStyle: baseStyle
  }
];
