<template>
  <div
    class="ui input trade-report-code-input"
    :class="{ error: showError, disabled: disabled }"
    :data-tooltip="'Enter Trade Report Code'"
    :data-position="'top center'"
    data-inverted="inverted"
  >
    <input
      ref="tradeReportCodeInput"
      :value="value"
      :placeholder="'TR Code'"
      :maxlength="maxlength"
      :disabled="disabled"
      :type="'text'"
      @input="onInput"
      @keyup="onKeyUp"
      @keydown="onKeyDown"
    />
    <div class="validation-fail" v-show="showError">{{ errorMessage }}</div>
  </div>
</template>

<script setup>
import { ref, nextTick } from "vue";

const props = defineProps({
  value: { type: String, default: "" },
  disabled: { type: Boolean, value: false },
  isValidate: { type: Boolean, value: false },
});
const emits = defineEmits(["input", "enter"]);

const tradeReportCodeInput = ref(null);
const showError = ref(false);
const errorMessage = ref("Invalid Trade Report Code");

const maxlength = 2;

const onInput = (event) => {
  const inputValue = event.target.value || "";
  emits("input", inputValue.toUpperCase());

  if (props.isValidate) {
    nextTick(() => {
      validate();
    });
  }
};

const onKeyDown = (event) => {
  if (event.key === "Enter") event.preventDefault();
};

const onKeyUp = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();

    if (props.isValidate) validate();
    emits("enter");
  }
};

const validate = () => {
  errorMessage.value = "Invalid Trade Report Code";

  if (!props.value) {
    showError.value = false;
    return true;
  }

  if (props.value.length < maxlength) {
    errorMessage.value = "Trade Report Code must be 2 digits";
    showError.value = true;

    return false;
  }

  const regex = /\d\d/g;
  const result = regex.test(props.value);

  showError.value = !result;
  errorMessage.value = "Trade Report Code must be 2 digits";

  return result;
};

const select = () => {
  if (tradeReportCodeInput.value) {
    tradeReportCodeInput.value.select();
  }
};
</script>

<style lang="scss" scoped>
.trade-report-code-input {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  input {
    height: 2rem;
    padding: 0 0.25rem !important;
  }

  .validation-fail {
    color: red;
  }
}
</style>
