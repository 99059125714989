<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    value: {
      type: String
    },
    filterable: {
      type: String,
      default: "startsWith"
    },
    market: {
      type: String,
      default: "SET"
    }
  },
  data() {
    return {
      style: "width: 12rem; min-width: 12rem;",
      defaultValue: "",
      options: [
        {
          text: "-",
          value: ""
        },
        {
          text: this.market === "TFEX" ? "Long" : "Buy",
          value: this.market === "TFEX" ? "L" : "B"
        },
        {
          text: this.market === "TFEX" ? "Short" : "Sell",
          value: "S"
        }
      ]
    };
  }
};
</script>
