/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class UR03 extends WsReqMsg {
	constructor() {
		super(1);
		this._currentPin = new StringMessageBase();
		this._newPin = new StringMessageBase();
	}
	
	get currentPin() {
		return this._currentPin;
	}
	
	set currentPin(currentPin) {
		this._currentPin = new StringMessageBase(currentPin);
	}
	
	get newPin() {
		return this._newPin;
	}
	
	set newPin(newPin) {
		this._newPin = new StringMessageBase(newPin);
	}
}

export default UR03;
