import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle
} from "../Formatter.js";
import { getAccountTypeByKey } from "@/utils/AccountTypeFormatter.js";
import { getCreditTypeByKey } from "@/utils/CreditTypeFormatter.js";
import { getCustomerTypeByKey } from "@/utils/CustomerTypeFormatter.js";
import { getCustomerStatusByKey } from "@/utils/CustomerStatusFormatter.js";
import { getCustomerFlagByKey } from "@/utils/CustomerFlagFormatter.js";
import { getVIPFlagByKey } from "@/utils/VIPFlagFormatter.js";
import { getMutualFlagByKey } from "@/utils/MutualFlagFormatter.js";

const columnDefsETSCustomerList = [
  {
    headerName: "Account",
    field: "custId",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 120,
    cellStyle: () => ({ ...totalColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Cust Group ID",
    field: "custGrpId",
    width: 125,
    cellStyle: () => ({ ...totalColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Name",
    field: "name",
    width: 200,
    cellStyle: () => ({ ...qtyColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Thai Name",
    field: "thai",
    width: 200,
    cellStyle: () => ({ ...qtyColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Bought Value",
    field: "buyValue",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: () => ({ ...sideColorStyle({ value: "B" }), "justify-content": "flex-end" })
  },
  {
    headerName: "Sold Value",
    field: "sellValue",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: () => ({ ...sideColorStyle({ value: "S" }), "justify-content": "flex-end" })
  },
  {
    headerName: "Net Value",
    field: "netValue",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Account Type",
    field: "accTyp",
    width: 125,
    valueFormatter: key => getAccountTypeByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Credit Type",
    field: "crdTyp",
    width: 120,
    valueFormatter: key => getCreditTypeByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "PC",
    field: "pc",
    width: 120,
    valueFormatter: key => getCustomerTypeByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Status",
    field: "status",
    width: 80,
    valueFormatter: key => getCustomerStatusByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Customer Flag",
    field: "custFlag",
    width: 130,
    valueFormatter: key => getCustomerFlagByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "VIP Flag",
    field: "vip",
    width: 120,
    valueFormatter: key => getVIPFlagByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Confirm Flag",
    field: "cnfrm",
    width: 120,
    valueFormatter: key => (key.value === "Y" ? "Confirmed" : key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Mutual Fund",
    field: "mutalFlag",
    width: 120,
    valueFormatter: key => getMutualFlagByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Telephone",
    field: "mobile",
    width: 150,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Comm&VAT",
    field: "comm",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Total Net",
    field: "totalNet",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Brk Net Value",
    field: "brkNetValue",
    width: 130,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Total Trade",
    field: "totalTrade",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  }
];

const columnDefsDTSCustomerList = [
  {
    headerName: "Account",
    field: "custId",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 120,
    cellStyle: () => ({ ...totalColorStyle(), "justify-content": "flex-start" })
  },
  // {
  //   headerName: "Cust Group ID",
  //   field: "custGrpId",
  //   width: 125,
  //   cellStyle: () => ({ ...totalColorStyle(), "justify-content": "flex-start" })
  // },
  {
    headerName: "Name",
    field: "name",
    width: 200,
    cellStyle: () => ({ ...qtyColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Thai Name",
    field: "thai",
    width: 200,
    cellStyle: () => ({ ...qtyColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Open Value",
    field: "buyValue",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: () => ({ ...sideColorStyle({ value: "B" }), "justify-content": "flex-end" })
  },
  {
    headerName: "Close Value",
    field: "sellValue",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: () => ({ ...sideColorStyle({ value: "S" }), "justify-content": "flex-end" })
  },
  {
    headerName: "Net Value",
    field: "netValue",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Account Type",
    field: "accTyp",
    width: 125,
    valueFormatter: key => getAccountTypeByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Credit Type",
    field: "crdTyp",
    width: 150,
    valueFormatter: key => getCreditTypeByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "PC",
    field: "pc",
    width: 120,
    valueFormatter: key => getCustomerTypeByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Status",
    field: "status",
    width: 80,
    valueFormatter: key => getCustomerStatusByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Flag - F",
    field: "custFlagFuture",
    width: 120,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Flag - O",
    field: "custFlagOption",
    width: 120,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "VIP Flag",
    field: "vip",
    width: 120,
    valueFormatter: key => getVIPFlagByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Confirm Flag",
    field: "cnfrm",
    width: 120,
    valueFormatter: key => (key.value === "Y" ? "Confirmed" : key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Mutual Fund",
    field: "mutalFlag",
    width: 120,
    valueFormatter: key => getMutualFlagByKey(key.value),
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  // {
  //   headerName: "Telephone",
  //   field: "mobile",
  //   width: 150,
  //   cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  // },
  {
    headerName: "Comm&VAT",
    field: "comm",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Total Net",
    field: "totalNet",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Brk Net Value",
    field: "brkNetValue",
    width: 130,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "Total Trade",
    field: "totalTrade",
    width: 120,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  }
];

export { columnDefsETSCustomerList, columnDefsDTSCustomerList };
