/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:57
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class AR02 extends WsReqMsg {
	constructor() {
		super(1);
		this._accountCode = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
		this._symbol = new StringMessageBase();
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	set exchangeId(exchangeId) {
		this._exchangeId = new NumberMessageBase(exchangeId);
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
}

export default AR02;
