class ArrayMessageBase {
  constructor(clazz) {
    this._clazz = clazz;
    this._records = [];
  }

  new(arr) {
    if (arr) {
      let arrArray = arr.split("][");
      arrArray.forEach(record => {
        if (record.startsWith("[[")) {
          record = record.substring(2, record.length);
        }
        if (record.endsWith("]]")) {
          record = record.substring(0, record.length - 2);
        }
        let obj = new this._clazz();
        let count = 0;
        let dataArray = record.split("|");
        Object.keys(obj).forEach(key => {
          if (obj[key]) {
            obj[key].set(dataArray[count]);
          }
          ++count;
        });
        this._records.push(obj);
        ++count;
      });
    }
  }

  set(array) {
    if (array.length) {
      array.forEach(value => {
        const key = Object.keys(value)[0];
        let obj = new this._clazz();
        obj[key] = value[key];
        this._records.push(obj);
      });
    }
  }

  get() {
    return this._records;
  }

  toJsonObject() {
    let out = [];
    this._records.forEach(item => {
      let data = {};
      let keys = Object.keys(item);
      keys.forEach(key => {
        data[key.substring(1)] = item[key].get();
      });
      out.push(data);
    });
    return out;
  }
}

export default ArrayMessageBase;
