/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class UR02 extends WsReqMsg {
	constructor() {
		super(1);
		this._currentPassword = new StringMessageBase();
		this._newPassword = new StringMessageBase();
	}
	
	get currentPassword() {
		return this._currentPassword;
	}
	
	set currentPassword(currentPassword) {
		this._currentPassword = new StringMessageBase(currentPassword);
	}
	
	get newPassword() {
		return this._newPassword;
	}
	
	set newPassword(newPassword) {
		this._newPassword = new StringMessageBase(newPassword);
	}
}

export default UR02;
