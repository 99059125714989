<template>
  <div class="dropdown-item" v-show="show">
    <virtual-list
      v-if="scroller"
      ref="item"
      rtag="ul"
      :size="40"
      :remain="10"
      :style="`top: ${posY}px; left: ${posX}px;` + (width ? ` width: ${width}px !important;` : '')"
    >
      <slot :items="optionsLimit"></slot>
      <template v-if="!this.$slots.default">
        <li v-if="processing && !isSymbol" class="no-item" style="padding-left: 0.75rem !important">Loading...</li>
        <template v-else-if="!isSymbol && optionsLimit.length > 0">
          <li
            v-for="(o, index) in optionsLimit"
            :key="index"
            :data-index="index"
            :data-text="o[keyText].toLowerCase()"
            :data-val="typeof o[keyValue] === 'object' ? hash(o[keyValue]) : o[keyValue]"
            style="padding-left: 0 !important"
          >
            <button class="dropdown-item-button" :disabled="selectableCallback ? !selectableCallback(o) : false" @mousedown="onItemClick(o)">
              {{ displayFormatter ? displayFormatter(o[keyText]) : o[keyText] }}
            </button>
          </li>
        </template>

        <li v-if="processing && isSymbol" class="no-item" style="padding: 0 0.75rem !important">
          <sui-image :src="loadingImage" avatar /> Loading...
        </li>
        <template v-else-if="isSymbol && optionsLimit && optionsLimit.length > 0">
          <li
            v-for="(o, index) in optionsLimit"
            :key="index"
            v-lazy-container="{ selector: 'img' }"
            :data-text="o.symbol"
            :data-index="index"
            :data-val="hash(o.value)"
            style="padding-left: 0.75rem !important"
          >
            <button class="dropdown-item-button" :disabled="selectableCallback ? !selectableCallback(o) : false" @mousedown="onItemClick(o)">
              <div class="symbol-list-container">
                <sui-image :data-src="symbolImage(o.imageName)" :data-loading="loadingImage" :data-error="defaultImage" src="lazy" avatar />
                <div>{{ o.symbol }}</div>
              </div>
            </button>
          </li>
        </template>
        <li v-if="!freeText && (!optionsLimit || optionsLimit.length <= 0)" class="no-item" style="padding-left: 0.75rem !important">
          No {{ placeholder ? placeholder.toLowerCase() : "item" }} found.
        </li>
      </template>
    </virtual-list>
    <ul v-else ref="item" :style="`top: ${posY}px; left: ${posX}px;` + (width ? ` width: ${width}px !important;` : '')">
      <slot :items="optionsLimit"></slot>
      <template v-if="!this.$slots.default">
        <li v-if="processing && !isSymbol" class="no-item" style="padding-left: 0.75rem !important">Loading...</li>
        <template v-else-if="!isSymbol && optionsLimit.length > 0">
          <li
            v-for="(o, index) in optionsLimit"
            :key="index"
            :data-index="index"
            :data-text="o[keyText].toLowerCase()"
            :data-val="typeof o[keyValue] === 'object' ? hash(o[keyValue]) : o[keyValue]"
            style="padding: 0 !important"
          >
            <button class="dropdown-item-button" :disabled="selectableCallback ? !selectableCallback(o) : false" @mousedown="onItemClick(o)">
              {{ displayFormatter ? displayFormatter(o[keyText]) : o[keyText] }}
            </button>
          </li>
        </template>

        <li v-if="processing && isSymbol" class="no-item" style="padding: 0 0.75rem !important">
          <sui-image :src="loadingImage" avatar /> Loading...
        </li>
        <template v-else-if="isSymbol && optionsLimit && optionsLimit.length > 0">
          <li
            v-for="(o, index) in optionsLimit"
            :key="index"
            v-lazy-container="{ selector: 'img' }"
            :data-text="o.symbol"
            :data-index="index"
            :data-val="hash(o.value)"
            style="padding-left: 0.75rem !important"
          >
            <button class="dropdown-item-button" :disabled="selectableCallback ? !selectableCallback(o) : false" @mousedown="onItemClick(o)">
              <div class="symbol-list-container">
                <sui-image :data-src="symbolImage(o.imageName)" :data-loading="loadingImage" :data-error="defaultImage" src="lazy" avatar />
                <div>{{ o.symbol }}</div>
              </div>
            </button>
          </li>
        </template>
        <li v-if="!freeText && (!optionsLimit || optionsLimit.length <= 0)" class="no-item" style="padding-left: 0.75rem !important">
          No {{ placeholder ? placeholder.toLowerCase() : "item" }} found.
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import * as md5 from "md5";
import virtualList from "vue-virtual-scroll-list";

export default {
  components: {
    "virtual-list": virtualList,
  },
  data() {
    return {
      scroller: false,
      itemsLimit: 1000,
      show: false,
      processing: false,
      isSymbol: false,
      keyText: null,
      keyValue: null,
      placeholder: null,
      freeText: null,
      items: [],
      options: [],
      lastIndex: 0,
      targetRect: null,
      tempForceWidth: null,
      posY: 0,
      posX: 0,
      width: null,
      height: null,
      callbackData: null,
      imageFinder: null,
      defaultImage: null,
      loadingImage: null,
      inputId: null,
      selectableCallback: null,
      displayFormatter: null,
    };
  },
  computed: {
    itemEl: function () {
      if (this.scroller) {
        return this.$refs.item.$el;
      } else {
        return this.$refs.item;
      }
    },
    optionsLimit: function () {
      if (this.options && !this.scroller && this.options.length > this.itemsLimit) {
        return this.options.slice(0, this.itemsLimit);
      }
      return this.options;
    },
  },
  created() {
    this.$EventBus.$on("dropdown", this.onDropdown);
    this.$EventBus.$on("inputdropdown", this.onInputDropdown);
    this.$EventBus.$on("opendropdown", this.onOpenDropdown);
    this.$EventBus.$on("closedropdown", this.onCloseDropdown);
    this.$EventBus.$on("keyupdropdown", this.onKeyUp);
    this.$EventBus.$on("keydowndropdown", this.onKeyDown);
    this.$EventBus.$on("selectdropdown", this.onSelectDropdown);
    this.$EventBus.$on("processingdropdown", this.onProcessing);

    this.imageFinder = require.context(`@/assets/company-logo-compress/`, false, /(\.png|\.gif)$/);
    this.defaultImage = this.imageFinder(`./PLACEHOLDER.png`);
    this.loadingImage = this.imageFinder(`./loading.gif`);
  },
  beforeDestroy() {
    this.$EventBus.$off("dropdown", this.onDropdown);
    this.$EventBus.$off("inputdropdown", this.onInputDropdown);
    this.$EventBus.$off("opendropdown", this.onOpenDropdown);
    this.$EventBus.$off("closedropdown", this.onCloseDropdown);
    this.$EventBus.$off("toggledropdown", this.onToggleDropdown);
    this.$EventBus.$off("keyupdropdown", this.onKeyUp);
    this.$EventBus.$off("keydowndropdown", this.onKeyDown);
    this.$EventBus.$off("selectdropdown", this.onSelectDropdown);
    this.$EventBus.$off("processingdropdown", this.onProcessing);
  },
  methods: {
    hash(obj) {
      return md5(JSON.stringify(obj));
    },
    symbolImage(name) {
      if (name) {
        try {
          return this.imageFinder(`./${name}.png`);
        } catch (err) {
          return this.defaultValue;
        }
      }
      return this.defaultValue;
    },
    calcPosition() {
      const x = this.targetRect.top + this.targetRect.height + 200;
      if (x > window.innerHeight) {
        this.$nextTick(() => {
          this.posY = this.targetRect.top - 200;
          this.$nextTick(() => {
            const height = this.itemEl.getBoundingClientRect().height;
            if (height < 200) {
              this.posY = this.targetRect.top - height;
            }
          });
        });
      } else {
        this.posY = this.targetRect.top + this.targetRect.height;
      }
    },

    onDropdown(event) {
      console.log("test event", event);
      if (this.inputId === event.inputId && !event.forceUpdateOptions) {
        this.selectableCallback = event.selectableCallback || null;

        if (this.targetRect.top !== event.rect.top || this.targetRect.left !== event.rect.left) {
          this.updatePosition(event.rect, event.forceWidth);
          this.calcPosition();
        }
        return;
      }
      if (this.inputId !== event.inputId) {
        this.$EventBus.$emit("ondropdownchange", event.inputId);
        this.show = false;
      }
      this.items = Object.assign([], event.items);
      this.options = Object.assign([], event.items);
      this.callbackData = event.callbackData;
      this.inputId = event.inputId;
      this.placeholder = event.placeholder;
      this.freeText = event.freeText;
      this.keyText = event.keyText;
      this.keyValue = event.keyValue;
      this.tempForceWidth = event.forceWidth;
      this.updatePosition(event.rect, event.forceWidth);
      this.isSymbol = event.isSymbol;
      this.scroller = event.scroller;
      this.selectableCallback = event.selectableCallback || null;
      this.displayFormatter = event.displayFormatter || null;

      if (!this.scroller) {
        if (this.isSymbol) {
          this.itemsLimit = 100;
        } else {
          this.itemsLimit = 1000;
        }
      }

      this.$nextTick(() => {
        this.calcPosition();
      });
    },
    updatePosition(rect, forceWidth) {
      if (forceWidth) {
        this.width = forceWidth;
      } else {
        if (rect.width > this.$remToPx(12)) {
          this.width = rect.width;
        } else {
          this.width = null;
        }
      }
      this.posX = rect.left;
      this.posY = rect.top - 200;
      this.targetRect = rect;
    },
    onInputDropdown(text, filterable) {
      if (!this.show) {
        const activeEl = this.itemEl.querySelector("li.active");
        if (activeEl) {
          activeEl.classList.remove("active");
        }
      }
      this.show = true;
      this.processing = true;
      if (filterable) {
        if (text) {
          this.options = this.$_.filter(this.items, (o) => {
            return o[this.keyText].toLowerCase()[filterable](text.toLowerCase());
          });
        } else {
          this.show = false;
          this.processing = false;
          return;
        }
        this.$nextTick(() => {
          setTimeout(() => {
            const currentEl = this.itemEl.querySelector("li:first-child");
            if (currentEl) {
              currentEl.classList.add("active");
            }
            this.itemEl.scrollTo(0, 0);
          }, 1);
        });
      } else {
        setTimeout(() => {
          const found = this.itemEl.querySelector(`li[data-text*="${this.displayValue.toLowerCase()}"]`);
          if (found) {
            found.classList.add("active");
          }
          this.itemEl.scrollTo(0, found.scrollHeight);
        }, 1);
      }
      this.$nextTick(() => {
        this.calcPosition();
        this.processing = false;
      });
    },
    onProcessing(inputId, processing, newOptions) {
      this.processing = processing;
      if (this.inputId === inputId) {
        if (newOptions) {
          this.items = Object.assign([], newOptions);
          this.options = Object.assign([], newOptions);
          if (this.targetRect) {
            this.updatePosition(this.targetRect, this.tempForceWidth);
            this.$nextTick(() => {
              this.calcPosition();
            });
          }
        }
      }
    },
    onSelectDropdown(oldSelectedValue) {
      if (!this.callbackData) {
        return;
      }
      if (!this.freeText && (!this.optionsLimit || this.optionsLimit.length <= 0)) {
        this.callbackData(undefined, false);
        return;
      }
      let data = oldSelectedValue;
      if (this.show) {
        const currentEl = this.itemEl.querySelector("li.active");
        if (currentEl) {
          data = currentEl.getAttribute("data-val");
        }
      }
      this.callbackData(data, false);
    },
    onOpenDropdown(rect, forceWidth) {
      if (forceWidth) {
        this.width = forceWidth;
      } else {
        if (rect.width > this.$remToPx(12)) {
          this.width = rect.width;
        } else {
          this.width = null;
        }
      }
      this.posX = rect.left;
      this.posY = rect.top - 200;
      this.targetRect = rect;
      this.calcPosition();

      this.show = true;
      this.options = Object.assign([], this.items);
      this.$nextTick(() => {
        this.calcPosition();
        const activeEl = this.itemEl.querySelector("li.active");
        if (activeEl) {
          activeEl.classList.remove("active");
        }
        this.itemEl.scrollTo(0, 0);
        setTimeout(() => {
          const fristChild = this.itemEl.querySelector("li:first-child");
          fristChild.classList.add("active");
        }, 1);
      });
    },
    onCloseDropdown() {
      this.show = false;
    },
    onToggleDropdown() {
      this.show = !this.show;
    },
    onItemClick(data) {
      if (!this.callbackData) {
        return;
      }
      this.callbackData(data, true);
    },
    onKeyUp() {
      const currentEl = this.itemEl.querySelector("li.active");
      if (currentEl) {
        const prevEl = currentEl.previousSibling;
        if (prevEl) {
          try {
            const prevIndex = prevEl.getAttribute("data-index");

            currentEl.classList.remove("active");
            prevEl.classList.add("active");

            const x = prevEl.offsetTop - this.itemEl.scrollTop;
            if (x < 0) {
              this.itemEl.scrollTo(0, prevEl.offsetTop);
            }

            const firstEl = this.itemEl.querySelector("li:first-child");
            const firstIndex = firstEl.getAttribute("data-index");

            if (firstIndex === prevIndex && this.lastIndex > 0) {
              this.prevScroll();
            }
          } catch (err) {
            // passed away
          }
        }
      }
    },
    onKeyDown() {
      const uiHeight = this.itemEl.getBoundingClientRect().height;
      const currentEl = this.itemEl.querySelector("li.active");
      if (currentEl) {
        const nextEl = currentEl.nextSibling;
        if (nextEl) {
          try {
            const nextIndex = nextEl.getAttribute("data-index");
            currentEl.classList.remove("active");
            nextEl.classList.add("active");

            const x = nextEl.offsetTop + nextEl.getBoundingClientRect().height - this.itemEl.scrollTop;
            if (x > uiHeight) {
              this.itemEl.scrollTo(0, nextEl.offsetTop - uiHeight + nextEl.getBoundingClientRect().height);
            }

            const lastEl = this.itemEl.querySelector("li:last-child");
            const lastIndex = lastEl.getAttribute("data-index");

            if (lastIndex === nextIndex) {
              this.nextScroll();
            }
          } catch (err) {
            // passed away
          }
        }
      } else {
        const firstEl = this.itemEl.querySelector("li:first-child");
        firstEl.classList.add("active");
      }
    },
    prevScroll() {
      let startIndex = this.lastIndex - this.options.length;
      if (startIndex < 0) {
        startIndex = 0;
      }
      const endIndex = this.lastIndex;
      this.lastIndex = startIndex;
      this.options = this.items.slice(startIndex, endIndex);
      this.$nextTick(() => {
        this.itemEl.scrollTo(0, this.itemEl.scrollHeight);
        setTimeout(() => {
          const currentEl = this.itemEl.querySelector("li:last-child");
          currentEl.classList.add("active");
        }, 1);
      });
    },
    nextScroll() {
      const lastChild = this.itemEl.querySelector("li:last-child");
      let index = lastChild.getAttribute("data-index");
      if (index) {
        index = parseInt(index);
        let startIndex = this.lastIndex + index + 1;
        if (this.options.length > startIndex) {
          this.lastIndex += index;
          let endIndex = startIndex + this.options.length;
          if (this.options.length < endIndex) {
            endIndex = this.options.length;
          }
          if (endIndex - startIndex < 10) {
            startIndex -= 6;
          }
          this.filterOptions = this.options.slice(startIndex, endIndex);
          this.$nextTick(() => {
            this.itemEl.scrollTo(0, 0);
            setTimeout(() => {
              const currentEl = this.itemEl.querySelector("li:first-child");
              currentEl.classList.add("active");
            }, 1);
          });
          return;
        }
      }
    },
  },
};
</script>

<style scoped>
li.active {
  background: #1b2025;
}

ul {
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 100000;
  min-width: 12rem;
  width: 12rem;
  max-height: 200px;
  padding: 0;
  margin: 0;
  background: #262d33;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  overflow-y: auto;
}

li {
  transition-duration: 0.5s;
  padding: 0 2px !important;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #dcddde;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

li:hover {
  cursor: pointer;
  /* background: #1b2025; */
}

ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  width: 100%;
}

li.no-item,
li.no-item:hover {
  cursor: default;
  background: inherit;
}

.dropdown-item {
  position: absolute;
}

.dropdown-item .dropdown-item-button {
  width: 100%;
  height: 100%;
  text-align: start;
  color: #dcddde;
  background-color: inherit;
  border: 1px solid transparent;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.dropdown-item .dropdown-item-button:enabled:hover {
  background: #1b2025;
  cursor: pointer;
}

.dropdown-item .dropdown-item-button:disabled {
  background: #2d363e;
  color: #4f585e;
}

.dropdown-item .dropdown-item-button .symbol-list-container {
  display: flex;
  align-items: center;
  column-gap: 0.2rem;
}
</style>
