<template>
  <div class="quote-news">
    <div class="ui grid" :style="{ height: '100%' }">
      <div class="eight wide column" :style="{ height: '100%' }">
        <ag-grid-vue
          style="width: 100%; height: 100%; padding: 0px"
          :class="{
            'ag-theme-balham-dark': theme === 'Dark',
            'ag-theme-balham': theme === 'Light',
          }"
          row-selection="single"
          :grid-options="gridQuoteNewsOptions"
          :column-defs="columnDefsQuoteNews"
          :row-data="rowQuoteNews"
          :get-row-node-id="getQuoteNewsRowNodeId"
          :row-height="21"
          :header-height="32"
          :default-col-def="{ sortable: true, resizable: true }"
          :animate-rows="true"
          :suppress-drag-leave-hides-columns="true"
          :enable-cell-change-flash="true"
          :locale-text="{ noRowsToShow: `Don't have any News.` }"
          :row-drag-managed="false"
          overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
          @grid-ready="onQuoteNewsGridReady"
          @cell-focused="onCellQuoteNewsFocused"
        />
      </div>
      <div class="eight wide column">
        <div class="ui grid">
          <div class="sixteen wide column" style="height: 32px; background-color: #1b2025">
            <h5 class="ui grey header inverted" style="padding: 8px">{{ newsHeadline }}</h5>
          </div>
          <div class="sixteen wide column" style="height: calc(55vh - 54px - 139px - 20px); overflow: hidden">
            <div v-if="!newsContent && reportFileExtension === '.zip'" style="padding: 0.5rem">
              <a :href="reportFileLink" target="_blank">
                <sui-button animated="vertical" color="blue" style="width: 7rem">
                  <sui-button-content hidden>Download</sui-button-content>
                  <sui-button-content visible>
                    <sui-icon name="cloud download" />
                  </sui-button-content>
                </sui-button>
              </a>
              <span style="padding-left: 0.5rem" class="ui grey tiny header inverted">File : {{ reportFileName }}.zip</span>
            </div>
            <iframe
              v-if="!newsContent && reportFileExtension === '.pdf'"
              :src="reportFileLink"
              style="margin: 0px; calc(55vh - 54px - 137px - 20px);"
              title="pdf-news"
            />
            <div v-if="newsContent" style="height: calc(55vh - 54px - 139px - 20px); overflow: auto">
              <h5 class="ui grey header inverted" style="font-family: 'Courier New', Courier, monospace" v-html="newsContent" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsQuoteNews } from "@/components/grid/quote/QuoteNewsColumnDefs.js";

Object.freeze(columnDefsQuoteNews);

export default {
  name: "QuoteNews",
  components: {
    AgGridVue,
  },
  data: () => ({
    columnDefsQuoteNews,

    gridQuoteNewsApi: null,
    columnQuoteNewsApi: null,
    rowQuoteNews: [],
    newsHeadline: "",
    newsContent: "",
    reportFileName: "",
    reportFileType: "",
    reportFileExtension: "",
    reportFileLink: "",
  }),
  watch: {
    currentQuoteSelectedSymbol() {
      this.NR01();
    },
  },
  created() {
    this.gridQuoteNewsOptions = {};
    this.NR01();

    this.$EventBus.$on("ctinf/NS01", this.newsListCinf);
    this.$EventBus.$on("ctinf/NS02", this.newsContentCinf);

    this.$EventBus.$on("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/NS01", this.newsListCinf);
    this.$EventBus.$off("ctinf/NS02", this.newsContentCinf);

    this.$EventBus.$off("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);
  },
  methods: {
    onQuoteTableStartRefresh() {
      this.NR01();
    },
    NR01() {
      if (!this.currentQuoteSelectedSymbol) {
        this.$EventBus.$emit("onQuoteTableStopRefresh");
        return;
      }

      this.newsHeadline = "";
      this.newsContent = "";
      this.reportFileName = "";
      this.reportFileType = "";
      this.reportFileExtension = "";
      this.reportFileLink = "";

      const msg = this.$messageFactory.createMessage("NR01");

      if (this.currentQuoteSelectedSymbol) {
        msg.symbol.set(this.currentQuoteSelectedSymbol.value.symbol);
      }
      msg.fromDate.set(this.$moment().startOf("year").format("DD/MM/YYYY"));
      msg.toDate.set(this.$moment().format("DD/MM/YYYY"));

      this.$ws.send(msg);
      if (this.gridQuoteNewsApi) {
        this.gridQuoteNewsApi.showLoadingOverlay();
      }
    },
    NR02(newsId) {
      const msg = this.$messageFactory.createMessage("NR02");

      msg.newsId.set(newsId);

      this.$ws.send(msg);
    },
    updateRowQuoteNews(news) {
      this.rowQuoteNews = news.newsList;
    },
    onQuoteNewsGridReady(params) {
      this.gridQuoteNewsApi = params.api;
      this.columnQuoteNewsApi = params.columnApi;
    },
    getQuoteNewsRowNodeId(data) {
      return data.newsId;
    },
    onCellQuoteNewsFocused(event) {
      this.gridQuoteNewsOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          this.newsHeadline = node.data.headline;
          this.reportFileName = node.data.reportFileName;
          this.reportFileType = node.data.reportFileType;

          let fileExtension = ".pdf";

          if (["2", "3", "6", "7"].includes(node.data.reportFileType)) {
            this.reportFileExtension = fileExtension;
          }
          if (["4", "5", "6", "7"].includes(node.data.reportFileType)) {
            fileExtension = ".zip";
            this.reportFileExtension = fileExtension;
          }

          let linkDate = node.data.datetime.split(" ")[0];
          linkDate = this.$moment(linkDate, "DD/MM/YYYY").format("YYYYMMDD");

          this.reportFileLink = `${this.newsServerUrl}/${linkDate}/${node.data.reportFileName + fileExtension}`;
          this.NR02(node.data.newsId);
          return;
        }
      });
    },
    newsListCinf(news) {
      this.updateRowQuoteNews(news);
      if (this.gridQuoteNewsApi) {
        this.gridQuoteNewsApi.hideOverlay();
      }
      this.$EventBus.$emit("onQuoteTableStopRefresh");
    },
    newsContentCinf(news) {
      this.newsContent = news.newsContent.split(" ").join("&nbsp;").split("\r\n").join("<br />");
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
    newsServerUrl: get("model/systemRefData@newsServerUrl"),
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.quote-news {
  height: 100%;
}
</style>
