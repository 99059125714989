<template>
  <div class="ui equal width grid cust-by-stock-view">
    <div class="row content-container">
      <div class="four wide column left-side-section">
        <div class="row quote-info-container">
          <sui-tab class="tab-container" :menu="{ attached: 'top' }">
            <sui-tab-pane title="Quote Info" attached="top" class="quote-info-tab">
              <QuoteInfoDetail :market="marketType" />
            </sui-tab-pane>
            <div class="refresh-icon-container">
              <i class="refresh alternate icon" :class="{ loading: isLoading }" @click="onRefresh" />
            </div>
          </sui-tab>
        </div>
        <div class="row market-stock-ticker-container">
          <MarketStockTicker />
        </div>
      </div>
      <div class="column cust-by-stock-table-container">
        <sui-tab :menu="{ attached: 'top' }">
          <sui-tab-pane title="Cust. By Stock" attached="top">
            <CustByStockTable />
          </sui-tab-pane>
          <div class="refresh-icon-container">
            <i class="refresh alternate icon" :class="{ loading: isLoading }" @click="onRefresh" />
          </div>
        </sui-tab>
      </div>
    </div>
  </div>
</template>

<script setup>
import useCustByStockView from "./hooks/useCustByStockView";

import QuoteInfoDetail from "@/components/quote/QuoteInfoDetail";
import MarketStockTicker from "@/components/market/MarketStockTicker";
import CustByStockTable from "@/components/custbystock/CustByStockTable";

const { isLoading, marketType, onRefresh } = useCustByStockView();
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/cust-by-stock/cust-by-stock-view.scss";
</style>
