/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class IR03 extends WsReqMsg {
	constructor() {
		super(1);
		this._marketId = new StringMessageBase();
		this._indexId = new StringMessageBase();
	}
	
	get marketId() {
		return this._marketId;
	}
	
	set marketId(marketId) {
		this._marketId = new StringMessageBase(marketId);
	}
	
	get indexId() {
		return this._indexId;
	}
	
	set indexId(indexId) {
		this._indexId = new StringMessageBase(indexId);
	}
}

export default IR03;
