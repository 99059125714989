import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  priceColorStyle,
} from "../Formatter.js";

const columnDefsQuoteSaleByTime = [
  {
    headerName: "Time",
    field: "time",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 100,
    cellStyle: (params) => ({ ...totalColorStyle(params), "justify-content": "flex-start" }),
  },
  {
    headerName: "Side",
    field: "side",
    width: 80,
    cellStyle: sideColorStyle,
  },
  {
    headerName: "Volume",
    field: "volume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Price",
    field: "price",
    width: 120,
    cellStyle: priceColorStyle,
  },
];

export { columnDefsQuoteSaleByTime };
