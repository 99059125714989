/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class NR01 extends WsReqMsg {
	constructor() {
		super(1);
		this._symbol = new StringMessageBase();
		this._fromDate = new StringMessageBase();
		this._toDate = new StringMessageBase();
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get fromDate() {
		return this._fromDate;
	}
	
	set fromDate(fromDate) {
		this._fromDate = new StringMessageBase(fromDate);
	}
	
	get toDate() {
		return this._toDate;
	}
	
	set toDate(toDate) {
		this._toDate = new StringMessageBase(toDate);
	}
}

export default NR01;
