import numeral from "numeral";
import store from "@/store";
import { find as _find } from "lodash";
import moment from "moment";

export const GREEN_COLOR = "#00aa00";
export const YELLOW_COLOR = "#e4af0a";
export const RED_COLOR = "#d91e18";
export const BLUE_COLOR = "#2185d0";
export const PINK_COLOR = "#e03997";
export const VIOLET_COLOR = "#a332c8";
export const GREY_COLOR = "#838383";

const NO_VALUE = "-";

const priceColor = (params) => {
  switch (params.data.changeFlag) {
    case "U" /* Up */:
      return GREEN_COLOR;
    case "C" /* Ceiling */:
      return BLUE_COLOR;
    case "D" /* Down */:
      return RED_COLOR;
    case "F" /* Floor */:
      return PINK_COLOR;
    case "N" /* No Change */:
      return YELLOW_COLOR;
    default:
      return YELLOW_COLOR;
  }
};

const symbolColorStyle = (params) => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-start",
    ...baseStyle,
  };
  return { ...customStyle, color: priceColor(params) };
};

const priceColorStyle = (params) => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };
  if (params.value === "Total") {
    return customStyle;
  }
  return { ...customStyle, color: priceColor(params) };
};

const qtyColorStyle = () => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };
  return { ...customStyle, color: YELLOW_COLOR };
};

const ceilColorStyle = () => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };
  return { ...customStyle, color: BLUE_COLOR };
};

const floorColorStyle = () => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };
  return { ...customStyle, color: PINK_COLOR };
};

const comparePriceColorStyle = (params) => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };

  if (numeral(params.data.prior).value() === 0 || params.value === NO_VALUE) {
    return { ...customStyle, color: "unset" };
  } else if (numeral(params.value).value() >= numeral(params.data.ceiling).value()) {
    return { ...customStyle, color: BLUE_COLOR };
  } else if (numeral(params.value).value() > numeral(params.data.prior).value()) {
    return { ...customStyle, color: GREEN_COLOR };
  } else if (numeral(params.value).value() <= numeral(params.data.floor).value()) {
    return { ...customStyle, color: PINK_COLOR };
  } else if (numeral(params.value).value() < numeral(params.data.prior).value()) {
    return { ...customStyle, color: RED_COLOR };
  } else {
    return { ...customStyle, color: YELLOW_COLOR };
  }
};

const comparePriceNoCeilingFloorColorStyle = (params) => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };

  if (numeral(params.data.prior).value() === 0 || params.value === NO_VALUE) {
    return { ...customStyle, color: "unset" };
  } else if (numeral(params.value).value() > numeral(params.data.prior).value()) {
    return { ...customStyle, color: GREEN_COLOR };
  } else if (numeral(params.value).value() < numeral(params.data.prior).value()) {
    return { ...customStyle, color: RED_COLOR };
  } else {
    return { ...customStyle, color: YELLOW_COLOR };
  }
};

const bidOfferPriceColorStyle = (params) => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };
  if (numeral(params.value).value() === 0) {
    return { ...customStyle, color: "unset" };
  } else if (numeral(params.value).value() === numeral(params.data.ceiling).value()) {
    return { ...customStyle, color: BLUE_COLOR };
  } else if (numeral(params.value).value() > numeral(params.data.prior).value()) {
    return { ...customStyle, color: GREEN_COLOR };
  } else if (numeral(params.value).value() === numeral(params.data.floor).value()) {
    return { ...customStyle, color: PINK_COLOR };
  } else if (numeral(params.value).value() < numeral(params.data.prior).value()) {
    return { ...customStyle, color: RED_COLOR };
  } else {
    return { ...customStyle, color: YELLOW_COLOR };
  }
};

const totalColorStyle = () => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };
  return { ...customStyle, color: "unset" };
};

const baseStyle = {
  "font-weight": "700",
  "font-family": "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
  "font-size": "1rem",
};

export const sideColorStyle = (params) => {
  let color = "";
  if (params.value === "B" || params.value === "L") {
    color = GREEN_COLOR;
  } else if (params.value === "S") {
    color = RED_COLOR;
  } else if (params.value === "H") {
    color = VIOLET_COLOR;
  } else if (params.value === "C") {
    color = BLUE_COLOR;
  }
  return { ...baseStyle, color: color };
};

export const valueSideColorStyle = (params) => {
  let color = "";
  if (params.value === "B" || params.value === "L") {
    color = GREEN_COLOR;
  } else if (params.value === "S") {
    color = RED_COLOR;
  } else if (params.value === "H") {
    color = VIOLET_COLOR;
  } else if (params.value === "C") {
    color = BLUE_COLOR;
  }
  return { ...baseStyle, "justify-content": "flex-end", color: color };
};

export const numeralCellFormatter = (params, format, suffix = "") => {
  if (params.value === "") {
    return "";
  }

  return numeral(params.value).format(format) + suffix;
};

export const unrealizedCellFormatter = (value, format, percentage = false) => {
  if (percentage) {
    value = numeral(value).value() / 100;
  }

  if (numeral(value).value() === 0) {
    return "0.00";
  } else {
    return numeral(value).format(format);
  }
};

export const positionFormatter = (params) => {
  switch (params.value) {
    case "O":
      return "Open";
    case "C":
      return "Close";
    default: {
      return "";
    }
  }
};

export const statusFormatter = (params) => {
  let status = params.value;
  if (!status) return status;

  if (params.data.specialOrderType === "T" || params.data.specialOrderType === "S") {
    if (status == "A") {
      status += params.data.specialOrderType;
      if (params.data.inActivate == "Y") {
        status += "*";
      }
    }
  }

  if (params.data.approved === "A") {
    status += "A";
  }

  return status;
};

export const sideFormatter = (params, market) => {
  if (market === "SET") {
    switch (params.value) {
      case "B":
        return "Buy";
      case "S":
        return "Sell";
      case "C":
        return "Cover";
      case "H":
        return "Short";
      default: {
        return "";
      }
    }
  } else if (market === "TFEX") {
    switch (params.value) {
      case "L":
        return "Long";
      case "S":
        return "Short";
      default: {
        return "";
      }
    }
  }
  return "";
};

export const informationSideFormatter = (params) => {
  switch (params.value) {
    case "B":
      return "Bought";
    case "S":
      return "Sold";
    case "T":
      return "Total";
    default: {
      return params.value;
    }
  }
};

export const stopCondFormatter = (params) => {
  let cond = params.value;
  if (!cond) return cond;

  if (cond == "1") {
    cond = "Bid >= Stop value";
  } else if (cond == "2") {
    cond = "Bid <= Stop value";
  } else if (cond == "3") {
    cond = "Offer >= Stop value";
  } else if (cond == "4") {
    cond = "Offer <= Stop value";
  } else if (cond == "5") {
    cond = "Last >= Stop value";
  } else if (cond == "6") {
    cond = "Last <= Stop value";
  } else if (cond == "7") {
    cond = "High >= Stop value";
  } else if (cond == "8") {
    cond = "High <= Stop value";
  } else if (cond == "9") {
    cond = "Low >= Stop value";
  } else if (cond == "10") {
    cond = "Low <= Stop value";
  } else if (cond == "11") {
    cond = "Avg >= Stop value";
  } else if (cond == "12") {
    cond = "Avg <= Stop value";
  } else if (cond == "13") {
    cond = "Index >= Stop value";
  } else if (cond == "14") {
    cond = "Index <= Stop value";
  }
  return cond;
};

export const stopTypeFormatter = (params) => {
  let type = params.value;
  if (!type) return type;

  if (type == "1") {
    type = "Price";
  } else if (type == "2") {
    type = "Percentage";
  } else if (type == "3") {
    type = "Prior Price";
  } else if (type == "4") {
    type = "Prior Percentage";
  }
  return type;
};

export const operatorFormatter = (params) => {
  let operator = params.value;
  if (!operator) return operator;

  if (operator == "1") {
    operator = "AND";
  } else if (operator == "2") {
    operator = "OR";
  }
  return operator;
};

export const traillingTypeFormatter = (params) => {
  let operator = params.value;
  if (!operator) return operator;

  if (operator == "S") {
    operator = "Spread";
  } else if (operator == "P") {
    operator = "Percentage";
  }
  return operator;
};

export const basedOnFormatter = (params) => {
  let basedOn = params.value;
  if (!basedOn) return basedOn;

  if (basedOn == "1") {
    basedOn = "Last Sale";
  } else if (basedOn == "2") {
    basedOn = "Best Bid";
  } else if (basedOn == "3") {
    basedOn = "Best Offer";
  }
  return basedOn;
};

export const auditCodeFormatter = (params) => {
  switch (params.value) {
    case "A":
      return "Approve";
    case "C":
      return "Change";
    case "X":
      return "Cancel";
    case "R":
      return "Reject";
    case "D":
      return "Disapprove";
    default:
      return params.value;
  }
};

export const boardFormatter = (params) => {
  switch (params.value) {
    case "M":
      return "Main Board";
    case "O":
      return "Odd Lot Board";
    case "F":
      return "Foreign Board";
    case "B":
      return "Big Lot Board";
    default:
      return params.value;
  }
};

export const conditionFormatter = (params) => {
  switch (params.value) {
    case "F":
      return "FOK";
    case "I":
      return "IOC";
    case "O":
      return ""; // odd lot
    default:
      return params.value;
  }
};

export const orderStatusFormatter = (params) => {
  let status = statusFormatter({
    value: params.value,
    data: params.data,
  });
  let cStatus = "";
  if (status) cStatus = status.charAt(0);
  switch (cStatus) {
    case "O":
      return "Open";
    case "M":
      return "Matched";
    case "X":
      return "Cancelled";
    case "C":
      return "SET Cancelled";
    case "P":
      return "Pending";
    case "R":
      return "Rejected";
    case "A":
      if (status.length == 1) {
        return "Waiting Approval";
      } else {
        return "Waiting Trigger";
      }
    case "D":
      return "Disapproved";
    case "T":
      return "Split";
  }
  return status;
};

const unrealizedColorStyle = (params) => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };

  if (numeral(params.data.unrealized).value() === 0 || params.value === NO_VALUE) {
    return { ...customStyle, color: YELLOW_COLOR };
  } else if (numeral(params.data.unrealized).value() > 0) {
    return { ...customStyle, color: GREEN_COLOR };
  } else if (numeral(params.data.unrealized).value() < 0) {
    return { ...customStyle, color: RED_COLOR };
  } else {
    return { ...customStyle, color: YELLOW_COLOR };
  }
};

const unrealPlColorStyle = (params) => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };

  if (numeral(params.data.unrealPl).value() === 0 || params.value === NO_VALUE) {
    return { ...customStyle, color: YELLOW_COLOR };
  } else if (numeral(params.data.unrealPl).value() > 0) {
    return { ...customStyle, color: GREEN_COLOR };
  } else if (numeral(params.data.unrealPl).value() < 0) {
    return { ...customStyle, color: RED_COLOR };
  } else {
    return { ...customStyle, color: YELLOW_COLOR };
  }
};

const realizedColorStyle = (params) => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };

  if (numeral(params.data.realized).value() === 0 || params.value === NO_VALUE) {
    return { ...customStyle, color: YELLOW_COLOR };
  } else if (numeral(params.data.realized).value() > 0) {
    return { ...customStyle, color: GREEN_COLOR };
  } else if (numeral(params.data.realized).value() < 0) {
    return { ...customStyle, color: RED_COLOR };
  } else {
    return { ...customStyle, color: YELLOW_COLOR };
  }
};

const valueColorStyle = (params) => {
  const customStyle = {
    display: "flex",
    "align-items": "center",
    "justify-content": "flex-end",
    ...baseStyle,
  };

  if (numeral(params.value).value() === 0 || params.value === NO_VALUE) {
    return { ...customStyle, color: YELLOW_COLOR };
  } else if (numeral(params.value).value() > 0) {
    return { ...customStyle, color: GREEN_COLOR };
  } else if (numeral(params.value).value() < 0) {
    return { ...customStyle, color: RED_COLOR };
  } else {
    return { ...customStyle, color: YELLOW_COLOR };
  }
};

export const ttfFormatter = (params) => {
  switch (params.value) {
    case "1":
    case 1:
      return "TTF";
    case "2":
    case 2:
      return "NVDR";
    default:
      return params.value;
  }
};

export const symbolToSecurityId = (market, symbol) => {
  let secList = [];
  if (market === "SET") {
    secList = store.get("model/systemRefData@securities");
  } else if (market === "TFEX") {
    secList = store.get("model/systemRefData@dtsSecurities");
  }

  const found = _find(secList, (s) => {
    return s.symbol == symbol;
  });

  if (found) {
    return found.securityId;
  }

  return null;
};

const validityDataFormatter = (params) => {
  const validity = params.data?.validity;

  if (!validity) return "";

  if (params.data.exchangeId == 2) {
    return params.data.validityData;
  }

  switch (validity) {
    case "DATE":
      return moment(params.data.validityData, "YYMMDD").format("DD/MM/YYYY");
    case "D":
      return moment(params.data.validityData, "YYMMDD").format("DD/MM/YYYY");
    default:
      return params.data.validityData;
  }
};

const validityFormatter = (params) => {
  const validity = params.data?.validity;

  switch (validity) {
    case "":
      return "DAY";
    case "D":
      return "DATE";
    case "S":
      return "SESS";
    case "C":
      return "GTC";
    default:
      return validity;
  }
};
const openColorStyle = () => {
  return qtyColorStyle();
};

export {
  NO_VALUE,
  baseStyle,
  symbolColorStyle,
  priceColorStyle,
  qtyColorStyle,
  ceilColorStyle,
  floorColorStyle,
  comparePriceColorStyle,
  comparePriceNoCeilingFloorColorStyle,
  bidOfferPriceColorStyle,
  totalColorStyle,
  unrealizedColorStyle,
  realizedColorStyle,
  unrealPlColorStyle,
  valueColorStyle,
  openColorStyle,
  validityDataFormatter,
  validityFormatter,
};
