<template>
  <table class="tg" style="table-layout: fixed; width: 100%">
    <colgroup>
      <col style="width: 60%" />
      <col style="width: 40%" />
    </colgroup>
    <tr>
      <td class="tg-s6z2" rowspan="2">
        <h2 ref="securityLastPrice" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.last)]">
          {{ $numeral(securityInfoLatest.last).format("0,0.00") }}
        </h2>
      </td>
      <td class="tg-s6z3">
        <h4
          v-if="securityInfoLatest.exchangeId === 2"
          ref="securityChange"
          class="ui header"
          :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.last)]"
        >
          {{
            ($numeral(securityInfoLatest.last).value() > $numeral(securityInfoLatest.prevSettlePrice).value() ? "+" : "") +
            $numeral(securityInfoLatest.change).format("0,0.00")
          }}
        </h4>
        <h4 v-else ref="securityChange" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.last)]">
          {{
            ($numeral(securityInfoLatest.last).value() > $numeral(securityInfoLatest.prior).value() ? "+" : "") +
            $numeral(securityInfoLatest.change).format("0,0.00")
          }}
        </h4>
      </td>
    </tr>
    <tr>
      <td class="tg-s6z3">
        <h4
          v-if="securityInfoLatest.exchangeId === 2"
          ref="securityPercentChange"
          class="ui header"
          :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.last)]"
        >
          {{
            ($numeral(securityInfoLatest.last).value() > $numeral(securityInfoLatest.prevSettlePrice).value() ? "+" : "") +
            $numeral(securityInfoLatest.changePercent).format("0,0.00") +
            "%"
          }}
        </h4>
        <h4 v-else ref="securityPercentChange" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.last)]">
          {{
            ($numeral(securityInfoLatest.last).value() > $numeral(securityInfoLatest.prior).value() ? "+" : "") +
            $numeral(securityInfoLatest.changePercent).format("0,0.00") +
            "%"
          }}
        </h4>
      </td>
    </tr>
  </table>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  name: "QuoteLastsale",
  components: {},
  props: ["currentQuoteSelectedSymbol"],
  data: () => ({
    securityInfoLatest: {},
  }),
  watch: {
    currentQuoteSelectedSymbol(currentQuoteSelectedSymbol) {
      if (currentQuoteSelectedSymbol === null) {
        this.securityInfoLatest = {};
      }
    },
  },
  created() {
    this.$EventBus.$on("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$on("ctinf/SS51", this.dtsSecurityInfoUpdateCinf);
    this.$EventBus.$on("bcst/PB01", this.updateSecurityInfo);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$off("ctinf/SS51", this.dtsSecurityInfoUpdateCinf);
    this.$EventBus.$off("bcst/PB01", this.updateSecurityInfo);
  },
  methods: {
    getClassColorCompare(securityInfoLatest, comparison) {
      const ceiling = this.$numeral(securityInfoLatest.ceiling).value();
      const floor = this.$numeral(securityInfoLatest.floor).value();

      let prior = this.$numeral(securityInfoLatest.prior).value();
      if (securityInfoLatest.exchangeId === 2) {
        prior = this.$numeral(securityInfoLatest.prevSettlePrice).value();
      }
      comparison = this.$numeral(comparison).value();

      if (!comparison) {
        return "yellow";
      } else if (comparison >= ceiling) {
        return "blue";
      } else if (comparison > prior) {
        return "green";
      } else if (comparison <= floor) {
        return "pink";
      } else if (comparison < prior) {
        return "red";
      } else {
        return "yellow";
      }
    },
    securityInfoUpdateCinf(securityInfoUpdateCinf) {
      if (!this.currentQuoteSelectedSymbol) {
        return;
      }

      if (securityInfoUpdateCinf.securityId !== this.currentQuoteSelectedSymbol.value.securityId) {
        return;
      }

      this.securityInfoLatest = Object.assign({}, securityInfoUpdateCinf);
    },
    dtsSecurityInfoUpdateCinf(dtsSecurityInfoUpdateCinf) {
      if (!this.currentQuoteSelectedSymbol) {
        return;
      }

      if (dtsSecurityInfoUpdateCinf.securityId !== this.currentQuoteSelectedSymbol.value.securityId) {
        return;
      }

      this.securityInfoLatest = Object.assign({}, dtsSecurityInfoUpdateCinf);
    },
    updateSecurityInfo(update) {
      if (update.securityId !== this.securityInfoLatest.securityId) {
        return;
      }

      if (this.securityInfoLatest.last !== update.price) {
        this.blinkBackground("securityLastPrice");
      }
      this.securityInfoLatest.last = update.price;

      if (this.securityInfoLatest.change !== update.changePrice) {
        this.blinkBackground("securityChange");
        this.blinkBackground("securityPercentChange");
      }
      this.securityInfoLatest.change = update.changePrice;
      if (this.$numeral(update.changePrice).value()) {
        this.securityInfoLatest.changePercent =
          (this.$numeral(update.changePrice).value() / this.$numeral(this.securityInfoLatest.prior).value()) * 100;
      }
    },
    blinkBackground(ref) {
      this.$refs[ref].classList.add("blink");

      setTimeout(() => {
        this.$refs[ref].classList.remove("blink");
      }, 250);
    },
    blinkText(ref, direction) {
      this.$refs[ref].classList.add(direction);
      setTimeout(() => {
        this.$refs[ref].classList.remove(direction);
      }, 500);
    },
  },
};
</script>

<style scoped>
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
  border-color: black;
}
.tg th {
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
  border-color: black;
}
.tg .tg-s6z2 {
  text-align: center;
}

.tg .tg-s6z3 {
  text-align: right;
}

.ui.header.blink {
  background-color: rgba(22, 160, 133, 0.5) !important;
}
.ui.header.up {
  color: #00aa00 !important;
}
.ui.header.down {
  color: #d91e18 !important;
}
</style>
