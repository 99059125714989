var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"portfolio-commission-table"},[_c('div',{staticClass:"commission-group-container"},[_c('div',{staticClass:"custom-header"},[_vm._v(" "+_vm._s("Commission Group")+" ")]),_c('div',{staticClass:"custom-header yellow"},[_vm._v(" "+_vm._s(_vm.etsAccountInfoCinf && _vm.etsAccountInfoCinf.commissionType)+" ")])]),_c('div',{staticClass:"custom-grid"},[_c('ag-grid-vue',{staticClass:"commission-table",class:{
        'ag-theme-balham-dark': _vm.theme === 'Dark',
        'ag-theme-balham': _vm.theme === 'Light',
      },attrs:{"row-selection":"single","grid-options":_vm.gridCommissionTableOptions,"column-defs":_vm.columnDefsCommissionTable,"row-data":_vm.rowCommissionTable,"get-row-node-id":_vm.getCommissionTableRowNodeId,"row-height":21,"header-height":32,"default-col-def":{ sortable: true, resizable: true },"animate-rows":true,"suppress-drag-leave-hides-columns":true,"enable-cell-change-flash":true,"locale-text":{ noRowsToShow: `Don't have any Commission.` },"row-drag-managed":false,"overlay-loading-template":"<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"},on:{"grid-ready":_vm.onCommissionTableGridReady,"cell-focused":_vm.onCellCommissionTableFocused}}),_c('ag-grid-vue',{staticClass:"commission-table",class:{
        'ag-theme-balham-dark': _vm.theme === 'Dark',
        'ag-theme-balham': _vm.theme === 'Light',
      },attrs:{"row-selection":"single","grid-options":_vm.gridCommissionGroupOptions,"column-defs":_vm.columnDefsCommissionGroup,"row-data":_vm.rowCommissionGroup,"get-row-node-id":_vm.getCommissionGroupRowNodeId,"row-height":21,"header-height":32,"default-col-def":{ sortable: true, resizable: true },"animate-rows":true,"suppress-drag-leave-hides-columns":true,"enable-cell-change-flash":true,"locale-text":{ noRowsToShow: `Don't have any Commission.` },"row-drag-managed":false,"overlay-loading-template":"<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"},on:{"grid-ready":_vm.onCommissionGroupGridReady,"cell-focused":_vm.onCellCommissionGroupFocused}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }