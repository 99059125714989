import { render, staticRenderFns } from "./TickerHeader.vue?vue&type=template&id=4fe7df92&scoped=true&"
import script from "./TickerHeader.vue?vue&type=script&lang=js&"
export * from "./TickerHeader.vue?vue&type=script&lang=js&"
import style0 from "./TickerHeader.vue?vue&type=style&index=0&id=4fe7df92&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe7df92",
  null
  
)

export default component.exports