<template>
  <div class="ui grid market-watch-bar">
    <div class="row">
      <div class="column" style="width: 17rem; padding-left: 1rem !important; height: 3rem; line-height: 3rem">
        <div class="ui form">
          <div class="inline fields">
            <div class="field">
              <sui-checkbox v-model="selectedMode" radio value="SET" :disabled="!isEnableETS()">
                <h4 class="ui grey tiny header">SET</h4>
              </sui-checkbox>
            </div>
            <div class="field">
              <sui-checkbox v-model="selectedMode" radio value="TFEX" :disabled="!isEnableDTS()">
                <h4 class="ui grey tiny header">TFEX</h4>
              </sui-checkbox>
            </div>
            <div class="field">
              <sui-checkbox v-model="selectedMode" radio value="FAVORITE">
                <h4 class="ui grey tiny header">Favorites</h4>
              </sui-checkbox>
            </div>
          </div>
        </div>
      </div>

      <div v-if="selectedMode === 'FAVORITE'" class="column" style="width: 15vw; padding: 0px 1rem !important">
        <sui-dropdown v-model="currentSelectedFavorite" fluid text="Favorites" button pointing class="ht-fav-dropdown">
          <div class="text">{{ currentSelectedFavorite }}</div>
          <sui-dropdown-menu>
            <sui-dropdown-item
              v-for="(option, index) in favouriteOptions"
              :key="`${option.text}-${index}`"
              :value="option.value"
              @select="onSelectFavorite"
            >
              <div class="favorite-option">
                <div :class="`ui empty label circular ${option.label.color}`"></div>
                <div class="option-text">{{ option.text }}</div>
              </div>
            </sui-dropdown-item>
          </sui-dropdown-menu>
        </sui-dropdown>
      </div>

      <div v-if="!['Port', 'New Favorite', null].includes(currentSelectedFavorite) && selectedMode === 'FAVORITE'" class="column">
        <sui-button-group style="position: absolute; padding-top: 0.5rem">
          <sui-button animated="vertical" color="blue" class="ht-fav-edit" @click="onEditFavorite">
            <sui-button-content hidden>Edit Favorite</sui-button-content>
            <sui-button-content visible>
              <sui-icon name="edit outline" />
            </sui-button-content>
          </sui-button>
          <sui-button animated="vertical" color="black" class="ht-fav-delete" @click="onRemoveFavorite">
            <sui-button-content hidden>Remove Favorite</sui-button-content>
            <sui-button-content visible>
              <sui-icon name="trash alternate outline" />
            </sui-button-content>
          </sui-button>
        </sui-button-group>
      </div>

      <div v-show="selectedMode === 'SET'" class="column" style="width: 15vw; padding: 0px 1rem !important">
        <v-selectmenu
          ref="smsg"
          v-model="selectedStockGroupSET"
          position="left"
          :data="stockGroupOptionsSET"
          key-field="id"
          show-field="name"
          language="en"
          title="Stock Group"
          class="ht-market-dropdown"
        />
      </div>

      <div v-show="selectedMode === 'TFEX'" class="column" style="width: 15vw; padding: 0px 1rem !important">
        <v-selectmenu
          ref="smsg"
          v-model="selectedStockGroupTFEX"
          position="left"
          :data="stockGroupOptionsTFEX"
          key-field="id"
          show-field="name"
          language="en"
          title="Stock Group"
          class="ht-market-dropdown"
        />
      </div>

      <div
        v-show="
          (selectedMode === 'SET' && $_.find(rankingMenu(), ['securityIds', selectedStockGroupSET])) ||
          (selectedMode === 'TFEX' && $_.find(rankingMenu(), ['securityIds', selectedStockGroupTFEX]))
        "
        class="column"
        style="width: 10vw; padding-left: 0.75rem !important"
      >
        <sui-popup flowing hoverable position="bottom left" inverted>
          <sui-grid centered divided :columns="2" style="width: 26rem">
            <sui-grid-column text-align="left">
              <sui-form v-show="selectedMode === 'SET'">
                <sui-form-fields grouped>
                  <sui-form-field>
                    <sui-checkbox v-model="filterSET" label="Common Stock" value="S" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterSET" label="Derivatives Warrant" value="V" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterSET" label="ETF" value="L" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterSET" label="Preferred" value="P" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterSET" label="Depository Receipts" value="X" />
                  </sui-form-field>
                </sui-form-fields>
              </sui-form>
              <sui-form v-show="selectedMode === 'TFEX'">
                <sui-form-fields grouped>
                  <sui-form-field>
                    <sui-checkbox v-model="filterTFEX" label="Equity Index" value="I" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterTFEX" label="Precious Metal" value="M" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterTFEX" label="Interest Rate" value="R" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterTFEX" label="Currency" value="C" />
                  </sui-form-field>
                </sui-form-fields>
              </sui-form>
            </sui-grid-column>
            <sui-grid-column text-align="left">
              <sui-form v-show="selectedMode === 'SET'">
                <sui-form-fields grouped>
                  <sui-form-field>
                    <sui-checkbox v-model="filterSET" label="Foreign Common Stock" value="F" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterSET" label="Warrant" value="W" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterSET" label="Unit Trust" value="U" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterSET" label="Preferred Foreign" value="Q" />
                  </sui-form-field>
                </sui-form-fields>
              </sui-form>
              <sui-form v-show="selectedMode === 'TFEX'">
                <sui-form-fields grouped>
                  <sui-form-field>
                    <sui-checkbox v-model="filterTFEX" label="Single Stock" value="S" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterTFEX" label="Deferred Previous Metal" value="D" />
                  </sui-form-field>
                  <sui-form-field>
                    <sui-checkbox v-model="filterTFEX" label="Agriculture" value="A" />
                  </sui-form-field>
                </sui-form-fields>
              </sui-form>
            </sui-grid-column>
          </sui-grid>
          <div style="text-align: right"><sui-button class="ui mini grey button" @click="onClickRestoreDefaults">Restore Defaults</sui-button></div>
          <sui-button slot="trigger" class="ui tiny button" content="Filter" icon="filter" label-position="left" secondary />
        </sui-popup>
      </div>

      <div class="column" style="width: 0px"><MarketFavoriteModal /></div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import {
  rankingMenu,
  securitiesTypeMenu,
  securitiesStatusMenu,
  portFavorite,
  newFavorite,
  equityMenu,
  preciousMetalMenu,
  deferredPreciousMetalMenu,
  interestRateMenu,
  agriCultureMenu,
  currencyMenu,
  generateRankingMenu,
  generateIndexesMenu,
  generateSectorIndustryMenu,
  generateSecuritiesTypeMenu,
  generateSecuritiesStatusMenu,
  generateUnderlyingMenu,
} from "./marketWatchDropdown";
import vSelectMenu from "v-selectmenu";
import MarketFavoriteModal from "./MarketFavoriteModal";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

export default {
  name: "MarketWatchBar",
  components: {
    MarketFavoriteModal,
  },
  data: () => ({
    selectedMode: "SET",
    selectedMarket: "SET",
    selectedFavorite: null,
    selectedFavoriteWaiting: null,
    selectedStockGroup: "",
    selectedStockGroupSET: "",
    selectedStockGroupTFEX: "",
    favouriteOptions: [portFavorite, newFavorite],
    stockGroupOptionsSET: [generateRankingMenu()],
    stockGroupOptionsSETIndex: [],
    stockGroupOptionsSETSectorIndustry: [],
    stockGroupOptionsTFEX: [generateRankingMenu(), ...generateUnderlyingMenu()],
    securityListInterval: null,
    isEnableETS,
    isEnableDTS,
    sectorsIndustries: [],
    currentAccountPosition: [],
    AR02RefId: "",
    filterSET: ["S"],
    filterTFEX: ["I"],
    newFavorite,
  }),
  watch: {
    currentSelectedMode(currentSelectedMode) {
      if (currentSelectedMode === "SET" || currentSelectedMode === "TFEX") {
        this.selectedMode = currentSelectedMode;
      }
    },
    currentSelectedAccount(currentSelectedAccount) {
      this.AR02();
    },
    selectedStockGroupSET(selectedStockGroupSET) {
      this.selectedStockGroup = selectedStockGroupSET;
      this.currentSelectedStockGroupSET = selectedStockGroupSET;
    },
    filterSET() {
      const temp = this.selectedStockGroup;
      this.selectedStockGroup = "";
      this.$nextTick(() => {
        this.selectedStockGroup = temp;
      });
    },
    selectedStockGroupTFEX(selectedStockGroupTFEX) {
      this.selectedStockGroup = selectedStockGroupTFEX;
      this.currentSelectedStockGroupTFEX = selectedStockGroupTFEX;
    },
    filterTFEX() {
      const temp = this.selectedStockGroup;
      this.selectedStockGroup = "";
      this.$nextTick(() => {
        this.selectedStockGroup = temp;
      });
    },
    favorites(favorites) {
      this.updateFavorites(favorites);
    },
    selectedMode(selectedMode) {
      this.currentSelectedMode = selectedMode;
      if (selectedMode === "SET" || selectedMode === "TFEX") {
        this.selectedMarket = selectedMode;
      } else {
        if (this.securityListInterval) {
          clearInterval(this.securityListInterval);
        }
        this.selectedMarket = null;
        const oldSelectedFavorite = this.currentSelectedFavorite;
        this.selectedFavorite = null;
        this.$nextTick(() => {
          this.selectedFavorite = oldSelectedFavorite;
        });
      }
    },
    currentSelectedFavorite(selectedFavorite) {
      if (selectedFavorite) {
        this.selectedMarket = null;
      }

      if (!selectedFavorite || this.selectedMode !== "FAVORITE") {
        return;
      }

      if (selectedFavorite === "New Favorite") {
        this.$EventBus.$emit("openMarketFavoriteModal", "NEW");
        return;
      }

      this.getFavoriteList();
    },
    selectedMarket(newSelectedMarket, oldSelectedMarket) {
      if (newSelectedMarket === "SET") {
        this.selectedStockGroup = "clear";
        this.$nextTick(() => {
          this.selectedStockGroup = this.selectedStockGroupSET;
        });
      } else if (newSelectedMarket === "TFEX") {
        this.selectedStockGroup = "clear";
        this.$nextTick(() => {
          this.selectedStockGroup = this.selectedStockGroupTFEX;
        });
      }
      this.$EventBus.$emit("clearMarketWatchTable");
    },
    selectedStockGroup(selectedStockGroup) {
      if (this.selectedMode === "FAVORITE") {
        return;
      }

      if (!selectedStockGroup || selectedStockGroup === "clear") {
        this.$EventBus.$emit("clearMarketWatchTable");
        return;
      }

      let value = null;

      value = this.$_.find(rankingMenu(), ["securityIds", selectedStockGroup]);

      if (value) {
        if (this.currentSelectedMode === "SET") {
          if (this.filterSET.length && !(this.filterSET.length === 1 && this.filterSET[0] === "S")) {
            value.securityTypes = this.filterSET.join(",");
          }
        } else if (this.currentSelectedMode === "TFEX") {
          if (this.filterTFEX.length && !(this.filterTFEX.length === 1 && this.filterTFEX[0] === "I")) {
            value.securityTypes = this.filterTFEX.join(",");
          }
        }
      }

      if (this.currentSelectedMode === "SET") {
        if (!value) {
          value = this.$_.find(this.indexes, ["name", selectedStockGroup]);
          if (value) {
            const newValue = {
              securityIds: value.name,
              listType: "IX",
              listId: value.indexId,
              securityTypes: "",
              exchangeId: "1",
            };
            value = newValue;
          }
        }

        if (!value) {
          value = this.$_.find(this.sectorsIndustries, ["name", selectedStockGroup]);

          if (value) {
            const newValue = {
              securityIds: value.name,
              listType: value.indexType,
              listId: value.indexId,
              securityTypes: "",
              exchangeId: "1",
            };
            value = newValue;
          }
        }

        if (!value) {
          value = this.$_.find(securitiesTypeMenu(), ["securityIds", selectedStockGroup]);
        }

        if (!value) {
          value = this.$_.find(securitiesStatusMenu(), ["securityIds", selectedStockGroup]);
        }
      } else if (this.currentSelectedMode === "TFEX") {
        if (!value) {
          value = this.$_.find(
            [...equityMenu(), ...preciousMetalMenu(), ...deferredPreciousMetalMenu(), ...interestRateMenu(), ...agriCultureMenu(), ...currencyMenu()],
            ["securityIds", selectedStockGroup]
          );
        }
      }
      this.$store.set("global/isFavoriteMode", false);

      if (this.securityListInterval) {
        clearInterval(this.securityListInterval);
      }

      this.SR03(value, this.currentSelectedMode);
      this.securityListInterval = setInterval(() => {
        this.SR03(value, this.currentSelectedMode);
      }, process.env.VUE_APP_FETCH_RATE);

      this.$EventBus.$emit("setSuppressRowDragMarketWatchTable", true);
      this.$EventBus.$emit("onSelectedStockGroupChange", selectedStockGroup);
    },
    stockGroupOptionsSET(stockGroupOptions) {
      if (this.userType !== "T" && stockGroupOptions.length) {
        this.stockGroupOptionsSET[0].list = this.$_.remove(stockGroupOptions[0].list, (menu) => menu.name !== "Most Active in Broker");
      }
    },
    stockGroupOptionsTFEX(stockGroupOptions) {
      if (this.userType !== "T" && stockGroupOptions.length) {
        this.stockGroupOptionsTFEX[0].list = this.$_.remove(stockGroupOptions[0].list, (menu) => menu.name !== "Most Active in Broker");
      }
    },
  },
  created() {
    this.$EventBus.$on("cancelMarketFavoriteModal", this.cancelMarketFavoriteModal);

    this.$EventBus.$on("favoriteListChange", this.onFavoriteListChange);

    if (this.indexes) {
      this.updateIndexes(this.indexes);
    }

    if (this.favorites) {
      this.updateFavorites(this.favorites);
    }

    this.$EventBus.$on("ctinf/IS03", this.indexSectorIndustryRsp);
    this.$EventBus.$on("ctinf/SS03", this.stockGroupUpdateCinf);
    this.$EventBus.$on("ctinf/US01", this.favoriteUpdateCinf);
    this.$EventBus.$on("ctinf/AS02", this.etsAccountPositionCinf);
    this.$EventBus.$on("ctinf/AS52", this.dtsAccountPositionCinf);

    if (this.currentSelectedAccount && this.currentSelectedFavorite === "Port") {
      this.AR02();
    }

    this.IR03();
  },
  beforeDestroy() {
    this.$EventBus.$off("cancelMarketFavoriteModal", this.cancelMarketFavoriteModal);
    this.$EventBus.$off("favoriteListChange", this.onFavoriteListChange);

    this.$EventBus.$off("ctinf/PS51", this.indexSectorIndustryRsp);
    this.$EventBus.$off("ctinf/SS03", this.stockGroupUpdateCinf);
    this.$EventBus.$off("ctinf/US01", this.favoriteUpdateCinf);
    this.$EventBus.$off("ctinf/AS02", this.etsAccountPositionCinf);
    this.$EventBus.$off("ctinf/AS52", this.dtsAccountPositionCinf);

    if (this.securityListInterval) {
      clearInterval(this.securityListInterval);
    }
  },
  methods: {
    rankingMenu,
    getFavoriteList() {
      if (!this.currentSelectedFavorite) return;

      let value = {};

      if (this.currentSelectedFavorite === "Port") {
        if (!this.currentAccountPosition.length) {
          this.$EventBus.$emit("clearMarketWatchTable");
          this.$store.set("global/isFavoriteMode", false);
          this.$EventBus.$emit("setSuppressRowDragMarketWatchTable", true);
          return;
        }

        value = {
          securityIds: this.currentAccountPosition.join(),
          listType: "FV",
          listId: "P",
          securityTypes: "S",
          exchangeId: "1",
        };

        this.$store.set("global/isFavoriteMode", false);
        this.$EventBus.$emit("setSuppressRowDragMarketWatchTable", true);
      } else {
        value = {
          securityIds: this.currentSelectedFavorite,
          listType: "FV",
          listId: this.$_.find(this.favorites, ["name", this.currentSelectedFavorite]).favoriteId,
          securityTypes: "S",
          exchangeId: "1",
        };

        this.$store.set("global/isFavoriteMode", true);
        this.$EventBus.$emit("setSuppressRowDragMarketWatchTable", false);
      }

      this.SR03(value);
    },

    onSelectFavorite(value) {
      if (value === "New Favorite") {
        this.$EventBus.$emit("openMarketFavoriteModal", "NEW");
        return;
      }

      this.currentSelectedFavorite = value;
    },
    AR02() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount.exchangeId == 0) {
        this.currentAccountPosition = [];
        return;
      }
      const msg = this.$messageFactory.createMessage("AR02");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);
      msg.exchangeId.set(this.currentSelectedAccount.exchangeId);

      this.AR02RefId = this.$shortid.generate();
      this.$ws.send(msg, this.AR02RefId);
    },
    etsAccountPositionCinf(position) {
      if (position.refId !== this.AR02RefId) {
        return;
      }

      this.accountPositionCinf(position);
    },
    dtsAccountPositionCinf(position) {
      if (position.refId !== this.AR02RefId) {
        return;
      }

      this.accountPositionCinf(position);
    },
    accountPositionCinf(position) {
      if (position.records.length) {
        const positionUniq = this.$_.uniqBy(position.records, (e) => e.symbol);
        this.currentAccountPosition = positionUniq.map((e) => e.symbol);
      } else {
        this.currentAccountPosition = [];
      }

      if (this.currentSelectedFavorite !== "Port") {
        return;
      }

      if (this.currentSelectedFavorite === "Port") {
        this.currentSelectedFavorite = null;
      }
      this.$nextTick(() => {
        this.currentSelectedFavorite = "Port";
      });
    },
    XR01() {
      const msg = this.$messageFactory.createMessage("XR01");

      this.$ws.send(msg);
    },
    updateFavorites(favorites) {
      let favouriteOptions = [];
      const colorList = ["red", "orange", "yellow", "green", "blue", "purple"];

      favouriteOptions.push(portFavorite);
      if (favorites?.length) {
        favorites.forEach((favorite, index) => {
          favouriteOptions.push({
            text: favorite.name,
            value: favorite.name,
            favoriteId: favorite.favoriteId,
            label: { color: colorList[index % 6], empty: true, circular: true },
          });
        });
      }
      favouriteOptions.push(newFavorite);

      this.favouriteOptions = favouriteOptions;

      if (this.selectedFavoriteWaiting) {
        this.currentSelectedFavorite = this.selectedFavoriteWaiting;
        this.selectedFavoriteWaiting = null;
      }
    },
    SR03(selectedStockGroup, selectedMarket = "SET") {
      const msg = this.$messageFactory.createMessage("SR03");

      msg.securityIds.set(selectedStockGroup.securityIds);
      msg.listType.set(selectedStockGroup.listType);
      msg.listId.set(selectedStockGroup.listId);
      msg.securityTypes.set(selectedStockGroup.securityTypes);
      if (selectedMarket === "SET") {
        msg.exchangeId.set(1);
      } else if (selectedMarket === "TFEX") {
        msg.exchangeId.set(2);
      }
      msg.listGroup.set(selectedStockGroup.listGroup);

      this.$ws.send(msg);
    },

    IR03() {
      const msg = this.$messageFactory.createMessage("IR03");

      this.$ws.send(msg);
    },
    onRemoveFavorite() {
      const favoriteId = this.$_.find(this.favorites, ["name", this.currentSelectedFavorite]).favoriteId;
      if (!favoriteId) return;

      this.$Swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#DB2828",
          // cancelButtonColor: "#E0E1E2",
          confirmButtonText: "<b>Remove</b>",
          cancelButtonText: "<b>Cancel</b>",
        })
        .then((result) => {
          if (result.value) {
            this.UR01(favoriteId, "D");
          }
        });
    },
    onEditFavorite() {
      const favoriteId = this.$_.find(this.favorites, ["name", this.currentSelectedFavorite]).favoriteId;
      if (!favoriteId) return;

      this.$EventBus.$emit("openMarketFavoriteModal", "EDIT", this.currentSelectedFavorite, favoriteId);
    },
    UR01(favoriteId, updateType, favouriteName, symbols) {
      const msg = this.$messageFactory.createMessage("UR01");

      msg.favoriteId.set(favoriteId);
      msg.updateType.set(updateType);

      msg.name.set(favouriteName);
      msg.symbols.set(encodeURI(JSON.stringify(symbols)));

      this.$ws.send(msg);
    },
    onFavoriteListChange(mode, selectedSymbol) {
      let symbols = [];
      const favoriteId = this.$_.find(this.favorites, ["name", this.currentSelectedFavorite]).favoriteId;
      if (!favoriteId) return;

      this.currentMaketWatchList.forEach((stock) => {
        symbols.push({
          symbol: stock.symbol,
          exchange: { id: stock.exchangeId },
        });
      });

      if (mode === "ADD") {
        symbols.push({
          symbol: selectedSymbol.symbol,
          exchange: { id: selectedSymbol.value.exchangeId },
        });

        this.UR01(favoriteId, "U", this.currentSelectedFavorite, symbols);
      } else if (mode === "REMOVE") {
        symbols = this.$_.reject(symbols, ["symbol", selectedSymbol.symbol]);

        if (symbols.length === 0) {
          this.$Swal
            .fire({
              title: "Are you sure?",
              text: "If favorite is empty, it will be deleted!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: "Confirm",
              cancelButtonText: "Cancel",
              reverseButtons: true,
            })
            .then((result) => {
              if (result.value) {
                this.UR01(favoriteId, "D", this.currentSelectedFavorite, symbols);
                if (this.favouriteOptions.length === 3) {
                  this.$nextTick(() => {
                    this.currentSelectedFavorite = "Port";
                  });
                } else {
                  this.currentSelectedFavorite = this.favouriteOptions[1].value;
                }
                return;
              } else {
                return;
              }
            });
        } else {
          this.UR01(favoriteId, "U", this.currentSelectedFavorite, symbols);
        }
      } else if (mode === "MOVE") {
        this.UR01(favoriteId, "U", this.currentSelectedFavorite, symbols);
      }
    },
    indexSectorIndustryRsp(indexSectorIndustry) {
      const { indexes } = indexSectorIndustry;
      let sector = [];
      let industry = [];

      indexes.forEach((index) => {
        if (index.indexType === "SC") {
          sector.push(index);
        } else if (index.indexType === "IN") {
          industry.push(index);
        }
      });

      this.sectorsIndustries = [...sector, ...industry];

      const sectorIndustryMenu = generateSectorIndustryMenu(sector, industry);

      this.stockGroupOptionsSETSectorIndustry = sectorIndustryMenu;

      this.stockGroupOptionsSET = [
        generateRankingMenu(),
        this.stockGroupOptionsSETIndex,
        ...this.stockGroupOptionsSETSectorIndustry,
        generateSecuritiesTypeMenu(),
        generateSecuritiesStatusMenu(),
      ];
    },
    stockGroupUpdateCinf(update) {
      this.$store.set("global/currentMaketWatchList", update.securities || []);
      /* Send subscribe message */
    },
    favoriteUpdateCinf(update) {
      if (update.resultCode !== 1001) return;

      if (update.updateType === "I" || update.updateType === "U") {
        this.selectedFavoriteWaiting = update.favoriteName;
        this.XR01();

        if (update.updateType === "U") {
          const value = {
            securityIds: update.favoriteName,
            listType: "FV",
            listId: update.favoriteId,
            securityTypes: "S",
            exchangeId: "1",
          };
          this.SR03(value);
        }
      } else if (update.updateType === "D") {
        if (this.favouriteOptions.length === 3) {
          this.$nextTick(() => {
            this.currentSelectedFavorite = "Port";
          });
        } else {
          this.currentSelectedFavorite = this.favouriteOptions[1].value;
        }
        this.XR01();
      }
    },
    updateIndexes(indexes) {
      const indexesMenu = generateIndexesMenu(indexes);

      this.stockGroupOptionsSETIndex = indexesMenu;

      this.stockGroupOptionsSET = [
        generateRankingMenu(),
        this.stockGroupOptionsSETIndex,
        ...this.stockGroupOptionsSETSectorIndustry,
        generateSecuritiesTypeMenu(),
        generateSecuritiesStatusMenu(),
      ];
    },
    cancelMarketFavoriteModal(mode) {
      if (mode === "EDIT") return;

      this.currentSelectedFavorite = this.favouriteOptions[this.favouriteOptions.length - 2].value;
    },
    onClickRestoreDefaults() {
      if (this.selectedMode === "SET") {
        this.filterSET = ["S"];
      } else if (this.selectedMode === "TFEX") {
        this.filterTFEX = ["I"];
      }
    },
  },
  computed: {
    favorites: get("model/systemRefData@favorites"),
    indexes: get("model/systemRefData@indexes"),
    currentMaketWatchList: get("global/currentMaketWatchList"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
    currentSelectedMode: sync("global/currentSelectedMode"),
    currentSelectedStockGroupSET: sync("global/currentSelectedStockGroupSET"),
    currentSelectedStockGroupTFEX: sync("global/currentSelectedStockGroupTFEX"),
    currentSelectedFavorite: sync("global/currentSelectedFavorite"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
    userType: get("model/systemRefData@userType"),
  },
  mounted() {
    this.$refs.smsg.i18n.advanced_default = "Stock Group";
    this.$refs.smsg.i18n.not_found = "Not Found.";

    this.$nextTick(() => {
      this.selectedMode = this.currentSelectedMode;
      this.selectedStockGroupSET = this.currentSelectedStockGroupSET;
      this.selectedStockGroupTFEX = this.currentSelectedStockGroupTFEX;

      this.$nextTick(() => {
        if (this.selectedMode === "FAVORITE" && this.currentSelectedFavorite) this.getFavoriteList();
      });
      this.AR02();
    });
  },
};
</script>

<style scoped>
.ui.button.fluid.dropdown {
  background-color: #2f383f;
}

.ui.grey.header {
  color: #dcddde !important;
}

.ui.secondary.menu {
  min-height: unset;
  height: 32px !important;
  border: 0px !important;
  background-color: unset !important;
}
</style>

<style>
.sm-default-btn {
  width: 14vw;
  height: 37px;
  text-align: start;
  background-color: #2f383f !important;
  border: 0px !important;
  color: #dcddde !important;
  font-weight: 800;
  font-size: 1.1rem;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}

/* .sm-caret-down {
  margin-left: 7.5vw !important;
} */

.v-dropdown-container {
  background-color: #2f383f !important;
  border: 0px !important;
  border-radius: 0.25rem !important;
}

.sm-header {
  background-color: #1b2025 !important;
  border-radius: 0.25rem !important;
  color: #dcddde !important;
}

.sm-search,
.sm-tabs {
  background-color: #1b2025 !important;
  border-bottom: 0px !important;
}

.sm-tabs ul li.active a {
  color: rgba(200, 136, 65) !important;
}

.sm-tabs ul li.active:after {
  background-color: rgba(200, 136, 65) !important;
}

.sm-search input {
  color: #dcddde !important;
  background-color: #2f383f !important;
}

.sm-result-tabs,
.sm-input-area {
  background-color: #1b2025 !important;
  border: 0px !important;
}

.sm-input-area > input {
  background-color: #2f383f !important;
  border: 0px !important;
  color: #dcddde !important;
}

.sm-result-tabs {
  height: 2.5rem;
}

.sm-result-tabs > ul > li > a {
  padding: 0.25rem 0.5rem !important;
}

.sm-result-tabs > ul > li > a.active {
  background-color: #2f383f !important;
  color: rgba(200, 136, 65) !important;
  border-radius: 0.5rem !important;
  border: 1px solid rgba(200, 136, 65) !important;
}

.sm-result-area.sm-scroll-limit {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.sm-result-area.sm-scroll-limit,
.sm-results {
  background-color: #2f383f !important;
}

.sm-header > h3 {
  color: #dcddde !important;
}

.sm-item-text,
.sm-selected-icon,
.sm-message-box {
  color: #dcddde !important;
}

.sm-result-area.sm-scroll-limit > ul.sm-results > li.sm-over {
  background-color: #242b30 !important;
}

.ht-fav-dropdown {
  height: 2rem;
  line-height: 2rem !important;
  top: calc(50% - 1rem);
}

.ht-fav-dropdown .favorite-option {
  display: flex;
  align-items: center;
  gap: 0 0.4rem;
}

.ht-fav-dropdown .favorite-option .option-text {
  line-height: 0;
}

.ht-fav-dropdown .text {
  position: absolute;
  top: 0;
}
.ht-fav-dropdown i.dropdown {
  line-height: 0 !important;
  margin-top: 3px !important;
}

.ht-market-dropdown button {
  height: 2rem;
  line-height: 2rem !important;
  font-size: 100%;
  padding: 0 0.75rem !important;
}

.sm-caret-down {
  position: absolute;
  right: 0.75rem;
  top: calc(50% - 3px);
}

.ht-fav-edit {
  width: 7.5rem;
  height: 2rem;
}
.ht-fav-delete {
  width: 9rem;
  height: 2rem;
}

.ht-fav-delete .visible.content,
.ht-fav-edit .visible.content {
  position: absolute !important;
  padding: 0 !important;
  margin: 0 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  height: 2rem !important;
}
.ht-fav-delete i,
.ht-fav-edit i {
  margin-top: 0.5rem !important;
}
</style>
