<template>
  <div class="side-bar">
    <transition name="fade">
      <div v-if="sidebarVisible" class="shadow-wrapper" @click="sidebarVisible = false" />
    </transition>
    <sui-sidebar :visible="sidebarVisible" animation="push" class="inverted side-bar-content-container" style="background-color: #2f383f">
      <div class="item" style="padding: 0 !important; position: relative">
        <div class="ht-logo-navbar" style="margin-left: 1rem" />
        <div style="position: fixed; right: 0; top: 0; line-height: calc(3rem - 1px)">
          <div class="ht-back-btn" @click="closeSidebar">
            <i class="angle double left icon"></i>
          </div>
        </div>
      </div>
      <div class="item link main" style="cursor: unset">
        <div style="padding: 0 0.5rem; line-height: 1.25rem">
          <span data-v-483b6756="" style="color: #e4af0a; font-size: 1rem; font-weight: 700; word-wrap: break-word">
            {{ userFullName && userFullNameParser(userFullName) }}
          </span>
          <br />
          <strong style="word-wrap: break-word">{{ userType && userTypeParser(userType) }} ({{ username }})</strong>
        </div>
      </div>
      <div class="item link main" style="height: calc(100vh - 6.5rem); overflow: auto">
        <router-link active-class="active" class="single" to="/market" exact @click.native="doActionRouter">
          <div>
            <strong>Market</strong>
          </div>
        </router-link>
        <router-link active-class="active" class="single" to="/portfolio" exact @click.native="doActionRouter">
          <div>
            <strong v-if="userType === 'T'">Account</strong>
            <strong v-else>Portfolio</strong>
          </div>
        </router-link>
        <router-link active-class="active" class="single" to="/quote" exact @click.native="doActionRouter">
          <div>
            <strong>Quote</strong>
          </div>
        </router-link>
        <router-link active-class="active" class="single" to="/custbystock" exact @click.native="doActionRouter">
          <div>
            <strong>Cust. By Stock</strong>
          </div>
        </router-link>
        <router-link active-class="active" class="single" to="/bbos" exact @click.native="doActionRouter">
          <div>
            <strong>BBOs</strong>
          </div>
        </router-link>

        <router-link active-class="active" class="single" to="/ticker" exact @click.native="doActionRouter">
          <div>
            <strong>Ticker</strong>
          </div>
        </router-link>

        <router-link active-class="active" class="single" to="/technical" exact @click.native="doActionRouter">
          <div>
            <strong>Technical</strong>
          </div>
        </router-link>

        <router-link active-class="active" class="single" to="/news" exact @click.native="doActionRouter">
          <div>
            <strong>News</strong>
          </div>
        </router-link>
        <router-link active-class="active" class="single" to="/index" exact @click.native="doActionRouter">
          <div>
            <strong>Index</strong>
          </div>
        </router-link>
        <router-link v-if="userType === 'T'" active-class="active" class="single" to="/customers" exact @click.native="doActionRouter">
          <div>
            <strong>Customers</strong>
          </div>
        </router-link>

        <div is="sui-segment" v-if="userType === 'T'" inverted exact>
          <sui-accordion exclusive inverted>
            <sui-accordion-title :active="companyActive" @click="companyActive = !companyActive" class="accordion-menu">
              <sui-icon name="dropdown" />
              <strong>Company</strong>
            </sui-accordion-title>
            <sui-accordion-content :active="companyActive">
              <div class="ui list">
                <router-link active-class="active" class="item" to="/information">
                  <div>
                    <strong>Information</strong>
                  </div>
                </router-link>
                <router-link active-class="active" class="item" to="/value">
                  <div>
                    <strong>Value</strong>
                  </div>
                </router-link>
                <router-link active-class="active" class="item" to="/brokerranking">
                  <div>
                    <strong>Broker Ranking</strong>
                  </div>
                </router-link>
              </div>
            </sui-accordion-content>
          </sui-accordion>
        </div>
        <div class="setting-menu single" @click="$EventBus.$emit('settings')">
          <strong>{{ "Setting" }}</strong>
        </div>
        <div class="about-menu single" @click="$EventBus.$emit('about')">
          <strong>{{ "About" }}</strong>
        </div>
        <div class="single" @click="onLogoutClicked">
          <strong>Logout</strong>
        </div>
      </div>
    </sui-sidebar>
  </div>
</template>
k
<script>
import _api from "@/api/authentication";
import { get, sync } from "vuex-pathify";

import SettingModal from "@/components/modal/SettingsModal";
import AboutModal from "@/components/modal/AboutModal";

export default {
  // components: { SettingModal, AboutModal },
  data() {
    return {
      sidebarVisible: false,
      companyActive: true,
    };
  },
  watch: {
    $route() {
      this.sidebarVisible = false;
    },
    sidebarVisible(sidebarVisible) {
      if (sidebarVisible) {
        if (["/information", "/value", "/brokerranking"].includes(this.$route.path)) {
          this.companyActive = true;
        }
      }
    },
  },
  created() {
    this.$EventBus.$on("openSidebar", this.openSidebar);
    this.$EventBus.$on("toggleSidebar", this.toggleSidebar);
  },
  beforeDestroy() {
    this.$EventBus.$off("openSidebar", this.openSidebar);
    this.$EventBus.$off("toggleSidebar", this.toggleSidebar);
  },
  methods: {
    doActionRouter() {
      this.modeOrder = false;
      this.closeSidebar();
    },
    openSidebar() {
      this.sidebarVisible = true;
    },
    toggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    closeSidebar() {
      this.sidebarVisible = false;
    },
    userTypeParser(userType) {
      switch (userType) {
        case "T":
          return "Trader";
        case "U":
          return "Trader";
        case "I":
          return "Investor";
        default:
          return "N/A";
      }
    },
    userFullNameParser(userFullName) {
      const fullNameArray = userFullName.split(" ");
      const fullNameUpperFirst = fullNameArray.map((name) => this.$_.upperFirst(name.toLowerCase()));

      return fullNameUpperFirst.join(" ");
    },
    onLogoutClicked() {
      _api
        .logout()
        .then((response) => {
          this.$router.replace("/login");
        })
        .catch((error) => error);
    },
  },
  computed: {
    modeOrder: sync("global/modeOrder"),
    userType: get("model/systemRefData@userType"),
    username: get("model/systemRefData@username"),
    userFullName: get("model/systemRefData@userFullName"),
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  .side-bar-content-container {
    width: 18% !important;
    overflow: hidden !important;
  }

  .setting-menu {
    display: none;
  }

  .about-menu {
    display: none;
  }

  .accordion-menu {
    strong {
      color: #dcddde !important;
    }

    ~ .content .active {
      strong {
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .setting-menu {
      display: block;
    }

    .about-menu {
      display: block;
    }
  }
}
.ht-back-btn {
  cursor: pointer;
  padding: 0 0.75rem;
  transition: 0.3s;
  background-color: #00000080;
}

.ht-back-btn:hover {
  background-color: #000000b3;
}

.ht-logo-navbar {
  position: relative;
  background: url("../../assets/images/logo.svg") no-repeat center;
  background-size: contain;
  width: 8rem;
  min-width: 5em;
  height: 3rem;
}

.shadow-wrapper {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: #000000d9;
  z-index: 102;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

div.item.link {
  padding: 1rem 0.5rem !important;
}

div.item.link.main {
  padding: 0.5rem 0.5rem !important;
}

.single {
  display: block;
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem !important;
  margin-bottom: 0.25rem;
}

.ui.list .item:last-child {
  margin-bottom: 0rem;
  padding-bottom: 0rem;
}

.single strong {
  margin-left: 1rem;
  color: #dcddde;
}

.single.active strong {
  color: #fff !important;
}

.single:hover {
  cursor: pointer;
  background: #c88841cc !important;
}

.single.active {
  background: #c88841 !important;
}

.ui.sidebar.menu .item {
  border-radius: 0.5rem !important;
}

.ui.list .item {
  padding: 0.5rem 2.5rem !important;
  margin-bottom: 0.25rem;
}

.ui.list .item:first-child {
  margin-top: -0.25rem;
}

.ui.list .item.active {
  background: #c88841 !important;
  border-radius: 0.5rem !important;
}

.ui.list .item:hover {
  cursor: pointer;
  background: #c88841cc !important;
  border-radius: 0.5rem !important;
}

.ui.inverted.segment {
  background: #2f383f;
  padding: 0 !important;
  margin: 0;
  padding-bottom: 0.25rem !important;
}
</style>
