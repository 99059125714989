import { reactive, computed, nextTick, watch, getCurrentInstance, onBeforeMount, onBeforeUnmount } from "vue";
import store from "@/store";

export default function usePTModalSET() {
  const vm = getCurrentInstance().proxy;

  const ptDealFormData = reactive({
    side: "",
    selectedSymbol: null,
    volume: null,
    price: null,
    board: "M",
    sellerFirmId: null,
    sellerTraderId: null,
    sellerAccountId: null,
    sellerTTF: null,
    sellerControlKey: null,
    buyerFirmId: null,
    buyerTraderId: null,
    buyerAccountId: null,
    buyerTTF: null,
    buyerControlKey: null,
    etsPTDealInsertRefId: null,
    securityInfoLatest: {},
    etsPTDealInsertBuyerRefId: null,
    etsPTDealInsertSellerRefId: null,
    trCode: null,
  });

  const traders = computed(() => store.getters["model/systemRefData"]?.traders);

  const inputsFilterable = ["<InputNumber>", "<InputPrice>", "<SymbolDropdown>", "<TradeReportCodeInput>", "<TradeReportControlKeyInput>"];

  const DR02 = () => {
    const buyMessage = vm.$messageFactory.createMessage("DR02");
    const sellMessage = vm.$messageFactory.createMessage("DR02");
    const controlKey = generateControlKey();

    sellMessage.traderId.set(ptDealFormData.sellerTraderId);
    sellMessage.custId.set(ptDealFormData.sellerAccountId);
    sellMessage.trustId.set(ptDealFormData.sellerTTF);
    sellMessage.symbol.set(ptDealFormData.selectedSymbol.symbol);
    sellMessage.price.set(ptDealFormData.price);
    sellMessage.board.set(ptDealFormData.board);
    sellMessage.vol.set(ptDealFormData.volume);
    sellMessage.side.set("S");
    sellMessage.controlKey.set(controlKey);
    sellMessage.trCode.set(ptDealFormData.trCode ?? "");

    buyMessage.traderId.set(ptDealFormData.buyerTraderId);
    buyMessage.custId.set(ptDealFormData.buyerAccountId);
    buyMessage.trustId.set(ptDealFormData.buyerTTF);
    buyMessage.symbol.set(ptDealFormData.selectedSymbol.symbol);
    buyMessage.price.set(ptDealFormData.price);
    buyMessage.board.set(ptDealFormData.board);
    buyMessage.vol.set(ptDealFormData.volume);
    buyMessage.side.set("B");
    buyMessage.controlKey.set(controlKey);
    buyMessage.trCode.set(ptDealFormData.trCode ?? "");

    ptDealFormData.etsPTDealInsertSellerRefId = vm.$shortid.generate();
    ptDealFormData.etsPTDealInsertBuyerRefId = vm.$shortid.generate();
    vm.$ws.send(sellMessage, ptDealFormData.etsPTDealInsertSellerRefId);
    vm.$ws.send(buyMessage, ptDealFormData.etsPTDealInsertBuyerRefId);
  };

  const DR03 = () => {
    const msg = vm.$messageFactory.createMessage("DR03");

    msg.symbol.set(ptDealFormData.selectedSymbol.symbol);
    msg.price.set(ptDealFormData.price);
    msg.board.set(ptDealFormData.board);
    msg.vol.set(ptDealFormData.volume);
    msg.side.set(ptDealFormData.side);
    msg.trCode.set(ptDealFormData.trCode ?? "");

    if (ptDealFormData.side === "B") {
      msg.firm.set(ptDealFormData.buyerFirmId);
      msg.traderId.set(ptDealFormData.buyerTraderId);
      msg.custId.set(ptDealFormData.buyerAccountId);
      msg.trustId.set(ptDealFormData.buyerTTF);
      msg.SETTraderId.set(ptDealFormData.sellerTraderId);
      msg.contractFirm.set(ptDealFormData.sellerFirmId);
      msg.controlKey.set(ptDealFormData.buyerControlKey ?? "");
    } else if (ptDealFormData.side === "S") {
      msg.firm.set(ptDealFormData.sellerFirmId);
      msg.traderId.set(ptDealFormData.sellerTraderId);
      msg.custId.set(ptDealFormData.sellerAccountId);
      msg.trustId.set(ptDealFormData.sellerTTF);
      msg.SETTraderId.set(ptDealFormData.buyerTraderId);
      msg.contractFirm.set(ptDealFormData.buyerFirmId);
      msg.controlKey.set(ptDealFormData.sellerControlKey ?? "");
    }

    ptDealFormData.etsPTDealInsertRefId = vm.$shortid.generate();
    vm.$ws.send(msg, ptDealFormData.etsPTDealInsertRefId);
  };

  const clearForm = () => {
    ptDealFormData.side = "";
    ptDealFormData.selectedSymbol = null;
    ptDealFormData.volume = null;
    ptDealFormData.price = null;
    ptDealFormData.sellerFirmId = null;
    ptDealFormData.sellerAccountId = null;
    ptDealFormData.sellerTTF = null;
    ptDealFormData.buyerFirmId = null;
    ptDealFormData.buyerAccountId = null;
    ptDealFormData.buyerTTF = null;
    ptDealFormData.trCode = null;
    ptDealFormData.buyerControlKey = null;
    ptDealFormData.sellerControlKey = null;
  };

  const clearValidate = () => {
    nextTick(() => {
      setTimeout(() => {
        vm.$children.forEach((child) => {
          if (inputsFilterable.includes(child.$vnode.componentOptions.tag)) {
            child.clearValidate();
          }
        });
      }, 10);
    });
  };

  const initPTDeal = () => {
    clearForm();
    clearValidate();

    nextTick(() => {
      if (vm.$refs.ptdealside) {
        vm.$refs.ptdealside.select();
      }
    });
  };

  const resolveBoard = (board) => {
    switch (board) {
      case "M":
        return "Main Board";
      case "B":
        return "Big Lot Board";
      case "F":
        return "Foreign Board";
      case "O":
        return "Odd Lot Board";
      default:
        return "-";
    }
  };

  const resolveSide = (side) => {
    switch (side) {
      case "B":
        return "Buy";
      case "S":
        return "Sell";
      default:
        return "Symbol";
    }
  };

  const resolveTTF = (type) => {
    switch (type) {
      case "1":
        return "TTF";
      case "2":
        return "NVDR";
      case "3":
        return "BUYIN";
      default:
        return "-";
    }
  };

  const onSend = () => {
    let validate = true;

    const filterErrors = vm.$children.filter((child) => {
      if (inputsFilterable.includes(child._name)) {
        if (child.isValidate) {
          const validateFunction = child.validate || child._setupProxy.validate;
          const result = validateFunction();

          validate &= result;
          return !result;
        } else {
          child.clearValidate();
        }
      }
      return false;
    });

    if (validate) {
      const board = resolveBoard(ptDealFormData.board);
      const sellerTTF = resolveTTF(ptDealFormData.sellerTTF);
      const buyerTTF = resolveTTF(ptDealFormData.buyerTTF);

      const html = `
          <div>
            <div style='display:grid;grid-template-columns:30% 1fr'>
                <div style='text-align:end;'>Board:</div>
                <div>${board}</div>
                <div style='text-align:end;'>Total Amount:</div>
                <div>${isNaN(ptDealFormData.price) ? "N/A" : vm.$numeral(ptDealFormData.volume * ptDealFormData.price).format("0,0.000000")}</div>
                <div style='text-align:end;'>TR Code:</div>
                <div>${ptDealFormData.trCode ?? "-"}</div>
              </div>
              <br/>
              <div style="display:grid;grid-template-columns: 30% 1fr 1fr;gap:5px 0;">
                <div></div>
                <div>
                  <b>Sell Information</b>
                </div>
                <div>
                  <b>Buy Information</b>
                </div>
                <div style='text-align:end;'>Broker ID:</div>
                  <div>${ptDealFormData.sellerFirmId || "-"}</div>
                  <div>${ptDealFormData.buyerFirmId || "-"}</div>
                <div style='text-align:end;'>Trader ID:</div>
                  <div>${ptDealFormData.sellerTraderId || "-"}</div>
                  <div>${ptDealFormData.buyerTraderId || "-"}</div>
                <div style='text-align:end;'>Account ID:</div>
                  <div>${ptDealFormData.sellerAccountId || "-"}</div>
                  <div>${ptDealFormData.buyerAccountId || "-"}</div>
                <div style='text-align:end;'>ControlKey:</div>
                  <div>${ptDealFormData.sellerControlKey || "-"}</div>
                  <div>${ptDealFormData.buyerControlKey || "-"}</div>
                <div style='text-align:end;'>TTF:</div>
                  <div>${sellerTTF}</div>
                  <div>${buyerTTF}</div>
              </div>
          </div>
        `;

      vm.$Swal
        .fire({
          title: "Trade Report Confirmation",
          html: html,
          icon: "info",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            if (ptDealFormData.side === "B" || ptDealFormData.side === "S") {
              DR03();
            } else {
              DR02();
            }
          }
        });
    } else {
      const selectFunction = filterErrors[0].select || filterErrors[0]._setupProxy.select;
      selectFunction();
    }
  };

  const onEtsPTDealInsertCtord = (data) => {
    if (
      ptDealFormData.etsPTDealInsertRefId !== data.refId &&
      ptDealFormData.etsPTDealInsertBuyerRefId !== data.refId &&
      ptDealFormData.etsPTDealInsertSellerRefId !== data.refId
    ) {
      return;
    }

    if (data.resultCode === 1001) {
      clearForm();
      clearValidate();

      vm.$EventBus.$emit("ptdealClose");
      if (data.orderId) {
        vm.$Swal.fire(`Complete`, `Your Trade Report number is ${data.orderId}`, "success");
      } else {
        vm.$Swal.fire(`Complete`, `Your Trade Report number is ${data.buyOrderId}, ${data.sellOrderId}`, "success");
      }
    } else {
      if (data.resultMessage) {
        data.resultMessage = data.resultMessage.replace(/[<>]/g, "");
      }
      vm.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error");
    }
  };

  const calculateBoard = () => {
    const value = ptDealFormData.volume * ptDealFormData.price;

    if (ptDealFormData.volume >= 1000000 || value > 3000000) {
      ptDealFormData.board = "B";
    } else if (ptDealFormData.securityInfoLatest.securityType === "F") {
      ptDealFormData.board = "F";
    } else if (ptDealFormData.volume >= Number(ptDealFormData.securityInfoLatest.boardLot)) {
      ptDealFormData.board = "M";
    } else if (value > 0) {
      ptDealFormData.board = "O";
    } else {
      ptDealFormData.board = "";
    }
  };

  const onSecurityInfoUpdateCinf = (securityInfoUpdateCinf) => {
    ptDealFormData.securityInfoLatest = Object.assign({}, securityInfoUpdateCinf);
  };

  const randomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const generateControlKey = () => {
    let controlKeyFirstChar = null;
    let controlKeySecondChar = null;

    while (!controlKeyFirstChar) {
      const randomAscii = randomNumber(43, 126);

      if (randomAscii !== 63 && randomAscii !== 64) controlKeyFirstChar = randomAscii;
    }

    while (!controlKeySecondChar) {
      const randomAscii = randomNumber(43, 126);

      if (randomAscii !== 63 && randomAscii !== 64) controlKeySecondChar = randomAscii;
    }

    return `${String.fromCharCode(controlKeyFirstChar)}${String.fromCharCode(controlKeySecondChar)}`;
  };

  watch(
    () => ptDealFormData.side,
    () => {
      const trader = vm.$_.find(traders.value, ["exchangeId", 1]);

      if (ptDealFormData.side === "") {
        ptDealFormData.sellerTraderId = trader?.traderCode ?? "";
        ptDealFormData.buyerTraderId = trader?.traderCode ?? "";

        ptDealFormData.buyerFirmId = "";
        ptDealFormData.sellerFirmId = "";
        ptDealFormData.buyerControlKey = null;
        ptDealFormData.sellerControlKey = null;
      } else if (ptDealFormData.side === "B") {
        ptDealFormData.sellerTraderId = "";
        ptDealFormData.sellerFirmId = "";
        ptDealFormData.sellerAccountId = "";
        ptDealFormData.buyerFirmId = "";
        ptDealFormData.buyerTraderId = trader?.traderCode || "";
        ptDealFormData.sellerTTF = null;
        ptDealFormData.sellerControlKey = null;
      } else if (ptDealFormData.side === "S") {
        ptDealFormData.sellerTraderId = trader?.traderCode || "";
        ptDealFormData.sellerFirmId = "";
        ptDealFormData.buyerTraderId = "";
        ptDealFormData.buyerFirmId = "";
        ptDealFormData.buyerAccountId = "";
        ptDealFormData.buyerTTF = null;
        ptDealFormData.buyerControlKey = null;
      }
    }
  );

  watch([() => ptDealFormData.volume, () => ptDealFormData.price, () => ptDealFormData.securityInfoLatest], () => {
    calculateBoard();
  });

  onBeforeMount(() => {
    vm.$EventBus.$on("ptdealOpen", initPTDeal);
    vm.$EventBus.$on("ctinf/SS01", onSecurityInfoUpdateCinf);
    //ptdeal insert
    vm.$EventBus.$on("ctord/DS02", onEtsPTDealInsertCtord);
    vm.$EventBus.$on("ctord/DS03", onEtsPTDealInsertCtord);
  });

  onBeforeUnmount(() => {
    vm.$EventBus.$off("ptdealOpen", initPTDeal);
    vm.$EventBus.$off("ctinf/SS01", onSecurityInfoUpdateCinf);
    vm.$EventBus.$off("ctord/DS02", onEtsPTDealInsertCtord);
    vm.$EventBus.$off("ctord/DS03", onEtsPTDealInsertCtord);
  });

  return { ptDealFormData, onSend };
}
