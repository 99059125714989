<template>
  <div>
    <sui-modal v-model="dialog" size="small" class="ht-order-change-modal">
      <sui-modal-header>
        <span>Order Change</span>
        <div class="close" @click="close"><i class="close grey icon"></i></div>
      </sui-modal-header>
      <div style="clear: float"></div>
      <sui-modal-content ref="content">
        <div class="ui grid padded">
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 6rem; margin: 0">Order No.:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0">{{ orderId }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 6rem; margin: 0" :style="sideStyle">{{ side }}:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0">{{ symbol }}</h5>
            </div>
          </div>
          <div v-if="market === 'TFEX'" class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; margin: 0; width: 6rem">Position:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0">{{ position }}</h5>
            </div>
          </div>
        </div>
        <form class="ui form" style="margin-top: 0.5rem">
          <div class="three fields">
            <div class="field">
              <label>Volume</label>
              <InputNumber
                ref="volume"
                v-model="volume"
                input-style="height: 2.5rem;"
                name="Volume"
                placeholder="Volume"
                is-validate
                format="0,0"
                required
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
                @enter="onVolumeEnter"
              />
            </div>
            <div class="field">
              <label>Price</label>
              <InputPrice
                ref="price"
                v-model="price"
                input-style="height: 2.5rem;"
                name="Price"
                placeholder="Price"
                is-validate
                :format="PriceFormatter"
                :min="0"
                required
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
                @enter="nextElement($refs.account)"
              />
            </div>
            <div class="field">
              <label>Account</label>
              <AccountDropdown
                v-if="!['M', 'U'].includes(traderType)"
                ref="account"
                v-model="account"
                style="width: 100%"
                base-style="height: 2.5rem; line-height: 2.5rem; width: 100%;"
                :market="market"
                name="Account"
                placeholder="Account"
                is-validate
                :disabled="disabledAccount"
                required
                allow-blank
                @enter="nextElement($refs.iceberg)"
              />
              <InputAccount
                v-else
                ref="account"
                v-model="account"
                style="width: 100%"
                input-style="height: 2.5rem;"
                :market="market"
                name="Account"
                placeholder="Account"
                is-validate
                :disabled="disabledAccount"
                required
                allow-blank
                :sync="false"
                @enter="nextElement($refs.iceberg)"
              />
            </div>
          </div>
          <div class="three fields">
            <div class="field">
              <label>Iceberg</label>
              <InputNumber
                ref="iceberg"
                v-model="iceberg"
                input-style="height: 2.5rem;"
                type="number"
                name="Iceberg"
                placeholder="Iceberg"
                format="0,0"
                :min="0"
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
                @enter="
                  () => {
                    if (this.market === 'SET') {
                      this.nextElement($refs.ttf);
                    } else if (this.market === 'TFEX') {
                      this.nextElement($refs.validity);
                    }
                  }
                "
              />
            </div>
            <div v-if="market === 'SET'" class="field">
              <label>TTF</label>
              <TTFDropdown ref="ttf" v-model="ttf" style="width: 100%" base-style="height: 2.5rem; line-height: 2.5rem; width: 100%;" />
            </div>
            <div v-if="market === 'SET' && showValidity" class="field">
              <label>Validity:</label>
              <ValidityDropdown
                ref="evalidity"
                v-model="validity"
                market="SET"
                style="width: 100%"
                base-style="height: 2.5rem; line-height: 2.5rem; width: 100%;"
                @enter="
                  () => {
                    this.$nextTick(() => {
                      if (this.validity && this.validity === 'SESS') {
                        return nextElement($refs.setValiditySession, 200);
                      } else if (this.validity && (this.validity === 'DATE' || this.validity === 'TIME')) {
                        return nextElement($refs.setValidityDate, 200);
                      } else {
                        return this.submit();
                      }
                    });
                  }
                "
              />
            </div>
            <div v-if="market === 'SET'" v-show="validity && validity === 'SESS' && showValidity" class="field">
              <label>Validity Session:</label>
              <ValiditySessionDropdown
                ref="setValiditySession"
                v-model="validitySession"
                market="SET"
                style="width: 100%"
                base-style="height: 2.5rem; line-height: 2.5rem; width: 100%;"
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
              />
            </div>
            <div v-if="market === 'SET'" v-show="validity && ['DATE', 'TIME', 'EXP'].includes(validity) && showValidity" class="field">
              <label>Validity Data:</label>
              <DatePicker
                ref="setValidityDate"
                v-model="validityDate"
                style="height: 2.5rem; line-height: 2.5rem; padding-left: 1rem; padding-top: 4px"
                input-style="height: 2.5rem; margin-top: -4px;"
                :style="validity === 'TIME' ? 'width: calc(50% - 5px)' : 'width: 100%;'"
                name="Validity Date"
                :is-validate="['DATE', 'TIME'].includes(validity)"
                :required="['DATE', 'TIME'].includes(validity)"
                :disabled="validity === 'EXP' || (changeOrderId != null && changeOrderId != undefined && !changeCanDelete)"
                placeholder="Pick a date"
                @change="submit"
              />
            </div>

            <div v-if="market === 'TFEX'" class="field">
              <label>Validity:</label>
              <ValidityDropdown
                ref="validity"
                v-model="validity"
                market="TFEX"
                style="width: 100%"
                base-style="height: 2.5rem; line-height: 2.5rem; width: 100%;"
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
                @enter="
                  () => {
                    this.$nextTick(() => {
                      if (this.validity && this.validity === 'SESS') {
                        nextElement($refs.validitysession, 200);
                      } else if (this.validity && (this.validity === 'DATE' || this.validity === 'TIME')) {
                        nextElement($refs.validitydate, 200);
                      } else {
                        this.submit();
                      }
                    });
                  }
                "
              />
            </div>
            <div v-if="market === 'TFEX'" v-show="validity && validity === 'SESS'" class="field">
              <label>Validity Session:</label>
              <ValiditySessionDropdown
                ref="validitysession"
                v-model="validitySession"
                market="TFEX"
                style="width: 100%"
                base-style="height: 2.5rem; line-height: 2.5rem; width: 100%;"
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
              />
            </div>
            <div v-if="market === 'TFEX'" v-show="validity && ['DATE', 'TIME', 'EXP'].includes(validity)" class="field">
              <label>Validity Data:</label>
              <DatePicker
                ref="validitydate"
                v-model="validityDate"
                style="height: 2.5rem; line-height: 2.5rem; padding-left: 1rem; padding-top: 4px"
                input-style="height: 2.5rem; margin-top: -4px;"
                :style="validity === 'TIME' ? 'width: calc(50% - 5px)' : 'width: 100%;'"
                name="Validity Date"
                :is-validate="['DATE', 'TIME'].includes(validity)"
                :required="['DATE', 'TIME'].includes(validity)"
                :disabled="validity === 'EXP' || (changeOrderId != null && changeOrderId != undefined && !changeCanDelete)"
                placeholder="Pick a date"
                @enter="
                  () => {
                    if (this.validity === 'DATE') {
                      nextElement($refs.validitytime, 200);
                    } else {
                      this.submit();
                    }
                  }
                "
              />
              <TimePicker
                v-if="validity === 'TIME'"
                ref="validitytime"
                v-model="validityTime"
                style="height: 2.5rem; line-height: 2.5rem; margin-left: 10px; padding-left: 1rem; padding-top: 4px; width: calc(50% - 5px)"
                input-style="height: 2.5rem; margin-top: -4px;"
                name="Validity Time"
                :is-validate="validity === 'TIME'"
                required
                placeholder="Select time"
                :disabled="changeOrderId != null && changeOrderId != undefined && !changeCanDelete"
                @enter="submit"
              />
            </div>
          </div>
          <div v-if="userType === 'I'" class="field">
            <label>PIN</label>
            <InputPIN ref="pin" v-model="pin" name="PIN" placeholder="PIN" is-validate required @enter="submit" />
          </div>
        </form>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button type="button" :loading="processing" :disabled="processing" @click="reset">Reset</sui-button>
        <sui-button type="button" positive :loading="processing" :disabled="processing" @click="submit">Confirm</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import AccountDropdown from "@/components/AccountDropdown";
import InputAccount from "@/components/InputAccount";
import InputNumber from "@/components/InputNumber";
import InputPrice from "@/components/InputPrice";
import TTFDropdown from "@/components/TTFDropdown";
import InputPIN from "@/components/InputPIN";
import ValidityDropdown from "@/components/ValidityDropdown";
import ValiditySessionDropdown from "@/components/ValiditySessionDropdown";
import DatePicker from "@/components/DatePicker";
import TimePicker from "@/components/TimePicker";
import { isCancellableOrderStatus } from "@/utils/ValidationUtils";
import { price as PriceFormatter, volume as VolumeFormatter, str2Price, str2Volume } from "@/utils/NumberFormatter";
import { sideFormatter, sideColorStyle, positionFormatter } from "@/components/grid/Formatter";
import { parseEtsDateToUTCDate, parseValidityTimeStrToUTCDate, parseEtsShortDateToUTCDate } from "@/utils/DateUtils";
export default {
  components: {
    AccountDropdown,
    InputAccount,
    InputNumber,
    InputPrice,
    TTFDropdown,
    InputPIN,
    ValidityDropdown,
    ValiditySessionDropdown,
    DatePicker,
    TimePicker,
  },
  data() {
    return {
      PriceFormatter,
      processing: false,
      dialog: false,
      market: "SET",
      changeOrderId: null,
      changeCanDelete: false,
      order: {},
      orderId: null,
      side: null,
      sideStyle: null,
      position: null,
      symbol: null,
      fullSymbol: null,
      volume: null,
      price: null,
      account: null,
      ttf: null,
      iceberg: null,
      cond: null,
      pin: null,
      validity: null,
      validityDate: null,
      validitySession: null,
      validityTime: null,
      resolve: null,
      inputsFilterable: ["InputNumber", "InputPrice", "InputPIN", "AccountDropdown"],
      showValidity: false,
    };
  },
  watch: {
    validity(value) {
      if (value && value === "EXP") {
        if (this.fullSymbol) {
          this.validityDate = this.$moment(this.fullSymbol.expDate, "DD/MM/YYYY").toDate();
        }
      } else {
        this.validityDate = new Date(this.dtsBusinessDate);
      }
    },
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        setTimeout(() => {
          this.order = {};
          this.changeOrderId = null;
          this.changeCanDelete = false;
          this.orderId = null;
          this.side = null;
          this.position = null;
          this.symbol = null;
          this.fullSymbol = null;
          this.volume = null;
          this.price = null;
          this.account = null;
          this.ttf = null;
          this.iceberg = null;
          this.pin = null;
          this.validity = null;
          this.validityDate = null;
          this.validitySession = null;
          this.validityTime = null;
          this.$nextTick(() => {
            this.clearValidate();
            this.resolve(null);
          });
        }, 200);
      });
    },
    reset() {
      this.init(this.order);
      this.clearValidate();
    },
    clearValidate() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.content.$children.forEach((c) => {
            if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
              c.clearValidate();
            }
          });
        }, 10);
      });
    },
    nextElement(nextElement, timeout) {
      if (!nextElement) {
        this.$nextTick(() => {
          this.submit();
        });
        return;
      }
      if (nextElement.isDisabled()) {
        this.$nextTick(() => {
          this.submit();
        });
        return;
      }
      if (timeout) {
        setTimeout(() => {
          nextElement.select();
        }, timeout);
      } else {
        nextElement.select();
      }
    },
    init(order) {
      this.order = order;
      console.log("test init");

      if (order.condition) {
        this.showValidity = false;
      }

      console.log("test order condition", order);

      if (order.validity && !order.condition) {
        this.showValidity = true;
        // console.log('test validity')
        // Goodtil
        this.$nextTick(() => {
          this.validity = order.validity;
          console.log("test validity", this.validity);
          if (this.validity === "DATE") {
            this.$nextTick(() => {
              this.validityDate = parseEtsShortDateToUTCDate(order.validityData);
            });
          } else if (this.validity === "TIME") {
            const date = parseValidityTimeStrToUTCDate(order.validityData);
            this.$nextTick(() => {
              this.validityDate = date;
              this.validityTime = date;
            });
          } else if (this.validity === "SESS") {
            this.$nextTick(() => {
              try {
                this.validitySession = parseInt(order.validityData);
              } catch (err) {
                this.validitySession = null;
              }
            });
          }
        });
      }

      if (order.exchangeId == 1) {
        this.market = "SET";
        this.$nextTick(() => {
          this.ttf = order.ttf;
        });
      } else if (order.exchangeId == 2) {
        this.market = "TFEX";
        this.$nextTick(() => {
          this.position = positionFormatter({
            value: order.contractPosition,
          });
        });
      } else {
        this.market = null;
      }
      this.changeOrderId = order.orderId;
      this.changeCanDelete = isCancellableOrderStatus(order);

      this.orderId = order.orderId;
      this.symbol = order.symbol;
      this.side = sideFormatter(
        {
          value: order.side,
        },
        this.market
      );
      this.sideStyle = sideColorStyle({
        value: order.side,
      });
      delete this.sideStyle["font-family"];
      delete this.sideStyle["font-weight"];
      delete this.sideStyle["font-size"];
      this.volume = str2Volume(order.volume);
      this.iceberg = str2Volume(order.icebergVol);
      this.price = str2Price(order.price);
      this.$nextTick(() => {
        setTimeout(() => {
          this.account = order.accountCode;
        }, 100);
      });
    },
    setFullSymbol(fullSymbol) {
      this.processing = false;
      this.fullSymbol = fullSymbol;
    },
    changeOrder(order, fullSymbol) {
      if (fullSymbol) {
        this.fullSymbol = fullSymbol;
      } else {
        // wait from OrderEntry
        this.processing = true;
      }
      this.init(order);
      this.dialog = true;
      this.$nextTick(() => {
        if (this.market === "SET") {
          this.processing = false;
        }
        setTimeout(() => {
          if (this.changeCanDelete) {
            this.$refs.volume.select();
          } else {
            this.$refs.account.select();
          }
        }, 100);
      });
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    submit() {
      let validate = true;
      const filterErrors = this.$refs.content.$children.filter((c) => {
        if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
          if (c.isValidate) {
            const result = c.validate();
            validate &= result;
            return !result;
          } else {
            c.clearValidate();
          }
        }
        return false;
      });

      if (validate) {
        let positionHtml = null;
        if (this.market === "TFEX") {
          positionHtml = `
            <div class="right aligned column" style="padding-right: 1rem !important;">Position:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.position}</div>
          `;
        }
        const html = `
          <div class="ui two column grid">
            <div class="right aligned column" style="padding-right: 1rem !important;">${this.side}:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.symbol}</div>
            ${positionHtml ? positionHtml : ""}
            <div class="right aligned column" style="padding-right: 1rem !important;">Volume:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${VolumeFormatter(this.volume)}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Price:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${PriceFormatter(this.price)}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Account:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.account}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Total Amount:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${PriceFormatter(this.price * this.volume)}</div>
          </div>
        `;
        this.$Swal
          .fire({
            title: "Order Change Confirmation",
            html: html,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              let newValue = {};
              newValue.volume = this.volume;
              newValue.price = this.price;
              newValue.account = this.account;
              newValue.iceberg = this.iceberg;
              newValue.pin = this.pin;
              newValue.validity = this.validity;

              if (this.market === "SET") {
                newValue.ttf = this.ttf;
                if (this.validity == "DATE") {
                  newValue.validityData = this.validityDate;
                } else if (this.validity == "SESS") {
                  newValue.validityData = this.validitySession;
                }
              } else if (this.market === "TFEX") {
                newValue.validityDate = this.validityDate;
                newValue.validitySession = this.validitySession;
                newValue.validityTime = this.validityTime;
              }

              this.resolve({
                changeCanDelete: this.changeCanDelete,
                oldOrder: this.order,
                newValue: newValue,
              });
              this.close();
            } else {
              return false;
            }
          });
      } else {
        filterErrors[0].select();
      }
    },
    onVolumeEnter() {
      const oldVol = str2Volume(this.order.volume);
      const oldIceberg = str2Volume(this.order.icebergVol);
      const newVol = this.$refs.volume.rawNumber;
      if (oldIceberg && oldIceberg != oldVol) {
        if (newVol < oldIceberg) {
          this.iceberg = newVol;
        }
      }
      this.nextElement(this.$refs.price);
    },
  },
  computed: {
    disabledAccount: function () {
      if (this.changeOrderId) {
        return false;
      }
      return true;
    },
    traderType: get("global/traderType"),
    userType: get("model/systemRefData@userType"),
    dtsBusinessDate: get("model/systemRefData@dtsBusinessDate"),
  },
};
</script>

<style>
.ht-order-change-modal h5 {
  line-height: 2rem !important;
}
.ht-order-change-modal .ui.inverted.header {
  color: #dcddde;
}
</style>
