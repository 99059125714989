<template>
  <div
    class="ui input trade-report-control-key-input"
    :class="{ error: showError, disabled: disabled }"
    :data-tooltip="'Enter Trade Report Control Key'"
    :data-position="'top center'"
    data-inverted="inverted"
  >
    <input
      ref="tradeReportControlKeyInput"
      :value="value"
      :placeholder="'Control Key'"
      :maxlength="maxlength"
      :disabled="disabled"
      @input="onInput"
      @keydown="onKeyDown"
      @keyup="onKeyUp"
    />
    <div class="validation-fail" v-show="showError">{{ errorMessage }}</div>
  </div>
</template>

<script setup>
import { ref, nextTick } from "vue";

const props = defineProps({
  value: { type: String, default: "" },
  disabled: { type: Boolean, value: false },
  isValidate: { type: Boolean, value: false },
});

const emits = defineEmits(["input"]);

const showError = ref(false);
const errorMessage = ref("Invalid Control Key");
const tradeReportControlKeyInput = ref(null);

const maxlength = 2;

const onInput = (event) => {
  const inputValue = event.target.value || "";
  emits("input", inputValue);

  if (props.isValidate) {
    nextTick(() => {
      validate();
    });
  }
};

const onKeyDown = (event) => {
  if (event.key === "Enter") event.preventDefault();
};

const onKeyUp = (event) => {
  if (event.key === "Enter") {
    event.preventDefault();

    if (props.isValidate) validate();
    emits("enter");
  }
};

const validate = () => {
  let result = true;
  errorMessage.value = "Invalid Control Key";

  if (!props.value) {
    showError.value = !result;
    return result;
  }

  if (props.value.length < maxlength) {
    result = false;
    showError.value = !result;
    errorMessage.value = "Control key must be 2 characters";

    return result;
  }

  for (let index = 0; index < props.value.length; index++) {
    const asciiCode = props.value.charCodeAt(index);

    if (!(asciiCode >= 43 && asciiCode <= 126 && asciiCode !== 63 && asciiCode !== 64)) {
      result = false;
      errorMessage.value = 'Control key must not includes "?" and "@"';
    }
  }

  showError.value = !result;
  return result;
};

const select = () => {
  if (tradeReportControlKeyInput.value) {
    tradeReportControlKeyInput.value.select();
  }
};
</script>

<style lang="scss" scoped>
.trade-report-control-key-input {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;

  input {
    height: 2rem;
    padding: 0 0.25rem !important;
  }

  .validation-fail {
    color: red;
  }
}
</style>
