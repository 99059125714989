/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class XS01 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._userId = new NumberMessageBase();
		this._username = new StringMessageBase();
		this._userType = new StringMessageBase();
		this._userFullName = new StringMessageBase();
		this._userSettings = new StringMessageBase();
		this._newsServerUrl = new StringMessageBase();
		this._licenseFlags = new StringMessageBase();
		this._accounts = new ArrayMessageBase(Account);
		this._markets = new ArrayMessageBase(Market);
		this._securities = new ArrayMessageBase(Security);
		this._indexes = new ArrayMessageBase(Index);
		this._favorites = new ArrayMessageBase(Favorite);
		this._advTraderFlag = new StringMessageBase();
		this._goodtillTraderFlag = new StringMessageBase();
		this._notifications = new ArrayMessageBase(Notification);
		this._upperLimitFlag = new StringMessageBase();
		this._nightMode = new StringMessageBase();
		this._bussinessDate = new StringMessageBase();
		this._exchanges = new ArrayMessageBase(Exchange);
		this._technicalGraphFlags = new StringMessageBase();
		this._technicalGraphUrl = new StringMessageBase();
		this._technicalGraphApiUrl = new StringMessageBase();
		this._dtsSecurities = new ArrayMessageBase(DtsSecurity);
		this._dtsMarkets = new ArrayMessageBase(DtsMarket);
		this._dtsIndexes = new ArrayMessageBase(DtsIndex);
		this._pinDigitsOnly = new StringMessageBase();
		this._pinMinLength = new NumberMessageBase();
		this._pinMaxLength = new NumberMessageBase();
		this._pinCanChange = new StringMessageBase();
		this._dtsBusinessDate = new StringMessageBase();
		this._traders = new ArrayMessageBase(Trader);
		this._otpEnable = new StringMessageBase();
		this._otpEmailEnable = new StringMessageBase();
		this._otpTOTPEnable = new StringMessageBase();
		this._otpTypes = new ArrayMessageBase(OtpType);
		this._otpEmail = new StringMessageBase();
		this._dtsNightMode = new StringMessageBase();
		this._priceAlertLimit = new NumberMessageBase();
		this._passwordCanChange = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get userId() {
		return this._userId;
	}
	
	get username() {
		return this._username;
	}
	
	get userType() {
		return this._userType;
	}
	
	get userFullName() {
		return this._userFullName;
	}
	
	get userSettings() {
		return this._userSettings;
	}
	
	get newsServerUrl() {
		return this._newsServerUrl;
	}
	
	get licenseFlags() {
		return this._licenseFlags;
	}
	
	get accounts() {
		return this._accounts;
	}
	
	get markets() {
		return this._markets;
	}
	
	get securities() {
		return this._securities;
	}
	
	get indexes() {
		return this._indexes;
	}
	
	get favorites() {
		return this._favorites;
	}
	
	get advTraderFlag() {
		return this._advTraderFlag;
	}
	
	get goodtillTraderFlag() {
		return this._goodtillTraderFlag;
	}
	
	get notifications() {
		return this._notifications;
	}
	
	get upperLimitFlag() {
		return this._upperLimitFlag;
	}
	
	get nightMode() {
		return this._nightMode;
	}
	
	get bussinessDate() {
		return this._bussinessDate;
	}
	
	get exchanges() {
		return this._exchanges;
	}
	
	get technicalGraphFlags() {
		return this._technicalGraphFlags;
	}
	
	get technicalGraphUrl() {
		return this._technicalGraphUrl;
	}
	
	get technicalGraphApiUrl() {
		return this._technicalGraphApiUrl;
	}
	
	get dtsSecurities() {
		return this._dtsSecurities;
	}
	
	get dtsMarkets() {
		return this._dtsMarkets;
	}
	
	get dtsIndexes() {
		return this._dtsIndexes;
	}
	
	get pinDigitsOnly() {
		return this._pinDigitsOnly;
	}
	
	get pinMinLength() {
		return this._pinMinLength;
	}
	
	get pinMaxLength() {
		return this._pinMaxLength;
	}
	
	get pinCanChange() {
		return this._pinCanChange;
	}
	
	get dtsBusinessDate() {
		return this._dtsBusinessDate;
	}
	
	get traders() {
		return this._traders;
	}
	
	get otpEnable() {
		return this._otpEnable;
	}
	
	get otpEmailEnable() {
		return this._otpEmailEnable;
	}
	
	get otpTOTPEnable() {
		return this._otpTOTPEnable;
	}
	
	get otpTypes() {
		return this._otpTypes;
	}
	
	get otpEmail() {
		return this._otpEmail;
	}
	
	get dtsNightMode() {
		return this._dtsNightMode;
	}
	
	get priceAlertLimit() {
		return this._priceAlertLimit;
	}
	
	get passwordCanChange() {
		return this._passwordCanChange;
	}
}

export class Account {
	constructor() {
		this._accountCode = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
		this._advItwFlag = new StringMessageBase();
		this._goodtillItwFlag = new StringMessageBase();
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	get advItwFlag() {
		return this._advItwFlag;
	}
	
	get goodtillItwFlag() {
		return this._goodtillItwFlag;
	}
}

export class Market {
	constructor() {
		this._marketId = new NumberMessageBase();
		this._name = new StringMessageBase();
		this._mktStCode = new StringMessageBase();
		this._up = new StringMessageBase();
		this._down = new StringMessageBase();
		this._unchange = new StringMessageBase();
		this._buyValue = new StringMessageBase();
		this._sellValue = new StringMessageBase();
		this._openValue = new StringMessageBase();
		this._totalMktVal = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
	}
	
	get marketId() {
		return this._marketId;
	}
	
	get name() {
		return this._name;
	}
	
	get mktStCode() {
		return this._mktStCode;
	}
	
	get up() {
		return this._up;
	}
	
	get down() {
		return this._down;
	}
	
	get unchange() {
		return this._unchange;
	}
	
	get buyValue() {
		return this._buyValue;
	}
	
	get sellValue() {
		return this._sellValue;
	}
	
	get openValue() {
		return this._openValue;
	}
	
	get totalMktVal() {
		return this._totalMktVal;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
}

export class Security {
	constructor() {
		this._securityId = new NumberMessageBase();
		this._symbol = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
}

export class Index {
	constructor() {
		this._indexId = new NumberMessageBase();
		this._name = new StringMessageBase();
		this._prior = new StringMessageBase();
		this._last = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._value = new StringMessageBase();
	}
	
	get indexId() {
		return this._indexId;
	}
	
	get name() {
		return this._name;
	}
	
	get prior() {
		return this._prior;
	}
	
	get last() {
		return this._last;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get volume() {
		return this._volume;
	}
	
	get value() {
		return this._value;
	}
}

export class Favorite {
	constructor() {
		this._favoriteId = new NumberMessageBase();
		this._name = new StringMessageBase();
	}
	
	get favoriteId() {
		return this._favoriteId;
	}
	
	get name() {
		return this._name;
	}
}

export class Notification {
	constructor() {
		this._name = new StringMessageBase();
	}
	
	get name() {
		return this._name;
	}
}

export class Exchange {
	constructor() {
		this._id = new NumberMessageBase();
		this._name = new StringMessageBase();
	}
	
	get id() {
		return this._id;
	}
	
	get name() {
		return this._name;
	}
}

export class DtsSecurity {
	constructor() {
		this._securityId = new NumberMessageBase();
		this._symbol = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
		this._securityType = new StringMessageBase();
		this._marketId = new StringMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	get securityType() {
		return this._securityType;
	}
	
	get marketId() {
		return this._marketId;
	}
}

export class DtsMarket {
	constructor() {
		this._marketId = new NumberMessageBase();
		this._name = new StringMessageBase();
		this._mktStCode = new StringMessageBase();
		this._up = new StringMessageBase();
		this._down = new StringMessageBase();
		this._unchange = new StringMessageBase();
		this._buyValue = new StringMessageBase();
		this._sellValue = new StringMessageBase();
		this._openValue = new StringMessageBase();
		this._totalMktVal = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
	}
	
	get marketId() {
		return this._marketId;
	}
	
	get name() {
		return this._name;
	}
	
	get mktStCode() {
		return this._mktStCode;
	}
	
	get up() {
		return this._up;
	}
	
	get down() {
		return this._down;
	}
	
	get unchange() {
		return this._unchange;
	}
	
	get buyValue() {
		return this._buyValue;
	}
	
	get sellValue() {
		return this._sellValue;
	}
	
	get openValue() {
		return this._openValue;
	}
	
	get totalMktVal() {
		return this._totalMktVal;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
}

export class DtsIndex {
	constructor() {
		this._indexId = new NumberMessageBase();
		this._name = new StringMessageBase();
		this._prior = new StringMessageBase();
		this._last = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._value = new StringMessageBase();
	}
	
	get indexId() {
		return this._indexId;
	}
	
	get name() {
		return this._name;
	}
	
	get prior() {
		return this._prior;
	}
	
	get last() {
		return this._last;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get volume() {
		return this._volume;
	}
	
	get value() {
		return this._value;
	}
}

export class Trader {
	constructor() {
		this._traderCode = new StringMessageBase();
		this._exchangeId = new NumberMessageBase();
		this._status = new StringMessageBase();
		this._type = new StringMessageBase();
	}
	
	get traderCode() {
		return this._traderCode;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	get status() {
		return this._status;
	}
	
	get type() {
		return this._type;
	}
}

export class OtpType {
	constructor() {
		this._type = new StringMessageBase();
	}
	
	get type() {
		return this._type;
	}
}

export default XS01;
