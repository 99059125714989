import { ref, computed, getCurrentInstance, watch, onMounted, onBeforeUnmount, nextTick } from "vue";
import store from "@/store";

import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils";
import { SecurityItem } from "@/messages/websocket/XR03";

export default function useMarketView() {
  const vm = getCurrentInstance().proxy;
  const marketTickerSETHeight = ref(0);
  const marketTickerTFEXHeight = ref(0);
  const marketBBOContainer = ref(null);
  const showAllBBOMode = ref(false);
  const marketTickerSETContainer = ref(null);
  const marketTickerTFEXContainer = ref(null);
  const maxBBOLevel = ref(5);

  const currentQuoteSelectedSymbol = computed(() => store.getters["global/currentQuoteSelectedSymbol"]);

  const currentMarketWatchList = computed(() => store.getters["global/currentDisplayMarketWatchList"]);
  const orderEntryModeOrder = computed(() => store.getters["global/modeOrder"]);

  const tickerFilterSET = computed({
    get() {
      return store.getters["global/tickerFilterSET"];
    },
    set(value) {
      store.commit("global/SET_TICKER_FILTER_SET", value);
    },
  });

  const tickerFilterTFEX = computed({
    get() {
      return store.getters["global/tickerFilterTFEX"];
    },
    set(value) {
      store.commit("global/SET_TICKER_FILTER_TFEX", value);
    },
  });

  const tickerFilterModeSum = computed({
    get() {
      return store.getters["global/tickerFilterModeSum"];
    },
    set(value) {
      store.commit("global/SET_TICKER_FILTER_MODE_SUM", value);
    },
  });

  const fontSize = computed(() => {
    const settings = store.getters["global/settings"];

    return settings?.fontSize || "1";
  });

  const reCalculateMarketTickerSETHeight = () => {
    if (marketTickerSETContainer.value) {
      if (!tickerFilterSET.value) {
        marketTickerSETHeight.value = 0;
      } else {
        marketTickerSETHeight.value = marketTickerSETContainer.value.offsetHeight;
      }
    } else {
      marketTickerSETHeight.value = 0;
    }
  };

  const reCalculateMarketTickerTFEXHeight = () => {
    if (marketTickerTFEXContainer.value) {
      if (!tickerFilterSET.value) {
        marketTickerTFEXHeight.value = 0;
      } else {
        marketTickerTFEXHeight.value = marketTickerTFEXContainer.value.offsetHeight;
      }
    } else {
      marketTickerTFEXHeight.value = 0;
    }
  };

  const reCalculateMarketTickerHeight = () => {
    nextTick(() => {
      reCalculateMarketTickerSETHeight();
      reCalculateMarketTickerTFEXHeight();
    });
  };

  const onSwitchLevel = (isShowAllBBO) => {
    vm.$EventBus.$emit("market-bbo-size-change");
    showAllBBOMode.value = isShowAllBBO;

    if (maxBBOLevel.value === 5) {
      maxBBOLevel.value = 10;
    } else {
      maxBBOLevel.value = 5;
    }
  };

  const sendSubscribeQuoteInfo = () => {
    //PriceOpenTradeBcst
    const pb03Msg = vm.$messageFactory.createMessage("XR03");
    //PriceProjectionBcst
    const pb04Msg = vm.$messageFactory.createMessage("XR03");

    //6-10 Level BBOs
    const pb22Msg = vm.$messageFactory.createMessage("XR03");

    pb03Msg.messageCode.set("PB03");
    pb04Msg.messageCode.set("PB04");
    pb22Msg.messageCode.set("PB22");

    const securityItem = new SecurityItem();
    securityItem.securityId = Number(currentQuoteSelectedSymbol.value.value.securityId);

    pb22Msg.securityList.set([securityItem]);
    pb03Msg.securityList.set([securityItem]);
    pb04Msg.securityList.set([securityItem]);

    vm.$ws.send(pb22Msg);
    vm.$ws.send(pb03Msg);
    vm.$ws.send(pb04Msg);
  };

  const sendSubscribeMarketWatchInfo = () => {
    //bbos
    const pb21Msg = vm.$messageFactory.createMessage("XR03");

    pb21Msg.messageCode.set("PB21");

    let resolvedSecurityList = [];

    if (currentMarketWatchList.value?.length) {
      resolvedSecurityList = currentMarketWatchList.value.map((security) => {
        const securityItem = new SecurityItem();
        securityItem.securityId = Number(security.securityId);
        return securityItem;
      });
    }

    if (currentQuoteSelectedSymbol.value) {
      const securityItem = new SecurityItem();
      securityItem.securityId = Number(currentQuoteSelectedSymbol.value.value.securityId);

      const duplicateSecurity = resolvedSecurityList.find((security) => security.securityId.get() === securityItem.securityId.get());

      if (!duplicateSecurity) resolvedSecurityList.push(securityItem);
    }

    if (!resolvedSecurityList.length) return;

    pb21Msg.securityList.set(resolvedSecurityList);
    vm.$ws.send(pb21Msg);

    if (isEnableDTS()) {
      //DTS bbo 1-5 level
      const pb52Msg = vm.$messageFactory.createMessage("XR03");

      pb52Msg.messageCode.set("PB52");
      pb52Msg.securityList.set(resolvedSecurityList);
      vm.$ws.send(pb52Msg);
    }
  };

  const removeSubscription = () => {
    unSubscribeMarketWatch();
    unSubscribeQuote();

    //ticker
    const pb01Msg = vm.$messageFactory.createMessage("XR03");

    pb01Msg.messageCode.set("PB01");
    pb01Msg.mode.set("D");

    vm.$ws.send(pb01Msg);
  };

  const unSubscribeMarketWatch = () => {
    const pb21Msg = vm.$messageFactory.createMessage("XR03");

    pb21Msg.messageCode.set("PB21");
    pb21Msg.mode.set("D");
    vm.$ws.send(pb21Msg);

    if (isEnableDTS()) {
      const pb52Msg = vm.$messageFactory.createMessage("XR03");

      pb52Msg.messageCode.set("PB52");
      pb52Msg.mode.set("D");
      vm.$ws.send(pb52Msg);
    }
  };

  const unSubscribeQuote = () => {
    const pb03Msg = vm.$messageFactory.createMessage("XR03");
    const pb04Msg = vm.$messageFactory.createMessage("XR03");
    const pb22Msg = vm.$messageFactory.createMessage("XR03");

    pb03Msg.messageCode.set("PB03");
    pb04Msg.messageCode.set("PB04");
    pb22Msg.messageCode.set("PB22");
    pb03Msg.mode.set("D");
    pb04Msg.mode.set("D");
    pb22Msg.mode.set("D");

    vm.$ws.send(pb03Msg);
    vm.$ws.send(pb04Msg);
    vm.$ws.send(pb22Msg);
  };

  watch(tickerFilterSET, () => {
    reCalculateMarketTickerHeight();
  });

  watch(tickerFilterTFEX, () => {
    reCalculateMarketTickerHeight();
  });

  watch(fontSize, () => {
    reCalculateMarketTickerHeight();
  });

  watch([currentQuoteSelectedSymbol, currentMarketWatchList], ([newCurrentQuote, newDisplayList], [oldCurrentQuote, oldDisplayList]) => {
    if (orderEntryModeOrder.value) return;

    if (newCurrentQuote && newCurrentQuote?.symbol !== oldCurrentQuote?.symbol) sendSubscribeQuoteInfo();

    if (!newCurrentQuote) unSubscribeQuote();

    const newMarketWatchList = newDisplayList || [];
    const oldMarketWatchList = oldDisplayList || [];

    if (!newMarketWatchList.length && !oldMarketWatchList.length && !newCurrentQuote) return unSubscribeMarketWatch();

    if (newMarketWatchList.length !== oldMarketWatchList.length) return sendSubscribeMarketWatchInfo();

    if (!newMarketWatchList.length && newCurrentQuote) return sendSubscribeMarketWatchInfo();

    const notDuplicatedSecurity = newMarketWatchList.find((security) => {
      const duplicateSecurity = oldMarketWatchList.find((watchedSecurity) => watchedSecurity.symbol === security.symbol);

      if (duplicateSecurity) return false;
      return true;
    });

    if (notDuplicatedSecurity) return sendSubscribeMarketWatchInfo();

    const isCurrentQuoteInWatchList = newMarketWatchList.find((security) => security.symbol === newCurrentQuote?.symbol || null);
    const isOldQuoteInWatchList = newMarketWatchList.find((security) => security.symbol === oldCurrentQuote?.symbol || null);

    if (!isCurrentQuoteInWatchList) return sendSubscribeMarketWatchInfo();

    if (!isOldQuoteInWatchList && isCurrentQuoteInWatchList) return sendSubscribeMarketWatchInfo();
  });

  watch(orderEntryModeOrder, async () => {
    if (!orderEntryModeOrder.value) {
      await nextTick();

      if (currentQuoteSelectedSymbol.value) sendSubscribeQuoteInfo();

      return sendSubscribeMarketWatchInfo();
    }

    unSubscribeQuote();
    return unSubscribeMarketWatch();
  });

  onMounted(() => {
    reCalculateMarketTickerHeight();

    if (currentQuoteSelectedSymbol.value) sendSubscribeQuoteInfo();

    sendSubscribeMarketWatchInfo();

    vm.$EventBus.$on("onResize", reCalculateMarketTickerHeight);

    if (!isEnableETS()) {
      tickerFilterSET.value = false;
    }
    if (!isEnableDTS()) {
      tickerFilterTFEX.value = false;
    }
  });

  onBeforeUnmount(() => {
    vm.$EventBus.$off("onResize", reCalculateMarketTickerHeight);
    removeSubscription();
  });

  return {
    marketTickerSETHeight,
    marketTickerTFEXHeight,
    currentQuoteSelectedSymbol,
    tickerFilterSET,
    tickerFilterTFEX,
    tickerFilterModeSum,
    marketBBOContainer,
    showAllBBOMode,
    marketTickerSETContainer,
    marketTickerTFEXContainer,
    maxBBOLevel,
    isEnableETS,
    isEnableDTS,
    onSwitchLevel,
  };
}
