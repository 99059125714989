<template>
  <form class="ui form" @submit.prevent="submit">
    <div v-if="otpTOTPEnable === 'Y'" class="field">
      <label>Time-based One-Time Password</label>
      <sui-checkbox v-model="totp" toggle label="TOTP Status : " class="two-factor" @change="totpChanged" />
      <span class="ui tiny header" :class="totp ? 'green' : 'red'"> {{ totp ? " ON" : " OFF" }}</span>
    </div>
    <div v-if="otpEmailEnable === 'Y'" class="field">
      <label>Email</label>
      <sui-checkbox v-model="email" toggle label="Email Status : " class="two-factor" @change="emailChanged" />
      <span class="ui tiny header" :class="email ? 'green' : 'red'"> {{ email ? " ON" : " OFF" }}</span>
      <div v-if="email" style="padding-top: 0.5rem; text-align: right">
        <sui-input v-model="currentEmail" disabled />
        <button type="button" class="ui mini blue button" style="margin-top: 0.5rem" @click="onChangeEmail">Change Email</button>
      </div>
    </div>
  </form>
</template>

<script>
import { get, sync } from "vuex-pathify";

import _api from "@/api/authentication";
import _otp from "@/api/otp";

export default {
  props: ["hasChanged"],
  data: () => ({
    totp: false,
    email: false,
    currentEmail: "",
    isChange: false,
  }),
  created() {
    this.otpTypes.forEach((otpType) => {
      this[otpType.type] = true;
    });

    if (this.hasChanged) {
      this.isChange = this.hasChanged;
    }

    if (this.otpEmail) {
      this.currentEmail = this.hideRealEmail(this.otpEmail);
    }
  },
  methods: {
    hideRealEmail(email) {
      email = email.split("@");
      return email[0].slice(0, 2) + "******@" + email[1];
    },
    totpChanged(totp) {
      if (totp) {
        this.$Swal
          .fire({
            title: "Are you sure?",
            text: "Do you want to enable TOTP for 2-Factor Authentication.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "<b>Confirm</b>",
            cancelButtonText: "<b>Cancel</b>",
          })
          .then((result) => {
            if (result.value) {
              this.showTOTPQRCode();
            } else {
              this.totp = !totp;
            }
          });
      } else {
        this.$Swal
          .fire({
            title: "Are you sure?",
            text: "Do you want to disable TOTP for 2-Factor Authentication.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "<b>Confirm</b>",
            cancelButtonText: "<b>Cancel</b>",
          })
          .then((result) => {
            if (result.value) {
              this.showConfirmTOTP(false);
            } else {
              this.totp = !totp;
            }
          });
      }
    },
    emailChanged(email) {
      if (email) {
        this.$Swal
          .fire({
            title: "Are you sure?",
            text: "Do you want to enable Email for 2-Factor Authentication.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "<b>Confirm</b>",
            cancelButtonText: "<b>Cancel</b>",
          })
          .then((result) => {
            if (result.value) {
              this.showEmailInput();
            } else {
              this.email = !email;
            }
          });
      } else {
        this.$Swal
          .fire({
            title: "Are you sure?",
            text: "Do you want to disable Email for 2-Factor Authentication.",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "<b>Confirm</b>",
            cancelButtonText: "<b>Cancel</b>",
          })
          .then((result) => {
            if (result.value) {
              this.showDisableEmail();
            } else {
              this.email = !email;
            }
          });
      }
    },
    showTOTPQRCode() {
      _otp
        .getSecret()
        .then(async (res) => {
          let qrcode = "";
          try {
            qrcode = await _otp.getQRCodeArrayBuffer();
          } catch (error) {}
          this.$Swal
            .fire({
              title: "Your Secret Key!",
              html: `<textarea rows="2" cols="40" style="font-family: monospace; resize: none;">${res.data.secret
                .match(/.{1,4}/g)
                .join(" ")}</textarea>
              <br><br>To get the verification code, you can use any TOTP providers such as Google Authenticator, scan qr code or copy and enter the key above into the authenticator application.`,
              imageUrl: qrcode,
              imageWidth: 350,
              imageHeight: 350,
              imageAlt: "QRCode",
              showCancelButton: true,
              confirmButtonText: "<b>Enable</b>",
              cancelButtonText: "<b>Cancel</b>",
            })
            .then((result) => {
              if (result.value) {
                this.showConfirmTOTP(true);
              } else {
                this.totp = false;
              }
            });
        })
        .catch(() => {
          this.totp = false;
        });
    },
    showConfirmTOTP(enable) {
      this.$Swal
        .fire({
          title: "Submit your TOTP Code",
          icon: "question",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "<b>Validate</b>",
          cancelButtonText: "<b>Cancel</b>",
          showLoaderOnConfirm: true,
          preConfirm: (code) => {
            return _otp
              .updateTOTP({ enable: enable, code: code })
              .then((response) => {
                return response;
              })
              .catch(() => {
                this.$Swal.showValidationMessage("The TOTP Code is invalid.");
              });
          },
          allowOutsideClick: true,
          inputValidator: (code) => {
            if (code.length !== 6 || isNaN(code)) {
              return "The code should have 6 digits.";
            }
          },
        })
        .then((result) => {
          if (result.value && result.value.status === 200) {
            this.$Swal.fire("Complete", "Your TOTP status has been changed.", "success").then(() => {
              if (result.value.data.backupCodes?.length) {
                this.showBackupCodes(result.value.data.backupCodes);
              }
            });
            this.totp = enable;
            this.isChange = true;
          } else {
            this.totp = !enable;
          }
        });
    },
    showBackupCodes(backupCodes) {
      backupCodes = backupCodes.join(" ");
      this.$Swal.fire(
        "Your Backup Codes",
        `<textarea rows="16" cols="20" style="font-family: monospace; resize: none;">${backupCodes}</textarea>` +
          "<br><br>The following codes are your backup codes. Keep these backup codes somewhere safe but accessible.",
        "info"
      );
    },
    showEmailInput() {
      this.$Swal
        .fire({
          title: "Submit your Email",
          icon: "question",
          input: "email",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "<b>Submit</b>",
          cancelButtonText: "<b>Cancel</b>",
          showLoaderOnConfirm: true,
          preConfirm: (email) => {
            return _otp
              .requestEmail({ type: "NEW", email: email })
              .then(() => {
                return email;
              })
              .catch(() => {
                this.$Swal.showValidationMessage("Can't send OTP code to your email.");
              });
          },
          allowOutsideClick: true,
        })
        .then((result) => {
          if (result.value) {
            this.showConfirmEmail(true, result.value);
          } else {
            this.email = false;
          }
        });
    },
    showDisableEmail() {
      this.$Swal
        .queue([
          {
            title: "Send the OTP code to your email.",
            icon: "question",
            confirmButtonText: "Send",
            text: "The system will send a code to your email. Please click send to receive the code by email.",
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return _otp
                .requestEmail({ type: "VERIFY" })
                .then(() => {
                  return true;
                })
                .catch(() => {
                  return false;
                });
            },
          },
        ])
        .then((result) => {
          if (result.value) {
            this.showConfirmEmail(false);
          } else {
            this.$Swal.insertQueueStep({
              icon: "error",
              title: "Can't send OTP code to your email.",
            });
            this.email = false;
          }
        });
    },
    showConfirmEmail(enable, email) {
      this.$Swal
        .fire({
          title: "Submit your Email OTP Code",
          icon: "question",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "<b>Validate</b>",
          cancelButtonText: "<b>Cancel</b>",
          showLoaderOnConfirm: true,
          preConfirm: (code) => {
            const arg = {
              enable: enable,
              code: code,
            };
            if (email) {
              arg.email = email;
            }
            return _otp
              .updateEmail(arg)
              .then((response) => {
                return response;
              })
              .catch(() => {
                this.$Swal.showValidationMessage("The Email OTP Code is invalid.");
              });
          },
          allowOutsideClick: true,
          inputValidator: (code) => {
            if (code.length !== 6 || isNaN(code)) {
              return "The code should have 6 digits.";
            }
          },
        })
        .then((result) => {
          if (result.value && result.value.status === 200) {
            this.$Swal.fire("Complete", "Your Email OTP status has been changed.", "success").then(() => {
              if (result.value.data.backupCodes?.length) {
                this.showBackupCodes(result.value.data.backupCodes);
              }
            });
            this.otpEmail = email;
            this.email = enable;
            this.isChange = true;
          } else {
            this.email = !enable;
          }
        });
    },
    onChangeEmail() {
      this.$Swal
        .fire({
          title: "Submit your (New) Email",
          icon: "question",
          input: "email",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "<b>Submit</b>",
          cancelButtonText: "<b>Cancel</b>",
          showLoaderOnConfirm: true,
          preConfirm: (email) => {
            return _otp
              .requestEmail({ type: "CHANGE", email: email })
              .then(() => {
                return email;
              })
              .catch(() => {
                this.$Swal.showValidationMessage("Can't send OTP code to your email.");
              });
          },
          allowOutsideClick: true,
        })
        .then((result) => {
          if (result.value) {
            this.showConfirmEmailOldCode(result.value);
          }
        });
    },
    showConfirmEmailOldCode(newEmail) {
      this.$Swal
        .fire({
          title: "Submit your (Old) Email OTP Code",
          icon: "question",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "<b>Submit</b>",
          cancelButtonText: "<b>Cancel</b>",
          showLoaderOnConfirm: true,
          preConfirm: (code_old) => code_old,
          allowOutsideClick: true,
          inputValidator: (code_old) => {
            if (code_old.length !== 6 || isNaN(code_old)) {
              return "The code should have 6 digits.";
            }
          },
        })
        .then((result) => {
          if (result.value) {
            this.showConfirmEmailNewCode(newEmail, result.value);
          }
        });
    },
    showConfirmEmailNewCode(email, code_old) {
      this.$Swal
        .fire({
          title: "Submit your (New) Email OTP Code",
          icon: "question",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "<b>Validate</b>",
          cancelButtonText: "<b>Cancel</b>",
          showLoaderOnConfirm: true,
          preConfirm: (code_new) => {
            const arg = {
              code_old: code_old,
              code: code_new,
              email: email,
            };
            return _otp
              .updateEmail(arg)
              .then(() => {
                return true;
              })
              .catch(() => {
                return false;
              });
          },
          allowOutsideClick: true,
          inputValidator: (code_new) => {
            if (code_new.length !== 6 || isNaN(code_new)) {
              return "The code should have 6 digits.";
            }
          },
        })
        .then((result) => {
          if (result.value) {
            this.otpEmail = email;
            this.$Swal.fire("Complete", "Your Email OTP status has been changed", "success");
            this.isChange = true;
          } else {
            this.$Swal.fire("Error", "The Old/New Email OTP Code is invalid", "error");
          }
        });
    },
    save() {
      if (this.isChange) {
        this.$Swal.fire("Warning!", "Your setting has been changed.<br>2-Factor Authentication re-login required.", "warning").then(() => {
          _api
            .logout()
            .then((response) => {
              this.$router.replace("/login");
            })
            .catch((error) => error);
        });
        return;
      } else {
        return new Promise((resolve, reject) => {
          resolve();
        });
      }
    },
  },
  watch: {
    totp(totp) {
      let otpTypes = [...this.otpTypes];
      if (totp) {
        const result = this.$_.findIndex(otpTypes, ["type", "totp"]);
        if (result === -1) {
          otpTypes.push({ type: "totp" });
          this.otpTypes = otpTypes;
        }
      } else {
        this.$_.remove(otpTypes, (otpType) => otpType.type === "totp");
        this.otpTypes = otpTypes;
      }
    },
    email(email) {
      let otpTypes = [...this.otpTypes];
      if (email) {
        const result = this.$_.findIndex(otpTypes, ["type", "email"]);
        if (result === -1) {
          otpTypes.push({ type: "email" });
          this.otpTypes = otpTypes;
        }
      } else {
        this.$_.remove(otpTypes, (otpType) => otpType.type === "email");
        this.otpTypes = otpTypes;
      }
    },
    isChange(isChange) {
      if (isChange) {
        this.$EventBus.$emit("2FactorAuthenticationHasChanged");
      }
    },
    otpEmail(otpEmail) {
      this.currentEmail = this.hideRealEmail(otpEmail);
    },
  },
  computed: {
    otpEnable: get("model/systemRefData@otpEnable"),
    otpTOTPEnable: get("model/systemRefData@otpTOTPEnable"),
    otpEmailEnable: get("model/systemRefData@otpEmailEnable"),
    otpTypes: sync("model/systemRefData@otpTypes"),
    otpEmail: sync("model/systemRefData@otpEmail"),
  },
};
</script>

<style>
.two-factor.ui.toggle.checkbox .box:before,
.two-factor.ui.toggle.checkbox label:before {
  background-color: #2f383f !important;
}

.two-factor.ui.toggle.checkbox input:checked ~ .box:before,
.two-factor.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #c88841 !important;
}

.two-factor.ui.toggle.checkbox input:checked ~ .box,
.two-factor.ui.toggle.checkbox input:checked ~ label {
  color: #dcddde !important;
}
</style>
