import { ref, computed, getCurrentInstance, onBeforeMount, watch, onMounted, onBeforeUnmount, nextTick } from "vue";
import store from "@/store";
import { SecurityItem } from "@/messages/websocket/XR03";
import { isEnableDTS } from "@/utils/LicenseUtils";

export default function useCustByStockView() {
  const vm = getCurrentInstance().proxy;
  const isLoading = ref(false);
  const marketType = ref("ALL");

  const currentSelectedAccount = computed(() => store.getters["global/currentSelectedAccount"]);
  const currentQuoteSelectedSymbol = computed(() => store.getters["global/currentQuoteSelectedSymbol"]);
  const orderEntryModeOrder = computed(() => store.getters["global/modeOrder"]);

  const init = () => {
    marketType.value = "ALL";

    if (currentSelectedAccount.value) {
      if (!currentSelectedAccount.value.accountCode || currentSelectedAccount.value.accountCode === "all_accounts") {
        marketType.value = "ALL";
      } else if (currentSelectedAccount.value.accountCode === "all_equity_accounts" || currentSelectedAccount.value.exchangeId == "1") {
        marketType.value = "SET";
      } else if (currentSelectedAccount.value.accountCode === "all_derivative_accounts" || currentSelectedAccount.value.exchangeId == "2") {
        marketType.value = "TFEX";
      }
    }
  };

  const onRefresh = () => {
    vm.$EventBus.$emit("onCustByStockStartRefresh");
    vm.$EventBus.$emit("onQuoteInfoStartRefresh");
  };

  const onCustByStockRefresh = (refreshValue) => () => {
    isLoading.value = refreshValue;
  };

  const XR03 = () => {
    // bbos
    const pb21Msg = vm.$messageFactory.createMessage("XR03");
    const pb22Msg = vm.$messageFactory.createMessage("XR03");

    //PriceLastTradeBcst
    const pb01Msg = vm.$messageFactory.createMessage("XR03");

    //MarketStatus
    const mb03Msg = vm.$messageFactory.createMessage("XR03");

    pb21Msg.messageCode.set("PB21");
    pb22Msg.messageCode.set("PB22");
    pb01Msg.messageCode.set("PB01");
    mb03Msg.messageCode.set("MB03");

    let resolvedSecurityList = [];

    if (currentQuoteSelectedSymbol.value) {
      const securityItem = new SecurityItem();
      securityItem.securityId = Number(currentQuoteSelectedSymbol.value.value.securityId);

      resolvedSecurityList.push(securityItem);
    }

    if (!resolvedSecurityList.length) return;

    pb21Msg.securityList.set(resolvedSecurityList);
    pb22Msg.securityList.set(resolvedSecurityList);
    pb01Msg.securityList.set(resolvedSecurityList);
    mb03Msg.securityList.set(resolvedSecurityList);

    vm.$ws.send(pb21Msg);
    vm.$ws.send(pb22Msg);
    vm.$ws.send(pb01Msg);
    vm.$ws.send(mb03Msg);

    if (isEnableDTS()) {
      //bbos
      const pb52Msg = vm.$messageFactory.createMessage("XR03");
      //PriceLastTradeBcst
      const pb51Msg = vm.$messageFactory.createMessage("XR03");

      pb51Msg.messageCode.set("PB51");
      pb52Msg.messageCode.set("PB52");
      pb51Msg.securityList.set(resolvedSecurityList);
      pb52Msg.securityList.set(resolvedSecurityList);

      vm.$ws.send(pb51Msg);
      vm.$ws.send(pb52Msg);
    }
  };

  const unSubscribe = (code) => {
    const message = vm.$messageFactory.createMessage("XR03");

    message.messageCode.set(code);
    message.mode.set("D");
    vm.$ws.send(message);
  };

  const removeSubscription = () => {
    unSubscribe("PB21");
    unSubscribe("PB22");
    unSubscribe("PB01");
    unSubscribe("MB03");

    if (isEnableDTS()) {
      unSubscribe("PB52");
      unSubscribe("PB51");
    }
  };

  watch(currentSelectedAccount, () => {
    init();
  });

  watch(currentQuoteSelectedSymbol, () => {
    if (currentQuoteSelectedSymbol.value) XR03();
  });

  watch(orderEntryModeOrder, async () => {
    if (!orderEntryModeOrder.value) {
      await nextTick();

      if (currentQuoteSelectedSymbol.value) return XR03();
    }

    return removeSubscription();
  });

  onBeforeMount(() => {
    vm.$EventBus.$on("onCustByStockStartRefresh", onCustByStockRefresh(true));
    vm.$EventBus.$on("onCustByStockStopRefresh", onCustByStockRefresh(false));
    vm.$EventBus.$on("onQuoteInfoStartRefresh", onCustByStockRefresh(true));
    vm.$EventBus.$on("onQuoteInfoStopRefresh", onCustByStockRefresh(false));

    init();
  });

  onMounted(() => {
    if (currentQuoteSelectedSymbol.value) {
      XR03();
    }
  });

  onBeforeUnmount(() => {
    vm.$EventBus.$off("onCustByStockStartRefresh", onCustByStockRefresh(true));
    vm.$EventBus.$off("onCustByStockStopRefresh", onCustByStockRefresh(false));
    vm.$EventBus.$off("onQuoteInfoStartRefresh", onCustByStockRefresh(true));
    vm.$EventBus.$off("onQuoteInfoStopRefresh", onCustByStockRefresh(false));

    removeSubscription();
  });

  return { isLoading, marketType, onRefresh };
}
