<template>
  <div>
    <sui-modal v-model="dialog" size="small" class="ht-ptdeal-approve-modal">
      <sui-modal-header>
        <span>Approve Trade Report</span>
        <div class="close" @click="close"><i class="close grey icon" /></div>
      </sui-modal-header>
      <div style="clear: float;" />
      <sui-modal-content ref="content">
        <div class="ui grid padded">
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0;">Order No.:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0;">{{ currentDeal.orderId }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 v-if="currentDeal.side === 'L'" class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0; color: #2185d0;">
                Long
              </h5>
              <h5
                v-else-if="currentDeal.side === 'S'"
                class="ui header inverted"
                style="display: inline-block; width: 8rem; margin: 0; color: #e03997;"
              >
                Short
              </h5>
              <h5 v-else class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0;">
                Series
              </h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0;">{{ currentDeal.symbol }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0;">Volume:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0;">{{ currentDeal.volume }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0;">Price:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0;">{{ $numeral(currentDeal.price).format("0,0.000000") }}</h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0;">Total Amount:</h5>
              <h5 class="ui header inverted" style="display: inline-block; margin: 0;">
                <template v-if="isNaN(currentDeal.price)">N/A</template>
                <template v-else>{{
                  $numeral($numeral(currentDeal.volume).value() * $numeral(currentDeal.price).value()).format("0,0.000000")
                }}</template>
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h5 class="ui header inverted" style="display: inline-block; width: 8rem; margin: 0;">Board:</h5>
              <h5 v-if="board === 'M'" class="ui header inverted" style="display: inline-block; margin: 0;">Main Board</h5>
              <h5 v-else-if="board === 'B'" class="ui header inverted" style="display: inline-block; margin: 0;">Big Lot Board</h5>
              <h5 v-else-if="board === 'F'" class="ui header inverted" style="display: inline-block; margin: 0;">Foreign Board</h5>
              <h5 v-else-if="board === 'O'" class="ui header inverted" style="display: inline-block; margin: 0;">Odd Lot Board</h5>
              <h5 v-else class="ui header inverted" style="display: inline-block; margin: 0;">-</h5>
            </div>
          </div>
        </div>
        <br />
        <strong v-if="currentDeal.side === 'L'">Long Information</strong>
        <strong v-if="currentDeal.side === 'S'">Short Information</strong>
        <form class="ui form" style="margin-top: 0.5rem;">
          <div class="three fields">
            <div class="field">
              <label>Account ID</label>
              <InputAccount
                ref="account"
                v-model="accountId"
                input-style="width: 12rem;"
                name="Account"
                placeholder="Account ID"
                is-validate
                required
                :sync="false"
                @enter="$refs.position.select()"
              />
            </div>
            <div class="field">
              <label>Position</label>
              <PTDealPositionDropdown ref="position" v-model="position" style="width: 12rem;" @enter="submit" />
            </div>
          </div>
        </form>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button type="button" positive :loading="processing" :disabled="processing" @click="submit">Confirm</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

import InputAccount from "@/components/InputAccount";
import PTDealPositionDropdown from "@/components/PTDealPositionDropdown";

export default {
  components: {
    // InputText,
    InputAccount,
    PTDealPositionDropdown
  },
  data: () => ({
    dialog: false,
    processing: false,

    accountId: null,
    position: null,

    currentDeal: {},
    board: "B",
    dtsPTDealApproveRefId: null,
    inputsFilterable: ["InputAccount"]
  }),
  created() {
    this.$EventBus.$on("ptdealDtsApprove", this.onApprovePTDeal);

    //ptdeal approve
    this.$EventBus.$on("ctord/DS52", this.dtsPTDealApproveCtord);
  },
  beforeDestroy() {
    this.$EventBus.$off("ptdealDtsApprove", this.onApprovePTDeal);

    //ptdeal approve
    this.$EventBus.$off("ctord/DS52", this.dtsPTDealApproveCtord);
  },
  methods: {
    close() {
      this.dialog = false;
    },
    onApprovePTDeal(deal) {
      if (deal.exchangeId == 2) {
        this.dialog = true;
        this.currentDeal = deal;
        this.accountId = "";
        this.position = deal.positionType;
      }
    },
    DR52() {
      this.processing = true;

      const msg = this.$messageFactory.createMessage("DR52");

      const trader = this.$_.find(this.traders, ["exchangeId", 2]);

      msg.side.set(this.currentDeal.side);
      msg.symbol.set(this.currentDeal.symbol);
      msg.vol.set(this.$numeral(this.currentDeal.volume).value());
      msg.price.set(this.$numeral(this.currentDeal.price).value());
      msg.traderId.set(trader.traderCode);
      msg.positionType.set(this.position || "O");
      msg.accountCode.set(this.accountId);
      msg.cpMember.set(this.currentDeal.contractFirm);
      msg.cpTrader.set(this.currentDeal.cpTrader);

      this.dtsPTDealApproveRefId = this.$shortid.generate();
      this.$ws.send(msg, this.dtsPTDealApproveRefId);
    },
    submit() {
      let validate = true;
      const filterErrors = this.$refs.content.$children.filter(c => {
        if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
          if (c.isValidate) {
            const result = c.validate();
            validate &= result;
            return !result;
          } else {
            c.clearValidate();
          }
        }
        return false;
      });

      if (validate) {
        let side = "Series";
        if (this.currentDeal.side === "L") {
          side = "Long";
        } else if (this.currentDeal.side === "S") {
          side = "Short";
        }

        const board = "Big Lot Board";

        let sider = "Short Information";
        if (this.currentDeal.side === "L") {
          sider = "Long Information";
        }

        let position = "Open";
        if (this.position === "C") {
          position = "Close";
        }

        const html = `
          <div class="ui two column grid">
            <div class="right aligned column ht-${side.toLowerCase()}-label" style="padding-right: 1rem !important;">${side}:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.currentDeal.symbol}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Volume:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.currentDeal.volume}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Price:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${
              isNaN(this.currentDeal.price) ? this.currentDeal.price : this.$numeral(this.currentDeal.price).format("0,0.000000")
            }</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Total Amount:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${
              isNaN(this.currentDeal.price)
                ? "N/A"
                : this.$numeral(this.$numeral(this.currentDeal.volume).value() * this.$numeral(this.currentDeal.price).value()).format("0,0.000000")
            }</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Board:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${board}</div>
            <br><br>
            <div class="right aligned column" style="padding-right: 1rem !important;">Approve:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;"><b>${sider}</b></div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Account ID:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.accountId || "-"}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Position:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${position}</div>
          </div>
        `;

        this.$Swal
          .fire({
            title: "Approve Trade Report Confirmation",
            html: html,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            reverseButtons: true
          })
          .then(result => {
            if (result.value) {
              this.DR52();
            }
          });
      } else {
        filterErrors[0].select();
      }
    },
    dtsPTDealApproveCtord(data) {
      if (this.dtsPTDealApproveRefId !== data.refId) {
        return;
      }
      this.processing = false;

      if (data.resultCode === 1001) {
        this.clearForm();
        this.clearValidate();
        this.close();
        this.$Swal.fire(`Complete`, `Your Trade Report number ${data.orderId} has been approved.`, "success");
      } else {
        this.$Swal.fire(`Error ${data.resultCode}!`, data.resultMessage, "error");
      }
    },
    clearForm() {
      this.accountId = null;
      this.position = null;
      this.currentDeal = {};
      this.board = "";
    },
    clearValidate() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.content.$children.forEach(c => {
            if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
              c.clearValidate();
            }
          });
        }, 10);
      });
    }
  },
  computed: {
    theme: sync("global/theme"),
    traders: get("model/systemRefData@traders")
  }
};
</script>

<style>
.ht-ptdeal-approve-modal h5 {
  line-height: 2rem !important;
}
.ht-ptdeal-approve-modal .ui.inverted.header {
  color: #dcddde;
}

.ht-long-label {
  color: #2185d0;
}

.ht-short-label {
  color: #e03997;
}
</style>
