/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class FS06 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._alertId = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get alertId() {
		return this._alertId;
	}
}

export default FS06;
