<template>
  <el-date-picker
    ref="picker"
    v-model="date"
    style="position: relative"
    :disabled="disabled"
    type="date"
    :placeholder="placeholder"
    :picker-options="pickerOptions"
    :data-tooltip="displayErrorText"
    :class="{ error: displayErrorText }"
    data-position="top center"
    data-inverted="inverted"
    :format="format"
    @change="$emit('change', date)"
  >
  </el-date-picker>
</template>

<script>
import { DatePicker } from "element-ui";

export default {
  components: {
    "el-date-picker": DatePicker,
  },
  props: {
    value: {
      type: Date,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: "dd/MM/yyyy",
    },
    startDate: {
      type: Date,
    },
    endDate: {
      type: Date,
    },
    isValidate: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputStyle: {
      type: String,
    },
  },
  emits: ["change"],
  data() {
    return {
      date: this.$moment().startOf("day").toDate(),
      displayErrorText: null,
      pickerOptions: null,
    };
  },
  watch: {
    value(value) {
      this.date = value;
    },
    date(value) {
      this.$emit("input", value);
    },
    errorText(value) {
      this.displayErrorText = value;
    },
  },
  created() {
    const disabledDate = (time) => {
      if (this.startDate && this.endDate) {
        return time.getTime() > this.$moment(this.startDate).startOf("day") && time.getTime() < this.$moment(this.endDate).startOf("day");
      } else if (this.startDate) {
        return time.getTime() < this.$moment(this.endDate).startOf("day");
      } else if (this.endDate) {
        return time.getTime() > this.$moment(this.startDate).startOf("day");
      }
      return false;
    };
    this.pickerOptions = {
      disabledDate,
    };

    if (this.value) {
      this.date = this.value;
    } else {
      this.$emit("input", this.date);
    }
  },
  mounted() {
    if (this.inputStyle) {
      this.$refs.picker.$el.querySelector("input").setAttribute("style", this.inputStyle);
    }
  },
  methods: {
    clearValidate() {
      this.displayErrorText = null;
    },
    validate() {
      this.clearValidate();
      if (this.disabled) {
        return true;
      }

      if (this.required && !this.date) {
        this.displayErrorText = this.name + " is requried.";
        this.$emit("onErrorText", this.displayErrorText);
        return false;
      }

      return true;
    },
    focus() {
      const input = this.$refs.picker.$el.querySelector("input");
      input.focus();
    },
    select() {
      const input = this.$refs.picker.$el.querySelector("input");
      input.select();
    },
    isDisabled() {
      return this.disabled;
    },
  },
};
</script>
