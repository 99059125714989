<template>
  <div class="progress-bar-broker" ref="progress-bar-broker">
    <sui-tab :menu="{ attached: 'top' }" class="progress-bar-broker-tabs">
      <sui-tab-pane class="progress-bar-broker-tab" :title="`Value & Share`" attached="top">
        <apexchart type="bar" :width="dynamicWidth" :height="dynamicHeight" :options="chartOptions" :series="series" :key="updateKey" />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

export default {
  name: "MarketStockGraph",
  components: {},
  props: ["focus"],
  data: () => ({
    isLoading: false,
    dynamicWidth: 100,
    dynamicHeight: 100,
    updateKey: 0,
    series: [
      {
        name: "Buy",
        data: [0, 0],
      },
      {
        name: "Sell",
        data: [0, 0],
      },
    ],
    chartOptions: {
      theme: {
        mode: "dark",
      },
      grid: {
        borderColor: "#2F383F",
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
        },
      },
      chart: {
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
          tools: {
            selection: false,
            zoom: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#2185d0", "#E03997"],
      stroke: {
        width: 0,
        color: "#1B2025",
      },
      xaxis: {
        categories: ["Value", "Share"],
      },
      tooltip: {
        y: {
          formatter: (value, opts) => {
            let pct = "0%";
            if (opts.w.globals.seriesPercent[opts.seriesIndex]?.[opts.dataPointIndex]) {
              pct = opts.w.globals.seriesPercent[opts.seriesIndex][opts.dataPointIndex].toFixed(2) + "%";
            }
            return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ` (${pct})`;
          },
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 0,
      },
    },
  }),
  watch: {
    focus(data) {
      if (data) {
        this.series = [
          {
            name: "Buy",
            data: [data.buyVal, data.sellVal],
          },
          {
            name: "Sell",
            data: [data.sellVal, data.sellShare],
          },
        ];
      } else {
        this.series = [
          {
            name: "Buy",
            data: [0, 0],
          },
          {
            name: "Sell",
            data: [0, 0],
          },
        ];
      }
    },
  },
  created() {
    this.$EventBus.$on("onBrokerStartRefresh", this.onBrokerStartRefresh);
    this.$EventBus.$on("onBrokerStopRefresh", this.onBrokerStopRefresh);
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);

    this.$EventBus.$off("onBrokerStartRefresh", this.onBrokerStartRefresh);
    this.$EventBus.$off("onBrokerStopRefresh", this.onBrokerStopRefresh);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onBrokerStartRefresh");
    },
    onBrokerStartRefresh() {
      this.isLoading = true;
    },
    onBrokerStopRefresh() {
      this.isLoading = false;
    },
    onResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      this.dynamicHeight = 0;
      this.dynamicWidth = 0;

      this.resizeTimeout = setTimeout(() => {
        const chartContainer = this.$refs["progress-bar-broker"];

        this.dynamicHeight = chartContainer.clientHeight - 32;
        this.dynamicWidth = chartContainer.clientWidth - 40;

        this.updateKey++;
      }, 200);
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/company/progress-bar-broker.scss";
</style>
