<template>
  <div class="value-view">
    <sui-tab class="value-view-tabs" :menu="{ attached: 'top' }">
      <sui-tab-pane class="value-view-tab" title="Company Value" attached="top">
        <CompanyValue />
      </sui-tab-pane>
      <sui-tab-pane class="value-view-tab" title="Trader Value" attached="top">
        <TraderValue />
      </sui-tab-pane>
      <sui-tab-pane class="value-view-tab" title="Group Value" attached="top">
        <GroupValue />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import CompanyValue from "@/components/value/CompanyValue.vue";
import GroupValue from "@/components/value/GroupValue.vue";
import TraderValue from "@/components/value/TraderValue.vue";

export default {
  name: "Value",
  components: {
    CompanyValue,
    GroupValue,
    TraderValue,
  },
  data: () => ({
    isLoading: false,
  }),
  created() {
    this.$EventBus.$on("onValueStartRefresh", this.onValueStartRefresh);
    this.$EventBus.$on("onValueStopRefresh", this.onValueStopRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("onValueStartRefresh", this.onValueStartRefresh);
    this.$EventBus.$off("onValueStopRefresh", this.onValueStopRefresh);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onValueStartRefresh");
    },
    onValueStartRefresh() {
      this.isLoading = true;
    },
    onValueStopRefresh() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.value-view {
  height: 100%;

  .value-view-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .value-view-tab {
    display: flex;
    flex-direction: column;
    height: calc(100% - 32px) !important;
  }
}
.column {
  background-color: #262d33 !important;
}

.ht-border {
  border-style: solid;
  border-color: black;
}
</style>
