var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-bar"},[_c('div',{staticClass:"ui tiny fixed menu borderless inverted",class:{ inverted: _vm.theme === 'Dark' },staticStyle:{"height":"3rem","line-height":"3rem","border":"0px"}},[_c('div',{staticClass:"ui fluid container ht-navbar-container nav-bar-content-container"},[_c('div',{staticClass:"ui tiny header item logo-container",staticStyle:{"border":"0px","padding":"0px"}},[_c('div',{ref:"hitradeXLogo",staticClass:"ht-logo-navbar hitrade-x-logo"}),(_vm.showBrokerLogo)?_c('img',{ref:"brokerLogo",staticClass:"broker-logo",staticStyle:{"margin-left":"0.5rem"},attrs:{"src":_vm.logo_path,"alt":"broker-logo"},on:{"error":_vm.replaceByDefault}}):_vm._e()]),_c('div',{staticClass:"menu-list-container"},[(!_vm.brokerMenu)?_c('div',{ref:"nav-menu-list",staticClass:"menu-list"},_vm._l((_vm.menuList),function(menu){return _c('div',{key:menu.title,staticClass:"menu-item"},[_c('router-link',{staticClass:"item",attrs:{"active-class":"active","to":menu.route,"exact":""},nativeOn:{"click":function($event){return _vm.doActionRouter.apply(null, arguments)}}},[_c('h5',[_vm._v(_vm._s(menu.title))])])],1)}),0):_c('div',{staticClass:"broker-menu-list"},_vm._l((_vm.brokerLinks),function(link,index){return _c('router-link',{key:link.name,staticClass:"item",attrs:{"to":index === 0 ? '/brokerpage' : '#',"exact":""}},[_c('h5',[_vm._v(_vm._s(link.name))])])}),1),(!_vm.brokerMenu)?_c('div',{staticClass:"menu-item more-button"},[_c('a',{ref:"nav-menu-more-button",staticClass:"item",class:{
              active: ![
                '/market',
                '/portfolio',
                '/quote',
                '/custbystock',
                '/bbos',
                '/ticker',
                '/technical',
                '/news',
                '/index',
                '/brokerpage',
              ].includes(_vm.$route.path),
            },on:{"click":_vm.openSidebar}},[_c('h5',[_vm._v(_vm._s("More"))])])]):_vm._e()]),_c('div',{staticClass:"right menu",staticStyle:{"padding-right":"0.5rem"}},[_c('div',{staticClass:"ui simple dropdown item"},[_c('i',{staticClass:"user icon"}),_c('span',{staticStyle:{"color":"#dcddde","font-size":"1rem","font-weight":"700"}},[_vm._v(_vm._s(_vm.userType && _vm.userTypeParser(_vm.userType))+" : ")]),_c('span',{staticStyle:{"color":"#e4af0a","font-size":"1rem","font-weight":"700"}},[_vm._v(_vm._s(_vm.userFullName && _vm.userFullNameParser(_vm.userFullName)))]),_c('span',{staticStyle:{"color":"#dcddde","font-size":"1rem","font-weight":"700"}},[_vm._v(" ("+_vm._s(_vm.username)+")")]),_c('i',{staticClass:"dropdown icon"}),_c('div',{staticClass:"menu"},[_c('a',{staticClass:"item",on:{"click":function($event){return _vm.onSettingsClick('settings')}}},[_c('i',{staticClass:"settings icon"}),_vm._v("Settings")]),_c('a',{staticClass:"item",on:{"click":function($event){return _vm.onSettingsClick('about')}}},[_c('i',{staticClass:"info circle icon"}),_vm._v("About")]),_c('div',{staticClass:"divider"}),_c('a',{staticClass:"item",on:{"click":_vm.onLogoutClicked}},[_c('i',{staticClass:"sign out alternate icon"}),_vm._v("Logout")])])])])])]),_c('SettingModal'),_c('ShortcutModal'),_c('AboutModal'),_c('Sidebar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }