/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class SR04 extends WsReqMsg {
	constructor() {
		super(1);
		this._securityId = new NumberMessageBase();
		this._exchangeId = new NumberMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	set securityId(securityId) {
		this._securityId = new NumberMessageBase(securityId);
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	set exchangeId(exchangeId) {
		this._exchangeId = new NumberMessageBase(exchangeId);
	}
}

export default SR04;
