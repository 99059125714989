<template>
  <div>
    <div style="height: 4rem; line-height: 4rem">
      <div class="ui form">
        <div class="fields">
          <div class="field">
            <h5 class="ui grey header inverted" style="line-height: 4rem; margin-left: 1rem">Symbol</h5>
          </div>
          <div class="field">
            <div style="margin-top: 0.5rem; width: 12rem">
              <SymbolDropdown
                ref="symbol"
                v-model="selectedSymbol"
                market="ALL"
                image
                style="min-height: 3rem !important; line-height: 3rem !important; width: 12rem !important; min-width: 12rem !important"
                @enter="$refs.symbol.select()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui grid">
      <div class="sixteen wide column" style="height: calc(55vh - 4rem - 32px - 5px)">
        <ag-grid-vue
          style="width: 100%; height: calc(55vh - 4rem - 32px - 5px); padding: 0px"
          :class="{
            'ag-theme-balham-dark': theme === 'Dark',
            'ag-theme-balham': theme === 'Light',
          }"
          row-selection="single"
          :grid-options="gridAdvertisementOptions"
          :column-defs="columnDefsAdvertisement"
          :row-data="rowAdvertisement"
          :get-row-node-id="getAdvertisementRowNodeId"
          :row-height="21"
          :header-height="32"
          :default-col-def="{ sortable: true, resizable: true }"
          :animate-rows="true"
          :suppress-drag-leave-hides-columns="true"
          :enable-cell-change-flash="true"
          :locale-text="{ noRowsToShow: `Don't have any Advertisement.` }"
          :row-drag-managed="false"
          overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
          @grid-ready="onAdvertisementGridReady"
          @cell-focused="onCellAdvertisementFocused"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SymbolDropdown from "@/components/SymbolDropdown";
import { DatePicker } from "element-ui";

import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsAdvertisement } from "@/components/grid/advertisement/ColumnDefs.js";

Object.freeze(columnDefsAdvertisement);

export default {
  name: "Advertisement",
  components: {
    AgGridVue,
    SymbolDropdown,
  },
  data: () => ({
    columnDefsAdvertisement,

    gridAdvertisementApi: null,
    columnAdvertisementApi: null,
    rowAdvertisement: [],
    selectedSymbol: null,
  }),
  watch: {
    selectedSymbol(selectedSymbol) {
      this.VR01();
    },
  },
  methods: {
    resolveNewsRefresh(isRefresh) {
      if (isRefresh) {
        return this.onNewsStartRefresh();
      }
    },
    onNewsStartRefresh() {
      this.VR01();
    },
    VR01() {
      const msg = this.$messageFactory.createMessage("VR01");

      if (this.selectedSymbol) {
        msg.symbol.set(this.selectedSymbol.value.symbol);
      }

      this.$ws.send(msg);
      if (this.gridAdvertisementApi) {
        this.gridAdvertisementApi.showLoadingOverlay();
      }
    },
    updateRowAdvertisement(advertisements) {
      this.rowAdvertisement = advertisements.advertisementList;
      if (this.gridAdvertisementApi) {
        this.gridAdvertisementApi.hideOverlay();
      }
      this.$EventBus.$emit("onNewsRefresh", false);
    },
    onAdvertisementGridReady(params) {
      this.gridAdvertisementApi = params.api;
      this.columnAdvertisementApi = params.columnApi;
    },
    getAdvertisementRowNodeId(data) {
      return data.time + data.symbol;
    },
    onCellAdvertisementFocused(event) {
      this.gridAdvertisementOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
  created() {
    this.gridAdvertisementOptions = {};
    this.VR01();

    this.$EventBus.$on("ctinf/VS01", this.updateRowAdvertisement);
    this.$EventBus.$on("onNewsRefresh", this.resolveNewsRefresh);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/VS01", this.updateRowAdvertisement);
    this.$EventBus.$off("onNewsRefresh", this.resolveNewsRefresh);
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.ht-news-datepicker .el-range-separator {
  padding: 0px !important;
  margin-top: -10px !important;
  color: #dcddde !important;
}

.ht-news-datepicker .el-input__icon.el-range__icon.el-icon-date {
  margin-top: -10px !important;
}

.el-picker-panel__sidebar {
  background-color: #2f383f !important;
}

.el-picker-panel__shortcut {
  color: #dcddde !important;
}

.el-picker-panel__shortcut:hover {
  color: #409eff !important;
}

.el-date-table__row > .available.in-range > div {
  background-color: #1b2025 !important;
}
</style>
