<template>
  <div class="bbo-view">
    <div class="bbo-container" v-for="(bbo, index) in bboList" :key="index">
      <BBO :value="bbo" :show-all="bbo.isShowAllBBO" @input="onSelectBBO(index, $event)" @switch-level="onSwitchLevel(index, $event)" />
    </div>
  </div>
</template>

<script setup>
import useBBOView from "./hooks/useBBOView";

import BBO from "@/components/bbos/BBO.vue";

const { bboList, onSelectBBO, onSwitchLevel } = useBBOView();
</script>

<style lang="scss" scoped>
@import "@/assets/styles/bbo/bbo-view.scss";
</style>
