import { ref, getCurrentInstance, onBeforeMount, onBeforeUnmount } from "vue";

export default function useInformationView() {
  const vm = getCurrentInstance().proxy;

  const isLoading = ref(false);

  const onRefresh = () => {
    vm.$EventBus.$emit("onInformationStartRefresh");
  };

  const onInformationStartRefresh = () => {
    isLoading.value = true;
  };

  const onInformationStopRefresh = () => {
    isLoading.value = false;
  };

  onBeforeMount(() => {
    vm.$EventBus.$on("onInformationStartRefresh", onInformationStartRefresh);
    vm.$EventBus.$on("onInformationStopRefresh", onInformationStopRefresh);
  });

  onBeforeUnmount(() => {
    vm.$EventBus.$off("onInformationStartRefresh", onInformationStartRefresh);
    vm.$EventBus.$off("onInformationStopRefresh", onInformationStopRefresh);
  });

  return { isLoading, onRefresh };
}
