<template>
  <div class="ticker-full-page" :class="{ black: theme === 'Dark' }">
    <div class="ui grid content-container">
      <div class="four columns row" style="padding-bottom: 0px">
        <div
          v-for="(item, column) in 4"
          :key="column"
          class="column table-container"
          :style="[
            theme === 'Light' ? { 'border-left': '5px solid #000000' } : { 'border-left': '5px solid #000000' },
            column === 0 ? { 'padding-right': '0px', 'border-left': '0px' } : {},
            column === 1 ? { padding: '0px' } : {},
            column === 2 ? { padding: '0px' } : {},
            column === 3 ? { 'padding-left': '0px', 'border-right': '0px' } : {},
            theme === 'Light' ? { 'border-top': '0px solid #e3e5e5' } : { 'border-top': '0px solid #414141' },
          ]"
          ref="table-container"
        >
          <table
            style="border: 0px; border-radius: 0px"
            :class="{ inverted: theme === 'Dark' }"
            class="ui very compact small single line unstackable striped table"
          >
            <thead ref="table-header">
              <TickerHeader />
            </thead>
            <tbody>
              <tr
                v-for="(ticker, index) in arrayTickerColumn[column]"
                :key="index"
                :class="{
                  active: index === lastPosition - (maximumTickers / 4) * column - 1,
                }"
                :style="{ height: `${tableRowHeight}px !important` }"
              >
                <TickerBody :ticker="ticker" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import TickerHeader from "@/components/ticker/TickerHeader.vue";
import TickerBody from "@/components/ticker/TickerBody.vue";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

export default {
  name: "MarketTickerFullPage",
  components: {
    TickerHeader,
    TickerBody,
  },
  props: ["tickerFullPageHeight"],
  data: () => ({
    tickerTaker: null,
    maximumTickers: 0,
    maximumTickersColumn: 0,
    lastPosition: 0,
    arrayTickerColumn: [[], [], [], []],
    fetchTicker: 20,
    tableRowHeight: 21,
    isEnableETS,
    isEnableDTS,
  }),
  watch: {
    currentSelectedFavorite() {
      this.fetchFavoriteIdByCurrentSelectedFavorite();
    },
    tickerFilterSET() {
      this.reload();
    },
    tickerFilterTFEX() {
      this.reload();
    },
    fontSize() {
      return this.reload();
    },
    tickerFilterModeSum() {
      return this.reload();
    },
    modeOrder: {
      async handler(mode) {
        if (!mode) {
          await this.$nextTick();

          return this.fetchFavoriteIdByCurrentSelectedFavorite();
        }

        return this.removeSubscription();
      },
    },
  },
  created() {
    this.tickerBuffer = [];
    this.arrayTicker = [];

    this.$EventBus.$on("bcst/PB01", this.updateTickerFromETS);
    this.$EventBus.$on("bcst/PB51", this.updateTickerFromDTS);
    this.$EventBus.$on("ctinf/PS01", this.updateInfoFromETS);
    this.$EventBus.$on("ctinf/PS51", this.updateInfoFromDTS);
    this.$EventBus.$on("ctinf/SS03", this.stockGroupUpdateCinf);
    this.$EventBus.$on("onResize", this.reload);
  },
  mounted() {
    this.calMaximumTickers();
    this.fetchFavoriteIdByCurrentSelectedFavorite();
    this.takeTickerFromBuffer();
  },
  beforeDestroy() {
    this.$EventBus.$off("bcst/PB01", this.updateTickerFromETS);
    this.$EventBus.$off("bcst/PB51", this.updateTickerFromDTS);
    this.$EventBus.$off("ctinf/PS01", this.updateInfoFromETS);
    this.$EventBus.$off("ctinf/PS51", this.updateInfoFromDTS);
    this.$EventBus.$off("ctinf/SS03", this.stockGroupUpdateCinf);
    this.$EventBus.$off("onResize", this.reload);

    this.removeSubscription();

    if (this.tickerTaker) clearInterval(this.tickerTaker);
  },
  methods: {
    reload() {
      this.tickerBuffer = [];
      this.arrayTicker = [];
      this.arrayTickerColumn = [[], [], [], []];
      this.lastPosition = 0;
      this.calMaximumTickers();
      this.fetchFavoriteIdByCurrentSelectedFavorite();
    },
    calMaximumTickers() {
      const tableHeader = this.$refs["table-header"][0];
      const tableContainer = this.$refs["table-container"][0];
      const vw = Math.max(window.innerWidth);
      const calFontSize = 12 + (4 * Number(this.fontSize || "1") * (vw - 820)) / (1920 - 820);

      const tableHeaderHeight = Math.ceil(tableHeader?.offsetHeight) || 32;
      const tableRowHeight = Math.ceil(calFontSize + 8);

      let maximumTickersColumn = Math.floor(((tableContainer?.offsetHeight || 100) - tableHeaderHeight) / tableRowHeight);
      this.maximumTickersColumn = maximumTickersColumn;
      this.maximumTickers = maximumTickersColumn * 4;
      this.tableRowHeight = tableRowHeight;
    },
    subscribeTicker(favoriteId) {
      this.tickerBuffer = [];
      this.arrayTicker = [];
      this.arrayTickerColumn = [[], [], [], []];

      /* Subscribe all */
      if (this.isEnableETS()) {
        if (!favoriteId) {
          this.XR03("PB01"); /* PriceLastTradeBcst */
        }
        this.PR01(favoriteId);
      }

      if (this.isEnableDTS()) {
        if (!favoriteId) {
          this.XR03("PB51"); /* DtsPriceLastTradeBcst */
        }
        this.PR51(favoriteId);
      }
    },
    fetchFavoriteIdByCurrentSelectedFavorite() {
      if (this.currentSelectedFavorite && this.currentSelectedFavorite !== "Port") {
        const favoriteId = this.$_.find(this.favorites, ["name", this.currentSelectedFavorite]).favoriteId;
        this.subscribeTicker(favoriteId);
        this.SR03(this.currentSelectedFavorite, favoriteId);
      } else {
        this.subscribeTicker();
      }
    },
    PR01(favoriteId) {
      const msg = this.$messageFactory.createMessage("PR01");

      msg.lastTradeCount.set(this.fetchTicker);
      if (favoriteId) {
        msg.filterListType.set("FV");
        msg.filterListId.set(favoriteId);
      }

      this.$ws.send(msg);
    },
    PR51(favoriteId) {
      const msg = this.$messageFactory.createMessage("PR51");

      msg.lastTradeCount.set(this.fetchTicker);
      if (favoriteId) {
        msg.filterListType.set("FV");
        msg.filterListId.set(favoriteId);
      }

      this.$ws.send(msg);
    },
    XR03(messageCode, securities) {
      const msg = this.$messageFactory.createMessage("XR03");

      if (securities) {
        const securityList = securities.map((security) => ({
          securityId: security.securityId,
        }));
        msg.securityList.set(securityList);
      }
      msg.messageCode.set(messageCode);

      this.$ws.send(msg);
    },
    SR03(securityIds, listId) {
      const msg = this.$messageFactory.createMessage("SR03");

      msg.securityIds.set(securityIds);
      msg.listType.set("FV");
      msg.listId.set(listId);
      msg.securityTypes.set("S");

      this.$ws.send(msg);
    },
    stockGroupUpdateCinf(update) {
      if (update.securities.length) {
        /* Subscribe all */
        if (this.isEnableETS()) {
          const etsSecurities = this.$_.filter(update.securities, ["exchangeId", 1]);
          this.XR03("PB01", etsSecurities); /* PriceLastTradeBcst */
        }

        if (this.isEnableDTS()) {
          const dtsSecurities = this.$_.filter(update.securities, ["exchangeId", 2]);
          this.XR03("PB51", dtsSecurities); /* DtsPriceLastTradeBcst */
        }
      }
    },
    updateInfoFromETS(update) {
      if (!this.tickerFilterSET) {
        return;
      }
      this.updateInfo(update, "PS01");
    },
    updateInfoFromDTS(update) {
      if (!this.tickerFilterTFEX) {
        return;
      }
      this.updateInfo(update, "PS51");
    },
    updateInfo(update, code) {
      update.trades.slice(0).forEach((ticker) => {
        ticker.code = code;
        this.tickerToBuffer(ticker);
      });
    },
    updateTickerFromETS(update) {
      if (!this.tickerFilterSET) {
        return;
      }
      update.code = "PB01";
      this.updateTicker(update);
    },
    updateTickerFromDTS(update) {
      if (!this.tickerFilterTFEX) {
        return;
      }
      update.code = "PB51";
      this.updateTicker(update);
    },
    updateTicker(update) {
      this.tickerToBuffer(update);
    },
    tickerToBuffer(ticker) {
      this.tickerBuffer.push(ticker);
    },
    sumTicker(tickerBuffer) {
      let uniqTicker = this.$_.uniqWith(tickerBuffer, (ticker1, ticker2) => {
        return ticker1.time === ticker2.time && ticker1.symbol === ticker2.symbol && ticker1.side === ticker2.side && ticker1.price === ticker2.price;
      });

      uniqTicker.forEach((ticker, index) => {
        let sumVolume = this.$_.sumBy(tickerBuffer, (o) => {
          if (ticker.time === o.time && ticker.symbol === o.symbol && ticker.side === o.side && ticker.price === o.price) {
            return this.$numeral(o.volume).value();
          } else {
            return 0;
          }
        });

        uniqTicker[index].volume = this.$numeral(sumVolume).format("0,0");
      });

      return uniqTicker;
    },
    takeTickerFromBuffer() {
      if (this.tickerTaker) {
        clearInterval(this.tickerTaker);
      }

      this.tickerTaker = setInterval(() => {
        let tickerBuffer = this.tickerBuffer.slice(-parseInt(this.maximumTickers));
        this.tickerBuffer = [];

        if (tickerBuffer.length > 0) {
          if (this.tickerFilterModeSum) {
            const uniqTicker = this.sumTicker(tickerBuffer);
            this.renderTickerFromBuffer(uniqTicker);
          } else {
            this.renderTickerFromBuffer(tickerBuffer);
          }
        }
      }, Number(process.env.VUE_APP_TICKER_RATE));
    },
    renderTickerFromBuffer(tickerBuffer) {
      if (this.lastPosition >= this.maximumTickers) {
        this.lastPosition -= this.maximumTickers;
      }

      if (this.lastPosition + tickerBuffer.length <= this.maximumTickers) {
        this.arrayTicker.splice(this.lastPosition, tickerBuffer.length, ...tickerBuffer);
        this.lastPosition += tickerBuffer.length;
      } else {
        let tickerBufferTail = tickerBuffer.slice(0, this.maximumTickers - this.lastPosition);
        let tickerBufferHead = tickerBuffer.slice(this.maximumTickers - this.lastPosition);

        this.arrayTicker.splice(this.lastPosition, this.maximumTickers, ...tickerBufferTail);
        this.arrayTicker.splice(0, tickerBufferHead.length, ...tickerBufferHead);

        this.lastPosition = tickerBufferHead.length;
      }

      //sort with time

      this.arrayTickerColumn[0].splice(0, this.maximumTickersColumn, ...this.arrayTicker.slice(0, this.maximumTickersColumn));
      this.arrayTickerColumn[1].splice(
        0,
        this.maximumTickersColumn,
        ...this.arrayTicker.slice(this.maximumTickersColumn, this.maximumTickersColumn * 2)
      );
      this.arrayTickerColumn[2].splice(
        0,
        this.maximumTickersColumn,
        ...this.arrayTicker.slice(this.maximumTickersColumn * 2, this.maximumTickersColumn * 3)
      );
      this.arrayTickerColumn[3].splice(0, this.maximumTickers, ...this.arrayTicker.slice(this.maximumTickersColumn * 3, this.maximumTickers));
      for (let index = 0; index <= 3; index++) {
        if (this.arrayTickerColumn[index].length > this.maximumTickersColumn) {
          this.arrayTickerColumn[index].length = this.maximumTickersColumn;
        }
      }
    },
    unSubscribe(code) {
      const message = this.$messageFactory.createMessage("XR03");

      message.messageCode.set(code);
      message.mode.set("D");
      this.$ws.send(message);
    },
    removeSubscription() {
      if (this.isEnableETS()) this.unSubscribe("PB01");
      if (this.isEnableDTS()) this.unSubscribe("PB51");
    },
  },

  computed: {
    theme: sync("global/theme"),
    tickerFilterSET: get("global/tickerFilterSET"),
    tickerFilterTFEX: get("global/tickerFilterTFEX"),
    tickerFilterFavorite: get("global/tickerFilterFavorite"),
    tickerFilterModeSum: get("global/tickerFilterModeSum"),
    favorites: get("model/systemRefData@favorites"),
    currentSelectedFavorite: sync("global/currentSelectedFavorite"),
    fontSize: get("global/settings@fontSize"),
    modeOrder: get("global/modeOrder"),
  },
};
</script>

<style scoped>
.ui.table.inverted td.active,
.ui.table.inverted tr.active {
  background: rgba(224, 224, 224, 0.2) !important;
}

.ticker-full-page {
  height: 100%;
}

.ticker-full-page .content-container {
  height: 100%;
  flex-direction: column;
}

.ticker-full-page .content-container .row {
  flex: 1;
  min-height: 0;
}

.ticker-full-page .table-container {
  background-color: rgb(38, 45, 51) !important;
  height: 100%;
}

@media screen and (max-width: 1440px) {
  .wide-col {
    display: none !important;
  }
}
</style>
