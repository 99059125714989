<template>
  <div class="index-sector-industry">
    <div class="header-section">
      <div class="sector-checkbox-section">
        <div class="sector-checkbox-option">
          <sui-checkbox v-model="selectedSectorIndustry" radio value="Sector" class="custom-checkbox" />
          <div>{{ "Sector" }}</div>
        </div>
        <div class="sector-checkbox-option">
          <sui-checkbox v-model="selectedSectorIndustry" radio value="Industry" class="custom-checkbox" />
          <div>{{ "Industry" }}</div>
        </div>
      </div>
      <div class="market-dropdown-container">
        <div>{{ "Market" }}</div>
        <sui-dropdown
          v-model="selectedMarket"
          fluid
          text="All Markets"
          button
          pointing
          :options="marketOptions"
          class="ht-indexsector-dropdown custom-dropdown"
        />
      </div>
      <div class="date-picker-container">
        <div>{{ "Date" }}</div>
        <el-date-picker v-model="selectedDate" type="date" align="right" format="dd MMM yyyy" :clearable="false" :picker-options="pickerOptions" />
      </div>
    </div>
    <div class="sector-table-section">
      <div class="refresh-icon-container">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" @click="onRefresh" />
      </div>
      <ag-grid-vue
        class="sector-table"
        :class="{
          'ag-theme-balham-dark': theme === 'Dark',
          'ag-theme-balham': theme === 'Light',
        }"
        row-selection="single"
        :grid-options="gridIndexSectorIndustryOptions"
        :column-defs="columnDefsIndexSectorIndustry"
        :row-data="rowIndexSectorIndustry"
        :get-row-node-id="getIndexSectorIndustryRowNodeId"
        :row-height="21"
        :header-height="32"
        :default-col-def="{ sortable: true, resizable: true }"
        :animate-rows="true"
        :suppress-drag-leave-hides-columns="true"
        :enable-cell-change-flash="true"
        :locale-text="{ noRowsToShow: `Don't have any Index.` }"
        :row-drag-managed="false"
        overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
        @grid-ready="onIndexSectorIndustryGridReady"
        @cell-focused="onCellIndexSectorIndustryFocused"
      />
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { DatePicker } from "element-ui";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsIndexSectorIndustry } from "@/components/grid/index/IndexSectorIndustryColumnDefs.js";

Object.freeze(columnDefsIndexSectorIndustry);

export default {
  name: "IndexSectorIndustry",
  components: {
    AgGridVue,
    "el-date-picker": DatePicker,
  },
  data: () => ({
    isLoading: false,
    columnDefsIndexSectorIndustry,

    gridIndexSectorIndustryApi: null,
    columnIndexSectorIndustryApi: null,
    rowIndexSectorIndustry: [],
    selectedSectorIndustry: "Sector",
    selectedMarket: null,
    marketOptions: [
      {
        text: "All Markets",
        value: "",
      },
    ],
    IR01RefId: "",
    selectedDate: new Date(),
    pickerOptions: {
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
    },
  }),
  watch: {
    selectedSectorIndustry(selectedSectorIndustry) {
      this.IR01();
    },
    selectedMarket(selectedMarket) {
      this.IR01();
    },
    indexes() {
      this.initMarketOptions();
    },
    dtsMarkets() {
      this.initMarketOptions();
    },
    selectedDate() {
      this.IR01();
    },
  },
  methods: {
    onRefresh() {
      this.IR01();
    },
    initMarketOptions() {
      if (!this.indexes || !this.dtsMarkets) return;

      this.marketOptions = [
        {
          text: "All Markets",
          value: "",
        },
      ];

      this.indexes.forEach((index) => {
        if (index.name === ".SET" || index.name === ".MAI") {
          let indexName = index.name;
          indexName = indexName.replace(".", "");

          let indexIndexId = index.indexId;

          if (indexName === "MAI") {
            indexName = indexName.toLowerCase();
          }

          this.marketOptions.push({
            text: indexName,
            value: indexIndexId,
          });
        }
      });
    },
    IR01() {
      this.isLoading = true;
      const msg = this.$messageFactory.createMessage("IR01");

      if (this.selectedSectorIndustry === "Sector") {
        msg.indexType.set("3");
      } else if (this.selectedSectorIndustry === "Industry") {
        msg.indexType.set("2");
      }

      msg.marketId.set(this.selectedMarket);
      msg.tradingDate.set(this.$moment(this.selectedDate).format("DD/MM/YYYY"));

      this.IR01RefId = this.$shortid.generate();
      this.$ws.send(msg, this.IR01RefId);
      if (this.gridIndexSectorIndustryApi) {
        this.gridIndexSectorIndustryApi.showLoadingOverlay();
      }
    },
    updateRowIndexSectorIndustry(index) {
      this.rowIndexSectorIndustry = index.indexes;
      if (this.gridIndexSectorIndustryApi) {
        this.gridIndexSectorIndustryApi.hideOverlay();
      }
    },
    onIndexSectorIndustryGridReady(params) {
      this.gridIndexSectorIndustryApi = params.api;
      this.columnIndexSectorIndustryApi = params.columnApi;
    },
    getIndexSectorIndustryRowNodeId(data) {
      return data.indexId;
    },
    onCellIndexSectorIndustryFocused(event) {
      this.gridIndexSectorIndustryOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    indexListCinf(index) {
      if (index.refId !== this.IR01RefId) {
        this.isLoading = false;
        return;
      }

      this.updateRowIndexSectorIndustry(index);
      this.isLoading = false;
    },
  },
  computed: {
    theme: sync("global/theme"),
    indexes: get("model/systemRefData@indexes"),
    dtsMarkets: get("model/systemRefData@dtsMarkets"),
  },
  created() {
    this.gridIndexSectorIndustryOptions = {};
    this.IR01();

    this.initMarketOptions();

    this.$EventBus.$on("ctinf/IS01", this.indexListCinf);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/IS01", this.indexListCinf);
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.index-sector-industry {
  height: 100%;
}

.index-sector-industry .header-section {
  box-sizing: border-box;
  padding: 0 0.5rem;
  margin: 0;
  height: 18%;
  display: flex;
  align-items: stretch;
  color: #dcddde;
  font-size: 1em;
  font-weight: 700;
  column-gap: 1rem;
}

.index-sector-industry .header-section .sector-checkbox-section {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 1rem;
  height: 100%;
}

.index-sector-industry .header-section .sector-checkbox-section .sector-checkbox-option {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.index-sector-industry .header-section .sector-checkbox-section .sector-checkbox-option .custom-checkbox {
  width: 16px;
  height: 16px;
}

.index-sector-industry .header-section .market-dropdown-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 14%;
}

.index-sector-industry .header-section .market-dropdown-container .custom-dropdown {
  height: 30px;
  top: 0 !important;
}

.index-sector-industry .header-section .date-picker-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  width: 14%;
}

.index-sector-industry .sector-table-section {
  height: calc(100% - 18%);
  position: relative;
}

.index-sector-industry .sector-table-section .refresh-icon-container {
  position: absolute;
  top: 5px;
  right: 0.25rem;
  z-index: 1;
}

.index-sector-industry .sector-table-section .refresh-icon-container:hover {
  cursor: pointer;
}

.index-sector-industry .sector-table-section .refresh-icon-container .refresh.icon {
  color: #dcddde;
}

.index-sector-industry .sector-table-section .sector-table {
  height: 100%;
  width: 100%;
  padding: 0;
}

.ui.button.fluid.dropdown {
  background-color: #2f383f;
}

.ui.grey.header {
  color: #dcddde !important;
}

.ui.secondary.menu {
  min-height: unset;
  height: 32px !important;
  border: 0px !important;
  background-color: unset !important;
}
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.ht-indexsector-dropdown {
  height: 2rem;
  min-height: 2rem;
  top: calc(50% - 1.5rem) !important;
}
.ht-indexsector-dropdown .text {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 1rem;
  right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-indexsector-dropdown i.dropdown {
  line-height: 0 !important;
  margin-top: 3px !important;
}
</style>
