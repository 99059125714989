<template>
  <sui-modal v-model="dialog" size="small">
    <sui-modal-header>
      <span>Disclaimer</span>
    </sui-modal-header>
    <div style="clear: float;"></div>
    <sui-modal-content>
      <p style="text-indent: 3rem;">
        Save PIN function allows you to save your PIN until logout or session expired. During this period, re-enter PIN is not required for any
        transaction performed in this trading program only.
      </p>
      <p style="text-indent: 3rem;">
        Therefore, you understand and accept the risk of using this save PIN function. We will take no responsibility on any loss or damage from any
        error occured.
      </p>
      <br />
      <br />
      <div class="ui checkbox" style="margin-left: 3rem; font-weight: bold;" @click="checked = !checked">
        <input v-model="checked" type="checkbox" />
        <label style="color: #00aa00; cursor: pointer;">I agree with all the terms spectified above.</label>
      </div>
    </sui-modal-content>
    <sui-modal-actions>
      <sui-button type="button" positive :disabled="!checked" @click="agree">Agree</sui-button>
      <sui-button type="button" @click="disagree">Disagree</sui-button>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      checked: false,
      resolve: null
    };
  },
  methods: {
    confirm() {
      return new Promise(resolve => {
        this.checked = false;
        this.dialog = true;
        this.resolve = resolve;
      });
    },
    agree() {
      if (!this.checked) {
        return;
      }
      this.dialog = false;
      this.resolve(true);
    },
    disagree() {
      this.dialog = false;
      this.resolve(false);
    }
  }
};
</script>
