import { baseStyle } from "../Formatter.js";
import { EventBus } from "@/event-bus";

const columnDefs = [
  {
    headerName: "Function",
    width: 200,
    field: "funcName",
    cellStyle: baseStyle
  },
  {
    headerName: "Key",
    width: 200,
    field: "keyName",
    cellStyle: baseStyle,
    cellClass: "ht-shortcut-cell",
    cellRenderer: params => {
      return params.value ? params.value : "";
    },
    onCellClicked: params => {
      EventBus.$emit("shortcut", params.data);
    }
  }
];

export default columnDefs;
