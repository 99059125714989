const values = {
  1: "Regular Customer",
  2: "Credit Line",
  3: "Total Exposure"
};

const getCreditTypeByKey = key => {
  return values[key] || key;
};

export { getCreditTypeByKey };
