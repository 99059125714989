<template>
  <form class="ui form" @submit.prevent="submit">
    <div class="field">
      <label>Current Password</label>
      <InputPassword
        ref="currentPassword"
        v-model="currentPassword"
        name="Current Password"
        placeholder="Current Password"
        error-position="bottom center"
        input-style="height: 2.5rem;"
        eye-button
        is-validate
        required
        autofocus
      />
    </div>
    <div class="field">
      <label>New Password</label>
      <InputPassword
        ref="newPassword"
        v-model="newPassword"
        name="New Password"
        placeholder="New Password"
        input-style="height: 2.5rem;"
        eye-button
        is-validate
        required
        :error-text="newPasswordErr"
      />
    </div>
    <div class="field">
      <label>Confirm New Password</label>
      <InputPassword
        ref="confirmNewPassword"
        v-model="confirmNewPassword"
        name="Confirm New Password"
        placeholder="Confirm New Password"
        input-style="height: 2.5rem;"
        eye-button
        is-validate
        required
        :error-text="confirmNewPasswordErr"
      />
    </div>
    <input type="submit" style="height: 0; min-height: 0; visibility: hidden;" tabindex="-1" />
  </form>
</template>

<script>
import InputPassword from "@/components/InputPassword";
export default {
  components: {
    InputPassword
  },
  data() {
    return {
      refId: null,
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      confirmNewPasswordErr: null,
      newPasswordErr: null,
      inputsFilterable: ["InputPassword"],
      resolve: null,
      reject: null
    };
  },
  mounted() {
    this.$refs.currentPassword.$el.querySelector("input").focus();
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    resetForm() {
      this.currentPassword = null;
      this.newPassword = null;
      this.confirmNewPassword = null;
      this.$nextTick(() => {
        this.$children.forEach(c => {
          if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
            c.clearValidate();
          }
        });
        this.$refs.currentPassword.$el.querySelector("input").focus();
      });
    },
    save() {
      return new Promise((resolve, reject) => {
        this.confirmNewPasswordErr = null;
        this.newPasswordErr = null;
        let validate = true;
        const filterErrors = this.$children.filter(c => {
          if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
            if (c.isValidate) {
              const result = c.validate();
              validate &= result;
              return !result;
            } else {
              c.clearValidate();
            }
          }
          return false;
        });

        if (!this.currentPassword || !this.newPassword || !this.confirmNewPassword) {
          return;
        }

        if (this.newPassword !== this.confirmNewPassword) {
          this.$Swal.fire(`Warning!`, "New Password and Confirm New Password do not match.", "warning");
          this.confirmNewPasswordErr = "New Password and Confirm New Password do not match.";
          this.$refs.confirmNewPassword.$el.querySelector("input").focus();
          this.$refs.confirmNewPassword.$el.querySelector("input").select();
          reject();
          return;
        }

        if (this.newPassword.length < 6 || this.newPassword.length > 20) {
          this.$Swal.fire(`Warning!`, "Password length must between 6 to 20 characters.", "warning");
          this.newPasswordErr = "Password length must between 6 to 20 characters.";
          this.$refs.newPassword.$el.querySelector("input").focus();
          this.$refs.newPassword.$el.querySelector("input").select();
          reject();
          return;
        }

        if (!new RegExp(/(?=.*[0-9])(?=.*[A-Za-z])/).test(this.newPassword)) {
          this.$Swal.fire(`Warning!`, "Password must contains a mix of numeric and English characters.", "warning");
          this.newPasswordErr = "Password must contains a mix of numeric and English characters.";
          this.$refs.newPassword.$el.querySelector("input").focus();
          this.$refs.newPassword.$el.querySelector("input").select();
          reject();
          return;
        }

        if (this.currentPassword === this.newPassword) {
          this.$Swal.fire(`Warning!`, "Can't change new password same as old password.", "warning");
          this.newPasswordErr = "Can't change new password same as old password.";
          this.$refs.newPassword.$el.querySelector("input").focus();
          this.$refs.newPassword.$el.querySelector("input").select();
          reject();
          return;
        }

        if (!validate) {
          filterErrors[0].$el.querySelector("input").focus();
          reject();
          return;
        }

        this.resolve = resolve;
        this.reject = reject;
        this.$EventBus.$on("ctinf/US02", this.passwordChangedRsp);
        this.sendPasswordChangedReq();
      });
    },
    sendPasswordChangedReq() {
      const msg = this.$messageFactory.createMessage("UR02");

      msg.currentPassword.set(this.currentPassword);
      msg.newPassword.set(this.newPassword);
      this.refId = this.$shortid.generate();
      this.$ws.send(msg, this.refId);
    },
    passwordChangedRsp(data) {
      if (data.refId === this.refId) {
        this.$EventBus.$off("ctinf/US02", this.passwordChangedRsp);
        if (data.resultCode === 1001) {
          this.resetForm();
          this.resolve();
        } else {
          if (data.resultCode === 2001) {
            this.reject(new Error("2001: Invalid Current Password."));
          } else {
            this.reject(new Error(data.resultCode + ": Change Password Fail."));
          }
        }
        this.resolve = null;
        this.reject = null;
      }
    }
  }
};
</script>
