<template>
  <div class="pt-modal-set">
    <form class="pt-modal-set-form ui form" @submit.prevent="onSend">
      <div class="field custom-field">
        <label>{{ "Side" }}</label>
        <PTDealSideDropdown class="pt-deal-side-dropdown" ref="ptdealside" v-model="ptDealFormData.side" @enter="$refs.esymbol.select()" />
      </div>
      <div class="field custom-field">
        <label>{{ "Symbol" }}</label>
        <SymbolDropdown
          ref="esymbol"
          v-model="ptDealFormData.selectedSymbol"
          market="SET"
          class="symbol-dropdown"
          is-validate
          required
          notification
          order
          @enter="$refs.evol.select()"
        />
      </div>
      <div class="field custom-field">
        <label>{{ "Volume" }}</label>
        <InputNumber
          ref="evol"
          v-model.number="ptDealFormData.volume"
          name="Volume"
          class="ht-ord-volume"
          placeholder="Volume"
          is-validate
          format="0,0"
          required
          @enter="$refs.eprice.select()"
        />
      </div>
      <div class="field custom-field">
        <label>{{ "Price" }}</label>
        <InputPrice
          ref="eprice"
          v-model.number="ptDealFormData.price"
          name="Price"
          class="ht-ord-price"
          placeholder="Price"
          is-validate
          format="0,0.000000"
          :min="0"
          required
          @enter="$refs.trCode._setupProxy.select()"
        />
      </div>
      <div class="field custom-field tr-code-field">
        <label>{{ "TR Code" }}</label>
        <TradeReportCodeInput
          ref="trCode"
          is-validate
          v-model="ptDealFormData.trCode"
          @enter="ptDealFormData.side === '' ? $refs.saccount.select() : ptDealFormData.side === 'B' ? $refs.sfirm.select() : $refs.saccount.select()"
        />
      </div>
      <div class="field custom-field">
        <label>{{ "Total Amount" }}</label>
        <div v-if="isNaN(ptDealFormData.price)">{{ "N/A" }}</div>
        <div v-else>{{ $numeral(ptDealFormData.volume * ptDealFormData.price).format("0,0.000000") }}</div>
      </div>
      <div class="field custom-field">
        <label>{{ "Board" }}</label>
        <div v-if="ptDealFormData.board === 'M'">{{ "Main Board" }}</div>
        <div v-else-if="ptDealFormData.board === 'B'">{{ "Big Lot Board" }}</div>
        <div v-else-if="ptDealFormData.board === 'F'">{{ "Foreign Board" }}</div>
        <div v-else-if="ptDealFormData.board === 'O'">{{ "Odd Lot Board" }}</div>
        <div v-else>{{ "-" }}</div>
      </div>
      <div class="field custom-field">
        <label>{{ "Sell Information" }}</label>
        <InputText
          ref="sfirm"
          v-model="ptDealFormData.sellerFirmId"
          class="ht-trader-id custom-input-text"
          name="Broker ID"
          placeholder="Broker ID"
          :uppercase="true"
          :disabled="ptDealFormData.side === '' || ptDealFormData.side === 'S'"
          @enter="
            ptDealFormData.side === '' ? $refs.saccount.select() : ptDealFormData.side === 'S' ? $refs.saccount.select() : $refs.strader.select()
          "
        />
        <InputText
          ref="strader"
          v-model="ptDealFormData.sellerTraderId"
          class="ht-trader-id custom-input-text"
          name="Trader ID"
          placeholder="Trader ID"
          :uppercase="true"
          :disabled="ptDealFormData.side === '' || ptDealFormData.side === 'S'"
          @enter="ptDealFormData.side === 'B' ? $refs.baccount.select() : $refs.saccount.select()"
        />
        <InputText
          ref="saccount"
          v-model="ptDealFormData.sellerAccountId"
          class="ht-trader-id custom-input-text"
          name="Account ID"
          placeholder="Account ID"
          :uppercase="true"
          :disabled="ptDealFormData.side === 'B'"
          @enter="ptDealFormData.side === '' ? $refs.sttf.select() : $refs.sellerControlKey._setupProxy.select()"
        />
        <TradeReportControlKeyInput
          ref="sellerControlKey"
          is-validate
          :disabled="ptDealFormData.side === '' || ptDealFormData.side === 'B'"
          v-model="ptDealFormData.sellerControlKey"
          @enter="$refs.sttf.select()"
        />
        <label>{{ "TTF" }}</label>
        <TTFDropdown
          ref="sttf"
          v-model="ptDealFormData.sellerTTF"
          :disabled="ptDealFormData.side === 'B'"
          trade-report-mode
          @enter="ptDealFormData.side === '' ? $refs.baccount.select() : $refs.bfirm.select()"
          class="custom-ttf-dropdown"
        />
      </div>
      <div class="field custom-field">
        <label>{{ "Buy Information" }}</label>
        <InputText
          ref="bfirm"
          v-model="ptDealFormData.buyerFirmId"
          class="ht-trader-id custom-input-text"
          name="Broker ID"
          placeholder="Broker ID"
          :uppercase="true"
          :disabled="ptDealFormData.side === '' || ptDealFormData.side === 'B'"
          @enter="ptDealFormData.side === 'B' ? $refs.baccount.select() : $refs.btrader.select()"
        />
        <InputText
          ref="btrader"
          v-model="ptDealFormData.buyerTraderId"
          class="ht-trader-id custom-input-text"
          name="Trader ID"
          placeholder="Trader ID"
          :uppercase="true"
          :disabled="ptDealFormData.side === '' || ptDealFormData.side === 'B'"
          @enter="ptDealFormData.side === 'S' ? onSend() : $refs.baccount.select()"
        />
        <InputText
          ref="baccount"
          v-model="ptDealFormData.buyerAccountId"
          class="ht-trader-id custom-input-text"
          name="Account ID"
          placeholder="Account ID"
          :uppercase="true"
          :disabled="ptDealFormData.side === 'S'"
          @enter="ptDealFormData.side === '' ? $refs.bttf.select() : $refs.buyerControlKey._setupProxy.select()"
        />
        <TradeReportControlKeyInput
          ref="buyerControlKey"
          :disabled="ptDealFormData.side === '' || ptDealFormData.side === 'S'"
          is-validate
          v-model="ptDealFormData.buyerControlKey"
          @enter="$refs.bttf.select()"
        />
        <label>{{ "TTF" }}</label>
        <TTFDropdown
          ref="bttf"
          v-model="ptDealFormData.buyerTTF"
          trade-report-mode
          :disabled="ptDealFormData.side === 'S'"
          @enter="onSend"
          class="custom-ttf-dropdown"
        />
      </div>
      <button class="submit-button" :style="{ display: 'none' }" type="submit"></button>
    </form>
  </div>
</template>

<script setup>
import usePTModalSET from "./hooks/usePTDealModalSET";

import SymbolDropdown from "@/components/SymbolDropdown";
import PTDealSideDropdown from "@/components/PTDealSideDropdown";
import InputNumber from "@/components/InputNumber";
import InputPrice from "@/components/InputPrice";
import InputText from "@/components/InputText";
import TTFDropdown from "@/components/TTFDropdown";
import TradeReportCodeInput from "./TradeReportCodeInput.vue";
import TradeReportControlKeyInput from "./TradeReportControlKeyInput.vue";

const { ptDealFormData, onSend } = usePTModalSET();
</script>

<style scoped lang="scss">
@import "~@/assets/styles/pt-deal/pt-deal-modal-set.scss";
</style>
