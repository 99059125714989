<template>
  <div class="ui grid status-bar">
    <div class="column account-input-container" style="background-color: #1b2025; padding-left: 5px !important; width: 22rem">
      <AllAccountDropdown v-if="!['M', 'U'].includes(traderType)" ref="account" v-model="selectedAccount" @enter="onAccountChanged" />
      <InputAllAccount v-else ref="account" v-model="selectedAccount" name="Account" placeholder="Account" @enter="onEnterInputAllAcount" />
    </div>
    <div class="column" style="width: calc(100vw - 22rem)">
      <div :style="[theme === 'Light' ? { background: '#f9fafb' } : { background: '#1B2025' }]">
        <table class="tg status-table" style="table-layout: fixed; width: 100%; height: 3rem; border-top: 0px solid #2224261a">
          <colgroup>
            <col style="width: 30%" />
            <col style="width: 7rem" />
            <col style="width: 18%" />
            <col style="width: 6rem" />
            <col style="width: 18%" />
            <col style="width: 2rem" />
            <col style="width: 18rem" />
          </colgroup>
          <tr>
            <td class="tg-s268">
              <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                <span class="ui tiny yellow header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">
                  {{ accountInfo.accountName }}
                </span>
              </div>
            </td>
            <td class="tg-s268">
              <div v-if="accountInfo && accountInfo.accountType === 'B'">
                <span class="ui tiny grey header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">{{ "Excess Equity : " }}</span>
              </div>
              <div v-if="accountInfo && accountInfo.accountType === 'C'">
                <span class="ui tiny grey header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">{{ "Buy Limit : " }}</span>
              </div>
            </td>
            <td class="tg-0ord">
              <div v-if="accountInfo" :class="[{ blink: componentsClass.buyLimit }]">
                <span class="ui tiny yellow header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">{{ accountInfo.buyLimit }}</span>
              </div>
            </td>
            <td class="tg-s268">
              <div v-if="accountInfo && accountInfo.accountType === 'B'">
                <span class="ui tiny grey header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">{{ "Loan Limit : " }}</span>
              </div>
              <div v-if="accountInfo && accountInfo.accountType === 'C'">
                <span class="ui tiny grey header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">{{ "Credit Line : " }}</span>
              </div>
              <div v-else-if="currentSelectedAccount && currentSelectedAccount.exchangeId == 2">
                <span class="ui tiny grey header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">{{ "Excess Equity : " }}</span>
              </div>
            </td>
            <td class="tg-0ord">
              <div v-if="accountInfo && accountInfo.accountType === 'B'" :class="[{ blink: componentsClass.loanLimit }]">
                <span class="ui tiny yellow header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">
                  {{ accountInfo.loanLimit }}
                </span>
              </div>
              <div v-if="accountInfo && accountInfo.accountType === 'C'" :class="[{ blink: componentsClass.creditLine }]">
                <span class="ui tiny yellow header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">
                  {{ accountInfo.creditLine }}
                </span>
              </div>
              <div v-else-if="currentSelectedAccount && currentSelectedAccount.exchangeId == 2" :class="[{ blink: componentsClass.ee }]">
                <span class="ui tiny yellow header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">{{ accountInfo.ee }}</span>
              </div>
            </td>
            <td class="tg-0ord">
              <div v-if="currentSelectedAccount && currentSelectedAccount.exchangeId != '0'" style="cursor: pointer">
                <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
              </div>
            </td>
            <td class="tg-0ord">
              <div class="time-status-container">
                <div class="ui tiny grey inverted header" style="font-weight: 700">
                  {{ serverTime && $moment(serverTime).format("ddd MMM DD YYYY, HH:mm:ss") }}&nbsp;
                </div>
                <i v-if="appStatus === 'Online'" ref="realtimeicon" class="circle mini icon green"></i>
                <i v-if="appStatus === 'Connecting...'" ref="realtimeicon" class="circle mini icon yellow"></i>
                <i v-if="appStatus === 'Offline'" ref="realtimeicon" class="circle mini icon grey"></i>
                <div class="ui tiny grey header" style="font-weight: 700" :class="{ inverted: theme === 'Dark' }">{{ appStatus }}&nbsp;</div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import _api from "@/api/authentication";

import { get, sync } from "vuex-pathify";
import AllAccountDropdown from "@/components/AllAccountDropdown";
import InputAllAccount from "@/components/InputAllAccount";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

export default {
  name: "StatusBar",
  components: {
    AllAccountDropdown,
    InputAllAccount,
  },
  data: () => ({
    appStatus: "Online",
    selectedAccount: null,
    accountInfo: {
      accountName: "",
      accountType: "",
      buyLimit: "",
      loanLimit: "",
      creditLine: "",
      ee: "",
    },
    serverTime: null,
    serverTimeInterval: null,
    accountInfoInterval: null,
    timer: null,
    isLoading: false,
    componentsClass: {
      excessEquity: false,
      buyLimit: false,
      loanLimit: false,
      creditLine: false,
      ee: false,
    },
  }),
  watch: {
    currentSelectedAccount(value) {
      if (value.exchangeId == 0) {
        this.selectedAccount = value.accountCode;
      } else {
        this.selectedAccount = value.accountCode;
        this.onRefresh();
      }
    },
  },
  created() {
    this.$EventBus.$on("ctinf/AS01", this.etsAccountInfoCinf);
    this.$EventBus.$on("ctinf/AS51", this.dtsAccountInfoCinf);
    this.$EventBus.$on("ctinf/AS03", this.etsAccountCreditBalanceCinf);

    this.getServerTime();
    this.serverTimeInterval = setInterval(() => {
      this.getServerTime();
    }, process.env.VUE_APP_CHECKAUTH_RATE);

    this.onRefresh();
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS01", this.etsAccountInfoCinf);
    this.$EventBus.$off("ctinf/AS51", this.dtsAccountInfoCinf);
    this.$EventBus.$off("ctinf/AS03", this.etsAccountCreditBalanceCinf);

    this.clearAllInterval();
  },
  methods: {
    clearAllInterval() {
      if (this.serverTimeInterval) {
        clearInterval(this.serverTimeInterval);
      }

      if (this.timer) {
        clearInterval(this.timer);
      }

      if (this.accountInfoInterval) {
        clearInterval(this.accountInfoInterval);
      }
    },
    onRefresh() {
      if (this.accountInfoInterval) {
        clearInterval(this.accountInfoInterval);
      }

      this.AR01();
      this.accountInfoInterval = setInterval(() => {
        this.AR01();
      }, process.env.VUE_APP_FETCH_RATE);
    },
    blinkBackground(ref) {
      this.componentsClass[ref] = true;
      setTimeout(() => {
        this.componentsClass[ref] = false;
      }, 250);
    },
    getServerTime() {
      _api
        .checkAuth()
        .then((response) => {
          if (response.status === 200) {
            if (this.timer) {
              clearInterval(this.timer);
            }
            this.serverTime = response.data;
            this.timer = setInterval(() => {
              this.serverTime += 1000;
            }, 1000);
          } else {
            this.clearAllInterval();
            this.$Swal.fire("Error", "Your session has been expired. Please login again.", "error").then(() => {
              this.forceLogout();
            });
          }
        })
        .catch((error) => {
          this.clearAllInterval();
          this.forceLogout();
          console.error(error);
        });
    },
    forceLogout() {
      this.$ws.stop();
      _api
        .logout()
        .then((response) => {
          this.$router.replace("/login");
        })
        .catch((error) => error);
    },
    onEnterInputAllAcount(account) {
      this.$store.set("global/currentAccount", account.accountCode);
      this.$store.set("global/currentSelectedAccount", {
        exchangeId: account.exchangeId,
        accountCode: account.accountCode,
      });
    },
    onAccountChanged(account) {
      this.isEnterAccount = true;
      if (account) {
        let exchangeId = "0";
        const accountCode = account.value;

        if (account.text.includes("(Equity)")) exchangeId = "1";
        if (account.text.includes("(Derivative)")) exchangeId = "2";

        this.$store.set("global/currentAccount", accountCode);
        this.$store.set("global/currentSelectedAccount", {
          exchangeId,
          accountCode,
        });
      } else {
        this.$store.set("global/currentAccount", null);
        this.$store.set("global/currentSelectedAccount", {
          exchangeId: "0",
          accountCode: null,
        });
      }

      this.$nextTick(() => {
        this.$refs.account.select();
      });

      this.onRefresh();
    },
    AR01() {
      this.isLoading = true;
      if (!this.currentSelectedAccount || this.currentSelectedAccount.exchangeId == 0 || !this.currentSelectedAccount?.accountCode) {
        this.accountInfo = {
          accountName: "",
          accountType: "",
          buyLimit: "",
          loanLimit: "",
          creditLine: "",
          ee: "",
        };
        this.isLoading = false;
        return;
      }

      const msg = this.$messageFactory.createMessage("AR01");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);
      msg.exchangeId.set(this.currentSelectedAccount.exchangeId);

      this.$ws.send(msg);
    },
    AR03() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount?.exchangeId == 0 || !this.currentSelectedAccount?.accountCode) {
        this.accountInfo = {
          accountName: "",
          accountType: "",
          buyLimit: "",
          loanLimit: "",
          creditLine: "",
          ee: "",
        };
        this.isLoading = false;
        return;
      }

      const msg = this.$messageFactory.createMessage("AR03");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);

      this.$ws.send(msg);
    },
    etsAccountInfoCinf(info) {
      if (this.accountInfo.buyLimit !== info.buyLimit) {
        this.blinkBackground("buyLimit");
      }

      if (this.accountInfo.creditLine !== info.creditLine) {
        this.blinkBackground("creditLine");
      }

      this.accountInfo.accountName = info.accountName;
      this.accountInfo.accountType = info.accountType;

      this.accountInfo.buyLimit = info.buyLimit;
      this.accountInfo.creditLine = info.creditLine;

      this.AR03();
      this.isLoading = false;
    },
    etsAccountCreditBalanceCinf(info) {
      if (this.accountInfo.loanLimit !== info.loanLimit) {
        this.blinkBackground("loanLimit");
      }
      this.accountInfo.loanLimit = info.loanLimit;

      this.isLoading = false;
    },
    dtsAccountInfoCinf(info) {
      if (this.accountInfo.buyLimit !== info.buyLimit) {
        if (this.accountInfo.accountType === "B") {
          this.blinkBackground("excessEquity");
        } else if (this.accountInfo.accountType === "C") {
          this.blinkBackground("buyLimit");
        }
      }

      if (this.accountInfo.ee !== info.ee) {
        this.blinkBackground("ee");
      }

      this.accountInfo.accountName = info.accountName;
      this.accountInfo.accountType = info.accountType;

      this.accountInfo.buyLimit = info.buyLimit;
      this.accountInfo.ee = info.ee;
      this.isLoading = false;
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
    traderType: get("global/traderType"),
  },
};
</script>

<style lang="scss" scoped>
.status-bar {
  .account-input-container {
    display: flex !important;
    align-items: center;
  }
  .status-table {
    .time-status-container {
      display: flex;
      align-items: center;
      column-gap: 5px;

      .header {
        margin: 0 !important;
      }
    }

    td {
      div {
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        // display: none;
      }
    }
  }
}
.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  padding: 0 5px;
  overflow: hidden;
  word-break: normal;
  border: 0px solid black;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tg th {
  padding: 0 10px;
  overflow: hidden;
  word-break: normal;
}
.tg .tg-0ord {
  padding-top: 0;
  vertical-align: top;
  text-align: right;

  .refresh.icon {
    position: relative;
    bottom: 3px !important;

    &.loading {
      bottom: 0 !important;
    }
  }
}
.tg .tg-s6z2 {
  padding-top: 0;
  vertical-align: top;
  text-align: center;
}
.tg .tg-s268 {
  padding-top: 0;
  vertical-align: top;
  text-align: left;

  div {
    justify-content: flex-start !important;
  }
}

.blink {
  background-color: rgba(22, 160, 133, 0.5) !important;
}
</style>
