<template>
  <div class="quote-sell-by-time">
    <div style="height: 3rem;">
      <div class="ui form">
        <div class="fields">
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-right: 0px;">Time</h5>
          </div>
          <div class="field">
            <div style="padding: 0.5rem;" class="ht-quote-timepicker">
              <el-time-picker
                v-model="time"
                is-range
                range-separator="To"
                start-placeholder="Start time"
                end-placeholder="End time"
                format="HH:mm"
                :clearable="false"
                @change="SR05"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ui grid" :style="{ height: 'calc(100% - 3rem)' }">
      <div class="sixteen wide column" :style="{ height: '100%' }">
        <ag-grid-vue
          style="width: 100%; height: 100%; padding: 0px;"
          :class="{
            'ag-theme-balham-dark': theme === 'Dark',
            'ag-theme-balham': theme === 'Light'
          }"
          row-selection="single"
          :grid-options="gridQuoteSaleByTimeOptions"
          :column-defs="columnDefsQuoteSaleByTime"
          :row-data="rowQuoteSaleByTime"
          :get-row-node-id="getQuoteSaleByTimeRowNodeId"
          :row-height="21"
          :header-height="32"
          :default-col-def="{ sortable: true, resizable: true }"
          :animate-rows="true"
          :suppress-drag-leave-hides-columns="true"
          :enable-cell-change-flash="true"
          :locale-text="{ noRowsToShow: `Don't have any Sale.` }"
          :row-drag-managed="false"
          overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
          @grid-ready="onQuoteSaleByTimeGridReady"
          @cell-focused="onCellQuoteSaleByTimeFocused"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsQuoteSaleByTime } from "@/components/grid/quote/QuoteSaleByTimeColumnDefs.js";
import { TimePicker } from "element-ui";

Object.freeze(columnDefsQuoteSaleByTime);

export default {
  name: "QuoteSaleByTime",
  components: {
    AgGridVue,
    "el-time-picker": TimePicker
  },
  data: () => ({
    columnDefsQuoteSaleByTime,

    gridQuoteSaleByTimeApi: null,
    columnQuoteSaleByTimeApi: null,
    rowQuoteSaleByTime: [],
    time: [new Date(2000, 1, 1, 9, 0), new Date(2000, 1, 1, 17, 0)]
  }),
  watch: {
    currentQuoteSelectedSymbol() {
      this.SR05();
    }
  },
  methods: {
    onQuoteTableStartRefresh() {
      this.SR05();
    },
    SR05() {
      if (!this.currentQuoteSelectedSymbol) {
        this.$EventBus.$emit("onQuoteTableStopRefresh");
        return;
      }
      const msg = this.$messageFactory.createMessage("SR05");

      msg.fromTime.set(this.$moment(this.time[0]).format("HH:mm:ss"));
      msg.toTime.set(this.$moment(this.time[1]).format("HH:mm:ss"));
      msg.recordCount.set(100000);
      msg.securityId.set(this.currentQuoteSelectedSymbol.value.securityId);
      msg.exchangeId.set(this.currentQuoteSelectedSymbol.value.exchangeId);

      this.$ws.send(msg);
      if (this.gridQuoteSaleByTimeApi) {
        this.gridQuoteSaleByTimeApi.showLoadingOverlay();
      }
    },
    updateRowQuoteSaleByTime(trade) {
      this.rowQuoteSaleByTime = trade.records;
      if (this.gridQuoteSaleByTimeApi) {
        this.gridQuoteSaleByTimeApi.hideOverlay();
      }
      this.$EventBus.$emit("onQuoteTableStopRefresh");
    },
    onQuoteSaleByTimeGridReady(params) {
      this.gridQuoteSaleByTimeApi = params.api;
      this.columnQuoteSaleByTimeApi = params.columnApi;
    },
    getQuoteSaleByTimeRowNodeId(data) {
      return data.recordId;
    },
    onCellQuoteSaleByTimeFocused(event) {
      this.gridQuoteSaleByTimeOptions.api.forEachNode(node => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    }
  },
  computed: {
    theme: sync("global/theme"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol")
  },
  created() {
    this.gridQuoteSaleByTimeOptions = {};
    this.SR05();

    this.$EventBus.$on("ctinf/SS05", this.updateRowQuoteSaleByTime);

    this.$EventBus.$on("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS05", this.updateRowQuoteSaleByTime);

    this.$EventBus.$off("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);
  }
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.ht-quote-timepicker .el-range-separator {
  padding: 0px !important;
  margin-top: -10px !important;
  color: #dcddde !important;
}

.ht-quote-timepicker .el-input__icon.el-range__icon.el-icon-time {
  display: flex;
  align-items: center;
}

.quote-sell-by-time {
  height: 100%;
}
</style>
