/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class BS52 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._totalBrkVol = new NumberMessageBase();
		this._totalBrkVal = new NumberMessageBase();
		this._name = new StringMessageBase();
		this._title = new StringMessageBase();
		this._approvalOver = new NumberMessageBase();
		this._level = new NumberMessageBase();
		this._supervisor = new StringMessageBase();
		this._value = new NumberMessageBase();
		this._commission = new NumberMessageBase();
		this._sides = new ArrayMessageBase(SideRecord);
		this._accountTypes = new ArrayMessageBase(AccountTypeRecord);
		this._pcs = new ArrayMessageBase(PcRecord);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get totalBrkVol() {
		return this._totalBrkVol;
	}
	
	get totalBrkVal() {
		return this._totalBrkVal;
	}
	
	get name() {
		return this._name;
	}
	
	get title() {
		return this._title;
	}
	
	get approvalOver() {
		return this._approvalOver;
	}
	
	get level() {
		return this._level;
	}
	
	get supervisor() {
		return this._supervisor;
	}
	
	get value() {
		return this._value;
	}
	
	get commission() {
		return this._commission;
	}
	
	get sides() {
		return this._sides;
	}
	
	get accountTypes() {
		return this._accountTypes;
	}
	
	get pcs() {
		return this._pcs;
	}
}

export class SideRecord {
	constructor() {
		this._side = new StringMessageBase();
		this._vol = new NumberMessageBase();
		this._volBrkPct = new NumberMessageBase();
		this._volMktPct = new NumberMessageBase();
		this._val = new NumberMessageBase();
		this._valBrkPct = new NumberMessageBase();
		this._valMktPct = new NumberMessageBase();
		this._commission = new NumberMessageBase();
		this._commissionBrkPct = new NumberMessageBase();
	}
	
	get side() {
		return this._side;
	}
	
	get vol() {
		return this._vol;
	}
	
	get volBrkPct() {
		return this._volBrkPct;
	}
	
	get volMktPct() {
		return this._volMktPct;
	}
	
	get val() {
		return this._val;
	}
	
	get valBrkPct() {
		return this._valBrkPct;
	}
	
	get valMktPct() {
		return this._valMktPct;
	}
	
	get commission() {
		return this._commission;
	}
	
	get commissionBrkPct() {
		return this._commissionBrkPct;
	}
}

export class AccountTypeRecord {
	constructor() {
		this._type = new StringMessageBase();
		this._buyVol = new NumberMessageBase();
		this._buyVolBrkPct = new NumberMessageBase();
		this._sellVol = new NumberMessageBase();
		this._sellVolBrkPct = new NumberMessageBase();
		this._totalVol = new NumberMessageBase();
		this._totalVolBrkPct = new NumberMessageBase();
		this._buyVal = new NumberMessageBase();
		this._buyValBrkPct = new NumberMessageBase();
		this._sellVal = new NumberMessageBase();
		this._sellValBrkPct = new NumberMessageBase();
		this._totalVal = new NumberMessageBase();
		this._totalValBrkPct = new NumberMessageBase();
	}
	
	get type() {
		return this._type;
	}
	
	get buyVol() {
		return this._buyVol;
	}
	
	get buyVolBrkPct() {
		return this._buyVolBrkPct;
	}
	
	get sellVol() {
		return this._sellVol;
	}
	
	get sellVolBrkPct() {
		return this._sellVolBrkPct;
	}
	
	get totalVol() {
		return this._totalVol;
	}
	
	get totalVolBrkPct() {
		return this._totalVolBrkPct;
	}
	
	get buyVal() {
		return this._buyVal;
	}
	
	get buyValBrkPct() {
		return this._buyValBrkPct;
	}
	
	get sellVal() {
		return this._sellVal;
	}
	
	get sellValBrkPct() {
		return this._sellValBrkPct;
	}
	
	get totalVal() {
		return this._totalVal;
	}
	
	get totalValBrkPct() {
		return this._totalValBrkPct;
	}
}

export class PcRecord {
	constructor() {
		this._type = new StringMessageBase();
		this._buyVol = new NumberMessageBase();
		this._buyVolBrkPct = new NumberMessageBase();
		this._sellVol = new NumberMessageBase();
		this._sellVolBrkPct = new NumberMessageBase();
		this._totalVol = new NumberMessageBase();
		this._totalVolBrkPct = new NumberMessageBase();
		this._buyVal = new NumberMessageBase();
		this._buyValBrkPct = new NumberMessageBase();
		this._sellVal = new NumberMessageBase();
		this._sellValBrkPct = new NumberMessageBase();
		this._totalVal = new NumberMessageBase();
		this._totalValBrkPct = new NumberMessageBase();
	}
	
	get type() {
		return this._type;
	}
	
	get buyVol() {
		return this._buyVol;
	}
	
	get buyVolBrkPct() {
		return this._buyVolBrkPct;
	}
	
	get sellVol() {
		return this._sellVol;
	}
	
	get sellVolBrkPct() {
		return this._sellVolBrkPct;
	}
	
	get totalVol() {
		return this._totalVol;
	}
	
	get totalVolBrkPct() {
		return this._totalVolBrkPct;
	}
	
	get buyVal() {
		return this._buyVal;
	}
	
	get buyValBrkPct() {
		return this._buyValBrkPct;
	}
	
	get sellVal() {
		return this._sellVal;
	}
	
	get sellValBrkPct() {
		return this._sellValBrkPct;
	}
	
	get totalVal() {
		return this._totalVal;
	}
	
	get totalValBrkPct() {
		return this._totalValBrkPct;
	}
}

export default BS52;
