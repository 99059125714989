<template>
  <div
    class="ui action input"
    :class="{ error: displayErrorText, action: eyeButton }"
    :data-tooltip="displayErrorText"
    :data-position="errorPosition"
    data-inverted="inverted"
  >
    <input
      ref="input"
      v-model="text"
      :type="type"
      :class="{ uppercase: uppercase }"
      :style="inputStyle"
      :placeholder="placeholder"
      :autofocus="autofocus"
      :readonly="lock"
      @blur="onBlur"
      @keyup.enter="onEnter"
    />
    <ConfirmSavePIN ref="modal" />
    <a class="ui secondary icon button" :style="inputStyle" @click="onBtnClick">
      <i class="icon" :class="lock ? 'lock' : 'unlock'"></i>
    </a>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";
import InputPassword from "./InputPassword";
import ConfirmSavePIN from "@/components/modal/ConfirmSavePIN";

export default {
  components: {
    ConfirmSavePIN,
  },
  extends: InputPassword,
  data() {
    return {
      lock: false,
    };
  },
  watch: {
    value() {
      if (this.pin) {
        this.text = this.pin;
        this.$emit("input", this.text);
      }
    },
    pin(value) {
      if (value) {
        this.lock = true;
        this.text = value;
      } else {
        this.lock = false;
        this.text = null;
      }
    },
  },
  created() {
    if (this.pin) {
      this.text = this.pin;
    } else {
      this.text = null;
    }
  },
  methods: {
    onBtnClick() {
      if (!this.lock) {
        const result = this.validate();
        if (!result) {
          return;
        }
      }
      if (this.lock) {
        this.unlockPIN();
      } else {
        this.lockPIN();
      }
    },
    lockPIN() {
      this.$refs.modal.confirm().then((result) => {
        if (result) {
          this.pin = this.text;
        }
      });
    },
    unlockPIN() {
      this.pin = null;
    },
  },
  computed: {
    pin: sync("global/pin"),
  },
};
</script>

<style scoped>
.ui.input input {
  color: #dcddde;
  background: #2f383f;
  padding: 0 0.25rem;
  font-weight: 700;
  height: 2rem;
  border-right: 0 !important;
  flex: 1;
  width: 100%;
}
.ui.input input:focus {
  color: #dcddde !important;
  background: #2f383f !important;
  border-right: 0 !important;
}
.ui.input input:focus-within {
  border-right: 0 !important;
}
.ui.input input:read-only {
  border: 0 !important;
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
a.ui {
  height: 2rem;
}
</style>
