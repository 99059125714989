<template>
  <div :id="containerId" class="TVChartContainer" />
</template>

<script>
import { get } from "vuex-pathify";
import axios from "axios";

import { widget } from "../../../public/charting_library/charting_library.min.js";
import { Requester } from "../../../public/datafeeds/udf/lib/requester";
import { QuotesProvider } from "../../../public/datafeeds/udf/lib/quotes-provider";
import { UDFCompatibleDatafeedBase } from "../../../public/datafeeds/udf/lib/udf-compatible-datafeed-base";

function getLanguageFromURL() {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export default {
  name: "TVChartContainer",
  props: {
    symbol: {
      default: ".SET",
      type: String,
    },
    interval: {
      default: "D",
      type: String,
    },
    containerId: {
      default: "tv_chart_container",
      type: String,
    },
    datafeedUrl: {
      default: null,
      type: String,
    },
    libraryPath: {
      default: "/charting_library/",
      type: String,
    },
    chartsStorageUrl: {
      default: null,
      type: String,
    },
    chartsStorageApiVersion: {
      default: "1.1",
      type: String,
    },
    clientId: {
      default: "egw",
      type: String,
    },
    userId: {
      default: localStorage.getItem("tradingview_token"),
      type: String,
    },
    fullscreen: {
      default: false,
      type: Boolean,
    },
    autosize: {
      default: true,
      type: Boolean,
    },
    studiesOverrides: {
      type: Object,
    },
    theme: {
      default: "Dark",
      type: String,
    },
  },
  tvWidget: null,
  data() {
    return {
      useDatafeedUrl: null,
      useChartsStorageUrl: null,
    };
  },
  mounted() {
    this.getConfigs().then(() => {
      const datafeedURL = this.useDatafeedUrl;
      const requester = new Requester({
        Authorization: "Bearer " + localStorage.getItem("tradingview_token"),
      });
      const quotesProvider = new QuotesProvider(datafeedURL, requester);
      const updateFrequency = 10 * 1000;

      const widgetOptions = {
        symbol: this.currentQuoteSelectedSymbol?.symbol || this.symbol,
        // BEWARE: no trailing slash is expected in feed URL
        datafeed: new UDFCompatibleDatafeedBase(datafeedURL, quotesProvider, requester, updateFrequency),
        interval: this.interval,
        container_id: this.containerId,
        library_path: this.libraryPath,

        locale: getLanguageFromURL() || "en",
        disabled_features: ["timezone_menu", "header_screenshot"],
        enabled_features: ["charting_library_single_symbol_request"],
        charts_storage_url: this.useChartsStorageUrl,
        charts_storage_api_version: this.chartsStorageApiVersion,
        client_id: this.clientId,
        user_id: this.userId,
        timezone: "Asia/Bangkok",
        fullscreen: this.fullscreen,
        autosize: this.autosize,
        studies_overrides: this.studiesOverrides,
        theme: this.theme,
        loading_screen: { backgroundColor: "#262D33" },
        toolbar_bg: "#1B2025",
      };

      const tvWidget = new widget(widgetOptions);
      this.tvWidget = tvWidget;
    });
  },
  beforeDestroy() {
    if (this.tvWidget != null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  },
  methods: {
    async getConfigs() {
      this.useDatafeedUrl = this.datafeedUrl;
      this.useChartsStorageUrl = this.chartsStorageUrl;
      if (!this.useDatafeedUrl) {
        this.useDatafeedUrl = process.env.VUE_APP_TVAPI_PATH;
      }
      if (!this.useChartsStorageUrl) {
        this.useChartsStorageUrl = process.env.VUE_APP_TVAPI_PATH;
      }

      if (process.env.NODE_ENV === "production") {
        let DATA_FEED_URL = process.env.VUE_APP_TVAPI_PATH;
        const API_CONFIG_URL = window.location.origin + "/config.json";
        const response = await axios.get(API_CONFIG_URL);
        const { CONFIG_TVAPI_PATH } = response.data;
        let { CONFIG_TV_IP } = response.data;

        if (!CONFIG_TV_IP) {
          CONFIG_TV_IP = window.location.host;
        }

        if (CONFIG_TVAPI_PATH) {
          DATA_FEED_URL = window.location.protocol + "//" + CONFIG_TV_IP + CONFIG_TVAPI_PATH;
        } else {
          DATA_FEED_URL = window.location.protocol + "//" + CONFIG_TV_IP + process.env.VUE_APP_TVAPI_PATH;
        }

        this.useDatafeedUrl = DATA_FEED_URL;
        this.useChartsStorageUrl = DATA_FEED_URL;
      }
    },
  },
  computed: {
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
  },
};
</script>

<style lang="scss" scoped>
.TVChartContainer {
  height: calc(55vh - 5px);
}
</style>
