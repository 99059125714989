import numeral from "numeral";

export const price = value => {
  if (!value) {
    return "";
  }
  if (isNaN(value)) {
    return value;
  }

  const str = value.toString();
  const index = str.indexOf(".");
  let digits = 0;
  if (index !== -1) {
    digits = str.substring(index + 1).length;
  }
  if (digits > 2) {
    let format = "";
    for (let i = 0; i < digits; i++) {
      format += "0";
    }
    return numeral(value).format("0,0." + format);
  } else {
    return numeral(value).format("0,0.00");
  }
};

export const str2Price = str => {
  if (!str) {
    return null;
  }
  const allowedText = ["ATO", "ATC", "MP", "MO", "ML"];
  const value = str.replace(/,/g, "");
  if (isNaN(value) && allowedText.includes(value.toUpperCase())) {
    return value.toUpperCase();
  }
  try {
    return parseFloat(value);
  } catch (err) {
    return null;
  }
};

export const volume = value => {
  if (!value) {
    return "";
  }
  if (isNaN(value)) {
    return value;
  }

  return numeral(value).format("0,0");
};

export const str2Volume = str => {
  if (!str) {
    return null;
  }
  const value = str.replace(/,/g, "");
  try {
    return parseInt(value);
  } catch (err) {
    return null;
  }
};
