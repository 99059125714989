/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class DR04 extends WsReqMsg {
	constructor() {
		super(0);
		this._firm = new StringMessageBase();
		this._orderNo = new StringMessageBase();
		this._traderId = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
	}
	
	get firm() {
		return this._firm;
	}
	
	set firm(firm) {
		this._firm = new StringMessageBase(firm);
	}
	
	get orderNo() {
		return this._orderNo;
	}
	
	set orderNo(orderNo) {
		this._orderNo = new StringMessageBase(orderNo);
	}
	
	get traderId() {
		return this._traderId;
	}
	
	set traderId(traderId) {
		this._traderId = new StringMessageBase(traderId);
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get side() {
		return this._side;
	}
	
	set side(side) {
		this._side = new StringMessageBase(side);
	}
}

export default DR04;
