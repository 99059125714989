<template>
  <div class="ui grid padded">
    <form class="ui form" style="margin-top: 0.5rem; width: 100%">
      <div class="two fields">
        <div class="field">
          <label>Side</label>
          <PTDealSideDropdown ref="ptdealside" v-model="side" market="TFEX" @enter="$refs.esymbol.select()" />
        </div>

        <div class="field">
          <label>Series</label>
          <SymbolDropdown
            ref="esymbol"
            v-model="selectedSymbol"
            market="TFEX"
            class="ht-ord-symbol"
            is-validate
            required
            notification
            order
            @enter="$refs.evol.select()"
          />
        </div>
      </div>
      <div class="two fields">
        <div class="field">
          <label>Volume</label>
          <InputNumber
            ref="evol"
            v-model.number="volume"
            name="Volume"
            class="ht-ord-volume"
            placeholder="Volume"
            is-validate
            format="0,0"
            :min="1"
            required
            @enter="$refs.eprice.select()"
          />
        </div>
        <div class="field">
          <label>Price</label>
          <InputPrice
            ref="eprice"
            v-model.number="price"
            name="Price"
            class="ht-ord-price"
            placeholder="Price"
            is-validate
            format="0,0.000000"
            :min="0"
            required
            @enter="side === '' ? $refs.saccount.select() : side === 'L' ? $refs.sfirm.select() : $refs.saccount.select()"
          />
        </div>
      </div>
      <div class="two fields">
        <div class="field">
          <label>Total Amount</label>
          <template v-if="isNaN(price)">N/A</template>
          <template v-else>{{ $numeral(volume * price).format("0,0.000000") }}</template>
        </div>
        <div class="field">
          <label>Board</label>
          <span>Big Lot Board</span>
        </div>
      </div>
      <div class="two fields">
        <div class="field">
          <label>Short Information</label>
          <InputText
            ref="sfirm"
            v-model="shortBrokerId"
            class="ht-trader-id"
            style="height: 36px"
            name="Broker ID"
            placeholder="Broker ID"
            :uppercase="true"
            :disabled="side === '' || side === 'S'"
            @enter="side === '' ? $refs.saccount.select() : side === 'S' ? $refs.saccount.select() : $refs.strader.select()"
          />
          <InputText
            ref="strader"
            v-model="shortTraderId"
            class="ht-trader-id"
            style="height: 36px"
            name="Trader ID"
            placeholder="Trader ID"
            :uppercase="true"
            :disabled="side === '' || side === 'S'"
            @enter="side === 'L' ? $refs.sposition.select() : $refs.saccount.select()"
          />
          <InputText
            ref="saccount"
            v-model="shortAccountId"
            class="ht-trader-id"
            style="height: 36px"
            name="Account ID"
            placeholder="Account ID"
            :uppercase="true"
            :disabled="side === 'L'"
            @enter="$refs.sposition.select()"
          />
          <label>Position</label>
          <PTDealPositionDropdown ref="sposition" v-model="shortPosition" @enter="side === 'S' ? $refs.lfirm.select() : $refs.laccount.select()" />
        </div>
        <div class="field">
          <label>Long Information</label>
          <InputText
            ref="lfirm"
            v-model="longBrokerId"
            class="ht-trader-id"
            style="height: 36px"
            name="Broker ID"
            placeholder="Broker ID"
            :uppercase="true"
            :disabled="side === '' || side === 'L'"
            @enter="side === 'L' ? $refs.laccount.select() : $refs.ltrader.select()"
          />
          <InputText
            ref="ltrader"
            v-model="longTraderId"
            class="ht-trader-id"
            style="height: 36px"
            name="Trader ID"
            placeholder="Trader ID"
            :uppercase="true"
            :disabled="side === '' || side === 'L'"
            @enter="side === 'S' ? $refs.lposition.select() : $refs.laccount.select()"
          />
          <InputText
            ref="laccount"
            v-model="longAccountId"
            class="ht-trader-id"
            style="height: 36px"
            name="Account ID"
            placeholder="Account ID"
            :uppercase="true"
            :disabled="side === 'S'"
            @enter="$refs.lposition.select()"
          />
          <label>Position</label>
          <PTDealPositionDropdown ref="lposition" v-model="longPosition" @enter="onSendClicked" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

import PTDealSideDropdown from "@/components/PTDealSideDropdown";
import SymbolDropdown from "@/components/SymbolDropdown";
import InputNumber from "@/components/InputNumber";
import InputPrice from "@/components/InputPrice";
import InputText from "@/components/InputText";
import PTDealPositionDropdown from "@/components/PTDealPositionDropdown";
import { price as PriceFormatter, volume as VolumeFormatter } from "@/utils/NumberFormatter";

export default {
  components: {
    PTDealSideDropdown,
    SymbolDropdown,
    InputNumber,
    InputPrice,
    InputText,
    PTDealPositionDropdown,
  },
  data: () => ({
    side: "",
    selectedSymbol: null,
    volume: null,
    price: null,

    shortBrokerId: null,
    shortTraderId: null,
    shortAccountId: null,
    shortPosition: "",

    longBrokerId: null,
    longTraderId: null,
    longAccountId: null,
    longPosition: "",

    processing: false,

    dtsPTDealInsertRefId: [],
    successReponses: [],
    errorReponses: [],
    inputsFilterable: ["InputNumber", "InputPrice", "SymbolDropdown"],
  }),
  watch: {
    side(side) {
      const trader = this.$_.find(this.traders, ["exchangeId", 2]);

      if (side === "") {
        this.shortTraderId = trader?.traderCode || "";
        this.longTraderId = trader?.traderCode || "";

        this.longBrokerId = "";
        this.shortBrokerId = "";
      } else if (side === "L") {
        this.shortTraderId = "";
        this.shortBrokerId = "";
        this.shortAccountId = "";

        this.longBrokerId = "";
        this.longTraderId = trader?.traderCode || "";
      } else if (side === "S") {
        this.shortTraderId = trader?.traderCode || "";
        this.shortBrokerId = "";

        this.longTraderId = "";
        this.longBrokerId = "";
        this.longAccountId = "";
      }
    },
  },
  created() {
    this.$EventBus.$on("ptdealOpen", this.onNewPTDeal);

    //ptdeal insert
    this.$EventBus.$on("ctord/DS52", this.dtsPTDealInsertCtord);
  },
  beforeDestroy() {
    this.$EventBus.$off("ptdealOpen", this.onNewPTDeal);

    //ptdeal insert
    this.$EventBus.$off("ctord/DS52", this.dtsPTDealInsertCtord);
  },
  mounted() {
    this.focusSide();
  },
  methods: {
    onNewPTDeal() {
      this.clearForm();
      this.clearValidate();
      this.focusSide();
    },
    focusSide() {
      this.$nextTick(() => {
        if (this.$refs.ptdealside) {
          this.$refs.ptdealside.select();
        }
      });
    },
    clearForm() {
      this.side = "";
      this.selectedSymbol = null;
      this.volume = null;
      this.price = null;

      this.shortBrokerId = null;
      this.shortAccountId = null;
      this.shortPosition = null;

      this.longBrokerId = null;
      this.longAccountId = null;
      this.longPosition = null;
    },
    clearValidate() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$children.forEach((c) => {
            if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
              c.clearValidate();
            }
          });
        }, 10);
      });
    },
    DR52() {
      this.processing = true;

      const msg = this.$messageFactory.createMessage("DR52");
      let refId = this.$shortid.generate();

      const trader = this.$_.find(this.traders, ["exchangeId", 2]);

      msg.side.set(this.side);
      msg.symbol.set(this.selectedSymbol.symbol);
      msg.vol.set(this.volume);
      msg.price.set(this.price);
      msg.traderId.set(trader?.traderCode || "");

      if (this.side === "") {
        msg.side.set("L");
        msg.accountCode.set(this.longAccountId);
        msg.positionType.set(this.longPosition || "O");

        this.dtsPTDealInsertRefId.push(refId);
        this.$ws.send(msg, refId);

        msg.side.set("S");
        msg.accountCode.set(this.shortAccountId);
        msg.positionType.set(this.shortPosition || "O");

        refId = this.$shortid.generate();
        this.dtsPTDealInsertRefId.push(refId);
        this.$ws.send(msg, refId);
      } else {
        if (this.side === "L") {
          msg.traderId.set(this.longTraderId);
          msg.positionType.set(this.longPosition || "O");
          msg.accountCode.set(this.longAccountId);
          msg.cpMember.set(this.shortBrokerId);
          msg.cpTrader.set(this.shortTraderId);
        } else if (this.side === "S") {
          msg.traderId.set(this.shortTraderId);
          msg.positionType.set(this.shortPosition || "O");
          msg.accountCode.set(this.shortAccountId);
          msg.cpMember.set(this.longBrokerId);
          msg.cpTrader.set(this.longTraderId);
        }
        this.dtsPTDealInsertRefId.push(refId);
        this.$ws.send(msg, refId);
      }
    },
    onSendClicked() {
      let validate = true;
      const filterErrors = this.$children.filter((c) => {
        if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
          if (c.isValidate) {
            const result = c.validate();
            validate &= result;
            return !result;
          } else {
            c.clearValidate();
          }
        }
        return false;
      });

      if (validate) {
        let side = "Series";
        if (this.side === "L") {
          side = "Long";
        } else if (this.side === "S") {
          side = "Short";
        }

        const board = "Big Lot Board";
        let shortPosition = "Open";
        let longPosition = "Open";

        if (this.shortPosition === "C") {
          shortPosition = "Close";
        }

        if (this.longPosition === "C") {
          longPosition = "Close";
        }

        const html = `
          <div class="ui two column grid">
            <div class="right aligned column ht-${side.toLowerCase()}-label" style="padding-right: 1rem !important;">${side}:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${this.selectedSymbol.symbol}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Volume:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${VolumeFormatter(this.volume)}</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Price:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${
              isNaN(this.price) ? this.price : this.$numeral(this.price).format("0,0.000000")
            }</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Total Amount:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${
              isNaN(this.price) ? "N/A" : this.$numeral(this.volume * this.price).format("0,0.000000")
            }</div>
            <div class="right aligned column" style="padding-right: 1rem !important;">Board:</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">${board}</div>
            <br><br>
            <div class="left aligned column" style="padding-left: 1rem !important;"><b>Short Information</b></div>
            <div class="left aligned column" style="padding-left: 1rem !important;"><b>Long Information</b></div>
            <div class="left aligned column" style="padding-left: 1rem !important;">Broker ID: ${this.shortBrokerId || "-"}</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">Broker ID: ${this.longBrokerId || "-"}</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">Trader ID: ${this.shortTraderId || "-"}</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">Trader ID: ${this.longTraderId || "-"}</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">Account ID: ${this.shortAccountId || "-"}</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">Account ID: ${this.longAccountId || "-"}</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">Position: ${shortPosition}</div>
            <div class="left aligned column" style="padding-left: 1rem !important;">Position: ${longPosition}</div>
          </div>
        `;

        this.$Swal
          .fire({
            title: "Trade Report Confirmation",
            html: html,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.value) {
              this.DR52();
            }
          });
      } else {
        filterErrors[0].select();
      }
    },
    dtsPTDealInsertCtord(data) {
      if (this.dtsPTDealInsertRefId.includes(data.refId)) {
        this.$_.remove(this.dtsPTDealInsertRefId, (refId) => {
          return refId === data.refId;
        });

        if (data.resultCode === 1001) {
          this.successReponses.push(data);
        } else {
          this.errorReponses.push(data);
        }

        if (this.dtsPTDealInsertRefId.length === 0) {
          this.insertFinish();
        }
      }
    },
    insertFinish() {
      const type = this.errorReponses.length > 0 ? "error" : "success";
      if (type === "error") {
        const code = this.errorReponses[0].resultCode;
        if (code === 2002) {
          this.$Swal.fire({
            icon: type,
            text: this.errorReponses[0].resultMessage,
            title: `Error ${code}!`,
            onClose: () => {
              this.processing = false;
              this.successReponses = [];
              this.errorReponses = [];
              this.clearForm();
              this.clearValidate();
            },
          });
          return;
        }
      }

      let html = "";
      if (this.successReponses.length > 0) {
        html += `<h3 class="ui green header">Success ${this.successReponses.length} order(s).</h3>`;
        html += `<div class="ui two column grid">`;
        this.successReponses.forEach((r) => {
          html += `<div class="column">
            <div class="ui two column grid">
              <div class="column">${r.orderId}</div>
              <div class="column">${r.sendDate}</div>
            </div>
          </div>
          <div class="column" style="text-align: left; color: #00aa00;">Success</div>`;
        });
        html += "</div>";
      }
      if (this.errorReponses.length > 0) {
        html += `<h3 class="ui red header">Error ${this.errorReponses.length} order(s).</h3>`;
        html += `<div class="ui one column grid">`;
        this.$_.uniqBy(this.errorReponses, "resultCode").forEach((r) => {
          html += `
          <div class="column" style="text-align: center; color: #d91e18">${r.resultCode}: ${r.resultMessage}</div>`;
        });
      }
      this.$Swal.fire({
        icon: type,
        html: html,
        onClose: () => {
          if (!this.successReponses.length && this.errorReponses.length) {
            this.processing = false;
          } else {
            this.processing = false;
            this.clearForm();
            this.clearValidate();
            this.$EventBus.$emit("ptdealClose");
          }
          this.successReponses = [];
          this.errorReponses = [];
        },
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
    traders: get("model/systemRefData@traders"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
  },
};
</script>

<style>
.ht-long-label {
  color: #2185d0;
}

.ht-short-label {
  color: #e03997;
}
</style>
