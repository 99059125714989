const values = {
  STARTUP: "Start",
  PREOPEN1: "Pre-Open 1",
  PREOPEN2: "Pre-Open 2",
  PREOPEN3: "Pre-Open 3",
  OPEN1: "Open 1",
  OPEN2: "Open 2",
  OPEN3: "Open 3",
  INTERMISSION: "Intermission",
  INTERMISSION2: "Intermission2",
  SETTLEMENT: "Settlement",
  PRECLOSE: "Call Market",
  OFFHOUR: "Run Off",
  CLOSE: "Close",
  CLOSE2: "Close",
  AFTERMARKET: "Close",
  FREEZE: "Freeze",
  HALT: "Halt",
  HALT_NOMATCHING: "Halt",
  HALT_FULL: "Halt (Full)",
  CIRCUIT_BREAK: "Circuit Break",
  CIRCUIT_PRICE_BREAK: "Circuit Price Break",
  CIRCUIT_BREAK_NOMATCHING: "Circuit Break",
  CIRCUIT_BREAK_FULL: "Circuit Break (Full)",
  PREOPEN_TEMP: "Pre-Open",
  HALT_SEGMENT: "Halt Segment",
  PRE_AFTER_HALT: "Pre-AfterHalt",
  UNDERLYING: "Underlying",
  SUSPEND: "Suspend",
  EXPIRED: "Expired",
  PAUSE: "Pause",
};

const getMarketStatusByKey = (key) => {
  return values[key] || key;
};

export { getMarketStatusByKey };
