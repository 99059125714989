/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class FR04 extends WsReqMsg {
	constructor() {
		super(1);
		this._userId = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._conditionType = new StringMessageBase();
		this._conditionValue = new StringMessageBase();
	}
	
	get userId() {
		return this._userId;
	}
	
	set userId(userId) {
		this._userId = new StringMessageBase(userId);
	}
	
	get symbol() {
		return this._symbol;
	}
	
	set symbol(symbol) {
		this._symbol = new StringMessageBase(symbol);
	}
	
	get conditionType() {
		return this._conditionType;
	}
	
	set conditionType(conditionType) {
		this._conditionType = new StringMessageBase(conditionType);
	}
	
	get conditionValue() {
		return this._conditionValue;
	}
	
	set conditionValue(conditionValue) {
		this._conditionValue = new StringMessageBase(conditionValue);
	}
}

export default FR04;
