<template>
  <div class="company-value">
    <div class="options-container">
      <sui-checkbox v-model="selectedMarket" radio value="SET" :disabled="!isEnableETS()" class="custom-checkbox">
        <h5 class="ui grey inverted tiny header">{{ "SET" }}</h5>
      </sui-checkbox>
      <sui-checkbox v-model="selectedMarket" radio value="TFEX" :disabled="!isEnableDTS()" class="custom-checkbox">
        <h5 class="ui grey inverted tiny header">{{ "TFEX" }}</h5>
      </sui-checkbox>
      <div class="company-value-detail">
        <h5 class="ui grey header inverted ht-text-header">
          {{ "Broker Volume" }}
        </h5>
        <span class="ht-text-value">{{ brokerVol }}</span>
      </div>
      <div class="company-value-detail">
        <h5 class="ui grey header inverted ht-text-header">
          {{ "%Volume Mkt." }}
        </h5>
        <span class="ht-text-value">{{ brokerVolMktPct }}</span>
      </div>
      <div class="company-value-detail">
        <h5 class="ui grey header inverted ht-text-header">
          {{ "Broker Value" }}
        </h5>
        <span class="ht-text-value">{{ brokerVal }}</span>
      </div>
      <div class="company-value-detail">
        <h5 class="ui grey header inverted ht-text-header">
          {{ "%Value Mkt." }}
        </h5>
        <span class="ht-text-value">{{ brokerValMktPct }}</span>
      </div>
    </div>
    <div class="ui grid content-grid">
      <div class="two column row content-container">
        <div class="sixteen wide computer twelve wide large screen column twelve wide widescreen monitor column left-side-container">
          <div class="department-information">
            <ag-grid-vue
              class="department-information-table"
              :class="{
                'ag-theme-balham-dark': theme === 'Dark',
                'ag-theme-balham': theme === 'Light',
              }"
              row-selection="single"
              :grid-options="gridCompanyValueOptions"
              :column-defs="selectedMarket === 'SET' ? columnDefsETSCompanyValue : columnDefsDTSCompanyValue"
              :row-data="rowCompanyValue"
              :row-height="21"
              :header-height="32"
              :default-col-def="{ sortable: true, resizable: true }"
              :animate-rows="true"
              :suppress-drag-leave-hides-columns="true"
              :enable-cell-change-flash="true"
              :locale-text="{ noRowsToShow: `Don't have any Department.` }"
              :row-drag-managed="false"
              :pinned-bottom-row-data="pinnedBottomRowCompanyValue"
              overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
              @grid-ready="onCompanyValueGridReady"
              @cell-focused="onCellCompanyValueFocused"
            />
          </div>
        </div>

        <div class="four wide column large screen only right-side-container">
          <div class="row pie-chart-value-container">
            <PieChartValue mode="Company" :data="chartData" />
          </div>
          <div class="row top-5-trader-container">
            <Top5Trader mode="Company" :data="chartData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsETSCompanyValue, columnDefsDTSCompanyValue } from "@/components/grid/value/CompanyValueColumnDefs.js";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

Object.freeze(columnDefsETSCompanyValue);
Object.freeze(columnDefsDTSCompanyValue);

import PieChartValue from "@/components/value/PieChartValue.vue";
import Top5Trader from "@/components/value/Top5Trader.vue";

export default {
  name: "BrokerInformation",
  components: {
    AgGridVue,
    PieChartValue,
    Top5Trader,
  },
  data: () => ({
    columnDefsETSCompanyValue,
    columnDefsDTSCompanyValue,

    gridCompanyValueApi: null,
    columnCompanyValueApi: null,
    rowCompanyValue: [],
    pinnedBottomRowCompanyValue: [
      {
        side: "Total",
        departmentName: "0 Department(s)",
        buyVal: "",
        sellVal: "",
        totalVal: "",
        totalValBrkPct: "",
        commission: "",
        commissionBrkPct: "",
      },
    ],

    selectedMarket: "SET",
    isEnableETS,
    isEnableDTS,

    brokerVol: "0",
    brokerVolMktPct: "0.00%",
    brokerVal: "0",
    brokerValMktPct: "0.00%",

    BRRefId: null,
    chartData: {},
  }),
  watch: {
    selectedMarket() {
      this.filterChanged();
    },
  },
  methods: {
    onValueStartRefresh() {
      this.rowCompanyValue = [];
      this.pinnedBottomRowCompanyValue = [
        {
          rank: "Total",
          departmentName: "0 Department(s)",
          buyVal: "",
          sellVal: "",
          totalVal: "",
          totalValBrkPct: "",
          commission: "",
          commissionBrkPct: "",
        },
      ];

      this.brokerVol = "0";
      this.brokerVolMktPct = "0.00%";
      this.brokerVal = "0";
      this.brokerValMktPct = "0.00%";
      this.chartData = {};

      if (this.selectedMarket === "SET") {
        this.BR04();
      } else if (this.selectedMarket === "TFEX") {
        this.BR54();
      }
    },
    filterChanged() {
      this.onValueStartRefresh();
    },
    BR04() {
      const msg = this.$messageFactory.createMessage("BR04");
      this.BRRefId = this.$shortid.generate();

      msg.recordCount.set(0);
      msg.lastRecordId.set(0);

      this.$ws.send(msg, this.BRRefId);

      if (this.gridCompanyValueApi) {
        this.gridCompanyValueApi.showLoadingOverlay();
      }
    },
    BR54() {
      const msg = this.$messageFactory.createMessage("BR54");
      this.BRRefId = this.$shortid.generate();

      msg.recordCount.set(0);
      msg.lastRecordId.set(0);

      this.$ws.send(msg, this.BRRefId);

      if (this.gridCompanyValueApi) {
        this.gridCompanyValueApi.showLoadingOverlay();
      }
    },
    updateRowCompanyValue(value) {
      if (this.BRRefId !== value.refId) {
        return;
      }

      this.chartData = value;
      this.brokerVol = this.$numeral(value.brokerVol).format("0,0");
      if (value.brokerVolMktPct) {
        this.brokerVolMktPct = this.$numeral(value.brokerVolMktPct).format("0,0.00") + "%";
      } else {
        this.brokerVolMktPct = "0.00%";
      }
      this.brokerVal = this.$numeral(value.brokerVal).format("0,0");
      if (value.brokerValMktPct) {
        this.brokerValMktPct = this.$numeral(value.brokerValMktPct).format("0,0.00") + "%";
      } else {
        this.brokerValMktPct = "0.00%";
      }

      this.rowCompanyValue = value.records;
      this.pinnedBottomRowCompanyValue = [
        {
          rank: "Total",
          departmentName: value.records.length + " Department(s)",
          buyVal: "",
          sellVal: "",
          totalVal: "",
          totalValBrkPct: "",
          commission: "",
          commissionBrkPct: "",
        },
      ];
      if (this.gridCompanyValueApi) {
        this.gridCompanyValueApi.hideOverlay();
      }

      this.$EventBus.$emit("onValueStopRefresh");
    },
    onCompanyValueGridReady(params) {
      this.gridCompanyValueApi = params.api;
      this.columnCompanyValueApi = params.columnApi;
    },
    onCellCompanyValueFocused(event) {
      this.gridCompanyValueOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
  created() {
    this.gridCompanyValueOptions = {};

    this.$EventBus.$on("ctinf/BS04", this.updateRowCompanyValue);
    this.$EventBus.$on("ctinf/BS54", this.updateRowCompanyValue);

    this.$EventBus.$on("onValueStartRefresh", this.onValueStartRefresh);
  },
  mounted() {
    this.onValueStartRefresh();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/BS04", this.updateRowCompanyValue);
    this.$EventBus.$off("ctinf/BS54", this.updateRowCompanyValue);

    this.$EventBus.$off("onValueStartRefresh", this.onValueStartRefresh);
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
@import "~@/assets/styles/company/company-value.scss";
</style>
