import { BLUE_COLOR, positionFormatter, sideColorStyle, numeralCellFormatter, baseStyle } from "../Formatter.js";
import { isDisplayPTDealCheckbox } from "@/utils/ValidationUtils";

const columnDefsPTDealEntry = [
  {
    headerName: "Order No.",
    field: "orderId",
    pinned: "left",
    width: 140,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle,
      color: BLUE_COLOR
    },
    floatingFilterComponentFramework: "FilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      suppressFilterButton: true
    },
    filter: true,
    suppressMenu: true
  },
  {
    headerName: "Time",
    field: "time",
    width: 100,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR
    }
  },
  {
    headerName: "Symbol",
    field: "symbol",
    width: 100,
    cellStyle: baseStyle,
    floatingFilterComponentFramework: "SymbolFilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      suppressFilterButton: true
    },
    filter: true,
    suppressMenu: true
  },
  {
    headerName: "Position",
    field: "positionType",
    width: 100,
    valueFormatter: positionFormatter,
    cellStyle: baseStyle
  },
  {
    headerName: "Side",
    field: "side",
    width: 65,
    cellStyle: sideColorStyle,
    floatingFilterComponentFramework: "FilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      suppressFilterButton: true
    },
    filter: true,
    suppressMenu: true
  },
  {
    headerName: "Volume",
    field: "volume",
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    width: 100,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle
    }
  },
  {
    headerName: "Price",
    field: "price",
    valueFormatter: params => {
      if (!params.data) {
        return;
      }
      return numeralCellFormatter(params, "0,0.000000");
    },
    width: 150,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle
    }
  },
  {
    headerName: "Status",
    field: "status",
    width: 100,
    cellStyle: baseStyle,
    floatingFilterComponentFramework: "FilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      suppressFilterButton: true
    },
    filter: true,
    suppressMenu: true
  },
  {
    headerName: "Clearing Date",
    field: "clearingDate",
    width: 140,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR
    }
  },
  {
    headerName: "Comm+VAT",
    field: "commVat",
    width: 120,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle
    }
  },
  {
    headerName: "Confirm#",
    field: "confirmNo",
    width: 140,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR
    },
    floatingFilterComponentFramework: "FilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      suppressFilterButton: true
    },
    filter: true,
    suppressMenu: true
  },
  {
    headerName: "Flag",
    field: "ttf",
    width: 65,
    cellStyle: baseStyle
  },
  {
    headerName: "Account",
    field: "accountCode",
    width: 140,
    cellStyle: baseStyle,
    floatingFilterComponentFramework: "FilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      suppressFilterButton: true
    },
    filter: true,
    suppressMenu: true
  },
  {
    headerName: "Trade ID",
    field: "tradeId",
    width: 200,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR
    },
    floatingFilterComponentFramework: "FilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      suppressFilterButton: true
    },
    filter: true,
    suppressMenu: true
  },
  {
    headerName: "Order Type",
    field: "orderType",
    width: 100,
    cellStyle: baseStyle
  },
  {
    headerName: "Service Type",
    field: "serviceType",
    width: 115,
    cellStyle: baseStyle
  },
  {
    headerName: "Firm",
    field: "firm",
    width: 100,
    cellStyle: baseStyle
  },
  {
    headerName: "Contract Firm",
    field: "contractFirm",
    width: 120,
    cellStyle: baseStyle
  }
];

export default columnDefsPTDealEntry;
