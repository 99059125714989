<script>
import BaseDropdown from "./BaseDropdown";
import { get } from "vuex-pathify";

export default {
  extends: BaseDropdown,
  props: {
    inputUpperCase: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: String,
      default: "startsWith",
    },
    tradeReportMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: { userType: get("model/systemRefData@userType") },
  watch: {
    tradeReportMode(mode) {
      if (mode) {
        this.options = [
          {
            text: "-",
            value: "",
          },
          {
            text: "TTF",
            value: "1",
          },
          {
            text: "NVDR",
            value: "2",
          },
          {
            text: "BUYIN",
            value: "3",
          },
        ];

        return;
      }

      this.options = [
        {
          text: "-",
          value: "",
        },
        {
          text: "TTF",
          value: "1",
        },
        {
          text: "NVDR",
          value: "2",
        },
      ];

      return;
    },
  },
  data() {
    return {
      style: "width: 100%",
      defaultValue: "",
      displayValue: "",
      options: [
        {
          text: "-",
          value: "",
        },
        {
          text: "TTF",
          value: "1",
        },
        {
          text: "NVDR",
          value: "2",
        },
      ],
    };
  },
  created() {
    if (this.tradeReportMode) {
      this.options = [
        {
          text: "-",
          value: "",
        },
        {
          text: "TTF",
          value: "1",
        },
        {
          text: "NVDR",
          value: "2",
        },
        {
          text: "BUYIN",
          value: "3",
        },
      ];
    }
  },
};
</script>
