/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class BR53 extends WsReqMsg {
	constructor() {
		super(1);
		this._marketId = new StringMessageBase();
		this._recordCount = new NumberMessageBase();
		this._lastRecordId = new NumberMessageBase();
		this._sortBy = new StringMessageBase();
	}
	
	get marketId() {
		return this._marketId;
	}
	
	set marketId(marketId) {
		this._marketId = new StringMessageBase(marketId);
	}
	
	get recordCount() {
		return this._recordCount;
	}
	
	set recordCount(recordCount) {
		this._recordCount = new NumberMessageBase(recordCount);
	}
	
	get lastRecordId() {
		return this._lastRecordId;
	}
	
	set lastRecordId(lastRecordId) {
		this._lastRecordId = new NumberMessageBase(lastRecordId);
	}
	
	get sortBy() {
		return this._sortBy;
	}
	
	set sortBy(sortBy) {
		this._sortBy = new StringMessageBase(sortBy);
	}
}

export default BR53;
