<template>
  <div class="customer-list" style="overflow: hidden">
    <div style="height: 3rem; line-height: 3rem">
      <div class="ui form">
        <div class="fields">
          <div class="field" style="padding-left: 1.5rem">
            <div class="ui form">
              <div class="inline fields">
                <div class="field">
                  <sui-checkbox v-model="selectedMarket" radio value="SET" :disabled="!isEnableETS()">
                    <h4 class="ui grey inverted tiny header">SET</h4>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox v-model="selectedMarket" radio value="TFEX" :disabled="!isEnableDTS()">
                    <h4 class="ui grey inverted tiny header">TFEX</h4>
                  </sui-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-left: 0px; padding-right: 0px; width: 4.5rem">Trader ID</h5>
          </div>
          <div class="field" style="width: 8rem; padding: 0px 0px 0px 0rem !important">
            <InputText
              v-model="traderID"
              class="ht-trader-id"
              style="height: 36px"
              name="Trader ID"
              placeholder="Trader ID"
              :uppercase="true"
              @enter="onEnter"
            />
          </div>
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-right: 0px">Type</h5>
          </div>
          <div class="field" style="width: 11rem; padding: 0px 0px 0px 1rem !important">
            <sui-dropdown v-model="selectedType" fluid button pointing :options="typeOptions" class="ht-custlist-dropdown" />
          </div>
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-right: 0px">Status</h5>
          </div>
          <div class="field" style="width: 10rem; padding: 0px 0px 0px 1rem !important">
            <sui-dropdown v-model="selectedStatus" fluid button pointing :options="statusOptions" class="ht-custlist-dropdown" />
          </div>
          <div style="padding: 0 1rem 0 1rem !important">
            <h5 class="ui grey header inverted ht-text-header">
              Trader Name <span class="ht-text-value">{{ traderName }}</span>
            </h5>
          </div>
          <div style="padding: 0 0 0 1rem !important">
            <h5 class="ui grey header inverted ht-text-header">
              Title <span class="ht-text-value">{{ traderTitle }}</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="ui grid">
      <div class="sixteen wide column" style="height: calc(55vh - 3rem - 32px - 5px)">
        <ag-grid-vue
          :key="refreshKey"
          style="width: 100%; height: calc(55vh - 3rem - 32px - 5px); padding: 0px"
          :class="{
            'ag-theme-balham-dark': theme === 'Dark',
            'ag-theme-balham': theme === 'Light',
          }"
          row-selection="single"
          row-model-type="infinite"
          :grid-options="gridCustomerListOptions"
          :column-defs="selectedMarket === 'SET' ? columnDefsETSCustomerList : columnDefsDTSCustomerList"
          :get-row-node-id="getCustomerListRowNodeId"
          :row-height="21"
          :header-height="32"
          :default-col-def="{ sortable: false, resizable: true }"
          :animate-rows="true"
          :suppress-drag-leave-hides-columns="true"
          :enable-cell-change-flash="true"
          :locale-text="{ noRowsToShow: `Don't have any Customer.` }"
          :row-drag-managed="false"
          :pinned-bottom-row-data="pinnedBottomRowCustomerList"
          overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
          @grid-ready="onCustomerListGridReady"
          @cell-focused="onCellCustomerListFocused"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsETSCustomerList, columnDefsDTSCustomerList } from "@/components/grid/customers/CustomerListColumnDefs.js";
import InputText from "@/components/InputText";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

Object.freeze(columnDefsETSCustomerList);
Object.freeze(columnDefsDTSCustomerList);

export default {
  name: "CustomerList",
  components: {
    AgGridVue,
    InputText,
  },
  data: () => ({
    columnDefsETSCustomerList,
    columnDefsDTSCustomerList,

    gridCustomerListApi: null,
    columnCustomerListApi: null,
    rowCustomerList: [],
    refreshKey: 0,
    pinnedBottomRowCustomerList: [
      {
        custId: "Total",
        name: "0 Customer(s) Remaining...",
        buyValue: "",
        sellValue: "",
        netValue: "",
        custFlag: "T",
        mutalFlag: "T",
        comm: "",
        totalNet: "",
        brkNetValue: "",
        totalTrade: "",
      },
    ],
    selectedSymbol: null,
    selectedMarket: "SET",
    selectedType: "D",
    typeOptions: [
      {
        text: "All Customers",
        value: "A",
      },
      {
        text: "Have Order (Owner Only)",
        value: "O",
      },
      {
        text: "Have Deal (Owner Only)",
        value: "D",
      },
    ],
    selectedStatus: "B",
    statusOptions: [
      {
        text: "Both",
        value: "B",
      },
      {
        text: "Already Confirmed",
        value: "A",
      },
      {
        text: "Unconfirmed",
        value: "U",
      },
    ],
    traderID: "",
    traderName: "",
    traderTitle: "",
    isEnableETS,
    isEnableDTS,

    customerListRefId: null,
    recordCount: 100,
    gridParams: null,
    customerListLastRecordId: -1,
    traderInfoRefId: null,
  }),
  watch: {
    selectedMarket() {
      this.traderID = "";
      this.traderName = "";
      this.traderTitle = "";
      this.selectedType = "D";
      this.selectedStatus = "B";

      this.filterChanged();
    },
    selectedType() {
      this.filterChanged();
    },
    selectedStatus() {
      this.filterChanged();
    },
  },
  methods: {
    onCustomerStartRefresh() {
      if (this.nightMode === "Y") {
        this.$Swal.fire("Error", "Customer List does not support in After Close.", "error");
        return;
      }
      if (!this.gridCustomerListApi) {
        return;
      }
      this.rowCustomerList = [];
      this.customerListLastRecordId = -1;

      this.refreshKey++;
    },
    onEnter() {
      if (this.traderID.length > 4) {
        this.traderID = this.traderID.slice(0, 4);
      }
      this.onCustomerStartRefresh();
    },
    filterChanged() {
      this.onCustomerStartRefresh();
    },
    CR01() {
      const msg = this.$messageFactory.createMessage("CR01");

      const trader = this.$_.find(this.traders, ["exchangeId", 1]);
      if (!trader?.traderCode) {
        this.$Swal.fire("Error", "User does not have SET account.", "error");
        this.setRowData();
        return;
      }
      msg.ownTid.set(trader.traderCode);

      if (!this.traderID) {
        this.CR04(trader.traderCode);
      } else {
        this.CR04(this.traderID);
      }
      msg.reqTid.set(this.traderID);

      let type = "A";

      if (this.selectedType === "A") {
        switch (this.selectedStatus) {
          case "B":
            /*'A'-all customer*/
            break;
          case "A":
            type = "U"; /*'U'-customer have flag confirm*/
            break;
          case "U":
            type = "X"; /*'X'-customer no flag confirm*/
            break;
        }
      } else if (this.selectedType === "O") {
        switch (this.selectedStatus) {
          case "B":
            type = "O"; /*'O'-customer have order only*/
            break;
          case "A":
            type = "V"; /*'V'-customer have order and flag confirm*/
            break;
          case "U":
            type = "Y"; /*'Y'-customer have order and no flag confirm*/
            break;
        }
      } else if (this.selectedType === "D") {
        switch (this.selectedStatus) {
          case "B":
            type = "D"; /*'D'-customer have deal only*/
            break;
          case "A":
            type = "W"; /*'W'-customer have deal and flag confirm*/
            break;
          case "U":
            type = "Z"; /*'Z'-customer have deal and no flag confirm*/
            break;
        }
      }
      msg.type.set(type);
      msg.recordCount.set(this.recordCount);
      msg.lastRecordId.set(this.customerListLastRecordId);

      this.customerListRefId = this.$shortid.generate();
      this.$ws.send(msg, this.customerListRefId);
      if (this.gridCustomerListApi) {
        this.gridCustomerListApi.showLoadingOverlay();
      }
    },
    CR51() {
      const msg = this.$messageFactory.createMessage("CR51");

      const trader = this.$_.find(this.traders, ["exchangeId", 2]);
      if (!trader?.traderCode) {
        this.$Swal.fire("Error", "User does not have TFEX account.", "error");
        this.setRowData();
        return;
      }
      msg.ownTid.set(trader.traderCode);

      if (!this.traderID) {
        this.CR54(trader.traderCode);
      } else {
        this.CR54(this.traderID);
      }
      msg.reqTid.set(this.traderID);

      let type = "A";
      if (this.selectedType === "A") {
        switch (this.selectedStatus) {
          case "B":
            /*'A'-all customer*/
            break;
          case "A":
            type = "U"; /*'U'-customer have flag confirm*/
            break;
          case "U":
            type = "X"; /*'X'-customer no flag confirm*/
            break;
        }
      } else if (this.selectedType === "O") {
        switch (this.selectedStatus) {
          case "B":
            type = "O"; /*'O'-customer have order only*/
            break;
          case "A":
            type = "V"; /*'V'-customer have order and flag confirm*/
            break;
          case "U":
            type = "Y"; /*'Y'-customer have order and no flag confirm*/
            break;
        }
      } else if (this.selectedType === "D") {
        switch (this.selectedStatus) {
          case "B":
            type = "D"; /*'D'-customer have deal only*/
            break;
          case "A":
            type = "W"; /*'W'-customer have deal and flag confirm*/
            break;
          case "U":
            type = "Z"; /*'Z'-customer have deal and no flag confirm*/
            break;
        }
      }
      msg.type.set(type);
      msg.recordCount.set(this.recordCount);
      msg.lastRecordId.set(this.customerListLastRecordId);

      this.customerListRefId = this.$shortid.generate();
      this.$ws.send(msg, this.customerListRefId);
      if (this.gridCustomerListApi) {
        this.gridCustomerListApi.showLoadingOverlay();
      }
    },
    CR04(traderID) {
      const msg = this.$messageFactory.createMessage("CR04");

      msg.reqTid.set(traderID);

      this.traderInfoRefId = this.$shortid.generate();
      this.$ws.send(msg, this.traderInfoRefId);
    },
    CR54(traderID) {
      const msg = this.$messageFactory.createMessage("CR54");

      msg.reqTid.set(traderID);

      this.traderInfoRefId = this.$shortid.generate();
      this.$ws.send(msg, this.traderInfoRefId);
    },
    updateRowCustomerList(customers) {
      if (this.customerListRefId !== customers.refId) {
        return;
      }

      if (customers.records.length === 0) {
        this.customerListLastRecordId = -1;
      } else {
        this.rowCustomerList = this.rowCustomerList.concat(customers.records);
        this.customerListLastRecordId = customers.records[customers.records.length - 1].custRec;
      }

      this.setRowData();
    },
    setRowData() {
      // take a slice of the total rows
      const rowsThisPage = this.rowCustomerList.slice(this.gridParams.startRow, this.gridParams.endRow);
      // if on or after the last page, work out the last row.
      let lastRow = -1;
      if (this.rowCustomerList.length === this.gridParams.endRow) {
        this.pinnedBottomRowCustomerList = [
          {
            custId: "Total",
            name: this.rowCustomerList.length + " Customer(s) Remaining...",
            buyValue: "",
            sellValue: "",
            netValue: "",
            custFlag: "T",
            mutalFlag: "T",
            comm: "",
            totalNet: "",
            brkNetValue: "",
            totalTrade: "",
          },
        ];
        lastRow = this.rowCustomerList.length + 1;
      } else if (this.rowCustomerList.length < this.gridParams.endRow) {
        this.pinnedBottomRowCustomerList = [
          {
            custId: "Total",
            name: this.rowCustomerList.length + " Customer(s)",
            buyValue: "",
            sellValue: "",
            netValue: "",
            custFlag: "T",
            mutalFlag: "T",
            comm: "",
            totalNet: "",
            brkNetValue: "",
            totalTrade: "",
          },
        ];
        lastRow = this.rowCustomerList.length;
      }

      if (this.rowCustomerList.length === 0) {
        this.gridCustomerListApi.showNoRowsOverlay();
        this.$EventBus.$emit("onCustomerStopRefresh");
      } else {
        if (this.gridCustomerListApi) {
          this.gridCustomerListApi.hideOverlay();
        }
        this.$EventBus.$emit("onCustomerStopRefresh");
      }
      // call the success callback
      this.gridParams.successCallback(rowsThisPage, lastRow);
    },
    updateTraderInfo(data) {
      if (this.traderInfoRefId === data.refId) {
        if (data.resultCode === 1001) {
          this.traderName = data.name;
          this.traderTitle = data.title;
        } else {
          console.error(data.resultCode + ": " + data.resultMessage);
        }
      }
    },
    onCustomerListGridReady(params) {
      this.gridCustomerListApi = params.api;
      this.columnCustomerListApi = params.columnApi;
      this.gridCustomerListApi.setDatasource({
        rowCount: null,
        getRows: (params) => {
          if (this.nightMode === "Y") {
            return;
          }
          console.log("asking for " + params.startRow + " to " + params.endRow);
          this.gridCustomerListApi.showLoadingOverlay();

          this.gridParams = params;

          if (this.selectedMarket === "SET") {
            this.CR01();
          } else if (this.selectedMarket === "TFEX") {
            this.CR51();
          }
        },
      });
    },
    getCustomerListRowNodeId(data) {
      return data.custId;
    },
    onCellCustomerListFocused(event) {
      this.gridCustomerListOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
    traders: get("model/systemRefData@traders"),
    nightMode: get("model/systemRefData@nightMode"),
  },
  created() {
    this.gridCustomerListOptions = {};

    this.$EventBus.$on("ctinf/CS01", this.updateRowCustomerList);
    this.$EventBus.$on("ctinf/CS51", this.updateRowCustomerList);
    this.$EventBus.$on("ctinf/CS04", this.updateTraderInfo);
    this.$EventBus.$on("ctinf/CS54", this.updateTraderInfo);

    this.$EventBus.$on("onCustomerStartRefresh", this.onCustomerStartRefresh);
  },
  mounted() {
    if (this.nightMode === "Y") {
      this.$Swal.fire("Error", "Customer List does not support in After Close.", "error");
      this.$nextTick(() => {
        this.pinnedBottomRowCustomerList = [
          {
            custId: "Total",
            name: "0 Customer(s)",
            buyValue: "",
            sellValue: "",
            netValue: "",
            custFlag: "T",
            mutalFlag: "T",
            comm: "",
            totalNet: "",
            brkNetValue: "",
            totalTrade: "",
          },
        ];
      });
      return;
    }
    this.onCustomerStartRefresh();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/CS01", this.updateRowCustomerList);
    this.$EventBus.$off("ctinf/CS51", this.updateRowCustomerList);
    this.$EventBus.$off("ctinf/CS04", this.updateTraderInfo);
    this.$EventBus.$off("ctinf/CS54", this.updateTraderInfo);

    this.$EventBus.$off("onCustomerStartRefresh", this.onCustomerStartRefresh);
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.ui.button.fluid.dropdown {
  background-color: #2f383f;
}

.ui.grey.header {
  color: #dcddde !important;
}

.ui.secondary.menu {
  min-height: unset;
  height: 32px !important;
  border: 0px !important;
  background-color: unset !important;
}

.dropdown.icon {
  color: #999999;
}
</style>

<style>
.ui.fluid.dropdown > .dropdown.icon {
  color: #999999;
}

.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.ht-news-datepicker .el-range-separator {
  padding: 0px !important;
  margin-top: -10px !important;
  color: #dcddde !important;
}

.ht-news-datepicker .el-input__icon.el-range__icon.el-icon-date {
  margin-top: -10px !important;
}

.el-picker-panel__sidebar {
  background-color: #2f383f !important;
}

.el-picker-panel__shortcut {
  color: #dcddde !important;
}

.el-picker-panel__shortcut:hover {
  color: #409eff !important;
}

.el-date-table__row > .available.in-range > div {
  background-color: #1b2025 !important;
}

.ht-trader-id > input {
  border: 0px !important;
}

.ht-custlist-dropdown {
  height: 2rem;
  min-height: 2rem;
  top: calc(50% - 1.5rem) !important;
}
.ht-custlist-dropdown .text {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 1rem;
  right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-custlist-dropdown i.dropdown {
  line-height: 0 !important;
  margin-top: 3px !important;
}

.ht-text-header {
  display: inline;
  margin: 0 !important;
  padding: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-text-value {
  color: #e4af0a !important;
  padding-left: 1rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
