/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class OR05 extends WsReqMsg {
	constructor() {
		super(1);
		this._orderId = new StringMessageBase();
		this._sendDate = new StringMessageBase();
	}
	
	get orderId() {
		return this._orderId;
	}
	
	set orderId(orderId) {
		this._orderId = new StringMessageBase(orderId);
	}
	
	get sendDate() {
		return this._sendDate;
	}
	
	set sendDate(sendDate) {
		this._sendDate = new StringMessageBase(sendDate);
	}
}

export default OR05;
