<template>
  <el-time-select
    ref="picker"
    v-model="time"
    :disabled="disabled"
    :format="format"
    :picker-options="pickerOptions"
    :placeholder="placeholder"
    :data-tooltip="displayErrorText"
    :class="{ error: displayErrorText }"
    data-position="top center"
    data-inverted="inverted"
    :clearable="false"
  >
  </el-time-select>
</template>

<script>
import { TimePicker } from "element-ui";
export default {
  components: {
    "el-time-select": TimePicker,
  },
  props: {
    value: {
      type: Date,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: "HH:mm",
    },
    isValidate: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputStyle: {
      type: String,
    },
  },
  data() {
    return {
      time: this.$moment().toDate(),
      displayErrorText: null,
      pickerOptions: null,
    };
  },
  watch: {
    value(value) {
      this.time = value;
    },
    time(value) {
      this.$emit("input", value);
    },
    errorText(value) {
      this.displayErrorText = value;
    },
  },
  created() {
    this.pickerOptions = {
      format: this.format,
    };

    if (this.value) {
      this.time = this.value;
    } else {
      this.$emit("input", this.time);
    }
  },
  mounted() {
    if (this.inputStyle) {
      this.$refs.picker.$el.querySelector("input").setAttribute("style", this.inputStyle);
    }
  },
  methods: {
    clearValidate() {
      this.displayErrorText = null;
    },
    validate() {
      this.clearValidate();
      if (this.disabled) {
        return true;
      }

      if (this.required && !this.time) {
        this.displayErrorText = this.name + " is requried.";
        this.$emit("onErrorText", this.displayErrorText);
        return false;
      }

      return true;
    },
    focus() {
      const input = this.$refs.picker.$el.querySelector("input");
      input.focus();
    },
    select() {
      const input = this.$refs.picker.$el.querySelector("input");
      input.select();
    },
    isDisabled() {
      return this.disabled;
    },
  },
};
</script>
