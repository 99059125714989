import { make } from "vuex-pathify";

const websocket = () => {
  const state = {
    /* Global status*/
    socketStatus: false,

    /* Channel status*/
    ctordState: "CLOSED",
    ctinfState: "CLOSED",
    bcstState: "CLOSED",
    ntfState: "CLOSED"
  };
  const mutations = make.mutations(state);
  const actions = make.actions(state);
  const getters = make.getters(state);
  return { namespaced: true, state, mutations, actions, getters };
};

export default websocket;
