<template>
  <div class="quote-sell-by-date">
    <ag-grid-vue
      style="width: 100%; height:100%; padding: 0px;"
      :class="{
        'ag-theme-balham-dark': theme === 'Dark',
        'ag-theme-balham': theme === 'Light'
      }"
      row-selection="single"
      :grid-options="gridQuoteSaleByDateOptions"
      :column-defs="columnDefsQuoteSaleByDate"
      :row-data="rowQuoteSaleByDate"
      :get-row-node-id="getQuoteSaleByDateRowNodeId"
      :row-height="21"
      :header-height="32"
      :default-col-def="{ sortable: true, resizable: true }"
      :animate-rows="true"
      :suppress-drag-leave-hides-columns="true"
      :enable-cell-change-flash="true"
      :locale-text="{ noRowsToShow: `Don't have any Sale.` }"
      :row-drag-managed="false"
      overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
      @grid-ready="onQuoteSaleByDateGridReady"
      @cell-focused="onCellQuoteSaleByDateFocused"
    />
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsQuoteSaleByDate } from "@/components/grid/quote/QuoteSaleByDateColumnDefs.js";

Object.freeze(columnDefsQuoteSaleByDate);

export default {
  name: "QuoteSaleByDate",
  components: {
    AgGridVue
  },
  data: () => ({
    columnDefsQuoteSaleByDate,

    gridQuoteSaleByDateApi: null,
    columnQuoteSaleByDateApi: null,
    rowQuoteSaleByDate: []
  }),
  watch: {
    currentQuoteSelectedSymbol() {
      this.SR08();
    }
  },
  methods: {
    onQuoteTableStartRefresh() {
      this.SR08();
    },
    SR08() {
      if (!this.currentQuoteSelectedSymbol) {
        this.$EventBus.$emit("onQuoteTableStopRefresh");
        return;
      }
      const msg = this.$messageFactory.createMessage("SR08");

      msg.securityId.set(this.currentQuoteSelectedSymbol.value.securityId);
      msg.exchangeId.set(this.currentQuoteSelectedSymbol.value.exchangeId);

      this.$ws.send(msg);
      if (this.gridQuoteSaleByDateApi) {
        this.gridQuoteSaleByDateApi.showLoadingOverlay();
      }
    },
    updateRowQuoteSaleByDate(history) {
      this.rowQuoteSaleByDate = history.histories;
      if (this.gridQuoteSaleByDateApi) {
        this.gridQuoteSaleByDateApi.hideOverlay();
      }
      this.$EventBus.$emit("onQuoteTableStopRefresh");
    },
    onQuoteSaleByDateGridReady(params) {
      this.gridQuoteSaleByDateApi = params.api;
      this.columnQuoteSaleByDateApi = params.columnApi;
    },
    getQuoteSaleByDateRowNodeId(data) {
      return data.date;
    },
    onCellQuoteSaleByDateFocused(event) {
      this.gridQuoteSaleByDateOptions.api.forEachNode(node => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    }
  },
  computed: {
    theme: sync("global/theme"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol")
  },
  created() {
    this.gridQuoteSaleByDateOptions = {};
    this.SR08();

    this.$EventBus.$on("ctinf/SS08", this.updateRowQuoteSaleByDate);

    this.$EventBus.$on("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS08", this.updateRowQuoteSaleByDate);

    this.$EventBus.$off("onQuoteTableStartRefresh", this.onQuoteTableStartRefresh);
  }
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.quote-sell-by-date {
  height: 100%;
}
</style>
