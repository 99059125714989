import store from "./../store";
import { find as _find } from "lodash";

export const isEnableStopOrder = (accountCode) => {
  const userType = store.get("model/systemRefData@userType");
  const advTraderFlag = store.get("model/systemRefData@advTraderFlag");
  const licenseFlags = store.get("model/systemRefData@licenseFlags");

  if (userType === "T") {
    return advTraderFlag === "Y" && licenseFlags.charAt(0) === "Y";
  } else if (userType === "I") {
    if (!accountCode) {
      return false;
    }
    const accounts = store.get("model/systemRefData@accounts");
    const account = _find(accounts, (a) => {
      return a.accountCode === accountCode;
    });

    if (account) {
      return account.advItwFlag === "Y" && licenseFlags.charAt(0) === "Y";
    }
    return false;
  }
};

export const isEnableTrailingOrder = (accountCode) => {
  const userType = store.get("model/systemRefData@userType");
  const advTraderFlag = store.get("model/systemRefData@advTraderFlag");
  const licenseFlags = store.get("model/systemRefData@licenseFlags");

  if (userType === "T") {
    return advTraderFlag === "Y" && licenseFlags.charAt(1) === "Y";
  } else if (userType === "I") {
    if (!accountCode) {
      return false;
    }
    const accounts = store.get("model/systemRefData@accounts");
    const account = _find(accounts, (a) => {
      return a.accountCode === accountCode;
    });

    if (account) {
      return account.advItwFlag === "Y" && licenseFlags.charAt(1) === "Y";
    }
    return false;
  }
};

export const isEnableGoodTillOrder = (accountCode) => {
  const userType = store.get("model/systemRefData@userType");
  const goodtillTraderFlag = store.get("model/systemRefData@goodtillTraderFlag");
  const accounts = store.get("model/systemRefData@accounts");
  const traderType = store.get("global/traderType");

  let account = null;

  if (accountCode) {
    account = _find(accounts, (a) => {
      return a.accountCode === accountCode;
    });
  }

  if (userType === "T" && ["M", "U"].includes(traderType)) {
    return goodtillTraderFlag === "Y";
  } else if (userType === "I" || (userType === "T" && !["M", "U"].includes(traderType))) {
    if (!account) {
      return false;
    }

    return account.goodtillItwFlag === "Y";
  }
};

export const isEnableETS = (licenseFlags) => {
  let newLicenseFlags = licenseFlags;
  if (!licenseFlags) {
    newLicenseFlags = store.get("model/systemRefData@licenseFlags");
  }

  if (!newLicenseFlags) {
    return false;
  }
  return newLicenseFlags.charAt(4) === "Y";
};

export const isEnableDTS = (licenseFlags) => {
  let newLicenseFlags = licenseFlags;
  if (!licenseFlags) {
    newLicenseFlags = store.get("model/systemRefData@licenseFlags");
  }

  if (!newLicenseFlags) {
    return false;
  }
  return newLicenseFlags.charAt(3) === "Y";
};

export const isEnableHitradeX = (licenseFlags) => {
  let newLicenseFlags = licenseFlags;
  if (!licenseFlags) {
    newLicenseFlags = store.get("model/systemRefData@licenseFlags");
  }

  if (!newLicenseFlags) {
    return false;
  }
  return newLicenseFlags.charAt(5) === "Y";
};
