/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class DR01 extends WsReqMsg {
	constructor() {
		super(1);
		this._accountCode = new StringMessageBase();
		this._securityId = new NumberMessageBase();
		this._orderId = new StringMessageBase();
		this._dealId = new StringMessageBase();
		this._tradeId = new StringMessageBase();
		this._recordCount = new NumberMessageBase();
		this._lastRecordId = new StringMessageBase();
		this._confirmNo = new StringMessageBase();
		this._sendDate = new StringMessageBase();
		this._origin = new StringMessageBase();
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
	
	get securityId() {
		return this._securityId;
	}
	
	set securityId(securityId) {
		this._securityId = new NumberMessageBase(securityId);
	}
	
	get orderId() {
		return this._orderId;
	}
	
	set orderId(orderId) {
		this._orderId = new StringMessageBase(orderId);
	}
	
	get dealId() {
		return this._dealId;
	}
	
	set dealId(dealId) {
		this._dealId = new StringMessageBase(dealId);
	}
	
	get tradeId() {
		return this._tradeId;
	}
	
	set tradeId(tradeId) {
		this._tradeId = new StringMessageBase(tradeId);
	}
	
	get recordCount() {
		return this._recordCount;
	}
	
	set recordCount(recordCount) {
		this._recordCount = new NumberMessageBase(recordCount);
	}
	
	get lastRecordId() {
		return this._lastRecordId;
	}
	
	set lastRecordId(lastRecordId) {
		this._lastRecordId = new StringMessageBase(lastRecordId);
	}
	
	get confirmNo() {
		return this._confirmNo;
	}
	
	set confirmNo(confirmNo) {
		this._confirmNo = new StringMessageBase(confirmNo);
	}
	
	get sendDate() {
		return this._sendDate;
	}
	
	set sendDate(sendDate) {
		this._sendDate = new StringMessageBase(sendDate);
	}
	
	get origin() {
		return this._origin;
	}
	
	set origin(origin) {
		this._origin = new StringMessageBase(origin);
	}
}

export default DR01;
