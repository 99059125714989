/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:57
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class AS52 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._sumSellableQty = new StringMessageBase();
		this._sumOnHandQty = new StringMessageBase();
		this._sumUnrealPl = new StringMessageBase();
		this._sumClosedQty = new StringMessageBase();
		this._sumRealPl = new StringMessageBase();
		this._sumOpenQty = new StringMessageBase();
		this._sumStartOfDayQty = new StringMessageBase();
		this._sumTodayOpenQty = new StringMessageBase();
		this._sumUnrealCostPl = new StringMessageBase();
		this._sumOutstandingOpenQty = new StringMessageBase();
		this._sumOutstandingCloseQty = new StringMessageBase();
		this._sumUnrealPlVal = new StringMessageBase();
		this._sumUnrealCostPlVal = new StringMessageBase();
		this._records = new ArrayMessageBase(Record);
		this._marketCharts = new ArrayMessageBase(MarketChart);
		this._sumCostValue = new StringMessageBase();
		this._sumMarketValue = new StringMessageBase();
		this._sumUnrealizedPercent = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get sumSellableQty() {
		return this._sumSellableQty;
	}
	
	get sumOnHandQty() {
		return this._sumOnHandQty;
	}
	
	get sumUnrealPl() {
		return this._sumUnrealPl;
	}
	
	get sumClosedQty() {
		return this._sumClosedQty;
	}
	
	get sumRealPl() {
		return this._sumRealPl;
	}
	
	get sumOpenQty() {
		return this._sumOpenQty;
	}
	
	get sumStartOfDayQty() {
		return this._sumStartOfDayQty;
	}
	
	get sumTodayOpenQty() {
		return this._sumTodayOpenQty;
	}
	
	get sumUnrealCostPl() {
		return this._sumUnrealCostPl;
	}
	
	get sumOutstandingOpenQty() {
		return this._sumOutstandingOpenQty;
	}
	
	get sumOutstandingCloseQty() {
		return this._sumOutstandingCloseQty;
	}
	
	get sumUnrealPlVal() {
		return this._sumUnrealPlVal;
	}
	
	get sumUnrealCostPlVal() {
		return this._sumUnrealCostPlVal;
	}
	
	get records() {
		return this._records;
	}
	
	get marketCharts() {
		return this._marketCharts;
	}
	
	get sumCostValue() {
		return this._sumCostValue;
	}
	
	get sumMarketValue() {
		return this._sumMarketValue;
	}
	
	get sumUnrealizedPercent() {
		return this._sumUnrealizedPercent;
	}
}

export class Record {
	constructor() {
		this._seriesId = new StringMessageBase();
		this._side = new StringMessageBase();
		this._sellableQty = new StringMessageBase();
		this._onHandQty = new StringMessageBase();
		this._openAvg = new StringMessageBase();
		this._markAvg = new StringMessageBase();
		this._lastSale = new StringMessageBase();
		this._settlementPrice = new StringMessageBase();
		this._unrealPl = new StringMessageBase();
		this._closedQty = new StringMessageBase();
		this._realPl = new StringMessageBase();
		this._closedAvg = new StringMessageBase();
		this._closedCostAvg = new StringMessageBase();
		this._openQty = new StringMessageBase();
		this._startOfDayQty = new StringMessageBase();
		this._todayOpenQty = new StringMessageBase();
		this._costAvg = new StringMessageBase();
		this._unrealCostPl = new StringMessageBase();
		this._availableQty = new StringMessageBase();
		this._outstandingOpenQty = new StringMessageBase();
		this._outstandingCloseQty = new StringMessageBase();
		this._todayPremium = new StringMessageBase();
		this._expectedTodayPremium = new StringMessageBase();
		this._ordMarginAvg = new StringMessageBase();
		this._sellableQtyVal = new StringMessageBase();
		this._unrealPlVal = new StringMessageBase();
		this._costValue = new StringMessageBase();
		this._marketValue = new StringMessageBase();
		this._marketPrice = new StringMessageBase();
		this._unrealPercent = new StringMessageBase();
	}
	
	get seriesId() {
		return this._seriesId;
	}
	
	get side() {
		return this._side;
	}
	
	get sellableQty() {
		return this._sellableQty;
	}
	
	get onHandQty() {
		return this._onHandQty;
	}
	
	get openAvg() {
		return this._openAvg;
	}
	
	get markAvg() {
		return this._markAvg;
	}
	
	get lastSale() {
		return this._lastSale;
	}
	
	get settlementPrice() {
		return this._settlementPrice;
	}
	
	get unrealPl() {
		return this._unrealPl;
	}
	
	get closedQty() {
		return this._closedQty;
	}
	
	get realPl() {
		return this._realPl;
	}
	
	get closedAvg() {
		return this._closedAvg;
	}
	
	get closedCostAvg() {
		return this._closedCostAvg;
	}
	
	get openQty() {
		return this._openQty;
	}
	
	get startOfDayQty() {
		return this._startOfDayQty;
	}
	
	get todayOpenQty() {
		return this._todayOpenQty;
	}
	
	get costAvg() {
		return this._costAvg;
	}
	
	get unrealCostPl() {
		return this._unrealCostPl;
	}
	
	get availableQty() {
		return this._availableQty;
	}
	
	get outstandingOpenQty() {
		return this._outstandingOpenQty;
	}
	
	get outstandingCloseQty() {
		return this._outstandingCloseQty;
	}
	
	get todayPremium() {
		return this._todayPremium;
	}
	
	get expectedTodayPremium() {
		return this._expectedTodayPremium;
	}
	
	get ordMarginAvg() {
		return this._ordMarginAvg;
	}
	
	get sellableQtyVal() {
		return this._sellableQtyVal;
	}
	
	get unrealPlVal() {
		return this._unrealPlVal;
	}
	
	get costValue() {
		return this._costValue;
	}
	
	get marketValue() {
		return this._marketValue;
	}
	
	get marketPrice() {
		return this._marketPrice;
	}
	
	get unrealPercent() {
		return this._unrealPercent;
	}
}

export class MarketChart {
	constructor() {
		this._symbol = new StringMessageBase();
		this._marketValue = new StringMessageBase();
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get marketValue() {
		return this._marketValue;
	}
}

export default AS52;
