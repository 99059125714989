const values = {
  C: "Cash",
  B: "Credit Balance",
  M: "Margin"
};

const getAccountTypeByKey = key => {
  return values[key] || key;
};

export { getAccountTypeByKey };
