<template>
  <div class="portfolio-commission-table">
    <div class="commission-group-container">
      <div class="custom-header">
        {{ "Commission Group" }}
      </div>
      <div class="custom-header yellow">
        {{ etsAccountInfoCinf && etsAccountInfoCinf.commissionType }}
      </div>
    </div>
    <div class="custom-grid">
      <ag-grid-vue
        :class="{
          'ag-theme-balham-dark': theme === 'Dark',
          'ag-theme-balham': theme === 'Light',
        }"
        class="commission-table"
        row-selection="single"
        :grid-options="gridCommissionTableOptions"
        :column-defs="columnDefsCommissionTable"
        :row-data="rowCommissionTable"
        :get-row-node-id="getCommissionTableRowNodeId"
        :row-height="21"
        :header-height="32"
        :default-col-def="{ sortable: true, resizable: true }"
        :animate-rows="true"
        :suppress-drag-leave-hides-columns="true"
        :enable-cell-change-flash="true"
        :locale-text="{ noRowsToShow: `Don't have any Commission.` }"
        :row-drag-managed="false"
        overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
        @grid-ready="onCommissionTableGridReady"
        @cell-focused="onCellCommissionTableFocused"
      />
      <ag-grid-vue
        :class="{
          'ag-theme-balham-dark': theme === 'Dark',
          'ag-theme-balham': theme === 'Light',
        }"
        class="commission-table"
        row-selection="single"
        :grid-options="gridCommissionGroupOptions"
        :column-defs="columnDefsCommissionGroup"
        :row-data="rowCommissionGroup"
        :get-row-node-id="getCommissionGroupRowNodeId"
        :row-height="21"
        :header-height="32"
        :default-col-def="{ sortable: true, resizable: true }"
        :animate-rows="true"
        :suppress-drag-leave-hides-columns="true"
        :enable-cell-change-flash="true"
        :locale-text="{ noRowsToShow: `Don't have any Commission.` }"
        :row-drag-managed="false"
        overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
        @grid-ready="onCommissionGroupGridReady"
        @cell-focused="onCellCommissionGroupFocused"
      />
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsCommissionTable, columnDefsCommissionGroup } from "@/components/grid/commission/ColumnDefs.js";

Object.freeze(columnDefsCommissionTable);
Object.freeze(columnDefsCommissionGroup);

export default {
  name: "PortfolioCommissionTable",
  components: {
    AgGridVue,
  },
  props: ["onShow"],
  data: () => ({
    columnDefsCommissionTable,
    columnDefsCommissionGroup,
    gridCommissionTableApi: null,
    columnCommissionTableApi: null,
    rowCommissionTable: [],
    gridCommissionGroupApi: null,
    columnCommissionGroupApi: null,
    rowCommissionGroup: [],
    etsAccountInfoCinf: {},
    ar01Ref: null,
    ar05Ref: null,
  }),
  watch: {
    currentSelectedAccount() {
      if (!this.onShow) return;

      this.AR01();
    },
  },
  methods: {
    onPortfolioTableStartRefresh() {
      if (!this.onShow) return;

      this.AR01();
    },
    AR01() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount.exchangeId == 0 || !this.currentSelectedAccount?.accountCode) {
        this.etsAccountInfoCinf = {};
        return;
      }

      const msg = this.$messageFactory.createMessage("AR01");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);
      msg.exchangeId.set(this.currentSelectedAccount.exchangeId);
      this.ar01Ref = this.$shortid.generate();

      this.$ws.send(msg, this.ar01Ref);
    },
    AR05() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount.exchangeId == 0 || !this.currentSelectedAccount.accountCode) {
        this.rowCommissionTable = [];
        this.rowCommissionGroup = [];
        this.$EventBus.$emit("onPortfolioTableStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("AR05");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);
      msg.serviceType.set(this.etsAccountInfoCinf.serviceType);
      msg.customerType.set(this.etsAccountInfoCinf.customerType);
      msg.commissionType.set(this.etsAccountInfoCinf.commissionType);
      this.ar05Ref = this.$shortid.generate();

      this.$ws.send(msg, this.ar05Ref);
      if (this.gridCommissionTableApi && this.gridCommissionGroupApi) {
        this.gridCommissionTableApi.showLoadingOverlay();
        this.gridCommissionGroupApi.showLoadingOverlay();
      }
    },
    updateAccountCommission(commission) {
      this.rowCommissionTable = commission.commTbls;
      this.rowCommissionGroup = commission.commGrps;
    },
    onCommissionTableGridReady(params) {
      this.gridCommissionTableApi = params.api;
      this.columnCommissionTableApi = params.columnApi;
    },
    getCommissionTableRowNodeId(data) {
      return data.commType + data.maxValue;
    },
    onCellCommissionTableFocused(event) {
      this.gridCommissionTableOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    onCommissionGroupGridReady(params) {
      this.gridCommissionGroupApi = params.api;
      this.columnCommissionGroupApi = params.columnApi;
    },
    getCommissionGroupRowNodeId(data) {
      return data.commType + data.commFlag;
    },
    onCellCommissionGroupFocused(event) {
      this.gridCommissionGroupOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    onEtsAccountInfoCinf(data) {
      if (data.refId !== this.ar01Ref) return;

      this.etsAccountInfoCinf = data;
      this.AR05();
    },
    etsAccountCommissionCinf(position) {
      if (position.refId !== this.ar05Ref) return;

      if (!this.onShow) return;

      this.updateAccountCommission(position);
      if (this.gridCommissionTableApi) {
        this.gridCommissionTableApi.hideOverlay();
      }
      if (this.gridCommissionGroupApi) {
        this.gridCommissionGroupApi.hideOverlay();
      }
      this.$EventBus.$emit("onPortfolioTableStopRefresh");
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
  },
  created() {
    this.gridCommissionTableOptions = {};
    this.gridCommissionGroupOptions = {};
    this.AR01();

    this.$EventBus.$on("ctinf/AS01", this.onEtsAccountInfoCinf);
    this.$EventBus.$on("ctinf/AS05", this.etsAccountCommissionCinf);

    this.$EventBus.$on("onPortfolioTableStartRefresh", this.onPortfolioTableStartRefresh);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS01", this.onEtsAccountInfoCinf);
    this.$EventBus.$off("ctinf/AS05", this.etsAccountCommissionCinf);

    this.$EventBus.$off("onPortfolioTableStartRefresh", this.onPortfolioTableStartRefresh);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/portfolio/portfolio-commission-table.scss";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>
