<template>
  <div>
    <sui-modal v-model="modalState" :closable="false" size="mini">
      <sui-modal-header v-if="mode === 'NEW'">Create a New Favorite</sui-modal-header>
      <sui-modal-header v-if="mode === 'EDIT'">Edit a Favorite</sui-modal-header>
      <sui-modal-content>
        <div class="ui form">
          <div class="required field">
            <h4 class="ui grey inverted tiny header">Favorite Name</h4>
            <input v-model="favouriteName" type="text" placeholder="Favorite Name" />
          </div>
          <div v-if="mode === 'NEW'" class="required field">
            <h4 class="ui grey inverted tiny header">Symbol</h4>
            <SymbolDropdown v-model="selectedSymbol" market="ALL" image />
          </div>
        </div>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button positive @click.native="save"> Save </sui-button>
        <sui-button @click.native="cancel"> Cancel </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import SymbolDropdown from "@/components/SymbolDropdown";

export default {
  name: "MarketFavoriteModal",
  components: {
    SymbolDropdown,
  },
  data: () => ({
    modalState: false,
    favouriteName: "",
    favoriteId: "",
    mode: "NEW",
    selectedSymbol: null,
  }),
  created() {
    this.$EventBus.$on("openMarketFavoriteModal", this.open);
  },
  methods: {
    toggle() {
      this.modalState = !this.modalState;
    },
    open(mode, favouriteName, favoriteId) {
      this.mode = mode;
      this.favouriteName = favouriteName;
      this.favoriteId = favoriteId;
      this.modalState = true;

      this.selectedSymbol = null;
    },
    close() {
      this.modalState = false;
    },
    save() {
      if (!this.favouriteName) {
        this.$Swal.fire(`Warning!`, "Please input your favourite name.", "warning");
        return;
      }

      if (!this.favouriteName.match(/^[a-zA-Z0-9-_ ]+$/)) {
        this.$Swal.fire(`Warning!`, "Invalid Favorite name. Special characters is not allowed.", "warning");
        return;
      }

      if (!this.selectedSymbol && this.mode === "NEW") {
        this.$Swal.fire(`Warning!`, "Please select your first symbol.", "warning");
        return;
      }

      if (this.mode === "NEW") {
        this.UR01(this.favouriteName, "I", this.selectedSymbol);
        this.favouriteName = "";
        this.close();
      } else if (this.mode === "EDIT") {
        this.UR01(this.favouriteName, "U");
        this.favouriteName = "";
        this.close();
      }
    },
    cancel() {
      this.$EventBus.$emit("cancelMarketFavoriteModal", this.mode);
      this.close();
    },
    UR01(favouriteName, updateType, selectedSymbol) {
      const msg = this.$messageFactory.createMessage("UR01");

      msg.favoriteId.set(this.favoriteId);
      msg.name.set(favouriteName);
      if (this.mode === "NEW") {
        const symbols = [{ symbol: selectedSymbol.symbol, exchange: { id: selectedSymbol.exchangeId } }];
        msg.symbols.set(encodeURI(JSON.stringify(symbols)));
      } else if (this.mode === "EDIT") {
        let symbols = [];
        this.currentMaketWatchList.forEach((data) => {
          symbols.push({ symbol: data.symbol, exchange: { id: data.exchangeId } });
        });
        msg.symbols.set(encodeURI(JSON.stringify(symbols)));
      }
      msg.updateType.set(updateType);

      this.$ws.send(msg);
    },
    UR05() {
      const msg = this.$messageFactory.createMessage("UR05");

      this.$ws.send(msg);
    },
  },
  computed: {
    currentMaketWatchList: get("global/currentMaketWatchList"),
  },
};
</script>
