<template>
  <div>
    <table style="border: 0px" class="ui very compact small single line unstackable striped inverted table">
      <thead ref="tickerHeaderRef">
        <TickerHeader />
      </thead>
      <tbody>
        <tr
          v-for="(ticker, index) in arrayTickerColumn"
          :key="index"
          :class="[{ active: index === lastPosition - 1 }]"
          :style="{ height: `${tableRowHeight}px !important` }"
        >
          <TickerBody :ticker="ticker" />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import TickerHeader from "@/components/ticker/TickerHeader.vue";
import TickerBody from "@/components/ticker/TickerBody.vue";

export default {
  components: {
    TickerHeader,
    TickerBody,
  },
  props: { marketTickerTFEXHeight: Number },

  data: () => ({
    tickerTaker: null,

    maximunTickers: 0,
    maximunTickersColumn: 0,

    lastPosition: 0,

    arrayTickerColumn: [],
    tableRowHeight: 21,
  }),

  watch: {
    marketTickerTFEXHeight(val) {
      this.tickerBuffer = [];
      this.arrayTicker = [];
      this.lastPosition = 0;

      this.calMaximunTickers();
      this.PR51();
    },
    tickerFilterModeSum() {
      this.tickerBuffer = [];
      this.arrayTicker = [];
      this.lastPosition = 0;

      this.calMaximunTickers();
      this.PR51();
    },
  },

  created() {
    this.tickerBuffer = [];
    this.arrayTicker = [];

    /* Subscribe all */
    this.XR03("PB51"); /* DtsPriceLastTradeBcst */

    this.$EventBus.$on("ctinf/PS51", this.updateInfo);
    this.$EventBus.$on("bcst/PB51", this.updateTicker);

    this.PR51();
  },
  mounted() {
    this.takeTickerFromBuffer();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/PS51", this.updateInfo);
    this.$EventBus.$off("bcst/PB51", this.updateTicker);

    if (this.tickerTaker) {
      clearInterval(this.tickerTaker);
    }
  },
  methods: {
    calMaximunTickers() {
      const vw = Math.max(window.innerWidth);
      const calFontSize = 12 + (4 * Number(this.fontSize || "1") * (vw - 820)) / (1920 - 820);

      const tickerHeader = this.$refs.tickerHeaderRef;

      const tableHeaderHeight = tickerHeader?.offsetHeight || 32;
      const tableRowHeight = Math.ceil(calFontSize + 8);

      let maximunTickersColumn = Math.floor(((this.marketTickerTFEXHeight || 100) - tableHeaderHeight) / tableRowHeight);

      this.maximunTickersColumn = maximunTickersColumn;
      this.maximunTickers = maximunTickersColumn;
      this.tableRowHeight = tableRowHeight;
    },
    PR51() {
      const msg = this.$messageFactory.createMessage("PR51");

      msg.lastTradeCount.set(50);

      this.$ws.send(msg);
    },
    XR03(messageCode) {
      const msg = this.$messageFactory.createMessage("XR03");

      msg.messageCode.set(messageCode);

      this.$ws.send(msg);
    },
    updateInfo(update) {
      update.trades
        .slice(0)
        .reverse()
        .forEach((ticker) => {
          ticker.code = "PS51";
          this.tickerToBuffer(ticker);
        });
    },
    updateTicker(update) {
      update.code = "PB51";
      this.tickerToBuffer(update);
    },
    tickerToBuffer(ticker) {
      this.tickerBuffer.unshift(ticker);
    },
    sumTicker(tickerBuffer) {
      let uniqTicker = this.$_.uniqWith(tickerBuffer, (ticker1, ticker2) => {
        return ticker1.time === ticker2.time && ticker1.symbol === ticker2.symbol && ticker1.side === ticker2.side && ticker1.price === ticker2.price;
      });

      uniqTicker.forEach((ticker, index) => {
        let sumVolume = this.$_.sumBy(tickerBuffer, (o) => {
          if (ticker.time === o.time && ticker.symbol === o.symbol && ticker.side === o.side && ticker.price === o.price) {
            return this.$numeral(o.volume).value();
          } else {
            return 0;
          }
        });

        uniqTicker[index].volume = this.$numeral(sumVolume).format("0,0");
      });

      return uniqTicker;
    },
    takeTickerFromBuffer() {
      if (this.tickerTaker) {
        clearInterval(this.tickerTaker);
      }

      this.tickerTaker = setInterval(() => {
        let tickerBuffer = this.tickerBuffer.slice(-parseInt(this.maximunTickersColumn));
        this.tickerBuffer = [];

        if (tickerBuffer.length > 0) {
          if (this.tickerFilterModeSum) {
            const uniqTicker = this.sumTicker(tickerBuffer);
            this.renderTickerFromBuffer(uniqTicker);
          } else {
            this.renderTickerFromBuffer(tickerBuffer);
          }
        }
      }, Number(process.env.VUE_APP_TICKER_RATE));
    },
    renderTickerFromBuffer(tickerBuffer) {
      if (this.lastPosition >= this.maximunTickers) {
        this.lastPosition -= this.maximunTickers;
      }

      if (this.lastPosition + tickerBuffer.length <= this.maximunTickers) {
        this.arrayTicker.splice(this.lastPosition, tickerBuffer.length, ...tickerBuffer);

        this.lastPosition += tickerBuffer.length;
      } else {
        let tickerBufferTail = tickerBuffer.slice(0, this.maximunTickers - this.lastPosition);
        let tickerBufferHead = tickerBuffer.slice(this.maximunTickers - this.lastPosition);

        this.arrayTicker.splice(this.lastPosition, this.maximunTickers, ...tickerBufferTail);
        this.arrayTicker.splice(0, tickerBufferHead.length, ...tickerBufferHead);

        this.lastPosition = tickerBufferHead.length;
      }

      this.arrayTickerColumn = this.arrayTicker;
      if (this.arrayTickerColumn.length > this.maximunTickersColumn) {
        this.arrayTickerColumn.length = this.maximunTickersColumn;
      }
    },
  },
  computed: {
    tickerFilterModeSum: get("global/tickerFilterModeSum"),
  },
};
</script>

<style scoped>
.ui.table.inverted td.active,
.ui.table.inverted tr.active {
  background: rgba(224, 224, 224, 0.2) !important;
}
</style>
