<template>
  <div class="ticker-view">
    <div class="view-header">
      <div class="left-side-header">
        <div class="filter-container">
          <div class="filter-label">{{ "Filter" }}</div>
          <sui-dropdown v-model="selectedFavorite" fluid text="None" button pointing :options="favoriteOptions" class="favorite-dropdown" />
        </div>
        <div class="market-container">
          <div class="market-label">{{ "Market" }}</div>
          <sui-checkbox v-model="tickerFilterSET" checkbox :disabled="!isEnableETS()" />
          <div class="label">{{ "SET" }}</div>
          <sui-checkbox v-model="tickerFilterTFEX" checkbox :disabled="!isEnableDTS()" />
          <div class="label">{{ "TFEX" }}</div>
        </div>
      </div>
      <div class="right-side-header">
        <div class="mode-sum-container">
          <sui-checkbox v-model="tickerFilterModeSum" checkbox />
          <div class="label">{{ "Mode Sum" }}</div>
        </div>
      </div>
    </div>
    <div class="ticker-full-page-container" ref="tickerFullPage">
      <TickerFullPage :ticker-full-page-height="tickerFullPageHeight" />
    </div>
  </div>
</template>

<script setup>
import useTickerView from "./hooks/useTickerView";

import TickerFullPage from "@/components/ticker/TickerFullPage";

const {
  tickerFullPageHeight,
  selectedFavorite,
  favoriteOptions,
  tickerFilterSET,
  tickerFilterTFEX,
  tickerFilterModeSum,
  tickerFullPage,
  isEnableETS,
  isEnableDTS,
} = useTickerView();
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/ticker/ticker-view.scss";
</style>
