const vhToPx = (value) => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    y = w.innerHeight || e.clientHeight || g.clientHeight;

  let result = (y * value) / 100;
  return result;
};

const vwToPx = (value) => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName("body")[0],
    x = w.innerWidth || e.clientWidth || g.clientWidth;

  let result = (x * value) / 100;
  return result;
};

const remToPx = (value) => {
  let result = value * parseFloat(getComputedStyle(document.documentElement).fontSize);
  return result;
};

export { vhToPx, vwToPx, remToPx };
