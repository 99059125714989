<template>
  <div class="broker-ranking-view">
    <sui-tab :menu="{ attached: 'top' }" class="tabs">
      <sui-tab-pane class="tab" title="Broker Ranking" attached="top">
        <BrokerRanking />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import BrokerRanking from "@/components/broker/BrokerRanking.vue";

export default {
  name: "Brokerranking",
  components: {
    BrokerRanking,
  },
  data: () => ({
    isLoading: false,
  }),
  created() {
    this.$EventBus.$on("onBrokerStartRefresh", this.onBrokerStartRefresh);
    this.$EventBus.$on("onBrokerStopRefresh", this.onBrokerStopRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("onBrokerStartRefresh", this.onBrokerStartRefresh);
    this.$EventBus.$off("onBrokerStopRefresh", this.onBrokerStopRefresh);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onBrokerStartRefresh");
    },
    onBrokerStartRefresh() {
      this.isLoading = true;
    },
    onBrokerStopRefresh() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.broker-ranking-view {
  background-color: black;
  width: 100%;
  height: 100%;

  .tabs {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;

    .tab {
      flex: 1;
    }
  }
}
// .column {
//   background-color: rgb(38, 45, 51) !important;
// }

.ht-border {
  border-style: solid;
  border-color: black;
}
</style>
