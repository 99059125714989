<template>
  <div class="information-view">
    <sui-tab :menu="{ attached: 'top' }" class="information-view-tab">
      <sui-tab-pane title="Broker Information" attached="top">
        <BrokerInformation />
      </sui-tab-pane>
      <sui-tab-pane title="Trader Information" attached="top">
        <TraderInformation />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script setup>
import useInformationView from "./hooks/useInformationView";

import BrokerInformation from "@/components/information/BrokerInformation.vue";
import TraderInformation from "@/components/information/TraderInformation.vue";

const { isLoading, onRefresh } = useInformationView();
</script>

<style lang="scss" scoped>
.information-view {
  height: 100%;
  background-color: #262d33;

  .information-view-tab {
    height: 100%;
    display: flex;
    flex-direction: column;

    .ui.tab {
      flex: 1;
      min-height: 0;
    }
  }
}
// .column {
//   background-color: #262d33 !important;
// }
</style>
