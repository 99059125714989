var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticker-full-page",class:{ black: _vm.theme === 'Dark' }},[_c('div',{staticClass:"ui grid content-container"},[_c('div',{staticClass:"four columns row",staticStyle:{"padding-bottom":"0px"}},_vm._l((4),function(item,column){return _c('div',{key:column,ref:"table-container",refInFor:true,staticClass:"column table-container",style:([
          _vm.theme === 'Light' ? { 'border-left': '5px solid #000000' } : { 'border-left': '5px solid #000000' },
          column === 0 ? { 'padding-right': '0px', 'border-left': '0px' } : {},
          column === 1 ? { padding: '0px' } : {},
          column === 2 ? { padding: '0px' } : {},
          column === 3 ? { 'padding-left': '0px', 'border-right': '0px' } : {},
          _vm.theme === 'Light' ? { 'border-top': '0px solid #e3e5e5' } : { 'border-top': '0px solid #414141' },
        ])},[_c('table',{staticClass:"ui very compact small single line unstackable striped table",class:{ inverted: _vm.theme === 'Dark' },staticStyle:{"border":"0px","border-radius":"0px"}},[_c('thead',{ref:"table-header",refInFor:true},[_c('TickerHeader')],1),_c('tbody',_vm._l((_vm.arrayTickerColumn[column]),function(ticker,index){return _c('tr',{key:index,class:{
                active: index === _vm.lastPosition - (_vm.maximumTickers / 4) * column - 1,
              },style:({ height: `${_vm.tableRowHeight}px !important` })},[_c('TickerBody',{attrs:{"ticker":ticker}})],1)}),0)])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }