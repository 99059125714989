<template>
  <div class="all-account-dropdown">
    <div class="dropdown-container">
      <div
        class="ht-base"
        style="flex: 1"
        :style="baseStyle ? baseStyle : style"
        :data-tooltip="displayErrorText"
        data-position="top center"
        data-inverted="inverted"
        :class="[{ error: displayErrorText, disabled: disabled }, 'ht-dropdown']"
      >
        <div ref="container" class="ht-wrapper">
          <div ref="inputContainer" class="ht-input" :class="{ disabled: disabled }">
            <input
              ref="input"
              v-model="displayValue"
              type="text"
              spellcheck="false"
              :style="inputStyle"
              :placeholder="placeholder"
              :disabled="disabled"
              :autofocus="autofocus"
              @focus="onInputFocus"
              @input="onInput"
              @click="onInputClick"
              @blur="onInputBlur"
              @keyup="onKeyUp"
              @keydown="onKeyDown"
            />
            <div class="ht-button" @click="onButtonClick">
              <i class="angle icon" :class="{ down: !showMenu, up: showMenu }" :style="buttonStyle"></i>
            </div>
          </div>
        </div>
      </div>
      <div v-if="allAccountMode && nightMode !== 'Y' && userType === 'T'" class="checkbox-container">
        <sui-checkbox label="My Customers" v-model="myCustomers" class="custom-checkbox" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseDropdown from "./BaseDropdown";
import { get, sync } from "vuex-pathify";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils";

export default {
  extends: BaseDropdown,
  props: {
    market: {
      type: String,
      default: "SET",
    },
    filterable: {
      type: String,
      default: "startsWith",
    },
    inputStyle: {
      type: String,
      default: "height: 2rem !important; min-height: 2rem !important;",
    },
    buttonStyle: {
      type: String,
      default: "height: 2rem !important; min-height: 2rem !important;",
    },
    inputUpperCase: {
      type: Boolean,
      default: true,
    },
    allAccountMode: {
      type: Boolean,
      default: true,
    },
    scroller: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Account",
    },
  },
  data() {
    return {
      style: "background-color: #2F383F; width: 12rem; min-width: 12rem; height: 2rem !important; min-height: 2rem !important;",
      defaultValue: null,
      myCustomers: true,
      ownerOptions: null,
      hierarchyOptions: null,
      resolve: null,
      etsCustomerListDataRef: null,
      dtsCustomerListDataRef: null,
      stateCounter: 0,
      tempCustomers: [],
      forceUpdateOptions: true,
    };
  },
  watch: {
    myCustomers(value) {
      this.useMyCustomer = value;
      this.init();
    },
  },
  created() {
    if (this.allAccountMode && this.userType === "T") {
      this.defaultValue = "all_accounts";
      this.displayValue = "All Accounts";
      this.selectedValue = "all_accounts";

      this.tempSelectedObject = {
        value: "all_accounts",
        text: "All Accounts",
      };

      this.options.push({
        value: "all_accounts",
        text: "All Accounts",
      });

      this.$emit("input", "all_accounts");
    }

    this.$EventBus.$on("ctinf/CS01", this.onReceiveCustomersList);

    if (this.allAccountMode) {
      this.forceWidth = this.$remToPx(14);
    }
    this.init();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/CS01", this.onReceiveCustomersList);
  },
  methods: {
    init() {
      this.$EventBus.$emit("processingdropdown", this._uid, true);
      this.generateOptions().then((options) => {
        this.options = Object.assign([], options);
        if (options.length > 0) {
          if (!this.defaultValue && this.userType === "I") {
            this.defaultValue = options[0].value;
            this.displayValue = options[0].text;
            this.selectedValue = options[0].value;
            this.tempSelectedObject = options;
            this.$emit("input", options[0].value);
          } else if (!this.value && this.defaultValue && this.defaultValue !== "all_accounts") {
            const selectedOption = options.find((option) => option.value === this.defaultValue);

            if (selectedOption) {
              this.displayValue = selectedOption.text;
              this.selectedValue = selectedOption.value;
              this.tempSelectedObject = selectedOption;
              this.$emit("input", selectedOption.value);
            }
          } else if (this.value && options.length) {
            const selectedOption = options.find((option) => option.value === this.value);

            if (selectedOption) {
              this.displayValue = selectedOption.text;
              this.selectedValue = selectedOption.value;
              this.tempSelectedObject = selectedOption;
            }
          }
        }

        this.$EventBus.$emit("processingdropdown", this._uid, false, this.options);
      });
    },
    generateOptions() {
      return new Promise((resolve) => {
        const isETS = isEnableETS();
        const isDTS = isEnableDTS();

        let options = [];
        if (this.allAccountMode) {
          if (this.userType === "T") {
            options.push({
              value: "all_accounts",
              text: "All Accounts",
            });
          }
          if (this.userType === "T" && isETS && isDTS) {
            options.push({
              value: "all_equity_accounts",
              text: "All Equity Accounts",
            });

            options.push({
              value: "all_derivative_accounts",
              text: "All Derivative Accounts",
            });
          }
        }

        if (!this.useMyCustomer) {
          if (this.hierarchyOptions) {
            resolve(this.hierarchyOptions);
          } else {
            this.tempCustomers = [];
            this.stateCounter = 0;
            this.hierarchyOptions = options;
            this.resolve = resolve;
            if (isETS) {
              this.sendRequestETSCustomerList();
            } else {
              this.stateCounter++;
            }
            if (isDTS) {
              this.sendRequestDTSCustomerList();
            } else {
              this.stateCounter++;
            }
          }
        } else {
          if (!this.ownerOptions) {
            const newAccount = [];
            this.accounts.forEach((account) => {
              if (account.exchangeId == 1 && isETS) {
                if (this.allAccountMode || this.market === "ALL") {
                  newAccount.push({
                    value: account.accountCode,
                    text: account.accountCode + " (Equity)",
                  });
                } else {
                  if (this.market === "SET" || this.market === "ALL") {
                    newAccount.push({
                      value: account.accountCode,
                      text: account.accountCode,
                    });
                  }
                }
              } else if (account.exchangeId == 2 && isDTS) {
                if (this.allAccountMode || this.market === "ALL") {
                  newAccount.push({
                    value: account.accountCode,
                    text: account.accountCode + " (Derivative)",
                  });
                } else {
                  if (this.market === "TFEX") {
                    newAccount.push({
                      value: account.accountCode,
                      text: account.accountCode,
                    });
                  }
                }
              }
            });
            options = options.concat(newAccount);
            this.ownerOptions = options;
          }
          if (this.selectedValue) {
            const found = this.$_.find(this.ownerOptions, (c) => {
              return c.value === this.selectedValue;
            });
            if (!found) {
              if (this.defaultValue) {
                let checker = this.defaultValue;
                const defaultValue = this.$_.find(this.ownerOptions, (c) => {
                  return c.value === checker;
                });

                this.displayValue = defaultValue.text;
                this.selectedValue = defaultValue.value;
                this.tempSelectedObject = defaultValue;
              } else {
                this.displayValue = null;
                this.selectedValue = null;
                this.tempSelectedObject = null;
              }
              this.$emit("input", this.selectedValue);
            }
          }
          resolve(this.ownerOptions);
        }
      });
    },
    sendRequestETSCustomerList() {
      const msg = this.$messageFactory.createMessage("CR01");
      const trader = this.$_.find(this.traders, ["exchangeId", 1]);

      msg.ownTid.set(trader.traderCode);
      msg.type.set("A");
      msg.recordCount.set("0");
      msg.lastRecordId.set("0");

      this.etsCustomerListDataRef = this.$shortid.generate();
      this.$ws.send(msg, this.etsCustomerListDataRef);
    },
    sendRequestDTSCustomerList() {
      let newAccount = [];
      this.accounts.forEach((account) => {
        if (account.exchangeId == 2) {
          if (this.allAccountMode || this.market === "ALL") {
            newAccount.push({
              value: account.accountCode,
              text: account.accountCode + " (Derivative)",
            });
          } else {
            newAccount.push({
              value: account.accountCode,
              text: account.accountCode,
            });
          }
        }
      });
      this.tempCustomers = this.tempCustomers.concat(newAccount);

      this.stateCounter++;
      if (this.stateCounter === 2) {
        this.stateCounter = 0;
        this.hierarchyOptions = [...this.hierarchyOptions, ...this.tempCustomers];
        this.resolve(this.hierarchyOptions);
      }
    },
    onReceiveCustomersList(data) {
      if (this.etsCustomerListDataRef === data.refId) {
        if (data.resultCode === 1001) {
          this.stateCounter++;

          let newAccount = [];
          data.records.forEach((c) => {
            if (this.allAccountMode || this.market === "ALL") {
              newAccount.push({
                value: c.custId,
                text: c.custId + " (Equity)",
              });
            } else {
              if (this.market === "SET") {
                newAccount.push({
                  value: c.custId,
                  text: c.custId,
                });
              }
            }
          });

          this.tempCustomers = this.tempCustomers.concat(newAccount);
          if (this.stateCounter === 2) {
            this.stateCounter = 0;
            this.hierarchyOptions = [...this.hierarchyOptions, ...this.tempCustomers];
            this.resolve(this.hierarchyOptions);
          }
        } else {
          console.error(data.resultCode + ": " + data.resultMessage);
        }
      }
    },
  },
  computed: {
    userType: get("model/systemRefData@userType"),
    accounts: get("model/systemRefData@accounts"),
    traders: get("model/systemRefData@traders"),
    useMyCustomer: sync("global/useMyCustomer"),
    nightMode: get("model/systemRefData@nightMode"),
  },
};
</script>

<style lang="scss" scoped>
.all-account-dropdown {
  width: 100%;

  &:deep(.ht-dropdown) {
    width: 100% !important;
    min-width: 0 !important;
  }

  .dropdown-container {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    height: 100%;

    .checkbox-container {
      overflow: hidden;
      white-space: nowrap;

      .custom-checkbox {
        &:deep(label) {
          font-weight: 700;
        }
      }
    }
  }
}
.ht-dropdown {
  position: relative;
  display: inline-flex;
  vertical-align: top;
  border-radius: 0.28571429rem;
  min-height: 2rem;
  line-height: 2rem;
  background: #2f383f;
}

.ht-dropdown.error input {
  border: 1px solid #d91e18 !important;
  border-right: 0 !important;
}
.ht-dropdown.error .ht-button {
  border: 1px solid #d91e18 !important;
  border-left: 0 !important;
}

.ht-input {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ht-button {
  min-width: 2rem;
  width: 2rem;
  text-align: center;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
}

.ht-button i,
.ht-button i::before {
  margin: 0 auto;
}

.ht-button i {
  position: absolute;
  right: 0.4rem;
  color: #dcddde;
}

.ht-dropdown.disabled .ht-button i {
  color: grey !important;
}

.ht-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
}

.ht-dropdown input {
  flex: 1;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0.28571429rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 0 0 0 0.75rem !important;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  z-index: 0;
  color: #dcddde !important;
  background: #2f383f !important;
  vertical-align: middle !important;
  // margin-top: -0.25rem !important;
}

.ht-dropdown input:focus {
  outline: none;
}

.ht-dropdown.disabled,
.ht-dropdown input:disabled {
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
.ht-dropdown.disabled .ht-button {
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}

.ht-items {
  position: relative;
  width: 100%;
}
</style>
