import { ref, computed, watch, onBeforeMount, onBeforeUnmount, onMounted, getCurrentInstance } from "vue";
import store from "@/store";

import { isEnableDTS, isEnableETS } from "@/utils/LicenseUtils";

export default function useTickerView() {
  const vm = getCurrentInstance().proxy;
  const tickerFullPageHeight = ref(0);
  const selectedFavorite = ref(null);
  const favoriteOptions = ref([]);

  const favorites = computed(() => store.getters["model/systemRefData"]?.favorites);
  const currentSelectedFavorite = computed(() => store.getters["global/currentSelectedFavorite"]);
  const tickerFullPage = ref(null);

  const tickerFilterSET = computed({
    get() {
      return store.getters["global/tickerFilterSET"];
    },
    set(filter) {
      store.dispatch("global/setTickerFilterSET", filter);
    },
  });

  const tickerFilterTFEX = computed({
    get() {
      return store.getters["global/tickerFilterTFEX"];
    },
    set(filter) {
      store.dispatch("global/setTickerFilterTFEX", filter);
    },
  });

  const tickerFilterFavorite = computed({
    get() {
      return store.getters["global/tickerFilterFavorite"];
    },
    set(filter) {
      store.dispatch("global/setTickerFilterFavorite", filter);
    },
  });

  const tickerFilterModeSum = computed({
    get() {
      return store.getters["global/tickerFilterModeSum"];
    },
    set(filter) {
      store.dispatch("global/setTickerFilterModeSum", filter);
    },
  });

  const reCalculateTickerFullPageHeight = () => {
    if (tickerFullPage.value) {
      tickerFullPageHeight.value = tickerFullPage.value.offsetHeight;
      return;
    }

    tickerFullPageHeight.value = 0;
  };

  const updateFavorite = (favorites) => {
    const colorList = ["red", "orange", "yellow", "green", "blue", "purple"];
    let resolveFavouriteOptions = [];

    resolveFavouriteOptions.push({ text: "None", value: null, label: { empty: true, circular: true } });

    if (favorites?.length) {
      favorites.forEach((favorite, index) => {
        resolveFavouriteOptions.push({
          text: favorite.name,
          value: favorite.name,
          favoriteId: favorite.favoriteId,
          label: { color: colorList[index % 6], empty: true, circular: true },
        });
      });
    }

    favoriteOptions.value = resolveFavouriteOptions;
  };

  watch(favorites, () => {
    updateFavorite(favorites);
  });

  watch(selectedFavorite, () => {
    if (!selectedFavorite) {
      store.dispatch("global/setTickerFilterFavorite", false);
      return store.dispatch("global/setCurrentSelectedFavorite", "Port");
    }

    store.dispatch("global/setTickerFilterFavorite", true);
    return store.dispatch("global/setCurrentSelectedFavorite", selectedFavorite.value);
  });

  onBeforeMount(() => {
    if (!isEnableETS()) {
      store.dispatch("global/setTickerFilterSET", false);
    }
    if (!isEnableDTS()) {
      store.dispatch("global/setTickerFilterTFEX", false);
    }
    if (favorites.value) {
      updateFavorite(favorites.value);
    }

    vm.$EventBus.$on("onResize", reCalculateTickerFullPageHeight);
  });

  onMounted(() => {
    reCalculateTickerFullPageHeight();

    if (currentSelectedFavorite.value && currentSelectedFavorite.value !== "Port") {
      selectedFavorite.value = currentSelectedFavorite.value;
    } else {
      selectedFavorite.value = null;
    }
  });

  onBeforeUnmount(() => {
    vm.$EventBus.$off("onResize", reCalculateTickerFullPageHeight);
  });

  return {
    tickerFullPageHeight,
    selectedFavorite,
    favoriteOptions,
    tickerFilterSET,
    tickerFilterTFEX,
    tickerFilterFavorite,
    tickerFilterModeSum,
    tickerFullPage,
    isEnableETS,
    isEnableDTS,
  };
}
