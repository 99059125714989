import StringMessageBase from "./StringMessageBase";
import WsMsg from "./WsMsg";

class WsResMsg extends WsMsg {
  constructor(channelId) {
    super(channelId);
    this._refId = new StringMessageBase();
  }

  get refId() {
    return this._refId;
  }
}

export default WsResMsg;
