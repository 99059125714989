<template>
  <div class="portfolio-info">
    <div v-show="viewMode === 0">
      <sui-tab :menu="{ attached: 'top' }" style="height: 32px">
        <sui-tab-pane title="Account Info" attached="top">
          <PortfolioInfoAccount :on-show="viewMode === 0" />
        </sui-tab-pane>
        <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
          <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
        </div>
      </sui-tab>
    </div>

    <div v-show="viewMode === 1">
      <sui-tab :menu="{ attached: 'top' }" style="height: 32px">
        <sui-tab-pane title="Account Info" attached="top">
          <PortfolioInfoAccount :on-show="viewMode === 1" />
        </sui-tab-pane>
        <sui-tab-pane title="Credit Balance" attached="top">
          <PortfolioInfoCreditBalance :on-show="viewMode === 1" />
        </sui-tab-pane>
        <sui-tab-pane title="Margin" attached="top">
          <PortfolioInfoEquityTable :on-show="viewMode === 1" />
        </sui-tab-pane>
        <sui-tab-pane title="Asset" attached="top">
          <PortfolioInfoAssetTable :on-show="viewMode === 1" />
        </sui-tab-pane>
        <sui-tab-pane title="Liabilities" attached="top">
          <PortfolioInfoLiabilitiesTable :on-show="viewMode === 1" />
        </sui-tab-pane>
        <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
          <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
        </div>
      </sui-tab>
    </div>

    <div v-show="viewMode === 2">
      <sui-tab :menu="{ attached: 'top' }" style="height: 32px">
        <sui-tab-pane title="Account Info" attached="top">
          <PortfolioInfoAccount :on-show="viewMode === 2" />
        </sui-tab-pane>
        <sui-tab-pane title="Margin" attached="top">
          <PortfolioInfoMargin :on-show="viewMode === 2" />
        </sui-tab-pane>
        <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
          <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
        </div>
      </sui-tab>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import PortfolioInfoAccount from "./PortfolioInfoAccount";
import PortfolioInfoCreditBalance from "./PortfolioInfoCreditBalance";
import PortfolioInfoEquityTable from "./PortfolioInfoEquityTable";
import PortfolioInfoAssetTable from "./PortfolioInfoAssetTable";
import PortfolioInfoLiabilitiesTable from "./PortfolioInfoLiabilitiesTable";
import PortfolioInfoMargin from "./PortfolioInfoMargin";

export default {
  name: "PortfolioInfo",
  components: {
    PortfolioInfoAccount,
    PortfolioInfoCreditBalance,
    PortfolioInfoEquityTable,
    PortfolioInfoAssetTable,
    PortfolioInfoLiabilitiesTable,
    PortfolioInfoMargin,
  },
  data: () => ({
    isLoading: true,
    accountType: "",
    viewMode: 0,
  }),
  watch: {
    currentSelectedAccount(currentSelectedAccount) {
      if (!currentSelectedAccount || currentSelectedAccount.exchangeId == 0) {
        this.viewMode = 0;
      }
    },
  },
  created() {
    this.$EventBus.$on("ctinf/AS01", this.etsAccountInfoCinf);
    this.$EventBus.$on("ctinf/AS51", this.dtsAccountInfoCinf);

    this.$EventBus.$on("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
    this.$EventBus.$on("onPortfolioInfoStopRefresh", this.onPortfolioInfoStopRefresh);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS01", this.etsAccountInfoCinf);
    this.$EventBus.$off("ctinf/AS51", this.dtsAccountInfoCinf);

    this.$EventBus.$off("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
    this.$EventBus.$off("onPortfolioInfoStopRefresh", this.onPortfolioInfoStopRefresh);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onPortfolioInfoStartRefresh");
    },
    onPortfolioInfoStartRefresh() {
      this.isLoading = true;
    },
    onPortfolioInfoStopRefresh() {
      this.isLoading = false;
    },
    etsAccountInfoCinf(info) {
      this.accountType = info.accountType;
      if (info.accountType === "B") {
        this.viewMode = 1;
      } else {
        this.viewMode = 0;
      }
    },
    dtsAccountInfoCinf() {
      this.viewMode = 2;
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
  },
};
</script>

<style>
.apexcharts-canvas.dark {
  background: #262d33 !important;
}

.item.active {
  background-color: #262d33 !important;
}

.ui.attached.menu.top {
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
  min-height: unset !important;
}

a.item {
  height: 32px !important;
  padding: 0px 1rem !important;
  font-weight: 800 !important;
  color: #dcddde !important;
}

a.item > span {
  padding: 0.25rem !important;
}

a.item.active > span {
  color: rgba(200, 136, 65) !important;
  border-radius: 0.5rem !important;
  padding: 0.25rem !important;
  border: 1px solid rgba(200, 136, 65) !important;
}

.ui.tab.segment.attached.active.top {
  background-color: #262d33 !important;
  border: 0px !important;
  padding: 0px !important;
  /* margin-top: -8px !important; */
}
</style>
