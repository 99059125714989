/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class SS02 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._securities = new ArrayMessageBase(Security);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get securities() {
		return this._securities;
	}
}

export class Security {
	constructor() {
		this._resultCode = new NumberMessageBase();
		this._recordId = new StringMessageBase();
		this._securityId = new NumberMessageBase();
		this._symbol = new StringMessageBase();
		this._prior = new StringMessageBase();
		this._ceiling = new StringMessageBase();
		this._floor = new StringMessageBase();
		this._last = new StringMessageBase();
		this._change = new StringMessageBase();
		this._changePercent = new StringMessageBase();
		this._bid1 = new StringMessageBase();
		this._bid2 = new StringMessageBase();
		this._bid3 = new StringMessageBase();
		this._bid4 = new StringMessageBase();
		this._bid5 = new StringMessageBase();
		this._offer1 = new StringMessageBase();
		this._offer2 = new StringMessageBase();
		this._offer3 = new StringMessageBase();
		this._offer4 = new StringMessageBase();
		this._offer5 = new StringMessageBase();
		this._bidVol1 = new StringMessageBase();
		this._bidVol2 = new StringMessageBase();
		this._bidVol3 = new StringMessageBase();
		this._bidVol4 = new StringMessageBase();
		this._bidVol5 = new StringMessageBase();
		this._offerVol1 = new StringMessageBase();
		this._offerVol2 = new StringMessageBase();
		this._offerVol3 = new StringMessageBase();
		this._offerVol4 = new StringMessageBase();
		this._offerVol5 = new StringMessageBase();
		this._changeFlag = new StringMessageBase();
		this._decInPrice = new StringMessageBase();
		this._exchangeId = new StringMessageBase();
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get recordId() {
		return this._recordId;
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get prior() {
		return this._prior;
	}
	
	get ceiling() {
		return this._ceiling;
	}
	
	get floor() {
		return this._floor;
	}
	
	get last() {
		return this._last;
	}
	
	get change() {
		return this._change;
	}
	
	get changePercent() {
		return this._changePercent;
	}
	
	get bid1() {
		return this._bid1;
	}
	
	get bid2() {
		return this._bid2;
	}
	
	get bid3() {
		return this._bid3;
	}
	
	get bid4() {
		return this._bid4;
	}
	
	get bid5() {
		return this._bid5;
	}
	
	get offer1() {
		return this._offer1;
	}
	
	get offer2() {
		return this._offer2;
	}
	
	get offer3() {
		return this._offer3;
	}
	
	get offer4() {
		return this._offer4;
	}
	
	get offer5() {
		return this._offer5;
	}
	
	get bidVol1() {
		return this._bidVol1;
	}
	
	get bidVol2() {
		return this._bidVol2;
	}
	
	get bidVol3() {
		return this._bidVol3;
	}
	
	get bidVol4() {
		return this._bidVol4;
	}
	
	get bidVol5() {
		return this._bidVol5;
	}
	
	get offerVol1() {
		return this._offerVol1;
	}
	
	get offerVol2() {
		return this._offerVol2;
	}
	
	get offerVol3() {
		return this._offerVol3;
	}
	
	get offerVol4() {
		return this._offerVol4;
	}
	
	get offerVol5() {
		return this._offerVol5;
	}
	
	get changeFlag() {
		return this._changeFlag;
	}
	
	get decInPrice() {
		return this._decInPrice;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
}

export default SS02;
