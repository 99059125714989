<template>
  <ag-grid-vue
    style="width: 100%; height: calc(100% - 52px); padding: 0px; padding-top: 1rem;"
    :class="{
      'ag-theme-balham-dark': theme === 'Dark',
      'ag-theme-balham': theme === 'Light'
    }"
    :column-defs="columnDefs"
    :row-data="value"
    :row-height="21"
    :header-height="32"
    :animate-rows="true"
    :suppress-drag-leave-hides-columns="true"
    :default-col-def="{ resizable: true }"
    :locale-text="{ noRowsToShow: `Don't have any Shortcuts.` }"
    @grid-ready="onGridReady"
  />
</template>

<script>
import { sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import columnDefs from "@/components/grid/keyboardSetting/ColumnDefs";
export default {
  components: {
    AgGridVue
  },
  props: {
    value: {
      type: Array
    }
  },
  data() {
    return {
      columnDefs,
      gridApi: null,
      columnApi: null,
      data: []
    };
  },
  watch: {
    value(value) {
      this.data = value;
    }
  },
  created() {
    this.data = this.value;
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    }
  },
  computed: {
    theme: sync("global/theme")
  }
};
</script>

<style>
.ht-shortcut-cell {
  cursor: pointer;
}
</style>
