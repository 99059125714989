<template>
  <div class="have-call-force" style="overflow: hidden">
    <div style="height: 3rem; line-height: 3rem">
      <div class="ui form">
        <div class="fields">
          <div class="field" style="padding-left: 1.5rem">
            <div class="ui form">
              <div class="inline fields">
                <div class="field">
                  <sui-checkbox v-model="selectedMarket" radio value="SET" :disabled="!isEnableETS()">
                    <h4 class="ui grey inverted tiny header">SET</h4>
                  </sui-checkbox>
                </div>
                <div class="field">
                  <sui-checkbox v-model="selectedMarket" radio value="TFEX" :disabled="!isEnableDTS()">
                    <h4 class="ui grey inverted tiny header">TFEX</h4>
                  </sui-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-right: 0px">Customer Type</h5>
          </div>
          <div class="field" style="width: 12rem; padding: 0px 0px 0px 1rem !important">
            <sui-dropdown
              v-if="selectedMarket === 'SET'"
              v-model="selectedSETType"
              fluid
              button
              pointing
              :options="typeSETOptions"
              class="ht-callforce-dropdown"
            />
            <sui-dropdown v-else v-model="selectedTFEXType" fluid button pointing :options="typeTFEXOptions" class="ht-callforce-dropdown" />
          </div>
          <div class="field">
            <h5 class="ui grey header inverted" style="padding: 1rem; padding-right: 0px">Status</h5>
          </div>
          <div class="field" style="width: 15rem; padding: 0px 0px 0px 1rem !important">
            <sui-dropdown
              v-if="selectedMarket === 'SET'"
              v-model="selectedSETStatus"
              fluid
              button
              pointing
              :options="statusSETOptions"
              class="ht-callforce-dropdown"
            />
            <sui-dropdown v-else v-model="selectedTFEXStatus" fluid button pointing :options="statusTFEXOptions" class="ht-callforce-dropdown" />
          </div>
        </div>
      </div>
    </div>
    <div class="ui grid">
      <div class="sixteen wide column" style="height: calc(55vh - 3rem - 32px - 5px)">
        <ag-grid-vue
          style="width: 100%; height: calc(55vh - 3rem - 32px - 5px); padding: 0px"
          :class="{
            'ag-theme-balham-dark': theme === 'Dark',
            'ag-theme-balham': theme === 'Light',
          }"
          row-selection="single"
          :grid-options="gridHaveCallForceOptions"
          :column-defs="selectedMarket === 'SET' ? columnDefsETSHaveCallForce : columnDefsDTSHaveCallForce"
          :row-data="rowHaveCallForce"
          :get-row-node-id="getHaveCallForceRowNodeId"
          :row-height="21"
          :header-height="32"
          :default-col-def="{ sortable: true, resizable: true }"
          :animate-rows="true"
          :suppress-drag-leave-hides-columns="true"
          :enable-cell-change-flash="true"
          :locale-text="{ noRowsToShow: `Don't have any Customer.` }"
          :row-drag-managed="false"
          overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
          @grid-ready="onHaveCallForceGridReady"
          @cell-focused="onCellHaveCallForceFocused"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsETSHaveCallForce, columnDefsDTSHaveCallForce } from "@/components/grid/customers/HaveCallForceColumnDefs.js";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

Object.freeze(columnDefsETSHaveCallForce);
Object.freeze(columnDefsDTSHaveCallForce);

export default {
  name: "HaveCallForce",
  components: {
    AgGridVue,
  },
  data: () => ({
    columnDefsETSHaveCallForce,
    columnDefsDTSHaveCallForce,

    gridHaveCallForceApi: null,
    columnHaveCallForceApi: null,
    rowHaveCallForce: [],
    selectedSymbol: null,
    selectedMarket: "SET",
    selectedSETType: "C",
    typeSETOptions: [
      // {
      //   text: "All Type",
      //   value: "A"
      // },
      {
        text: "Call Margin",
        value: "C",
      },
      {
        text: "Force Sell",
        value: "F",
      },
    ],
    selectedTFEXType: "C",
    typeTFEXOptions: [
      // {
      //   text: "All Type",
      //   value: "A"
      // },
      {
        text: "Call Margin",
        value: "C",
      },
      {
        text: "Force Close",
        value: "F",
      },
    ],
    selectedSETStatus: "C",
    statusSETOptions: [
      {
        text: "Current",
        value: "C",
      },
      {
        text: "Previous",
        value: "P",
      },
    ],
    selectedTFEXStatus: "E",
    statusTFEXOptions: [
      {
        text: "Current (Expected)",
        value: "E",
      },
      {
        text: "Current (Port)",
        value: "C",
      },
      {
        text: "Previous",
        value: "P",
      },
    ],
    isEnableETS,
    isEnableDTS,
  }),
  watch: {
    selectedMarket() {
      this.rowHaveCallForce = [];

      this.selectedSETType = "C";
      this.selectedTFEXType = "C";
      this.selectedSETStatus = "C";
      this.selectedTFEXStatus = "E";

      this.filterChanged();
    },
    selectedSETType() {
      this.filterChanged();
    },
    selectedTFEXType() {
      this.filterChanged();
    },
    selectedSETStatus(selectedSETStatus) {
      if (selectedSETStatus === "C") {
        this.columnHaveCallForceApi.setColumnsVisible(["curForce", "curCall"], true);
        this.columnHaveCallForceApi.setColumnsVisible(["prvForce", "prvCall"], false);
      } else {
        this.columnHaveCallForceApi.setColumnsVisible(["curForce", "curCall"], false);
        this.columnHaveCallForceApi.setColumnsVisible(["prvForce", "prvCall"], true);
      }
      this.filterChanged();
    },
    selectedTFEXStatus() {
      this.filterChanged();
    },
  },
  methods: {
    onCustomerStartRefresh() {
      if (this.nightMode === "Y") {
        this.$Swal.fire("Error", "Have Call/Force does not support in After Close.", "error");
        return;
      }

      if (this.selectedMarket === "SET") {
        this.CR02();
      } else if (this.selectedMarket === "TFEX") {
        this.CR52();
      }
    },
    filterChanged() {
      if (this.selectedTFEXType === "C") {
        this.columnHaveCallForceApi.setColumnsVisible(["MMVal"], true);
        this.columnHaveCallForceApi.setColumnsVisible(["floatPl", "unrealPl", "force"], false);
      } else {
        this.columnHaveCallForceApi.setColumnsVisible(["force"], true);
        this.columnHaveCallForceApi.setColumnsVisible(["MMVal"], false);

        if (this.selectedTFEXStatus === "P") {
          this.columnHaveCallForceApi.setColumnsVisible(["floatPl"], true);
          this.columnHaveCallForceApi.setColumnsVisible(["unrealPl"], false);
        } else {
          this.columnHaveCallForceApi.setColumnsVisible(["unrealPl"], true);
          this.columnHaveCallForceApi.setColumnsVisible(["floatPl"], false);
        }
      }

      this.onCustomerStartRefresh();
    },
    CR02() {
      const msg = this.$messageFactory.createMessage("CR02");

      const trader = this.$_.find(this.traders, ["exchangeId", 1]);
      if (!trader?.traderCode) {
        this.$EventBus.$emit("onCustomerStopRefresh");
        this.$Swal.fire("Error", "User does not have SET account.", "error");
        return;
      }
      msg.ownTid.set(trader.traderCode);
      msg.crdtBalFlag.set(this.selectedSETType);
      msg.period.set(this.selectedSETStatus);
      msg.recordCount.set("0");
      msg.lastRecordId.set("0");

      this.$ws.send(msg);
      if (this.gridHaveCallForceApi) {
        this.gridHaveCallForceApi.showLoadingOverlay();
      }
    },
    CR52() {
      const msg = this.$messageFactory.createMessage("CR52");

      const trader = this.$_.find(this.traders, ["exchangeId", 2]);
      if (!trader?.traderCode) {
        this.$EventBus.$emit("onCustomerStopRefresh");
        this.$Swal.fire("Error", "User does not have TFEX account.", "error");
        return;
      }
      msg.ownTid.set(trader.traderCode);
      msg.forceCallFlag.set(this.selectedTFEXType);
      if (this.selectedTFEXStatus === "E") {
        msg.status.set("");
      } else {
        msg.status.set(this.selectedTFEXStatus);
      }
      msg.recordCount.set("0");
      msg.lastRecordId.set("0");

      this.$ws.send(msg);
      if (this.gridHaveCallForceApi) {
        this.gridHaveCallForceApi.showLoadingOverlay();
      }
    },
    updateRowHaveCallForce(haveCallForce) {
      this.rowHaveCallForce = haveCallForce.records;
      if (this.gridHaveCallForceApi) {
        this.gridHaveCallForceApi.hideOverlay();
      }
      this.$EventBus.$emit("onCustomerStopRefresh");
    },
    onHaveCallForceGridReady(params) {
      this.gridHaveCallForceApi = params.api;
      this.columnHaveCallForceApi = params.columnApi;
    },
    getHaveCallForceRowNodeId(data) {
      return data.crdtBalRec;
    },
    onCellHaveCallForceFocused(event) {
      this.gridHaveCallForceOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
    traders: get("model/systemRefData@traders"),
    nightMode: get("model/systemRefData@nightMode"),
  },
  created() {
    this.gridHaveCallForceOptions = {};

    this.$EventBus.$on("ctinf/CS02", this.updateRowHaveCallForce);
    this.$EventBus.$on("ctinf/CS52", this.updateRowHaveCallForce);

    this.$EventBus.$on("onCustomerStartRefresh", this.onCustomerStartRefresh);
  },
  mounted() {
    if (this.nightMode === "Y") {
      this.$Swal.fire("Error", "Have Call/Force does not support in After Close.", "error");
      return;
    }
    this.onCustomerStartRefresh();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/CS02", this.updateRowHaveCallForce);
    this.$EventBus.$off("ctinf/CS52", this.updateRowHaveCallForce);

    this.$EventBus.$off("onCustomerStartRefresh", this.onCustomerStartRefresh);
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

.ui.button.fluid.dropdown {
  background-color: #2f383f;
}

.ui.grey.header {
  color: #dcddde !important;
}

.ui.secondary.menu {
  min-height: unset;
  height: 32px !important;
  border: 0px !important;
  background-color: unset !important;
}
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.ht-news-datepicker .el-range-separator {
  padding: 0px !important;
  margin-top: -10px !important;
  color: #dcddde !important;
}

.ht-news-datepicker .el-input__icon.el-range__icon.el-icon-date {
  margin-top: -10px !important;
}

.el-picker-panel__sidebar {
  background-color: #2f383f !important;
}

.el-picker-panel__shortcut {
  color: #dcddde !important;
}

.el-picker-panel__shortcut:hover {
  color: #409eff !important;
}

.el-date-table__row > .available.in-range > div {
  background-color: #1b2025 !important;
}

.ht-callforce-dropdown {
  height: 2rem;
  min-height: 2rem;
  top: calc(50% - 1.5rem) !important;
}
.ht-callforce-dropdown .text {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 1rem;
  right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-callforce-dropdown i.dropdown {
  line-height: 0 !important;
  margin-top: 3px !important;
}
</style>
