<template>
  <div id="app">
    <router-view />
    <portal-target name="semantic-ui-vue" />
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import { DateTime } from "luxon";

export default {
  data: () => ({
    resizeTimer: null,
  }),
  computed: {
    settings: get("global/settings"),
    userName: get("model/systemRefData@username"),
  },
  created() {
    this.checkOrderNotify();
    window.addEventListener("resize", this.onResize);
    ["", "webkit", "moz", "ms"].forEach((prefix) => window.addEventListener(`${prefix}fullscreenchange`, this.onResize));

    const root = document.documentElement;
    root.style.fontSize = `calc(12px + ${4 * Number(this.settings?.fontSize || "1")} * ((100vw - 1024px) / (1920 - 1024)))`;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    ["", "webkit", "moz", "ms"].forEach((prefix) => window.removeEventListener(`${prefix}fullscreenchange`, this.onResize));
  },
  watch: {
    settings(newSettings) {
      const root = document.documentElement;
      root.style.fontSize = `calc(12px + ${4 * Number(newSettings.fontSize || "1")} * ((100vw - 1024px) / (1920 - 1024)))`;
    },

    userName() {
      this.checkOrderNotify();
    },
  },
  methods: {
    onResize() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.$EventBus.$emit("onResize");
      }, 100);
    },
    checkOrderNotify() {
      const orderNotify = localStorage.getItem("order-notify");
      const token = localStorage.getItem("token");

      if (!token && !this.userName) return localStorage.removeItem("order-notify");

      if (!orderNotify) return;

      const parsedOrderNotify = JSON.parse(orderNotify);

      const today = DateTime.now().setZone("Asia/Bangkok");
      const createdDate = DateTime.fromFormat(parsedOrderNotify.createdAt, "dd-LL-yyyy").setZone("Asia/Bangkok");

      if (!today.hasSame(createdDate, "day") || (token && this.userName && this.userName !== parsedOrderNotify.user)) {
        return localStorage.removeItem("order-notify");
      }
    },
  },
};
</script>

<style scoped>
@import "~semantic-ui-offline/semantic.min.css";
@import "~element-ui/lib/theme-chalk/index.css";
</style>

<style>
/* lato-regular - latin */
@font-face {
  font-family: "Lato";
  src: url("~@/assets/fonts/lato-v16-latin-regular.woff2") format("woff2"), url("~@/assets/fonts/lato-v16-latin-regular.woff") format("woff");
}

#app {
  height: 100vh;
}

html,
body {
  overflow: hidden !important;
  padding: 0 !important;
  margin: 0 !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: calc(12px + (4 * ((100vw - 1024px) / (1920 - 1024))));
}

.ui.grid,
.row,
.column {
  margin: 0px !important;
  padding: 0px !important;
}

body ::-webkit-scrollbar-corner {
  background: #22282e;
}

body ::-webkit-scrollbar {
  width: 0.9rem;
  height: 0.9rem;
}

body ::-webkit-scrollbar-thumb {
  background: #555a5e;
  border-radius: 50px;
}

body ::-webkit-scrollbar-thumb:window-inactive {
  /* Select the thumb when the browser window isn't in focus */
  background: #555a5e;
  border-radius: 50px;
}

body {
  scrollbar-color: #555a5e #22282e;
}

.ui.green.header,
.ui.tiny.header.green {
  color: #00aa00 !important;
}

.ui.yellow.header,
.ui.tiny.header.yellow {
  color: #e4af0a !important;
}

.ui.red.header,
.ui.tiny.header.red {
  color: #d91e18 !important;
}

.ui.blue.header,
.ui.tiny.header.blue {
  color: #2185d0 !important;
}

/* .ag-theme-balham .ag-row-odd { background-color: #fafafb !important; } .ag-theme-balham .ag-row-even { background-color: #ffffff !important;}.ag-theme-balham-dark .ag-row-odd { background-color: #262c2e !important;}.ag-theme-balham-dark .ag-row-even {background-color: #2d3436 !important;} */

.ui.inverted.table {
  background: #262d33;
}

.ui.inverted.striped.table tbody tr:nth-child(2n),
.ui.inverted.striped.table > tr:nth-child(2n) {
  background-color: #2f383f;
}

.ui.table thead {
  background: #20262b;
}

.ui.modal > .header {
  border-color: #1b2025 !important;
  background: #1b2025;
  color: #dcddde !important;
}
.ui.modal > .header .close {
  float: right;
}
.ui.modal > .header .close i {
  cursor: pointer;
}
.ui.modal > .header .close i:hover {
  color: #dcddde !important;
}

.ui.modal > .content {
  overflow: unset;
  background: #262d33;
  color: #dcddde !important;
}
.ui.modal > .actions {
  border: 0 !important;
  border-color: #1b2025 !important;
  background: #1b2025 !important;
}
.ui.active.modal {
  background: #1b2025 !important;
}

.ui.input.error > input {
  color: #d91e18 !important;
  border-color: #d91e18 !important;
  background: #2f383f !important;
}
::-webkit-input-placeholder {
  text-transform: none;
  color: grey !important;
}
:-ms-input-placeholder {
  text-transform: none;
  color: grey !important;
}
::placeholder {
  text-transform: none;
  color: grey !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.multiselect__input::placeholder {
  color: grey !important;
}

.multiselect__placeholder {
  color: grey !important;
}

.multiselect--disabled {
  background: unset !important;
}

.multiselect__element > .multiselect__option--highlight {
  background-color: #1b2025 !important;
}

.multiselect.error {
  border: 1px solid red;
  border-radius: 5px;
}

.ag-theme-balham-dark .ag-overlay-loading-wrapper {
  background-color: hsla(0, 0%, 0%, 0);
}

.ag-root-wrapper-body.ag-layout-normal > div > div.ag-overlay > div > div > div > div:nth-child(2) {
  color: #dcddde !important;
}

.el-date-editor,
.el-date-editor input {
  height: 30px;
  min-height: 30px;
  line-height: 30px;
  background-color: #2f383f !important;
  border-color: #2f383f !important;
  border-radius: 5px !important;
  color: #dcddde !important;
}

.el-date-editor.is-disabled,
.el-date-editor.is-disabled input {
  border-color: #666666 !important;
  background-color: #666666 !important;
}

.el-date-editor.is-disabled:hover,
.el-date-editor.is-disabled input:hover {
  border-color: #666666 !important;
}

.el-date-editor:hover,
.el-date-editor input:hover {
  border-color: #2f383f !important;
}

.el-date-editor i {
  line-height: 30px;
}

.el-picker-panel {
  background-color: #2f383f !important;
  border-color: #2f383f !important;
}
.el-picker-panel span.el-date-picker__header-label,
.el-picker-panel th,
.el-picker-panel button.el-picker-panel__icon-btn,
.el-picker-panel td.available,
.el-picker-panel td.available a.cell,
.el-picker-panel .el-month-table td a.cell {
  color: #dcddde !important;
}

.el-picker-panel td.prev-month,
.el-picker-panel td.next-month,
.el-picker-panel td.disabled div,
.el-picker-panel .el-month-table td.disabled a {
  color: #666666 !important;
}

.el-picker-panel td.disabled div,
.el-picker-panel .el-month-table td.disabled a {
  background-color: unset !important;
}

.popper__arrow {
  border-top-color: #2f383f !important;
  border-bottom-color: #2f383f !important;
}
.el-popper[x-placement^="top"] .popper__arrow::after {
  border-top-color: #2f383f !important;
}
.el-popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: #2f383f !important;
}

.el-date-editor.error,
.el-date-editor.error input {
  border-color: red !important;
}

.el-time-panel {
  border-color: #2f383f !important;
  background-color: #2f383f !important;
}
.el-time-spinner {
  background-color: #2f383f !important;
}
.el-time-spinner__item {
  color: #666666 !important;
}
.el-time-spinner__item:hover {
  background-color: #1b2025 !important;
}
.el-time-spinner__item.active {
  color: #dcddde !important;
  background-color: unset !important;
}
.el-time-panel__btn.cancel {
  font: inherit !important;
  color: #666666 !important;
}
.el-time-panel__btn.confirm {
  font: inherit !important;
  font-weight: bold !important;
}
.el-time-panel__footer {
  border-top: 1px solid #26292c !important;
}

.swal2-modal {
  background: #1b2025 !important;
}
.swal2-modal .swal2-title,
.swal2-modal .swal2-content {
  color: #dcddde !important;
}

.ui.selection.dropdown div.default.text {
  color: grey !important;
}
.ui.selection.dropdown.error {
  border-color: red !important;
}

.ui.selection.dropdown .item.selected {
  background-color: #1b2025 !important;
}

.ui.selection.dropdown .item.active {
  color: #fff !important;
}

.ui.vertical.tabular.menu .item {
  height: 40px !important;
  line-height: 40px !important;
}
.ui.tab.segment.attached {
  overflow: auto;
  background: #262d33 !important;
  border: none;
}
.ui.form .field > label {
  color: #dcddde;
}
.ui.form .field .ui.input input,
.ui.form .fields .field .ui.input input {
  background-color: #2f383f;
  color: #dcddde;
}

.ui.buttons > .ui.dropdown:last-child .menu,
.ui.menu .right.dropdown.item .menu,
.ui.menu .right.menu .dropdown:last-child .menu,
.ui.menu .dropdown.item .menu {
  background: rgb(47, 56, 63);
}
.ui.menu .ui.dropdown .menu > .item {
  color: #dcddde !important;
}
.ui.menu .ui.dropdown .menu > .item:hover {
  color: #dcddde !important;
  background: #262d33 !important;
  border: unset !important;
  border-radius: unset !important;
}

.ui.dropdown .menu > .divider {
  background-color: #252c31 !important;
}

.ui.inverted.menu .dropdown.item:hover,
.ui.inverted.menu .link.item:hover,
.ui.inverted.menu a.item:hover,
.ui.link.inverted.menu .item:hover {
  background: rgb(47, 56, 63) !important;
}

input::selection {
  background: #0968d9;
  color: #dcddde;
}

/* .ag-header {
  height: 4rem !important;
  min-height: 4rem !important;
}
.ag-header-row:nth-child(2) {
  top: 2rem !important;
}
.ag-header-row,
.ag-header-cell {
  height: 2rem !important;
} */
.ag-header-cell .ui.input input {
  height: 100% !important;
  width: 100% !important;
}

/* .field h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */

input:focus-within {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0) !important;
}

.ag-selection-checkbox {
  margin-top: 0.65rem;
}

.ag-selection-checkbox .ag-icon {
  font-size: 16px;
  margin-top: -3px;
}

.ui.top.attached.menu > .item:first-child {
  border-radius: 0 !important;
}

.ht-grid-cell-centered {
  text-align: center !important;
}

.apexcharts-canvas.apexcharts-theme-dark {
  background: #262d33 !important;
}

.apexcharts-svg {
  background-color: #262d33 !important;
}

.ag-body-viewport {
  background-color: #262d33 !important;
}

.ag-theme-balham-dark .ag-root-wrapper {
  border: solid 0px;
}

.chart-page {
  background: #262d33 !important;
}
</style>
