<template>
  <div class="pie-chart-information" ref="pie-chart-information">
    <sui-tab class="pie-chart-information-tab" :menu="{ attached: 'top' }">
      <sui-tab-pane class="account-type-tab" title="Account Type" attached="top">
        <div class="empty-value" v-if="$_.sum(seriesAccountType) === 0">
          {{ "Don't have any Value." }}
        </div>
        <apexchart
          type="pie"
          :width="dynamicWidth"
          :height="dynamicHeight"
          :options="chartOptionsAccountType"
          :series="seriesAccountType"
          :key="updateKey"
          v-else
        />
      </sui-tab-pane>
      <sui-tab-pane class="customer-type-tab" title="Customer Type" attached="top">
        <div v-if="$_.sum(seriesCustomerType) === 0" class="empty-value">
          {{ "Don't have any Value." }}
        </div>
        <apexchart
          type="pie"
          :width="dynamicWidth"
          :height="dynamicHeight"
          :options="chartOptionsCustomerType"
          :series="seriesCustomerType"
          :key="updateKey"
          v-else
        />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

const chartOptionsAccountType = {
  labels: ["N/A"],
  theme: {
    mode: "dark",
    palette: "palette2",
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: "gradient",
  },
  stroke: {
    width: 0,
    color: "#1B2025",
  },
  legend: {
    position: "right",
    offsetX: 0,
    offsetY: 0,
    formatter: function (val, opts) {
      if (opts.w.globals.seriesPercent[opts.seriesIndex]?.[0]) {
        return " " + val + " : " + opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(2) + "%";
      } else {
        return " " + val + " : " + "0%";
      }
    },
  },
  tooltip: {
    y: {
      formatter: (value) => {
        if (value) {
          return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return "0";
        }
      },
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

const chartOptionsCustomerType = {
  labels: ["N/A"],
  theme: {
    mode: "dark",
    palette: "palette2",
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    type: "gradient",
  },
  stroke: {
    width: 0,
    color: "#1B2025",
  },
  legend: {
    position: "right",
    offsetX: 0,
    offsetY: 0,
    formatter: function (val, opts) {
      if (opts.w.globals.seriesPercent[opts.seriesIndex]?.[0]) {
        return " " + val + " : " + opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(2) + "%";
      } else {
        return " " + val + " : " + "0%";
      }
    },
  },
  tooltip: {
    y: {
      formatter: (value) => {
        if (value) {
          return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          return "0";
        }
      },
    },
  },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

export default {
  name: "InformationPieChart",
  components: {},
  props: ["mode", "data"],
  data: () => ({
    isLoading: false,
    seriesAccountType: [100],
    chartOptionsAccountType: chartOptionsAccountType,
    seriesCustomerType: [100],
    chartOptionsCustomerType: chartOptionsCustomerType,
    dynamicHeight: 100,
    dynamicWidth: 100,
    updateKey: 0,
  }),
  watch: {
    data(data) {
      if (data.accountTypes?.length) {
        this.seriesAccountType = data.accountTypes.map((accountType) => this.$numeral(accountType.totalVal.toFixed(0)).value());
        this.chartOptionsAccountType = { ...chartOptionsAccountType, labels: data.accountTypes.map((accountType) => accountType.type) };
      } else {
        this.seriesAccountType = [100];
        this.chartOptionsAccountType = chartOptionsAccountType;
      }

      if (data.pcs?.length) {
        this.seriesCustomerType = data.pcs.map((customerType) => this.$numeral(customerType.totalVal.toFixed(0)).value());
        this.chartOptionsCustomerType = { ...chartOptionsCustomerType, labels: data.pcs.map((customerType) => customerType.type) };
      } else {
        this.seriesCustomerType = [100];
        this.chartOptionsCustomerType = chartOptionsCustomerType;
      }
    },
  },
  created() {
    this.resizeTimeout = null;
    this.$EventBus.$on("onInformationStartRefresh", this.onInformationStartRefresh);
    this.$EventBus.$on("onInformationStopRefresh", this.onInformationStopRefresh);
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    this.$EventBus.$off("onInformationStartRefresh", this.onInformationStartRefresh);
    this.$EventBus.$off("onInformationStopRefresh", this.onInformationStopRefresh);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onInformationStartRefresh");
    },
    onInformationStartRefresh() {
      this.isLoading = true;
    },
    onInformationStopRefresh() {
      this.isLoading = false;
    },
    onResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      this.dynamicHeight = 0;
      this.dynamicWidth = 0;

      this.resizeTimeout = setTimeout(() => {
        const chartContainer = this.$refs["pie-chart-information"];

        this.dynamicHeight = chartContainer.clientHeight - 32;
        this.dynamicWidth = chartContainer.clientWidth - 32;
        this.updateKey++;
      }, 200);
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
};
</script>

<style lang="scss" scoped>
.pie-chart-information {
  height: 100%;
  background: #262d33;
  overflow: hidden;

  .pie-chart-information-tab {
    display: flex;
    height: 100%;
    flex-direction: column;

    .account-type-tab {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .customer-type-tab {
      @extend .account-type-tab;
    }
  }

  .empty-value {
    font-size: 12px;
    color: #dcddde;
    z-index: 1;
  }
}

.apexcharts-canvas.dark {
  background: #262d33 !important;
}

.item.active {
  background-color: #262d33 !important;
}

.ui.attached.menu.top {
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
  min-height: unset !important;
}

a.item {
  height: 32px !important;
  padding: 0px 1rem !important;
  font-weight: 800 !important;
  color: #dcddde !important;
}

a.item > span {
  padding: 0.25rem !important;
}

a.item.active > span {
  color: #c88841 !important;
  border-radius: 0.5rem !important;
  padding: 0.25rem !important;
  border: 1px solid #c88841 !important;
}

.ui.tab.segment.attached.active.top {
  background-color: #262d33 !important;
  border: 0px !important;
  padding: 0px !important;
  /* margin-top: -8px !important; */
}
</style>
