import { make } from "vuex-pathify";

const model = () => {
  const state = {
    systemRefData: {},
    shortcuts: null,
    currentShortcut: null,
    isEditingShortcut: false
  };
  const mutations = make.mutations(state);
  const actions = make.actions(state);
  const getters = make.getters(state);
  return { namespaced: true, state, mutations, actions, getters };
};

export default model;
