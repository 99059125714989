var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"broker-information",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"options-container"},[_c('sui-checkbox',{staticClass:"custom-checkbox",attrs:{"radio":"","value":"SET","disabled":!_vm.isEnableETS()},model:{value:(_vm.selectedMarket),callback:function ($$v) {_vm.selectedMarket=$$v},expression:"selectedMarket"}},[_c('h5',{staticClass:"ui grey inverted tiny header"},[_vm._v(_vm._s("SET"))])]),_c('sui-checkbox',{staticClass:"custom-checkbox",attrs:{"radio":"","value":"TFEX","disabled":!_vm.isEnableDTS()},model:{value:(_vm.selectedMarket),callback:function ($$v) {_vm.selectedMarket=$$v},expression:"selectedMarket"}},[_c('h5',{staticClass:"ui grey inverted tiny header"},[_vm._v(_vm._s("TFEX"))])]),_c('div',{staticClass:"total-market-volume"},[_c('h5',{staticClass:"ui grey inverted tiny header"},[_vm._v(" "+_vm._s("Total Market Volume")+" ")]),_c('span',{staticClass:"ht-text-value"},[_vm._v(_vm._s(_vm.totalMktVol))])]),_c('div',{staticClass:"total-market-value"},[_c('h5',{staticClass:"ui grey inverted tiny header"},[_vm._v(" "+_vm._s("Total Market Value")+" ")]),_c('span',{staticClass:"ht-text-value"},[_vm._v(_vm._s(_vm.totalMktVal))])])],1),_c('div',{staticClass:"ui grid content-grid"},[_c('div',{staticClass:"two column row content-container"},[_c('div',{staticClass:"sixteen wide computer twelve wide large screen column twelve wide widescreen monitor column left-side-container"},[_c('div',{staticClass:"broker-information-by-type"},[_c('ag-grid-vue',{staticClass:"broker-information-by-type-table",class:{
              'ag-theme-balham-dark': _vm.theme === 'Dark',
              'ag-theme-balham': _vm.theme === 'Light',
            },attrs:{"row-selection":"single","grid-options":_vm.gridBrokerInformationBySideOptions,"column-defs":_vm.selectedMarket === 'SET' ? _vm.columnDefsETSBrokerInformationBySide : _vm.columnDefsDTSBrokerInformationBySide,"row-data":_vm.rowBrokerInformationBySide,"row-height":21,"header-height":32,"default-col-def":{ sortable: false, resizable: true },"animate-rows":true,"suppress-drag-leave-hides-columns":true,"enable-cell-change-flash":true,"locale-text":{ noRowsToShow: `Don't have any Broker Information by Side.` },"row-drag-managed":false,"pinned-bottom-row-data":_vm.pinnedBottomRowBrokerInformationBySide,"overlay-loading-template":"<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"},on:{"grid-ready":_vm.onBrokerInformationBySideGridReady,"cell-focused":_vm.onCellBrokerInformationBySideFocused}})],1),_c('div',{staticClass:"broker-information-by-account"},[_c('ag-grid-vue',{staticClass:"broker-information-by-account-table",class:{
              'ag-theme-balham-dark': _vm.theme === 'Dark',
              'ag-theme-balham': _vm.theme === 'Light',
            },attrs:{"row-selection":"single","grid-options":_vm.gridBrokerInformationByAccountTypeAndCustomerTypeOptions,"column-defs":_vm.selectedMarket === 'SET'
                ? _vm.columnDefsETSBrokerInformationByAccountTypeAndCustomerType
                : _vm.columnDefsDTSBrokerInformationByAccountTypeAndCustomerType,"row-data":_vm.rowBrokerInformationByAccountTypeAndCustomerType,"row-height":21,"header-height":32,"default-col-def":{ sortable: false, resizable: true },"animate-rows":true,"suppress-drag-leave-hides-columns":true,"enable-cell-change-flash":true,"locale-text":{ noRowsToShow: `Don't have any Broker Information by Account & Customer Type.` },"row-drag-managed":false,"overlay-loading-template":"<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>","is-full-width-cell":_vm.isBrokerInformationByAccountTypeAndCustomerTypeFullWidthCell,"full-width-cell-renderer":_vm.fullWidthBrokerInformationByAccountTypeAndCustomerTypeCellRenderer,"get-row-height":_vm.getBrokerInformationByAccountTypeAndCustomerTypeRowHeight},on:{"grid-ready":_vm.onBrokerInformationByAccountTypeAndCustomerTypeGridReady,"cell-focused":_vm.onCellBrokerInformationByAccountTypeAndCustomerTypeFocused}})],1)]),_c('div',{staticClass:"four wide column large screen only right-side-container"},[_c('div',{staticClass:"row pie-chart-information"},[_c('PieChartInformation',{attrs:{"data":_vm.chartData}})],1),_c('div',{staticClass:"row progress-bar-information"},[_c('ProgressBarInformation',{attrs:{"mode":"Broker","data":_vm.chartData}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }