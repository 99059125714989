<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    value: {
      type: Number,
    },
    placeholder: {
      type: String,
      default: "Stop Type",
    },
    filterable: {
      type: String,
      default: "startsWith",
    },
  },
  data() {
    return {
      style: "width: 100%; min-width: 20px;",
      defaultValue: 1,
      displayValue: 1,
      options: [
        {
          text: "Price",
          value: 1,
        },
        {
          text: "Percentage",
          value: 2,
        },
        {
          text: "Prior Price",
          value: 3,
        },
        {
          text: "Prior Percentage",
          value: 4,
        },
      ],
    };
  },
};
</script>
