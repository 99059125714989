<template>
  <div class="portfolio-customer-summary-table">
    <div class="selection-section">
      <h5 class="ui grey header inverted">{{ "Selection" }}</h5>
      <CustomerSummaryDropdown
        class="customer-summary-dropdown"
        :dropdown-options="etsSummaryOptions"
        v-model="selectedSummary"
        v-if="!currentSelectedAccount || currentSelectedAccount.exchangeId !== 2"
      />
      <CustomerSummaryDropdown
        class="customer-summary-dropdown"
        :dropdown-options="dtsSummaryOptions"
        v-model="selectedSummary"
        v-if="currentSelectedAccount && currentSelectedAccount.exchangeId == 2"
      />
    </div>
    <div class="sixteen wide column summary-content-container">
      <h5 class="ui grey header inverted" style="font-family: 'Courier New', Courier, monospace" v-html="custSumContent" />
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import CustomerSummaryDropdown from "./CustomerSummaryDropdown.vue";

export default {
  name: "PortfolioCustomerSummaryTable",
  props: ["onShow"],
  components: {
    CustomerSummaryDropdown,
  },
  data: () => ({
    etsSummaryOptions: [
      {
        text: "5 - Customer Summary",
        value: "5",
      },
      {
        text: "6 - Customer Confirm Summary",
        value: "6",
      },
      {
        text: "7 - Customer Confirm",
        value: "7",
      },
      {
        text: "8 - Confirm by Deal-Time",
        value: "8",
      },
      {
        text: "9 - Confirm by Stock",
        value: "9",
      },
      {
        text: "A - Print Report Option 3 & 9",
        value: "A",
      },
      {
        text: "F - Confirm Sequence by Stock",
        value: "F",
      },
    ],
    dtsSummaryOptions: [
      {
        text: "5 - Customer Summary",
        value: "5",
      },
      {
        text: "6 - Customer Confirm Summary",
        value: "6",
      },
      {
        text: "7 - Customer Confirm",
        value: "7",
      },
      {
        text: "8 - Confirm by Deal-ID",
        value: "8",
      },
      {
        text: "9 - Confirm by Stock",
        value: "9",
      },
      {
        text: "A - Print Report Option 2 & 9",
        value: "A",
      },
    ],
    selectedSummary: "5",
    custSumContent: "",
    refId: null,
  }),
  watch: {
    selectedSummary() {
      if (!this.onShow) return;

      this.AR07();
    },
    currentSelectedAccount(currentSelectedAccount) {
      if (!this.onShow) return;

      this.AR07();
    },
  },
  created() {
    this.AR07();

    this.$EventBus.$on("ctinf/AS07", this.etsAccountSummaryCinf);
    this.$EventBus.$on("ctinf/AS57", this.dtsAccountSummaryCinf);

    this.$EventBus.$on("onPortfolioTableStartRefresh", this.onPortfolioTableStartRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS07", this.etsAccountSummaryCinf);
    this.$EventBus.$off("ctinf/AS57", this.dtsAccountSummaryCinf);

    this.$EventBus.$off("onPortfolioTableStartRefresh", this.onPortfolioTableStartRefresh);
  },
  methods: {
    onPortfolioTableStartRefresh() {
      if (!this.onShow) return;

      this.AR07();
    },
    AR07() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount?.exchangeId == 0) {
        this.custSumContent = "";
        this.$EventBus.$emit("onPortfolioTableStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("AR07");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);
      msg.option.set(this.selectedSummary);
      msg.exchangeId.set(this.currentSelectedAccount.exchangeId);
      this.refId = this.$shortid.generate();

      this.$ws.send(msg, this.refId);
    },
    updateAccountSummary(content) {
      this.custSumContent = content.custSumContent.split(" ").join("&nbsp;").split("\r\n").join("<br />");
    },
    etsAccountSummaryCinf(content) {
      if (content.refId !== this.refId) return;
      if (!this.onShow) return;

      this.updateAccountSummary(content);
      this.$EventBus.$emit("onPortfolioTableStopRefresh");
    },
    dtsAccountSummaryCinf(content) {
      if (!this.onShow) return;

      this.updateAccountSummary(content);
      this.$EventBus.$emit("onPortfolioTableStopRefresh");
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/portfolio/portfolio-customer-summary-table.scss";
</style>
