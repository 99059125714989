<template>
  <div class="market-stock-graph-intraday" ref="market-stock-graph-intraday">
    <apexchart
      type="area"
      :height="height - 5 || 102"
      :options="intradayChartOptions"
      :series="intradaySeries"
      style="display: inline-block; width: 100%"
      :style="height ? 'position: relative; bottom: -5px;' : ''"
    />
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import _api from "@/api/authentication";

const intradaySeries = [
  {
    name: "Price",
    data: [],
  },
];

const intradayChartOptions = {
  theme: {
    mode: "dark",
  },
  stroke: {
    width: 2,
    curve: "straight",
  },
  annotations: {
    yaxis: [
      {
        y: 0,
        borderColor: "#e4af0a",
        label: {
          show: true,
          offsetX: -10,
          offsetY: 5,
          text: "0.00",
          style: {
            color: "#e4af0a",
            background: "#262D33",
          },
        },
      },
    ],
  },
  xaxis: {
    type: "datetime",
  },
  chart: {
    sparkline: {
      enabled: true,
    },
    offsetX: 1,
  },
  colors: ["#00aa00"],
};

export default {
  name: "MarketStockGraphIntraday",
  components: {},
  data: () => ({
    intradaySeries: intradaySeries,
    intradayChartOptions: intradayChartOptions,
    SR04RefId: "",
    height: 170,
  }),
  watch: {
    currentQuoteSelectedSymbol(currentQuoteSelectedSymbol) {
      this.SR04(this.currentQuoteSelectedSymbol);

      if (!currentQuoteSelectedSymbol) {
        this.init();
      }
    },
  },
  created() {
    this.onMarketGraphIntradayStartRefresh();

    this.$EventBus.$on("ctinf/SS04", this.plotGraph);
    this.$EventBus.$on("onMarketGraphIntradayStartRefresh", this.onMarketGraphIntradayStartRefresh);
    this.$EventBus.$on("market-bbo-size-change", this.onSizeChange);
  },
  mounted() {
    this.onSizeChange();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS04", this.plotGraph);
    this.$EventBus.$off("onMarketGraphIntradayStartRefresh", this.onMarketGraphIntradayStartRefresh);
    this.$EventBus.$off("market-bbo-size-change", this.onSizeChange);
  },
  methods: {
    init() {
      this.intradaySeries = intradaySeries;
      this.intradayChartOptions = intradayChartOptions;
      this.$EventBus.$emit("onMarketGraphIntradayStopRefresh");
    },
    plotGraph(securityChartUpdateCtinf) {
      if (securityChartUpdateCtinf.refId !== this.SR04RefId) {
        return;
      }

      if (securityChartUpdateCtinf.closePrices.length === 0) {
        this.init();
        return;
      }

      let intradaySeriesData = [];

      let marketTime = ["95000"];
      while (marketTime[marketTime.length - 1] !== "170000") {
        const newTime = (Number(marketTime[marketTime.length - 1]) + 200).toString();

        if (Number(newTime) <= 123000 || Number(newTime) >= 143000) {
          marketTime.push(newTime);
        } else {
          marketTime.push("143000");
        }
      }

      const prior = this.$numeral(securityChartUpdateCtinf.prior).value();

      _api
        .checkAuth()
        .then((response) => {
          if (response.status === 200) {
            const serverTime = response.data;

            if (securityChartUpdateCtinf.closePrices.length) {
              marketTime.forEach((time) => {
                const dateTime = new Date(this.$moment(time, "Hmmss"));
                const dateServerTime = new Date(Number(serverTime));
                if (dateTime > dateServerTime) {
                  intradaySeriesData.push({ x: dateTime, y: undefined });
                } else if (dateTime instanceof Date && !isNaN(dateTime)) {
                  let output = this.$_.filter(securityChartUpdateCtinf.closePrices, (o) => {
                    return Number(o.time) <= Number(time);
                  });
                  if (output.length) {
                    intradaySeriesData.push({ x: dateTime, y: this.$numeral(output[output.length - 1].price).value() - prior });
                  } else {
                    intradaySeriesData.push({ x: dateTime, y: this.$numeral(securityChartUpdateCtinf.prior).value() - prior });
                  }
                }
              });

              intradaySeriesData.forEach((data, index) => {
                let dateServerTime = new Date(Number(serverTime));
                dateServerTime.setHours(12, 30, 0);
                if (data.x > dateServerTime) {
                  intradaySeriesData[index].x = new Date(this.$moment(data.x).subtract({ hours: 1, minutes: 30, seconds: 0 }));
                }
              });

              const highDistant = this.$numeral(securityChartUpdateCtinf.high).value() - prior;
              const lowDistant = Math.abs(this.$numeral(securityChartUpdateCtinf.low).value() - prior);
              const maxDistant = highDistant > lowDistant ? highDistant * 1.2 : lowDistant * 1.2;

              this.intradaySeries = [
                {
                  name: "Price",
                  data: intradaySeriesData,
                },
                {
                  data: [
                    {
                      x: intradaySeriesData[0].x,
                      y: maxDistant,
                    },
                  ],
                },
                {
                  data: [
                    {
                      x: intradaySeriesData[0].x,
                      y: -maxDistant,
                    },
                  ],
                },
              ];

              let chartColor = "";

              let lastPrior = this.$numeral(securityChartUpdateCtinf.closePrices[securityChartUpdateCtinf.closePrices.length - 1].price).value();

              if (lastPrior === 0) {
                lastPrior = prior;
              }

              if (lastPrior > prior) {
                chartColor = ["#00aa00"];
              } else if (lastPrior < prior) {
                chartColor = ["#d91e18"];
              } else {
                chartColor = ["#e4af0a"];
              }

              this.intradayChartOptions = {
                ...intradayChartOptions,
                ...{
                  colors: chartColor,
                  fill: {
                    type: "gradient",
                    gradient: {
                      shade: "dark",
                      type: "vertical",
                      shadeIntensity: 0.5,
                      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                      inverseColors: false,
                      opacityFrom: 0.8,
                      opacityTo: 0.5,
                      stops: [0, 80, 100],
                      colorStops: [],
                    },
                  },
                  annotations: {
                    yaxis: [
                      {
                        y: 0,
                        borderColor: "#e4af0a",
                        label: {
                          show: true,
                          offsetX: -10,
                          offsetY: 5,
                          text: this.$numeral(prior).format("0,0.00"),
                          style: {
                            color: "#e4af0a",
                            background: "#262D33",
                          },
                        },
                      },
                    ],
                  },
                  tooltip: {
                    theme: "dark",
                    enabledOnSeries: [0],
                    x: {
                      // format: "HH:mm:ss"
                      formatter: (value) => {
                        const dateServerTime = new Date(Number(serverTime));
                        let startBreak = dateServerTime.setHours(12, 29, 0);
                        let endBreak = dateServerTime.setHours(13, 0, 0);

                        if (value >= startBreak) {
                          if (value >= endBreak) {
                            return this.$moment(value).add({ hours: 1, minutes: 30, seconds: 0 }).format("HH:mm:ss");
                          } else {
                            return "Break";
                          }
                        } else {
                          return this.$moment(value).format("HH:mm:ss");
                        }
                      },
                    },
                    y: {
                      formatter: (value) => {
                        if (value != null) {
                          return this.$numeral(value + prior).format("0,0.00");
                        } else {
                          return "N/A";
                        }
                      },
                    },
                  },
                },
              };
            }
            this.$EventBus.$emit("onMarketGraphIntradayStopRefresh");
          } else {
            this.$EventBus.$emit("onMarketGraphIntradayStopRefresh");
          }
        })
        .catch((error) => {
          console.error(error);
          this.$EventBus.$emit("onMarketGraphIntradayStopRefresh");
        });
    },
    SR04(selectedSymbol) {
      if (!selectedSymbol) {
        this.$EventBus.$emit("onMarketGraphIntradayStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("SR04");

      msg.securityId.set(selectedSymbol.value.securityId);
      msg.exchangeId.set(selectedSymbol.value.exchangeId);

      this.SR04RefId = this.$shortid.generate();
      this.$ws.send(msg, this.SR04RefId);
    },
    onMarketGraphIntradayStartRefresh() {
      this.SR04(this.currentQuoteSelectedSymbol);
    },
    onSizeChange() {
      this.height = 170;

      this.$nextTick(() => {
        const marketStockGraph = this.$refs["market-stock-graph-intraday"];
        this.height = marketStockGraph.clientHeight - 32;
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
  },
};
</script>

<style>
.market-stock-graph-intraday {
  height: 100%;
}

.apexcharts-canvas.dark {
  background: #262d33 !important;
}

.item.active {
  background-color: #262d33 !important;
}

.ui.attached.menu.top {
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
  min-height: unset !important;
}

a.item {
  height: 32px !important;
  padding: 0px 1rem !important;
  font-weight: 800 !important;
  color: #dcddde !important;
}

a.item > span {
  padding: 0.25rem !important;
}

a.item.active > span {
  color: rgba(200, 136, 65) !important;
  border-radius: 0.5rem !important;
  padding: 0.25rem !important;
  border: 1px solid rgba(200, 136, 65) !important;
}

.ui.tab.segment.attached.active.top {
  background-color: #262d33 !important;
  border: 0px !important;
  padding: 0px !important;
  height: 100%;
}
</style>
