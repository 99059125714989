<template>
  <div class="market-bbo" :style="{ height: '100%' }">
    <div class="bbo-table-container">
      <table
        style="border: 0px"
        :class="{ inverted: theme === 'Dark' }"
        class="ui very compact small single line right aligned unstackable table bbo-table"
      >
        <thead>
          <tr style="opacity: 1">
            <th class="four wide bbo-table-header" id="bvol">
              <h5 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Volume</h5>
            </th>
            <th class="four wide bbo-table-header" id="bids">
              <h5 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Bids</h5>
            </th>
            <th class="four wide bbo-table-header" id="offers">
              <h5 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Offers</h5>
            </th>
            <th class="four wide bbo-table-header" id="ovol">
              <h5 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Volume</h5>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in BBO.slice(0, showAllBBO ? BBO.length : 5)" :key="index">
            <td :ref="`QtyBuy${index}`" class="ht-bbo-td">
              <h5 class="ui yellow header">
                {{ item.bidVol ? $numeral(item.bidVol).format("0,0") : "-" }}
              </h5>
            </td>
            <td :ref="`PriceBuy${index}`" class="ht-bbo-td">
              <div
                class="bg-bids-vol"
                :style="`width: ${
                  $numeral(sumVolBBOSideBuy).value() > 0 ? ($numeral(item.bidVol).value() / $numeral(sumVolBBOSideBuy).value()) * 199 : 0
                }%`"
              ></div>
              <h5 v-if="['ATO', 'ATC'].includes(item.bidPrice) && index === 0" class="ui grey header" :class="{ inverted: theme === 'Dark' }">
                {{ item.bidPrice }}
              </h5>
              <h5 v-else-if="!item.bidPrice" class="ui grey header" :class="{ inverted: theme === 'Dark' }">-</h5>
              <h5
                v-else
                class="ui header"
                :class="getClassPriceColor(item.bidPrice)"
                :style="[index === 0 ? { cursor: 'pointer' } : { cursor: 'unset' }]"
                @click="() => onBBOPriceClicked('bid', item.bidPrice, index),"
              >
                {{ $numeral(item.bidPrice).format("0,0.00") }}
              </h5>
            </td>
            <td :ref="`PriceSell${index}`" class="ht-bbo-td" style="padding-left: 2px">
              <div
                class="bg-offers-vol"
                :style="`width: ${
                  $numeral(sumVolBBOSideSell).value() > 0 ? ($numeral(item.offerVol).value() / $numeral(sumVolBBOSideSell).value()) * 199 : 0
                }%`"
              ></div>
              <h5 v-if="['ATO', 'ATC'].includes(item.offerPrice) && index === 0" class="ui grey header" :class="{ inverted: theme === 'Dark' }">
                {{ item.offerPrice }}
              </h5>
              <h5 v-else-if="!item.offerPrice" class="ui grey header" :class="{ inverted: theme === 'Dark' }">-</h5>
              <h5
                v-else
                class="ui header"
                :class="getClassPriceColor(item.offerPrice)"
                :style="[index === 0 ? { cursor: 'pointer' } : { cursor: 'unset' }]"
                @click="() => onBBOPriceClicked('offer', item.offerPrice, index)"
              >
                {{ $numeral(item.offerPrice).format("0,0.00") }}
              </h5>
            </td>
            <td :ref="`QtySell${index}`" class="ht-bbo-td">
              <h5 class="ui yellow header">
                {{ item.offerVol ? $numeral(item.offerVol).format("0,0") : "-" }}
              </h5>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="show-more-bbo-container" v-show="BBO && BBO.length > 0">
        <input
          type="checkbox"
          :id="`show-more-bbo-checkbox-${uid}`"
          v-model="showAllBBO"
          @change="$emit('switch-level', showAllBBO)"
          class="show-more-bbo-checkbox"
        />
        <label :for="`show-more-bbo-checkbox-${uid}`" class="show-more-bbo-label" :data-tooltip="!showAllBBO ? 'Show more BBOs' : 'Show less BBOs'">
          <i class="chevron down icon" />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

export default {
  name: "MarketBBO",
  props: ["currentQuoteSelectedSymbol", "showAll"],
  emits: ["switch-level"],
  data: () => ({
    currentSymbol: {},
    BBO: [],
    showAllBBO: false,
    uid: "",
    interval: null,
  }),
  watch: {
    BBO: {
      handler(newValue, oldValue) {
        if (!this.currentQuoteSelectedSymbol) {
          return;
        }

        if (!oldValue.length || !newValue.length) {
          return;
        }

        newValue.forEach((newBBO, index) => {
          if (!oldValue[index]) {
            return;
          }

          if (newBBO.bidVol !== oldValue[index]?.bidVol) {
            this.blinkBackground("Qty", "Buy", index);

            if (newBBO.bidVol > oldValue[index]?.bidVol) {
              this.blinkText("Qty", "Buy", index, "up");
            } else {
              this.blinkText("Qty", "Buy", index, "down");
            }
          }

          if (newBBO.bidPrice !== oldValue[index]?.bidPrice) {
            this.blinkBackground("Price", "Buy", index);
          }

          if (newBBO.offerVol !== oldValue[index]?.offerVol) {
            this.blinkBackground("Qty", "Sell", index);
            if (newBBO.offerVol > oldValue[index]?.offerVol) {
              this.blinkText("Qty", "Sell", index, "up");
            } else {
              this.blinkText("Qty", "Sell", index, "down");
            }
          }

          if (newBBO.offerPrice !== oldValue[index]?.offerPrice) {
            this.blinkBackground("Price", "Sell", index);
          }
        });
      },
      deep: true,
    },
    currentQuoteSelectedSymbol: {
      handler(newSymbol, oldSymbol) {
        if (newSymbol?.symbol !== oldSymbol?.symbol) {
          this.init();
          this.currentSymbol.symbol = newSymbol?.symbol || null;
        }
      },
    },
    showAll: {
      handler(newValue) {
        this.showAllBBO = newValue || false;
      },
    },
  },
  created() {
    this.uid = this._uid;
    this.timeout = {
      QtyBuy: [false, false, false, false, false],
      PriceBuy: [false, false, false, false, false],

      QtySell: [false, false, false, false, false],
      PriceSell: [false, false, false, false, false],
    };
    this.showAllBBO = this.showAll;

    this.$EventBus.$on("ctinf/SS01", this.updateInfo);
    this.$EventBus.$on("ctinf/SS51", this.updateInfo);
    this.$EventBus.$on("bcst/PB52", this.updateBBO);
    this.$EventBus.$on("bcst/PB21", this.updateNewBBO);
    this.$EventBus.$on("bcst/PB22", this.updateNewBBO);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS01", this.updateInfo);
    this.$EventBus.$off("ctinf/SS51", this.updateInfo);
    this.$EventBus.$off("bcst/PB52", this.updateBBO);
    this.$EventBus.$off("bcst/PB21", this.updateNewBBO);
    this.$EventBus.$off("bcst/PB22", this.updateNewBBO);
  },
  methods: {
    init() {
      this.BBO = [];
    },
    getClassPriceColor(comparison) {
      const ceiling = this.$numeral(this.currentSymbol.ceiling).value();
      const floor = this.$numeral(this.currentSymbol.floor).value();

      let prior = this.$numeral(this.currentSymbol.prior).value();
      if (this.currentSymbol.exchangeId === 2) {
        prior = this.$numeral(this.currentSymbol.prevSettlePrice).value();
      }
      comparison = this.$numeral(comparison).value();

      if (!comparison) {
        if (this.theme === "Dark") {
          return "grey inverted";
        } else {
          return "grey";
        }
      } else if (comparison >= ceiling) {
        return "blue";
      } else if (comparison > prior) {
        return "green";
      } else if (comparison <= floor) {
        return "pink";
      } else if (comparison < prior) {
        return "red";
      } else {
        return "yellow";
      }
    },
    blinkBackground(type, side, index) {
      const domList = this.$refs[`${type + side + index}`];
      if (!domList && !domList?.length) return;

      const dom = domList[0];
      dom.classList.add("blink");
      if (this.timeout[`${type + side + index}`]) {
        clearTimeout(this.timeout[`${type + side + index}`]);
      }
      this.timeout[`${type + side + index}`] = setTimeout(() => {
        dom.classList.remove("blink");
      }, 250);
    },
    blinkText(type, side, index, direction) {
      const domList = this.$refs[`${type + side + index}`];
      if (!domList && !domList?.length) return;

      const dom = domList[0];
      dom.classList.add("blink");
      dom.classList.add(direction);
      if (this.timeout[`${type + side + index}`]) {
        clearTimeout(this.timeout[`${type + side + index}`]);
      }
      this.timeout[`${type + side + index}`] = setTimeout(() => {
        dom.classList.remove("blink");
        dom.classList.remove(direction);
      }, 250);
    },

    updateBBO(update) {
      if (!this.currentQuoteSelectedSymbol) {
        return;
      }

      if (update.securityId !== this.currentQuoteSelectedSymbol.value.securityId) {
        return;
      }

      const temptBBO = JSON.parse(JSON.stringify(this.BBO));

      if (update.side === "B" || update.side === "L") {
        temptBBO.forEach((bboItem, index) => {
          bboItem.bidPrice = update[`price${index + 1}`];
          bboItem.bidVol = update[`volume${index + 1}`];
        });
      } else if (update.side === "S") {
        temptBBO.forEach((bboItem, index) => {
          bboItem.offerPrice = update[`price${index + 1}`];
          bboItem.offerVol = update[`volume${index + 1}`];
        });
      }

      this.BBO = temptBBO;
    },
    updateNewBBO(newBBO) {
      if (!this.currentQuoteSelectedSymbol || newBBO.securityId !== this.currentQuoteSelectedSymbol?.value.securityId) {
        return;
      }

      const keyList = Object.keys(newBBO)
        .filter((key) => key.match(/volume\d+/g))
        .sort((key1, key2) => {
          const key1Seq = Number(key1.match(/\d+/g));
          const key2Seq = Number(key2.match(/\d+/g));

          return key1Seq - key2Seq;
        });

      const temptBBO = JSON.parse(JSON.stringify(this.BBO));

      keyList.forEach((key) => {
        const index = key.match(/\d+/g);

        if (newBBO.side == "B") {
          temptBBO[Number(index) - 1].bidPrice = newBBO[`price${index}`];
          temptBBO[Number(index) - 1].bidVol = newBBO[`volume${index}`];
          return;
        }

        if (newBBO.side == "S") {
          temptBBO[Number(index) - 1].offerPrice = newBBO[`price${index}`];
          temptBBO[Number(index) - 1].offerVol = newBBO[`volume${index}`];
          return;
        }
      });

      this.BBO = temptBBO;
    },
    updateInfo(update) {
      if (!this.currentQuoteSelectedSymbol) {
        return;
      }

      if (update.securityId !== this.currentQuoteSelectedSymbol.value.securityId) {
        return;
      }

      const bidKeyList = Object.keys(update)
        .filter((key) => key.match(/bid\d+/g))
        .sort((key1, key2) => {
          const key1Seq = Number(key1.match(/\d+/g));
          const key2Seq = Number(key2.match(/\d+/g));

          return key1Seq - key2Seq;
        });

      const resolvedBBO = bidKeyList.map((_, index) => ({
        bidPrice: update[`bid${index + 1}`],
        bidVol: update[`bidVol${index + 1}`],
        offerPrice: update[`offer${index + 1}`],
        offerVol: update[`offerVol${index + 1}`],
      }));

      this.BBO = resolvedBBO;

      this.currentSymbol.prior = update.prior;
      this.currentSymbol.ceiling = update.ceiling;
      this.currentSymbol.floor = update.floor;

      this.currentSymbol.mktStCode = update.mktStCode;

      if (update.exchangeId === 2) {
        this.currentSymbol.exchangeId = update.exchangeId;
        this.currentSymbol.prevSettlePrice = update.prevSettlePrice;
      }
    },
    onBBOPriceClicked(side, price, index) {
      if (!this.currentQuoteSelectedSymbol || index !== 0) {
        return;
      }

      this.$EventBus.$emit(
        "onBBOPriceClicked",
        this.currentQuoteSelectedSymbol.value.symbol,
        this.currentQuoteSelectedSymbol.value.exchangeId,
        side,
        price
      );
    },

    XR03(messageCode) {
      const msg = this.$messageFactory.createMessage("XR03");

      msg.messageCode.set(messageCode);

      console.log("test send xr03", msg);

      this.$ws.send(msg);
    },
  },
  computed: {
    theme: sync("global/theme"),
    sumVolBBOSideBuy() {
      let sum = this.BBO.reduce((previous, current) => previous + this.$numeral(current.bidVol).value(), 0);

      return sum;
    },
    sumVolBBOSideSell() {
      let sum = this.BBO.reduce((previous, current) => previous + this.$numeral(current.offerVol).value(), 0);

      return sum;
    },
  },
};
</script>

<style scoped lang="scss">
tr {
  opacity: 0.999;
}
td {
  position: relative !important;
}
td:nth-child(2) > h5,
td:nth-child(3) > h5 {
  margin-top: 0px;
}
.bg-bids-vol,
.bg-offers-vol {
  position: absolute !important;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-color: #fbbd0830;
}
.bg-bids-vol {
  right: 0;
}
.ui.table.inverted .bg-bids-vol,
.ui.table.inverted .bg-offers-vol {
  background-color: #2b4e2ccf;
}

.ui.table td.blink,
.ui.table tr.blink {
  background-color: rgba(22, 160, 133, 0.5) !important;
}

.ui.table td.up h5 {
  color: #00aa00 !important;
}
.ui.table td.down h5 {
  color: #d91e18 !important;
}

.ui.inverted.table {
  background: unset;
}

.ht-bbo-td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 1rem !important;
  line-height: 21px !important;
  height: 21px !important;
}

.bbo-table-header {
  padding-top: 8px !important;
  padding-bottom: 7px !important;
  position: sticky;
  top: 0;
  background-color: #1b2025 !important;
  z-index: 2;
}

.market-bbo .tab-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1b2025;
  height: 32px;
}

.market-bbo .tab-title-container .level-toggle {
  margin-right: 0.4rem;
}

.market-bbo .tab-title-container .bbo-tab {
  height: 32px;
  width: 100%;
}

.market-bbo .bbo-table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0.5rem;
}

.market-bbo .bbo-table {
  margin: 0;

  th {
    padding: 0.4rem 0.6rem !important;
  }
}

.market-bbo .level-toggle-container {
  display: flex;
  align-items: center;
  color: #dcddde;
  flex-basis: 160px;
  column-gap: 0.5rem;
}

.market-bbo {
  height: 100%;
  padding: 0;
  min-height: 160px;
  background-color: #262d33;

  .show-more-bbo-container {
    display: flex;
    justify-content: center;
    padding: 0 0.2rem;

    .show-more-bbo-checkbox {
      display: none;

      &:checked {
        ~ .show-more-bbo-label .icon {
          transform: rotate(-180deg);
        }
      }
    }

    .show-more-bbo-label {
      width: 18px;
      height: 18px;
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid transparent;

      .icon {
        color: #dcddde;
        margin: 0;
        line-height: 16px;
        transition: all 0.4s cubic-bezier(0, 1.24, 0.29, 0.99);
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
