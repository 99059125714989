const values = {
  M: "Manager",
  U: "Supervisor",
  T: "Trader",
  O: "Order Entry",
  I: "Investor"
};

const getUserTypeByKey = key => {
  return values[key] || key;
};

export { getUserTypeByKey };
