<template>
  <div class="portfolio-market-value-chart" ref="portfolio-market-value-chart">
    <sui-tab :menu="{ attached: 'top' }">
      <sui-tab-pane title="Market Value" attached="top">
        <div v-if="$_.sum(series) === 0" style="position: absolute; color: #dcddde; z-index: 1; top: 40%; left: 35%; font-size: 12px">
          Don't have any Position.
        </div>
        <apexchart type="donut" :height="dynamicHeight" :options="chartOptions" :series="series" />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

export default {
  data: () => ({
    isLoading: true,
    refId: null,
    dynamicHeight: 0,
    series: [0],
    chartOptions: {
      chart: {
        offsetY: 6,
      },
      labels: ["N/A"],
      theme: {
        mode: "dark",
      },
      colors: ["#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800", "#FF4560"],
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      stroke: {
        width: 0,
        color: "#1B2025",
      },
      legend: {
        position: "right",
        offsetX: 0,
        offsetY: 0,
        formatter: function (val, opts) {
          return " " + val + " : " + opts.w.globals.series[opts.seriesIndex] + "%";
        },
      },
      tooltip: {
        y: {
          formatter: (value) => value + "%",
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  }),
  watch: {
    currentSelectedAccount() {
      this.AR02();
    },
  },
  created() {
    this.resizeTimeout = null;
    this.dynamicHeight = this.$vhToPx(20) - this.$vhToPx(0.75);

    this.AR02();

    this.$EventBus.$on("ctinf/AS02", this.etsAccountPositionCinf);
    this.$EventBus.$on("ctinf/AS52", this.dtsAccountPositionCinf);
  },
  mounted() {
    window.addEventListener("resize", this.onResize);

    this.onResize();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS02", this.etsAccountPositionCinf);
    this.$EventBus.$off("ctinf/AS52", this.dtsAccountPositionCinf);

    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        const chartContainer = this.$refs["portfolio-market-value-chart"];

        this.dynamicHeight = chartContainer.clientHeight - 32 - 15;
      }, 200);
    },
    onRefresh() {
      this.AR02();
    },
    AR02() {
      this.isLoading = true;
      if (!this.currentSelectedAccount || this.currentSelectedAccount?.exchangeId == 0 || !this.currentSelectedAccount?.accountCode) {
        this.clearChartData();
        this.isLoading = false;
        return;
      }
      const msg = this.$messageFactory.createMessage("AR02");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);
      msg.exchangeId.set(this.currentSelectedAccount.exchangeId);
      this.refId = this.$shortid.generate();

      this.$ws.send(msg, this.refId);
    },
    clearChartData() {
      this.series = [0];
      this.chartOptions = { ...this.chartOptions, labels: ["N/A"] };
    },
    updateMarketValueChart(position) {
      if (position.marketCharts.length === 0) {
        this.clearChartData();
      } else {
        this.series = position.marketCharts.map((marketChart) => this.$numeral(marketChart.marketValue).value());
        this.chartOptions = { ...this.chartOptions, labels: position.marketCharts.map((marketChart) => marketChart.symbol) };
      }
    },
    etsAccountPositionCinf(position) {
      if (position.refId !== this.refId) return;

      this.isLoading = false;
      this.updateMarketValueChart(position);
    },
    dtsAccountPositionCinf(position) {
      this.isLoading = false;
      this.updateMarketValueChart(position);
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
  },
};
</script>

<style lang="scss" scoped>
.apexcharts-legend-text {
  color: #dcddde !important;
  font-weight: 700 !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 1rem !important;
}

.portfolio-market-value-chart {
  height: 100%;
}
</style>
