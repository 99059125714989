<template>
  <div style="padding: 0px 0.5rem" class="market-stock-quote">
    <div class="ui grid content-container">
      <div class="symbol-container">
        <SymbolDropdown
          ref="symbol"
          v-model="selectedSymbol"
          market="ALL"
          image
          notification
          style="display: inline-block; min-height: 3rem !important; line-height: 3rem !important"
          class="custom-symbol-dropdown"
          @enter="$refs.symbol.select()"
        />
        <div v-if="isFavoriteMode" style="display: inline-block; margin-left: 0.5rem">
          <sui-button
            v-if="selectedSymbol && !$_.find(currentMaketWatchList, ['symbol', selectedSymbol.symbol])"
            animated="vertical"
            color="blue"
            style="height: 3rem"
            @click="onAddSymbol"
          >
            <sui-button-content hidden>Add</sui-button-content>
            <sui-button-content visible>
              <sui-icon name="star outline" />
            </sui-button-content>
          </sui-button>

          <sui-button v-else-if="selectedSymbol" animated="vertical" color="black" style="height: 3rem" @click="onRemoveSymbol">
            <sui-button-content hidden>Remove</sui-button-content>
            <sui-button-content visible>
              <sui-icon name="trash alternate outline" />
            </sui-button-content>
          </sui-button>
        </div>
      </div>

      <div class="detail-container">
        <table class="tg" style="width: 100%; height: 3.5rem">
          <colgroup>
            <col style="width: 2.7%" />
            <col style="width: 1.3%" />
            <col style="width: 1.2%" />
            <col style="width: 2%" />
            <col style="width: 1%" />
            <col style="width: 2%" />
            <col style="width: 1%" />
            <col style="width: 2%" />
            <col style="width: 1.2%" class="wide-col" />
            <col style="width: 2%" class="wide-col" />
            <col style="width: 2.5%" class="wide-col" />
          </colgroup>
          <tr>
            <td class="tg-0ord" rowspan="2">
              <h1
                ref="securityLastPrice"
                class="ui header"
                :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.last), { blink: componentsClass.securityLastPrice }]"
              >
                {{ $numeral(securityInfoLatest.last).format("0,0.00") }}
              </h1>
            </td>
            <td class="tg-0ord">
              <h4
                ref="securityChange"
                class="ui header"
                :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.last), { blink: componentsClass.securityChange }]"
              >
                {{
                  ($numeral(securityInfoLatest.last).value() > $numeral(securityInfoLatest.prior).value() ? "+" : "") +
                  $numeral(securityInfoLatest.change).format("0,0.00")
                }}
              </h4>
            </td>
            <td class="tg-lqy6">
              <h4 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Value</h4>
            </td>
            <td class="tg-lqy6">
              <h4 ref="securityValue" class="ui yellow header" :class="[{ blink: componentsClass.securityValue }]">
                {{ $numeral($numeral(securityInfoLatest.value).value() / 1000).format("0,0.00") }}
                K
              </h4>
            </td>
            <td class="tg-lqy6">
              <h4 class="ui grey header" :class="{ inverted: theme === 'Dark' }">High</h4>
            </td>
            <td class="tg-lqy6">
              <h4
                ref="securityHigh"
                class="ui header"
                :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.high), { blink: componentsClass.securityHigh }]"
              >
                {{ $numeral(securityInfoLatest.high).format("0,0.00") }}
              </h4>
            </td>
            <td class="tg-lqy6">
              <h4 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Avg</h4>
            </td>
            <td class="tg-lqy6">
              <h4
                ref="securityAvg"
                class="ui header"
                :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.average), { blink: componentsClass.securityAvg }]"
              >
                {{ $numeral(securityInfoLatest.average).format("0,0.00") }}
              </h4>
            </td>
            <td class="tg-lqy6 wide-col-content">
              <h4 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Ceiling</h4>
            </td>
            <td class="tg-lqy6 wide-col-content">
              <h4 ref="securityCelling" class="ui blue header" :class="[{ blink: componentsClass.securityCelling }]">
                {{ $numeral(securityInfoLatest.ceiling).format("0,0.00") }}
              </h4>
            </td>
            <td class="tg-lqy6 wide-col-content">
              <h4
                ref="securityOpenLabel"
                class="ui grey header"
                :class="[{ inverted: theme === 'Dark' }, { blink: componentsClass.securityOpenLabel }]"
              >
                {{ securityOpenLabelLatest }}
              </h4>
            </td>
          </tr>
          <tr>
            <td class="tg-0ord">
              <h4 ref="securityPercentChange" class="ui header" :class="getClassColorCompare(securityInfoLatest, securityInfoLatest.last)">
                {{
                  ($numeral(securityInfoLatest.last).value() > $numeral(securityInfoLatest.prior).value() ? "+" : "") +
                  $numeral(securityInfoLatest.changePercent).format("0,0.00") +
                  "%"
                }}
              </h4>
            </td>
            <td class="tg-lqy6">
              <h4 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Volume</h4>
            </td>
            <td class="tg-lqy6">
              <h4 ref="securityVolume" class="ui yellow header" :class="[{ blink: componentsClass.securityVolume }]">
                {{ $numeral(securityInfoLatest.volume).format("0,0") }}
              </h4>
            </td>
            <td class="tg-lqy6">
              <h4 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Low</h4>
            </td>
            <td class="tg-lqy6">
              <h4
                ref="securityLow"
                class="ui header"
                :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.low), { blink: componentsClass.securityLow }]"
              >
                {{ $numeral(securityInfoLatest.low).format("0,0.00") }}
              </h4>
            </td>
            <td class="tg-lqy6">
              <h4 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Close</h4>
            </td>
            <td class="tg-lqy6">
              <h4 ref="securityClose" class="ui yellow header" :class="[{ blink: componentsClass.securityClose }]">
                {{ $numeral(securityInfoLatest.prior).format("0,0.00") }}
              </h4>
            </td>
            <td class="tg-lqy6 wide-col-content">
              <h4 class="ui grey header" :class="{ inverted: theme === 'Dark' }">Floor</h4>
            </td>
            <td class="tg-lqy6 wide-col-content">
              <h4 ref="securityFloor" class="ui pink header" :class="[{ blink: componentsClass.securityFloor }]">
                {{ $numeral(securityInfoLatest.floor).format("0,0.00") }}
              </h4>
            </td>
            <td class="tg-lqy6 wide-col-content">
              <h4
                ref="securityOpen"
                class="ui header"
                :class="[getClassColorCompare(securityInfoLatest, securityOpenPriceLatest), { blink: componentsClass.securityOpen }]"
              >
                <template v-if="securityOpenPriceLatest === '-'">
                  {{ securityOpenPriceLatest }}
                </template>
                <template v-else>
                  {{ $numeral(securityOpenPriceLatest).format("0,0.00") }}
                </template>
              </h4>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import SymbolDropdown from "@/components/SymbolDropdown";
import { SecurityItem } from "@/messages/websocket/XR03";

export default {
  name: "MarketStockQuote",
  components: {
    SymbolDropdown,
  },
  data: () => ({
    selectedSymbol: null,
    isLoading: false,
    securityInfoLatest: {},
    componentsClass: {
      securityLastPrice: false,

      securityChange: false,
      securityValue: false,
      securityHigh: false,
      securityAvg: false,
      securityCelling: false,
      securityOpenLabel: false,

      securityPercentChange: false,
      securityVolume: false,
      securityLow: false,
      securityClose: false,
      securityFloor: false,
      securityOpen: false,
    },
    securityOpenLabelLatest: "",
    securityOpenPriceLatest: 0,
  }),
  watch: {
    selectedSymbol(selectedSymbol) {
      if (!selectedSymbol) {
        this.securityInfoLatest = {};
        this.securityOpenLabelLatest = "";
        this.securityOpenPriceLatest = 0;
        return;
      }

      this.SR04(selectedSymbol.value);
    },

    currentQuoteSelectedSymbol: {
      handler(newSymbol) {
        if (newSymbol?.symbol) {
          this.SR01(newSymbol.value);
        }
      },
    },
  },
  created() {
    this.$EventBus.$on("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$on("ctinf/SS51", this.dtsSecurityInfoUpdateCinf);
    this.$EventBus.$on("bcst/PB01", this.updateSecurityInfo);
    this.$EventBus.$on("bcst/PB51", this.updateSecurityInfo);
    this.$EventBus.$on("bcst/PB03", this.priceOpenTradeBcst);
    this.$EventBus.$on("bcst/PB04", this.priceProjectionBcst);
    this.selectedSymbol = this.currentQuoteSelectedSymbol || null;

    if (this.selectedSymbol) this.SR01(this.selectedSymbol.value);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS01", this.securityInfoUpdateCinf);
    this.$EventBus.$off("ctinf/SS51", this.dtsSecurityInfoUpdateCinf);
    this.$EventBus.$off("bcst/PB01", this.updateSecurityInfo);
    this.$EventBus.$off("bcst/PB51", this.updateSecurityInfo);
    this.$EventBus.$off("bcst/PB03", this.priceOpenTradeBcst);
    this.$EventBus.$off("bcst/PB04", this.priceProjectionBcst);
  },
  methods: {
    getClassColorCompare(securityInfoLatest, comparison) {
      const ceiling = this.$numeral(securityInfoLatest.ceiling).value();
      const floor = this.$numeral(securityInfoLatest.floor).value();

      let prior = this.$numeral(securityInfoLatest.prior).value();
      if (securityInfoLatest.exchangeId === 2) {
        prior = this.$numeral(securityInfoLatest.prevSettlePrice).value();
      }
      comparison = this.$numeral(comparison).value();

      if (!comparison) {
        return "yellow";
      } else if (comparison >= ceiling) {
        return "blue";
      } else if (comparison > prior) {
        return "green";
      } else if (comparison <= floor) {
        return "pink";
      } else if (comparison < prior) {
        return "red";
      } else {
        return "yellow";
      }
    },
    onAddSymbol() {
      this.$EventBus.$emit("favoriteListChange", "ADD", this.selectedSymbol);
    },
    onRemoveSymbol() {
      this.$EventBus.$emit("favoriteListChange", "REMOVE", this.selectedSymbol);
    },
    SR01(selectedSymbol) {
      const msg = this.$messageFactory.createMessage("SR01");

      msg.securityId.set(selectedSymbol.securityId);
      msg.lastTradeCount.set(5);
      msg.full.set("Y");
      msg.exchangeId.set(selectedSymbol.exchangeId);

      this.$ws.send(msg);
    },
    SR04(selectedSymbol) {
      const msg = this.$messageFactory.createMessage("SR04");

      msg.securityId.set(selectedSymbol.securityId);
      msg.exchangeId.set(selectedSymbol.exchangeId);

      this.$ws.send(msg);
    },
    XR03(selectedSymbol) {
      const msg = this.$messageFactory.createMessage("XR03");

      const securityItem = new SecurityItem();
      securityItem.securityId = Number(selectedSymbol.securityId);

      const securityList = this.currentMaketWatchList || [];

      const securityItemList = securityList.map((security) => {
        const securityItem = new SecurityItem();
        securityItem.securityId = Number(security.securityId);
        return securityItem;
      });

      const duplicateSecurity = securityItemList.find((security) => {
        return security.securityId.get() === securityItem.securityId.get();
      });

      if (!duplicateSecurity) msg.securityList.get().push(securityItem);

      msg.securityList.get().push(...securityItemList);

      msg.messageCode.set("PB03"); /* PriceOpenTradeBcst */
      this.$ws.send(msg);
      msg.messageCode.set("PB04"); /* PriceProjectionBcst */
      this.$ws.send(msg);
    },
    blinkBackground(ref) {
      this.$refs[ref].classList.add("blink");
      setTimeout(() => {
        this.$refs[ref].classList.remove("blink");
      }, 250);
    },
    blinkText(ref, direction) {
      this.$refs[ref].classList.add(direction);
      setTimeout(() => {
        this.$refs[ref].classList.remove(direction);
      }, 500);
    },
    updateSecurityInfo(update) {
      if (update.securityId !== this.securityInfoLatest.securityId) {
        return;
      }

      if (this.securityInfoLatest.last !== update.price) {
        this.blinkBackground("securityLastPrice");
      }
      this.securityInfoLatest.last = update.price;

      if (this.securityInfoLatest.change !== update.changePrice) {
        this.blinkBackground("securityChange");
        this.blinkBackground("securityPercentChange");
      }
      this.securityInfoLatest.change = update.changePrice;
      if (this.$numeral(update.changePrice).value()) {
        this.securityInfoLatest.changePercent =
          (this.$numeral(update.changePrice).value() / this.$numeral(this.securityInfoLatest.prior).value()) * 100;
      }

      if (this.securityInfoLatest.volume !== update.totalVolume) {
        this.blinkBackground("securityVolume");
      }
      this.securityInfoLatest.volume = update.totalVolume;

      if (this.securityInfoLatest.value !== update.totalValue) {
        this.blinkBackground("securityValue");
        this.blinkBackground("securityAvg");
      }
      this.securityInfoLatest.value = update.totalValue;

      if (this.$numeral(update.price).value() > this.$numeral(this.securityInfoLatest.high).value()) {
        if (this.securityInfoLatest.high !== update.price) {
          this.blinkBackground("securityHigh");
        }
        this.securityInfoLatest.high = update.price;
      }

      if (this.$numeral(update.price).value() < this.$numeral(this.securityInfoLatest.low).value()) {
        if (this.securityInfoLatest.low !== update.price) {
          this.blinkBackground("securityLow");
        }
        this.securityInfoLatest.low = update.price;
      }

      if (this.$numeral(update.totalValue).value() && this.$numeral(update.totalVolume).value()) {
        this.securityInfoLatest.average = this.$numeral(update.totalValue).value() / this.$numeral(update.totalVolume).value();
      }
    },
    securityInfoUpdateCinf(securityInfoUpdateCinf) {
      this.securityInfoLatest = Object.assign({}, securityInfoUpdateCinf);

      switch (securityInfoUpdateCinf.mktStCode) {
        case "PREOPEN1":
        case "PREOPEN2":
        case "PREOPEN_TEMP":
          this.securityOpenLabelLatest = "Projected Open";
          this.securityOpenPriceLatest = securityInfoUpdateCinf.projectedPrice;
          break;
        case "OPEN1":
        case "INTERMISSION":
          this.securityOpenLabelLatest = "Open 1";
          this.securityOpenPriceLatest = securityInfoUpdateCinf.open1;
          break;
        case "OPEN2":
          this.securityOpenLabelLatest = "Open 2";
          this.securityOpenPriceLatest = securityInfoUpdateCinf.open2;
          break;
        case "PRECLOSE":
          this.securityOpenLabelLatest = "Projected Close";
          this.securityOpenPriceLatest = securityInfoUpdateCinf.projectedPrice;
          break;
        case "OFFHOUR":
        case "CLOSE":
        case "AFTERMARKET":
          this.securityOpenLabelLatest = "Close";
          this.securityOpenPriceLatest = securityInfoUpdateCinf.last;
          break;
        default:
          this.securityOpenLabelLatest = "";
          this.securityOpenPriceLatest = "0.00";
          break;
      }
    },
    dtsSecurityInfoUpdateCinf(dtsSecurityInfoUpdateCinf) {
      this.securityInfoLatest = Object.assign({}, dtsSecurityInfoUpdateCinf);

      switch (dtsSecurityInfoUpdateCinf.mktStCode) {
        case "PREOPEN1":
        case "PREOPEN2":
        case "PREOPEN3":
          this.securityOpenLabelLatest = "Projected Open";
          this.securityOpenPriceLatest = dtsSecurityInfoUpdateCinf.projectedPrice;
          break;
        case "OPEN1":
        case "INTERMISSION":
          this.securityOpenLabelLatest = "Open 1";
          this.securityOpenPriceLatest = dtsSecurityInfoUpdateCinf.open1;
          break;
        case "OPEN2":
        case "INTERMISSION2":
          this.securityOpenLabelLatest = "Open 2";
          this.securityOpenPriceLatest = dtsSecurityInfoUpdateCinf.open2;
          break;
        case "OPEN3":
        case "INTERMISSION3":
          this.securityOpenLabelLatest = "Open 3";
          this.securityOpenPriceLatest = dtsSecurityInfoUpdateCinf.open3;
          break;
        case "PRE_SETTLEMENT":
          this.securityOpenLabelLatest = "Projected Close";
          this.securityOpenPriceLatest = dtsSecurityInfoUpdateCinf.projectedPrice;
          break;
        case "SETTLEMENT":
        case "CLOSE":
        case "AFTERMARKET":
          this.securityOpenLabelLatest = "Close";
          this.securityOpenPriceLatest = dtsSecurityInfoUpdateCinf.last;
          break;
        default:
          this.securityOpenLabelLatest = "";
          this.securityOpenPriceLatest = "0.00";
          break;
      }
    },
    priceOpenTradeBcst(update) {
      if (update.securityId !== this.securityInfoLatest.securityId) {
        return;
      }

      this.blinkBackground("securityOpenLabel");
      this.blinkBackground("securityOpen");

      if (update.type === "1") {
        this.securityOpenLabelLatest = "Open 1";
      } else if (update.type === "2") {
        this.securityOpenLabelLatest = "Open 2";
      }
      this.securityOpenPriceLatest = update.price;
    },
    priceProjectionBcst(update) {
      if (update.securityId !== this.securityInfoLatest.securityId) {
        return;
      }

      this.blinkBackground("securityOpenLabel");
      this.blinkBackground("securityOpen");

      if (update.type === "1") {
        this.securityOpenLabelLatest = "Projected Open";
      } else if (update.type === "2") {
        this.securityOpenLabelLatest = "Projected Close";
      }
      this.securityOpenPriceLatest = update.price;
    },
  },
  computed: {
    theme: sync("global/theme"),
    isFavoriteMode: get("global/isFavoriteMode"),
    currentMaketWatchList: get("global/currentMaketWatchList"),
    markets: get("model/systemRefData@markets"),
    dtsMarkets: get("model/systemRefData@dtsMarkets"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
  },
};
</script>

<style lang="scss" scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.market-stock-quote {
  background-color: #262d33;

  .content-container {
    column-gap: 1rem;
    .symbol-container {
      display: flex;
      min-width: 13rem;
      padding: 0;
      align-items: center;

      .custom-symbol-dropdown {
        width: 100% !important;
      }
    }
    .detail-container {
      padding: 0;
      display: flex;
      align-items: center;
      flex: 1;
      overflow: auto;
    }
  }
}

.tg {
  border-collapse: collapse;
  border-spacing: 0;
}
.tg td {
  overflow: hidden;
  border: 0px solid red;
  word-break: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.tg th {
  border-style: solid;
  border-width: 0px;
  overflow: hidden;
  word-break: normal;
}
.tg .tg-0ord {
  text-align: right;
  vertical-align: middle;
}
.tg .tg-lqy6 {
  text-align: right;
  vertical-align: middle;
}
.tg .tg-s268 {
  text-align: left;
  vertical-align: middle;
}
.tg .tg-0lax {
  text-align: left;
  vertical-align: middle;
}

.ui.header.blink {
  background-color: #16a08580 !important;
}

.ui.header.up {
  color: #00aa00 !important;
}
.ui.header.down {
  color: #d91e18 !important;
}
</style>

<style>
div.default.text,
input.search {
  color: #dcddde !important;
}

.ui.selection.active.dropdown {
  border: 0px solid red !important;
}

.menu.transition {
  border: 0px !important;
  background-color: #262d33 !important;
}

.menu.transition > .message {
  color: #dcddde !important;
}

div.item {
  padding: 0.25rem 0rem !important;
  color: #dcddde !important;
  border: 0px solid red !important;
}

div.text {
  color: #dcddde !important;
  font-weight: 800;
  /* font-size: 1.1rem; */
}

.multiselect__tags {
  background-color: #2f383f !important;
  border-color: #2f383f !important;
  min-height: unset !important;
  padding-top: 4px !important;
  height: 40px !important;
}

.multiselect__input {
  background-color: #2f383f !important;
  color: #dcddde !important;
  font-weight: 800;
  padding-top: 0.4rem;
  text-transform: uppercase;
}

.multiselect__single {
  background-color: #2f383f !important;
  color: #dcddde !important;
  font-weight: 700;
}

.multiselect__placeholder {
  font-weight: 800;
  padding-left: 0.5rem;
  padding-top: 0.4rem;
  font-size: 1.1rem !important;
}

.multiselect__content-wrapper {
  background-color: #262d33;
  border: 0px;
}

.multiselect__option {
  padding: 0.5rem 1rem;
  color: #dcddde;
}

.multiselect__option--highlight {
  background: #242b30 !important;
}
</style>
