<script>
import BaseDropdown from "./BaseDropdown";

export default {
  extends: BaseDropdown,
  props: {
    value: String,
    market: {
      type: String,
      default: "TFEX",
    },
    placeholder: {
      type: String,
      default: "Validity",
    },
    filterable: {
      type: String,
      default: "startsWith",
    },
  },
  data() {
    return {
      style: "width: 8rem; min-width: 8rem;",
      defaultValue: "DAY",
      displayValue: "DAY",
      options: [
        {
          text: "Day",
          value: "DAY",
        },
        {
          text: "Date",
          value: "DATE",
        },
        {
          text: "FOK",
          value: "FOK",
        },
        {
          text: "FAK",
          value: "FAK",
        },
        {
          text: "Exp",
          value: "EXP",
        },
        {
          text: "Time",
          value: "TIME",
        },
        {
          text: "Session",
          value: "SESS",
        },
        {
          text: "NXPRE",
          value: "NXPRE",
        },
      ],
    };
  },
  created() {
    if (this.market === "SET") {
      this.options = [
        {
          text: "Day",
          value: "DAY",
        },
        {
          text: "Date",
          value: "DATE",
        },
        {
          text: "Session",
          value: "SESS",
        },
        {
          text: "Cancel",
          value: "GTC",
        },
      ];
    }
  },
};
</script>
