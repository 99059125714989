<template>
  <div style="height: 100%" class="bbo-item">
    <div style="height: 100%; display: flex; flex-direction: column">
      <div style="display: flex; flex-direction: row; padding: 0.25rem 0.5rem">
        <div>
          <SymbolDropdown
            ref="symbol"
            v-model="selectedSymbol"
            market="ALL"
            image
            :sync="false"
            :notificate-to-order="false"
            :default-from-store="false"
            style="
              margin-top: 1px;
              min-height: 2.5rem !important;
              line-height: 2.5rem !important;
              width: 10rem !important;
              min-width: 10rem !important;
            "
            @enter="$refs.symbol.select()"
          />
        </div>
        <div style="flex: 1">
          <QuoteLastsale :current-quote-selected-symbol="selectedSymbol" />
        </div>
      </div>
      <div style="flex: 1">
        <MarketBBO :current-quote-selected-symbol="selectedSymbol" :show-all="showAll" @switch-level="$emit('switch-level', $event)" />
      </div>
    </div>
  </div>
</template>

<script setup>
import SymbolDropdown from "@/components/SymbolDropdown";
import QuoteLastsale from "@/components/quote/QuoteLastsale";
import MarketBBO from "@/components/market/MarketBBO";

import useBBO from "@/components/bbos/hooks/useBBO";

const props = defineProps({ value: { type: Object, default: {} }, showAll: { type: Boolean, default: false } });
defineEmits(["input", "switch-level"]);

const { selectedSymbol } = useBBO(props);
</script>

<style lang="scss" scoped>
.bbo-item {
  background-color: #262d33 !important;
}
</style>
