<template>
  <div style="width: 100%; height: 100%" class="market-watch-table">
    <div style="height: 3rem; line-height: 3rem"><MarketWatchBar /></div>
    <ag-grid-vue
      style="width: 100%; height: calc(100% - 3rem); padding: 0px"
      class="displayed-market-watch"
      :class="{
        'ag-theme-balham-dark': theme === 'Dark',
        'ag-theme-balham': theme === 'Light',
      }"
      row-selection="single"
      :grid-options="gridMarketWatchOptions"
      :column-defs="columnDefsMarketWatch"
      :row-data="rowMarketWatch"
      :get-row-node-id="getMarketWatchRowNodeId"
      :row-height="21"
      :header-height="32"
      :animate-rows="true"
      :suppress-drag-leave-hides-columns="true"
      :enable-cell-change-flash="true"
      :default-col-def="{ resizable: true }"
      :locale-text="{ noRowsToShow: `Don't have any Symbol.` }"
      :row-drag-managed="true"
      :row-buffer="0"
      @grid-ready="onMarketWatchGridReady"
      @row-drag-end="rowMarketWatchDragEnd"
      @row-drag-enter="onRowDragEnter"
      @row-selected="onRowMarketWatchSelected"
      @cell-focused="onCellMarketWatchFocused"
      @bodyScroll="onScroll"
      @modelUpdated="onUpdateModel"
      @gridSizeChanged="onGridResize"
    />
  </div>
</template>

<script>
import { sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsMarketWatch } from "@/components/grid/market/ColumnDefs.js";
import BBOTopBidCellRenderer from "@/components/grid/market/BBOTopBidCellRenderer";
import BBOTopOfferCellRenderer from "@/components/grid/market/BBOTopOfferCellRenderer";
import MarketWatchBar from "@/components/market/MarketWatchBar";

Object.freeze(columnDefsMarketWatch);

export default {
  name: "MarketWatchTable",
  components: {
    MarketWatchBar,
    AgGridVue,
    /* eslint-disable */
    // it use for aggrid column define
    BBOTopBidCellRenderer,
    /* eslint-disable */
    // it use for aggrid column define
    BBOTopOfferCellRenderer,
  },
  data: () => ({
    columnDefsMarketWatch,
    gridMarketWatchApi: null,
    columnMarketWatchApi: null,
    rowMarketWatch: [],

    selectedStockGroup: "",
    scrollTimeout: null,
    isDragging: false,
    resizeTimeOut: null,
  }),
  methods: {
    onGridResize() {
      console.log("test grid resize");
      if (this.resizeTimeOut) {
        clearTimeout(this.resizeTimeOut);
      }

      this.resizeTimeOut = setTimeout(() => {
        this.updateDisplayList();
      }, 500);
    },
    onRowDragEnter() {
      this.isDragging = true;
    },
    updateDisplayList() {
      if (!this.gridMarketWatchApi) return;

      const displaySecurityList = this.gridMarketWatchApi.getRenderedNodes();
      let watchedSecurityList = [];

      if (displaySecurityList.length) {
        watchedSecurityList = displaySecurityList.map((displayNode) => displayNode.data);
      }

      const currentMaketWatchList = this.$store.get("global/currentDisplayMarketWatchList") || [];

      if (watchedSecurityList.length !== currentMaketWatchList.length)
        return this.$store.set("global/currentDisplayMarketWatchList", watchedSecurityList);

      const notDuplicatedSecurity = currentMaketWatchList.find((security) => {
        const duplicateSecurity = watchedSecurityList.find((watchedSecurity) => watchedSecurity.symbol === security.symbol);

        if (duplicateSecurity) return false;
        return true;
      });

      if (notDuplicatedSecurity) return this.$store.set("global/currentDisplayMarketWatchList", watchedSecurityList);
    },
    onScroll(update) {
      if (update.direction !== "vertical") return;
      if (this.isDragging) return;

      if (this.scrollTimeout) clearTimeout(this.scrollTimeout);

      this.scrollTimeout = setTimeout(() => {
        this.updateDisplayList();
      }, 500);
    },
    onUpdateModel() {
      if (this.isDragging) return;
      this.updateDisplayList();
    },
    onMarketWatchGridReady(params) {
      this.gridMarketWatchApi = params.api;
      this.columnMarketWatchApi = params.columnApi;

      if (this.selectedStockGroup) {
        this.onSelectedStockGroupChange(this.selectedStockGroup);
      }
    },
    getMarketWatchRowNodeId(data) {
      return data.securityId;
    },
    updateRowMarketWatch(update) {
      this.rowMarketWatch = update.securities;

      this.$store.set("global/currentMaketWatchList", update.securities);
    },
    updateBBOMarketWatch(update) {
      if (!this.gridMarketWatchApi.getRowNode(update.securityId)) {
        return;
      }

      let updateNode = Object.assign({}, this.gridMarketWatchApi.getRowNode(update.securityId).data);

      if (update.side === "B" || update.side === "L") {
        if (update.price1) {
          updateNode.topBid = update.price1;
        }

        if (update.volume1) {
          updateNode.topBidVol = update.volume1;
        }
      } else if (update.side === "S") {
        if (update.price1) {
          updateNode.topOffer = update.price1;
        }

        if (update.volume1) {
          updateNode.topOfferVol = update.volume1;
        }
      }

      this.gridMarketWatchOptions.api.batchUpdateRowData({ update: [updateNode] }, () => {});
    },
    updateLastsaleMarketWatch(update) {
      if (!this.gridMarketWatchApi.getRowNode(update.securityId)) {
        return;
      }

      let updateNode = Object.assign({}, this.gridMarketWatchApi.getRowNode(update.securityId).data);

      if (update.price) {
        updateNode.last = update.price;
      }

      if (update.changeFlag) {
        updateNode.changeFlag = update.changeFlag;
      }

      if (update.totalVolume) {
        updateNode.volume = update.totalVolume;
      }

      if (update.totalValue) {
        updateNode.value = update.totalValue;
      }

      this.gridMarketWatchOptions.api.batchUpdateRowData({ update: [updateNode] }, () => {});
    },

    rowMarketWatchDragEnd(event) {
      let rowMarketWatch = this.rowMarketWatch.slice(0);
      const fromIndex = this.$_.findIndex(rowMarketWatch, ["symbol", event.node.data.symbol]);
      const element = rowMarketWatch[fromIndex];

      rowMarketWatch.splice(fromIndex, 1);
      rowMarketWatch.splice(event.overIndex, 0, element);

      this.isDragging = false;

      const selectedRows = this.gridMarketWatchApi.getSelectedRows();

      if (selectedRows.length === 1) {
        const symbol = {
          symbol: selectedRows[0].symbol,
          value: {
            exchangeId: selectedRows[0].exchangeId,
            securityId: selectedRows[0].securityId,
            symbol: selectedRows[0].symbol,
          },
          fromUID: "outside",
        };

        this.$store.set("global/currentQuoteSelectedSymbol", symbol);
      }

      this.updateDisplayList();
      this.$store.set("global/currentMaketWatchList", rowMarketWatch);
      this.$EventBus.$emit("favoriteListChange", "MOVE");
    },
    onRowMarketWatchSelected(params) {
      const symbolSelectedRows = params.api.getSelectedRows();

      if (this.isDragging) return;

      if (symbolSelectedRows.length === 1) {
        if (params.node.selected && params.data) {
          const symbol = {
            symbol: params.data.symbol,
            value: {
              exchangeId: params.data.exchangeId,
              securityId: params.data.securityId,
              symbol: params.data.symbol,
            },
            fromUID: "outside",
          };

          this.$store.set("global/currentQuoteSelectedSymbol", symbol);
        }
      }
    },
    onCellMarketWatchFocused(event) {
      this.gridMarketWatchOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    onSelectedStockGroupChange(selectedStockGroup) {
      this.selectedStockGroup = selectedStockGroup;
      if (!this.columnMarketWatchApi) {
        return;
      }

      const mostSwingCol = ["swingPercent"];
      const turnoverListCol = ["turnoverFlag", "turnoverStartDate", "turnoverEndDate", "exchangeId"];
      const mostActiveInBrokerCol = ["volBrk", "valBrk"];
      const projectedOpenCloseCol = ["projectedOpen", "projectedClose", "projectedVol", "projectedOpenPercent", "projectedClosePercent", "prevClose"];
      const openGainersLosersCol = ["open", "openVol", "openPercent", "prevClose"];
      const allDynamicCol = [...mostSwingCol, ...turnoverListCol, ...mostActiveInBrokerCol, ...projectedOpenCloseCol, ...openGainersLosersCol];

      this.columnMarketWatchApi.setColumnsVisible(allDynamicCol, false);

      if (selectedStockGroup === "Most Swing") {
        this.columnMarketWatchApi.setColumnsVisible(mostSwingCol, true);
      } else if (selectedStockGroup === "Turnover List") {
        this.columnMarketWatchApi.setColumnsVisible(turnoverListCol, true);
      } else if (selectedStockGroup === "Most Active in Broker") {
        this.columnMarketWatchApi.setColumnsVisible(mostActiveInBrokerCol, true);
      } else if (selectedStockGroup.includes("Projected Open/Close")) {
        this.columnMarketWatchApi.setColumnsVisible(projectedOpenCloseCol, true);
      } else if (selectedStockGroup.startsWith("Open")) {
        this.columnMarketWatchApi.setColumnsVisible(openGainersLosersCol, true);
      }
    },
    clearMarketWatchTable() {
      this.rowMarketWatch = [];
    },
    setSuppressRowDragMarketWatchTable(suppress) {
      this.gridMarketWatchOptions.api.setSuppressRowDrag(suppress);
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
  created() {
    this.gridMarketWatchOptions = {};

    this.$EventBus.$on("onSelectedStockGroupChange", this.onSelectedStockGroupChange);

    this.$EventBus.$on("ctinf/SS03", this.updateRowMarketWatch);
    this.$EventBus.$on("bcst/PB01", this.updateLastsaleMarketWatch);
    this.$EventBus.$on("bcst/PB21", this.updateBBOMarketWatch);
    this.$EventBus.$on("bcst/PB51", this.updateLastsaleMarketWatch);
    this.$EventBus.$on("bcst/PB52", this.updateBBOMarketWatch);
  },
  mounted() {
    this.$EventBus.$on("clearMarketWatchTable", this.clearMarketWatchTable);

    this.$EventBus.$on("setSuppressRowDragMarketWatchTable", this.setSuppressRowDragMarketWatchTable);
  },
  beforeDestroy() {
    this.$EventBus.$off("clearMarketWatchTable", this.clearMarketWatchTable);
    this.$EventBus.$off("setSuppressRowDragMarketWatchTable", this.setSuppressRowDragMarketWatchTable);
    this.$EventBus.$off("onSelectedStockGroupChange", this.onSelectedStockGroupChange);

    this.$EventBus.$off("ctinf/SS03", this.updateRowMarketWatch);
    this.$EventBus.$off("bcst/PB01", this.updateLastsaleMarketWatch);
    this.$EventBus.$off("bcst/PB21", this.updateBBOMarketWatch);
    this.$EventBus.$off("bcst/PB51", this.updateLastsaleMarketWatch);
    this.$EventBus.$off("bcst/PB52", this.updateBBOMarketWatch);

    if (this.scrollTimeout) clearTimeout(this.scrollTimeout);
    if (this.resizeTimeOut) clearTimeout(this.resizeTimeOut);
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}
</style>
