import { render, staticRenderFns } from "./MarketTickerTFEX.vue?vue&type=template&id=51e4bb37&scoped=true&"
import script from "./MarketTickerTFEX.vue?vue&type=script&lang=js&"
export * from "./MarketTickerTFEX.vue?vue&type=script&lang=js&"
import style0 from "./MarketTickerTFEX.vue?vue&type=style&index=0&id=51e4bb37&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51e4bb37",
  null
  
)

export default component.exports