const values = {
  1: "Gross",
  2: "Net"
};

const getMarginMethodByKey = key => {
  return values[key] || key;
};

export { getMarginMethodByKey };
