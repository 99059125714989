var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('sui-modal',{attrs:{"size":"large"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('sui-modal-header',[_c('span',[_vm._v("Order Detail")]),_c('div',{staticClass:"close",on:{"click":_vm.close}},[_c('i',{staticClass:"close grey icon"})])]),_c('div',{staticStyle:{"clear":"float"}}),_c('sui-modal-content',[_c('div',{staticClass:"ui six column grid"},_vm._l((_vm.rows),function(r,index){return _c('div',{key:index,staticClass:"row"},[_vm._l((r),function(c,index){return [_c('div',{key:'key_' + index,staticClass:"column",class:c.keyClasses},[_c('h5',{staticClass:"ui header inverted",class:{ grey: !c.valueStyles },style:(c.keyStyles)},[_vm._v(" "+_vm._s(c.key)+" ")])]),_c('div',{key:'value_' + index,staticClass:"column",class:c.valueClasses},[_c('h5',{staticClass:"ui header inverted",class:{ blue: !c.valueStyles },style:(c.valueStyles)},[_vm._v(" "+_vm._s(c.value || "-")+" ")])])]})],2)}),0),_c('br'),(_vm.market === 'SET')?_c('div',{staticStyle:{"height":"100px"}},[_c('ag-grid-vue',{class:{
            'ag-theme-balham-dark': _vm.theme === 'Dark',
            'ag-theme-balham': _vm.theme === 'Light',
          },staticStyle:{"width":"100%","height":"100%","padding":"0px"},attrs:{"column-defs":_vm.SETAdvanceColumnDefs,"row-data":_vm.advanceRowData,"row-height":21,"header-height":32,"default-col-def":{ resizable: true },"animate-rows":true,"suppress-drag-leave-hides-columns":false,"enable-cell-change-flash":true,"locale-text":{ noRowsToShow: `Don't have any Condition.` }},on:{"grid-ready":_vm.onSETAdvanceGridReady,"row-data-changed":_vm.onSETAdvanceDataChange}})],1):(_vm.market === 'TFEX')?_c('div',{staticStyle:{"height":"100px"}},[_c('ag-grid-vue',{class:{
            'ag-theme-balham-dark': _vm.theme === 'Dark',
            'ag-theme-balham': _vm.theme === 'Light',
          },staticStyle:{"width":"100%","height":"100%","padding":"0px"},attrs:{"column-defs":_vm.TFEXAdvanceColumnDefs,"row-data":_vm.advanceRowData,"row-height":21,"header-height":32,"default-col-def":{ resizable: true },"animate-rows":true,"suppress-drag-leave-hides-columns":false,"enable-cell-change-flash":true,"locale-text":{ noRowsToShow: `Don't have any Condition.` }},on:{"grid-ready":_vm.onTFEXAdvanceGridReady,"row-data-changed":_vm.onTFEXAdvanceDataChange}})],1):_vm._e(),_c('div',{staticStyle:{"height":"200px"}},[_c('ag-grid-vue',{class:{
            'ag-theme-balham-dark': _vm.theme === 'Dark',
            'ag-theme-balham': _vm.theme === 'Light',
          },staticStyle:{"width":"100%","height":"100%","padding":"0px"},attrs:{"column-defs":_vm.market === 'SET' ? _vm.SETActivityColumnDefs : _vm.ActivityColumnDefs,"row-data":_vm.activityRowData,"row-height":21,"header-height":32,"default-col-def":{ resizable: true },"animate-rows":true,"suppress-drag-leave-hides-columns":false,"enable-cell-change-flash":true,"locale-text":{ noRowsToShow: `Don't have any Activity.` }},on:{"grid-ready":_vm.onActivityGridReady,"row-data-changed":_vm.onActivityDataChange}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }