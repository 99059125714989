<template>
  <div class="trader-value">
    <div class="options-container">
      <div class="row">
        <sui-checkbox v-model="selectedMarket" radio value="SET" :disabled="!isEnableETS()" class="custom-dropdown">
          <h5 class="ui grey inverted tiny header">{{ "SET" }}</h5>
        </sui-checkbox>
        <sui-checkbox v-model="selectedMarket" radio value="TFEX" :disabled="!isEnableDTS()">
          <h5 class="ui grey inverted tiny header">{{ "TFEX" }}</h5>
        </sui-checkbox>
        <div class="trader-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Broker Volume" }}
          </h5>
          <span class="ht-text-value">{{ brokerVol }}</span>
        </div>
        <div class="trader-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "%Volume Mkt." }}
          </h5>
          <span class="ht-text-value">{{ brokerVolMktPct }}</span>
        </div>
        <div class="trader-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Broker Value" }}
          </h5>
          <span class="ht-text-value">{{ brokerVal }}</span>
        </div>
        <div class="trader-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "%Value Mkt." }}
          </h5>
          <span class="ht-text-value">{{ brokerValMktPct }}</span>
        </div>
      </div>
      <div class="row">
        <div class="trader-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Total Volume" }}
          </h5>
          <span class="ht-text-value">{{ totalVol }}</span>
        </div>
        <div class="trader-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "%Volume Brk." }}
          </h5>
          <span class="ht-text-value">{{ totalVolBrkPct }}</span>
        </div>
        <div class="trader-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Total Value" }}
          </h5>
          <span class="ht-text-value">{{ totalVal }}</span>
        </div>
        <div class="trader-value-detail">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "%Value Brk." }}
          </h5>
          <span class="ht-text-value">{{ totalValBrkPct }}</span>
        </div>
      </div>
    </div>
    <div class="ui grid content-grid">
      <div class="two column row content-container">
        <div class="sixteen wide computer twelve wide large screen column twelve wide widescreen monitor column left-side-container">
          <div class="trader-table-container">
            <ag-grid-vue
              class="trader-table"
              :class="{
                'ag-theme-balham-dark': theme === 'Dark',
                'ag-theme-balham': theme === 'Light',
              }"
              row-selection="single"
              row-model-type="infinite"
              :grid-options="gridTraderValueOptions"
              :column-defs="selectedMarket === 'SET' ? columnDefsETSTraderValue : columnDefsDTSTraderValue"
              :row-height="21"
              :header-height="32"
              :default-col-def="{ sortable: false, resizable: true }"
              :animate-rows="true"
              :suppress-drag-leave-hides-columns="true"
              :enable-cell-change-flash="true"
              :locale-text="{ noRowsToShow: `Don't have any Trader.` }"
              :row-drag-managed="false"
              :pinned-bottom-row-data="pinnedBottomRowTraderValue"
              overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
              @grid-ready="onTraderValueGridReady"
              @cell-focused="onCellTraderValueFocused"
            />
          </div>
        </div>

        <div class="four wide column large screen only right-side-container">
          <div class="row pie-chart-value-container">
            <PieChartValue mode="Trader" :data="chartData" />
          </div>
          <div class="row top-5-trader-container">
            <Top5Trader mode="Trader" :data="chartData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsETSTraderValue, columnDefsDTSTraderValue } from "@/components/grid/value/TraderValueColumnDefs.js";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

Object.freeze(columnDefsETSTraderValue);
Object.freeze(columnDefsDTSTraderValue);

import PieChartValue from "@/components/value/PieChartValue.vue";
import Top5Trader from "@/components/value/Top5Trader.vue";

export default {
  name: "TraderValue",
  components: {
    AgGridVue,
    PieChartValue,
    Top5Trader,
  },
  data: () => ({
    columnDefsETSTraderValue,
    columnDefsDTSTraderValue,
    gridTraderValueApi: null,
    columnTraderValueApi: null,
    rowTraderValue: [],
    pinnedBottomRowTraderValue: [
      {
        traderId: "Total",
        level: "",
        type: "0 Trader(s) Remaining...",
        boughtVal: "",
        boughtValPct: "",
        soldVal: "",
        soldValPct: "",
        totalVal: "",
        totalValBrkPct: "",
        vol: "",
        volPct: "",
        commission: "",
        commissionBrkPct: "",
      },
    ],
    selectedMarket: "SET",
    isEnableETS,
    isEnableDTS,
    brokerVol: "0",
    brokerVolMktPct: "0.00%",
    brokerVal: "0",
    brokerValMktPct: "0.00%",
    totalVol: "0",
    totalVolBrkPct: "0.00%",
    totalVal: "0",
    totalValBrkPct: "0.00%",
    BRRefId: null,
    chartData: {},
    recordCount: 100,
    gridParams: null,
    traderValueLastRecordId: -1,
  }),
  watch: {
    selectedMarket() {
      this.brokerVol = "0";
      this.brokerVolMktPct = "0.00%";
      this.brokerVal = "0";
      this.brokerValMktPct = "0.00%";
      this.totalVol = "0";
      this.totalVolBrkPct = "0.00%";
      this.totalVal = "0";
      this.totalValBrkPct = "0.00%";

      this.filterChanged();
    },
  },
  methods: {
    onValueStartRefresh() {
      if (!this.gridTraderValueApi) {
        return;
      }
      this.rowTraderValue = [];
      this.traderValueLastRecordId = -1;
      this.gridTraderValueApi.deselectAll();
      this.gridTraderValueApi.purgeInfiniteCache();
      this.$nextTick(() => {
        this.gridTraderValueApi.setInfiniteRowCount(0, false);
      });
    },
    filterChanged() {
      this.onValueStartRefresh();
    },
    BR05() {
      const msg = this.$messageFactory.createMessage("BR05");

      const trader = this.$_.find(this.traders, ["exchangeId", 1]);
      if (!trader?.traderCode) {
        this.$Swal.fire("Error", "User does not have SET account.", "error");
        this.setRowData();
        return;
      }
      msg.ownTid.set(trader.traderCode);

      msg.recordCount.set(this.recordCount);
      msg.lastRecordId.set(this.traderValueLastRecordId + 1);

      this.BRRefId = this.$shortid.generate();
      this.$ws.send(msg, this.BRRefId);
      if (this.gridTraderValueApi) {
        this.gridTraderValueApi.showLoadingOverlay();
      }
    },
    BR55() {
      const msg = this.$messageFactory.createMessage("BR55");

      const trader = this.$_.find(this.traders, ["exchangeId", 2]);
      if (!trader?.traderCode) {
        this.$Swal.fire("Error", "User does not have TFEX account.", "error");
        this.setRowData();
        return;
      }
      msg.ownTid.set(trader.traderCode);

      msg.recordCount.set(this.recordCount);
      msg.lastRecordId.set(this.traderValueLastRecordId + 1);

      this.BRRefId = this.$shortid.generate();
      this.$ws.send(msg, this.BRRefId);
      if (this.gridTraderValueApi) {
        this.gridTraderValueApi.showLoadingOverlay();
      }
    },
    updateRowTraderValue(trader) {
      if (this.BRRefId !== trader.refId) {
        return;
      }

      if (this.traderValueLastRecordId === -1) {
        this.chartData = trader;
        this.brokerVol = this.$numeral(trader.brokerVol).format("0,0");
        if (trader.brokerVolMktPct) {
          this.brokerVolMktPct = this.$numeral(trader.brokerVolMktPct).format("0,0.00") + "%";
        } else {
          this.brokerVolMktPct = "0%";
        }
        this.brokerVal = this.$numeral(trader.brokerVal).format("0,0");
        if (trader.brokerValMktPct) {
          this.brokerValMktPct = this.$numeral(trader.brokerValMktPct).format("0,0.00") + "%";
        } else {
          this.brokerValMktPct = "0%";
        }
        this.totalVol = this.$numeral(trader.totalVol).format("0,0");
        if (trader.totalVolBrkPct) {
          this.totalVolBrkPct = this.$numeral(trader.totalVolBrkPct).format("0,0.00") + "%";
        } else {
          this.totalVolBrkPct = "0%";
        }
        this.totalVal = this.$numeral(trader.totalVal).format("0,0");
        if (trader.totalValBrkPct) {
          this.totalValBrkPct = this.$numeral(trader.totalValBrkPct).format("0,0.00") + "%";
        } else {
          this.totalValBrkPct = "0%";
        }
      }

      if (trader.records.length === 0) {
        this.traderValueLastRecordId = -1;
      } else {
        this.rowTraderValue = this.rowTraderValue.concat(trader.records);
        this.traderValueLastRecordId = trader.records[trader.records.length - 1].recordId;
      }

      this.setRowData();
    },
    setRowData() {
      // take a slice of the total rows
      const rowsThisPage = this.rowTraderValue.slice(this.gridParams.startRow, this.gridParams.endRow);
      // if on or after the last page, work out the last row.
      let lastRow = -1;
      if (this.rowTraderValue.length === this.gridParams.endRow) {
        this.pinnedBottomRowTraderValue = [
          {
            traderId: "Total",
            level: "",
            type: this.rowTraderValue.length + " Trader(s) Remaining...",
            boughtVal: "",
            boughtValPct: "",
            soldVal: "",
            soldValPct: "",
            totalVal: "",
            totalValBrkPct: "",
            vol: "",
            volPct: "",
            commission: "",
            commissionBrkPct: "",
          },
        ];
        lastRow = this.rowTraderValue.length + 1;
      } else if (this.rowTraderValue.length < this.gridParams.endRow) {
        this.pinnedBottomRowTraderValue = [
          {
            traderId: "Total",
            level: "",
            type: this.rowTraderValue.length + " Trader(s)",
            boughtVal: "",
            boughtValPct: "",
            soldVal: "",
            soldValPct: "",
            totalVal: "",
            totalValBrkPct: "",
            vol: "",
            volPct: "",
            commission: "",
            commissionBrkPct: "",
          },
        ];
        lastRow = this.rowTraderValue.length;
      }

      if (this.rowTraderValue.length === 0) {
        this.gridTraderValueApi.showNoRowsOverlay();
        this.$EventBus.$emit("onValueStopRefresh");
      } else {
        if (this.gridTraderValueApi) {
          this.gridTraderValueApi.hideOverlay();
        }
        this.$EventBus.$emit("onValueStopRefresh");
      }
      // call the success callback
      this.gridParams.successCallback(rowsThisPage, lastRow);
    },
    onTraderValueGridReady(params) {
      this.gridTraderValueApi = params.api;
      this.columnTraderValueApi = params.columnApi;
      this.gridTraderValueApi.setDatasource({
        rowCount: null,
        getRows: (params) => {
          console.log("asking for " + params.startRow + " to " + params.endRow);
          this.gridTraderValueApi.showLoadingOverlay();

          this.gridParams = params;

          if (this.selectedMarket === "SET") {
            this.BR05();
          } else if (this.selectedMarket === "TFEX") {
            this.BR55();
          }
        },
      });
    },
    onCellTraderValueFocused(event) {
      this.gridTraderValueOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
    traders: get("model/systemRefData@traders"),
  },
  created() {
    this.gridTraderValueOptions = {};

    this.$EventBus.$on("ctinf/BS05", this.updateRowTraderValue);
    this.$EventBus.$on("ctinf/BS55", this.updateRowTraderValue);

    this.$EventBus.$on("onValueStartRefresh", this.onValueStartRefresh);
  },
  mounted() {
    this.onValueStartRefresh();
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/BS05", this.updateRowTraderValue);
    this.$EventBus.$off("ctinf/BS55", this.updateRowTraderValue);

    this.$EventBus.$off("onValueStartRefresh", this.onValueStartRefresh);
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
@import "~@/assets/styles/company/trader-value.scss";
</style>
