<template>
  <div class="customer-view">
    <sui-tab :menu="{ attached: 'top' }" class="customer-view-tab">
      <sui-tab-pane title="Customer List" attached="top">
        <CustomerList />
      </sui-tab-pane>
      <sui-tab-pane title="Have Call/Force" attached="top">
        <HaveCallForce />
      </sui-tab-pane>
      <sui-tab-pane title="Security/Series Position" attached="top">
        <SeriesPosition />
      </sui-tab-pane>
      <div class="refresh-button">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script setup>
import useCustomerView from "./hooks/useCustomerView";

import CustomerList from "@/components/customers/CustomerList.vue";
import HaveCallForce from "@/components/customers/HaveCallForce.vue";
import SeriesPosition from "@/components/customers/SeriesPosition.vue";

const { isLoading, onRefresh } = useCustomerView();
</script>

<style lang="scss" scoped>
.customer-view {
  height: 100%;

  .customer-view-tab {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .refresh-button {
      position: absolute;
      top: 4px;
      right: 0.25rem;

      .refresh.icon {
        color: #dcddde;
        font-size: 16px;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
