const values = {
  C: "Client",
  P: "Portfolio",
  M: "Mutual Fund",
  F: "Foreign",
  I: "Sub Client",
  S: "Sub Portfolio",
  U: "Sub Mutual Fund",
  O: "Sub Foreign"
};

const getCustomerTypeByKey = key => {
  return values[key] || key;
};

export { getCustomerTypeByKey };
