import { totalColorStyle, qtyColorStyle, ceilColorStyle } from "../Formatter.js";
import { getCommissionServiceTypeByKey } from "@/utils/CommissionServiceTypeFormatter.js";

const columnDefsCommissionTable = [
  {
    headerName: "Commission Type",
    field: "commType",
    width: 170,
    cellStyle: params => ({ ...totalColorStyle(params), "justify-content": "center" })
  },
  {
    headerName: "Max Value",
    field: "maxValue",
    width: 200,
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Commission Rate",
    field: "commRate",
    width: 170,
    cellStyle: totalColorStyle
  },
  {
    headerName: "Minimum Charge",
    field: "minChargeFlag",
    width: 170,
    cellStyle: params => ({ ...ceilColorStyle(params), "justify-content": "center" })
  }
];

const columnDefsCommissionGroup = [
  {
    headerName: "Security Group",
    field: "securityGroup",
    width: 135,
    cellStyle: totalColorStyle
  },
  {
    headerName: "Commission Type",
    field: "commType",
    width: 145,
    cellStyle: params => ({ ...totalColorStyle(params), "justify-content": "center" })
  },
  {
    headerName: "Flag",
    field: "commFlag",
    width: 55,
    cellStyle: params => ({ ...ceilColorStyle(params), "justify-content": "center" })
  },
  {
    headerName: "Service Type",
    field: "serviceType",
    width: 115,
    cellStyle: params => ({ ...ceilColorStyle(params), "justify-content": "center" })
  },
  {
    headerName: "Description",
    field: "serviceType",
    valueFormatter: key => getCommissionServiceTypeByKey(key.value),
    width: 110,
    cellStyle: params => ({ ...ceilColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Accum ID",
    field: "accumId",
    width: 100,
    cellStyle: params => ({ ...ceilColorStyle(params), "justify-content": "center" })
  },
  {
    headerName: "Minimum Charge (THB)",
    field: "minCharge",
    width: 195,
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Trading Fee (%)",
    field: "tradingFee",
    width: 150,
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Clearing Fee (%)",
    field: "clearingFee",
    width: 150,
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Settlement Fee (THB)",
    field: "atsFee",
    width: 180,
    cellStyle: qtyColorStyle
  }
];

export { columnDefsCommissionTable, columnDefsCommissionGroup };
