<template>
  <div
    class="ui input"
    :class="{ error: displayErrorText, disabled: disabled }"
    :data-tooltip="displayErrorText"
    :data-position="errorPosition"
    data-inverted="inverted"
  >
    <input
      ref="input"
      v-model="text"
      type="text"
      :style="inputStyle"
      :placeholder="placeholder"
      style="text-transform: uppercase"
      @keyup.enter="onEnter"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

let timeout;
export default {
  props: {
    value: {
      type: String,
    },
    market: {
      type: String,
      required: true,
    },
    fixedMarket: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    isValidate: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
    },
    errorPosition: {
      type: String,
      default: "top center",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputStyle: {
      type: String,
      default: "text-transform: uppercase;",
    },
    sync: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      text: null,
      displayErrorText: null,
    };
  },
  watch: {
    value(value) {
      this.text = this.value;
    },
    text(value) {
      if (value) {
        value = value.toUpperCase();
      }
      this.$emit("input", value);
    },
  },
  created() {
    if (this.sync) {
      this.$EventBus.$on("accountFreeText", this.onReceiveAccount);
    }
  },
  beforeDestroy() {
    if (this.sync) {
      this.$EventBus.$off("accountFreeText", this.onReceiveAccount);
    }
  },
  methods: {
    isDisabled() {
      return this.disabled;
    },
    clearValidate() {
      this.displayErrorText = null;
    },
    validate() {
      this.clearValidate();
      if (this.disabled) {
        return true;
      }

      if (this.required && !this.text) {
        this.displayErrorText = this.name + " is requried.";
        this.$emit("onErrorText", this.displayErrorText);
        return false;
      }

      return true;
    },
    customError(text) {
      this.displayErrorText = text;
      this.$emit("onErrorText", this.displayErrorText);
    },
    onFocus() {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        this.select();
      }, 100);
    },
    onBlur() {
      this.validate();
      this.$emit("blur");
    },
    onEnter(e) {
      const validate = this.validate();

      if (!this.text) {
        return this.$emit("enter", e);
      }

      if (!validate) {
        return;
      }

      let exchangeId;
      if (this.market === "SET") {
        exchangeId = 1;
      } else if (this.market === "TFEX") {
        exchangeId = 2;
      } else {
        this.$emit("input", this.text.toUpperCase());
        this.$nextTick(() => {
          this.$emit("enter", e);
        });
        return;
      }
      this.$EventBus.$emit("accountFreeText", this._uid, this.market, this.text.toUpperCase());
      this.$emit("input", this.text.toUpperCase());
      this.$nextTick(() => {
        this.$emit("enter", {
          text: this.text.toUpperCase(),
          value: exchangeId + "_" + this.text.toUpperCase(),
        });
      });
    },
    onReceiveAccount(uid, market, account) {
      if (uid !== this._uid && this.market === market) {
        this.text = account;
      }
    },
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
  },
};
</script>

<style scoped>
.ui.input input {
  color: #dcddde;
  background: #2f383f;
  padding: 0 0.25rem;
  font-weight: 700;
  height: 2rem;
}
.ui.input input:focus {
  color: #dcddde !important;
  background: #2f383f !important;
}
input:read-only {
  border: 0 !important;
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
</style>
