/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:57
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsReqMsg from "../WsReqMsg";

class AR53 extends WsReqMsg {
	constructor() {
		super(1);
		this._accountCode = new StringMessageBase();
	}
	
	get accountCode() {
		return this._accountCode;
	}
	
	set accountCode(accountCode) {
		this._accountCode = new StringMessageBase(accountCode);
	}
}

export default AR53;
