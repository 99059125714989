<template>
  <div style="cursor: pointer;" @click="onBBOPriceClicked">
    {{ params.data.topOffer }}
  </div>
</template>

<script>
export default {
  methods: {
    onBBOPriceClicked() {
      const { symbol, exchangeId, topOffer } = this.params.data;
      this.$EventBus.$emit("onBBOPriceClicked", symbol, exchangeId, "offer", topOffer);
    }
  }
};
</script>
