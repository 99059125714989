<template>
  <div class="portfolio-unrealize-chart" ref="portfolio-unrealize-chart">
    <sui-tab :menu="{ attached: 'top' }">
      <sui-tab-pane title="% Unrealized P/L" attached="top">
        <apexchart type="bar" :height="dynamicHeight" :options="chartOptions" :series="series" style="padding-left: 1rem" />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

export default {
  data: () => ({
    refId: null,
    isLoading: true,
    dynamicHeight: 0,
    series: [
      {
        name: "Unrealized",
        data: [0],
      },
    ],
    chartOptions: {
      theme: {
        mode: "dark",
      },
      fill: {
        type: "gradient",
      },
      grid: {
        borderColor: "#2F383F",
      },
      chart: {
        offsetY: 12,
        toolbar: {
          show: false,
          tools: {
            selection: false,
            zoom: false,
          },
        },
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 0,
                to: Number.MAX_SAFE_INTEGER,
                color: "#00aa00",
              },
              {
                from: Number.MIN_SAFE_INTEGER,
                to: 0,
                color: "#d91e18",
              },
            ],
          },
          columnWidth: "80%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        title: {
          text: "% Unrealized P/L",
        },
        labels: {
          formatter: function (y) {
            return y.toFixed(2) + "%";
          },
        },
        forceNiceScale: true,
      },
      xaxis: {
        type: "category",
        categories: [""],
        labels: {
          rotate: -90,
          show: true,
        },
      },
    },
  }),
  watch: {
    currentSelectedAccount() {
      this.AR02();
    },
  },
  created() {
    this.resizeTimeout = null;
    this.dynamicHeight = this.$vhToPx(20) - this.$vhToPx(0.75);

    this.AR02();

    this.$EventBus.$on("ctinf/AS02", this.updateETSUnrealPLChart);
    this.$EventBus.$on("ctinf/AS52", this.updateDTSUnrealPLChart);
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS02", this.updateETSUnrealPLChart);
    this.$EventBus.$off("ctinf/AS52", this.updateDTSUnrealPLChart);

    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        const chartContainer = this.$refs["portfolio-unrealize-chart"];

        this.dynamicHeight = chartContainer.clientHeight - 32 - 15;
      }, 200);
    },
    onRefresh() {
      this.AR02();
    },
    AR02() {
      this.isLoading = true;
      if (!this.currentSelectedAccount || this.currentSelectedAccount?.exchangeId == 0 || !this.currentSelectedAccount?.accountCode) {
        this.clearChartData();
        this.isLoading = false;
        return;
      }

      const msg = this.$messageFactory.createMessage("AR02");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);
      msg.exchangeId.set(this.currentSelectedAccount.exchangeId);

      this.refId = this.$shortid.generate();
      this.$ws.send(msg, this.refId);
    },
    clearChartData() {
      this.series = [
        {
          name: "Unrealized",
          data: [0],
        },
      ];
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          type: "category",
          categories: [""],
          labels: {
            rotate: -90,
            show: true,
          },
        },
      };
    },
    updateETSUnrealPLChart(position) {
      if (position.refId !== this.refId) return;

      this.isLoading = false;
      if (position.records.length === 0) {
        this.clearChartData();
      } else {
        this.series = [
          {
            name: "Unrealized",
            data: position.records
              .filter((record) => this.$numeral(record.unrealizedPercent).value())
              .map((record) => this.$numeral(record.unrealizedPercent).value()),
          },
        ];
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            type: "category",
            categories: position.records.filter((record) => this.$numeral(record.unrealizedPercent).value()).map((record) => record.symbol),
            labels: {
              rotate: -90,
              show: position.records.length <= 20,
            },
          },
        };

        if (this.series.data && this.series.data.length <= 3) {
          this.chartOptions.plotOptions.bar.columnWidth = "20%";
        } else if (this.series.data && this.series.data.length <= 5) {
          this.chartOptions.plotOptions.bar.columnWidth = "50%";
        } else {
          this.chartOptions.plotOptions.bar.columnWidth = "80%";
        }
      }
    },
    updateDTSUnrealPLChart(position) {
      this.isLoading = false;
      if (position.records.length === 0) {
        this.clearChartData();
      } else {
        this.series = [
          {
            name: "Unrealized",
            data: position.records
              .filter((record) => this.$numeral(record.unrealPercent).value())
              .map((record) => this.$numeral(record.unrealPercent).value()),
          },
        ];
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            type: "category",
            categories: position.records.filter((record) => this.$numeral(record.unrealPercent).value()).map((record) => record.seriesId),
            labels: {
              rotate: -90,
              show: position.records.length <= 20,
            },
          },
        };
        if (this.series.data && this.series.data.length <= 3) {
          this.chartOptions.plotOptions.bar.columnWidth = "20%";
        } else if (this.series.data && this.series.data.length <= 5) {
          this.chartOptions.plotOptions.bar.columnWidth = "50%";
        } else {
          this.chartOptions.plotOptions.bar.columnWidth = "80%";
        }
      }
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
  },
};
</script>

<style lang="scss" scoped>
.apexcharts-yaxis-title,
.apexcharts-yaxis-texts-g,
.apexcharts-xaxis-texts-g {
  color: #dcddde !important;
  font-weight: 700 !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
  font-size: 1rem !important;
}

.portfolio-unrealize-chart {
  height: 100%;
}
</style>
