/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class ON54 extends WsMsg {
	constructor() {
		super(3);
		this._orderId = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._side = new StringMessageBase();
		this._matchedVol = new StringMessageBase();
		this._publishedVol = new StringMessageBase();
		this._cancelVol = new StringMessageBase();
		this._price = new StringMessageBase();
		this._status = new StringMessageBase();
		this._specialOrderType = new StringMessageBase();
		this._sendDate = new StringMessageBase();
	}
	
	get orderId() {
		return this._orderId;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get side() {
		return this._side;
	}
	
	get matchedVol() {
		return this._matchedVol;
	}
	
	get publishedVol() {
		return this._publishedVol;
	}
	
	get cancelVol() {
		return this._cancelVol;
	}
	
	get price() {
		return this._price;
	}
	
	get status() {
		return this._status;
	}
	
	get specialOrderType() {
		return this._specialOrderType;
	}
	
	get sendDate() {
		return this._sendDate;
	}
}

export default ON54;
