<template>
  <div class="news-view">
    <sui-tab :menu="{ attached: 'top' }" class="news-tab">
      <sui-tab-pane title="News" attached="top">
        <StockNews />
      </sui-tab-pane>
      <sui-tab-pane title="Advertisement" attached="top">
        <Advertisement />
      </sui-tab-pane>
      <div class="refresh-icon-container" :class="{ loading: isLoading }">
        <i class="refresh alternate icon" style="color: #dcddde" @click="onClickRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script setup>
import useNewsView from "./hooks/useNewsView";

import StockNews from "@/components/news/StockNews.vue";
import Advertisement from "@/components/news/Advertisement.vue";

const { isLoading, onClickRefresh } = useNewsView();
</script>

<style scoped lang="scss">
@import "~@/assets/styles/news/news-view.scss";
</style>
