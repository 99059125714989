<template>
  <div class="progress-bar-information" ref="progress-bar-information">
    <sui-tab :menu="{ attached: 'top' }" class="progress-bar-information-tab">
      <sui-tab-pane :title="`${mode} Value`" attached="top" class="progress-bar-tab">
        <apexchart type="bar" :width="dynamicWidth" :height="dynamicHeight" :options="chartOptions" :series="series" :key="updateKey" />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

export default {
  name: "MarketStockGraph",
  components: {},
  props: ["mode", "data"],
  data: () => ({
    isLoading: false,
    dynamicHeight: 100,
    dynamicWidth: 100,
    updateKey: 0,
    series: [
      {
        name: "Buy",
        data: [0, 0],
      },
      {
        name: "Sell",
        data: [0, 0],
      },
      {
        name: "Other",
        data: [0, 0],
      },
    ],
    chartOptions: {
      theme: {
        mode: "dark",
      },
      grid: {
        borderColor: "#2F383F",
      },
      fill: {
        type: "gradient",
      },
      chart: {
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false,
          tools: {
            selection: false,
            zoom: false,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#2185d0", "#E03997", "#E4AF0A"],
      stroke: {
        width: 0,
        color: "#1B2025",
      },
      xaxis: {
        categories: ["%Value Mkt.", "Total Value"],
      },
      tooltip: {
        y: {
          formatter: (value, opts) => {
            let pct = "0%";
            if (opts.w.globals.seriesPercent[opts.seriesIndex]?.[opts.dataPointIndex]) {
              pct = opts.w.globals.seriesPercent[opts.seriesIndex][opts.dataPointIndex].toFixed(2) + "%";
            }
            return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ` (${pct})`;
          },
        },
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 0,
      },
    },
  }),
  watch: {
    data(data) {
      if (data.sides?.length) {
        if (this.mode === "Broker") {
          this.series = [
            {
              name: "Buy",
              data: [data.sides[0].val, data.sides[0].val],
            },
            {
              name: "Sell",
              data: [data.sides[1].val, data.sides[1].val],
            },
            {
              name: "Market",
              data: [
                this.$numeral(data.totalMktVal.toFixed(0)).value() -
                  (this.$numeral(data.sides[0].val.toFixed(0)).value() + this.$numeral(data.sides[1].val.toFixed(0)).value()),
                0,
              ],
            },
          ];
        } else if (this.mode === "Trader") {
          this.series = [
            {
              name: "Buy",
              data: [data.sides[0].val, data.sides[0].val],
            },
            {
              name: "Sell",
              data: [data.sides[1].val, data.sides[1].val],
            },
            {
              name: "Broker",
              data: [
                this.$numeral(data.totalBrkVal.toFixed(0)).value() -
                  (this.$numeral(data.sides[0].val.toFixed(0)).value() + this.$numeral(data.sides[1].val.toFixed(0)).value()),
                0,
              ],
            },
          ];
        }
      } else {
        this.series = [
          {
            name: "Buy",
            data: [0, 0],
          },
          {
            name: "Sell",
            data: [0, 0],
          },
          {
            name: "Other",
            data: [0, 0],
          },
        ];
      }
    },
  },
  created() {
    this.$EventBus.$on("onInformationStartRefresh", this.onInformationStartRefresh);
    this.$EventBus.$on("onInformationStopRefresh", this.onInformationStopRefresh);
    this.resizeTimeout = null;

    if (this.mode === "Broker") {
      this.chartOptions.xaxis = {
        categories: ["%Value Mkt.", "Total Value"],
      };
    } else if (this.mode === "Trader") {
      this.chartOptions.xaxis = {
        categories: ["%Value Brk.", "Total Value"],
      };
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    this.$EventBus.$off("onInformationStartRefresh", this.onInformationStartRefresh);
    this.$EventBus.$off("onInformationStopRefresh", this.onInformationStopRefresh);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onInformationStartRefresh");
    },
    onInformationStartRefresh() {
      this.isLoading = true;
    },
    onInformationStopRefresh() {
      this.isLoading = false;
    },
    onResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      this.dynamicHeight = 0;
      this.dynamicWidth = 0;

      this.resizeTimeout = setTimeout(() => {
        const chartContainer = this.$refs["progress-bar-information"];

        this.dynamicHeight = chartContainer.clientHeight - 32;
        this.dynamicWidth = chartContainer.clientWidth - 50;
        this.updateKey++;
      }, 200);
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
};
</script>

<style lang="scss" scoped>
.progress-bar-information {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .progress-bar-information-tab {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;

    &:deep(.ui.top) {
      width: 100%;
    }

    .progress-bar-tab {
      flex: 1;
      width: 100%;
    }
  }
}
.apexcharts-canvas.dark {
  background: #262d33 !important;
}

.item.active {
  background-color: #262d33 !important;
}

.ui.attached.menu.top {
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
  min-height: unset !important;
}

a.item {
  height: 32px !important;
  padding: 0px 1rem !important;
  font-weight: 800 !important;
  color: #dcddde !important;
}

a.item > span {
  padding: 0.25rem !important;
}

a.item.active > span {
  color: #c88841 !important;
  border-radius: 0.5rem !important;
  padding: 0.25rem !important;
  border: 1px solid #c88841 !important;
}

.ui.tab.segment.attached.active.top {
  background-color: #262d33 !important;
  border: 0px !important;
  padding: 0px !important;
  /* margin-top: -8px !important; */
}
</style>
