class WebSocketConverter {
  constructor(messageFactory) {
    this.messageFactory = messageFactory;
  }

  systemRefDataReq() {
    return this.messageFactory.createMessage("XR01");
  }

  priceLastTradeReq() {
    let obj = this.messageFactory.createMessage("PR01");
    obj.lastTradeCount.set("25");
    return obj;
  }

  makeRequestMarketList(name, keys) {
    let keyArr = keys.split("_");
    let SR03 = this.messageFactory.createMessage("SR03");
    SR03.securityIds.set(name);
    SR03.listType.set(keyArr[0]);
    SR03.securityTypes.set("S");
    SR03.exchangeId.set(1);
    SR03.listId.set(keyArr[1]);
    return SR03;
  }
}

export default WebSocketConverter;
