export const DEFAULT_SHORTKEYS = [
  [
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 49,
      keyName: "Alt + 1",
      funcName: "View Market"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 50,
      keyName: "Alt + 2",
      funcName: "View Portfolio"
    },
    {
      shift: false,
      alt: true,
      ctrl: true,
      window: false,
      command: false,
      keyCode: 79,
      keyName: "Ctrl + Alt + O",
      funcName: "View Orders"
    },
    {
      shift: false,
      alt: true,
      ctrl: true,
      window: false,
      command: false,
      keyCode: 68,
      keyName: "Ctrl + Alt + D",
      funcName: "View Deals"
    },
    {
      shift: false,
      alt: true,
      ctrl: true,
      window: false,
      command: false,
      keyCode: 67,
      keyName: "Ctrl + Alt + C",
      funcName: "View Commission"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 51,
      keyName: "Alt + 3",
      funcName: "View Quote"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 52,
      keyName: "Alt + 4",
      funcName: "View BBOs"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 53,
      keyName: "Alt + 5",
      funcName: "View Ticker"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 54,
      keyName: "Alt + 6",
      funcName: "View Technical"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 55,
      keyName: "Alt + 7",
      funcName: "View News"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 56,
      keyName: "Alt + 8",
      funcName: "View Index"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 57,
      keyName: "Alt + 9",
      funcName: "View Customers"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 112,
      keyName: "Alt + F1",
      funcName: "View Information"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 113,
      keyName: "Alt + F2",
      funcName: "View Value"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 114,
      keyName: "Alt + F3",
      funcName: "View Broker Ranking"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 111,
      keyName: "Numpad /",
      funcName: "Buy Order"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 109,
      keyName: "Numpad -",
      funcName: "Sell Order"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 45,
      keyName: "Ins",
      funcName: "Toggle Advance Order"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 107,
      keyName: "+",
      funcName: "Toggle Order Exanpanded Mode"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 36,
      keyName: "Home",
      funcName: "Toggle More Menu"
    }
  ],
  // options 2
  [
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 49,
      keyName: "Shift + 1",
      funcName: "View Market"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 50,
      keyName: "Shift + 2",
      funcName: "View Portfolio"
    },
    {
      shift: false,
      alt: true,
      ctrl: true,
      window: false,
      command: false,
      keyCode: 79,
      keyName: "Ctrl + Alt + O",
      funcName: "View Orders"
    },
    {
      shift: false,
      alt: true,
      ctrl: true,
      window: false,
      command: false,
      keyCode: 68,
      keyName: "Ctrl + Alt + D",
      funcName: "View Deals"
    },
    {
      shift: false,
      alt: true,
      ctrl: true,
      window: false,
      command: false,
      keyCode: 67,
      keyName: "Ctrl + Alt + C",
      funcName: "View Commission"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 51,
      keyName: "Shift + 3",
      funcName: "View Quote"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 52,
      keyName: "Shift + 4",
      funcName: "View BBOs"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 53,
      keyName: "Shift + 5",
      funcName: "View Ticker"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 54,
      keyName: "Shift + 6",
      funcName: "View Technical"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 55,
      keyName: "Shift + 7",
      funcName: "View News"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 56,
      keyName: "Shift + 8",
      funcName: "View Index"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 57,
      keyName: "Shift + 9",
      funcName: "View Customers"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 112,
      keyName: "Shift + F1",
      funcName: "View Information"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 113,
      keyName: "Shift + F2",
      funcName: "View Value"
    },
    {
      shift: true,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 114,
      keyName: "Shift + F3",
      funcName: "View Broker Ranking"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 111,
      keyName: "Numpad /",
      funcName: "Buy Order"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 109,
      keyName: "Numpad -",
      funcName: "Sell Order"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 45,
      keyName: "Ins",
      funcName: "Toggle Advance Order"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 107,
      keyName: "+",
      funcName: "Toggle Order Exanpanded Mode"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 36,
      keyName: "Home",
      funcName: "Toggle More Menu"
    }
  ],
  // options 3
  [
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 112,
      keyName: "F1",
      funcName: "View Market"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 113,
      keyName: "F2",
      funcName: "View Portfolio"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 79,
      keyName: "Alt + O",
      funcName: "View Orders"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 68,
      keyName: "Alt + D",
      funcName: "View Deals"
    },
    {
      shift: false,
      alt: true,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 67,
      keyName: "Alt + C",
      funcName: "View Commission"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 114,
      keyName: "F3",
      funcName: "View Quote"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 115,
      keyName: "F4",
      funcName: "View BBOs"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 116,
      keyName: "F5",
      funcName: "View Ticker"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 117,
      keyName: "F6",
      funcName: "View Technical"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 118,
      keyName: "F7",
      funcName: "View News"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 119,
      keyName: "F8",
      funcName: "View Index"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 120,
      keyName: "F9",
      funcName: "View Customers"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 121,
      keyName: "F10",
      funcName: "View Information"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 122,
      keyName: "F11",
      funcName: "View Value"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 123,
      keyName: "F12",
      funcName: "View Broker Ranking"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 144,
      keyName: "&#128274;",
      funcName: "Buy Order"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 109,
      keyName: "Numpad -",
      funcName: "Sell Order"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 45,
      keyName: "Ins",
      funcName: "Toggle Advance Order"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 107,
      keyName: "+",
      funcName: "Toggle Order Exanpanded Mode"
    },
    {
      shift: false,
      alt: false,
      ctrl: false,
      window: false,
      command: false,
      keyCode: 36,
      keyName: "Home",
      funcName: "Toggle More Menu"
    }
  ]
];
