import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  priceColorStyle,
  valueColorStyle,
} from "../Formatter.js";

const columnDefsETSQuoteStockInPlay = [
  {
    headerName: "#Buy Deals",
    field: "buyCount",
    width: 120,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Bought Volume",
    field: "buyVolume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Bids Volume",
    field: "bidVolume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Price",
    field: "price",
    width: 120,
    cellStyle: priceColorStyle,
  },
  {
    headerName: "Offers Volume",
    field: "offerVolume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Sold Volume",
    field: "sellVolume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "#Sell Deals",
    field: "sellCount",
    width: 120,
    cellStyle: totalColorStyle,
  },
];

const columnDefsDTSQuoteStockInPlay = [
  {
    headerName: "#Long Deals",
    field: "buyCount",
    width: 120,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Bought Qty",
    field: "buyVolume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Bids Qty",
    field: "bidVolume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Price",
    field: "price",
    width: 120,
    cellStyle: priceColorStyle,
  },
  {
    headerName: "Offers Qty",
    field: "offerVolume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Sold Qty",
    field: "sellVolume",
    width: 150,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "#Short Deals",
    field: "sellCount",
    width: 120,
    cellStyle: totalColorStyle,
  },
];

export { columnDefsETSQuoteStockInPlay, columnDefsDTSQuoteStockInPlay };
