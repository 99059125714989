const values = {
  Y: "Mutual",
  C: "Cash Not Buy",
  M: "Margin Not Buy",
  T: " "
};

const getMutualFlagByKey = key => {
  return values[key] || "Normal";
};

export { getMutualFlagByKey };
