const values = {
  " ": "Normal",
  V: "VIP",
  N: "VIP (Basic)",
  F: "Normal, Trader Level Lower Than Owner Can View (Same Route)"
};

const getVIPFlagByKey = key => {
  if (Number(values)) {
    return "VIP Has Option " + Number(values);
  }
  return values[key] || key;
};

export { getVIPFlagByKey };
