class StringMessageBase {
  constructor(defaultValue) {
    this.value = "";
    if (defaultValue) {
      this.value += defaultValue;
    }
  }

  set(value) {
    this.value = value + "";
  }

  get() {
    return this.value;
  }

  getTrim() {
    return this.value.trim();
  }
}

export default StringMessageBase;
