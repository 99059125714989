import { make } from "vuex-pathify";

const settings = localStorage.getItem("settings");

let temptSetting = { fontSize: 1 };

if (settings) temptSetting = JSON.parse(settings);

const global = () => {
  const state = {
    theme: "Dark",

    /* Account */
    currentAccount: null,
    pin: null,
    useMyCustomer: true,
    modeOrder: false,

    /* Marketwatch */
    isFavoriteMode: false,
    currentMaketWatchList: [],
    currentQuoteSelectedSymbol: null,
    tempCurrentQuoteSelectedSymbol: {},
    currentSelectedAccount: null,
    currentSelectedMode: "SET",
    currentSelectedStockGroupSET: "",
    currentSelectedStockGroupTFEX: "",
    currentSelectedFavorite: null,
    currentDisplayMarketWatchList: [],

    /* Trader */
    traderType: null,

    /* BBOs */
    currentBBOTable: [
      [{}, {}, {}],
      [{}, {}, {}],
      [{}, {}, {}],
      [{}, {}, {}],
    ],

    /* Ticker */
    tickerFilterSET: true,
    tickerFilterTFEX: false,
    tickerFilterFavorite: false,
    tickerFilterModeSum: false,

    /* Securities */
    etsSecurities: {},
    dtsSecurities: {},
    settings: temptSetting,
  };
  const mutations = make.mutations(state);
  const actions = make.actions(state);
  const getters = make.getters(state);

  return { namespaced: true, state, mutations, actions, getters };
};

export default global;
