import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle
} from "../Formatter.js";

const columnDefsQuoteNews = [
  {
    headerName: "Date",
    field: "datetime",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 100,
    valueGetter: params => {
      if (params.data.datetime) {
        return params.data.datetime.split(" ")[0];
      }
    },
    cellStyle: params => ({ ...totalColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Time",
    field: "datetime",
    pinned: "left",
    width: 100,
    valueGetter: params => {
      if (params.data.datetime) {
        return params.data.datetime.split(" ")[1];
      }
    },
    cellStyle: params => ({ ...totalColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Symbol",
    field: "symbol",
    pinned: "left",
    width: 150,
    cellStyle: params => ({ ...qtyColorStyle(params), "justify-content": "flex-start" })
  },
  {
    headerName: "Headline",
    field: "headline",
    width: 595,
    autoHeight: true,
    cellStyle: params => ({ ...ceilColorStyle(params), "justify-content": "flex-start", "white-space": "normal" })
  }
];

export { columnDefsQuoteNews };
