var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ black: _vm.theme === 'Dark' },style:({ height: '100%' })},[_c('div',{staticClass:"ui grid",style:({ height: '100%' })},[_c('div',{staticClass:"four columns row",staticStyle:{"padding-bottom":"0px"}},_vm._l((4),function(_,column){return _c('div',{key:column,staticClass:"column",style:([
          _vm.theme === 'Light' ? { 'border-right': '0px solid #000000' } : { 'border-right': '0px solid #000000' },
          column === 0 ? { 'padding-right': '0px' } : {},
          column === 1 ? { padding: '0px' } : {},
          column === 2 ? { padding: '0px' } : {},
          column === 3 ? { 'padding-left': '0px' } : {},
          { height: `${_vm.tickerFullPageHeight}px` },
          _vm.theme === 'Light' ? { 'border-top': '0px solid #000000' } : { 'border-top': '0px solid #000000' },
          { height: '100%' },
        ])},[_c('table',{staticClass:"ui very compact small single line unstackable striped table",class:{ inverted: _vm.theme === 'Dark' },staticStyle:{"border":"0px","border-radius":"0px"}},[_c('thead',[_c('TickerHeader',{attrs:{"hide-symbol":""}})],1),_c('tbody',_vm._l((_vm.arrayTickerColumn[column]),function(ticker,index){return _c('tr',{key:index,class:{
                active: index === _vm.lastPosition - (_vm.maximunTickers / 4) * column - 1,
              }},[_c('TickerBody',{attrs:{"ticker":ticker,"hide-symbol":""}})],1)}),0)])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }