<template>
  <div>
    <sui-modal v-model="dialog" size="large">
      <sui-modal-header>
        <span>Order Detail</span>
        <div class="close" @click="close"><i class="close grey icon"></i></div>
      </sui-modal-header>
      <div style="clear: float"></div>
      <sui-modal-content>
        <div class="ui six column grid">
          <div v-for="(r, index) in rows" :key="index" class="row">
            <template v-for="(c, index) in r">
              <div :key="'key_' + index" class="column" :class="c.keyClasses">
                <h5 class="ui header inverted" :class="{ grey: !c.valueStyles }" :style="c.keyStyles">
                  {{ c.key }}
                </h5>
              </div>
              <div :key="'value_' + index" class="column" :class="c.valueClasses">
                <h5 class="ui header inverted" :class="{ blue: !c.valueStyles }" :style="c.valueStyles">
                  {{ c.value || "-" }}
                </h5>
              </div>
            </template>
          </div>
        </div>
        <br />
        <div v-if="market === 'SET'" style="height: 100px">
          <ag-grid-vue
            style="width: 100%; height: 100%; padding: 0px"
            :class="{
              'ag-theme-balham-dark': theme === 'Dark',
              'ag-theme-balham': theme === 'Light',
            }"
            :column-defs="SETAdvanceColumnDefs"
            :row-data="advanceRowData"
            :row-height="21"
            :header-height="32"
            :default-col-def="{ resizable: true }"
            :animate-rows="true"
            :suppress-drag-leave-hides-columns="false"
            :enable-cell-change-flash="true"
            :locale-text="{ noRowsToShow: `Don't have any Condition.` }"
            @grid-ready="onSETAdvanceGridReady"
            @row-data-changed="onSETAdvanceDataChange"
          />
        </div>
        <div v-else-if="market === 'TFEX'" style="height: 100px">
          <ag-grid-vue
            style="width: 100%; height: 100%; padding: 0px"
            :class="{
              'ag-theme-balham-dark': theme === 'Dark',
              'ag-theme-balham': theme === 'Light',
            }"
            :column-defs="TFEXAdvanceColumnDefs"
            :row-data="advanceRowData"
            :row-height="21"
            :header-height="32"
            :default-col-def="{ resizable: true }"
            :animate-rows="true"
            :suppress-drag-leave-hides-columns="false"
            :enable-cell-change-flash="true"
            :locale-text="{ noRowsToShow: `Don't have any Condition.` }"
            @grid-ready="onTFEXAdvanceGridReady"
            @row-data-changed="onTFEXAdvanceDataChange"
          />
        </div>
        <div style="height: 200px">
          <ag-grid-vue
            style="width: 100%; height: 100%; padding: 0px"
            :class="{
              'ag-theme-balham-dark': theme === 'Dark',
              'ag-theme-balham': theme === 'Light',
            }"
            :column-defs="market === 'SET' ? SETActivityColumnDefs : ActivityColumnDefs"
            :row-data="activityRowData"
            :row-height="21"
            :header-height="32"
            :default-col-def="{ resizable: true }"
            :animate-rows="true"
            :suppress-drag-leave-hides-columns="false"
            :enable-cell-change-flash="true"
            :locale-text="{ noRowsToShow: `Don't have any Activity.` }"
            @grid-ready="onActivityGridReady"
            @row-data-changed="onActivityDataChange"
          />
        </div>
      </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import SETAdvanceColumnDefs from "@/components/grid/orderDetail/SETAdvanceColumnDefs";
import TFEXAdvanceColumnDefs from "@/components/grid/orderDetail/TFEXAdvanceColumnDefs";
import ActivityColumnDefs from "@/components/grid/orderDetail/ActivityColumnDefs";
import SETActivityColumnDefs from "@/components/grid/orderDetail/SETActivityColumnDefs";
import {
  ttfFormatter,
  positionFormatter,
  sideFormatter,
  sideColorStyle,
  boardFormatter,
  conditionFormatter,
  orderStatusFormatter,
} from "@/components/grid/Formatter";

export default {
  components: {
    AgGridVue,
  },
  data() {
    return {
      SETAdvanceColumnDefs,
      TFEXAdvanceColumnDefs,
      ActivityColumnDefs,
      SETActivityColumnDefs,
      dialog: false,
      rows: [],
      advanceRowData: [],
      activityRowData: [],
      setGridAdvanceApi: null,
      tfexGridAdvanceApi: null,
      setColumnAdvanceApi: null,
      tfexColumnAdvanceApi: null,
      gridActivityApi: null,
      columnActivityApi: null,
      market: "SET",
      mergeCount: 0,
      tempActivitys: [],
      tempDeals: [],
      dealRefId: null,
    };
  },
  created() {
    this.$EventBus.$on("orderDetail", this.onOrderDetial);

    this.$EventBus.$on("ctinf/OS05", this.etsOrderDetialCtinf);
    this.$EventBus.$on("ctinf/OS06", this.etsOrderAuditCtinf);
    this.$EventBus.$on("ctinf/DS01", this.etsDealQueryCtinf);
    this.$EventBus.$on("ctinf/OS08", this.etsOrderAdvanceCtinf);
    this.$EventBus.$on("ctinf/OS55", this.dtsOrderDetialCtinf);
    this.$EventBus.$on("ctinf/OS56", this.dtsOrderAuditCtinf);
    this.$EventBus.$on("ctinf/OS58", this.dtsOrderAdvanceCtinf);
    this.$EventBus.$on("ctinf/DS51", this.dtsDealQueryCtinf);
  },
  beforeDestroy() {
    this.$EventBus.$off("orderDetail", this.onOrderDetial);

    this.$EventBus.$off("ctinf/OS05", this.etsOrderDetialCtinf);
    this.$EventBus.$off("ctinf/OS06", this.etsOrderAuditCtinf);
    this.$EventBus.$off("ctinf/DS01", this.etsDealQueryCtinf);
    this.$EventBus.$off("ctinf/OS08", this.etsOrderAdvanceCtinf);
    this.$EventBus.$off("ctinf/OS55", this.dtsOrderDetialCtinf);
    this.$EventBus.$off("ctinf/OS56", this.dtsOrderAuditCtinf);
    this.$EventBus.$off("ctinf/OS58", this.dtsOrderAdvanceCtinf);
    this.$EventBus.$off("ctinf/DS51", this.dtsDealQueryCtinf);
  },
  methods: {
    mergeActivity() {
      this.mergeCount = 0;
      let mergeData = this.tempActivitys.concat(this.tempDeals);
      mergeData = this.$_.orderBy(
        mergeData,
        [
          (o) => {
            return this.$moment(o.time, "HH:mm:ss").valueOf();
          },
        ],
        ["asc"]
      );
      this.activityRowData = mergeData;
    },
    onSETAdvanceDataChange() {
      if (this.setColumnAdvanceApi) {
        let allColumnIds = [];
        this.setColumnAdvanceApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.setColumnAdvanceApi.autoSizeColumns(allColumnIds);
      }
    },
    onTFEXAdvanceDataChange() {
      if (this.tfexColumnAdvanceApi) {
        let allColumnIds = [];
        this.tfexColumnAdvanceApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.tfexColumnAdvanceApi.autoSizeColumns(allColumnIds);
      }
    },
    onActivityDataChange() {
      if (this.columnActivityApi) {
        let allColumnIds = [];
        this.columnActivityApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.columnActivityApi.autoSizeColumns(allColumnIds);
      }
    },
    close() {
      this.dialog = false;
    },
    onSETAdvanceGridReady(params) {
      this.setGridAdvanceApi = params.api;
      this.setColumnAdvanceApi = params.columnApi;
    },
    onTFEXAdvanceGridReady(params) {
      this.tfexGridAdvanceApi = params.api;
      this.tfexColumnAdvanceApi = params.columnApi;
    },
    onActivityGridReady(params) {
      this.gridActivityApi = params.api;
      this.columnActivityApi = params.columnApi;
    },
    OR05(sendDate, orderId) {
      const msg = this.$messageFactory.createMessage("OR05");
      msg.sendDate = sendDate;
      msg.orderId = orderId;
      this.$ws.send(msg);
    },
    OR06(sendDate, orderId) {
      const msg = this.$messageFactory.createMessage("OR06");
      msg.sendDate = sendDate;
      msg.orderId = orderId;
      this.$ws.send(msg);
    },
    OR08(sendDate, orderId) {
      const msg = this.$messageFactory.createMessage("OR08");
      msg.sendDate = sendDate;
      msg.orderId = orderId;
      this.$ws.send(msg);
    },
    DR01(sendDate, orderId) {
      const msg = this.$messageFactory.createMessage("DR01");
      msg.sendDate = sendDate;
      msg.orderId = orderId;

      this.dealRefId = this.$shortid.generate();
      this.$ws.send(msg, this.dealRefId);
    },
    OR55(sendDate, orderId) {
      const msg = this.$messageFactory.createMessage("OR55");
      msg.sendDate = sendDate;
      msg.orderId = orderId;
      this.$ws.send(msg);
    },
    OR56(sendDate, orderId) {
      const msg = this.$messageFactory.createMessage("OR56");
      msg.sendDate = sendDate;
      msg.orderId = orderId;
      this.$ws.send(msg);
    },
    OR58(sendDate, orderId) {
      const msg = this.$messageFactory.createMessage("OR58");
      msg.sendDate = sendDate;
      msg.orderId = orderId;
      this.$ws.send(msg);
    },
    DR51(sendDate, orderId) {
      const msg = this.$messageFactory.createMessage("DR51");
      msg.sendDate = sendDate;
      msg.orderId = orderId;
      this.dealRefId = this.$shortid.generate();
      this.$ws.send(msg, this.dealRefId);
    },
    onOrderDetial(market, sendDate, orderId) {
      this.market = market;
      this.rows = [];
      this.advanceRowData = [];
      this.activityRowData = [];
      this.dialog = true;
      const newSendDate = this.$moment(sendDate, "DD/MM/YYYY").format("YYYYMMDD");
      if (market === "SET") {
        this.OR05(newSendDate, orderId);
        this.OR06(newSendDate, orderId);
        this.OR08(newSendDate, orderId);
        this.DR01(newSendDate, orderId);
      } else if (market === "TFEX") {
        this.OR55(newSendDate, orderId);
        this.OR56(newSendDate, orderId);
        this.OR58(newSendDate, orderId);
        this.DR51(newSendDate, orderId);
      }
    },
    etsOrderDetialCtinf(data) {
      if (data.resultCode !== 1001) {
        console.error(data.resultCode + ": " + data.resultMessage);
        return;
      }
      console.log("test data", data);
      const rows = [
        [
          {
            key: "Order No",
            value: data.orderId,
          },
          {
            key: "Side",
            value: sideFormatter(
              {
                value: data.side,
              },
              "SET"
            ),
            valueStyles: sideColorStyle({
              value: data.side,
            }),
          },
          {
            key: "Entry Time",
            value: data.entrytime,
          },
        ],
        [
          {
            key: "Volume",
            value: data.volume,
          },
          {
            key: "Price",
            value: data.price,
          },
          {
            key: "Board",
            value: boardFormatter({
              value: data.board,
            }),
          },
        ],
        [
          {
            key: "Account",
            value: data.account,
          },
          {
            key: "Matched",
            value: data.matchedVol,
          },
          {
            key: "Published",
            value: data.publishedVol,
          },
        ],
        [
          {
            key: "Iceberg",
            value: data.icebergVol,
          },
          {
            key: "Flag",
            value: ttfFormatter({
              value: data.ttf,
            }),
            valueClasses: "eight wide",
          },
        ],
        [
          {
            key: "Trader",
            value: data.trader,
          },
          {
            key: "Entry Trader",
            value: data.entrytrader,
          },
          {
            key: "Cond.",
            value: conditionFormatter({
              value: data.cond,
            }),
          },
        ],
        [
          {
            key: "Quote",
            value: data.quote,
          },
          {
            key: "Quote Time",
            value: data.quoteTime,
          },
          {
            key: "PC",
            value: data.pc,
          },
        ],
        [
          {
            key: "Orig Price",
            value: data.origPrice,
          },
          {
            key: "Approver",
            value: data.approver,
          },
          {
            key: "Status",
            value: orderStatusFormatter({
              value: data.status,
              data: data,
            }),
          },
        ],
        [
          {
            key: "Credit",
            value: data.credit,
          },
          {
            key: "Terminal",
            value: data.terminal,
          },
          {
            key: "Sbk Cust ID",
            value: data.sbkCustId,
          },
        ],
        [
          {
            key: "Cancel Trader",
            value: data.cancelTraderId,
          },
          {
            key: "Cancel Time",
            value: data.cancelTime,
          },
          {
            key: "Position Type",
            value: data.positionType,
          },
        ],
        [
          {
            key: "Basket/Batch No",
            value: data.bbNo,
          },
          {
            key: "Special Order Type",
            value: data.specialOrderType,
          },
          {
            key: "DMA",
            value: data.dma,
          },
        ],
        [
          {
            key: "Trading Channel",
            value: data.tradeChanel,
          },
          {
            key: "Web Server IP",
            value: data.webServerIp,
          },
          {
            key: "ISP Assigned IP",
            value: data.ispAssidnIp,
          },
        ],
        [
          {
            key: "Client IP",
            value: data.clientIpFld,
          },
          {
            key: "Send Date",
            value: data.sendDate,
          },
          {
            key: "SET Order ID",
            value: data.setOrdId,
          },
        ],
        [
          {
            key: "Validity",
            value: data.validity,
          },
          {
            key: "Validity Data",
            value: data.validityData,
          },
        ],
        [
          {
            key: "Customer name",
            value: data.custName,
            classes: "three row",
            valueClasses: "thirteen wide",
          },
        ],
        [
          {
            key: "Remark",
            value: data.remark,
            classes: "three row",
            valueClasses: "thirteen wide",
          },
        ],
      ];
      this.rows = rows;
    },
    etsOrderAdvanceCtinf(data) {
      if (data.resultCode === 1001) {
        if (data.specialOrderType === "S") {
          // Stop Order
          const stopColumns = ["symbol", "stopType", "stopValue", "refValue", "cond", "oper"];
          const traillingColumns = ["traillingType", "traillingAmount", "basedOn", "step", "interval"];
          this.setColumnAdvanceApi.setColumnsVisible([...stopColumns], true);
          this.setColumnAdvanceApi.setColumnsVisible([...traillingColumns], false);
          if (data.condition1 && data.condition1 !== "0") {
            this.advanceRowData.push({
              symbol: data.tickerSec1,
              stopType: data.advType1,
              stopValue: data.advVal1,
              refValue: data.refPrice1,
              cond: data.condition1,
              oper: data.operation,
            });
          }
          if (data.condition2 && data.condition2 !== "0") {
            this.advanceRowData.push({
              symbol: data.tickerSec2,
              stopType: data.advType2,
              stopValue: data.advVal2,
              refValue: data.refPrice2,
              cond: data.condition2,
              oper: data.operation,
            });
          }
          if (data.condition3 && data.condition3 !== "0") {
            this.advanceRowData.push({
              symbol: data.tickerSec3,
              stopType: data.advType3,
              stopValue: data.advVal3,
              refValue: data.refPrice3,
              cond: data.condition3,
              oper: data.operation,
            });
          }
        } else if (data.specialOrderType === "T") {
          // Trailling Order
          const stopColumns = ["symbol", "stopType", "stopValue", "refValue", "cond", "oper"];
          const traillingColumns = ["traillingType", "traillingAmount", "basedOn", "step", "interval"];
          this.setColumnAdvanceApi.setColumnsVisible([...stopColumns], false);
          this.setColumnAdvanceApi.setColumnsVisible([...traillingColumns], true);
          this.advanceRowData.push({
            traillingType: data.advType1,
            traillingAmount: data.advVal1,
            basedOn: data.condition1,
            step: data.advStep,
            interval: data.advIntervalTime,
          });
        }
      } else {
        console.error(data.resultCode + ": " + data.resultMessage);
      }
    },
    etsOrderAuditCtinf(data) {
      if (data.resultCode === 1001) {
        this.tempActivitys = data.records.map((o, idx) => Object.assign({ index: idx }, o));
        this.mergeCount++;
        if (this.mergeCount === 2) {
          this.mergeActivity();
        }
      } else {
        console.error(data.resultCode + ": " + data.resultMessage);
      }
    },
    etsDealQueryCtinf(data) {
      if (this.dealRefId !== data.refId) {
        return;
      }
      if (data.resultCode === 1001) {
        this.tempDeals = data.records.map((o, idx) => {
          let newData = Object.assign({ index: idx }, o);
          return {
            time: newData.time,
            action: newData.side,
            traderId: newData.traderId,
            price: newData.price,
            volume: newData.volume,
            confirmNo: newData.confirmNo,
          };
        });
        this.mergeCount++;
        if (this.mergeCount === 2) {
          this.mergeActivity();
        }
      } else {
        console.error(data.resultCode + ": " + data.resultMessage);
      }
    },
    dtsOrderDetialCtinf(data) {
      if (data.resultCode !== 1001) {
        console.error(data.resultCode + ": " + data.resultMessage);
        return;
      }
      const rows = [
        [
          {
            key: "Order No",
            value: data.orderId,
          },
          {
            key: "Side",
            value: sideFormatter(
              {
                value: data.side,
              },
              "TFEX"
            ),
            valueStyles: sideColorStyle({
              value: data.side,
            }),
          },
          {
            key: "Position",
            value: positionFormatter({
              value: data.position,
            }),
          },
        ],
        [
          {
            key: "Series ID",
            value: data.symbol,
          },
          {
            key: "Volume",
            value: data.volume,
          },
          {
            key: "Price",
            value: data.price,
          },
        ],
        [
          {
            key: "Account",
            value: data.account,
          },
          {
            key: "Matched",
            value: data.matchedVol,
          },
          {
            key: "Published",
            value: data.publishedVol,
          },
        ],
        [
          {
            key: "Iceberg",
            value: data.icebergVol,
          },
          {
            key: "Quote",
            value: data.quote,
          },
          {
            key: "Quote Time",
            value: data.quoteTime,
          },
        ],
        [
          {
            key: "Trader",
            value: data.trader,
          },
          {
            key: "Entry Trader",
            value: data.entrytrader,
          },
          {
            key: "Entry Time",
            value: data.entrytime,
          },
        ],
        [
          {
            key: "Board",
            value: data.board,
          },
          {
            key: "PC",
            value: data.pc,
          },
        ],
        [
          {
            key: "Status",
            value: data.status,
          },
          {
            key: "Credit",
            value: data.credit,
          },
          {
            key: "Terminal",
            value: data.terminal,
          },
        ],
        [
          {
            key: "Web Server IP",
            value: data.webServerIp,
          },
          {
            key: "ISP Assigned IP",
            value: data.ispAssidnIp,
          },
          {
            key: "Client IP",
            value: data.clientIpFld,
          },
        ],
        [
          {
            key: "Service Type",
            value: data.serviceType,
          },
          {
            key: "Send Date",
            value: data.sendDate,
          },
          {
            key: "Validity",
            value: data.validity,
          },
        ],
        [
          {
            key: "Clearing Member",
            value: data.clearingMember,
          },
          {
            key: "Org Entry Date",
            value: data.origOrdDate,
          },
          {
            key: "Validity Date",
            value: data.validityDate,
          },
        ],
        [
          {
            key: "TFEX Order ID",
            value: data.setOrdId,
            valueClasses: "eight wide",
          },
          {
            key: "Validity Data",
            value: data.validityData,
          },
        ],
        [
          {
            key: "Customer name",
            value: data.custName,
            classes: "three row",
            valueClasses: "thirteen wide",
          },
        ],
      ];
      this.rows = rows;
    },
    dtsOrderAdvanceCtinf(data) {
      if (data.resultCode === 1001) {
        this.advanceRowData = [
          {
            series: data.stopSymbol,
            flag: data.stopOrdStat,
            value: data.stopPrice,
            cond: data.stopCondition,
          },
        ];
      } else {
        console.error(data.resultCode + ": " + data.resultMessage);
      }
    },
    dtsOrderAuditCtinf(data) {
      if (data.resultCode === 1001) {
        this.tempActivitys = data.records.map((o, idx) => {
          const newData = Object.assign({ index: idx }, o);
          newData.from = "audit";
          return newData;
        });
        this.mergeCount++;
        if (this.mergeCount === 2) {
          this.mergeActivity();
        }
      } else {
        console.error(data.resultCode + ": " + data.resultMessage);
      }
    },
    dtsDealQueryCtinf(data) {
      if (this.dealRefId !== data.refId) {
        return;
      }
      if (data.resultCode === 1001) {
        this.tempDeals = data.records.map((o, idx) => {
          let newData = Object.assign({ index: idx }, o);
          return {
            time: newData.time,
            action: newData.side,
            traderId: newData.tradeId,
            price: newData.price,
            volume: newData.volume,
            from: "deal",
          };
        });
        this.mergeCount++;
        if (this.mergeCount === 2) {
          console.log(this.tempDeals);
          this.mergeActivity();
        }
      } else {
        console.error(data.resultCode + ": " + data.resultMessage);
      }
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
};
</script>

<style scoped>
.ui.grid > .row {
  padding-bottom: 0.25rem !important;
}
</style>
