/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class BS55 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._brokerVol = new NumberMessageBase();
		this._brokerVolMktPct = new NumberMessageBase();
		this._brokerVal = new NumberMessageBase();
		this._brokerValMktPct = new NumberMessageBase();
		this._totalVol = new NumberMessageBase();
		this._totalVolBrkPct = new NumberMessageBase();
		this._totalVal = new NumberMessageBase();
		this._totalValBrkPct = new NumberMessageBase();
		this._commission = new NumberMessageBase();
		this._records = new ArrayMessageBase(Record);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get brokerVol() {
		return this._brokerVol;
	}
	
	get brokerVolMktPct() {
		return this._brokerVolMktPct;
	}
	
	get brokerVal() {
		return this._brokerVal;
	}
	
	get brokerValMktPct() {
		return this._brokerValMktPct;
	}
	
	get totalVol() {
		return this._totalVol;
	}
	
	get totalVolBrkPct() {
		return this._totalVolBrkPct;
	}
	
	get totalVal() {
		return this._totalVal;
	}
	
	get totalValBrkPct() {
		return this._totalValBrkPct;
	}
	
	get commission() {
		return this._commission;
	}
	
	get records() {
		return this._records;
	}
}

export class Record {
	constructor() {
		this._recordId = new NumberMessageBase();
		this._traderId = new StringMessageBase();
		this._type = new StringMessageBase();
		this._level = new NumberMessageBase();
		this._vol = new NumberMessageBase();
		this._volPct = new NumberMessageBase();
		this._boughtVal = new NumberMessageBase();
		this._boughtValPct = new NumberMessageBase();
		this._soldVal = new NumberMessageBase();
		this._soldValPct = new NumberMessageBase();
		this._totalVal = new NumberMessageBase();
		this._totalValBrkPct = new NumberMessageBase();
		this._commission = new NumberMessageBase();
		this._commissionBrkPct = new NumberMessageBase();
	}
	
	get recordId() {
		return this._recordId;
	}
	
	get traderId() {
		return this._traderId;
	}
	
	get type() {
		return this._type;
	}
	
	get level() {
		return this._level;
	}
	
	get vol() {
		return this._vol;
	}
	
	get volPct() {
		return this._volPct;
	}
	
	get boughtVal() {
		return this._boughtVal;
	}
	
	get boughtValPct() {
		return this._boughtValPct;
	}
	
	get soldVal() {
		return this._soldVal;
	}
	
	get soldValPct() {
		return this._soldValPct;
	}
	
	get totalVal() {
		return this._totalVal;
	}
	
	get totalValBrkPct() {
		return this._totalValBrkPct;
	}
	
	get commission() {
		return this._commission;
	}
	
	get commissionBrkPct() {
		return this._commissionBrkPct;
	}
}

export default BS55;
