/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class MB01 extends WsMsg {
	constructor() {
		super(2);
		this._marketId = new NumberMessageBase();
		this._mktStCode = new StringMessageBase();
	}
	
	get marketId() {
		return this._marketId;
	}
	
	get mktStCode() {
		return this._mktStCode;
	}
}

export default MB01;
