<template>
  <div
    class="ui input"
    :class="{ error: displayErrorText, disabled: disabled }"
    :data-tooltip="displayErrorText"
    :data-position="errorPosition"
    data-inverted="inverted"
  >
    <input
      ref="input"
      v-model="text"
      :type="type"
      :style="inputStyle"
      :class="{ uppercase: uppercase }"
      :placeholder="placeholder"
      :autocomplete="type === 'password' ? 'new-password' : null"
      :autofocus="autofocus"
      @blur="onBlur"
      @keyup.enter="onEnter"
      @keydown="onKeyDown"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
    isValidate: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
    },
    errorPosition: {
      type: String,
      default: "top center",
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    inputStyle: {
      type: String,
      default: "text-transform: uppercase;",
    },
  },
  data() {
    return {
      type: "text",
      text: null,
      displayErrorText: null,
    };
  },
  watch: {
    value(value) {
      this.text = value;
      this.validate();
    },
    text(value) {
      let newValue = value;
      if (newValue && this.uppercase) {
        newValue = newValue.toUpperCase();
      }
      this.$emit("input", newValue);
    },
    errorText(value) {
      this.displayErrorText = value;
    },
  },
  created() {
    if (!this.value) {
      this.text = this.value;
    }
    if (!this.errorText) {
      this.displayErrorText = this.errorText;
    }
  },
  methods: {
    isDisabled() {
      return this.disabled;
    },
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    clearValidate() {
      this.displayErrorText = null;
    },
    validate() {
      this.clearValidate();
      if (this.disabled) {
        return true;
      }

      if (this.required && !this.text) {
        this.displayErrorText = this.name + " is requried.";
        this.$emit("onErrorText", this.displayErrorText);
        return false;
      }

      return true;
    },
    customError(text) {
      this.displayErrorText = text;
      this.$emit("onErrorText", this.displayErrorText);
    },
    onEnter(e) {
      const validate = this.validate();
      if (!validate) {
        return;
      }

      this.$emit("input", this.text);
      this.$nextTick(() => {
        this.$emit("enter", e);
      });
    },
    onBlur() {
      this.validate();
    },
    onKeyDown(event) {
      if (event.key === "Enter") event.preventDefault();
    },
  },
};
</script>

<style scoped>
.ui.input input {
  color: #dcddde;
  background: #2f383f;
  padding: 0 0.25rem;
  font-weight: 700;
  height: 2rem;
}
.ui.input input:focus {
  color: #dcddde !important;
  background: #2f383f !important;
}
.uppercase {
  text-transform: uppercase;
}
input:read-only {
  border: 0 !important;
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
</style>
