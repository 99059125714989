import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
} from "../Formatter.js";

const columnDefsDTSCredit = [
  {
    headerName: "Market ID",
    field: "symbol",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 200,
    cellStyle: (params) => ({ ...unrealizedColorStyle(params), "justify-content": "flex-start" }),
  },
  {
    headerName: "Previous",
    field: "positionType",
    width: 100,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Previous IM",
    field: "onHandVol",
    width: 180,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Previous MM",
    field: "sellableVol",
    width: 180,
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Previous FM",
    field: "averagePrice",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Previous Inter-Com",
    field: "marketPrice",
    width: 160,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Current",
    field: "costValue",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Current IM",
    field: "marketValue",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Current MM",
    field: "marketValue",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Current FM",
    field: "marketValue",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Current Inter-Com",
    field: "marketValue",
    width: 160,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Expected",
    field: "marketValue",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Expected IM",
    field: "marketValue",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Expected MM",
    field: "marketValue",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Expected FM",
    field: "marketValue",
    width: 150,
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Expected Inter-Com",
    field: "marketValue",
    width: 170,
    cellStyle: totalColorStyle,
  },
];

export { columnDefsDTSCredit };
