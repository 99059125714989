import { ref, computed, onBeforeMount, onBeforeUnmount, onMounted, getCurrentInstance, nextTick, watch } from "vue";
import store from "@/store";
import { SecurityItem } from "@/messages/websocket/XR03";
import { isEnableDTS } from "@/utils/LicenseUtils";

export default function useQuoteView() {
  const vm = getCurrentInstance().proxy;
  const isMarketStockGraphIntradayLoading = ref(true);
  const isMarketStockGraphBuySellLoading = ref(true);
  const marketInfoSectionHeight = ref(170);
  const quoteInfoSectionHeight = ref(0);

  const currentQuoteSelectedSymbol = computed(() => store.getters["global/currentQuoteSelectedSymbol"]);
  const orderEntryModeOrder = computed(() => store.getters["global/modeOrder"]);

  const onMarketGraphIntradayRefresh = (refreshValue) => () => {
    isMarketStockGraphIntradayLoading.value = refreshValue;
  };

  const onMarketGraphBuySellRefresh = (refreshValue) => () => {
    isMarketStockGraphBuySellLoading.value = refreshValue;
  };

  const refreshMarketStockGraphIntraday = () => {
    vm.$EventBus.$emit("onMarketGraphIntradayStartRefresh");
  };

  const refreshMarketStockGraphBuySell = () => {
    vm.$EventBus.$emit("onMarketGraphBuySellStartRefresh");
  };

  const onSwitchLevel = (_isShowAll) => {
    nextTick(() => {
      const marketInfoSection = vm.$refs.marketInfoSection;
      const quoteInfoSection = vm.$refs.quoteInfoSection;

      quoteInfoSection.style.height = `calc(55vh - 5px - ${marketInfoSection.getBoundingClientRect().height}px)`;
      vm.$EventBus.$emit("market-bbo-size-change");
    });
  };

  const XR03 = () => {
    // bbos
    const pb21Msg = vm.$messageFactory.createMessage("XR03");
    const pb22Msg = vm.$messageFactory.createMessage("XR03");

    //PriceLastTradeBcst
    const pb01Msg = vm.$messageFactory.createMessage("XR03");

    //MarketStatus
    const mb03Msg = vm.$messageFactory.createMessage("XR03");

    pb21Msg.messageCode.set("PB21");
    pb22Msg.messageCode.set("PB22");
    pb01Msg.messageCode.set("PB01");
    mb03Msg.messageCode.set("MB03");

    let resolvedSecurityList = [];

    if (currentQuoteSelectedSymbol.value) {
      const securityItem = new SecurityItem();
      securityItem.securityId = Number(currentQuoteSelectedSymbol.value.value.securityId);

      resolvedSecurityList.push(securityItem);
    }

    if (!resolvedSecurityList.length) return;

    pb21Msg.securityList.set(resolvedSecurityList);
    pb22Msg.securityList.set(resolvedSecurityList);
    pb01Msg.securityList.set(resolvedSecurityList);
    mb03Msg.securityList.set(resolvedSecurityList);

    vm.$ws.send(pb21Msg);
    vm.$ws.send(pb22Msg);
    vm.$ws.send(pb01Msg);
    vm.$ws.send(mb03Msg);

    if (isEnableDTS()) {
      //bbos
      const pb52Msg = vm.$messageFactory.createMessage("XR03");
      //PriceLastTradeBcst
      const pb51Msg = vm.$messageFactory.createMessage("XR03");

      pb51Msg.messageCode.set("PB51");
      pb52Msg.messageCode.set("PB52");
      pb51Msg.securityList.set(resolvedSecurityList);
      pb52Msg.securityList.set(resolvedSecurityList);

      vm.$ws.send(pb51Msg);
      vm.$ws.send(pb52Msg);
    }
  };

  const unSubscribe = (code) => {
    const message = vm.$messageFactory.createMessage("XR03");

    message.messageCode.set(code);
    message.mode.set("D");
    vm.$ws.send(message);
  };

  const removeSubscription = () => {
    unSubscribe("PB21");
    unSubscribe("PB22");
    unSubscribe("PB01");
    unSubscribe("MB03");

    if (isEnableDTS()) {
      unSubscribe("PB52");
      unSubscribe("PB51");
    }
  };

  onBeforeMount(() => {
    vm.$EventBus.$on("onMarketGraphIntradayStartRefresh", onMarketGraphIntradayRefresh(true));
    vm.$EventBus.$on("onMarketGraphBuySellStartRefresh", onMarketGraphBuySellRefresh(true));
    vm.$EventBus.$on("onMarketGraphIntradayStopRefresh", onMarketGraphIntradayRefresh(false));
    vm.$EventBus.$on("onMarketGraphBuySellStopRefresh", onMarketGraphBuySellRefresh(false));
  });

  watch(currentQuoteSelectedSymbol, () => {
    if (currentQuoteSelectedSymbol.value) return XR03();

    return removeSubscription();
  });

  watch(orderEntryModeOrder, async () => {
    if (!orderEntryModeOrder.value) {
      await nextTick();

      if (currentQuoteSelectedSymbol.value) return XR03();
    }

    return removeSubscription();
  });

  onMounted(() => {
    onSwitchLevel();

    if (currentQuoteSelectedSymbol.value) {
      XR03();
    }
  });

  onBeforeUnmount(() => {
    removeSubscription();

    vm.$EventBus.$off("onMarketGraphIntradayStartRefresh", onMarketGraphIntradayRefresh(true));
    vm.$EventBus.$off("onMarketGraphBuySellStartRefresh", onMarketGraphBuySellRefresh(true));
    vm.$EventBus.$off("onMarketGraphIntradayStopRefresh", onMarketGraphIntradayRefresh(false));
    vm.$EventBus.$off("onMarketGraphBuySellStopRefresh", onMarketGraphBuySellRefresh(false));
  });

  return {
    isMarketStockGraphIntradayLoading,
    isMarketStockGraphBuySellLoading,
    marketInfoSectionHeight,
    quoteInfoSectionHeight,
    currentQuoteSelectedSymbol,
    onSwitchLevel,
    refreshMarketStockGraphIntraday,
    refreshMarketStockGraphBuySell,
  };
}
