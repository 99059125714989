<template>
  <div class="portfolio-table">
    <div class="portfolio-table-tabs" v-show="!currentSelectedAccount || currentSelectedAccount.exchangeId != '2'">
      <sui-tab :menu="{ attached: 'top' }" :active-index.sync="activeTab">
        <sui-tab-pane title="Position" attached="top">
          <PortfolioETSPositionTable :on-show="!currentSelectedAccount || currentSelectedAccount.exchangeId != '2'" />
        </sui-tab-pane>
        <sui-tab-pane title="Commission" attached="top">
          <PortfolioCommissionTable :on-show="!currentSelectedAccount || currentSelectedAccount.exchangeId != '2'" />
        </sui-tab-pane>
        <sui-tab-pane title="Customer Summary" attached="top">
          <PortfolioCustomerSummaryTable :on-show="!currentSelectedAccount || currentSelectedAccount.exchangeId != '2'" />
        </sui-tab-pane>
        <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
          <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
        </div>
      </sui-tab>
    </div>

    <div class="portfolio-table-tabs" v-show="currentSelectedAccount && currentSelectedAccount.exchangeId == 2">
      <sui-tab :menu="{ attached: 'top' }">
        <sui-tab-pane title="Position" attached="top">
          <PortfolioDTSPositionTable :on-show="currentSelectedAccount && currentSelectedAccount.exchangeId == 2" />
        </sui-tab-pane>
        <sui-tab-pane title="Credit" attached="top">
          <PortfolioCreditTable :on-show="currentSelectedAccount && currentSelectedAccount.exchangeId == 2" />
        </sui-tab-pane>
        <sui-tab-pane title="Customer Summary" attached="top"
          ><PortfolioCustomerSummaryTable :on-show="currentSelectedAccount && currentSelectedAccount.exchangeId == 2" />
        </sui-tab-pane>
        <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
          <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
        </div>
      </sui-tab>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

import PortfolioETSPositionTable from "./PortfolioETSPositionTable";
import PortfolioDTSPositionTable from "./PortfolioDTSPositionTable";
import PortfolioCreditTable from "./PortfolioCreditTable";
import PortfolioCommissionTable from "./PortfolioCommissionTable";
import PortfolioCustomerSummaryTable from "./PortfolioCustomerSummaryTable";

export default {
  components: {
    PortfolioETSPositionTable,
    PortfolioDTSPositionTable,
    PortfolioCreditTable,
    PortfolioCommissionTable,
    PortfolioCustomerSummaryTable,
  },
  computed: {
    currentSelectedAccount: get("global/currentSelectedAccount"),
  },
  data: () => ({
    isLoading: true,
    activeTab: 0,
  }),
  created() {
    if (this.$route.params?.funcName) {
      if (this.$route.params.funcName === "View Position") {
        // position
        this.activeTab = 0;
      } else if (this.$route.params.funcName === "View Commission") {
        // commission
        this.activeTab = 1;
      }
    }
    this.$EventBus.$on("receiveShortcut", this.onReceiveShortcut);

    this.$EventBus.$on("onPortfolioTableStartRefresh", this.onPortfolioTableStartRefresh);
    this.$EventBus.$on("onPortfolioTableStopRefresh", this.onPortfolioTableStopRefresh);
  },

  beforeDestroy() {
    this.$EventBus.$off("receiveShortcut", this.onReceiveShortcut);

    this.$EventBus.$off("onPortfolioTableStartRefresh", this.onPortfolioTableStartRefresh);
    this.$EventBus.$off("onPortfolioTableStopRefresh", this.onPortfolioTableStopRefresh);
  },
  methods: {
    onReceiveShortcut(funcName) {
      if (funcName === "View Position") {
        // position
        this.activeTab = 0;
      } else if (funcName === "View Commission") {
        // commission
        this.activeTab = 1;
      }
    },
    onRefresh() {
      this.$EventBus.$emit("onPortfolioTableStartRefresh");
    },
    onPortfolioTableStartRefresh() {
      this.isLoading = true;
    },
    onPortfolioTableStopRefresh() {
      this.isLoading = false;
    },
  },
};
</script>
<style scoped lang="scss">
.portfolio-table {
  height: 100%;

  .portfolio-table-tabs {
    height: 100%;

    div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .ui.tab {
      height: calc(100% - 32px) !important;
    }
  }
}
</style>
