<template>
  <div class="pt-deal-modal">
    <sui-modal v-model="dialog" size="small">
      <sui-modal-header>
        <span>{{ "New Trade Report" }}</span>
        <div class="close" @click="close"><i class="close grey icon" /></div>
      </sui-modal-header>
      <div style="clear: float" />
      <sui-modal-content>
        <div class="ui form">
          <div class="inline fields">
            <label class="ui grey inverted tiny header">{{ "Market" }}</label>
            <div class="field">
              <sui-checkbox v-model="selectedMarket" radio value="SET" :disabled="!isEnableETS()">
                <h4 class="ui grey inverted tiny header">{{ "SET" }}</h4>
              </sui-checkbox>
            </div>
            <div class="field">
              <sui-checkbox v-model="selectedMarket" radio value="TFEX" :disabled="!isEnableDTS()">
                <h4 class="ui grey inverted tiny header">{{ "TFEX" }}</h4>
              </sui-checkbox>
            </div>
          </div>
        </div>
        <PTDealModalSET ref="PTDealModalSET" v-if="selectedMarket === 'SET'" />
        <PTDealModalTFEX ref="PTDealModalTFEX" v-if="selectedMarket === 'TFEX'" />
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button type="button" positive :loading="processing" :disabled="processing" @click="onSendClicked">Send</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

import PTDealModalTFEX from "@/components/modal/PTDealModalTFEX";
import PTDealModalSET from "@/components/pt-deal/PTDealModalSET.vue";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

export default {
  components: {
    PTDealModalSET,
    PTDealModalTFEX,
  },
  data: () => ({
    dialog: false,

    selectedMarket: "SET",
    isEnableETS,
    isEnableDTS,

    processing: false,
  }),
  watch: {
    currentSelectedMode(currentSelectedMode) {
      if (currentSelectedMode === "SET" || currentSelectedMode === "TFEX") {
        this.selectedMarket = currentSelectedMode;
      }
    },
    selectedMarket(selectedMarket) {
      this.currentSelectedMode = selectedMarket;
    },
  },
  created() {
    this.$EventBus.$on("ptdealOpen", this.open);
    this.$EventBus.$on("ptdealClose", this.close);
  },
  beforeDestroy() {
    this.$EventBus.$off("ptdealOpen", this.open);
    this.$EventBus.$off("ptdealClose", this.close);
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    onSendClicked() {
      if (this.selectedMarket === "SET") {
        const modalSET = this.$refs.PTDealModalSET.$el;
        const submitButton = modalSET.querySelector(".submit-button");

        submitButton.click();
      } else if (this.selectedMarket === "TFEX") {
        this.$refs.PTDealModalTFEX.onSendClicked();
      }
    },
  },
  computed: {
    theme: get("global/theme"),
    currentSelectedMode: sync("global/currentSelectedMode"),
  },
};
</script>
