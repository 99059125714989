import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store/";

// External Lib
import numeral from "numeral";
import axios from "axios";
import _ from "lodash";
import * as shortid from "shortid";
import moment from "moment";
import { Settings } from "luxon";

// External Component
import Swal from "sweetalert2";
import VeeValidate from "vee-validate";
import VueApexCharts from "vue-apexcharts";
import VueFriendlyIframe from "vue-friendly-iframe";
import locale from "element-ui/lib/locale";
import lang from "element-ui/lib/locale/lang/en";
locale.use(lang);
import Fragment from "vue-fragment";
import VueScrollTo from "vue-scrollto";

// Internal Web Socket Core
import * as WebSocket from "@/core/WebSocket";
import WebSocketConverter from "@/core/WebSocketConverter";
import MessageFactory from "./messages/websocket/MessageFactory";

// Css Framework
import SuiVue from "semantic-ui-vue";
import PortalVue from "portal-vue";
import vSelectMenu from "v-selectmenu";
import VueLazyload from "vue-lazyload";

//Utils
import { EventBus } from "@/event-bus.js";
import { vhToPx, vwToPx, remToPx } from "@/utils/CSSUnitConverter.js";

//setting-timezone
Settings.defaultZone = "Asia/Bangkok";

//Global Config
// axios.defaults.timeout = 1000;
axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
Vue.config.productionTip = false;
Vue.config.silent = true;
Vue.config.devtools = process.env.NODE_ENV === "development";
Vue.config.performance = process.env.NODE_ENV === "development";

//Add to vue
Vue.prototype.$http = axios;
Vue.prototype.$numeral = numeral;
Vue.prototype.$_ = _;
Vue.prototype.$Swal = Swal;
Vue.prototype.$vhToPx = vhToPx;
Vue.prototype.$vwToPx = vwToPx;
Vue.prototype.$remToPx = remToPx;

Vue.prototype.$ws = WebSocket;
Vue.prototype.$wsConverter = new WebSocketConverter(MessageFactory);
Vue.prototype.$messageFactory = MessageFactory;
Vue.prototype.$shortid = shortid;
Vue.prototype.$moment = moment;
Vue.prototype.$EventBus = EventBus;
if (process.env.NODE_ENV === "production") {
  console.log = () => null;
}

Vue.use(VueLazyload);
Vue.use(SuiVue);
Vue.use(PortalVue);
Vue.use(vSelectMenu);
Vue.use(VeeValidate);
Vue.use(VueApexCharts);
Vue.use(Fragment.Plugin);
Vue.component("apexchart", VueApexCharts);
Vue.use(VueFriendlyIframe);
Vue.use(VueScrollTo);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
