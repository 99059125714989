<template>
  <div style="width: 100%; height: 100%;">
    <form class="ui form" @submit.prevent="submit">
      <div class="field">
        <label>Keyboard Option</label>
        <div class="fields">
          <div class="six wide field">
            <KeyboardOptionDropdown ref="optionDropdown" v-model="option" />
          </div>
          <div class="ten wide field">
            <button type="button" class="ui mini grey button" :class="{ loading: processing, disabled: processing }" @click="reset">
              Reset
            </button>
          </div>
        </div>
      </div>
    </form>
    <SettingKeyboardTable v-model="selectedKeyboardShortcut" />
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import KeyboardOptionDropdown from "@/components/KeyboardOptionDropdown";
import SettingKeyboardTable from "./SettingKeyboardTable";
import { DEFAULT_SHORTKEYS } from "@/shortkeys";

export default {
  components: {
    KeyboardOptionDropdown,
    SettingKeyboardTable
  },
  data() {
    return {
      processing: false,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      defaultOptions: [],
      options: [],
      option: 0,
      selectedKeyboardShortcut: []
    };
  },
  watch: {
    option(value) {
      this.selectOption();
      this.$refs.optionDropdown.forceValue(value);
    }
  },
  created() {
    this.init();
    this.$EventBus.$on("onShortcut", this.onShortcut);
  },
  beforeDestroy() {
    this.$EventBus.$off("onShortcut", this.onShortcut);
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    init() {
      this.defaultOptions = DEFAULT_SHORTKEYS;
      if (this.shortcuts) {
        this.options = [...this.shortcuts];
      } else {
        this.options = [...this.defaultOptions];
      }

      const index = localStorage.getItem("keyboardShortcutIndex");
      if (index) {
        this.option = parseInt(index);
      }
      this.selectOption();
    },
    resetForm() {},
    selectOption() {
      this.selectedKeyboardShortcut = this.options[this.option];
    },
    reset() {
      this.selectedKeyboardShortcut = this.defaultOptions[this.option];
    },
    onShortcut(newParams) {
      const newShortcuts = this.$_.map(this.selectedKeyboardShortcut, (p, i) => {
        if (p.funcName === newParams.funcName) {
          return newParams;
        } else {
          return p;
        }
      });
      this.selectedKeyboardShortcut = newShortcuts;
    },
    save() {
      return new Promise((resolve, reject) => {
        this.options[this.option] = [...this.selectedKeyboardShortcut];
        this.shortcuts = [...this.options];
        this.currentShortcut = [...this.selectedKeyboardShortcut];

        localStorage.setItem("keyboardShortcuts", JSON.stringify(this.shortcuts));
        localStorage.setItem("keyboardShortcutIndex", this.option);

        resolve();
      });
    }
  },
  computed: {
    theme: sync("global/theme"),
    shortcuts: sync("model/shortcuts"),
    currentShortcut: sync("model/currentShortcut"),
    accounts: get("model/systemRefData@accounts")
  }
};
</script>

<style scoped>
.my-button {
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  /* border: 1px solid #666666; */
  color: #666666;
  cursor: pointer;
  background-color: #262d33;
}
.my-button h4 {
  padding: 0;
  line-height: 30px;
}
</style>
