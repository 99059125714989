/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class SS10 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._exchangeId = new StringMessageBase();
		this._securities = new ArrayMessageBase(Security);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get exchangeId() {
		return this._exchangeId;
	}
	
	get securities() {
		return this._securities;
	}
}

export class Security {
	constructor() {
		this._symbol = new StringMessageBase();
		this._average = new StringMessageBase();
		this._high = new StringMessageBase();
		this._low = new StringMessageBase();
		this._last = new StringMessageBase();
		this._volume = new StringMessageBase();
		this._changeVolPercent = new StringMessageBase();
		this._buyPercent = new StringMessageBase();
		this._high5Days = new StringMessageBase();
		this._low5Days = new StringMessageBase();
		this._averagePrice5Days = new StringMessageBase();
		this._averageVol5Days = new StringMessageBase();
		this._outPerformPercent = new StringMessageBase();
		this._prior = new StringMessageBase();
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get average() {
		return this._average;
	}
	
	get high() {
		return this._high;
	}
	
	get low() {
		return this._low;
	}
	
	get last() {
		return this._last;
	}
	
	get volume() {
		return this._volume;
	}
	
	get changeVolPercent() {
		return this._changeVolPercent;
	}
	
	get buyPercent() {
		return this._buyPercent;
	}
	
	get high5Days() {
		return this._high5Days;
	}
	
	get low5Days() {
		return this._low5Days;
	}
	
	get averagePrice5Days() {
		return this._averagePrice5Days;
	}
	
	get averageVol5Days() {
		return this._averageVol5Days;
	}
	
	get outPerformPercent() {
		return this._outPerformPercent;
	}
	
	get prior() {
		return this._prior;
	}
}

export default SS10;
