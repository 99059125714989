<script>
import FilterFloatingComponent from "@/components/grid/FilterFloatingComponent";
import { getBySymbol } from "@/utils/SymbolUtils";

export default {
  extends: FilterFloatingComponent,
  methods: {
    onEnter() {
      let valueToUse = !this.displayValue ? null : this.displayValue;
      const filters = this.params.api.getFilterModel();
      const oldFilter = filters[this.params.filterParams.colDef.field];
      if (oldFilter) {
        const oldFilterStr = JSON.parse(oldFilter.filter).symbol;
        if (oldFilterStr === valueToUse) {
          return;
        }
      } else {
        if (!valueToUse) {
          return;
        }
      }
      if (valueToUse) {
        let secsObj = getBySymbol(valueToUse);
        if (secsObj) {
          secsObj.symbol = valueToUse;
        } else {
          secsObj = {
            symbol: valueToUse,
          };
        }
        valueToUse = JSON.stringify(secsObj);
      }
      this.params.filterParams.context.resetFilter = true;
      this.params.api.deselectAll();
      this.params.api.purgeInfiniteCache();
      this.params.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged("equals", valueToUse);
      });
    },
    onParentModelChanged(parentModel) {
      if (parentModel?.filter) {
        try {
          const obj = JSON.parse(parentModel.filter);
          this.displayValue = obj.symbol;
        } catch (err) {
          this.displayValue = parentModel.filter;
        }
      } else {
        this.displayValue = "";
      }
    },
  },
};
</script>
