/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class MB02 extends WsMsg {
	constructor() {
		super(2);
		this._marketId = new NumberMessageBase();
		this._up = new StringMessageBase();
		this._down = new StringMessageBase();
		this._unchange = new StringMessageBase();
		this._buyValue = new StringMessageBase();
		this._sellValue = new StringMessageBase();
		this._openValue = new StringMessageBase();
		this._buyVolume = new StringMessageBase();
		this._sellVolume = new StringMessageBase();
		this._openVolume = new StringMessageBase();
		this._totalMktVal = new StringMessageBase();
	}
	
	get marketId() {
		return this._marketId;
	}
	
	get up() {
		return this._up;
	}
	
	get down() {
		return this._down;
	}
	
	get unchange() {
		return this._unchange;
	}
	
	get buyValue() {
		return this._buyValue;
	}
	
	get sellValue() {
		return this._sellValue;
	}
	
	get openValue() {
		return this._openValue;
	}
	
	get buyVolume() {
		return this._buyVolume;
	}
	
	get sellVolume() {
		return this._sellVolume;
	}
	
	get openVolume() {
		return this._openVolume;
	}
	
	get totalMktVal() {
		return this._totalMktVal;
	}
}

export default MB02;
