<template>
  <div class="trader-information" style="overflow: hidden">
    <div class="header-section">
      <div class="options-container">
        <sui-checkbox v-model="selectedMarket" radio value="SET" :disabled="!isEnableETS()" class="custom-checkbox">
          <h5 class="ui grey inverted tiny header">{{ "SET" }}</h5>
        </sui-checkbox>
        <sui-checkbox v-model="selectedMarket" radio value="TFEX" :disabled="!isEnableDTS()" class="custom-checkbox">
          <h5 class="ui grey inverted tiny header">{{ "TFEX" }}</h5>
        </sui-checkbox>
        <div class="trader-id">
          <h5 class="ui grey header inverted">{{ "Trader ID" }}</h5>
          <InputText
            v-model="traderID"
            class="ht-trader-id trader-id-input"
            style="height: 36px"
            name="Trader ID"
            placeholder="Trader ID"
            :uppercase="true"
            @enter="onEnter"
          />
        </div>
        <div class="view-as">
          <h5 class="ui grey header inverted">{{ "View as" }}</h5>
          <sui-dropdown v-model="selectedViewAs" fluid button pointing :options="viewAsOptions" class="ht-custlist-dropdown view-as-dropdown" />
        </div>
        <div class="trader-name">
          <h5 class="ui grey header inverted ht-text-header">{{ "Trader Name" }}</h5>
          <span class="ht-text-value">{{ traderName }}</span>
        </div>
        <div class="trader-title">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Title " }}
          </h5>
          <span class="ht-text-value">{{ traderTitle }}</span>
        </div>
      </div>
      <div class="trader-information-detail">
        <div class="trader-information-field">
          <h5 class="ui grey header inverted ht-text-header">{{ "Approval Over" }}</h5>
          <span class="ht-text-value">{{ approvalOver }}</span>
        </div>
        <div class="trader-information-field">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Level" }}
          </h5>
          <span class="ht-text-value">{{ level }}</span>
        </div>
        <div class="trader-information-field">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Supervisor" }}
          </h5>
          <span class="ht-text-value">{{ supervisor }}</span>
        </div>
        <div class="trader-information-field">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Value" }}
          </h5>
          <span class="ht-text-value">{{ value }}</span>
        </div>
        <div class="trader-information-field">
          <h5 class="ui grey header inverted ht-text-header">
            {{ "Commission" }}
          </h5>
          <span class="ht-text-value">{{ commission }}</span>
        </div>
      </div>
    </div>

    <div class="ui grid content-grid">
      <div class="two column row content-container">
        <div class="sixteen wide computer twelve wide large screen column twelve wide widescreen monitor column left-side-container">
          <div class="trader-information-by-side">
            <ag-grid-vue
              class="trader-information-by-side-table"
              :class="{
                'ag-theme-balham-dark': theme === 'Dark',
                'ag-theme-balham': theme === 'Light',
              }"
              row-selection="single"
              :grid-options="gridTraderInformationBySideOptions"
              :column-defs="selectedMarket === 'SET' ? columnDefsETSTraderInformationBySide : columnDefsDTSTraderInformationBySide"
              :row-data="rowTraderInformationBySide"
              :row-height="21"
              :header-height="32"
              :default-col-def="{ sortable: false, resizable: true }"
              :animate-rows="true"
              :suppress-drag-leave-hides-columns="true"
              :enable-cell-change-flash="true"
              :locale-text="{ noRowsToShow: `Don't have any Trader Information by Side.` }"
              :row-drag-managed="false"
              :pinned-bottom-row-data="pinnedBottomRowTraderInformationBySide"
              overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
              @grid-ready="onTraderInformationBySideGridReady"
              @cell-focused="onCellTraderInformationBySideFocused"
            />
          </div>
          <div class="trader-information-by-account">
            <ag-grid-vue
              class="trader-information-by-account-table"
              :class="{
                'ag-theme-balham-dark': theme === 'Dark',
                'ag-theme-balham': theme === 'Light',
              }"
              row-selection="single"
              :grid-options="gridTraderInformationByAccountTypeAndCustomerTypeOptions"
              :column-defs="
                selectedMarket === 'SET'
                  ? columnDefsETSTraderInformationByAccountTypeAndCustomerType
                  : columnDefsDTSTraderInformationByAccountTypeAndCustomerType
              "
              :row-data="rowTraderInformationByAccountTypeAndCustomerType"
              :row-height="21"
              :header-height="32"
              :default-col-def="{ sortable: false, resizable: true }"
              :animate-rows="true"
              :suppress-drag-leave-hides-columns="true"
              :enable-cell-change-flash="true"
              :locale-text="{ noRowsToShow: `Don't have any Trader Information by Account & Customer Type.` }"
              :row-drag-managed="false"
              overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
              :is-full-width-cell="isTraderInformationByAccountTypeAndCustomerTypeFullWidthCell"
              :full-width-cell-renderer="fullWidthTraderInformationByAccountTypeAndCustomerTypeCellRenderer"
              :get-row-height="getTraderInformationByAccountTypeAndCustomerTypeRowHeight"
              @grid-ready="onTraderInformationByAccountTypeAndCustomerTypeGridReady"
              @cell-focused="onCellTraderInformationByAccountTypeAndCustomerTypeFocused"
            />
          </div>
        </div>

        <div class="four wide column large screen only right-side-container">
          <div class="row pie-chart-information">
            <PieChartInformation mode="Trader" :data="chartData" />
          </div>
          <div class="row progress-bar-information">
            <ProgressBarInformation mode="Trader" :data="chartData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import {
  columnDefsETSTraderInformationBySide,
  columnDefsDTSTraderInformationBySide,
} from "@/components/grid/information/TraderInformationBySideColumnDefs.js";
import {
  columnDefsETSTraderInformationByAccountTypeAndCustomerType,
  columnDefsDTSTraderInformationByAccountTypeAndCustomerType,
} from "@/components/grid/information/TraderInformationByAccountTypeAndCustomerTypeColumnDefs.js";
import InputText from "@/components/InputText";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";
import { getCustomerTypeByKey } from "@/utils/CustomerTypeFormatter.js";
import { getAccountTypeByKey } from "@/utils/AccountTypeFormatter.js";

Object.freeze(columnDefsETSTraderInformationBySide);
Object.freeze(columnDefsDTSTraderInformationBySide);
Object.freeze(columnDefsETSTraderInformationByAccountTypeAndCustomerType);
Object.freeze(columnDefsDTSTraderInformationByAccountTypeAndCustomerType);

import PieChartInformation from "@/components/information/PieChartInformation.vue";
import ProgressBarInformation from "@/components/information/ProgressBarInformation.vue";

export default {
  name: "TraderInformation",
  components: {
    AgGridVue,
    PieChartInformation,
    ProgressBarInformation,
    InputText,
  },
  data: () => ({
    columnDefsETSTraderInformationBySide,
    columnDefsDTSTraderInformationBySide,
    columnDefsETSTraderInformationByAccountTypeAndCustomerType,
    columnDefsDTSTraderInformationByAccountTypeAndCustomerType,

    gridTraderInformationBySideApi: null,
    gridTraderInformationByAccountTypeAndCustomerTypeApi: null,
    columnTraderInformationBySideApi: null,
    columnTraderInformationByAccountTypeAndCustomerTypeApi: null,

    rowTraderInformationBySide: [],
    rowTraderInformationByAccountTypeAndCustomerType: [],
    pinnedBottomRowTraderInformationBySide: [
      {
        side: "Total",
        vol: 0,
        volBrkPct: 0,
        val: 0,
        valBrkPct: 0,
        commission: 0,
        commissionBrkPct: 0,
      },
    ],
    viewAsOptions: [
      {
        text: "Individual",
        value: "I",
      },
      {
        text: "Group",
        value: "G",
      },
    ],
    selectedMarket: "SET",
    isEnableETS,
    isEnableDTS,

    traderID: "",
    selectedViewAs: "I",
    traderName: "-",
    traderTitle: "-",
    approvalOver: "0",
    level: "-",
    supervisor: "-",
    value: "0",
    commission: "0.00",

    BRRefId: null,
    chartData: {},
  }),
  watch: {
    selectedMarket() {
      this.filterChanged();
    },
    // traderID(traderID) {
    //   if (traderID.length === 4) {
    //     this.filterChanged();
    //   }
    // },
    selectedViewAs() {
      this.filterChanged();
    },
  },
  methods: {
    onInformationStartRefresh() {
      this.rowTraderInformationBySide = [];
      this.rowTraderInformationByAccountTypeAndCustomerType = [];
      this.pinnedBottomRowTraderInformationBySide = [
        {
          side: "Total",
          vol: 0,
          volBrkPct: 0,
          val: 0,
          valBrkPct: 0,
          commission: 0,
          commissionBrkPct: 0,
        },
      ];

      this.chartData = {};
      this.traderName = "-";
      this.traderTitle = "-";
      this.approvalOver = "0";
      this.level = "-";
      this.supervisor = "-";
      this.value = "0";
      this.commission = "0.00";

      if (this.selectedMarket === "SET") {
        this.BR02();
      } else if (this.selectedMarket === "TFEX") {
        this.BR52();
      }
    },
    onEnter() {
      if (this.traderID.length > 4) {
        this.traderID = this.traderID.slice(0, 4);
      }
      this.onInformationStartRefresh();
    },
    filterChanged() {
      this.onInformationStartRefresh();
    },
    BR02() {
      const msg = this.$messageFactory.createMessage("BR02");
      this.BRRefId = this.$shortid.generate();

      const trader = this.$_.find(this.traders, ["exchangeId", 1]);
      if (!trader?.traderCode) {
        this.$EventBus.$emit("onInformationStopRefresh");
        this.$Swal.fire("Error", "User does not have SET account.", "error");
        return;
      }
      msg.ownTid.set(trader.traderCode);

      if (!this.traderID) {
        this.traderID = trader.traderCode;
      }
      msg.reqTid.set(this.traderID);
      msg.viewAs.set(this.selectedViewAs);

      this.$ws.send(msg, this.BRRefId);

      if (this.gridTraderInformationBySideApi) {
        this.gridTraderInformationBySideApi.showLoadingOverlay();
      }
      if (this.gridTraderInformationByAccountTypeAndCustomerTypeApi) {
        this.gridTraderInformationByAccountTypeAndCustomerTypeApi.showLoadingOverlay();
      }
    },
    BR52() {
      const msg = this.$messageFactory.createMessage("BR52");
      this.BRRefId = this.$shortid.generate();

      const trader = this.$_.find(this.traders, ["exchangeId", 2]);
      if (!trader?.traderCode) {
        this.$EventBus.$emit("onInformationStopRefresh");
        this.$Swal.fire("Error", "User does not have TFEX account.", "error");
        return;
      }
      msg.ownTid.set(trader.traderCode);

      if (!this.traderID) {
        this.traderID = trader.traderCode;
      }
      msg.reqTid.set(this.traderID);
      msg.viewAs.set(this.selectedViewAs);

      this.$ws.send(msg, this.BRRefId);

      if (this.gridTraderInformationBySideApi) {
        this.gridTraderInformationBySideApi.showLoadingOverlay();
      }
      if (this.gridTraderInformationByAccountTypeAndCustomerTypeApi) {
        this.gridTraderInformationByAccountTypeAndCustomerTypeApi.showLoadingOverlay();
      }
    },
    updateRowTraderInformationBySide(information) {
      if (this.BRRefId === information.refId) {
        if (information.resultCode === 1001) {
          this.traderName = information.name;
          this.traderTitle = information.title;
          this.approvalOver = this.$numeral(information.approvalOver).format("0,0");
          this.level = information.level;
          this.supervisor = information.supervisor || "-";
          this.value = this.$numeral(information.value).format("0,0");
          this.commission = this.$numeral(information.commission).format("0,0.00");

          this.rowTraderInformationBySide = information.sides.slice(0, 2);
          const lastIndexLength = information.sides.length - 1;
          this.pinnedBottomRowTraderInformationBySide = [
            {
              side: "Total",
              vol: information.sides[lastIndexLength].vol,
              volBrkPct: information.sides[lastIndexLength].volBrkPct,
              val: information.sides[lastIndexLength].val,
              valBrkPct: information.sides[lastIndexLength].valBrkPct,
              commission: information.sides[lastIndexLength].commission,
              commissionBrkPct: information.sides[lastIndexLength].commissionBrkPct,
            },
          ];

          information.accountTypes.forEach((accountType, index) => {
            information.accountTypes[index].type = getAccountTypeByKey(accountType.type);
          });

          information.pcs.forEach((customerType, index) => {
            information.pcs[index].type = getCustomerTypeByKey(customerType.type);
          });

          //Remove Sub PC
          this.$_.remove(information.pcs, (pc) => {
            return pc.type.includes("Sub");
          });

          this.chartData = information;

          this.rowTraderInformationByAccountTypeAndCustomerType = [...information.accountTypes, { fullWidth: true }, ...information.pcs];
        } else {
          this.traderName = "-";
          this.traderTitle = "-";
          this.approvalOver = "0";
          this.level = "-";
          this.supervisor = "-";
          this.value = "0";
          this.commission = "0.00";
          this.rowTraderInformationBySide = [];
          this.rowTraderInformationByAccountTypeAndCustomerType = [];

          this.chartData = {};
          console.error(information.resultCode + ": " + information.resultMessage);
          this.$Swal.fire("Error", "Incorrect Trade ID.", "error");
        }
      }

      if (this.gridTraderInformationBySideApi) {
        this.gridTraderInformationBySideApi.hideOverlay();
      }

      if (this.gridTraderInformationByAccountTypeAndCustomerTypeApi) {
        this.gridTraderInformationByAccountTypeAndCustomerTypeApi.hideOverlay();
      }

      this.$EventBus.$emit("onInformationStopRefresh");
    },
    onTraderInformationBySideGridReady(params) {
      this.gridTraderInformationBySideApi = params.api;
      this.columnTraderInformationBySideApi = params.columnApi;
    },
    onTraderInformationByAccountTypeAndCustomerTypeGridReady(params) {
      this.gridTraderInformationByAccountTypeAndCustomerTypeApi = params.api;
      this.columnTraderInformationByAccountTypeAndCustomerTypeApi = params.columnApi;
    },
    onCellTraderInformationBySideFocused(event) {
      this.gridTraderInformationBySideOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    onCellTraderInformationByAccountTypeAndCustomerTypeFocused(event) {
      this.gridTraderInformationByAccountTypeAndCustomerTypeOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    isTraderInformationByAccountTypeAndCustomerTypeFullWidthCell(rowNode) {
      return rowNode.data.fullWidth;
    },
    fullWidthTraderInformationByAccountTypeAndCustomerTypeCellRenderer(params) {
      const eDiv = document.createElement("div");
      eDiv.innerHTML = `<div style="font-weight: 700; font-family: Lato,'Helvetica Neue', Arial, Helvetica, sans-serif; font-size: 1rem; background-color: #1B2025; padding-left: 12px; line-height: 30px;">PC</div>`;
      return eDiv.firstChild;
    },
    getTraderInformationByAccountTypeAndCustomerTypeRowHeight(params) {
      const isFullWidth = params.node.data.fullWidth;
      if (isFullWidth) {
        return 30;
      } else {
        return 21;
      }
    },
  },
  computed: {
    theme: sync("global/theme"),
    traders: get("model/systemRefData@traders"),
  },
  created() {
    this.gridTraderInformationBySideOptions = {};
    this.gridTraderInformationByAccountTypeAndCustomerTypeOptions = {};

    this.$EventBus.$on("ctinf/BS02", this.updateRowTraderInformationBySide);
    this.$EventBus.$on("ctinf/BS52", this.updateRowTraderInformationBySide);

    this.$EventBus.$on("onInformationStartRefresh", this.onInformationStartRefresh);
  },
  mounted() {
    const trader = this.$_.find(this.traders, ["exchangeId", 1]);
    if (trader?.traderCode) {
      this.traderID = trader.traderCode;
      this.onInformationStartRefresh();
    }
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/BS02", this.updateRowTraderInformationBySide);
    this.$EventBus.$off("ctinf/BS52", this.updateRowTraderInformationBySide);

    this.$EventBus.$off("onInformationStartRefresh", this.onInformationStartRefresh);
  },
};
</script>

<style lang="scss" scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";

/* .column {
  background-color: rgb(38, 45, 51) !important;
} */

.trader-information {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  background-color: black;

  .header-section {
    display: flex;
    flex-direction: column;
    padding: 0.2rem 0;
    background-color: #262d33;

    .options-container {
      display: flex;
      align-items: center;
      width: 100%;
      column-gap: 1rem;
      padding: 0 1rem;
      background-color: #262d33;

      .custom-checkbox {
        h5 {
          margin: 0 !important;
        }
      }

      .trader-id {
        display: flex;
        align-items: center;
        column-gap: 1rem;

        h5 {
          margin: 0;
        }

        .trader-id-input {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }

      .view-as {
        @extend .trader-id;

        flex-basis: 240px;

        .view-as-dropdown {
          flex: 1;
        }
      }

      .trader-name {
        display: flex;
        align-items: center;
        column-gap: 1rem;

        h5 {
          margin: 0;
        }

        span {
          font-weight: 700;
          padding: 0 !important;
        }
      }

      .trader-title {
        @extend.trader-name;
      }
    }

    .trader-information-detail {
      background-color: #262d33;
      display: flex;
      align-items: center;
      column-gap: 1rem;
      padding: 0 1rem;

      .trader-information-field {
        @extend.trader-name;
      }
    }
  }

  .content-grid {
    flex: 1;
    background-color: black;
    display: flex;

    .content-container {
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      flex-wrap: nowrap;

      .left-side-container {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        .trader-information-by-side {
          width: 100%;
          height: 35%;

          .trader-information-by-side-table {
            width: 100%;
            height: 100%;
          }
        }

        .trader-information-by-account {
          width: 100%;
          height: 65%;

          .trader-information-by-account-table {
            width: 100%;
            height: 100%;
          }
        }
      }

      .right-side-container {
        display: flex;
        flex-direction: column;
        row-gap: 5px;

        .pie-chart-information {
          flex: 1;
          overflow: hidden;
        }

        .progress-bar-information {
          flex: 1;
          overflow: hidden;
        }
      }
    }
  }
}

.ht-border {
  border-style: solid;
  border-color: black;
}

.ui.button.fluid.dropdown {
  background-color: #2f383f;
}

.ui.grey.header {
  color: #dcddde !important;
}

.ui.secondary.menu {
  min-height: unset;
  height: 32px !important;
  border: 0px !important;
  background-color: unset !important;
}

.dropdown.icon {
  color: #999999;
}
</style>

<style>
.ui.fluid.dropdown > .dropdown.icon {
  color: #999999;
}

.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

.ht-news-datepicker .el-range-separator {
  padding: 0px !important;
  margin-top: -10px !important;
  color: #dcddde !important;
}

.ht-news-datepicker .el-input__icon.el-range__icon.el-icon-date {
  margin-top: -10px !important;
}

.el-picker-panel__sidebar {
  background-color: #2f383f !important;
}

.el-picker-panel__shortcut {
  color: #dcddde !important;
}

.el-picker-panel__shortcut:hover {
  color: #409eff !important;
}

.el-date-table__row > .available.in-range > div {
  background-color: #1b2025 !important;
}

.ht-trader-id > input {
  border: 0px !important;
}

.ht-custlist-dropdown {
  height: 2rem;
  min-height: 2rem;
  top: calc(50% - 1.5rem) !important;
}
.ht-custlist-dropdown .text {
  position: absolute;
  top: calc(50% - 0.5rem);
  left: 1rem;
  right: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-custlist-dropdown i.dropdown {
  line-height: 0 !important;
  margin-top: 3px !important;
}

.ht-text-header {
  display: inline;
  margin: 0 !important;
  padding: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ht-text-value {
  color: #e4af0a !important;
  padding-left: 1rem !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
