<template>
  <div class="ui equal width grid technical-view">
    <div class="row">
      <div class="column">
        <TVChartContainer />
      </div>
    </div>
  </div>
</template>

<script>
import TVChartContainer from "@/components/technical/TVChartContainer.vue";

export default {
  name: "Technical",
  components: {
    TVChartContainer,
  },
  data: () => ({}),
  methods: {},
};
</script>

<style scoped>
.column {
  background-color: rgb(38, 45, 51) !important;
}

.ht-border {
  border-style: solid;
  border-color: black;
}

.technical-view {
  height: 100%;
}
</style>
