<template>
  <div
    ref="container"
    :style="baseStyle ? baseStyle : style"
    :data-tooltip="displayErrorText"
    data-position="top center"
    data-inverted="inverted"
    :class="[{ error: displayErrorText, disabled: disabled }, 'ht-dropdown']"
  >
    <div class="ht-wrapper">
      <div ref="inputContainer" class="ht-input" :class="{ disabled: disabled }">
        <div v-if="image && selectedValue && displayValue" v-lazy-container="{ selector: 'img' }" class="ht-image">
          <sui-image
            v-if="image && !showMenu"
            :data-src="getImage(securities, selectedValue.value.symbol, selectedValue.value.exchangeId)"
            :data-loading="loadingImage"
            :data-error="defaultImage"
            src="lazy"
            avatar
          />
        </div>
        <div style="flex: 1">
          <input
            ref="input"
            v-model="displayValue"
            type="text"
            spellcheck="false"
            :style="inputStyle"
            :placeholder="placeholder"
            :disabled="disabled"
            @focus="onInputFocus"
            @input="onInput"
            @click="onInputClick"
            @blur="onInputBlur"
            @keyup="onKeyUp"
            @keydown="onKeyDown"
          />
        </div>
        <div class="ht-button" @click="onButtonClick">
          <i class="angle icon" :class="{ down: !showMenu, up: showMenu }" :style="buttonStyle"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import BaseDropdown from "./BaseDropdown";
import { getImage } from "@/utils/SymbolUtils.js";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils";

let dropdownTimeout = null;

export default {
  extends: BaseDropdown,
  props: {
    value: {
      type: [Object, String],
    },
    market: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Symbol",
    },
    keyText: {
      type: String,
      default: "symbol",
    },
    filterable: {
      type: String,
      default: "startsWith",
    },
    sync: {
      type: Boolean,
      default: true,
    },
    notification: {
      type: Boolean,
      default: false,
    },
    notificateToOrder: {
      type: Boolean,
      default: true,
    },
    order: {
      type: Boolean,
      default: false,
    },
    defaultFromStore: {
      type: Boolean,
      default: true,
    },
    inputUpperCase: {
      type: Boolean,
      default: true,
    },
    image: {
      type: Boolean,
      default: false,
    },
    scroller: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      getImage,
      processing: false,
      style: "width: 12rem; min-width: 12rem;",
      defaultValue: null,
      displayValue: null,
      options: null,
      fireNullToAnother: null,
      imageFinder: null,
      defaultImage: null,
      loadingImage: null,
    };
  },
  computed: {
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
    tempCurrentQuoteSelectedSymbol: sync("global/tempCurrentQuoteSelectedSymbol"),
    securities: get("model/systemRefData@securities"),
    dtsSecurities: get("model/systemRefData@dtsSecurities"),
  },
  watch: {
    market() {
      this.init();
    },
    value(value) {
      if (value === null) {
        this.fireNullToAnother = true;
        this.displayValue = null;
        this.selectedValue = null;
      } else {
        if (value) {
          if (typeof value === "object") {
            this.displayValue = value.symbol;
            this.selectedValue = value;
          } else {
            if (this.options) {
              const found = this.$_.find(this.options, (o) => {
                return o.symbol === value;
              });
              this.displayValue = found.symbol;
              this.selectedValue = found;
            } else {
              this.generateOptions().then((options) => {
                const found = this.$_.find(options, (o) => {
                  return o.symbol === value;
                });
                this.displayValue = found.symbol;
                this.selectedValue = found;
                this.$emit("input", this.selectedValue);

                this.clearValidate();
              });
            }
          }
        } else {
          this.displayValue = null;
          this.selectedValue = undefined;
          this.tempSelectedObject = undefined;
        }
      }
      this.validate();
    },
    currentQuoteSelectedSymbol(value) {
      if (!this.notification || !this.sync || this.disabled) {
        return;
      }
      const temp = this.tempCurrentQuoteSelectedSymbol[this._uid];
      if (value && temp && value[this.keyText] === temp[this.keyText]) {
        return;
      }
      this.tempCurrentQuoteSelectedSymbol[this._uid] = value;

      if (value) {
        if ((value.value.exchangeId == "1" && this.market === "TFEX") || (value.value.exchangeId == "2" && this.market === "SET")) {
          return;
        }
      }
      if (this.order && value && value.notChangeValue) {
        return;
      }

      if (value) {
        const newSymbol = Object.assign({}, value);
        if (newSymbol.fromUID && newSymbol.fromUID === this._uid) {
          return;
        }
        this.displayValue = newSymbol[this.keyText];
        this.selectedValue = newSymbol;
        this.tempSelectedObject = newSymbol;
        this.$emit("input", this.selectedValue);

        this.clearValidate();
      } else {
        this.displayValue = null;
        this.selectedValue = undefined;
        this.tempSelectedObject = null;
        this.$emit("input", null);

        this.clearValidate();
      }
    },
  },
  created() {
    if (this.currentQuoteSelectedSymbol && this.defaultFromStore) {
      if (
        (this.currentQuoteSelectedSymbol.value.exchangeId === 1 && this.market === "SET") ||
        (this.currentQuoteSelectedSymbol.value.exchangeId === 2 && this.market === "TFEX") ||
        this.market === "ALL"
      ) {
        this.displayValue = this.currentQuoteSelectedSymbol.symbol;
        this.selectedValue = this.currentQuoteSelectedSymbol;
        this.$emit("input", this.selectedValue);
      }
    } else {
      if (this.value) {
        this.displayValue = this.value.symbol;
        this.selectedValue = this.value;
      }
    }

    this.imageFinder = require.context(`@/assets/company-logo-compress/`, false, /(\.png|\.gif)$/);
    this.defaultImage = this.imageFinder(`./PLACEHOLDER.png`);
    this.loadingImage = this.imageFinder(`./loading.gif`);
  },
  beforeDestroy() {
    delete this.tempCurrentQuoteSelectedSymbol[this._uid];
  },
  methods: {
    async init() {
      const options = await this.generateOptions();
      this.items = Object.assign([], options);
      this.options = Object.assign([], options);
    },
    generateOptions() {
      return new Promise((resolve) => {
        const isETS = isEnableETS();
        const isDTS = isEnableDTS();

        let options = [];
        if (this.market === "SET" || (this.market === "ALL" && isETS)) {
          this.securities.forEach((securitie) => {
            options.push({
              value: securitie,
              symbol: securitie.symbol,
              exchangeId: securitie.exchangeId,
              imageName: securitie.imageName,
            });
          });
        }
        if (this.market === "TFEX" || (this.market === "ALL" && isDTS)) {
          this.dtsSecurities.forEach((securitie) => {
            options.push({
              value: securitie,
              symbol: securitie.symbol,
              exchangeId: securitie.exchangeId,
              imageName: securitie.imageName,
            });
          });
        }
        resolve(options);
      });
    },
    onButtonClick() {
      if (!this.showMenu) {
        if (!this.options) {
          this.init().then(() => {
            const rect = this.$refs.container.getBoundingClientRect();
            this.$EventBus.$emit("dropdown", {
              inputId: this._uid,
              items: this.options,
              placeholder: this.placeholder,
              freeText: this.freeText,
              keyText: this.keyText,
              keyValue: this.keyValue,
              rect: rect,
              callbackData: this.callbackData,
              isSymbol: this.image,
              forceWidth: null,
              forceUpdateOptions: false,
              scroller: this.scroller,
            });
            this.$EventBus.$emit("opendropdown", rect);
            this.showMenu = true;
            this.$refs.input.select();
          });
        } else {
          const rect = this.$refs.container.getBoundingClientRect();
          this.$EventBus.$emit("dropdown", {
            inputId: this._uid,
            items: this.options,
            placeholder: this.placeholder,
            freeText: this.freeText,
            keyText: this.keyText,
            keyValue: this.keyValue,
            rect: rect,
            callbackData: this.callbackData,
            isSymbol: this.image,
            forceWidth: null,
            forceUpdateOptions: false,
            scroller: this.scroller,
          });
          this.$EventBus.$emit("opendropdown", rect);
          this.showMenu = true;
          this.$refs.input.select();
        }
      }
    },
    onInputFocus() {
      if (dropdownTimeout) {
        clearTimeout(dropdownTimeout);
      }
      if (!this.options) {
        this.init().then(() => {
          this.afterInit();
        });
      } else {
        this.afterInit();
      }
    },
    afterInit() {
      const rect = this.$refs.container.getBoundingClientRect();
      this.$EventBus.$emit("dropdown", {
        inputId: this._uid,
        items: this.options,
        placeholder: this.placeholder,
        freeText: this.freeText,
        keyText: this.keyText,
        keyValue: this.keyValue,
        rect: rect,
        callbackData: this.callbackData,
        isSymbol: this.image,
        forceWidth: null,
        forceUpdateOptions: false,
        scroller: this.scroller,
      });
      if (dropdownTimeout) {
        clearTimeout(dropdownTimeout);
      }
      dropdownTimeout = setTimeout(() => {
        this.$refs.input.select();
      }, 10);
    },
    selectData(value) {
      if (value === null) {
        this.displayValue = null;
        this.selectedValue = undefined;
        this.tempSelectedObject = undefined;
        this.$emit("input", null);
        this.$emit("enter", this.tempSelectedObject);
        return;
      } else if (value === undefined) {
        this.endDropdown();
        this.$EventBus.$emit("closedropdown");
        this.showMenu = false;
        if (dropdownTimeout) {
          clearTimeout(dropdownTimeout);
        }
        dropdownTimeout = setTimeout(() => {
          this.$refs.input.select();
        }, 10);
        return;
      }
      if (this.showMenu && !this.processing) {
        let checker = this.selectedValue;
        if (this.selectedValue && typeof this.selectedValue === "object") {
          checker = this.hash(this.selectedValue[this.keyValue]);
        }
        if (checker !== value) {
          const data = this.$_.find(this.options, (o) => {
            let newValue = null;
            if (typeof o[this.keyValue] === "object") {
              newValue = this.hash(o[this.keyValue]);
            } else {
              newValue = o[this.keyValue];
            }
            return newValue == value;
          });
          this.selectedValue = data;
          if (typeof data[this.keyValue] !== "object") {
            this.selectedValue = data[this.keyValue];
          }
          this.displayValue = data[this.keyText];
          this.tempSelectedObject = data;
          this.$emit("input", this.selectedValue);
          this.$emit("enter", this.tempSelectedObject);

          const newSymbol = Object.assign({}, this.selectedValue);
          newSymbol.fromUID = this._uid;
          if (!this.notificateToOrder) {
            newSymbol.notChangeValue = true;
          }
          if (this.notification && (!this.selectedValue.fromUID || this.selectedValue.fromUID === this._uid)) {
            this.$store.set("global/currentQuoteSelectedSymbol", newSymbol);
          }
        } else {
          this.displayValue = this.tempSelectedObject[this.keyText];
        }
        this.$EventBus.$emit("closedropdown");
        this.showMenu = false;
      } else {
        if (this.displayValue) {
          if (this.validate()) {
            this.$emit("enter", this.tempSelectedObject);
          }
        } else {
          this.selectedValue = undefined;
          this.tempSelectedObject = undefined;
          this.$emit("input", null);
          if (this.notification) {
            this.$store.set("global/currentQuoteSelectedSymbol", null);
          }
          this.$nextTick(() => {
            if (this.validate()) {
              this.$emit("enter", this.tempSelectedObject);
            }
          });
        }
      }
    },
    onItemClick(data) {
      if (this.processing) {
        return;
      }
      const tempValue = this.selectedValue;
      this.selectedValue = data;
      let newValue1 = null;
      let newValue2 = null;
      if (typeof data[this.keyValue] === "object") {
        newValue1 = this.hash(data[this.keyValue]);
        newValue2 = tempValue ? this.hash(tempValue[this.keyValue]) : tempValue;
      } else {
        newValue1 = data[this.keyValue];
        newValue2 = tempValue;
        this.selectedValue = newValue1;
      }
      if (newValue1 !== newValue2) {
        this.displayValue = data[this.keyText];
        this.tempSelectedObject = data;
        this.$emit("input", this.selectedValue);
        this.$emit("enter", this.tempSelectedObject);
        const newSymbol = Object.assign({}, this.selectedValue);
        newSymbol.fromUID = this._uid;
        if (!this.notificateToOrder) {
          newSymbol.notChangeValue = true;
        }
        if (this.notification && (!this.selectedValue.fromUID || this.selectedValue.fromUID === this._uid)) {
          this.$store.set("global/currentQuoteSelectedSymbol", newSymbol);
        }
      }
      this.clearValidate();
    },
    symbolImage(name) {
      if (name) {
        try {
          return this.imageFinder(`./${name}.png`);
        } catch (err) {
          return this.defaultValue;
        }
      }
      return this.defaultValue;
    },
    async forceValue(value) {
      if (!this.options) {
        this.options = await this.generateOptions();
      }
      const found = this.$_.find(this.options, (o) => {
        return o.symbol === value;
      });
      if (found) {
        this.selectedValue = found;
        let newValue = null;
        if (typeof found[this.keyValue] !== "object") {
          this.selectedValue = found[this.keyValue];
        }
        this.tempSelectedObject = found;
        this.displayValue = found[this.keyText];
        this.$emit("input", this.selectedValue);
      }
    },
  },
};
</script>

<style scoped>
.active {
  background: #1b2025;
}

.ht-dropdown {
  position: relative;
  display: inline-flex;
  vertical-align: top;
  border-radius: 0.28571429rem;
  height: 2rem;
  min-height: 2rem;
  line-height: 2rem;
  background: #2f383f;
}

.ht-dropdown.error {
  border: 1px solid #d91e18 !important;
}

.ht-input {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  input {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ht-image {
  margin-left: 0.75rem;
  min-width: 2rem;
  width: 2rem;
  height: 2rem;
  vertical-align: middle;
}

.ht-image img {
  height: 100% !important;
  width: auto !important;
}

.ht-button {
  min-width: 2rem;
  width: 2rem;
  text-align: center;
  border-radius: 0.28571429rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
}

.ht-button i,
.ht-button i::before {
  margin: 0 auto;
}

.ht-button i {
  color: #dcddde;
}

.ht-dropdown.disabled .ht-button i {
  color: grey !important;
}

.ht-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
}

.ht-dropdown input {
  flex: 1;
  width: 100%;
  height: auto;
  border: none;
  border-radius: 0.28571429rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 0 0 0 0.75rem !important;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  z-index: 0;
  color: #dcddde !important;
  background: #2f383f !important;
  vertical-align: middle !important;
  margin-top: -0.25rem !important;
}

.ht-dropdown input:focus {
  outline: none;
}

.ht-dropdown.disabled,
.ht-dropdown input:disabled {
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
.ht-dropdown.disabled .ht-button {
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}

.ht-items {
  position: relative;
  width: 100%;
}

ul {
  position: absolute;
  left: 0;
  top: 2px;
  z-index: 100000;
  min-width: 12rem;
  width: 100%;
  max-height: 200px;
  padding: 0;
  margin: 0;
  background: #262d33;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  overflow-y: auto;
}

li {
  display: block;
  transition-duration: 0.5s;
  padding: 5px 2px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #dcddde;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

li:hover {
  cursor: pointer;
  background: #1b2025;
}

ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
  clear: both;
  width: 100%;
}

li.no-item,
li.no-item:hover {
  cursor: default;
  background: inherit;
}
</style>
