import {
  BLUE_COLOR,
  statusFormatter,
  sideColorStyle,
  numeralCellFormatter,
  baseStyle,
  conditionFormatter,
  ttfFormatter,
  validityDataFormatter,
  validityFormatter,
} from "../Formatter.js";
import { price as PriceFormatter } from "@/utils/NumberFormatter";
import { isDisplayOrderCheckbox } from "@/utils/ValidationUtils";

const columnDefsOrderEntry = [
  {
    headerName: "",
    field: "status",
    checkboxSelection: true,
    pinned: "left",
    width: 35,
    cellRendererFramework: "CheckboxOrderCellRenderer",
    cellStyle: (params) => {
      if (params.data) {
        const result = isDisplayOrderCheckbox(params.data);
        if (!result) {
          return {
            "pointer-events": "none",
          };
        }
      }
      return null;
    },
  },
  {
    headerName: "Order No.",
    field: "orderId",
    pinned: "left",
    width: 140,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle,
      color: BLUE_COLOR,
    },
    floatingFilterComponentFramework: "FilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      suppressFilterButton: true,
    },
    filter: true,
    suppressMenu: true,
  },
  {
    headerName: "Time",
    field: "time",
    width: 100,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR,
    },
  },
  {
    headerName: "Symbol",
    field: "symbol",
    width: 100,
    cellStyle: baseStyle,
    floatingFilterComponentFramework: "SymbolFilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      uppercase: true,
      suppressFilterButton: true,
    },
    filter: true,
    suppressMenu: true,
  },
  {
    headerName: "Side",
    field: "side",
    width: 65,
    cellStyle: sideColorStyle,
    floatingFilterComponentFramework: "FilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      suppressFilterButton: true,
    },
    filter: true,
    suppressMenu: true,
  },
  {
    headerName: "Volume",
    field: "volume",
    valueFormatter: (params) => numeralCellFormatter(params, "0,0"),
    width: 100,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle,
    },
  },
  {
    headerName: "Price",
    field: "price",
    valueFormatter: (params) => {
      if (!params.data) {
        return;
      }
      return PriceFormatter(params.value);
    },
    width: 100,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle,
    },
  },
  {
    headerName: "Matched",
    field: "matchedVol",
    width: 100,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle,
    },
  },
  {
    headerName: "Published",
    field: "publishedVol",
    width: 100,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle,
      color: BLUE_COLOR,
    },
  },
  {
    headerName: "Status",
    field: "status",
    width: 100,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR,
    },
    valueFormatter: statusFormatter,
  },
  {
    headerName: "Quote",
    field: "quote",
    width: 100,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR,
    },
  },
  {
    headerName: "Iceberg",
    field: "icebergVol",
    width: 100,
    cellStyle: {
      display: "flex",
      "justify-content": "flex-end",
      ...baseStyle,
    },
  },
  {
    headerName: "Condition",
    field: "condition",
    width: 110,
    cellStyle: baseStyle,
    valueFormatter: conditionFormatter,
  },
  {
    headerName: "Flag",
    field: "ttf",
    width: 100,
    cellStyle: baseStyle,
    valueFormatter: ttfFormatter,
  },
  {
    headerName: "Account",
    field: "accountCode",
    width: 140,
    cellStyle: baseStyle,
    floatingFilterComponentFramework: "FilterFloatingComponent",
    floatingFilterComponentParams: {
      type: "text",
      suppressFilterButton: true,
    },
    filter: true,
    suppressMenu: true,
  },
  {
    headerName: "Send Date",
    field: "sendDate",
    width: 120,
    cellStyle: {
      ...baseStyle,
      color: BLUE_COLOR,
    },
  },
  {
    headerName: "Validity",
    field: "validity",
    width: 120,
    cellStyle: baseStyle,
    valueFormatter: validityFormatter,
  },
  {
    headerName: "Validity Data",
    field: "validityData",
    width: 120,
    cellStyle: baseStyle,
    valueFormatter: validityDataFormatter,
  },
  {
    headerName: "Action",
    field: "exchangeId",
    pinned: "right",
    width: 105,
    cellRendererFramework: "ActionCellRenderer",
  },
];

export { columnDefsOrderEntry };
