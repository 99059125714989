import store from "./../store";
import moment from "moment";
import { parseEtsStrToUTCDate } from "./DateUtils";

export const isEditableOrderStatus = (record) => {
  const userType = store.get("model/systemRefData@userType");
  const bussinessDate = store.get("model/systemRefData@bussinessDate");
  const dtsBusinessDate = store.get("model/systemRefData@dtsBusinessDate");

  const status = record.status;
  const isQuoted = record.quote === "Y";
  const exchangeId = record.exchangeId;
  const sendDate = parseEtsStrToUTCDate(record.sendDate);
  // TFEX
  if (exchangeId == 2) {
    if (moment(dtsBusinessDate).isAfter(sendDate, "day")) {
      return false;
    }
    if ((isQuoted && status == "O") || (userType === "T" && status == "M") /* || status == 'A' ETSSUP-2241 */) {
      return true;
    }
    return false;
  } else {
    //SET
    if (moment(bussinessDate).isAfter(sendDate, "day")) {
      return false;
    }
    if (status == "O" || status == "PO" || (userType === "T" && status == "M") /* || status == 'A' ETSSUP-2241 */) {
      return true;
    }
    return false;
  }
};

export const isCancellableOrderStatus = (record) => {
  const bussinessDate = store.get("model/systemRefData@bussinessDate");
  const dtsBusinessDate = store.get("model/systemRefData@dtsBusinessDate");

  const sendDate = parseEtsStrToUTCDate(record.sendDate);
  const targetBusinessDate = record.exchangeId == 2 ? dtsBusinessDate : bussinessDate;
  if (moment(targetBusinessDate).isAfter(sendDate, "day")) {
    return false;
  }
  var status = record.status;
  if (status === "O" || status === "PO" || status.startsWith("A")) {
    return true;
  }
  return false;
};

export const isDisplayOrderCheckbox = (record) => {
  var status = record.status;
  if (status === "O" || status === "PO" || status.startsWith("A")) {
    return true;
  }
  return false;
};

export const isDisplayPTDealCheckbox = (record) => {
  return isCancellablePTDealStatus(record);
};

export const isApproveableOrderStatus = (record) => {
  var status = record.status;
  var exchangeId = record.exchangeId;
  if (status.startsWith("A") && exchangeId == 1) {
    return true;
  }
  return false;
};

export const isApproveablePTDealStatus = (record) => {
  var status = record.status;
  var exchangeId = record.exchangeId;
  var accountCode = record.accountCode;
  if ((accountCode === "" && status === "DC") || (exchangeId == 2 && accountCode === "" && status === "DR")) {
    return true;
  }
  return false;
};

export const isEditablePTDealStatus = (record) => {
  var status = record.status;
  var exchangeId = record.exchangeId;

  if ((status === "" || status === "C") && exchangeId == 1) {
    return true;
  }
  return false;
};

export const isCancellablePTDealStatus = (record) => {
  const status = record.status;
  const exchangeId = record.exchangeId;
  const accountCode = record.accountCode;

  if ((accountCode && status === "DC") || status === "C" || status === "XC" || (exchangeId == 2 && status === "DP")) {
    return true;
  }

  return false;
};
