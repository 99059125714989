import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle,
  informationSideFormatter,
  valueSideColorStyle
} from "../Formatter.js";
import { getAccountTypeByKey } from "@/utils/AccountTypeFormatter.js";
import { getCreditTypeByKey } from "@/utils/CreditTypeFormatter.js";
import { getCustomerTypeByKey } from "@/utils/CustomerTypeFormatter.js";
import { getCustomerStatusByKey } from "@/utils/CustomerStatusFormatter.js";
import { getCustomerFlagByKey } from "@/utils/CustomerFlagFormatter.js";
import { getVIPFlagByKey } from "@/utils/VIPFlagFormatter.js";
import { getMutualFlagByKey } from "@/utils/MutualFlagFormatter.js";

const columnDefsETSCompanyValue = [
  {
    headerName: "Rank",
    field: "rank",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 80,
    cellStyle: totalColorStyle
  },
  {
    headerName: "Department Name",
    field: "departmentName",
    width: 250,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Buy Value",
    field: "buyVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "B" })
  },
  {
    headerName: "Sell Value",
    field: "sellVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: () => valueSideColorStyle({ value: "S" })
  },
  {
    headerName: "Total Value",
    field: "totalVal",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "%Total Val Brk.",
    field: "totalValBrkPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Commission",
    field: "commission",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "%Comm Brk.",
    field: "commissionBrkPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: totalColorStyle
  }
];

const columnDefsDTSCompanyValue = columnDefsETSCompanyValue;

export { columnDefsETSCompanyValue, columnDefsDTSCompanyValue };
