/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class NS01 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._newsList = new ArrayMessageBase(NewsItem);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get newsList() {
		return this._newsList;
	}
}

export class NewsItem {
	constructor() {
		this._newsId = new NumberMessageBase();
		this._datetime = new StringMessageBase();
		this._symbol = new StringMessageBase();
		this._headline = new StringMessageBase();
		this._reportFileName = new StringMessageBase();
		this._reportFileType = new StringMessageBase();
	}
	
	get newsId() {
		return this._newsId;
	}
	
	get datetime() {
		return this._datetime;
	}
	
	get symbol() {
		return this._symbol;
	}
	
	get headline() {
		return this._headline;
	}
	
	get reportFileName() {
		return this._reportFileName;
	}
	
	get reportFileType() {
		return this._reportFileType;
	}
}

export default NS01;
