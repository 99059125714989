<template>
  <form class="ui form" @submit.prevent="submit">
    <div class="field">
      <label>Current Pin</label>
      <InputPassword
        ref="currentPin"
        v-model="currentPin"
        name="Current Pin"
        placeholder="Current Pin"
        error-position="bottom center"
        input-style="height: 2.5rem;"
        eye-button
        is-validate
        required
        autofocus
      />
    </div>
    <div class="field">
      <label>New Pin</label>
      <InputPassword
        ref="newPin"
        v-model="newPin"
        name="New Pin"
        placeholder="New Pin"
        input-style="height: 2.5rem;"
        eye-button
        is-validate
        required
        :error-text="newPinErr"
      />
    </div>
    <div class="field">
      <label>Confirm New Pin</label>
      <InputPassword
        ref="confirmNewPin"
        v-model="confirmNewPin"
        name="Confirm New Pin"
        placeholder="Confirm New Pin"
        input-style="height: 2.5rem;"
        eye-button
        is-validate
        required
        :error-text="confirmNewPinErr"
      />
    </div>
    <input type="submit" style="height: 0; min-height: 0; visibility: hidden;" tabindex="-1" />
  </form>
</template>

<script>
import { get } from "vuex-pathify";
import InputPassword from "@/components/InputPassword";

export default {
  components: {
    InputPassword
  },
  data() {
    return {
      refId: null,
      currentPin: null,
      newPin: null,
      confirmNewPin: null,
      confirmNewPinErr: null,
      newPinErr: null,
      inputsFilterable: ["InputPassword"],
      resolve: null,
      reject: null
    };
  },
  mounted() {
    this.$refs.currentPin.$el.querySelector("input").focus();
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    resetForm() {
      this.currentPin = null;
      this.newPin = null;
      this.confirmNewPin = null;
      this.$nextTick(() => {
        this.$children.forEach(c => {
          if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
            c.clearValidate();
          }
        });
        this.$refs.currentPin.$el.querySelector("input").focus();
      });
    },
    save() {
      return new Promise((resolve, reject) => {
        this.confirmNewPinErr = null;
        this.newPinErr = null;
        let validate = true;
        const filterErrors = this.$children.filter(c => {
          if (this.inputsFilterable.includes(c.$vnode.componentOptions.tag)) {
            if (c.isValidate) {
              const result = c.validate();
              validate &= result;
              return !result;
            } else {
              c.clearValidate();
            }
          }
          return false;
        });

        if (!this.currentPin || !this.newPin || !this.confirmNewPin) {
          return;
        }

        if (this.newPin !== this.confirmNewPin) {
          this.$Swal.fire(`Warning!`, "New Pin and Confirm New Pin do not match.", "warning");
          this.confirmNewPinErr = "New Pin and Confirm New Pin do not match.";
          this.$refs.confirmNewPin.$el.querySelector("input").focus();
          this.$refs.confirmNewPin.$el.querySelector("input").select();
          reject();
          return;
        }

        if (this.newPin.length < this.pinMinLength || this.newPin.length > this.pinMaxLength) {
          this.$Swal.fire(`Warning!`, `Pin length must between ${this.pinMinLength} to ${this.pinMaxLength} characters.`, "warning");
          this.newPinErr = `Pin length must between ${this.pinMinLength} to ${this.pinMaxLength} characters.`;
          this.$refs.newPin.$el.querySelector("input").focus();
          this.$refs.newPin.$el.querySelector("input").select();
          reject();
          return;
        }

        if (this.pinDigitsOnly === "Y" && isNaN(this.newPin)) {
          this.$Swal.fire(`Warning!`, "Pin must numeric only.", "warning");
          this.newPinErr = "Pin must numeric only.";
          this.$refs.newPin.$el.querySelector("input").focus();
          this.$refs.newPin.$el.querySelector("input").select();
          reject();
          return;
        }

        if (this.currentPin === this.newPin) {
          this.$Swal.fire(`Warning!`, "Can't change new pin same as old pin.", "warning");
          this.newPinErr = "Can't change new pin same as old pin.";
          this.$refs.newPin.$el.querySelector("input").focus();
          this.$refs.newPin.$el.querySelector("input").select();
          reject();
          return;
        }

        if (!validate) {
          filterErrors[0].$el.querySelector("input").focus();
          reject();
          return;
        }

        this.resolve = resolve;
        this.reject = reject;
        this.$EventBus.$on("ctinf/US03", this.pinChangedRsp);
        this.sendPinChangedReq();
      });
    },
    sendPinChangedReq() {
      const msg = this.$messageFactory.createMessage("UR03");

      msg.currentPin.set(this.currentPin);
      msg.newPin.set(this.newPin);
      this.refId = this.$shortid.generate();
      this.$ws.send(msg, this.refId);
    },
    pinChangedRsp(data) {
      if (data.refId === this.refId) {
        this.$EventBus.$off("ctinf/US03", this.pinChangedRsp);
        if (data.resultCode === 1001) {
          this.resetForm();
          this.resolve();
        } else {
          if (data.resultCode === 2001) {
            this.reject(new Error("2001: Invalid Current Pin."));
          } else {
            this.reject(new Error(data.resultCode + ": Change Pin Fail."));
          }
        }
        this.resolve = null;
        this.reject = null;
      }
    }
  },
  computed: {
    pinDigitsOnly: get("model/systemRefData@pinDigitsOnly"),
    pinMaxLength: get("model/systemRefData@pinMaxLength"),
    pinMinLength: get("model/systemRefData@pinMinLength")
  }
};
</script>
