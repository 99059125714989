import {
  numeralCellFormatter,
  realizedColorStyle,
  unrealizedCellFormatter,
  unrealizedColorStyle,
  totalColorStyle,
  qtyColorStyle,
  sideColorStyle,
  unrealPlColorStyle,
  ceilColorStyle,
  informationSideFormatter
} from "../Formatter.js";
import { getAccountTypeByKey } from "@/utils/AccountTypeFormatter.js";
import { getCreditTypeByKey } from "@/utils/CreditTypeFormatter.js";
import { getCustomerTypeByKey } from "@/utils/CustomerTypeFormatter.js";
import { getCustomerStatusByKey } from "@/utils/CustomerStatusFormatter.js";
import { getCustomerFlagByKey } from "@/utils/CustomerFlagFormatter.js";
import { getVIPFlagByKey } from "@/utils/VIPFlagFormatter.js";
import { getMutualFlagByKey } from "@/utils/MutualFlagFormatter.js";

const columnDefsETSGroupValueByTrader = [
  {
    headerName: "Trader ID",
    field: "traderId",
    pinned: "left",
    rowDrag: false,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 150,
    cellStyle: () => ({ ...totalColorStyle(), "justify-content": "flex-start", cursor: "pointer" })
  },
  {
    headerName: "Title",
    field: "title",
    width: 250,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start", cursor: "pointer" })
  },
  {
    headerName: "Level",
    field: "level",
    width: 80,
    cellStyle: () => ({ ...ceilColorStyle(), "justify-content": "flex-start" })
  },
  {
    headerName: "Value",
    field: "val",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "%Value",
    field: "valPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Volume",
    field: "vol",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "%Volume",
    field: "volPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: qtyColorStyle
  },
  {
    headerName: "Commission",
    field: "commission",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00"),
    cellStyle: totalColorStyle
  },
  {
    headerName: "%Commission",
    field: "commissionPct",
    width: 150,
    valueFormatter: params => numeralCellFormatter(params, "0,0.00", "%"),
    cellStyle: totalColorStyle
  }
];

const columnDefsDTSGroupValueByTrader = columnDefsETSGroupValueByTrader;

export { columnDefsETSGroupValueByTrader, columnDefsDTSGroupValueByTrader };
