import StringMessageBase from "./StringMessageBase";
import NumberMessageBase from "./NumberMessageBase";
import ArrayMessageBase from "./ArrayMessageBase";

class WsMsg {
  constructor(channelId) {
    this._code = new StringMessageBase();
    this._channelId = new NumberMessageBase(channelId);
  }

  get code() {
    return this._code;
  }

  set channelId(channelId) {
    this._channelId = new NumberMessageBase(channelId);
  }

  get channelId() {
    return this._channelId;
  }

  fromSeparatedValuesString(data) {
    let count = 0;
    let value = "";
    let keys = Object.keys(this);
    keys.splice(1, 1);
    while (data !== "") {
      if (data.startsWith("[[")) {
        value = data.substring(0, data.indexOf("]]") + 2);
        data = data.substring(data.indexOf("]]") + 3, data.length);
        if (this[keys[count]]) {
          this[keys[count]].new(unescape(value));
        }
      } else {
        value = data.substring(0, data.indexOf("|"));
        data = data.substring(data.indexOf("|") + 1, data.length);
        if (value === "[]") {
          this[keys[count]].new(null);
        } else {
          if (this[keys[count]]) {
            this[keys[count]].set(unescape(value));
          }
        }
      }
      ++count;
    }
  }

  toJsonObject() {
    let out = {};
    let keys = Object.keys(this);
    keys.forEach((key) => {
      if (this[key] instanceof ArrayMessageBase) {
        out[key.substring(1)] = this[key].toJsonObject();
      } else {
        let data = this[key].get();
        if (data === null || data === undefined) {
          data = "";
        }
        out[key.substring(1)] = data;
      }
    });
    return out;
  }

  toSeparatedValuesString() {
    let out = "";
    let keys = Object.keys(this);
    keys.splice(1, 1);
    keys.forEach((key) => {
      if (this[key] instanceof ArrayMessageBase) {
        out += "[";
        let records = this[key].get();
        records.forEach((record) => {
          out += "[";
          Object.keys(record).forEach((recKey) => {
            out += escapePipe(record[recKey].get()) + "|";
          });
          out += "]";
        });
        out += "]|";
      } else {
        out += escapePipe(this[key].get()) + "|";
      }
    });
    return out;
  }
}

function unescape(value) {
  if (value && typeof value === "string") {
    value = value.replace(/%1/g, "|");
    value = value.replace(/%%/g, "%");
  }
  return value;
}

function escapePipe(value) {
  if (value && typeof value === "string") {
    value = value.replace(/\|/g, "%1");
  }
  return value;
}

export default WsMsg;
