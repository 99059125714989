<template>
  <div>
    <ag-grid-vue
      style="width: 100%; height: calc(30vh - 32px - 5px); padding: 0px"
      :class="{
        'ag-theme-balham-dark': theme === 'Dark',
        'ag-theme-balham': theme === 'Light',
      }"
      row-selection="single"
      :grid-options="gridETSPositionOptions"
      :column-defs="columnDefsETSPosition"
      :row-data="rowETSPosition"
      :row-height="21"
      :header-height="32"
      :default-col-def="{ sortable: true, resizable: true }"
      :animate-rows="true"
      :suppress-drag-leave-hides-columns="true"
      :enable-cell-change-flash="true"
      :locale-text="{ noRowsToShow: `Don't have any Position.` }"
      :row-drag-managed="false"
      :pinned-bottom-row-data="pinnedBottomRowETSPosition"
      overlay-loading-template="<div><div class='ui active inline centered loader'></div><div style='color: #dcddde;'>Loading...</div></div>"
      @grid-ready="onETSPositionGridReady"
      @row-selected="onRowETSPositionSelected"
      @cell-focused="onCellETSPositionFocused"
    />
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { AgGridVue } from "ag-grid-vue";
import { columnDefsETSPosition } from "@/components/grid/position/ColumnDefs.js";

import { getBySymbolAndExchangeId } from "@/utils/SymbolUtils";

Object.freeze(columnDefsETSPosition);

export default {
  name: "PortfolioPositionTable",
  components: {
    AgGridVue,
  },
  props: ["onShow"],
  data: () => ({
    columnDefsETSPosition,
    socketRef: null,
    gridETSPositionApi: null,
    columnETSPositionApi: null,
    rowETSPosition: [],
    pinnedBottomRowETSPosition: [
      {
        symbol: "Total",
        costValue: "0.00",
        marketValue: "0.00",
        unrealized: "0.00",
        unrealizedPercent: "0.00%",
        realized: "0.00",
      },
    ],
  }),
  watch: {
    currentSelectedAccount(currentSelectedAccount) {
      if (!this.onShow) return;

      if (currentSelectedAccount.exchangeId == 0) {
        this.initPositionTable();
      }

      this.AR02();
    },
  },
  methods: {
    initPositionTable() {
      this.rowETSPosition = [];
      this.pinnedBottomRowETSPosition = [
        {
          symbol: "Total",
          costValue: "0.00",
          marketValue: "0.00",
          unrealized: "0.00",
          unrealizedPercent: "0.00%",
          realized: "0.00",
        },
      ];
    },
    onPortfolioTableStartRefresh() {
      if (!this.onShow) return;

      this.AR02();
    },
    AR02() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount?.exchangeId == 0 || !this.currentSelectedAccount?.accountCode) {
        this.$EventBus.$emit("onPortfolioTableStopRefresh");
        return;
      }
      const msg = this.$messageFactory.createMessage("AR02");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);
      msg.exchangeId.set(this.currentSelectedAccount.exchangeId);
      this.socketRef = this.$shortid.generate();
      this.$ws.send(msg, this.socketRef);
      if (this.gridETSPositionApi) {
        this.gridETSPositionApi.showLoadingOverlay();
      }
    },
    updateRowETSPosition(position) {
      this.rowETSPosition = position.records;
      this.pinnedBottomRowETSPosition = [
        {
          symbol: "Total",
          costValue: position.sumCostValue,
          marketValue: position.sumMarketValue,
          unrealized: this.$numeral(position.sumUnrealized).format("+0,0.00"),
          unrealizedPercent: this.$numeral(this.$numeral(position.sumUnrealizedPercent).value() / 100).format("+0,0.00%"),
          realized: this.$numeral(position.sumRealized).format("+0,0.00"),
        },
      ];
    },
    onETSPositionGridReady(params) {
      this.gridETSPositionApi = params.api;
      this.columnETSPositionApi = params.columnApi;
    },
    onCellETSPositionFocused(event) {
      this.gridETSPositionOptions.api.forEachNode((node) => {
        if (event.rowIndex === node.rowIndex) {
          node.setSelected(true);
          return;
        }
      });
    },
    onRowETSPositionSelected(params) {
      const symbolSelectedRows = params.api.getSelectedRows();

      if (symbolSelectedRows.length === 1) {
        if (params.node.selected && params.data) {
          const symbol = {
            symbol: params.data.symbol,
            value: {
              ...getBySymbolAndExchangeId(params.data.symbol, 1),
            },
            fromUID: "outside",
          };
          this.$store.set("global/currentQuoteSelectedSymbol", symbol);
        }
      }
    },
    etsAccountPositionCinf(position) {
      if (position.refId !== this.socketRef) return;
      if (!this.onShow) return;

      this.updateRowETSPosition(position);
      if (this.gridETSPositionApi) {
        this.gridETSPositionApi.hideOverlay();
      }
      this.$EventBus.$emit("onPortfolioTableStopRefresh");
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
  },
  created() {
    this.gridETSPositionOptions = {};
    this.AR02();

    this.$EventBus.$on("ctinf/AS02", this.etsAccountPositionCinf);

    this.$EventBus.$on("onPortfolioTableStartRefresh", this.onPortfolioTableStartRefresh);
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS02", this.etsAccountPositionCinf);

    this.$EventBus.$off("onPortfolioTableStartRefresh", this.onPortfolioTableStartRefresh);
  },
};
</script>

<style scoped>
@import "~ag-grid-community/dist/styles/ag-grid.css";
/* @import "~ag-grid-community/dist/styles/ag-theme-balham.css"; */
@import "~ag-grid-community/dist/styles/ag-theme-balham-dark.css";
</style>

<style>
.ag-theme-balham div.ag-row-selected {
  background-color: #b7e4ff !important;
}

.ag-theme-balham-dark div.ag-row-selected {
  background-color: #005880 !important;
}

div.ag-cell-value {
  display: flex;
  align-items: center;
  font-weight: 450;
}

span.ag-header-cell-text {
  color: #dcddde;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ag-theme-balham-dark {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-root {
  border: 0px;
}

.ag-theme-balham-dark .ag-row {
  border: 0px;
}

.ag-theme-balham-dark .ag-row-odd {
  background-color: #2f383f;
}

.ag-theme-balham-dark .ag-row-even {
  background-color: #262d33;
}

.ag-theme-balham-dark .ag-header {
  background-color: #1b2025;
  border-bottom: 0px;
}

div.ag-root-wrapper-body.ag-layout-normal > div > div.ag-floating-bottom > div.ag-pinned-left-floating-bottom > div > div {
  color: #dcddde !important;
}
</style>
