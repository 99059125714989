<template>
  <div class="input-all-account" style="display: inline-flex; vertical-align: middle">
    <div style="display: flex; flex-direction: row; width: 100%">
      <div class="ui input" style="flex: 1">
        <input
          ref="input"
          v-model="text"
          type="text"
          :placeholder="placeholder"
          style="text-transform: uppercase"
          @keyup.enter="onEnter"
          @focus="onFocus"
          @blur="onBlur"
        />
      </div>
      <div style="position: relative; height: 100%; line-height: normal; margin-left: 0.3rem">
        <div class="ht-market-btn" style="display: flex; flex-direction: row; align-items: center; height: 100%">
          <sui-label v-if="isEnableETS()" size="mini" style="cursor: pointer" :class="{ active: !marketSelection }" @click="changeMarket('SET')"
            >SET</sui-label
          >
          <sui-label v-if="isEnableDTS()" size="mini" style="cursor: pointer" :class="{ active: marketSelection }" @click="changeMarket('TFEX')"
            >TFEX</sui-label
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import { isEnableETS, isEnableDTS } from "@/utils/LicenseUtils.js";

let timeout;
export default {
  props: {
    value: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isEnableETS,
      isEnableDTS,
      text: null,
      displayErrorText: null,
      canChangeMarket: false,
      market: "SET",
      marketSelection: false,
    };
  },
  watch: {
    text(value) {
      this.$emit("input", value?.toUpperCase() || "");
    },
    value(modelValue) {
      this.text = modelValue;
    },
  },
  created() {
    const isETS = isEnableETS();
    const isDTS = isEnableDTS();

    this.text = this.value;

    if (isETS) {
      this.market = "SET";
      this.marketSelection = false;
    } else if (isDTS) {
      this.market = "TFEX";
      this.marketSelection = true;
    }
    if (isETS && isDTS) {
      this.canChangeMarket = true;
    }

    this.$EventBus.$on("accountFreeText", this.onReceiveAccount);
    this.$EventBus.$on("accountFreeMarket", this.onReceiveMarket);
  },
  beforeDestroy() {
    this.$EventBus.$off("accountFreeText", this.onReceiveAccount);
    this.$EventBus.$off("accountFreeMarket", this.onReceiveMarket);
  },
  methods: {
    changeMarket(market) {
      if (!this.canChangeMarket) {
        return;
      }
      this.market = market;
      if (market === "SET") {
        this.marketSelection = false;
      } else if (market === "TFEX") {
        this.marketSelection = true;
      }
      this.$EventBus.$emit("accountFreeMarket", this._uid, this.market);
    },
    onFocus() {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        this.select();
      }, 100);
    },
    onBlur() {
      if (this.currentAccount && this.currentAccount !== "all_accounts") {
        this.text = this.currentAccount.split("_").pop();
      }
    },
    onEnter(e) {
      let exchangeId;
      if (this.market === "SET") {
        exchangeId = 1;
      } else if (this.market === "TFEX") {
        exchangeId = 2;
      } else {
        return;
      }

      if (!this.text) {
        this.$EventBus.$emit("accountFreeText", this._uid, this.market, "");
        this.$emit("enter", {
          exchangeId: exchangeId,
          accountCode: this.text?.toUpperCase() || "",
        });
        return;
      }

      this.$EventBus.$emit("accountFreeText", this._uid, this.market, this.text.toUpperCase());

      this.$nextTick(() => {
        this.$emit("enter", {
          exchangeId: exchangeId,
          accountCode: this.text?.toUpperCase() || "",
        });
      });
    },
    onReceiveAccount(uid, market, account) {
      if (uid !== this._uid) {
        this.market = market;
        this.text = account;
        if (this.market === "SET") {
          this.marketSelection = false;
        } else if (this.market === "TFEX") {
          this.marketSelection = true;
        }
      }
    },
    onReceiveMarket(uid, market) {
      if (uid !== this._uid) {
        this.market = market;
        if (this.market === "SET") {
          this.marketSelection = false;
        } else if (this.market === "TFEX") {
          this.marketSelection = true;
        }
      }
    },
    focus() {
      this.$refs.input.focus();
    },
    select() {
      if (this.$refs.input) {
        this.$refs.input.select();
      }
    },
  },
  computed: {
    currentAccount: get("global/currentAccount"),
  },
};
</script>

<style lang="scss" scoped>
.ui.input input {
  color: #dcddde;
  background: #2f383f;
  padding: 0 0.25rem;
  font-weight: 700;
  height: 2rem;
  border-right: 0 !important;
}
.ui.input input:focus {
  color: #dcddde !important;
  background: #2f383f !important;
  border-right: 0 !important;
}
.ui.input input:focus-within {
  border-right: 0 !important;
}
.ui.input input:read-only {
  border: 0 !important;
  opacity: 0.45 !important;
  cursor: not-allowed !important;
}
a.ui {
  height: 2rem;
}

.ht-market-btn .active {
  background: #c88841 !important;
  color: #fff !important;
}
</style>
