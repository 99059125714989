<template>
  <div class="ui equal width form" style="padding: 0.5rem; height: calc(25vh - 32px - 5px); overflow: auto;">
    <div class="fields">
      <div class="field">
        <h5 class="ui inverted grey header">Margin Rate</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">{{ $numeral($numeral(accountCreditBalance.marginRate).value() / 100).format("0.00%") }}</h5>
      </div>
      <div class="field">
        <h5 class="ui inverted grey header">Loan Limit</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">{{ accountCreditBalance.loanLimit }}</h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui inverted grey header">Deposit Temporary</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ accountCreditBalance.depositTemporary }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui inverted grey header">Purchasing Power</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ accountCreditBalance.purchasingPower }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui inverted grey header">Buy Available</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ accountCreditBalance.buyAvailable }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui inverted grey header">Short Available</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ accountCreditBalance.shortAvailable }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui inverted grey header">Mark to Market EE</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">{{ accountCreditBalance.markEE }}</h5>
      </div>
      <div class="field">
        <h5 class="ui inverted grey header">%Mark to Market EE</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ accountCreditBalance.markEEPercentage }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="four wide field">
        <h5 class="ui inverted grey header">Loan of Open Order</h5>
      </div>
      <div class="twelve wide field">
        <h5 class="ui yellow header">{{ accountCreditBalance.loanOfOpen }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

export default {
  props: ["onShow"],
  data: () => ({
    accountCreditBalance: {
      marginRate: "",
      loanLimit: "",
      depositTemporary: "",
      purchasingPower: "",
      buyAvailable: "",
      shortAvailable: "",
      markEE: "",
      markEEPercentage: "",
      loanOfOpen: ""
    }
  }),
  watch: {
    currentSelectedAccount() {
      if (!this.onShow) return;

      this.AR03();
    }
  },
  created() {
    this.AR03();

    this.$EventBus.$on("ctinf/AS03", this.etsAccountCreditBalanceCinf);

    this.$EventBus.$on("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS03", this.etsAccountCreditBalanceCinf);

    this.$EventBus.$off("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
  },
  methods: {
    onPortfolioInfoStartRefresh() {
      if (!this.onShow) return;

      this.AR03();
    },
    AR03() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount.exchangeId == 0) {
        this.accountCreditBalance = {
          marginRate: "",
          loanLimit: "",
          depositTemporary: "",
          purchasingPower: "",
          buyAvailable: "",
          shortAvailable: "",
          markEE: "",
          markEEPercentage: "",
          loanOfOpen: ""
        };
        this.$EventBus.$emit("onPortfolioInfoStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("AR03");

      msg.accountCode.set(this.currentSelectedAccount.accountCode);

      this.$ws.send(msg);
    },
    etsAccountCreditBalanceCinf(info) {
      if (!this.onShow) return;

      this.accountCreditBalance = { ...this.accountCreditBalance, ...info };
      this.$EventBus.$emit("onPortfolioInfoStopRefresh");
    }
  },
  computed: {
    currentSelectedAccount: get("global/currentSelectedAccount")
  }
};
</script>

<style scoped>
.fields {
  margin: 0.5rem !important;
}
</style>
