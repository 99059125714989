<template>
  <div>
    <sui-modal v-model="dialog" size="large" class="ht-settings-modal" :closable="false">
      <sui-modal-header>
        <span>Settings</span>
        <div class="close" @click="close"><i class="close grey icon"></i></div>
      </sui-modal-header>
      <div style="clear: float"></div>
      <sui-modal-content>
        <sui-tab :menu="{ vertical: true, fluid: true, tabular: true }" @change="handleTabChange" :active-index.sync="activeIndex">
          <sui-tab-pane title="Change Password">
            <SettingPasswordTab ref="password" @submit="save" />
          </sui-tab-pane>
          <sui-tab-pane v-if="pinCanChange === 'Y' && userType === 'I'" title="Change PIN">
            <SettingPinTab ref="pin" @submit="save" />
          </sui-tab-pane>
          <sui-tab-pane v-if="otpEnable === 'Y'" title="2-Factor Authentication">
            <Setting2FactorAuthentication ref="otp" :has-changed="is2FactorAuthenticationHasChanged" @submit="save" />
          </sui-tab-pane>
          <sui-tab-pane title="Keyboard Shortcuts">
            <SettingKeyboardShortcutsTab ref="shortcuts" @submit="save" />
          </sui-tab-pane>
          <sui-tab-pane title="Appearance">
            <div class="font-size-setting-container">
              <div>{{ "Font Size :" }}</div>
              <div class="slider-container">
                <input type="range" min="1" max="2" value="1" step="0.25" list="font-size" v-model="fontSize" />
                <div class="font-size-list">
                  <div>
                    {{ "1X" }}
                  </div>
                  <div>{{ "1.25X" }}</div>
                  <div>
                    {{ "1.5X" }}
                  </div>
                  <div>{{ "1.75X" }}</div>
                  <div>
                    {{ "2X" }}
                  </div>
                </div>
              </div>
            </div>
          </sui-tab-pane>
        </sui-tab>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button type="button" positive :loading="processing" :disabled="processing" @click="save">Save</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

import SettingPasswordTab from "./settings/SettingPasswordTab";
import SettingPinTab from "./settings/SettingPinTab";
import SettingKeyboardShortcutsTab from "./settings/SettingKeyboardShortcutsTab";
import Setting2FactorAuthentication from "./settings/Setting2FactorAuthentication";

import _otp from "@/api/otp";

export default {
  components: {
    SettingPasswordTab,
    SettingPinTab,
    SettingKeyboardShortcutsTab,
    Setting2FactorAuthentication,
  },
  data() {
    return {
      processing: false,
      dialog: false,
      activeIndex: 0,
      activeTitle: "Change Password",
      is2FactorAuthenticationHasChanged: false,
      oldFontSize: 1,
    };
  },
  watch: {
    activeTitle(activeTitle) {
      if (this.is2FactorAuthenticationHasChanged && activeTitle !== "2-Factor Authentication") {
        this.$Swal.fire("Warning!", "Your 2-Factor Authentication has been changed.<br>Please save before changing tabs.", "warning").then(() => {
          if (this.pinCanChange === "Y" && this.userType === "I") {
            this.activeIndex = 2;
          } else {
            this.activeIndex = 1;
          }
        });
      }
    },
  },
  created() {
    this.$EventBus.$on("settings", this.onSettings);
    this.$EventBus.$on("2FactorAuthenticationHasChanged", this.on2FactorAuthenticationHasChanged);

    this.oldFontSize = this.fontSize;
  },
  beforeDestroy() {
    this.$EventBus.$off("settings", this.onSettings);
    this.$EventBus.$off("2FactorAuthenticationHasChanged", this.on2FactorAuthenticationHasChanged);
  },
  methods: {
    close() {
      if (this.otpEnable === "Y" && this.activeTitle === "2-Factor Authentication") {
        this.$refs.otp.save();
      }

      if (this.oldFontSize !== this.fontSize) {
        this.fontSize = this.oldFontSize;
      }

      this.dialog = false;
    },
    onSettings() {
      this.processing = false;
      this.dialog = true;
      this.activeIndex = 0;
      this.$nextTick(() => {
        this.$refs.password.resetForm();
      });
    },
    handleTabChange(e, activePane, index) {
      this.activeTitle = activePane.title;
    },
    save() {
      this.processing = true;
      this.$nextTick(() => {
        let result = null;
        if (this.activeTitle === "Change Password") {
          // password tab
          result = this.$refs.password.save();
        } else if (this.activeTitle === "Change PIN") {
          // pin
          result = this.$refs.pin.save();
        } else if (this.activeTitle === "2-Factor Authentication") {
          // otp
          result = this.$refs.otp.save();
        } else if (this.activeTitle === "Keyboard Shortcuts") {
          // shortcuts tab
          result = this.$refs.shortcuts.save();
        } else if (this.activeTitle === "Appearance") {
          const settings = localStorage.getItem("settings");
          let newSetting = null;

          if (settings) {
            newSetting = JSON.parse(settings);
            newSetting.fontSize = this.fontSize;
            newSetting = { fontSize: this.fontSize };
          } else {
            newSetting = { fontSize: this.fontSize };
          }

          this.oldFontSize = this.fontSize;
          localStorage.setItem("settings", JSON.stringify(newSetting));
          return this.$Swal.fire(`Complete`, `Your setting has been updated.`, "success").then(() => {
            this.processing = false;
          });
        }

        if (result) {
          result
            .then(() => {
              this.$Swal.fire(`Complete`, `Your setting has been updated.`, "success").then(() => {
                this.processing = false;
              });
            })
            .catch((e) => {
              this.processing = false;
              if (e?.message) {
                this.$Swal.fire(`Error!`, e.message, "error");
              }
            });
        } else {
          this.processing = false;
        }
      });
    },
    on2FactorAuthenticationHasChanged() {
      this.is2FactorAuthenticationHasChanged = true;
    },
  },
  computed: {
    userType: get("model/systemRefData@userType"),
    otpEnable: get("model/systemRefData@otpEnable"),
    pinCanChange: get("model/systemRefData@pinCanChange"),
    fontSize: sync("global/settings@fontSize"),
  },
};
</script>

<style lang="scss" scoped>
.ht-settings-modal .ui.tab.segment.attached {
  height: 50vh;
}
.ht-settings-modal .ui.modal > .content {
  height: calc(50vh + 45px);
}

.ht-settings-modal .font-size-setting-container {
  display: flex;
  gap: 0 1rem;
  font-weight: bold;

  .slider-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  input[type="range"] {
    accent-color: #efaf0a;
  }

  input {
    &:hover {
      cursor: pointer;
    }
  }

  .font-size-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 7px;
    padding: 0;

    div {
      position: relative;

      &::before {
        content: "";
        width: 2px;
        height: 5px;
        position: absolute;
        top: -5px;
        background-color: white;
        left: calc(50% - 1px);
      }
    }
  }
}
</style>
