<template>
  <div class="progress-bar-value" ref="progress-bar-value">
    <sui-tab :menu="{ attached: 'top' }" class="progress-bar-value-tabs">
      <sui-tab-pane :title="`${mode} Value`" attached="top" class="progress-bar-value-tab">
        <apexchart type="bar" :width="dynamicWidth" :height="dynamicHeight" :options="chartOptions" :series="series" :key="updateKey" />
      </sui-tab-pane>
      <div style="position: absolute; top: 5px; right: 0.25rem; cursor: pointer">
        <i class="refresh alternate icon" :class="{ loading: isLoading }" style="color: #dcddde" @click="onRefresh" />
      </div>
    </sui-tab>
  </div>
</template>

<script>
import { sync } from "vuex-pathify";

const series = [
  {
    name: "Buy",
    data: [0, 0],
  },
  {
    name: "Sell",
    data: [0, 0],
  },
  {
    name: "Other",
    data: [0, 0],
  },
];

const chartOptions = {
  theme: {
    mode: "dark",
  },
  grid: {
    borderColor: "#2F383F",
  },
  fill: {
    type: "gradient",
  },
  chart: {
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false,
      tools: {
        selection: false,
        zoom: false,
      },
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "60%",
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#2185d0", "#E03997", "#E4AF0A"],
  stroke: {
    width: 0,
    color: "#1B2025",
  },
  xaxis: {
    categories: ["%Value Brk.", "Total Value"],
  },
  tooltip: {
    y: {
      formatter: (value, opts) => {
        let pct = "0%";
        if (opts.w.globals.seriesPercent[opts.seriesIndex]?.[opts.dataPointIndex]) {
          pct = opts.w.globals.seriesPercent[opts.seriesIndex][opts.dataPointIndex].toFixed(2) + "%";
        }
        if (value) {
          return value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ` (${pct})`;
        } else {
          return "0" + ` (${pct})`;
        }
      },
    },
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    offsetX: 0,
  },
};

export default {
  name: "ValueBarChart",
  components: {},
  props: ["mode", "data"],
  data: () => ({
    isLoading: false,
    series: series,
    chartOptions: chartOptions,
    dynamicHeight: 100,
    dynamicWidth: 100,
    updateKey: 100,
  }),
  watch: {
    data(data) {
      if (data.sides?.length && this.mode === "Group") {
        this.series = [
          {
            name: "Buy",
            data: [this.$numeral(data.sides[0].val.toFixed(0)).value(), this.$numeral(data.sides[0].val.toFixed(0)).value()],
          },
          {
            name: "Sell",
            data: [this.$numeral(data.sides[1].val.toFixed(0)).value(), this.$numeral(data.sides[1].val.toFixed(0)).value()],
          },
          {
            name: "Other",
            data: [
              this.$numeral(data.totalBrkVal.toFixed(0)).value() -
                (this.$numeral(data.sides[0].val.toFixed(0)).value() + this.$numeral(data.sides[1].val.toFixed(0)).value()),
              0,
            ],
          },
        ];
      } else {
        this.series = series;
      }
    },
  },
  created() {
    this.$EventBus.$on("onValueStartRefresh", this.onValueStartRefresh);
    this.$EventBus.$on("onValueStopRefresh", this.onValueStopRefresh);
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    this.$EventBus.$off("onValueStartRefresh", this.onValueStartRefresh);
    this.$EventBus.$off("onValueStopRefresh", this.onValueStopRefresh);
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onRefresh() {
      this.$EventBus.$emit("onValueStartRefresh");
    },
    onValueStartRefresh() {
      this.isLoading = true;
    },
    onValueStopRefresh() {
      this.isLoading = false;
    },
    onResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }

      this.dynamicHeight = 0;
      this.dynamicWidth = 0;

      this.resizeTimeout = setTimeout(() => {
        const chartContainer = this.$refs["progress-bar-value"];

        this.dynamicHeight = chartContainer.clientHeight - 32;
        this.dynamicWidth = chartContainer.clientWidth - 50;
        this.updateKey++;
      }, 200);
    },
  },
  computed: {
    theme: sync("global/theme"),
  },
};
</script>

<style lang="scss" scoped>
.progress-bar-value {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .progress-bar-value-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;

    .progress-bar-value-tab {
      height: calc(100% - 32px);
      align-items: center;
      display: flex;
      justify-content: center;

      .empty-text {
        font-size: 12px;
        color: #dcddde;
      }
    }
  }
}

.apexcharts-canvas.dark {
  background: #262d33 !important;
}

.item.active {
  background-color: #262d33 !important;
}

.ui.attached.menu.top {
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
  min-height: unset !important;
}

a.item {
  height: 32px !important;
  padding: 0px 1rem !important;
  font-weight: 800 !important;
  color: #dcddde !important;
}

a.item > span {
  padding: 0.25rem !important;
}

a.item.active > span {
  color: #c88841 !important;
  border-radius: 0.5rem !important;
  padding: 0.25rem !important;
  border: 1px solid #c88841 !important;
}

.ui.tab.segment.attached.active.top {
  background-color: #262d33 !important;
  border: 0px !important;
  padding: 0px !important;
  /* margin-top: -8px !important; */
}
</style>
