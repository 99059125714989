<template>
  <div class="ui equal width form" style="padding: 0rem">
    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">
          {{ securityInfoLatest.name || "-" }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Prev. OI</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ $numeral(securityInfoLatest.prevOI).format("0,0") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Volume</h5>
      </div>
      <div class="field">
        <h5 ref="securityVolume" class="ui yellow header">
          {{ $numeral(securityInfoLatest.volume).format("0,0") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Prev. Settle</h5>
      </div>
      <div class="field">
        <h5 class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.prevSettlePrice)]">
          {{ price(securityInfoLatest.prevSettlePrice) || "0.00" }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Settlement</h5>
      </div>
      <div class="field">
        <h5 class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.settlementPrice)]">
          {{ price(securityInfoLatest.settlementPrice) || "0.00" }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Basis</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ price(securityInfoLatest.basis) || "0.00" }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Underlying</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ price(securityInfoLatest.underlying) || "0.00" }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">High</h5>
      </div>
      <div class="field">
        <h5 ref="securityHigh" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.high)]">
          {{ price(securityInfoLatest.high) || "0.00" }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Low</h5>
      </div>
      <div class="field">
        <h5 ref="securityLow" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.low)]">
          {{ price(securityInfoLatest.low) || "0.00" }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Ceilling</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">
          {{ price(securityInfoLatest.ceiling) || "0.00" }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Floor</h5>
      </div>
      <div class="field">
        <h5 class="ui pink header">
          {{ price(securityInfoLatest.floor) || "0.00" }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Open 0 (Night)</h5>
      </div>
      <div class="field">
        <h5 class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.open0)]">
          {{ price(securityInfoLatest.open0) || "0.00" }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Average</h5>
      </div>
      <div class="field">
        <h5 ref="securityAvg" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.average)]">
          {{ price(securityInfoLatest.average) || "0.00000" }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Open 1</h5>
      </div>
      <div class="field">
        <h5 class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.open1)]">
          {{ price(securityInfoLatest.open1) || "0.00" }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Open 2</h5>
      </div>
      <div class="field">
        <h5 class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.open2)]">
          {{ price(securityInfoLatest.open2) || "0.00" }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Long Vol</h5>
      </div>
      <div class="field">
        <h5 ref="securityLongVolume" class="ui yellow header">
          {{ $numeral(securityInfoLatest.longVolume).format("0,0") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Short Vol</h5>
      </div>
      <div ref="securityShortVolume" class="field">
        <h5 class="ui yellow header">
          {{ $numeral(securityInfoLatest.shortVolume).format("0,0") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Long Avg</h5>
      </div>
      <div class="field">
        <h5 ref="securityAverageLong" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.averageLong)]">
          {{ price(securityInfoLatest.averageLong) || "0.00" }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Short Avg</h5>
      </div>
      <div class="field">
        <h5 ref="securityAverageShort" class="ui header" :class="[getClassColorCompare(securityInfoLatest, securityInfoLatest.averageShort)]">
          {{ price(securityInfoLatest.averageShort) || "0.00" }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Contract Size</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ $numeral(securityInfoLatest.contractSize).format("0,0") }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Tick Size</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ $numeral(securityInfoLatest.tickSize).format("0,0.00") }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Flag</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">
          {{ securityInfoLatest.flag || "-" }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Close</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ price(securityInfoLatest.prior) || "0.00" }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Market Status</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">{{ getMarketStatusByKey(securityInfoLatest.mktStCode) || "-" }}</h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Last Trade Date</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">
          {{ securityInfoLatest.expDate || "-" }}
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { getSecurityTypeByKey } from "@/utils/SecurityTypeFormatter.js";
import { getMarketStatusByKey } from "@/utils/MarketStatusFormatter.js";
import { price } from "@/utils/NumberFormatter";

export default {
  name: "QuoteDTSInfo",
  props: ["currentQuoteSelectedSymbol"],
  data: () => ({
    securityInfoLatest: {},
  }),
  watch: {
    currentQuoteSelectedSymbol(currentQuoteSelectedSymbol) {
      if (currentQuoteSelectedSymbol === null) {
        this.securityInfoLatest = {};
        return;
      }

      this.SR01(currentQuoteSelectedSymbol.value);
    },
  },
  created() {
    if (this.currentQuoteSelectedSymbol) {
      this.SR01(this.currentQuoteSelectedSymbol.value);
    } else {
      this.$EventBus.$emit("onQuoteInfoStopRefresh");
    }

    this.$EventBus.$on("ctinf/SS51", this.dtsSecurityInfoUpdateCinf);
    this.$EventBus.$on("bcst/PB51", this.updateSecurityInfo);

    this.$EventBus.$on("onQuoteInfoStartRefresh", this.onQuoteInfoStartRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS51", this.dtsSecurityInfoUpdateCinf);
    this.$EventBus.$off("bcst/PB51", this.updateSecurityInfo);

    this.$EventBus.$off("onQuoteInfoStartRefresh", this.onQuoteInfoStartRefresh);
  },
  methods: {
    getSecurityTypeByKey,
    getMarketStatusByKey,
    price,
    onQuoteInfoStartRefresh() {
      this.securityInfoLatest = {};
      if (this.currentQuoteSelectedSymbol) {
        this.SR01(this.currentQuoteSelectedSymbol.value);
      } else {
        this.$EventBus.$emit("onQuoteInfoStopRefresh");
      }
    },
    SR01(selectedSymbol) {
      if (!selectedSymbol) {
        this.securityInfoLatest = {};
        this.$EventBus.$emit("onQuoteInfoStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("SR01");

      msg.securityId.set(selectedSymbol.securityId);
      msg.lastTradeCount.set(5);
      msg.full.set("Y");
      msg.exchangeId.set(selectedSymbol.exchangeId);

      this.$ws.send(msg);
    },
    getClassColorCompare(securityInfoLatest, comparison) {
      const ceiling = this.$numeral(securityInfoLatest.ceiling).value();
      const floor = this.$numeral(securityInfoLatest.floor).value();

      const prior = this.$numeral(securityInfoLatest.prevSettlePrice).value();
      comparison = this.$numeral(comparison).value();

      if (!comparison) {
        return "yellow";
      } else if (comparison >= ceiling) {
        return "blue";
      } else if (comparison > prior) {
        return "green";
      } else if (comparison <= floor) {
        return "pink";
      } else if (comparison < prior) {
        return "red";
      } else {
        return "yellow";
      }
    },
    dtsSecurityInfoUpdateCinf(dtsSecurityInfoUpdateCinf) {
      this.securityInfoLatest = Object.assign({}, dtsSecurityInfoUpdateCinf);
    },
    updateSecurityInfo(update) {
      if (update.securityId !== this.securityInfoLatest.securityId) {
        return;
      }

      if (this.securityInfoLatest.volume !== update.totalVolume) {
        this.blinkBackground("securityVolume");
      }
      this.securityInfoLatest.volume = update.totalVolume;

      if (this.securityInfoLatest.value !== update.totalValue) {
        this.blinkBackground("securityAvg");
      }
      this.securityInfoLatest.value = update.totalValue;

      if (this.$numeral(update.price).value() > this.$numeral(this.securityInfoLatest.high).value()) {
        if (this.securityInfoLatest.high !== update.price) {
          this.blinkBackground("securityHigh");
        }
        this.securityInfoLatest.high = update.price;
      }

      if (this.$numeral(update.price).value() < this.$numeral(this.securityInfoLatest.low).value()) {
        if (this.securityInfoLatest.low !== update.price) {
          this.blinkBackground("securityLow");
        }
        this.securityInfoLatest.low = update.price;
      }

      if (this.$numeral(update.totalValue).value() && this.$numeral(update.totalVolume).value()) {
        this.securityInfoLatest.average = this.$numeral(update.totalValue).value() / this.$numeral(update.totalVolume).value();
      }

      if (this.securityInfoLatest.buyVolume !== update.buyVolume) {
        this.blinkBackground("securityLongVolume");
      }
      this.securityInfoLatest.longVolume = update.longVolume;

      if (this.securityInfoLatest.sellVolume !== update.sellVolume) {
        this.blinkBackground("securityShortVolume");
      }
      this.securityInfoLatest.shortVolume = update.shortVolume;

      if (this.$numeral(update.longValue).value() && this.$numeral(update.longVolume).value()) {
        const averageLong = this.$numeral(update.longValue).value() / this.$numeral(update.longVolume).value();
        if (this.securityInfoLatest.averageLong !== averageLong) {
          this.blinkBackground("securityAverageLong");
        }
        this.securityInfoLatest.averageLong = averageLong;
      }

      if (this.$numeral(update.shortValue).value() && this.$numeral(update.shortVolume).value()) {
        const averageShort = this.$numeral(update.shortValue).value() / this.$numeral(update.shortVolume).value();
        if (this.securityInfoLatest.averageShort !== averageShort) {
          this.blinkBackground("securityAverageShort");
        }
        this.securityInfoLatest.averageShort = averageShort;
      }
    },
  },
};
</script>

<style scoped>
.fields {
  margin: 0.5rem !important;
}

.ui.header.blink {
  background-color: rgba(22, 160, 133, 0.5) !important;
}
.ui.header.up {
  color: #00aa00 !important;
}
.ui.header.down {
  color: #d91e18 !important;
}
</style>
