/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsResMsg from "../WsResMsg";

class BS04 extends WsResMsg {
	constructor() {
		super(1);
		this._resultCode = new NumberMessageBase();
		this._resultMessage = new StringMessageBase();
		this._brokerVol = new NumberMessageBase();
		this._brokerVolMktPct = new NumberMessageBase();
		this._brokerVal = new NumberMessageBase();
		this._brokerValMktPct = new NumberMessageBase();
		this._commission = new NumberMessageBase();
		this._records = new ArrayMessageBase(Record);
	}
	
	get resultCode() {
		return this._resultCode;
	}
	
	get resultMessage() {
		return this._resultMessage;
	}
	
	get brokerVol() {
		return this._brokerVol;
	}
	
	get brokerVolMktPct() {
		return this._brokerVolMktPct;
	}
	
	get brokerVal() {
		return this._brokerVal;
	}
	
	get brokerValMktPct() {
		return this._brokerValMktPct;
	}
	
	get commission() {
		return this._commission;
	}
	
	get records() {
		return this._records;
	}
}

export class Record {
	constructor() {
		this._recordId = new NumberMessageBase();
		this._rank = new NumberMessageBase();
		this._departmentName = new StringMessageBase();
		this._buyVal = new NumberMessageBase();
		this._sellVal = new NumberMessageBase();
		this._totalVal = new NumberMessageBase();
		this._totalValBrkPct = new NumberMessageBase();
		this._commission = new NumberMessageBase();
		this._commissionBrkPct = new NumberMessageBase();
	}
	
	get recordId() {
		return this._recordId;
	}
	
	get rank() {
		return this._rank;
	}
	
	get departmentName() {
		return this._departmentName;
	}
	
	get buyVal() {
		return this._buyVal;
	}
	
	get sellVal() {
		return this._sellVal;
	}
	
	get totalVal() {
		return this._totalVal;
	}
	
	get totalValBrkPct() {
		return this._totalValBrkPct;
	}
	
	get commission() {
		return this._commission;
	}
	
	get commissionBrkPct() {
		return this._commissionBrkPct;
	}
}

export default BS04;
