<template>
  <div class="market-stock-graph-buy-sell" ref="market-stock-graph-buy-sell">
    <apexchart type="bar" :height="height || 105" :options="buysellChartOptions" :series="buysellSeries" class="market-stock-graph-buy-sell" />
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";

const buysellSeries = [
  {
    name: "Buy",
    data: [],
  },
  {
    name: "Sell",
    data: [],
  },
];

const buysellChartOptions = {
  theme: {
    mode: "dark",
  },
  grid: {
    show: false,
    borderColor: "#2F383F",
  },
  fill: {
    type: "gradient",
  },
  chart: {
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "10%",
    },
  },
  xaxis: {
    type: "category",
    categories: [],
    labels: {
      rotate: -90,
      show: true,
    },
  },
  tooltip: {
    theme: "dark",
    x: {
      formatter: (value) => "Price: " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    },
    y: {
      formatter: (value) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      title: {
        formatter: (seriesName) => seriesName,
      },
    },
  },
  legend: {
    show: false,
  },
  colors: ["#2185d0", "#e03997"],
  dataLabels: {
    enabled: false,
  },
};

export default {
  name: "MarketStockGraphBuySell",
  components: {},
  data: () => ({
    buysellSeries: buysellSeries,
    buysellChartOptions: buysellChartOptions,
    height: 170,
  }),
  watch: {
    currentQuoteSelectedSymbol(currentQuoteSelectedSymbol) {
      if (currentQuoteSelectedSymbol) {
        this.init();
        this.SR06(currentQuoteSelectedSymbol.value.securityId, currentQuoteSelectedSymbol.value.exchangeId);
      } else {
        this.init();
      }
    },
  },
  created() {
    this.onMarketGraphBuySellStartRefresh();

    this.$EventBus.$on("ctinf/SS06", this.securityTradeByPriceCtinf);
    this.$EventBus.$on("onMarketGraphBuySellStartRefresh", this.onMarketGraphBuySellStartRefresh);
    this.$EventBus.$on("market-bbo-size-change", this.onSizeChange);
  },

  mounted() {
    this.onSizeChange();
  },

  beforeDestroy() {
    this.$EventBus.$off("ctinf/SS06", this.securityTradeByPriceCtinf);
    this.$EventBus.$off("onMarketGraphBuySellStartRefresh", this.onMarketGraphBuySellStartRefresh);
    this.$EventBus.$off("market-bbo-size-change", this.onSizeChange);
  },
  methods: {
    init() {
      this.buysellSeries = buysellSeries;
      this.buysellChartOptions = buysellChartOptions;
      this.$EventBus.$emit("onMarketGraphBuySellStopRefresh");
    },
    securityTradeByPriceCtinf(securityTradeByPriceCtinf) {
      let buyData = [];
      let sellData = [];
      let priceCategories = [];

      securityTradeByPriceCtinf.tradeGroups.reverse().forEach((tradeGroup) => {
        priceCategories.push(tradeGroup.price);
        buyData.push(this.$numeral(tradeGroup.buyVolume).value());
        sellData.push(this.$numeral(tradeGroup.sellVolume).value());
      });

      this.buysellSeries = [
        {
          name: "Buy",
          data: buyData,
        },
        {
          name: "Sell",
          data: sellData,
        },
      ];

      this.buysellChartOptions = {
        ...buysellChartOptions,
        xaxis: {
          type: "category",
          categories: priceCategories,
          labels: {
            rotate: -90,
            show: priceCategories.length <= 12,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: priceCategories.length <= 3 ? "20%" : "80%",
          },
        },
      };
      this.$EventBus.$emit("onMarketGraphBuySellStopRefresh");
    },
    SR06(securityId, exchangeId) {
      const msg = this.$messageFactory.createMessage("SR06");

      msg.securityId.set(securityId);
      msg.exchangeId.set(exchangeId);

      this.$ws.send(msg);
    },
    onMarketGraphBuySellStartRefresh() {
      if (this.currentQuoteSelectedSymbol) {
        this.init();
        this.SR06(this.currentQuoteSelectedSymbol.value.securityId, this.currentQuoteSelectedSymbol.value.exchangeId);
      } else {
        this.init();
      }
    },
    onSizeChange() {
      this.height = 170;

      this.$nextTick(() => {
        const marketStockGraph = this.$refs["market-stock-graph-buy-sell"];
        this.height = marketStockGraph.clientHeight - 32;
      });
    },
  },
  computed: {
    theme: sync("global/theme"),
    currentQuoteSelectedSymbol: get("global/currentQuoteSelectedSymbol"),
  },
};
</script>

<style scoped>
.apexcharts-canvas.dark {
  background: #262d33 !important;
}

.item.active {
  background-color: #262d33 !important;
}

.ui.attached.menu.top {
  height: 32px !important;
  border: 0px !important;
  background-color: #1b2025 !important;
  min-height: unset !important;
}

a.item {
  height: 32px !important;
  padding: 0px 1rem !important;
  font-weight: 800 !important;
  color: #dcddde !important;
}

a.item > span {
  padding: 0.25rem !important;
}

a.item.active > span {
  color: rgba(200, 136, 65) !important;
  border-radius: 0.5rem !important;
  padding: 0.25rem !important;
  border: 1px solid rgba(200, 136, 65) !important;
}

.ui.tab.segment.attached.active.top {
  background-color: #262d33 !important;
  border: 0px !important;
  padding: 0px !important;
}
.market-stock-graph-buy-sell {
  height: 100%;
}
</style>
