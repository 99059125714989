const values = {
  A: "Active",
  D: "Disable"
};

const getCustomerStatusByKey = key => {
  return values[key] || key;
};

export { getCustomerStatusByKey };
