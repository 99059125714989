<template>
  <div class="ui equal width form" style="padding: 0.5rem; height: calc(25vh - 32px - 5px); overflow: auto">
    <div class="fields">
      <div class="four wide field">
        <h5 class="ui grey header inverted" :style="['M', 'U'].includes(traderType) ? 'line-height: 2rem;' : null">Account</h5>
      </div>
      <div class="twelve wide field">
        <AccountDropdown
          v-if="!['M', 'U'].includes(traderType)"
          ref="account"
          v-model="selectedAccount"
          :default="selectedAccount"
          :all-account-mode="false"
          market="ALL"
          name="Account"
          placeholder="Account"
          allow-blank
          style="min-width: 12rem; !important; min-width: 12rem; !important;"
          @enter="onAccountChanged"
        />
        <InputAllAccount
          v-else
          ref="account"
          v-model="selectedAccount"
          name="Account"
          placeholder="Account"
          style="min-width: 14rem; !important; min-width: 14rem; !important; line-height: 3rem;"
          @enter="onEnterInputAllAcount"
        />
      </div>
    </div>
    <div class="fields">
      <div class="four wide field">
        <h5 class="ui grey header inverted">Name</h5>
      </div>
      <div class="twelve wide field">
        <h5 class="ui yellow header">{{ accountInfo.accountName }}</h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Account Code</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">
          {{ currentSelectedAccount && currentSelectedAccount.exchangeId != "0" ? currentSelectedAccount.accountCode : "" }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Account Market</h5>
      </div>
      <div class="field">
        <h5 v-if="currentSelectedAccount && currentSelectedAccount.exchangeId == 1 && currentSelectedAccount.accountCode" class="ui blue header">
          Equity
        </h5>
        <h5 v-if="currentSelectedAccount && currentSelectedAccount.exchangeId == 2 && currentSelectedAccount.accountCode" class="ui blue header">
          Derivatives
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Customer Type</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">
          {{ getCustomerTypeByKey(accountInfo.customerType) }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Account Type</h5>
      </div>
      <div class="field">
        <h5 class="ui blue header">
          {{ getAccountTypeByKey(accountInfo.accountType) }}
        </h5>
      </div>
    </div>

    <div v-if="currentSelectedAccount && currentSelectedAccount.exchangeId == 2" class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Margin Type</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ getMarginTypeByKey(accountInfo.marginType) }}
        </h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Mark to Market</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">{{ accountInfo.markToMarket }}</h5>
      </div>
    </div>

    <div v-if="currentSelectedAccount && currentSelectedAccount.exchangeId == 2" class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Auto Net Position</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">{{ accountInfo.autonetPosition }}</h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Margin Method</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">
          {{ getMarginMethodByKey(accountInfo.marginMethod) }}
        </h5>
      </div>
    </div>

    <div class="fields">
      <div class="field">
        <h5 v-if="accountInfo.accountType === 'B'" class="ui grey header inverted">Excess Equity</h5>
        <h5 v-else class="ui grey header inverted">Buy Limit</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">{{ accountInfo.buyLimit }}</h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Credit Line</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">{{ accountInfo.creditLine }}</h5>
      </div>
    </div>

    <div v-if="currentSelectedAccount && currentSelectedAccount.exchangeId == 1" class="fields">
      <div class="field">
        <h5 class="ui grey header inverted">Total Bought</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">{{ accountInfo.totalBuy }}</h5>
      </div>
      <div class="field">
        <h5 class="ui grey header inverted">Total Sold</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">{{ accountInfo.totalSell }}</h5>
      </div>
    </div>

    <div class="fields">
      <div v-if="currentSelectedAccount && currentSelectedAccount.exchangeId == 1" class="field">
        <h5 class="ui grey header inverted">Cash Amount</h5>
      </div>
      <div v-else class="field">
        <h5 class="ui grey header inverted">Cash Balance</h5>
      </div>

      <div v-if="currentSelectedAccount && currentSelectedAccount.exchangeId == 1" class="field">
        <h5 class="ui yellow header">{{ accountInfo.cashAmount }}</h5>
      </div>
      <div v-else class="field">
        <h5 class="ui yellow header">{{ accountInfo.cashBalancePrev }}</h5>
      </div>

      <div class="field">
        <h5 class="ui grey header inverted">Comm & VAT</h5>
      </div>
      <div class="field">
        <h5 class="ui yellow header">{{ accountInfo.totalCommVat }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { get, sync } from "vuex-pathify";
import { getCustomerTypeByKey } from "@/utils/CustomerTypeFormatter.js";
import { getAccountTypeByKey } from "@/utils/AccountTypeFormatter.js";
import { getMarginTypeByKey } from "@/utils/MarginTypeFormatter.js";
import { getMarginMethodByKey } from "@/utils/MarginMethodFormatter.js";
import AccountDropdown from "@/components/AccountDropdown";
import InputAllAccount from "@/components/InputAllAccount";

let timeout = null;

export default {
  components: {
    AccountDropdown,
    InputAllAccount,
  },
  props: ["onShow"],
  data: () => ({
    selectedAccount: null,
    isEnterAccount: false,
    refId: null,
    accountInfo: {
      accountName: "",
      accountType: "",
      autonetPosition: "",
      buyLimit: "",
      cashBalancePrev: "",
      creditLine: "",
      customerType: "",
      ee: "",
      exchangeId: "",
      marginMethod: "",
      marginType: "",
      markToMarket: "",
      totalCommVat: "",
      totalBuy: "",
      totalSell: "",
      cashAmount: "",
      serviceType: "",
      commissionType: "",
      highLimit: "",
      purchasingPower: "",
    },
  }),
  watch: {
    currentSelectedAccount(account) {
      if (!account?.accountCode) {
        this.selectedAccount = null;
        this.AR01();
      }

      if (!this.onShow) return;

      this.AR01();
    },
    currentAccount(value) {
      if (value && value !== "all_accounts" && value !== "all_equity_accounts" && value !== "all_derivative_accounts") {
        this.selectedAccount = this.currentSelectedAccount?.accountCode;
      } else {
        this.selectedAccount = null;
      }
      if (this.isEnterAccount) {
        this.isEnterAccount = false;
        this.$nextTick(() => {
          this.$refs.account.select();
        });
      }
    },
  },
  created() {
    if (this.currentSelectedAccount) this.AR01();

    if (
      this.currentAccount &&
      this.currentAccount !== "all_accounts" &&
      this.currentAccount !== "all_equity_accounts" &&
      this.currentAccount !== "all_derivative_accounts"
    ) {
      this.selectedAccount = this.currentAccount;
    } else {
      this.selectedAccount = null;
    }

    this.$nextTick(() => {
      this.$refs.account.select();
    });

    this.$EventBus.$on("ctinf/AS01", this.etsAccountInfoCinf);
    this.$EventBus.$on("ctinf/AS51", this.dtsAccountInfoCinf);

    this.$EventBus.$on("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
  },
  beforeDestroy() {
    this.$EventBus.$off("ctinf/AS01", this.etsAccountInfoCinf);
    this.$EventBus.$off("ctinf/AS51", this.dtsAccountInfoCinf);

    this.$EventBus.$off("onPortfolioInfoStartRefresh", this.onPortfolioInfoStartRefresh);
  },
  methods: {
    onEnterInputAllAcount(account) {
      this.$store.set("global/currentAccount", account.accountCode);
      this.$store.set("global/currentSelectedAccount", {
        exchangeId: account.exchangeId,
        accountCode: account.accountCode,
      });
    },

    onAccountChanged(account) {
      this.isEnterAccount = true;
      if (account) {
        let exchangeId;
        let accountCode;

        if (account.text.includes("(Equity)")) exchangeId = "1";
        if (account.text.includes("(Derivative)")) exchangeId = "2";

        if (["all_accounts", "all_equity_accounts", "all_derivative_accounts"].includes(account.value)) {
          accountCode = account.value;
        } else {
          accountCode = account.value.split("_").pop();
        }

        this.$store.set("global/currentAccount", accountCode);
        this.$store.set("global/currentSelectedAccount", {
          exchangeId,
          accountCode,
        });
      } else {
        this.$store.set("global/currentAccount", null);
        this.$store.set("global/currentSelectedAccount", {
          exchangeId: "0",
          accountCode: null,
        });
      }
    },
    onPortfolioInfoStartRefresh() {
      if (!this.onShow) return;

      this.AR01();
    },
    getCustomerTypeByKey,
    getAccountTypeByKey,
    getMarginTypeByKey,
    getMarginMethodByKey,
    AR01() {
      if (!this.currentSelectedAccount || this.currentSelectedAccount.exchangeId == 0 || !this.currentSelectedAccount.accountCode) {
        this.accountInfo = {
          accountName: "",
          accountType: "",
          autonetPosition: "",
          buyLimit: "",
          cashBalancePrev: "",
          creditLine: "",
          customerType: "",
          ee: "",
          exchangeId: "",
          marginMethod: "",
          marginType: "",
          markToMarket: "",
          totalCommVat: "",
          totalBuy: "",
          totalSell: "",
          cashAmount: "",
          serviceType: "",
          commissionType: "",
          highLimit: "",
          purchasingPower: "",
        };
        this.$EventBus.$emit("onPortfolioInfoStopRefresh");
        return;
      }

      const msg = this.$messageFactory.createMessage("AR01");
      this.refId = this.$shortid.generate();

      msg.accountCode.set(this.currentSelectedAccount.accountCode);
      msg.exchangeId.set(this.currentSelectedAccount.exchangeId);

      this.$ws.send(msg, this.refId);
    },
    etsAccountInfoCinf(info) {
      if (info.refId !== this.refId) return;
      if (!this.onShow) return;

      this.accountInfo = { ...this.accountInfo, ...info };
      this.$EventBus.$emit("onPortfolioInfoStopRefresh");
    },
    dtsAccountInfoCinf(info) {
      if (!this.onShow) return;

      this.accountInfo = { ...this.accountInfo, ...info };
      this.$EventBus.$emit("onPortfolioInfoStopRefresh");
    },
  },
  computed: {
    currentAccount: get("global/currentAccount"),
    currentSelectedAccount: get("global/currentSelectedAccount"),
    traderType: get("global/traderType"),
  },
};
</script>

<style scoped>
.fields {
  margin: 0.5rem !important;
}
</style>
