/*
 * This class was generated by the generator, please do not edit this file.
 * Generated on: 2024-09-05 14:31:58
 * Generated input: messages.xml
 */
import StringMessageBase from "../StringMessageBase";
import NumberMessageBase from "../NumberMessageBase";
import ArrayMessageBase from "../ArrayMessageBase";
import WsMsg from "../WsMsg";

class PB22 extends WsMsg {
	constructor() {
		super(2);
		this._securityId = new NumberMessageBase();
		this._side = new StringMessageBase();
		this._price6 = new StringMessageBase();
		this._volume6 = new StringMessageBase();
		this._price7 = new StringMessageBase();
		this._volume7 = new StringMessageBase();
		this._price8 = new StringMessageBase();
		this._volume8 = new StringMessageBase();
		this._price9 = new StringMessageBase();
		this._volume9 = new StringMessageBase();
		this._price10 = new StringMessageBase();
		this._volume10 = new StringMessageBase();
	}
	
	get securityId() {
		return this._securityId;
	}
	
	get side() {
		return this._side;
	}
	
	get price6() {
		return this._price6;
	}
	
	get volume6() {
		return this._volume6;
	}
	
	get price7() {
		return this._price7;
	}
	
	get volume7() {
		return this._volume7;
	}
	
	get price8() {
		return this._price8;
	}
	
	get volume8() {
		return this._volume8;
	}
	
	get price9() {
		return this._price9;
	}
	
	get volume9() {
		return this._volume9;
	}
	
	get price10() {
		return this._price10;
	}
	
	get volume10() {
		return this._volume10;
	}
}

export default PB22;
