import {
  symbolColorStyle,
  priceColorStyle,
  qtyColorStyle,
  ceilColorStyle,
  floorColorStyle,
  comparePriceColorStyle,
  bidOfferPriceColorStyle,
  totalColorStyle,
  NO_VALUE,
  numeralCellFormatter,
  openColorStyle,
} from "../Formatter.js";

import numeral from "numeral";

const columnDefsMarketWatch = [
  {
    headerName: "Symbol",
    field: "symbol",
    pinned: "left",
    rowDrag: true,
    headerCheckboxSelection: false,
    checkboxSelection: false,
    width: 200,
    cellStyle: symbolColorStyle,
  },
  {
    headerName: "Level",
    field: "turnoverFlag",
    width: 80,
    cellStyle: (params) => ({ ...ceilColorStyle(), "justify-content": "flex-start" }),
    hide: true,
  },
  {
    headerName: "Start Date",
    field: "turnoverStartDate",
    width: 120,
    cellStyle: totalColorStyle,
    hide: true,
  },
  {
    headerName: "End Date",
    field: "turnoverEndDate",
    width: 120,
    cellStyle: totalColorStyle,
    hide: true,
  },
  {
    headerName: "Source Type",
    field: "exchangeId",
    width: 120,
    valueFormatter: (params) => {
      if (params.value === 1) {
        return "SET";
      } else if (params.value === 2) {
        return "TFEX";
      } else {
        return params.value;
      }
    },
    cellStyle: (params) => ({ ...ceilColorStyle(params), "justify-content": "flex-start" }),
    hide: true,
  },
  {
    headerName: "Last",
    field: "last",
    width: 100,
    valueGetter: (params) => {
      if (!params.data.last) {
        return NO_VALUE;
      } else {
        return params.data.last;
      }
    },
    cellStyle: priceColorStyle,
  },
  {
    headerName: "Chg",
    field: "change",
    width: 120,
    cellStyle: priceColorStyle,
  },
  {
    headerName: "%Chg",
    field: "changePercent",
    width: 120,
    valueFormatter: (params) => params.value + "%",
    cellStyle: priceColorStyle,
  },
  {
    headerName: "Bid Vol",
    field: "topBidVol",
    width: 120,
    valueGetter: (params) => {
      if (!params.data.topBidVol) {
        return 0;
      } else {
        return params.data.topBidVol;
      }
    },
    valueFormatter: (params) => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Bid",
    field: "topBid",
    width: 120,
    valueGetter: (params) => {
      if (!params.data.topBid) {
        return 0;
      } else {
        return params.data.topBid;
      }
    },
    cellStyle: bidOfferPriceColorStyle,
    cellRendererFramework: "BBOTopBidCellRenderer",
  },
  {
    headerName: "Offer",
    field: "topOffer",
    width: 120,
    valueGetter: (params) => {
      if (!params.data.topOffer) {
        return 0;
      } else {
        return params.data.topOffer;
      }
    },
    cellStyle: bidOfferPriceColorStyle,
    cellRendererFramework: "BBOTopOfferCellRenderer",
  },
  {
    headerName: "Offer Vol",
    field: "topOfferVol",
    width: 120,
    valueGetter: (params) => {
      if (!params.data.topOfferVol) {
        return 0;
      } else {
        return params.data.topOfferVol;
      }
    },
    valueFormatter: (params) => numeralCellFormatter(params, "0,0"),
    cellStyle: qtyColorStyle,
  },
  {
    headerName: "Close",
    field: "prior",
    width: 100,
    valueGetter: (params) => {
      if (!params.data.prior) {
        return NO_VALUE;
      } else {
        return params.data.prior;
      }
    },
    cellStyle: comparePriceColorStyle,
  },
  {
    headerName: "Volume",
    field: "volume",
    width: 150,
    valueFormatter: (params) => numeralCellFormatter(params, "0,0"),
    cellStyle: totalColorStyle,
  },
  {
    headerName: "Value",
    field: "value",
    width: 145,
    valueFormatter: (params) => numeralCellFormatter(params, "0,0"),
    cellStyle: totalColorStyle,
  },
  {
    headerName: "%Swing",
    field: "swingPercent",
    width: 120,
    valueFormatter: (params) => params.value + "%",
    cellStyle: priceColorStyle,
    hide: true,
  },
  {
    headerName: "Volume Brk.",
    field: "volBrk",
    width: 150,
    valueFormatter: (params) => numeralCellFormatter(params, "0,0"),
    cellStyle: totalColorStyle,
    hide: true,
  },
  {
    headerName: "Value Brk.",
    field: "valBrk",
    width: 145,
    valueFormatter: (params) => numeralCellFormatter(params, "0,0"),
    cellStyle: totalColorStyle,
    hide: true,
  },
  {
    headerName: "Proj. Open",
    field: "projectedOpen",
    width: 100,
    valueGetter: (params) => {
      if (!params.data.projectedOpen) {
        return NO_VALUE;
      } else {
        return params.data.projectedOpen;
      }
    },
    cellStyle: comparePriceColorStyle,
    hide: true,
  },
  {
    headerName: "Proj. Close",
    field: "projectedClose",
    width: 100,
    valueGetter: (params) => {
      if (!params.data.projectedClose) {
        return NO_VALUE;
      } else {
        return params.data.projectedClose;
      }
    },
    cellStyle: comparePriceColorStyle,
    hide: true,
  },
  {
    headerName: "Proj. Vol",
    field: "projectedVol",
    width: 145,
    valueFormatter: (params) => numeralCellFormatter(params, "0,0"),
    cellStyle: totalColorStyle,
    hide: true,
  },
  {
    headerName: "%Proj. Open Chg",
    field: "projectedOpenPercent",
    width: 150,
    valueFormatter: (params) => (numeral(params.value).value() ? params.value + "%" : "-"),
    cellStyle: (params) => {
      if (params.value) {
        return priceColorStyle(params);
      } else {
        return totalColorStyle();
      }
    },
    hide: true,
  },
  {
    headerName: "%Proj. Close Chg",
    field: "projectedClosePercent",
    width: 150,
    valueFormatter: (params) => (numeral(params.value).value() ? params.value + "%" : "-"),
    cellStyle: (params) => {
      if (params.value) {
        return priceColorStyle(params);
      } else {
        return totalColorStyle();
      }
    },
    hide: true,
  },
  {
    headerName: "Open",
    field: "open",
    width: 100,
    valueGetter: (params) => {
      if (!params.data.open) {
        return NO_VALUE;
      } else {
        return params.data.open;
      }
    },
    cellStyle: comparePriceColorStyle,
    hide: true,
  },
  {
    headerName: "Open Vol",
    field: "openVol",
    width: 145,
    valueFormatter: (params) => numeralCellFormatter(params, "0,0"),
    cellStyle: totalColorStyle,
    hide: true,
  },
  {
    headerName: "%Open Chg",
    field: "openPercent",
    width: 120,
    valueFormatter: (params) => (numeral(params.value).value() ? params.value + "%" : "-"),
    cellStyle: (params) => {
      if (params.value) {
        return priceColorStyle(params);
      } else {
        return totalColorStyle();
      }
    },
    hide: true,
  },
  {
    headerName: "Prev Close",
    field: "prevClose",
    width: 100,
    valueGetter: (params) => {
      if (!params.data.prevClose) {
        return NO_VALUE;
      } else {
        return params.data.prevClose;
      }
    },
    cellStyle: comparePriceColorStyle,
    hide: true,
  },
  {
    headerName: "Open 1",
    field: "open1",
    width: 100,
    valueGetter: (params) => {
      if (!params.data.open1) {
        return NO_VALUE;
      } else {
        return params.data.open1;
      }
    },
    cellStyle: openColorStyle,
  },
  {
    headerName: "Open 2",
    field: "open2",
    width: 100,
    valueGetter: (params) => {
      if (!params.data.open1) {
        return NO_VALUE;
      } else {
        return params.data.open2;
      }
    },
    cellStyle: openColorStyle,
  },
  // {
  //   headerName: "High",
  //   field: "high",
  //   width: 100,
  //   valueGetter: params => {
  //     if (!params.data.high) {
  //       return NO_VALUE;
  //     } else {
  //       return params.data.high;
  //     }
  //   },
  //   cellStyle: comparePriceColorStyle
  // },
  // {
  //   headerName: "Low",
  //   field: "low",
  //   width: 100,
  //   valueGetter: params => {
  //     if (!params.data.low) {
  //       return NO_VALUE;
  //     } else {
  //       return params.data.low;
  //     }
  //   },
  //   cellStyle: comparePriceColorStyle
  // },
  // {
  //   headerName: "Ceiling",
  //   field: "ceiling",
  //   width: 100,
  //   cellStyle: ceilColorStyle
  // },
  // {
  //   headerName: "Floor",
  //   field: "floor",
  //   width: 100,
  //   cellStyle: floorColorStyle
  // },
  // {
  //   headerName: "Open 1",
  //   field: "open1",
  //   width: 100,
  //   valueGetter: params => {
  //     if (!params.data.open1) {
  //       return NO_VALUE;
  //     } else {
  //       return params.data.open1;
  //     }
  //   },
  //   cellStyle: comparePriceColorStyle
  // },
  // {
  //   headerName: "Open 2",
  //   field: "open2",
  //   width: 100,
  //   valueGetter: params => {
  //     if (!params.data.open2) {
  //       return NO_VALUE;
  //     } else {
  //       return params.data.open2;
  //     }
  //   },
  //   cellStyle: comparePriceColorStyle
  // },
  // {
  //   headerName: "Open 3",
  //   field: "open3",
  //   width: 100,
  //   valueGetter: params => {
  //     if (!params.data.open3) {
  //       return NO_VALUE;
  //     } else {
  //       return params.data.open3;
  //     }
  //   },
  //   cellStyle: comparePriceColorStyle
  // }
];

export { columnDefsMarketWatch };
