<template>
  <div style="width: 100%; background-color: #1B2025;">
    {{ params.value }}
  </div>
</template>

<script>
export default {
  components: {},
  data: () => ({}),

  watch: {},
  methods: {}
};
</script>
