<template>
  <div class="ui equal width grid">
    <div class="row ht-border" style="border-width: 0px 0px 5px 0px;">
      <div class="column" style="height: calc(55vh - 5px);">
        <vue-friendly-iframe ref="friendlyIframe" :src="src" allowfullscreen="true" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrokerPage",
  data: () => ({
    // Todo get value router params
    src: "https://www.aslsecurities.com/Default.aspx"
  })
};
</script>

<style scoped>
.column {
  background-color: rgb(38, 45, 51) !important;
}

.ht-border {
  border-style: solid;
  border-color: black;
}
</style>

<style>
iframe {
  height: calc(55vh - 5px);
  width: 100vw;
  border: 0px;
}
</style>
