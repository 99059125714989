class NumberMessageBase {
  constructor(defaultValue) {
    this.value = "";
    if (defaultValue != null && defaultValue != undefined && !isNaN(defaultValue)) {
      this.value = defaultValue;
    }
  }

  set(value) {
    if (value != null && value != undefined && !isNaN(value)) {
      this.value = Number(value);
    }
  }

  get() {
    return this.value;
  }
}

export default NumberMessageBase;
